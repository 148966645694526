import axios from "axios";

// const API_URL = process.env.REACT_APP_API_URI;
const API_URL = process.env.REACT_APP_API_URI_WIZR;

class AuthServiceWizr {
  login(getData) {
    return axios
      .post(API_URL + "merchat_mobile_login", getData)
      .then((response) => {
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    const userName = localStorage.getItem('username') ? localStorage.getItem('username') : ''
    const pass = localStorage.getItem('dot') ? localStorage.getItem('dot') : ''
    localStorage.clear();
    localStorage.setItem('username', userName)
    localStorage.setItem('dot', pass)
  }

  register(getData) {
    return axios.post(API_URL + "register", getData);
  }

  post(givenData, Url) {
    return axios
      .post(API_URL + Url, givenData)
      .then((response) => {
        // console.log('response', response);
        return response.data;
      });
  }
  loginWIZR(getData) {
    return axios
      // .post(API_URL + "B2C/login_via_email", getData)
      .post(API_URL + "generateOtpByEmail", getData)
      .then((response) => {
        return response.data;
      });
  }
  get(Url) {
    return axios
      .get(API_URL + Url,{headers:{'Authorization': 'Bearer ' + localStorage.getItem('token')}})
      .then((response) => {
      //  console.log('response', response);
        return response.data;
      });
  }
}

export default new AuthServiceWizr();

import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Box from "@mui/material/Box";
import { Component } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Card from "@mui/material/Card";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomizeCheckbox from "../common/CustomizeCheckbox";
import FilterCustomDivider from "../common/FilterCustomDivider";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Scrollbar } from "react-scrollbars-custom";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import moment from 'moment';
import { getMediumOfInstruction, getMerchantProductsByFilter, getMerchantProductsWizr, setShowSearched, setSearchedField } from "../actions/user";
import { connect } from "react-redux";
import Alert from '@mui/material/Alert';
import { logOut } from "../actions/auth";
import { history } from "../helpers/history";




let courseType = [
  {
    checked: false,
    label: "Online",
  },
  {
    checked: false,
    label: "In-person (Offline)",
  },
  {
    checked: false,
    label: "Hybrid",
  },
];

const courseDeliveryMode = [
  {
    checked: false,
    label: "Less than ₹ 5,000",
  },
  {
    checked: false,
    label: "₹ 5,001 - ₹ 10,000 ",
  },
  {
    checked: false,
    label: "₹ 10,001 - ₹ 30,000 ",
  },
  {
    checked: false,
    label: "₹ 30,001 - ₹ 60,000 ",
  },
  {
    checked: false,
    label: "₹ 60,001 - ₹ 1,00,000 ",
  },
  {
    checked: false,
    label: "More than ₹ 1,00,000",
  },
];


const listingTime = [
  {
    checked: false,
    label: "This Week",
  },
  {
    checked: false,
    label: "This Month",
  },
  {
    checked: false,
    label: "This Quarter",
  },
  {
    checked: false,
    label: "Custom",
  },
];



class CourseFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseTypes:[...courseType],
      courseDeliveryModes:courseDeliveryMode,
      // mediumOfInstructions:mediumOfInstruction,
      mediumOfInstructions:[],
      listingTimes:listingTime,
      a1: courseType,
      startDate:"",
      endDate:"",
      ListingTimeValue:"",
      FilterError:""
    };
  }

//   shouldComponentUpdate(nextProps, nextState){
//      if(nextProps.clearfilterValue !== this.props.clearfilterValue){
//     //    this.setState({ courseTypes:courseType,
//     //     courseDeliveryModes:courseDeliveryMode,
//     //     mediumOfInstructions:mediumOfInstruction,
//     //     listingTimes:listingTime})
//      }else{
//         true;
//      }
componentDidUpdate(prevProps) {
       if (prevProps.clearfilterValue !== this.props.clearfilterValue) {     
             this.setState({
              courseTypes:this.state.courseTypes.map(ele =>{ return {...ele,checked:false}}),
              courseDeliveryModes:this.state.courseDeliveryModes.map(ele =>{ return {...ele,checked:false}}),
              mediumOfInstructions:this.state.mediumOfInstructions.map(ele =>{ return {...ele,checked:false}}),
              startDate:"",
              endDate:"",
              ListingTimeValue:"",
              // listingTimes:this.state.listingTimes.map(ele =>{ return {...ele,checked:false}})
            })    
        } 

        if(prevProps.show !== this.props.show){
          if(this.props.show){
              const {stage,sfid} = this.props;
            const data = {
                "merchant_id": sfid
            }
            this.props.dispatch(getMediumOfInstruction(data)).then(response => {
              if(response.status === "success"){
                let mediumData = response?.data?.mediumData ? response.data.mediumData : [];
               this.setState({mediumOfInstructions: mediumData.map(ele =>{return {checked: false,label:ele.medium_of_instruction__c}})})
              }else{
                  this.setState({mediumOfInstructions:[]})
              }
           }).catch(error => {
            if(error?.status === 401){
              this.props.dispatch(logOut());
              history.push('/login');
            }
          })
          
      }

          }
        }
    
  

  handleCheck = (checkValue,index,Type) => {
   let {courseTypes,courseDeliveryModes,mediumOfInstructions,listingTimes} = this.state;
    if(Type==="courseTypes"){
      courseTypes[index].checked =checkValue;
      this.setState({courseTypes:courseTypes})
    }
    if(Type==="courseDeliveryModes"){
      courseDeliveryModes[index].checked = checkValue;
      this.setState({courseDeliveryModes:courseDeliveryModes})
    }
    if(Type==="mediumOfInstructions"){
      mediumOfInstructions[index].checked = checkValue;
      this.setState({mediumOfInstructions:mediumOfInstructions})
    }
    // if(Type==="listingTimes"){
    //   listingTimes[index].checked = e.target.checked;
    //   this.setState({listingTimes:listingTimes})
    // }
  };

  handleCloseFilterValue = (checkedValue,index) => {
   let {courseTypes} = this.state;
     courseTypes[index].checked = checkedValue;
    this.setState({courseTypes:courseTypes})
  };

  handleListingTime = (event) => {
    this.setState({ListingTimeValue:event.target.value})
    let value = event.target.value;
    if(value ==="This Week"){
      let start = moment(new Date()).format('YYYY-MM-DD')
      let last = moment(new Date(new Date().setDate(new Date().getDate() - 6))).format('YYYY-MM-DD')
      this.setState({startDate:last,endDate:start})
      
    }
    if(value === "This Month"){
      let start = moment(new Date()).format('YYYY-MM-DD')
      let last = moment(new Date(new Date().setDate(new Date().getDate() - 29))).format('YYYY-MM-DD')      
      this.setState({startDate:last,endDate:start})
    }
    if(value === "This Quarter"){
      let start = moment(new Date()).format('YYYY-MM-DD')
      let last = moment(new Date(new Date().setDate(new Date().getDate() - 89))).format('YYYY-MM-DD')  
      this.setState({startDate:last,endDate:start})
    }
    

  };

  handleClose=()=>{
    this.props.close();
}

clearFilter = () => {
  const {sfid,stage} = this.props;
  const {courseTypes,courseDeliveryModes,mediumOfInstructions,ListingTimeValue} = this.state;
  const courseTypesFilter = courseTypes.filter(ele => ele.checked)
  const courseDeliveryModesFilter = courseDeliveryModes.filter(ele => ele.checked)
  const mediumOfInstructionsFilter = mediumOfInstructions.filter(ele => ele.checked)
  if(mediumOfInstructionsFilter.length > 0 || courseDeliveryModesFilter.length > 0 || courseTypesFilter.length > 0 || ListingTimeValue ){
  const productStage = stage === "active" ? "Active": stage === "inactive" ? "Inactive":stage === "Approval pending"?"Approval pending": "";

    let dataObj =  {
      merchant_id:sfid,
      page: 1,
      limit: 10,
      // status:stage,
      // source :"B2C",
      // section:"Course",
      // sub_section:this.props.stage,
      filter: {
        search:"",
        // productStatus: "Active",  //Active, Inactive
        productStatus: productStage,
        course_type: "",
        course_delivery_mode:"", //Online, Offline
        medium_of_instruction__c:"",
        course_fees:"",
        // listing_time: ["2023-07-01", "2023-08-01"]
        listing_time:"",
    }
  }

  this.props.dispatch(setShowSearched(false))
  this.props.dispatch(setSearchedField(""))
  this.props.handleFilterValue({
    search:"",
    productStatus: productStage,
    course_type: "",
    course_delivery_mode:"", //Online, Offline
    medium_of_instruction__c:"",
    course_fees:"",
    listing_time:"",
})     
      this.getcourseFilterData(dataObj,true);
  }
  this.setState({
    courseTypes:this.state.courseTypes.map(ele =>{ return {...ele,checked:false}}),
    courseDeliveryModes:this.state.courseDeliveryModes.map(ele =>{ return {...ele,checked:false}}),
    mediumOfInstructions:this.state.mediumOfInstructions.map(ele =>{ return {...ele,checked:false}}),
    listingTimes:this.state.listingTimes.map(ele =>{ return {...ele,checked:false}}),
    startDate:"",
    endDate:"",
    ListingTimeValue:"",
  })
}

applyFilter = () => {
  const { user_id,stage,sfid } = this.props
  // const { brandOptSel, proOptSel, catOptSel, offOptSel, timeOptSel } = this.state
  let { courseTypes,courseDeliveryModes,mediumOfInstructions,endDate,startDate,ListingTimeValue } = this.state;

  const courseTypesFilter = courseTypes.filter(ele => ele.checked).map(element => element.label);
  let courseDeliveryModesFilter = courseDeliveryModes.filter(ele => ele.checked).map(element => element.label.replace(/,/g,""));
  courseDeliveryModesFilter = courseDeliveryModesFilter.map(element => element.replace(/₹ /g,"").trim());
   courseDeliveryModesFilter =  courseDeliveryModesFilter.map(ele => {
    if(ele === "Less than 5000"){
      return "0 - 5000"
    }else if( ele === "More than 100000"){
        return "100000 - 10000000"
    }else{
      return ele
    }
  })
  const mediumOfInstructionsFilter = mediumOfInstructions.filter(ele => ele.checked).map(element => element.label);
  // const listingTimesFilter = listingTime.filter(ele => ele.checked).map(element => element.name);
  const productStage = stage === "active" ? "Active": stage === "inactive" ? "Inactive":stage === "Approval pending"?"Approval pending": "";

   let filterObject = {
    search:"",
    productStatus: productStage,
    course_type: courseTypesFilter.length > 0 ? courseTypesFilter:"",
    // course_delivery_mode: courseDeliveryModesFilter.length > 0 ? courseDeliveryModesFilter : "",
    course_delivery_mode: "",
    course_fees:courseDeliveryModesFilter.length > 0 ? courseDeliveryModesFilter : "",
    medium_of_instruction__c: mediumOfInstructionsFilter.length > 0 ? mediumOfInstructionsFilter : "",
    listing_time:ListingTimeValue ? [startDate, endDate]:"",
   } 

   if(ListingTimeValue === "Custom"){
    let startDateValue = new Date(startDate).getTime();
    let endDateValue = new Date(endDate).getTime();
    if(!startDate || !endDate){
      this.setState({FilterError:"please select the start and end date"})
      setTimeout(() => {
        this.setState({FilterError:""})
      }, 1000);  
      return; 
    }
     if (startDateValue > endDateValue) {
      this.setState({FilterError:"start date must be less than the end date"})
      setTimeout(() => {
        this.setState({FilterError:""})
      }, 1000); 
      return;  
    } 
     
    filterObject = {...filterObject,listing_time: startDate && endDate  ? [startDate, endDate] : ""}
   }
   
   this.props.dispatch(setShowSearched(false))
   this.props.dispatch(setSearchedField(""))
  this.props.handleFilterValue(filterObject)

    let dataObj =  {
        merchant_id:sfid,
        page: 1,
        limit: 10,
        filter: filterObject,  
    }
     
    this.getcourseFilterData(dataObj);
     
  }

      // this.props.dispatch(getProductFilterData(dataObj)).then(response => {
      //     console.log(response, "product filter response");
      // })
          // .catch(error => {
          //     console.log('product filtere error', error)
          // })
  // }

  getcourseFilterData = async (dataObj,clearFilter) => {
    this.props.dispatch(getMerchantProductsWizr(dataObj)).then(response => {
      // this.clearFilter();
      if(response.status === "success"){
        if(!clearFilter){
       this.handleClose();
        }
        // this.handleClose();
      }else{
          this.setState({FilterError:response.message? response.message :"something went wrong"})
          setTimeout(() => {
            this.setState({FilterError:""})
          }, 1000);   
      }
  }).catch(error => {
    if(error?.status === 401){
      this.props.dispatch(logOut());
      history.push('/login');
    }
    this.setState({FilterError:error?.data?.message?error.data.message:"something went wrong"})
    setTimeout(() => {
      this.setState({FilterError:""})
    }, 1000);
  })
  }


handleClose=()=>{
  this.props.close();
}


  render() {
    let {courseTypes,courseDeliveryModes,mediumOfInstructions,listingTimes,startDate,endDate} = this.state;
    let {show,stage} = this.props;
    return (
      <>
          <Modal show={show} className='right myModal filter_modal filterbarMainModal' id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
    <button type="button"  onClick={this.handleClose}  className=" filterModalCloseButton" > <img style={{marginTop:"15px"}} src="./images/icons/icon-close2.png"/> </button>
  
      <Box className='filterDiv'>        
        <div className="filterboxdiv" >
         
          <h1  className='filter-main-title'   >
            Filters
          </h1>
          <div className="filterboxdivoptions">
          <button className='mr-2 filtermainbtn filterclearbtn font800' onClick = {this.clearFilter} >Clear All</button>
          <button className='filtermainbtn filterapplybtn'onClick={this.applyFilter}>Apply</button>
          </div>
        </div>
     <FilterCustomDivider/>
     <div style={{position:'relative'}}>
     {this.state.FilterError && <div className="filter-err-container">
         <Alert severity="error">{this.state.FilterError}</Alert>
     </div>}
        <Card
          variant="outlined"
          sx={{ border: "none", boxShadow: "none",overflowY:"auto" }}
          className="mobile-card-padding"
        >
           
          
        {/* <Scrollbar> */}

        {/* single accordian */}
          <Accordion
            elevation={0}
            disableGutters={true}
            className="accordionsec"
            sx={{ paddingBottom: "0.573vw" }}
          >
            <AccordionSummary
              className="accordian-summary"
              expandIcon={<ExpandMoreIcon className="accordian-arrow" />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className="text-title-filter">Course Type</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordian-detail paddingAccordianFilter">
              <>
                <Stack direction="row" gap="10px" flexWrap="wrap">
                  {courseTypes?.length > 0 &&
                    courseTypes.map((ele,i) => {
                        if(ele.checked){
                            return (
                                <button className="btn filterOptchk">
                                  {ele.label}{" "}
                                  <span>
                                    <img className='filterclosebtn'
                                      src="./images/icons/icon-close3.svg"
                                      onClick={() =>
                                        this.handleCheck(false,i,"courseTypes")
                                      }
                                    />{" "}
                                  </span>{" "}
                                </button>
                              );
                        }                    
                    })}
                </Stack>
              </>
              {courseTypes?.length > 0 &&
                courseTypes.map((ele,i) => {
                  return (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={2}
                      className="accordion-option"
                    >
                      <FormControlLabel
                        label={
                          <Typography className="text-options-filter">
                            {ele.label}
                          </Typography>
                        }
                        control={
                          <Checkbox
                          sx={{ '& .MuiSvgIcon-root': { fontSize:'1.318vw' } }}
                            color="default"
                            checked={ele.checked}
                            onChange={(e) =>
                              this.handleCheck(e.target.checked,i,"courseTypes")
                            }
                          />
                        }
                      />
                    </Stack>
                  );
                })}
            </AccordionDetails>
          </Accordion>

          {/* second accordian */}

          <Accordion
            elevation={0}
            disableGutters={true}
            className="accordionsec"
            sx={{ paddingBottom: "0.573vw" }}
          >
            <AccordionSummary
              className="accordian-summary"
              expandIcon={<ExpandMoreIcon className="accordian-arrow" />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className="text-title-filter">Course Fee</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordian-detail paddingAccordianFilter">
              <>
                <Stack direction="row" gap="10px" flexWrap="wrap">
                  {courseDeliveryModes?.length > 0 &&
                    courseDeliveryModes.map((ele,i) => {
                        if(ele.checked){
                            return (
                                <button className="btn filterOptchk">
                                  {ele.label}{" "}
                                  <span>
                                    <img className='filterclosebtn'
                                      src="./images/icons/icon-close3.svg"
                                      onClick={() =>
                                        this.handleCheck(false,i,"courseDeliveryModes")
                                      }
                                    />{" "}
                                  </span>{" "}
                                </button>
                              );
                        }

                    })}
                </Stack>
              </>
              {courseDeliveryModes?.length > 0 &&
                courseDeliveryModes.map((ele,i) => {
                  return (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={2}
                      className="accordion-option"
                    >
                      <FormControlLabel
                        label={
                          <Typography className="text-options-filter">
                            {ele.label}
                          </Typography>
                        }
                        control={
                          <Checkbox
                          sx={{ '& .MuiSvgIcon-root': { fontSize:'1.318vw' } }}
                            color="default"
                            checked={ele.checked}
                            onChange={(e) =>
                              this.handleCheck(e.target.checked,i,"courseDeliveryModes")
                            }
                          />
                        }
                      />
                    </Stack>
                  );
                })}
            </AccordionDetails>
          </Accordion>


          {/* <Accordion
            elevation={0}
            disableGutters={true}
            sx={{ paddingBottom: "0.573vw" }}
          >
            <AccordionSummary
              className="accordian-summary"
              expandIcon={<ExpandMoreIcon className="accordian-arrow" />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className="text-title-filter">Medium of Instruction</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordian-detail">
              <>
                <Stack direction="row" gap="10px" marginBottom="15px" flexWrap="wrap">
                  {mediumOfInstructions?.length > 0 &&
                    mediumOfInstructions.map((ele,i) => {
                        if(ele.checked){
                            return (
                                <button className="btn filterOptchk">
                                  {ele.label}{" "}
                                  <span>
                                    <img className='filterclosebtn'
                                      src="./images/icons/icon-close3.svg"
                                      onClick={() =>
                                        this.handleCheck(false,i,"mediumOfInstructions")
                                      }
                                    />{" "}
                                  </span>{" "}
                                </button>
                              );
                        }
                    })}
                </Stack>
              </>
              {mediumOfInstructions?.length > 0 &&
                mediumOfInstructions.map((ele,i) => {
                  if(!ele.label){
                    return;
                  }
                  return (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={2}
                      className="accordion-option"
                    >
                      <FormControlLabel
                        label={
                          <Typography className="text-options-filter">
                            {ele.label}
                          </Typography>
                        }
                        control={
                          <Checkbox
                          sx={{ '& .MuiSvgIcon-root': { fontSize:'1.318vw' } }}
                            color="default"
                            checked={ele.checked}
                            onChange={(e) =>
                              this.handleCheck(e.target.checked,i,"mediumOfInstructions")
                            }
                          />
                        }
                      />
                    </Stack>
                  );
                })}
            </AccordionDetails>
          </Accordion> */}

          <Accordion
            elevation={0}
            disableGutters={true}
            className="accordionsec"
            sx={{ paddingBottom: "0.573vw" }}
          >
            <AccordionSummary
              className="accordian-summary"
              expandIcon={<ExpandMoreIcon className="accordian-arrow" />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className="text-title-filter">Listing Time</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordian-detail paddingAccordianFilter">
              <>
                <Stack direction="row" gap="10px" flexWrap="wrap">
                  {listingTimes?.length > 0 &&
                    listingTimes.map((ele,i) => {
                        if(ele.checked){
                            return (
                                <button className="btn filterOptchk">
                                  {ele.label}{" "}
                                  <span>
                                    <img className='filterclosebtn'
                                      src="./images/icons/icon-close3.svg"
                                      onClick={() =>
                                        this.handleCloseFilterValue(false,i)
                                      }
                                    />{" "}
                                  </span>{" "}
                                </button>
                              );
                        }
                    })}
                </Stack>
              </>
              {/* {listingTimes?.length &&
                listingTimes.map((ele,i) => {
                  return (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={2}
                      className="accordion-option"
                    >
                      <FormControlLabel
                        label={
                          <Typography className="text-options-filter">
                            {ele.label}
                          </Typography>
                        }
                        control={
                          <Checkbox
                          sx={{ '& .MuiSvgIcon-root': { fontSize:'1.318vw' } }}
                            color="default"
                            checked={ele.checked}
                            onChange={(e) =>
                              this.handleCheck(e,i,"listingTimes")
                            }
                          />
                        }
                      />
                    </Stack>
                  );
                })} */}
                 <FormControl>
      {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        value={this.state.ListingTimeValue}
        onChange={this.handleListingTime}
      >
        <FormControlLabel value="This Week" control={<Radio />} label="This Week" />
        <FormControlLabel value="This Month" control={<Radio />} label="This Month" />
        <FormControlLabel value="This Quarter" control={<Radio />} label="This Quarter" />
        <FormControlLabel value="Custom" control={<Radio />} label="Custom" />
      </RadioGroup>
    </FormControl>
             { this.state.ListingTimeValue === "Custom" &&  <div className="mb-4 courseCalenderContainer">
                                      <div className="">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                          <DemoContainer components={['DatePicker', 'DatePicker']}>
                                            <DatePicker
                                              label=" Start Date"
                                              // defaultValue={dayjs(new Date())}
                                              // value={new Date(this.state.startDate)}
                                              views={['year', 'month', 'day']}
                                              onChange={(newValue) => this.setState({ startDate: moment(newValue).format('YYYY-MM-DD') })}
                                              disableFuture={true}
                                             slotProps={{ textField: {readOnly:true } }}
                                            
                                              // onError={(error,value) => this.setState({startDate:"",endDate:""})}
                                              // disabled
                    
                                            />
                                          </DemoContainer>
                                        </LocalizationProvider>
                                      </div>
                                      <div className="">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                          <DemoContainer components={['DatePicker', 'DatePicker']}>
                                            <DatePicker
                                              label="End Date"
                                              views={['year', 'month', 'day']}
                                              // value={this.state.endDate && this.state.endDate}
                                              onChange={(newValue) => this.setState({ endDate: moment(newValue).format('YYYY-MM-DD') })}
                                              // disabled={!this.state.startDate ? true : false}
                                              // minDate={this.state.startDate ? new Date(this.state.startDate):""}
                                              disableFuture={true}
                                              slotProps={{ textField: {readOnly:true } }}
                                            />
                                          </DemoContainer>
                                        </LocalizationProvider>
                                      </div>
                                    </div>}
            </AccordionDetails>
          </Accordion>
          {/* </Scrollbar> */}

        </Card>
        <FilterCustomDivider />
      </div>
      </Box>
      </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user, user_id, sfid } = state.auth;
  const { leadTab } = state.user;
  return {
  //   user,
  //   products,
  //   offer_details,
    user_id,
    sfid,
    leadTab
  };
}


export default connect(mapStateToProps)(CourseFilter);


import React, { Component } from 'react';
import { setWizrActiveTab, getAllMerchantProducts, getMerchantProductList, getCategory } from '../actions/user';
import { connect } from "react-redux";
import { openCreateLeadModel, openAddNewProduct } from '../actions/model';
import { logout, logoutWizr } from "../actions/auth";
import { history } from '../helpers/history';
class TopWizrbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlyDrop: "This Month",
      showUpArrow: false
    };
  }

  openCreateLeads = () => {
    // const data = {
    //   "merchant_id":localStorage.getItem("user_id")
    //   }
    //   this.props.dispatch(getAllMerchantProducts(data)).then(res => {
    //     if(res?.proData?.length > 0){
    //       this.setState({allProductData:res.proData})
    //       }
    //   })
    this.props.dispatch(openCreateLeadModel());

  }

  getMonthlyDrop = (stage) => {    
    this.setState({ monthlyDrop: stage })
    this.props.getSummary(stage)
  }

  // handleLogout = () => {
  //   // this.props.dispatch(logout());
  //   this.props.dispatch(logoutWizr()).then((response) => {
  //     if (response.data.status == "success") {
  //       history.push('/login')
  //     }

  //   });



  // }

  openBulkUpload = () => {
    let getProd = { merchant_id: this.props.user_id }
    this.props.dispatch(getMerchantProductList(getProd));
    this.props.dispatch(getCategory());

  }

  openAddNewProduct = () => {
    let getProd = { merchant_id: this.props.user_id }
    this.props.dispatch(getMerchantProductList(getProd));
    this.props.dispatch(getCategory());
    this.props.dispatch(openAddNewProduct(true));

  }
  render() {
    const { showAddNewCourse, showAddNewUser, showMontlydropdown, showMontlydropdowncred, activeWizrTab, showCreateCourse, showbulkupload, showAddNewUsercred, getSummary, showAddNewProduct, merchant_journey, showLogout } = this.props;    
    return (
      <>
        <nav className={`${activeWizrTab == 'WIZR Skills' ? 'top-header-wizr-left' : 'top-header-wizr-left top-header-wizr-left-color'}`}>
          {merchant_journey == "Both" ?
            <>
              <a className={`${activeWizrTab == 'WIZR Skills' ? 'selected ' : ''} wizr-skills-tab cursor-point`} onClick={() => this.props.dispatch(setWizrActiveTab("WIZR Skills"))}>Wizr Skills</a>
              <a className={`${activeWizrTab == 'WIZR Cred' ? 'selected ' : ''} wizr-skills-tab wizr-skills-tab-left cursor-point`} onClick={() => this.props.dispatch(setWizrActiveTab("WIZR Cred"))}>Wizr Cred</a>
            </>
            : merchant_journey == "B2C" ?
              <><a className={`${activeWizrTab == 'WIZR Skills' ? 'selected ' : ''} wizr-skills-tab cursor-point`} onClick={() => this.props.dispatch(setWizrActiveTab("WIZR Skills"))}>Wizr Skills</a></>
              : <a className={`${activeWizrTab == 'WIZR Cred' ? 'selected ' : ''} wizr-skills-tab wizr-skills-tab-left cursor-point`} onClick={() => this.props.dispatch(setWizrActiveTab("WIZR Cred"))} style={{ marginLeft: "7px" }}>Wizr Cred</a>
          }
          {/* <a className={`${activeWizrTab=='WIZR Skills'?'selected ':''} wizr-skills-tab cursor-point`} onClick={() => this.props.dispatch(setWizrActiveTab("WIZR Skills"))}>WIZR Skills</a>
          <a className={`${activeWizrTab=='WIZR Cred'?'selected ':''} wizr-skills-tab wizr-skills-tab-left cursor-point`} onClick={() => this.props.dispatch(setWizrActiveTab("WIZR Cred"))}>WIZR Cred</a> */}
          {/* {showAddNewCourse && <div className='addnewBtn'>
            <div className=' addnewBtnimg' data-toggle="modal" onClick={this.openBulkUploadAndAddNewProduct}
              data-target="#myModal2"><i className="fa fa-plus fa-light pr-2 text-white size18"></i>Add Course</div>
          </div>} */}
          {showAddNewProduct && <div className='addnewBtn newprobtn'>
            <div className=' addnewBtnimg' data-toggle="modal" onClick={this.openAddNewProduct}
              data-target="#myModal245"><i className="fa fa-plus fa-light pr-2 text-white size18"></i>Add New Product</div>
          </div>}

          {showCreateCourse &&
            <div className='addnewBtn addnewBtn-createLead'>
              <div className=' addnewBtnimg' data-toggle="modal" onClick={this.openCreateLeads}
                data-target="#myModal"><i className="fa fa-plus fa-light pr-2 text-white size18"></i>Create Lead</div>
            </div>}

          {showbulkupload &&
            <div className='addnewBtn addnewBtn-bulk'>
              <div className=' addnewBtnimg' data-toggle="modal" onClick={this.openBulkUpload}
                data-target="#myModal80"><img src="./images/bulkdownload.svg" className="pr-2 text-white size18" />Bulk Upload</div>
            </div>}
          {/* {showLogout && <div className='addnewBtnLogout addnewBtn-logout'>
            <div className=' addnewBtnimg' onClick={this.handleLogout}>
              <img src="./images/logout.svg" className="pr-2 text-white size18" />Logout</div>
          </div>} */}

          {showAddNewUser && <div className='addnewBtn'>
            <div className=' addnewBtnimg' onClick={this.props.openAddUser}><img src="./img/add_icon.svg" style={{marginTop: "-3px"}}></img>Add New User</div>
          </div>}
          {showAddNewUsercred && <div className='addnewBtn'>
            <div className=' addnewBtnimg' onClick={this.props.openAddUserCred}><i className="fa fa-plus fa-light pr-2 text-white size18"></i>Add New User</div>
          </div>}
          {showMontlydropdowncred && <div className="dropdown montlydrops"  >
            <button role="button" type="button" className=" d-flex btn dropdown dropdownData dropdownSubject" data-toggle="dropdown">
              {this.state.monthlyDrop}
              {/* <span><i className="fa fa-angle-down pl-3"></i></span> */}
            </button>
            <div class="dropdown-menu monthlydropoptcred" aria-labelledby="dropdownMenuButton">
              <a onClick={() => this.getMonthlyDrop("Today")} class="dropdown-item cursor-point" style={{ zIndex: '9999' }} >Today</a>
              <a onClick={() => this.getMonthlyDrop("This Week")} class="dropdown-item cursor-point" style={{ zIndex: '9999' }} >This Week</a>
              <a onClick={() => this.getMonthlyDrop("This Month")} class="dropdown-item cursor-point" style={{ zIndex: '9999' }} >This Month</a>
            </div>
          </div>}

          {showMontlydropdown && <div className="dropdown montlydrops"  >
            <button role="button" type="button" className=" d-flex btn dropdown dropdownData dropdownSubject" data-toggle="dropdown">
              {this.state.monthlyDrop === "today" ? "Today" : this.state.monthlyDrop === "thisweek" ? "This Week" : "This Month"}
              {/* <span><i className="fa fa-angle-down pl-3"></i></span> */}
            </button>
            <div class={`dropdown-menu monthlydropoptcred ${this.state.monthlyDrop === "today" ? "today_dash" : this.state.monthlyDrop === "thisweek" ? "week_dash" : "month_dash"}`} aria-labelledby="dropdownMenuButton">
              <a onClick={() => this.getMonthlyDrop("today")} class="dropdown-item cursor-point" style={{ zIndex: '9999' }} >Today </a>
              <a onClick={() => this.getMonthlyDrop("thisweek")} class="dropdown-item cursor-point" style={{ zIndex: '9999' }} >This Week</a>
              <a onClick={() => this.getMonthlyDrop("thismonth")} class="dropdown-item cursor-point" style={{ zIndex: '9999', borderBottom: "none" }} >This Month</a>
            </div>
          </div>}
        </nav>
      </>
    )
  }

}
function mapStateToProps(state) {
  const { activeWizrTab, merchant_journey } = state.user;
  const { isLoading, user_id, sfid } = state.auth;


  return {
    activeWizrTab,
    merchant_journey,
    user_id
  };
}

export default connect(mapStateToProps)(TopWizrbar);

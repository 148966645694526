import React from 'react'
import { Modal, Button, Form } from "react-bootstrap"

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { Component } from 'react';




// const initialState = {
// closeModal:false
// }

class FilterCustomDivider extends Component {
    constructor() {
        super()
        // this.state = initialState;
    }

    // handleClose=()=>{
    //     console.log("here323")
    //     this.props.close();
    // }
render(){
    // const {show,children}=this.props
//   const [open, setOpen] = React.useState(false);
//   const handleClose = () => setOpen(false);

//   const { filter_show, products } = this.props;
  return (
    
    <>
         <Divider variant="middle" className='DividerStyle' />

    </>
  )
    }
}
export default FilterCustomDivider;
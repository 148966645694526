 const dateSort = (givenArray, sortKey, orderType) => {
    let sortedArray
     if (orderType == -1) {
       sortedArray = givenArray.sort((a, b) =>
       a[sortKey].split('/').reverse().join().localeCompare(b[sortKey].split('/').reverse().join())); 
       console.log(sortedArray,'232')
     }
      if (orderType == 1) {
      sortedArray = givenArray.sort((a, b) =>
       b[sortKey].split('/').reverse().join().localeCompare(a[sortKey].split('/').reverse().join())); 
       console.log(sortedArray,'2321')
    }
     return sortedArray
    }
     export default dateSort;
import React from 'react'
import { Modal, Button, Form } from "react-bootstrap"
import Box from '@mui/material/Box';
import { Component } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Card from '@mui/material/Card';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomizeCheckbox from '../common/CustomizeCheckbox';
import FilterCustomDivider from '../common/FilterCustomDivider';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


const LeadDateFilterDataArr=[
  {
    checked:false,
    label:'Today',
  },
  {
    checked:false,
    label:'This Week',
  },
  {
    checked:false,
    label:'This Month',
  },
  {
    checked:false,
    label:'This Quarter',
  },
];
class LeadDateFilter extends Component {
    constructor(props) {
        super(props)
       this.state={
        // extensions:[{value: 1, label: 'Excel', extension: 'xls'},{value: 3, label: 'PDF', extension: 'pdf'}]
        LeadDateFilterData:[...LeadDateFilterDataArr],
       
    // professionalcheck:false,
    // studentcheck:false,
    // checkValStud:false,
    // checkValProf:false
    // studentcheckboxchecked:true,
    // professionalcheckboxchecked:true
      }
    }
    componentDidUpdate(prevProps) {
      if (prevProps.clearfilterValue !== this.props.clearfilterValue) {     
        // console.log("dsjfhdff",this.props.clearfilterValue);
            this.setState({
             LeadDateFilterData:this.state.LeadDateFilterData.map(ele =>{ return {...ele,checked:false}})
           },() => {
             console.log("232",this.state.LeadDateFilterData);
           })    
       }
       
   }
  //  clickedagain=(val)=>{
  //   console.log(val,"ewdd")
  //  }
    handleCheck = (e,index,Type) => {
      let {LeadDateFilterData} = this.state;
       if(Type==="LeadDateFilterData"){
        LeadDateFilterData[index].checked = e.target.checked;
         this.setState({LeadDateFilterData:LeadDateFilterData})
         this.props.handleDateChange(LeadDateFilterData)
        //  this.onFilterClick(LeadDateFilterData)
       }
      }
  handleCloseFilterValue = (checkedValue,index) => {
    let {LeadDateFilterData} = this.state;
    LeadDateFilterData[index].checked = checkedValue;
     this.setState({LeadDateFilterData:LeadDateFilterData})
   };

  //  handleDateChange =()=>{
  //   this.props.handleDateApply()
  //  }
render(){
  return (
 
    <>
    <Card variant="outlined" sx={{border: "none", boxShadow: "none"}} className='mobile-card-padding' >
    <Accordion elevation={0} disableGutters={true} sx={{paddingBottom: '0.573vw'}}    >
         <AccordionSummary className='accordian-summary' 
           expandIcon={<ExpandMoreIcon className='accordian-arrow' />}
           aria-controls="panel2a-content"
           id="panel2a-header"
         >
           <Typography className='text-title-filter'>
             Date
           </Typography>
         </AccordionSummary>
         <AccordionDetails className='accordian-detail'  >
         <>
         <Stack direction="row" gap="10px" marginBottom="15px" flexWrap="wrap">
                  {this.state.LeadDateFilterData?.length &&
                    this.state.LeadDateFilterData.map((ele,i) => {
                        if(ele.checked){
                            return (
                                <button className="btn filterOptchk">
                                  {ele.label}{" "}
                                  <span>
                                    <img
                                      src="./images/icons/icon-close3.svg"
                                      onClick={() =>
                                        this.handleCloseFilterValue(false,i)
                                      }
                                    />{" "}
                                  </span>{" "}
                                </button>
                              );
                        }                    
                    })}
                </Stack>
</>
{this.state.LeadDateFilterData?.length &&
                this.state.LeadDateFilterData.map((ele,i) => {
                  return (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={2}
                      className="accordion-option"
                    >
                      <FormControlLabel
                        label={
                          <Typography className="text-options-filter">
                            {ele.label}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            color="default"
                            checked={ele.checked}
                            onChange={(e) =>
                              this.handleCheck(e,i,"LeadDateFilterData")
                            }
                          />
                        }
                      />
                    </Stack>
                  );
                })}
        
 
         </AccordionDetails>
       </Accordion>
      
      </Card>
      <FilterCustomDivider/>
     </>
   );
   
      
    
 
 
  
}
}

export default LeadDateFilter;
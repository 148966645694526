import React, { Component } from 'react'
import OtpInput from 'react-otp-input';
import { history } from '../helpers/history';
import { connect } from "react-redux";
import { merchantLogin, salesForceLogin, clearMessage, loginWIZR, verifyOtp, changePassword, logout, verifyOtpWizr, merchantLoginWizr, changePasswordWizr } from "../actions/auth";
import { getUserProfile, setMerchantJourney } from "../actions/user";

let resendTimer;
class loginNew extends Component {
    constructor(props) {
        super(props);
        this.handleContinue = this.handleContinue.bind(this);
        this.sendOtp = this.sendOtp.bind(this);

        this.state = {
            otp: "",
            email: localStorage.getItem("emailOTP") ? localStorage.getItem("emailOTP") : "",
            password: "",
            newPassword: "",
            confirmPassword: "",
            emailError: "",
            passwordError: "",
            tpError: "",
            newPasswordError: "",
            confirmPasswordError: "",
            captcha: "",
            validEmail: false,
            validPassword: false,
            validConfirmPassword: false,
            validNewPassword: false,
            validOtp: false,
            showPasswordText: false,
            changePassIcon: false,
            resendStart: 30,
            showResend: false,
            // showPasswordText: false,
            firstTimeLoginStage: false,
            createNewPasswordStage: false,
            emailpassword: false,
            emailotp: false,
            otpStage: false,
            passwordRecoveryStage: false,
            sentVerificationLinkStage: false,
            allStages: ["firstTimeLoginStage", "createNewPasswordStage", "emailpassword", "emailotp", "otpStage", "passwordRecoveryStage", "sentVerificationLinkStage"],
            activeStage: "",
            loading: false,
            loginCount: 0,
            maxLoginCount: 3,
            otpError: "",
            loginAPIerror: "",
            newPassUserID: "",
            rememberMe: localStorage.getItem("dot") && localStorage.getItem("username") ? true : false,
            disableOtp: true,
        }
    }

    componentDidMount() {
        let qr = new URLSearchParams(window.location.search);
        let stage_name = qr.get("stage");
        if (!stage_name) {
            //history.push('/login?stage=loginStage')
            history.push('/login?stage=emailpassword')
        }
        if (stage_name == "loginStage") {
            this.setState({ activeStage: "firstTimeLoginStage" })
        }
        else if (stage_name == "createNewPasswordStage") {
            this.setState({ activeStage: "createNewPasswordStage" })
        }
        else if (stage_name == "emailpassword") {
            this.setState({ activeStage: "emailpassword" })
        }
        else if (stage_name == "emailotp") {
            this.setState({ activeStage: "emailotp" })
        }
        else if (stage_name == "otpStage") {
            this.setState({ activeStage: "otpStage" })
            // this.setState({ activeStage: "emailotp" })
            history.push('/login?stage=otpStage')
        }
        else if (stage_name == "passwordRecoveryStage") {
            this.setState({ activeStage: "passwordRecoveryStage" })
        }
        else if (stage_name == "sentVerificationLinkStage") {
            this.setState({ activeStage: "sentVerificationLinkStage" })
        }
        else {
            // this.setState({ activeStage: "firstTimeLoginStage" })
            this.setState({ activeStage: "emailpassword" })
            //history.push('/login?stage=firstTimeLoginStage')
            history.push('/login?stage=emailpassword')
        }
        let user = localStorage.getItem("user");
        if (user) {
            history.push('/home')
        }

        let givenMail = localStorage.getItem("emailOTP") ? localStorage.getItem("emailOTP") : "";
        if (stage_name == 'otpStage') {


            if (givenMail != "") {
                let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (givenMail.match(mailformat)) {
                    this.setState({ emailError: "", validEmail: true, email: givenMail })
                }
            } else {
                this.setState({ emailError: "", validEmail: false })
            }

        } else {

            const userName = localStorage.getItem('username') ? localStorage.getItem('username') : ''
            const pass = localStorage.getItem('dot') ? localStorage.getItem('dot') : ''
            this.setState({ email: userName, password: pass, validPassword: pass ? true : false, validEmail: userName ? true : false })
        }
    }

    handleOtp = (otp) => {
        // if (resendTimer) {
        //     this.setState({ showResend: true, resendStart: 30 })
        //     clearInterval(resendTimer)
        // }
        this.setState({ otp: otp, otpError: "" })
    }

    validateEmail = (e) => {
        this.setState({ loginAPIerror: "" })
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let email = e.target.value
        this.setState({ email: email })
        if (email.length == 0) {
            this.setState({ emailError: "Email cannot be empty", validEmail: false })
        }
        else if (email.length < 8 || email.length > 55) {
            this.setState({ emailError: "Invalid email format", validEmail: false })
        }
        else if (!email.match(mailformat)) {
            this.setState({ emailError: "Invalid email format", validEmail: false })
        }
        else {
            this.setState({ emailError: "", validEmail: true, email: email })
        }
    }

    validatePassword = (e) => {
        let qr = new URLSearchParams(window.location.search);
        let stage_name = qr.get("stage");

        let password = e.target.value;
        this.setState({ loginAPIerror: "" })

        let specialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        this.setState({ password: password })

        if (stage_name === "emailpassword") {
            if (password.length < 8) {
                this.setState({ validPassword: false, password: password })
            } else {
                this.setState({ passwordError: "", validPassword: true, password: password })
            }
        } 
        // else {
        //     if (password.length == 0) {
        //         this.setState({ passwordError: "Password cannot be empty", validPassword: false })
        //     }
        //     else if (password.length < 8) {
        //         this.setState({ passwordError: "Password must be between 8 and 15 characters in length", validPassword: false })
        //     }
        //     else if (!(/[a-z]/.test(password))) {
        //         this.setState({ passwordError: "Atleast add 1 lower case letter", validPassword: false })
        //     }
        //     else if (!(/\d/.test(password))) {
        //         this.setState({ passwordError: "Atleast add 1 digit", validPassword: false })
        //     }
        //     else if (!(specialChar.test(password))) {
        //         this.setState({ passwordError: "Atleast add 1 special", validPassword: false })
        //     }
        //     else if(password == this.state.confirmPassword){
        //         this.setState({ confirmPasswordError: "", validConfirmPassword: true, validConfirmPassword: password })
        //     }
        //     else {
        //         this.setState({ passwordError: "", validPassword: true, validConfirmPassword: false, password: password })
        //     }
        // }


    }


    // validateconfirmPassword = (e) => {
    //     let qr = new URLSearchParams(window.location.search);
    //     let stage_name = qr.get("stage");

    //     let password = e.target.value;
    //     this.setState({ confirmPassword: password })
    //     if (password.length == 0) {
    //         this.setState({ confirmPasswordError: "Password cannot be empty", validConfirmPassword: false })
    //     }
    //     else if (password !== this.state.password) {
    //         this.setState({ confirmPasswordError: "Password does not match with new password", validConfirmPassword: false })
    //     }
    //     else {
    //         this.setState({ confirmPasswordError: "", validConfirmPassword: true, validConfirmPassword: password })
    //     }

    // }

    validateCaptcha = (e) => {
        this.setState({ captcha: e.target.value })
    }
    resendOtp = () => {
        
        this.setState({ showResend: false, otp: "", otpError: "", disableOtp: true })

        this.sendOtp();
        // if (resendTimer) {
        //     this.setState({ resendStart: 30 })
        //     clearInterval(resendTimer)
        // }
        // resendTimer = setInterval(() => {
        //     if (this.state.resendStart > 0) {
        //         this.setState({ resendStart: this.state.resendStart - 1, disableOtp: true })
        //     }
        //     if (this.state.resendStart == 0) {
        //         this.setState({ showResend: true, resendStart: 30, disableOtp: false })
        //         clearInterval(resendTimer)
        //     }
        // }, 1000)
    }


    handleContinue = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
        });
        const { validEmail, validPassword } = this.state;
        const { dispatch, history, onBording } = this.props;
        if (this.state.rememberMe) {
            localStorage.setItem('username', this.state.email)
            localStorage.setItem('dot', this.state.password)
        }

        let data = {
            email: this.state.email,
            password: this.state.password
        }
        if (validEmail && validPassword) {
            dispatch(merchantLoginWizr(data))
                .then((response) => {
                    if (response.status == "success") {
                        this.setState({
                            loading: false,
                            newPassUserID: response.current_user.user_id
                        });
                        localStorage.setItem('cpplogo', response.data.cpp_circular_logo);
                        dispatch(setMerchantJourney(response.current_user.merchant_journey))
                        let obj = { id: this.props.user_id, token: this.props.token }
                        if (response.current_user.merchant_journey == "B2B2C") {
                            dispatch(salesForceLogin(obj));
                        }
                        // localStorage.setItem('username', this.state.email)

                        if (response.current_user.is_user_changed_password == 0) {
                            this.setState({ activeStage: "createNewPasswordStage", password: "", email: "" })
                            history.push('/login?stage=createNewPasswordStage')
                        } else {
                            if (response.current_user.merchant_journey == "B2B2C") {
                                let userData = { user_sfid: response.merchant_sfid }
                                this.props.dispatch(getUserProfile(userData)).then(
                                    (response) => {
                                        if (response.responseCode == 200 && response.message == "success") {
                                            history.push("/home");
                                        }
                                    });
                            }
                            history.push("/home");
                        }
                    }
                    else {
                        this.setState({
                            loading: false,
                            loginCount: this.state.loginCount + 1,
                            loginAPIerror: response.message
                        });
                        if (this.state.loginCount > this.state.maxLoginCount) {
                            //history.push("/login_mobile");
                            this.setState({ activeStage: "emailotp" })
                            history.push('/login?stage=emailotp')

                        }

                    }
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        loginCount: this.state.loginCount + 1,
                        loginAPIerror: this.props.authMessage
                    });
                    if (this.state.loginCount > this.state.maxLoginCount) {
                        // history.push("/login_mobile");
                        this.setState({ activeStage: "emailotp" })
                        history.push('/login?stage=emailotp')
                    }
                });
        }

    }

    handleFirstTimeLogin = (e) => {
        e.preventDefault();
        let { validEmail, validPassword } = this.state;
        if (validEmail && validPassword) {
            this.setState({ activeStage: "createNewPasswordStage", password: "", email: "" })
            history.push('/login?stage=createNewPasswordStage')
        }
        else {
            return
        }
    }
    handleNewPassword = (e) => {
        e.preventDefault();
        let { validConfirmPassword, validPassword } = this.state;
        if (validPassword && validConfirmPassword) {
            this.setState({ password: "", confirmPassword: "" })

            const { history, user_id } = this.props;
            let data = {
                newPassword: this.state.password,
                confirmPassword: this.state.confirmPassword,
                id: parseInt(this.state.newPassUserID)
            }
            this.props
                .dispatch(
                    changePasswordWizr(data)
                )
                .then((response) => {
                    // if (response.responseCode === 500) {
                    //     console.log("if", response.responseCode)
                    //     this.setState({ confirmPasswordError: response.message })
                    // }
                    history.push('/login?stage=emailpassword')
                    this.setState({ activeStage: "emailpassword", password: "", confirmPassword: "", newPassUserID: "" })


                })
                .catch((error) => {
                    this.setState({ confirmPasswordError: "Password mismatch" })
                });
        }
        else {
            this.setState({ confirmPasswordError: "Password mismatch" })
        }

    }



    handleOTPLogin = () => {
        this.setState({ activeStage: "emailotp", password: "", email: "", validEmail: false })
        history.push('/login?stage=emailotp')
    }
    handleEmailPassLogin = () => {
        this.setState({ activeStage: "emailpassword", password: "", email: "", loginAPIerror: "", emailError: "" })
        history.push('/login?stage=emailpassword')
    }
    handleGetOtp = (e) => {

        e.preventDefault();
        this.setState({ showResend: true, otp: '' })
        let { validEmail } = this.state;
        const { history, dispatch } = this.props;
        if (validEmail) {
            localStorage.setItem('emailOTP', this.state.email);
            // if (resendTimer) {
            //     this.setState({ showResend: false, resendStart: 30 })
            //     clearInterval(resendTimer)
            // }
            // resendTimer = setInterval(() => {
            //     if (this.state.resendStart > 0) {
            //         this.setState({ resendStart: this.state.resendStart - 1 })
            //     }
            //     if (this.state.resendStart == 0) {
            //         this.setState({ showResend: true, resendStart: 30 })
            //         clearInterval(resendTimer)
            //     }
            // }, 1000)
            this.sendOtp();
        }
        else {
            this.setState({ emailError: "Please enter valid Email Id." });
        }

    }


    sendOtp = () => {
        const { history, dispatch } = this.props;

        let data = {
            email: this.state.email,
        }
        dispatch(loginWIZR(data))
            .then((response) => {
                if (response.status == "error") {
                    this.setState({
                        emailError: response.message,
                    })
                } else if (response.status == "failed" && response.responseCode == 400) {
                    this.setState({
                        emailError: response.message,
                    })
                }
                else {
                    this.setState({
                        emailError: "",
                    })
                    this.setState({ activeStage: "otpStage" })
                    // localStorage.setItem("otpLogid", response.log_id)
                    history.push('/login?stage=otpStage')
                    if (resendTimer) {
                        this.setState({ resendStart: 30 })
                        clearInterval(resendTimer)
                    }
                    resendTimer = setInterval(() => {
                        if (this.state.resendStart > 0) {
                            this.setState({ resendStart: this.state.resendStart - 1, disableOtp: true })
                        }
                        if (this.state.resendStart == 0) {
                            this.setState({ showResend: true, resendStart: 30, disableOtp: false })
                            clearInterval(resendTimer)
                        }
                    }, 1000)
                }
            },
                (error) => {
                    this.setState({
                        emailError: "Invalid request",
                    })
                })
    }
    handleEditEmail = () => {
        this.setState({ activeStage: "emailotp", otpError: "", otp: "" })
        if (resendTimer) {
            this.setState({ showResend: true, resendStart: 30 })
            clearInterval(resendTimer)
        }
        history.push('/login?stage=emailotp')
    }

    handleLoginOTP = () => {
        let { otp } = this.state;
        const { dispatch, log_id } = this.props;
        if (otp.length == 4) {
            // this.setState({ password: "", otp: "" })
            let data = {
                otp: otp,
                // logId: parseInt(log_id)
                email: this.state.email
            }
            dispatch(verifyOtpWizr(data))
                .then((response) => {
                    if (response.status === 'success') {
                        this.setState({
                            otpError: ""
                        })
                        if (resendTimer) {
                            this.setState({ showResend: true, resendStart: 30 })
                            clearInterval(resendTimer)
                        }
                        let obj = { id: this.props.user_id, token: this.props.token }
                        localStorage.removeItem('emailOTP')
                        localStorage.setItem('cpplogo', response.data.cpp_circular_logo);
                        dispatch(setMerchantJourney(response.current_user.merchant_journey))
                        if (response.current_user.merchant_journey == "B2B2C") {
                            dispatch(salesForceLogin(obj));
                            let userData = { user_sfid: response.merchant_sfid }
                            this.props.dispatch(getUserProfile(userData)).then(
                                (response) => {
                                    if (response.responseCode == 200 && response.message == "success") {
                                        history.push("/home");
                                    }
                                });
                        }
                        else {
                            history.push("/home");
                        }

                    } else {
                        this.setState({
                            otpError: response.message,
                            otp: "",
                            password: "",
                            disableOtp:false
                        })
                        if (resendTimer) {
                            this.setState({ showResend: true, resendStart: 30 })
                            clearInterval(resendTimer)
                        }
                    }
                },
                    (error) => {

                        this.setState({
                            otpError: "Something went wrong",
                        })
                    });

        }
        else {
            return
        }
    }

    handleSentVerificationLink = () => {
        let { validEmail } = this.state;
        if (validEmail) {
            this.setState({ activeStage: "sentVerificationLinkStage" })
            history.push('/login?stage=sentVerificationLinkStage')
        }
    }
    handleForgotPassword = () => {
        let { passwordRecoveryStage } = this.state;
        this.setState({ activeStage: "passwordRecoveryStage" })
        history.push('/login?stage=passwordRecoveryStage')

    }
    editRecoveryEmail = () => {
        let { passwordRecoveryStage } = this.state;
        this.setState({ activeStage: "passwordRecoveryStage" })
        history.push('/login?stage=passwordRecoveryStage')
    }

    componentWillUnmount() {
        clearInterval(resendTimer);
    }
    enterKeyCall=(e)=>{
        if (e.keyCode == 13) {
            this.handleLoginOTP()
     }
    }

    rememberMeHandle = (e) => {
        this.setState({ rememberMe: e.target.checked})
        if(!e.target.checked){
           localStorage.removeItem('username');
           localStorage.removeItem('dot');
        }
    }

    validatePassConfirmPass = (pass, givenConfirmPass) => {
        let qr = new URLSearchParams(window.location.search);
        let stage_name = qr.get("stage");

        let password = pass;
        let confirmPassword = givenConfirmPass;

        this.setState({ loginAPIerror: "", passwordError: "", confirmPasswordError: "" })

        let specialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        // this.setState({ password: password })

        if (stage_name === "emailpassword") {
            if (password.length < 8) {
                this.setState({ validPassword: false, password: password })
            } else {
                this.setState({ passwordError: "", validPassword: true, password: password })
            }
        } else {
            if (password.length == 0) {
                this.setState({ passwordError: "Password cannot be empty", validPassword: false })
            }
            else if (!(/[A-Z]/.test(password))) {
                this.setState({ passwordError: "Atleast add 1 Upper case letter", validPassword: false })
            }
            else if (!(/[a-z]/.test(password))) {
                this.setState({ passwordError: "Atleast add 1 lower case letter", validPassword: false })
            }
            else if (!(/\d/.test(password))) {
                this.setState({ passwordError: "Atleast add 1 digit", validPassword: false })
            }
            else if (!(specialChar.test(password))) {
                this.setState({ passwordError: "Atleast add 1 special", validPassword: false })
            }
            else if (password.length < 8 || password.length > 15) {
                this.setState({ passwordError: "Password must be between 8 and 15 characters in length", validPassword: false })
            }
            else if (password !== confirmPassword) {
                if(confirmPassword != ""){
                    this.setState({ confirmPasswordError: "Password does not match with new password", validConfirmPassword: false })
                }else{
                    this.setState({ validPassword: false, validConfirmPassword: false })
                }
                
            }
            else if(password == confirmPassword){
                this.setState({ confirmPasswordError: "", validConfirmPassword: true, validPassword: true })
            }
            else {
                this.setState({ passwordError: "", validPassword: true, validConfirmPassword: false })
            }
        }
    }
    passwordValidate = (e) => {
        if(e.target.name == "newPassword"){
            this.setState({password:e.target.value}, () => {
                this.validatePassConfirmPass(this.state.password, this.state.confirmPassword)
            })
        }else{
            this.setState({confirmPassword:e.target.value}, () => {
                this.validatePassConfirmPass(this.state.password, this.state.confirmPassword)
            })
        }
        
    }

    render() {
        const { message, isSuccess, authMessage, user_id, log_id } = this.props;


        const { activeStage, emailError, passwordError, emailpassword, otpStage, passwordRecoveryStage, email, password, otp, sentVerificationLinkStage, createNewPasswordStage, validEmail, validPassword, showResend, showPasswordText, changePassIcon, emailotp, firstTimeLoginStage, confirmPassword, confirmPasswordError, validConfirmPassword, otpError } = this.state;
        return (
            <div className='login_mainSection' id="loginSection">
                <div className='container newContainer'>
                    <div className="row">
                        <div className='loginsec'>
                            <div className='col-md-7 col-lg-7 col-xs-12 colForLogin'>

                                {/* Login With Email 1st time */}
                                <div className='header'>
                                    <div className='logoWizr'><img src="./img/WiZRLogoBlack.png" /></div>
                                    {activeStage === "firstTimeLoginStage" && (
                                        <div>
                                            <h4>Let’s get you started!</h4>
                                            <p>Use your official credentials</p>
                                            <form className='formLogin' onSubmit={this.handleFirstTimeLogin}>
                                                <div className="form-group labelforadduser">
                                                    <label className="form-label">Email ID</label>
                                                    <input
                                                        className="form-control formadduser2"
                                                        type="text"
                                                        name="email"
                                                        placeholder='Enter email id'
                                                        id="email_id"
                                                        // style={{ height: '35px' }}
                                                        value={email}
                                                        onChange={(e) => this.validateEmail(e)}
                                                    />
                                                    <div className='text-danger'>{emailError}</div>
                                                </div>

                                                <div className="form-group labelforadduser mt-2">
                                                    <label className="form-label">Password</label>
                                                    <div className='passSec'>
                                                        <input
                                                            className="form-control formadduser2"
                                                            type={showPasswordText ? "text" : "password"}
                                                            name="password"
                                                            placeholder='Enter Password'
                                                            id="password"
                                                            // style={{ height: '35px' }}
                                                            value={password}
                                                            onChange={(e) => this.validatePassword(e)}
                                                        />
                                                        <div class="input-group-addon passicon" onClick={() => this.setState({ showPasswordText: !showPasswordText })}>
                                                            {showPasswordText == false ? <img src='./img/show-pass.svg' alt='Show Password' /> : <img src='./img/hide-pass.svg' />}
                                                        </div>
                                                    </div>
                                                    <div className='text-danger'>{passwordError}</div>
                                                </div>

                                                {/* <div className='ForgotPassword cursor-point' onClick={() => this.setState({ otpStage: false, emailPasswordStage: false, passwordRecoveryStage: true, sentVerificationLinkStage: false, createNewPasswordStage: false, email: "", emailError: "", validEmail: false,getOtpStage:false })}>Forgot Password</div> */}
                                                <div className='Buttonwithcontent'>
                                                    <button className={`ContinueButton ${validEmail && validPassword ? '' : 'disabledButtonStyle'}`} disabled={!(validEmail && validPassword)}>Continue</button>
                                                    <span className='loginOTPLink'>or  Login with <span onClick={() => this.handleOTPLogin()}>OTP</span></span>
                                                </div>
                                            </form>
                                        </div>)}
                                    {/* Login With Email 1st time end */}
                                    {/* Login With Email password*/}
                                    {activeStage === "emailpassword" && (
                                        <div>
                                            <h4>Let’s get you started!</h4>
                                            <p class="subcont">Use your official credentials</p>
                                            <form className='formLogin' onSubmit={this.handleContinue}>
                                                <div className="form-group labelforadduser">
                                                    <label className="form-label">Email ID</label>
                                                    <input
                                                        className="form-control formadduser2"
                                                        type="text"
                                                        placeholder='Enter email id'
                                                        name="email"
                                                        id="email_id"
                                                        // style={{ height: '35px' }}
                                                        value={email}
                                                        onChange={(e) => this.validateEmail(e)}
                                                    />
                                                    <div className='text-danger'>{emailError}</div>
                                                </div>

                                                <div className="form-group labelforadduser mt-2">
                                                    <label className="form-label">Password</label>
                                                    <div className='passSec'>
                                                        <input
                                                            className="form-control formadduser2"
                                                            type={showPasswordText ? "text" : "password"}
                                                            placeholder='Enter Password'
                                                            name="password"
                                                            id="password"
                                                            maxLength="15"
                                                            // style={{ height: '35px' }}
                                                            value={password}
                                                            onChange={(e) => this.validatePassword(e)}
                                                        />
                                                        <div class="input-group-addon passicon" onClick={() => this.setState({ showPasswordText: !showPasswordText })}>
                                                            {showPasswordText == false ? <img src='./img/show-pass.svg' alt='Show Password' /> : <img src='./img/hide-pass.svg' />}
                                                        </div>
                                                        <div className='text-danger'>{passwordError}</div>
                                                    </div>
                                                </div>
                                                {/* {isSuccess === 0 && authMessage && passwordError === '' && emailError === '' && (
                                                    <div className="form-group">
                                                        <div className={isSuccess === 1 ? "alert alert-success" : "text-danger"} role="alert">
                                                            {authMessage}
                                                        </div>
                                                    </div>
                                                )} */}
                                                {/* <p className='rememberMe'><input className='rememberMeInput' type="checkbox" onChange={(e) => this.rememberMeHandle(e)} checked={this.state.rememberMe} /><span class="remembertext">Remember me</span></p> */}
                                                <p className='checkmarksec'>
                                                    <label className="customcheck" onChange={(e) => this.rememberMeHandle(e)}>
                                                        <span className="remembertext">Remember me</span>
                                                        <input type="checkbox" checked={this.state.rememberMe} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </p>

                                                <div className="form-group">
                                                    <div className='text-danger'>{this.state.loginAPIerror}</div>
                                                </div>

                                                {/* <div className='ForgotPassword cursor-point' onClick={() => this.handleForgotPassword()}>Forgot Password</div> */}
                                                <div className='Buttonwithcontent'>
                                                    <button type="submit" className={`ContinueButton ${validEmail && validPassword ? '' : 'disabledButtonStyle'}`} disabled={!(validEmail && validPassword)}>Continue</button>
                                                    <span className='loginOTPLink'>or  Login with <span onClick={() => this.handleOTPLogin()}>OTP</span></span>
                                                </div>
                                            </form>
                                        </div>)}
                                    {/* Login With Email End */}
                                    {/* Login with Otp */}
                                    {activeStage === "otpStage" && (
                                        <div>
                                            <h4>We’ve sent you an OTP</h4>
                                            <p className='emailcont'>On your email id <span>{email}</span> <img className='ml-2 cursor-point' src="./img/penciledit.svg" onClick={() => this.handleEditEmail()} /></p>
                                            <div className='optverify'>
                                                <OtpInput
                                                    value={otp}
                                                    // onChange={(e) => this.setState({otp:e.target.value })}
                                                    onChange={this.handleOtp}
                                                    numInputs={4}
                                                    shouldAutoFocus
                                                    inputType="number"
                                                    renderSeparator={<span></span>}
                                                    renderInput={(props) => <input {...props} onKeyDown={(e)=>this.enterKeyCall(e)}/>}
                                                />
                                                <p className='resend resendotpsec' ><button className={`resendoTp ${this.state.disableOtp && "disable-resend"}`} onClick={this.resendOtp} disabled={this.state.disableOtp}>Resend</button> OTP in 00:{this.state.resendStart < 10 ? `0${this.state.resendStart}` : this.state.resendStart} seconds</p>
                                            </div>
                                            <div className='text-danger'>{otpError}</div>
                                            <div className='ButtonwithcontentPassword'>
                                                {/* <button className={`ContinueButton mr-2 ${otp.length != 6 ? 'disabledButtonStyle' : ''}`} disabled={!(otp.length != 6)}>Continue</button>  or  Login with <button className='btnforPassword' onClick={() => this.setState({ otpStage: false, emailPasswordStage: true, passwordRecoveryStage: false, sentVerificationLinkStage: false, createNewPasswordStage: false })}>Password</button> */}
                                                <button className={`ContinueButton mr-2 ${otp.length != 4 ? 'disabledButtonStyle' : ''}`} disabled={!(otp.length == 4)} onClick={() => this.handleLoginOTP()}>Continue</button><span className='loginOTPLink'>or  Login with <span onClick={() => this.handleEmailPassLogin()}>Password</span></span>

                                            </div>
                                        </div>)}
                                    {/* Login with Otp End */}
                                    {/* Login with Password recovery */}
                                    {activeStage === "passwordRecoveryStage" && (
                                        <div>
                                            <h4>Password Recovery</h4>
                                            <p>Lorem Ipsum</p>
                                            <form className='formLogin' onSubmit={this.handleSentVerificationLink}>
                                                <div className="form-group labelforadduser">
                                                    <label className="form-label">Email ID</label>
                                                    <input
                                                        className="form-control formadduser2"
                                                        type="text"
                                                        name="email"
                                                        id="email_id"
                                                        value={email}
                                                        onChange={(e) => this.validateEmail(e)}
                                                    // style={{ height: '35px' }}
                                                    />
                                                </div>
                                                <div className='text-danger'>{emailError}</div>
                                                <div className='Buttonwithcontentverification'>
                                                    <button type="submit" className={`ContinueButton ${validEmail ? '' : 'disabledButtonStyle'}`} disabled={!(validEmail)}>Send Verification Link</button>
                                                </div>
                                            </form>
                                        </div>)}
                                    {/* Login with Password recovery end  */}

                                    {/* Login with verification Link */}
                                    {activeStage === "sentVerificationLinkStage" && (
                                        <div>
                                            <h4>We’ve sent you a verification link</h4>
                                            <p>On your email id <span>{email}</span> <img className='ml-2 cursor-point' src="./img/penciledit.svg" onClick={() => this.editRecoveryEmail()} /></p>
                                            <div className='Buttonwithcontentresend'>
                                                <p>Didn’t receive the link? Don’t worry! We can send the link again</p>
                                                <button className='ContinueButton resendverify'>Re-send Verification Link</button>
                                            </div>
                                        </div>)}

                                    {/* getOtp */}
                                    {activeStage === "emailotp" && (
                                        <div>
                                            <h4>Let’s get you started!</h4>
                                            <p class="subcont">Use your official credentials</p>
                                            <form className='formLogin' onSubmit={this.handleGetOtp}>
                                                <div className="form-group labelforadduser">
                                                    <label className="form-label">Email ID</label>
                                                    <input
                                                        className="form-control formadduser2"
                                                        type="text"
                                                        placeholder='Enter email id'
                                                        name="email"
                                                        id="email_id"
                                                        value={email}
                                                        onChange={(e) => this.validateEmail(e)}
                                                    // style={{ height: '35px' }}
                                                    />
                                                    <div className='text-danger'>{emailError}</div>
                                                </div>
                                                <div className='Buttonwithcontentverification'>
                                                    <button className={`ContinueButton ${validEmail ? '' : 'disabledButtonStyle'}`} disabled={!(validEmail)}>Get OTP</button><span className='loginOTPLink'>or  Login with <span onClick={() => this.handleEmailPassLogin()}>Password</span></span>
                                                </div>
                                            </form>
                                        </div>)}
                                    {/* getOtp  end*/}
                                    {/* Login with verification Link end */}
                                    {/* New Password Start */}
                                    {activeStage === "createNewPasswordStage" && (
                                        <div>
                                            <h4>Create New Password</h4>
                                            <p>Please follow the instructions to create the password</p>
                                            <form className='formLogin' onSubmit={this.handleNewPassword}>
                                                <div className="form-group labelforadduser">
                                                    <label className="form-label">New Password</label>
                                                    <div className='passSec'>
                                                        <input
                                                            className="form-control formadduser2"
                                                            type={showPasswordText ? "text" : "password"}
                                                            name="newPassword"
                                                            id="new_password"
                                                            value={password}
                                                            maxLength={15}
                                                            onChange={(e) => this.passwordValidate(e)}
                                                            // onChange={(e) => this.setState({password:e.target.value}, () => {
                                                            //     this.validatePassConfirmPass(this.state.password, this.state.confirmPassword)
                                                            // })}
                                                        // style={{ height: '35px' }}
                                                        />
                                                        <div class="input-group-addon passicon" onClick={() => this.setState({ showPasswordText: !showPasswordText })}>
                                                            {showPasswordText == false ? <img src='./img/show-pass.svg' alt='Show Password' /> : <img src='./img/hide-pass.svg' />}
                                                        </div>
                                                    </div>
                                                    <div className='text-danger'>{passwordError}</div>
                                                </div>

                                                <div className="form-group labelforadduser mt-2">
                                                    <label className="form-label">Confirm Password</label>
                                                    <input
                                                        className="form-control formadduser2"
                                                        type="password"
                                                        name="confirmPassword"
                                                        value={confirmPassword}
                                                        onChange={(e) => this.passwordValidate(e)}
                                                        // onChange={(e) => this.setState({confirmPassword:e.target.value}, () => {
                                                        //     this.validatePassConfirmPass(this.state.password, this.state.confirmPassword)
                                                        // })}
                                                        id="password"
                                                    // style={{ height: '35px' }}
                                                    />
                                                    <div className='text-danger'>{confirmPasswordError}</div>
                                                </div>
                                                {/* <p className='rememberMe'><input type="checkbox" />Remember Me</p> */}
                                                <div className='Buttonwithcontent'>
                                                    <button className={`ContinueButton ${validPassword && validConfirmPassword ? '' : 'disabledButtonStyle'}`} disabled={!(validPassword && validConfirmPassword)}>Continue</button>
                                                </div>
                                            </form>
                                        </div>)}
                                    {/* New Password End */}
                                </div>
                            </div>
                            <div className='col-md-5 col-lg-5 col-xs-12 imgBackground pd0'>
                                <div className='imgContainer'>
                                    {/* {!createNewPasswordStage && (<img src='./img/leftFrame.png' className='img-fuild' />)}
                                    {createNewPasswordStage && (<img src='./img/passwordNotice.svg' className='img-fuild' />)} */}
                                    {<img src='./img/loginRightImage.svg' className='img-fuild' />}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { isLoggedIn, onBording, isSuccess, authMessage, user_id, token, log_id } = state.auth;
    const { message } = state.message;
    return {
        isLoggedIn,
        isSuccess,
        authMessage,
        message,
        user_id,
        onBording,
        token,
        log_id,

    };
}

export default connect(mapStateToProps)(loginNew);

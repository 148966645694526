import React from 'react'
import { Component } from 'react';
import { connect } from 'react-redux';

class NoRecordsFound extends Component {
    constructor() {
        super()
        
    }

render(){
  const {message,show_searched,isLoading}=this.props
  return (
    <>
   <div  className='norecordsTab text-center'>
  
      {isLoading ? <h1 className='sorrynoRecordtxt'></h1>:
      message && !show_searched 
      ?<h1 className='sorrynoRecordtxt'>{message}</h1>
      :
      <>
   <h1 className='sorrynoRecordtxt'>Sorry, we couldn’t find results for <b className="norecordSearchtxt">{`‘${message}’`}</b></h1>
   <p className='sorrynoRecord_subtxt'>Check your spelling. Or Trying looking up a different search tearm.</p>
   </>
  }
   </div>
   </>
  )
}
  
}

function mapStateToProps(state) {
  const {isLoading } = state.auth;
  return {
      isLoading
  };
}
export default connect(mapStateToProps)(NoRecordsFound);
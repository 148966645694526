import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Button } from "react-bootstrap"
import { closeSuccessModel,closeCreateLeadSuccess, openLeadProfileModel, updateProId, openSuccessModel } from "../actions/model"
import { getLeadProfile, softPullData } from "../actions/user"

class AddedLeadModel extends Component {

    constructor() {
        super()
        this.state = {
            isFrontUploading: false,
            isBackUploading: false,            
        }
    }    

    closeModel = () => {
        const {createLeadData} = this.props
        let opp_id = createLeadData ? createLeadData.opp_sfid : "";
        let sfid = createLeadData ? createLeadData.user_sfid : "";
        let id = createLeadData ? createLeadData.user_id : "";
        this.props.dispatch(closeCreateLeadSuccess());
        // if(createLeadData && createLeadData.mulesoft){
        //     if(createLeadData.mulesoft.status == "success" && createLeadData.mulesoft.data.limit){
        //         this.props.dispatch(softPullData({limit:createLeadData.mulesoft.data.limit,name:createLeadData.opp_applicant_name,age:21}))
        //         setTimeout(() => {
        //             this.props.dispatch(openSuccessModel());                    
        //         }, 1000);
        //     }else{
        //         this.props.dispatch(openLeadProfileModel(sfid ,id))
        //         this.props.dispatch(updateProId(opp_id));
        //     }
        // }else{
        //     this.props.dispatch(openLeadProfileModel(sfid ,id))
        //     this.props.dispatch(updateProId(opp_id));
        // }
        if(createLeadData.isNewUser){     
            if(createLeadData.mulesoft.Status == "ok" && createLeadData.mulesoft.data ){
                if(createLeadData.mulesoft.data.limit == 0){
                    this.props.dispatch(openLeadProfileModel(sfid, id))
                    this.props.dispatch(updateProId(opp_id));
                }else{
                    this.props.dispatch(softPullData({limit:createLeadData.mulesoft.data.limit,name:createLeadData.opp_applicant_name,age:createLeadData.mulesoft.data.age__c}))
                    setTimeout(() => {
                        this.props.dispatch(openSuccessModel());                    
                    }, 1000);
                }   
            }else{
                // this.props.dispatch(softPullData({limit:0,name:createLeadData.data.name,age:createLeadData.data.age__c}))    
                this.props.dispatch(openLeadProfileModel(sfid, id))
                this.props.dispatch(updateProId(opp_id));
            }
           

        }else{
            if(createLeadData.data.cc_sublimit__c){
                this.props.dispatch(softPullData({limit:createLeadData.data.cc_sublimit__c,name:createLeadData.data.name,age:createLeadData.data.age__c}))
                setTimeout(() => {
                    this.props.dispatch(openSuccessModel());                    
                }, 1000);
            }else{
        //         const { createLeadData } = this.props
        // console.log(createLeadData, '>>>>>>>>>>')
        // let opp_id = createLeadData ? createLeadData.opp_sfid : "";
        // let sfid = createLeadData ? createLeadData.user_sfid : "";
        // let id = createLeadData ? createLeadData.id : "";
        // this.props.dispatch(closeSuccessModel())
        this.props.dispatch(openLeadProfileModel(sfid, id))
        this.props.dispatch(updateProId(opp_id));
            }
            // this.props.dispatch(softPullData({limit:0,name:createLeadData.data.name,age:createLeadData.data.age__c}))
        }
        // setTimeout(() => {
        //     this.props.dispatch(openSuccessModel());                    
        // }, 1000);
    }

    render() {
      
        const { success_show, lead_limit, lead_name,create_lead_success,createLeadData } = this.props;
           
        
        return (
            <>
                <Modal show={create_lead_success ? create_lead_success : false} className="bulkupload type_1">

                    <form>
                        <Modal.Body>

                            <div className="row">

                                <div className="col-md-12 success-popup text-center">
                                    <img src="images/lead_succ.svg" alt="" className='img-fluid' />
                                    <h6 className='mt-2' style={{ fontWeight: 600, fontSize: "1.757vw" }}>Congratulations!</h6>
                                    <h6 className="mt-1" style={{ fontSize: "1.025vw"}}>You have added a lead</h6>
                                    {/* <h6 className="" style={{ fontWeight: 900 }}>lead!</h6> */}
                                    <div className="alert alert-info pl-5 pr-5" role="alert">
                                        <h6 style={{ fontSize: "1.318vw", fontWeight: 600, color: "black" }}>{createLeadData && createLeadData.opp_applicant_name ? createLeadData.opp_applicant_name : "--"}</h6>
                                        <p style={{ fontSize: "1.318vw", color: "black" }}>{createLeadData && createLeadData.data.customer_id__c ? createLeadData.data.customer_id__c : "--"}</p>
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12 mt-3 ">
                                <div className="d-block text-center mb-3 ml-2">
                                    <Button onClick={this.closeModel} variant="secondary" className="btn btn-dark">Continue</Button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </form>
                </Modal>
            </>
        )
    }

}

function mapStateToProps(state) {
    const { success_show,create_lead_success,createLeadData } = state.model;
    const { lead_limit, lead_name } = state.user
    return {
        success_show,
        lead_name,
        lead_limit,
        create_lead_success,
        createLeadData
    };
}

export default connect(mapStateToProps)(AddedLeadModel)
import React from 'react'
import { getMerchantProductsByStatus, merchentProductStatusUpdate, merchentProductSellingPriceUpdate, getSingleProductData, updateLoadingProductData, getMerchantProducts, getMerchantProductsWizr, setCourseDetails } from "../actions/user";
import Pagination from '@material-ui/lab/Pagination';
import { connect } from 'react-redux';
import TablePagination from '@mui/material/TablePagination';
import NoRecordsFound from './NoRecordsFound.component';
import tableSort from "../common/tableSort"
import { logOut } from '../actions/auth';
import { history } from '../helpers/history';



class InActiveProducts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sellingValue: "",
      products: {
        proData: []
      },
      page: 0,
      limit: 10,
      selectedInputTag: '',
      showInputTag: false,
      EditToggle: false,
      rowsPerPage: 10,
      serachPage: 0,
      searchLimit: 10,
      productData: [],
      showSortDown: false,
      showSortUp: false,

    }
  }

  componentDidMount() {
    this.getProductData()
    // let getProd = { merchant_id: this.props.user_id, status: "inactive", page: this.state.page+1, limit: this.state.limit,source:"B2C"}
    // this.props.dispatch(getMerchantProductsByStatus(getProd)).then((response) => {
    //     if (!response.responseCode) {
    //         this.setState({ DataSet: response });
    //     }
    // });
    if (this.props.products) {
      this.setState({ products: this.props.products })
    }
  }

  getProductData = async (page, limit) => {
    const { sfid } = this.props
    let getProd = {
      // merchant_id: this.props.user_id,
      merchant_id: sfid,
      page: page ? page : this.state.page + 1,
      limit: limit ? limit : this.state.limit,
      filter: { ...this.props.productFilter, productStatus: "Inactive" },
      //   filter: {
      //     search:"",
      //     // productStatus: "Active",  //Active, Inactive
      //     productStatus: "Inactive",
      //     course_type: "",
      //     course_delivery_mode:"", //Online, Offline
      //     medium_of_instruction__c:"",
      //     // listing_time: ["2023-07-01", "2023-08-01"]
      //     listing_time:"",
      // }
    }
    this.props.dispatch(getMerchantProductsWizr(getProd)).catch(error => {
      if (error.status === 401) {
        this.props.dispatch(logOut());
        history.push('/login');
      }
    })
    // .then((response) => {
    //   if (!response.responseCode) {
    //     this.setState({ DataSet: response });
    //   }
    // });
  }

  editprice = async (inputIs) => {
    this.setState({ EditToggle: inputIs })
    await this.setState({ showInputTag: true });
    document.getElementById(inputIs).focus();
    this.setState({ selectedInputTag: inputIs })
  }


  handleChangePage = (event, value) => {
    let getProd = { merchant_id: this.props.user_id, page: value, limit: this.state.limit, status: "inactive", source: "B2C" }
    this.setState({ page: value });
    this.props.dispatch(getMerchantProductsByStatus(getProd));
  }

  handleChangelimitOfPage = (event) => {
    let perRowData = event.target.value;
    let getProd = { merchant_id: this.props.user_id, page: this.state.page, limit: perRowData, status: "inactive", source: "B2C" }
    this.setState({ limit: perRowData });
    this.props.dispatch(getMerchantProductsByStatus(getProd));
  }


  updateSellingPrice = (item) => {
    let getProd = { merchant_id: this.props.user_id, product_id: item.sfid, new_selling_price: this.state.sellingValue, "section": "Product Details" }
    this.props.dispatch(merchentProductSellingPriceUpdate(getProd)).then((response) => {
      if (!response.responseCode) {
        let get_products = { merchant_id: this.props.user_id, status: "inactive", page: this.state.page + 1, limit: this.state.limit, source: "B2C" }
        this.props.dispatch(getMerchantProductsByStatus(get_products)).then((response) => {
          if (!response.responseCode) {
            //this.setState({ products: [] });
            this.setState({ products: response });
            this.setState({ EditToggle: '' })

          }
        });
      }
    })
    this.setState({ sellingValue: "", showInputTag: false })

  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.products != this.props.products) {
      this.setState({ products: this.props.products, productData: this.props.products.proData })
    }
    if (prevProps.globalseraching != this.props.globalseraching) {
      this.setState({ serachPage: 0, searchLimit: 10 })
    }
    if (prevProps.resetPage != this.props.resetPage) {
      this.setState({ page: 0, limit: 10 })
    }

  }

  handleSatusChange = (item) => {
    let merchantId = localStorage.getItem('user_id')
    let getProd = {}
    let status = ''
    if (!item.activate_product__c) {
      status = 'true'
    } else {
      status = 'false'
    }
    getProd.merchant_id = merchantId
    getProd.product_id = item.sfid
    getProd.status = status


    this.props.dispatch(merchentProductStatusUpdate(getProd)).then((response) => {
      if (!response.responseCode) {
        let get_products = { merchant_id: this.props.user_id, status: "inactive", page: this.state.page + 1, limit: this.state.limit, source: "B2C" }
        this.props.dispatch(getMerchantProductsByStatus(get_products)).then((response) => {
          if (!response.responseCode) {
            this.setState({ products: response });
          }
        });
      }
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.load_product_data != nextProps.load_product_data) {
      let get_products = { merchant_id: this.props.user_id, status: "inactive", page: this.state.page + 1, limit: this.state.limit, source: "B2C" }
      this.props.dispatch(getMerchantProductsByStatus(get_products)).then((response) => {
        if (!response.responseCode) {
          //this.setState({ products: [] });
          this.setState({ products: response });
          this.props.dispatch(updateLoadingProductData(false));

        }
      });
      return true
    }
    else {
      return true
    }
  }

  handleChangePageNew = (event, newPage) => {
    // if (this.props.globalseraching) {
    //   this.setState({ serachPage: newPage })
    //   return;
    // }

    let getProd = { merchant_id: this.props.user_id, status: "inactive", page: newPage + 1, limit: this.state.limit, source: "B2C" }

    // let getProd = { merchant_id: this.props.user_id, status: "inactive", page: this.state.page, limit: this.state.limit, }
    // this.props.dispatch(getMerchantProductsByStatus(getProd))
    this.getProductData(newPage + 1)
    this.setState({ page: newPage })
  };

  handleChangeRowsPerPage = (event) => {
    // if (this.props.globalseraching) {
    //   this.setState({ searchLimit: parseInt(event.target.value, 10), serachPage: 0, rowsPerPage: parseInt(event.target.value, 10) });
    //   return;
    // }

    const { sfid } = this.props
    this.setState({ limit: parseInt(event.target.value, 10), page: 0, rowsPerPage: parseInt(event.target.value, 10) });
    let getProd = { merchant_id: this.props.user_id, status: "inactive", page: 1, limit: parseInt(event.target.value, 10), source: "B2C" }
    // this.props.dispatch(getMerchantProductsByStatus(getProd))
    this.getProductData(1, parseInt(event.target.value, 10))
  };

  sortBy = (givenArray, sortKey, order) => {
    const sortedData = tableSort(givenArray, sortKey, order);
    this.setState({ productData: sortedData })
    if (order === 1) {
      this.setState({ showSortUp: true, showSortDown: false })
    } else {
      this.setState({ showSortDown: true, showSortUp: false })
    }
  }

  HandleCourseDetails = (item) => {
    this.props.dispatch(setCourseDetails(item, "Inactive"))
  }


  render() {
    const { globalSearch, globalseraching, activeWizrTab, searchedKeyword } = this.props
    const { products, page, limit, searchLimit, serachPage, productData, showSortUp, showSortDown } = this.state;
    // const products ={proData:["a","a","a","a","a","a","a","a","a","a"]}
    //   const products ={proData:[]}
    // let showPagination = false;
    //     const totalPages = products.inactive_product_count ? Math.ceil(products.inactive_product_count / Number(this.state.limit)) : 1;
    // let showPageination = false;
    let totalPages = 1;
    // if(globalseraching && globalSearch && globalSearch.length > 0){
    //   showPageination = true;
    //   totalPages =  globalSearch ? Math.ceil(globalSearch.length / Number(this.state.searchLimit)) : 1;

    // }
    if (productData && productData.length > 0) {
      // showPageination = true;
      totalPages = products.inactive_product_count ? Math.ceil(products.inactive_product_count / Number(this.state.limit)) : 1;

    }

    return (
      <>
        <div className="table-responsive">
          {globalseraching && <div className='search-result-text'>{`${products ? products.inactive_product_count : 0} Results found for "${searchedKeyword}"`}</div>}

          <table
            className="table tableStyle productTable"
            // id="dataTable"
            cellSpacing={0}
          >
            <thead>
              <tr>
                <th width="3%" className='appHead-headinghash'>
                  #
                </th>
                <th width="47%" className='appHead-heading' style={{ width: '450px', paddingLeft: '60px' }}>Course Details</th>
                <th width="15%" className='appHead-heading'>Course Type</th>
                {/* <th width="15%" className='appHead-heading d-flex align-items-center'>Course Fee
                     <div className="d-flex flex-column ml-1">
                <button className={this.state.showSortUp?"up active": "up"} onClick={() => this.sortBy(products.proData, 'mrp__c', 1)}></button>
                   <button className={this.state.showSortDown ? "down active":"down"} onClick={() => this.sortBy(products.proData, 'mrp__c', -1)}></button>
                     </div>
                </th> */}

                <th width="15%">
                  <div className="d-flex align-items-center appHead-heading ">Course Fee
                      <div className="d-flex flex-column ml-1">
                      <button className={this.state.showSortUp ? "up active" : "up"} onClick={() => this.sortBy(products.proData, 'offer_price__c', 1)}></button>
                      <button className={this.state.showSortDown ? "down active" : "down"} onClick={() => this.sortBy(products.proData, 'offer_price__c', -1)}></button>
                    </div>
                  </div>
                </th>
                {/* <th className='fz14'>Course Type</th>
                 <th className='fz14'>Course Fee</th> */}
                <th width="10%" className='fz14'>
                  <div className="d-flex align-items-center appHead-heading">Status
                    <div className="d-none">
                      <button className="up "></button>
                      <button className="down"></button>
                    </div>
                  </div>
                </th>
                <th width="10%" className='appHead-heading text-right'>Edit Info</th>

              </tr>
            </thead>
            <tbody id="all_table_body">
              <>

                {productData && productData.length > 0 ?
                  (
                    productData.map((item, index) => {
                      const lastSevenDay = new Date(new Date().getTime() - 7*86400000);
                      const courseCreateDate = new Date(item.createddate).getTime()
                       const isNewCourse = lastSevenDay <= courseCreateDate? true:false;

                       return(

                      <tr className="shown trhover bank-tr" key={index} data-toggle="modal"
                        data-target="#myModal22"
                        // onClick={() => this.props.dispatch(getSingleProductData({ merchant_id: this.props.user_id, product_id: item.sfid }))}
                        onClick={() => this.HandleCourseDetails(item)}
                      >
                        <td className='fz14'>
                          <div className="text-center">
                            {/* <div className="single_check"> */}
                            <div className="">

                              <label className='appIDnum'>{(page * limit) + (index + 1)}</label>
                            </div>

                          </div>
                        </td>
                        <td
                        >
                          <div className='coursenewlabel'>
                            {/* <div className="sale_ribbon">Sale</div>
                  <div className="new_ribbon">New</div> */}
                            <div className={`new-course-button ${isNewCourse ? "":"invisible"}`}>NEW</div>
                            <p className='appIDCourseName appTextline ml-0'>{item?.product_name ? item.product_name : "-"}</p>
                          </div>

                        </td>
                        <td className='ml-1 appIDProfile'>
                          {item?.course_delivery_mode__c ? item.course_delivery_mode__c : "-"}
                        </td>

                        <td>
                          {/* <p className='applicantName'>₹1,40,000</p> */}
                          <p className='applicantName'>{item?.offer_price__c ? `₹ ${item.offer_price__c.toLocaleString('en-IN')}` : "-"}</p>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">

                            <div className="d-flex align-items-center" >
                              <span className={(item?.activate_product__c === "Active Pending" || item?.activate_product__c === "Inactive Pending") ? "grey-circle" : item?.activate_product__c === "Inactive" ? 'red-circle ' : "green-circle"}></span>
                              <span className='applicantName maxContentwidth'> {item.activate_product__c}</span>
                            </div>
                          </div>
                        </td>
                        <td><i className="fa fa-angle-right float-right cursor-point size20"
                    
                          data-toggle="modal"
                          data-target="#myModal22">

                        </i>
                        </td>

                      </tr>
                    )})
                  ) : <td colSpan="10"><NoRecordsFound message={globalseraching? searchedKeyword :'No Data to show '} show_searched={globalseraching} /></td>
                }

              </>
            </tbody>
          </table>
          {/* </div> */}
          <div className="d-flex align-items-center justify-content-lg-end justify-content-center mb-4">
            {/* <div className="d-flex align-items-center row_per_page mr-lg-5 mr-4">
                        <p>Row per page</p>
                        <select page={this.state.page} onChange={this.handleChangelimitOfPage}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                        </select>
                    </div>
                    <div className="d-flex align-items-center pages">
                        <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} />

                    </div> */}
            {products && products.inactive_product_count && <div className="d-flex align-items-center pages">
              {/* <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} /> */}
              <TablePagination
                component="div"
                count={products.inactive_product_count}
                page={this.state.page}
                onPageChange={this.handleChangePageNew}
                rowsPerPage={this.state.limit}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 20, 50, 100]}
                labelDisplayedRows={({ page }) => {

                  return `Page: ${this.state.page + 1} of ${totalPages} `;

                }}
              />
            </div>}
          </div>
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  const { user_id, sfid } = state.auth;
  const { load_product_data, globalSearch, globalseraching, activeWizrTab, searchedKeyword } = state.user;


  return {
    user_id,
    load_product_data,
    globalSearch,
    globalseraching,
    activeWizrTab,
    searchedKeyword,
    sfid
  };
}

export default connect(mapStateToProps)(InActiveProducts)

import React from 'react'
import { connect } from "react-redux";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import TopBarWizrHelpNotification from './TopBarWizrHelpNotification';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom'
import CenteredPopUp from '../common/CenteredPopUp';
import { Modal } from "react-bootstrap"
import { CustomerSendHelpRequest, writeToUsWizr, logout } from '../actions/user';
import { history } from '../helpers/history';

class NewHelp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            showCenteredPopUp: false,
            selectedqueryType: 'Select Query',
            showWriteUsCenteredPopUp: false,
            showThanksCenteredPopUp: false,
            formSubject: "",
            formDiscription: "",
            isErrorName: false,
            errorname: '',
            isErrorNameDesc: false,
            errorNameDesc: ''
        };

    }
    handleChange = (panel) => (event, isExpanded) => {
        this.setState({
            expanded: isExpanded ? panel : false
        })
    };

    closewriteUscenterPopUp = () => {
        this.setState({ showWriteUsCenteredPopUp: false, selectedqueryType: "Select Query",isErrorName: false,errorname: '',isErrorNameDesc: false,errorNameDesc: '' })
    }
    closeThankscenterPopUp = () => {
        this.setState({ showThanksCenteredPopUp: false })
    }
    pageTop() {
        window.scrollTo(0, 0)
    }
    handleFormSelect = (event) => {
        this.setState({ formQueryType: event.target.value });
    }

    handleDiscription = (event) => {
        this.setState({ formDiscription: event.target.value });
    }
    handleSubmitForm = () => {

        let sfid = localStorage.getItem('sfid');
        const { formDiscription, selectedqueryType, formSubject } = this.state;
        let obj = {
            merchant_id: sfid,
            issue_type: selectedqueryType,
            subject: formSubject,
            description: formDiscription
        }
        if (obj.description != "" && obj.subject != "") {
            this.props.dispatch(writeToUsWizr(obj)).then((res) => {
                if (res.status === 'success') {
                    // this.setState({ helpFormOpen: false });
                    // document.getElementById("disc").value = ""
                    // this.setState({ formQueryType:"Order Related" });
                    // this.setState({ formSubject: "" });
                    // this.setState({ formDiscription: "" });
                    this.setState({ showWriteUsCenteredPopUp: false, showThanksCenteredPopUp: true, selectedqueryType: "Select Query" })

                } else {
                    if (res.status == 401) {
                        this.props.dispatch(logout());
                        history.push('/login');
                    }
                }
            });
        }
        else {
            alert("Subject and Description cannot be empty")
        }

        // this.setState({ selectedqueryType: "" });
        this.setState({ formSubject: "" });
        this.setState({ formDiscription: "" });
    }

    handleformChange = (e) => {
        if (e.target.value.length <= 4) {
            this.setState(
                { isErrorName: true, errorname: "Please enter atleast 5 characters" }
            );
        } else {
            this.setState({ formSubject: e.target.value, isErrorName: false, errorname: '' });

        }
    }
    handledescChange = (e) => {
        if (e.target.value.length <= 4) {
            this.setState(
                { isErrorNameDesc: true, errorNameDesc: "Please enter atleast 5 characters" }
            );
        } else {
            this.setState({ formDiscription: e.target.value, isErrorNameDesc: false, errorNameDesc: '' });

        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { expanded } = this.state;
        return (
            <>
                <a onClick={this.pageTop} style={{ cursor: 'pointer' }} className="scroll-to-top " href={void (0)}>
                    <img src='images/backToTop.svg' />
                </a>
                <div className='mainhelpNav'>
                    <Link to='/home'><img src="./img/WiZRLogoBlack.png" /></Link>
                    <TopBarWizrHelpNotification />
                </div>
                <div className="nh-main-div">

                    {/* main head */}
                    <div className="nh-top-sec">
                        <p className="nh-top-head">Hi, How can we help?</p>
                        <div className="nh-data-sec">
                            <a className="nh-data-cont" href='#Manage'>
                                <div className="nh-image-div">
                                    <img src="images/icons/account.svg" className="nh-image" alt="icon" />
                                </div>
                                <p className="nh-sub-head">Managing My<br />Account</p>
                                <p className="nh-sub-grey">Information on account management<br/>and settings.</p>
                            </a>
                            <a className="nh-data-cont" href='#Dashboard'>
                                <div className="nh-image-div">
                                    <img src="images/icons/dashboard.svg" className="nh-image" alt="icon" />
                                </div>
                                <p className="nh-sub-head">Dashboard<br />Features Related</p>
                                <p className="nh-sub-grey">FAQs about features and functionalities<br/>on the dashboard.</p>
                            </a>
                            <a className="nh-data-cont" href='#Glossary'>
                                <div className="nh-image-div">
                                    <img src="images/icons/glossy.svg" className="nh-image" alt="icon" />
                                </div>
                                <p className="nh-sub-head single-head">Glossary</p>
                                <p className="nh-sub-grey">Clarifications on terms and definitions<br/>used in the system.</p>
                            </a>
                        </div>
                    </div>

                    {/* 1st question section */}
                    <div className="nh-que-cont" id="Manage" >
                        <p className="nh-que-head">Managing My Account</p>
                        <Accordion expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <p className="acc-head">How can I add/ deactivate user?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    You can deactivate the user by: <br />
                                    1. Select settings <br />
                                    2. Click on the tab of user role <br />
                                    3. Click on active toggle.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={this.handleChange('panel2')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <p className="acc-head">How can I change the user role of the existing user?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    To change the user role of existing user:<br />
                                    1. Click on the setting<br />
                                    2. Click on the user role management<br />
                                    3. Click on the edit icon
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={this.handleChange('panel3')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <p className="acc-head">How do I add my bank account?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    To add a bank account:<br />
                                    1. Select Settings<br />
                                    2. Click on an add bank account tab<br />
                                    3. Click on add bank account<br />
                                    4. Complete the process and verify the bank details.<br />
                                    The bank account will be successfully added.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={this.handleChange('panel4')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                            >
                                <p className="acc-head">How can I add a product?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    If SKU is already available, You can add a product by:<br />
                                    1. Click on products tab<br />
                                    2. Click on add new product.<br /><br />

                                    If the SKU is not already available, you can add a new product by:<br />
                                    1. Click on Product Tab<br />
                                    2. Click on add new products<br />
                                    3. Select Can't find your Product<br />
                                    4. Submit the details of the product to get it approved.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel5'} onChange={this.handleChange('panel5')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel5bh-content"
                                id="panel5bh-header"
                            >
                                <p className="acc-head">How can I modify my product details?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    To modify the Product details:<br />
                                    1. Click on the products tab<br />
                                    2. Edit the product you want to<br />
                                    OR<br />
                                    You can bulk update the products by:<br />
                                    1. Click on the bulk update icon on the top of products table<br />
                                    2. Upload an Excel file containing all the product details (template of the same can be downbloaded from the system).
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel6'} onChange={this.handleChange('panel6')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel6bh-content"
                                id="panel6bh-header"
                            >
                                <p className="acc-head">How can I update my GST no.?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. You can update your GST No. by clicking on the profile
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel7'} onChange={this.handleChange('panel7')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel6bh-content"
                                id="panel6bh-header"
                            >
                                <p className="acc-head">How do I log in to the portal?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. To login into the portal, Select "Merchant" form the top row and after that you just have to enter the following details.<br />
                                    2. Name<br />
                                    3. Mail Id<br />
                                    4. Mobile No.<br />
                                    5. Brand Name<br />
                                    6. Category and sub category of your product<br />
                                    7. Bank details
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel8'} onChange={this.handleChange('panel8')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel6bh-content"
                                id="panel6bh-header"
                            >
                                <p className="acc-head">How can I Register myself as a Merchant?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. For getting yourself registered as Merchant you just have to upload the following:<br />
                                    2. Name<br />
                                    3. Mail Id<br />
                                    4. Mobile No.<br />
                                    5. Brand Name<br />
                                    6. Category and sub category of your product<br />
                                    7. Bank details
                                </p>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    {/* 2nd question section */}
                    <div className="nh-que-cont" id="Dashboard">
                        <p className="nh-que-head">Dashboard Features Related</p>
                        <Accordion expanded={expanded === 'panel11'} onChange={this.handleChange('panel11')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel11bh-content"
                                id="panel11bh-header"
                            >
                                <p className="acc-head">Where can I find the lead I created?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. Select "lead" from the side bar, which will display all your leads
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel21'} onChange={this.handleChange('panel21')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel21bh-content"
                                id="panel21bh-header"
                            >
                                <p className="acc-head">Can I edit the application?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. To edit the application submitted<br />
                                    2. Select “Leads”<br />
                                    3. Select the application to edit<br />
                                    4. On the Right top of the screen you can find editing option<br />
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel31'} onChange={this.handleChange('panel31')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel31bh-content"
                                id="panel31bh-header"
                            >
                                <p className="acc-head">Can I customise reports?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. Yes you can customise your reports according to Date of upload, Brand, etc.<br />
                                    2. Select "Leads" from the Dashboard<br />
                                    3. Select "Filter(Symbol)" from the top row
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel41'} onChange={this.handleChange('panel41')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel41bh-content"
                                id="panel41bh-header"
                            >
                                <p className="acc-head">How can I download/email my own report?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. Yes, you can get your Reports on Email.<br />
                                    2. Select “leads”<br />
                                    3. Selecting the envelope sign on the right top, will enable you to get your reports by email.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel51'} onChange={this.handleChange('panel51')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel51bh-content"
                                id="panel51bh-header"
                            >
                                <p className="acc-head">How can I find the payment details against each application?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    To find details of each product:<br />
                                    1. You can click on Leads<br />
                                    2. Select the application to view
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel61'} onChange={this.handleChange('panel61')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel61bh-content"
                                id="panel61bh-header"
                            >
                                <p className="acc-head">How can I configure my reports?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    You can configure all your reports by:<br />
                                    1. Select “Leads”<br />
                                    2. Select “Filter” from the top row to arrange all the reports in the desired manner.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel71'} onChange={this.handleChange('panel71')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel71bh-content"
                                id="panel71bh-header"
                            >
                                <p className="acc-head">Can I get my reports on mail?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. Yes, you can get your Reports on Email.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel81'} onChange={this.handleChange('panel81')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel81bh-content"
                                id="panel81bh-header"
                            >
                                <p className="acc-head">How can I make/edit email groups?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    You can edit or make a new email group by:<br />
                                    1. Select ”Lead”<br />
                                    2. Selecting the envelope sign on the right top, will enable you with an option to make a new email group or edit the existing email group.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel91'} onChange={this.handleChange('panel91')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel91bh-content"
                                id="panel91bh-header"
                            >
                                <p className="acc-head">How can I Whitelist a particular case?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    You can whitelist a particular case by:<br />
                                    1. Selecting “Product”<br />
                                    2. In Filter section, you can find an option to whitelist a Product
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel101'} onChange={this.handleChange('panel101')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel101bh-content"
                                id="panel101bh-header"
                            >
                                <p className="acc-head">How can I sent an approval for DRF?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. DRF is Disbursement request form. You have to fill the form of your bank either online or offline. Once the form is filled you can submit it to the bank for approval.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel111'} onChange={this.handleChange('panel111')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel111bh-content"
                                id="panel111bh-header"
                            >
                                <p className="acc-head"> How can I create a Customised tab for viewing my leads?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    You can customise your leads by:<br />
                                    1. Select "Leads" from the Dashboard<br />
                                    2. Select "Filters(Symbol)" from the top Row to get your leads customised according to Date of upload, Brand, Category, etc.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel121'} onChange={this.handleChange('panel121')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel121bh-content"
                                id="panel121bh-header"
                            >
                                <p className="acc-head"> How can I find the summary of the application?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    Summary of the application can be checked by:<br />
                                    1. Select "Leads"<br />
                                    2. Select the application to be viewed
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel131'} onChange={this.handleChange('panel131')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel131bh-content"
                                id="panel6bh-header"
                            >
                                <p className="acc-head"> How can I check my cancelled loans?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    You can check your canceled loans by:<br />
                                    1. Selecting “Settlements”<br />
                                    2. Select “Cancellation requests” from the top bar
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel141'} onChange={this.handleChange('panel141')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel141bh-content"
                                id="panel141bh-header"
                            >
                                <p className="acc-head">How can I check the status of refunds initiated to Eduvanz?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    You can check your refund status by:<br />
                                    1. Select “Settlements”<br />
                                    2. Then select “Refunds” to check the status of your refunds.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel151'} onChange={this.handleChange('panel151')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel151bh-content"
                                id="panel151bh-header"
                            >
                                <p className="acc-head"> What to do if the customer does the upfront payment to me directly?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. If the cusotmer Pays you directly, that amount is deducted from the amount disbursed from Stride to you.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    {/* 3rd question section */}
                    <div className="nh-que-cont" id="Glossary">
                        <p className="nh-que-head">Glossary</p>
                        <Accordion expanded={expanded === 'panel12'} onChange={this.handleChange('panel12')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel12bh-content"
                                id="panel12bh-header"
                            >
                                <p className="acc-head">What is whitelisting?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. White list is a list of products which are trusted, known or explicitly permitted.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel22'} onChange={this.handleChange('panel22')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel22bh-content"
                                id="panel22bh-header"
                            >
                                <p className="acc-head">Why do we need to upload bulk leads?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. It is not mandatory for you to upload bulk leads, you can upload single lead as well. But when you have to upload a long list of products, you can simply add an excel sheet, which contains all the required details of the product.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel32'} onChange={this.handleChange('panel32')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel32bh-content"
                                id="panel32bh-header"
                            >
                                <p className="acc-head"> What is the use of configuring reports?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. You can use the option to line your products according to date or month or value or approval status etc.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel42'} onChange={this.handleChange('panel42')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel42bh-content"
                                id="panel42bh-header"
                            >
                                <p className="acc-head">What is the use of making a customised tab?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. By creating a customised tab you can view the products according to their Date of upload, Brands, category, etc.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel52'} onChange={this.handleChange('panel52')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel52bh-content"
                                id="panel52bh-header"
                            >
                                <p className="acc-head"> How will the dashboard help me in business?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. Dashboard is the place where you can find all your product as listed with their status, approved or not. at the same time on dashboard you ca bulk upload all your products that you want to list on Stride.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel62'} onChange={this.handleChange('panel62')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel62bh-content"
                                id="panel62bh-header"
                            >
                                <p className="acc-head">What does the pre-approval and post-approval mean?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. The product/s that you will upload on Stride, will be checked from our end. Till your product is under review, it will be shown under "Pre approval". Once the product is approved by our team, it will be visible under " Post Approved".
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel72'} onChange={this.handleChange('panel72')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel72bh-content"
                                id="panel72bh-header"
                            >
                                <p className="acc-head">Where can I cancel  the listing of a product?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    You can cancel the listing of a product by:<br />
                                    1. Select "settlements" from the Dashboard<br />
                                    2. Select "Cancellation Request" from the top row.<br />
                                    3. Then approve the products that you wist to delist/cancel.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel82'} onChange={this.handleChange('panel82')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel82bh-content"
                                id="panel82bh-header"
                            >
                                <p className="acc-head">What if stride fails to verify my bank account?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    If we at Stride fail to verify your  account, then you have to upload either of:<br />
                                    1. Bank Statement<br />
                                    2. Cancelled Cheque<br />
                                    3. Bank Passbook
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel92'} onChange={this.handleChange('panel92')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel92bh-content"
                                id="panel92bh-header"
                            >
                                <p className="acc-head">How to add new stock of product when it runs out?</p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    If want to refill the products listed:<br />
                                    1. Select Products<br />
                                    2. Select the product you want to refill<br />
                                    3. Edit the Stock of the required Product
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel102'} onChange={this.handleChange('panel102')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel102bh-content"
                                id="panel102bh-header"
                            >
                                <p className="acc-head">How to turn inactive orders live?  </p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    If you want the product to go live:<br />
                                    1. Select Products<br />
                                    2. Turn on the activation status for the required product
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel112'} onChange={this.handleChange('panel112')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel112bh-content"
                                id="panel112bh-header"
                            >
                                <p className="acc-head">How to edit the offer on a product?  </p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    You can change the offer on the product by:<br />
                                    1. Select Products<br />
                                    2. Click on the SKU of the product you want to edit.<br />
                                    3. You can create new offer or make any of the previous offers go live.

                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel122'} onChange={this.handleChange('panel122')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel122bh-content"
                                id="panel122bh-header"
                            >
                                <p className="acc-head">Where will I find out of stock items?  </p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. You can find, out of Stock items by selecting 'Products'.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel132'} onChange={this.handleChange('panel132')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel132bh-content"
                                id="panel132bh-header"
                            >
                                <p className="acc-head">Can can I edit the offer on a products?  </p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. Yes, you can edit the offer on listed product.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel142'} onChange={this.handleChange('panel142')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel142bh-content"
                                id="panel142bh-header"
                            >
                                <p className="acc-head"> How can get to check the Leads with pending application?  </p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. You can check the leads with pending applications at Pre approval section in Leads.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel152'} onChange={this.handleChange('panel152')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel152bh-content"
                                id="panel152bh-header"
                            >
                                <p className="acc-head"> What do you mean by Lead?  </p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. Leads are your customers who want to buy the products at easy and low EMIs. You have to register the customer on Stride website as Leads, and after confirming their eligibity , we disburse the loan amount to you.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel162'} onChange={this.handleChange('panel162')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel162bh-content"
                                id="panel162bh-header"
                            >
                                <p className="acc-head"> If my customer is below 21 years of age, what do I do?  </p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. If the buyer is below the age of 21, then you have to register a  Co-borrower in order to avail a credit amount.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel172'} onChange={this.handleChange('panel172')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel172bh-content"
                                id="panel172bh-header"
                            >
                                <p className="acc-head"> What if my customer's E-Mandate fails?  </p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. If  resitration for the E-Mandate fails due to some reasons, customer can download a NACH Mandate form  and after signing it, one can upload it.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel182'} onChange={this.handleChange('panel182')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel182bh-content"
                                id="panel182bh-header"
                            >
                                <p className="acc-head"> Where can I file my query?  </p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    To raise a  query:<br />
                                    1. Select Lead<br />
                                    2. Click on any lead<br />
                                    3. On the right top, select (Symbol).
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel192'} onChange={this.handleChange('panel192')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel192bh-content"
                                id="panel192bh-header"
                            >
                                <p className="acc-head"> Do I need an approval before uploading a product?  </p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    1. If SKU of the product is already available on Stride, then no approval is required.<br />
                                    2. When SKU is not available on Stride, then the product will be reviewed by our team and then it will go live.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel202'} onChange={this.handleChange('panel202')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel202bh-content"
                                id="panel202bh-header"
                            >
                                <p className="acc-head"> How to complete my incomplete application?    </p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    For completing your pending application :<br />
                                    1. Select "Leads"<br />
                                    2. Click on the  required Lead
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel212'} onChange={this.handleChange('panel212')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel212bh-content"
                                id="panel212bh-header"
                            >
                                <p className="acc-head"> How can I approve the application for loans cancellation?    </p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    For approving  or rejecting the loan cancellation request:<br />
                                    1. Select 'Settlements'<br />
                                    2. Click on 'Cancellation Requests' from the top row<br />
                                    3. Approve or reject the required applications
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel222'} onChange={this.handleChange('panel222')} className="acc-div">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='downarrow' />}
                                aria-controls="panel222bh-content"
                                id="panel222bh-header"
                            >
                                <p className="acc-head"> How can I edit the role of the lead?    </p>
                            </AccordionSummary>
                            <AccordionDetails className='accdesclist'>
                                <p className='acc-desc'>
                                    To edit the Role of the Lead:<br />
                                    1. Go to settings<br />
                                    2. On the extreme left , select(Symbol)<br />
                                    3. Edit the required roles
                                </p>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    {/* write to us */}
                    <div className="wtu-div">
                        <div className="wtu-text-div">
                            <p className="wtu-text">Still need help?</p>
                        </div>
                        <div className="product_page">
                            <button className="nav_icons nav_download btn_style d-inline-block ml-3" onClick={() => this.setState({ showWriteUsCenteredPopUp: true })}>Write to us</button>
                        </div>
                    </div>

                    <CenteredPopUp show={this.state.showWriteUsCenteredPopUp} closePopUp={this.closewriteUscenterPopUp} getWidthclass="w432" >
                        <div className='LeadApprovedModal NewModalLeadWrite'>


                            <h1 className='writetoUsModalTitle text-justify'>Write us on</h1>
                            <div className='writeUS'>
                                <div class="dropdown">
                                    <label className="writeuslabel text-left required" for="QueryType" >Query Type</label>
                                    <button type="button" className={`btn d-flex justify-content-between w-100 align-items-center btn-primary dropdown-toggle newDropdownToggle ${this.state.selectedqueryType != "Select Query" && "newDropToggleSel"}`} data-toggle="dropdown">
                                        {this.state.selectedqueryType}
                                    </button>
                                    <div class="dropdown-menu w-100" style={{ padding: "0px" }}>
                                        <a class="dropdown-item" onClick={() => this.setState({ selectedqueryType: 'Order Related' })}>Order Related</a>
                                        <hr className="drop-border" />
                                        <a class="dropdown-item" onClick={() => this.setState({ selectedqueryType: 'General Issues' })}>General Issues</a>
                                        <hr className="drop-border" />
                                        <a class="dropdown-item" onClick={() => this.setState({ selectedqueryType: 'Legal Terms' })}>Legal Terms</a>
                                        <hr className="drop-border" />
                                        <a class="dropdown-item" onClick={() => this.setState({ selectedqueryType: 'Patner Onboarding' })}>Partner Onboarding</a>
                                        <hr className="drop-border" />
                                        <a class="dropdown-item" onClick={() => this.setState({ selectedqueryType: 'Conditions as per help' })}>Conditions as per help</a>
                                        <hr className="drop-border" />
                                        <a class="dropdown-item" onClick={() => this.setState({ selectedqueryType: 'Support Screen' })}>Support Screen</a>
                                    </div>
                                </div>
                                <label className="writeuslabel text-left required" for="exampleInputEmail1">Subject</label>
                                <input type="subject" class="form-control" className='writeusSubject' onChange={this.handleformChange} placeholder="" />
                                {this.state.isErrorName && this.state.errorname != '' && (
                                    <p style={{ color: "red" }} className="text-left">
                                        {this.state.errorname}
                                    </p>
                                )}
                                <label className="writeuslabel text-left required" for="Description">Description</label>
                                <textarea class="form-control" className='writeusDescription' rows="5" onChange={this.handledescChange}></textarea>
                                {this.state.isErrorNameDesc && this.state.errorNameDesc != '' && (
                                    <p style={{ color: "red" }} className="text-left">
                                        {this.state.errorNameDesc}
                                    </p>
                                )}
                                <div className='mt-4 d-flex justify-content-end'>
                                    <button className="btn  subBtn saveButton mb-5 " type="submit"
                                        // onClick={() => this.setState({ showWriteUsCenteredPopUp: false,showThanksCenteredPopUp:true })}
                                        disabled={this.state.selectedqueryType !== '' && this.state.selectedqueryType !== 'Select Query' && (this.state.formSubject.length >= 5 && !this.state.isErrorName) && (this.state.formDiscription.length >= 5 && !this.state.isErrorNameDesc) ? false : true}
                                        onClick={this.handleSubmitForm}
                                    >Send</button>
                                </div>
                            </div>
                        </div>
                    </CenteredPopUp>

                    <CenteredPopUp show={this.state.showThanksCenteredPopUp} closePopUp={this.closeThankscenterPopUp} getWidthclass="w4321" >
                        <div style={{ marginTop: "3.75rem" }}>
                            <img src="img/icon_thankYou.svg" style={{ width: "6.3125rem", height: "6.3125rem" }} />

                            <h1 className="thankyou_text">Thank you!</h1>
                            <p className='thankyou_subtext'>Your submission has been<br />received. Please check your<br />email for confirmation</p>
                        </div>
                        <div className='ok-btn mr-5 d-flex justify-content-end'>
                            <button className="writetoussend" type="button" onClick={() => this.setState({ showThanksCenteredPopUp: false })}>OK</button>
                        </div>
                    </CenteredPopUp>

                    {/* <Modal
                    show={this.state.showCenteredPopUp}
                    size='lg'
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='writeonUSModal'
                    
                > 
                    <Modal.Body>
                        <div className='writeonUSModalStructure'>
                        
                        <div className='d-flex justify-content-between ' >

                        <h1 className='writetoUsModalTitle'>Write on us</h1>
                        <img src="./images/icons/icon-close2.png" height="15px" className="mr-2 mt-n2 cursor-point" onClick={()=>this.setState({showCenteredPopUp:false})} />
                        
                        </div>
                 
                  <div className='writeUS'>
                  <div class="dropdown">
                  <label className="writeuslabel" for="QueryType">Query Type</label>
                        <button type="button" class="btn d-flex justify-content-between w-100 align-items-center btn-primary dropdown-toggle" data-toggle="dropdown">
                            {this.state.selectedqueryType}
                        </button>
                        <div class="dropdown-menu w-100">
                            <a class="dropdown-item" onClick={()=>this.setState({selectedqueryType:'Order Related'})}>Order Related</a>
                            <a class="dropdown-item" onClick={()=>this.setState({selectedqueryType:'General Issues'})}>General Issues</a>
                            <a class="dropdown-item" onClick={()=>this.setState({selectedqueryType:'Legal Terms'})}>Legal Terms</a>
                            <a class="dropdown-item" onClick={()=>this.setState({selectedqueryType:'Patner Onboarding'})}>Patner Onboarding</a>
                            <a class="dropdown-item" onClick={()=>this.setState({selectedqueryType:'Conditions as per help'})}>Conditions as per help</a>
                            <a class="dropdown-item" onClick={()=>this.setState({selectedqueryType:'Support Screen'})}>Support Screen</a>
                        </div>
                        </div>
                        <label className="writeuslabel" for="exampleInputEmail1">Subject</label>
                            <input type="subject" class="form-control" className='writeusSubject'  placeholder="" />
                                                                        
                            <label className="writeuslabel"   for="Description">Description</label>
                            <textarea class="form-control" className='writeusDescription'  rows="5"></textarea> 
                            <div className='mt-4 d-flex justify-content-end'>
                            <button className="writetoussend" type="button">Send</button>     
                            </div>
                    </div>                               
                        </div>

                    </Modal.Body>
                    
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal> */}



                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    const { FAQ_question } = state.user;
    return {
        FAQ_question
    };
}

export default connect(mapStateToProps)(NewHelp);
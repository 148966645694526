import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Modal, Button, Form } from "react-bootstrap"
import { closeLeadApplicationModel, openLeadProfileModel, openDropModel, closeDropModel, openQueryModel, closeQueryModel, openSuccessQueryModal, closeSuccessQueryModal, openLeadDropModal, closeLeadDropModal, closeQueryModelCred } from "../actions/model";
import {
    getLeadProfile, getAddress, getLeadProfileDocuemnt, getLeadPanDocuemnt, getLeadOtherDocuemnt,
    updateLoan, dropLead, raiseLeadQuery, merchantSettlementDetail
} from "../actions/user";
import dateFormat from 'dateformat';
import { Scrollbar } from "react-scrollbars-custom";

const initialState = {
    lead_sfid: null,
    username: '',
    userstatus: 'PENDING',
    owner_id: '',
    product: '',
    email: '',
    mobile: '',
    pan: null,
    card: '',
    dob: '',
    gender: '',
    pincode: '',
    loan_amount: '',
    selectedAddress: '',
    rent_amount: '',
    house: 0,
    company_name: '',
    monthly_income: '',
    profession: '',
    addressList: [],
    photo_verified: '',
    pan_verified: '',
    kyc_verified: '',
    created_date: '',
    profileType: '',
    profileBase: '',
    panType: '',
    panBase: '',
    frontProofType: '',
    frontProofBase: '',
    backProofType: '',
    backProofBase: '',
    frontFileType: 0,
    backFileType: 0,
    panFileType: 0,
    profileTitle: '',
    panTitle: '',
    frontTitle: '',
    backTitle: '',
    occupation: '',
    applicationStatus: '',
    showErr: false,
    loanerrmsg: '',
    showSuccess: false,
    showsuccessmsg: '',
    showErrdrop: false,
    droperrmsg: '',
    selectedReason: '',
    application_id: '-',
    showReceived:false,
    query_type:'Select Query'

};

class RaiseQueryCred extends Component {

    constructor() {
        super()
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);
    }



    async componentDidUpdate(prevProps) {
        if (prevProps.raise_query_cred !== this.props.raise_query_cred && this.props.raise_query_cred === true) {
            let data = {
                user_sfid: this.props.lead_id,
                opp_sfid: this.props.opp_id
            }
            await this.props.dispatch(getLeadProfile(data)).then((response) => {
                if (response.status === "success") {
                    let getData = response.data;
                    let account_profile = getData.account_profile ? getData.account_profile : '';
                    let gender = getData.gender__c ? getData.gender__c.toLowerCase() : '';

                    this.setState({
                        owner_id: getData.merchant_id,
                        lead_sfid: getData.opp_sfid ? getData.opp_sfid : '',
                        application_id: response.rowData.transaction_application_id__c ? response.rowData.transaction_application_id__c : '-',
                        username: getData.first_name__c,
                        userstatus: getData.account_status__c ? getData.account_status__c : 'PENDING',
                        product: getData.product_name ? getData.product_name : '-',
                        email: getData.email__c,
                        mobile: getData.phone,
                        pan: getData.pan_number__c,
                        card: 'XXXXXXX76A',
                        dob: new Date(getData.date_of_birth_applicant__c),
                        loan_amount: getData.mrp__c ? getData.mrp__c : '-',
                        pincode: getData.pin_code__c,
                        gender: getData.gender__c,
                        selectedAddress: account_profile ? account_profile.current_address : 0,
                        rent_amount: getData.rent_amount__c,
                        house: getData.rent_amount__c ? 2 : 1,
                        profession: getData.employer_type__c,
                        monthly_income: getData.monthly_income__c ? getData.monthly_income__c : '',
                        company_name: getData.employer_name__c ? getData.employer_name__c : '',
                        photo_verified: getData.is_photo_verified__c ? getData.is_photo_verified__c : '',
                        pan_verified: getData.is_pan_document_verified__c ? getData.is_pan_document_verified__c : '',
                        kyc_verified: getData.is_kyc_document_verified__c ? getData.is_kyc_document_verified__c : '',
                        created_date: getData.createddate ? getData.createddate : '',
                        occupation: getData.occupation__c ? getData.occupation__c : '',
                        applicationStatus: response.rowData.stage_title,
                    })
                }
            });
            await this.props.dispatch(getAddress(data)).then((response) => {
                if (response.status === "success") {
                    let getData = response.current_address;
                    this.setState({
                        addressList: getData,
                    })

                }
            });
            await this.props.dispatch(merchantSettlementDetail(this.props.opp_id));

            let proData = {
                sfid: this.props.lead_id,
            }
            await this.props.dispatch(getLeadProfileDocuemnt(proData)).then((response) => {
                if (response.status === "success") {
                    let getData = response.data;
                    if (getData.base64 !== undefined && getData.base64 !== "") {
                        this.setState({
                            profileBase: "data:image/jpg;base64," + getData.base64.base64,
                            profileType: "image/jpg",
                            profileTitle: getData.title
                        });
                    } else {
                        this.setState({
                            profileBase: "",
                            profileType: ""
                        });
                    }
                }
            });
            await this.props.dispatch(getLeadPanDocuemnt(proData)).then((response) => {
                if (response.status === "success") {
                    let getData = response.data;
                    if (getData.base64 !== undefined && getData.base64 !== "") {
                        let resData = getData.base64;
                        let type = 0;
                        let DocBase = ""
                        if (resData.filetype !== null) {
                            if (getData.filetype === "PDF") {
                                type = 2;
                                DocBase = "data:application/pdf;base64," + resData.base64;
                            } else {
                                type = 1;
                                DocBase = "data:image/jpg;base64," + resData.base64;
                            }

                        }
                        this.setState({
                            panFileType: type,
                            panBase: DocBase,
                            panType: resData.filetype ? resData.filetype : '',
                            panTitle: getData.title,
                        });
                    } else {
                        this.setState({ panBase: "", panType: "" });
                    }
                }
            });
            this.props.dispatch(getLeadOtherDocuemnt(proData)).then((response) => {
                if (response.status === "success") {
                    let getData = response.imageData && response.imageData;
                    for (let i = 0; i < getData.length; i++) {
                        if (getData[i].base64['Document Type'] === 'Aadhar Front')
                            this.setState({
                                // frontFileType: type,
                                frontProofBase: "data:image/jpg;base64," + getData[i].base64.base64,
                                frontProofType: 'image/jpg',
                                frontTitle: getData[i].title,
                            });

                    }





                }
            });
        }
    }


    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        this.setState({ selectedReason: event.target.value })
    }

    openRaiseQuery = () => {
        this.props.dispatch(openQueryModel());
    }

    openDrop = () => {
        this.props.dispatch(openDropModel());
    }

    handleLeadClose = () => {
        this.props.dispatch(closeDropModel());
    }

    handleQueryClose = () => {
        // this.props.dispatch(closeQueryModel());
        this.setState({showReceived:false,query_type:''})
        this.props.dispatch(closeQueryModelCred());
    }

    dropLead = () => {
        const { opp_id, dispatch } = this.props
        let obj = {
            oppertunity_sfid: opp_id,
            stage_name: 'Dropped',
            "reason_name": this.state.reason,
            "reason_description": this.state.description
        }
        dispatch(dropLead(obj)).then((response) => {
            if (response && response.status == "success") {
                // window.location = '/leads';
                this.setState({ showSuccess: true, showsuccessmsg: response.message })
                this.props.dispatch(openLeadDropModal());
                this.props.dispatch(closeDropModel());
            } else {
                if (response && response.status == "error") {
                    this.setState({ showErrdrop: true, droperrmsg: response.message })
                }
            }
        });
    }

    raiseQuery = (e) => {
        e.preventDefault();
        const { dispatch, lead_id } = this.props
        let obj = {
            user_sfid: localStorage.getItem('lead_id'),
            // "user_sfid": "001C4000002zE97IAE",
            "issue_type": this.state.query_type,
            "subject": "Order Enquiry",
            "description": this.state.quer_description
            // issue_type: Order,
            // subject: this.state.query_type, 
            // description: this.state.quer_description
        }
        dispatch(raiseLeadQuery(obj)).then((response) => {
            if (response && response.status == "success") {


                // window.location = '/leads';
                this.setState({ showSuccess: true, showsuccessmsg: response.message })
                // this.props.dispatch(closeQueryModel());
                // this.props.dispatch(openSuccessQueryModal());
                this.setState({showReceived:true})
            } else {
                if (response && response.status == "error") {
                    this.setState({ showErr: true, loanerrmsg: response.message })
                }
            }
        });
    }

    closeModalDrop = () => {
        this.props.dispatch(closeLeadDropModal());
        this.props.dispatch(closeLeadApplicationModel());
    }
    // openLeads = (sfid) => {
    //     this.props.dispatch(closeLeadApplicationModel());
    //     this.props.dispatch(openLeadProfileModel(sfid, this.props.L_id));
    // }

    // closeLeadApplicationModel = () => {
    //     this.setState(initialState);
    //     this.props.dispatch(closeLeadApplicationModel());
    // }

    render() {
        const { lead_application_show, lead_id, sfid, leade_drop, raise_query, orderSummary, showraiseQuerySuccess, showLeadDropSuccess, raise_query_cred } = this.props;
        const { dob, product, created_date, pan, photo_verified, pan_verified, kyc_verified, selectedAddress, gender, owner_id, username, userstatus, lead_sfid, email, mobile, card, loan_amount, pincode, monthly_income, frontProofBase, application_id } = this.state
        return (
            <>

                {/* <div show={lead_application_show} className="modal right fade myModal" id="myModal8" role="dialog">
                <div className="modal-dialog"> */}


                <Modal show={raise_query_cred} className="myModal raiseQueryModal" id="raiseQuery" role="dialog" size="md" contentClassName={`main-center-popup`}>
                    <div className="modal-dialog " style={{ borderRadius: '21px' }}>
                        {/* <div className='d-flex justify-content-end'>
                            <img src="./images/icons/icon-close2.png" height="15px" className="mr-4 cursor-point"  onClick={()=>console.log('hii')}/>

                        </div> */}
                        {/* Modal content*/}
                        
                        <form className="">
                            <div className="modal-content pl-5 pr-5">
                            {!this.state.showReceived ?  <div>
                                <div className='d-flex justify-content-end'>
                                    <img src="./images/icons/icon-close2.png" height="15px" className="mr-n2 cursor-point" onClick={this.handleQueryClose} />

                                </div>
                                <div className='d-flex'>
                                    <div className="raiseQuery">Raise Query</div>
                                    <div className='mt-2 raisedId ml-1'>{lead_id}</div>
                                </div>
                                <div className='mt-2 border-bottom pb-3'>
                                    <div className='raisedText'>Please select the query type below and </div>
                                    <div className='raisedText'>provide description</div>
                                </div>
                                {/* <button onClick={this.handleQueryClose} type="button" style={{ marginLeft: "20px" }} id="close-create" className="abs_close close" data-dismiss="modal"> <i className="fas fa-times"></i> </button> */}

                                {/* <div className="modal-header">
                                    // <button onClick={this.handleQueryClose} type="button" style={{ marginLeft: "20px" }} id="close-create" className="abs_close close" data-dismiss="modal"> <i className="fas fa-times"></i> </button>
                                    <div className='d-flex justify-content-between w-100'>
                                        <h5 className="modal-title fz24" style={{ marginLeft: "25px" }}>Raise Query OK</h5>
                                        <h6 className='ml-3' style={{ color: '#1824AC', fontWeight: 'bold' }}>{lead_sfid} </h6>
                                        {/* <button className='qst'>?</button> */}
                                {/* </div>
                                    <div className='ml-4' style={{ marginLeft: '10px' }}>
                                        <p>Please select the query type below</p>
                                        <p>and provide description.</p>
                                    </div>

                                </div>  */}

                                <div id="" className="modal-body pt-0 px-0">

                                    <div className=''>
                                        <>
                                            <div className="row justify-content-center mb-2 mt-4">
                                                <div className="col-sm-12">
                                                    <div className=''>
                                                        <div className="row justify-content-center mb-2">
                                                            <div className="col-sm-12 form-group ">
                                                                <label htmlFor='query_type' className="form-label raiseQueryText">
                                                                    Query Type
                                                                </label>

                                                                {/* <select
                                                                    name="query_type"
                                                                    id="query_type"
                                                                    className="form-control selectborder mt-3"
                                                                    onChange={this.handleChange}
                                                                    value={this.state.query_type ? this.state.query_type : ''}
                                                                >
                                                                    <option value=''>Select Any</option>
                                                                    <option value='Loan Approval'>Loan Approval</option>
                                                                    <option value='Document Upload'>Document Upload</option>
                                                                    <option value='Communication with Customer'>Communication with Customer</option>
                                                                    <option value='Applicaiton Process'>Applicaiton Process</option>
                                                                    <option value='NACH Mandate'>NACH Mandate</option>
                                                                    <option value='Agreement'>Agreement</option>
                                                                </select> */}
                                            <button type="button" class="btn d-flex justify-content-between w-100 align-items-center btn-primary dropdown-toggle mt-3 query" data-toggle="dropdown">
                                                    {this.state.query_type}
                                            </button>
                                            <div class="dropdown-menu w-100">
                                                    <a class="dropdown-item" onClick={()=>this.setState({query_type:'Loan Approval'})}>Loan Approval</a>
                                                    <a class="dropdown-item" onClick={()=>this.setState({query_type:'Document Upload'})}>Document Upload</a>
                                                    <a class="dropdown-item" onClick={()=>this.setState({query_type:'Communication with Customer'})}>Communication with Customer</a>
                                                    <a class="dropdown-item" onClick={()=>this.setState({query_type:'Applicaiton Process'})}>Applicaiton Process</a>
                                                    <a class="dropdown-item" onClick={()=>this.setState({query_type:'NACH Mandate'})}>NACH Mandate</a>
                                                    <a class="dropdown-item" onClick={()=>this.setState({query_type:'Agreement'})}>Agreement</a>
                                            </div>

                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center mb-2 mt-4">
                                                            <div className="col-sm-12 form-group">
                                                                <label className="form-label mb-3 descri">
                                                                    Description (Optional)
                                                                </label>
                                                                <textarea className="form-control textAreaStyle" onChange={this.handleChange} name="quer_description" id="exampleFormControlTextarea1" rows="4" placeholder=''></textarea>
                                                            </div>
                                                            {this.state.showErr &&
                                                                <div className="form-group"><div className='alert alert-danger' role='alert'>{this.state.loanerrmsg}</div></div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="row justify-content-end mb-2 mt-4">
                                                        <button
                                                            type="button"
                                                            className='btn btn-default_ btn btn-secondary'
                                                            onClick={this.raiseQuery}
                                                            disabled={this.state.query_type && this.state.quer_description ? false : true}
                                                        >Submit</button>
                                                    </div>

                                                </div>

                                            </div>
                                        </>

                                    </div>
                                </div>

                                

</div>:

<div className='text-center'>
                            <div> <img src="./images/receivedIcon.png" /></div>
                            <div className='queryReceived mt-4'>Query Received!</div>
                            <div className='querytext'>Our team will answer your</div>
                            <div className='querytext'>query shortly</div>
                            <div className='d-flex justify-content-center mt50'>
                                <div className='continueApplication mt-5 cursor-point' onClick={this.handleQueryClose}><p className='text-white'>Continue with application</p></div>
                            </div>

                        </div> 
}
{/* end */}

        



                            </div>

                        </form>
                        {/* <div className='text-center'>
                            <div> <img src="./images/receivedIcon.png" /></div>
                            <div className='queryReceived mt-4'>Query Received!</div>
                            <div className='querytext'>Our team will answer your</div>
                            <div className='querytext'>query shortly</div>
                            <div className='d-flex justify-content-center mt50'>
                                <div className='continueApplication mt-5 cursor-point' onClick={this.handleQueryClose}><p className='text-white'>Continue with application</p></div>
                            </div>

                        </div> */}
                    </div>


                </Modal>











            </>
        )
    }
}



function mapStateToProps(state) {
    const { lead_application_show, leade_drop, raise_query, showraiseQuerySuccess, showLeadDropSuccess, raise_query_cred } = state.model;
    const { isLoading, user_id, sfid } = state.auth;
    const { mproducts, category, lead_id, L_id, opp_id, orderSummary } = state.user;
    return {
        lead_application_show,
        leade_drop,
        raise_query,
        user_id,
        mproducts,
        isLoading,
        sfid,
        opp_id,
        category,
        lead_id,
        L_id,
        orderSummary,
        showraiseQuerySuccess,
        showLeadDropSuccess,
        raise_query_cred
    };
}

export default connect(mapStateToProps)(RaiseQueryCred)
import React from 'react'
// import {
//     getSettlement,
//   } from '../actions/user';
import { closeLoanCanelModal } from "../actions/model"
import { selectedCancelationId, getSettlement, merchantSettlementDetail, setSettlementclickItemId } from "../actions/user"
import Pagination from '@material-ui/lab/Pagination';
import TablePagination from '@mui/material/TablePagination';
import NoRecordsFound from "../common/NoRecordsFound.component";


export default class Cancellationsettlement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sellingValue: "",
      page: 1,
      limit: 10,
      stage: "Cancellation Request",
      // page: 1,
      rowsPerPage: 10
    }
  }

  closeCancelModel = (ele) => {
    this.props.dispatch(selectedCancelationId(ele))
    this.props.dispatch(closeLoanCanelModal())
  }

  handleChangePage = (event, value) => {
    const sfid = localStorage.getItem('sfid');
    let data = `page=${value}&section=${this.state.stage}&limit=${this.state.limit}`;
    this.setState({ page: value });
    this.props.dispatch(getSettlement(data, sfid));
  }

  handleChangelimitOfPage = (event) => {
    let perRowData = event.target.value;
    const sfid = localStorage.getItem('sfid');
    // let data =  `page=${perRowData}`;
    let data = `page=1&section=${this.state.stage}&limit=${perRowData}`;
    this.setState({ limit: perRowData });
    this.props.dispatch(getSettlement(data, sfid));
  }

  handleChangePageNew = (event, newPage) => {
    this.setState({ page: newPage })
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 })

  };


  openSettlementDetails = (id) => {
    this.props.dispatch(setSettlementclickItemId(id));
    this.props.dispatch(merchantSettlementDetail(id));
  }

  componentWillUnmount() {
    // this.setState({isHovered:false,indexHovered:null})
  }
  render() {
    const { userMessage, activeWizrTab } = this.props
    //const totalPages = userMessage.cancellation_request_count ? Math.ceil(userMessage.cancellation_request_count / Number(this.state.limit)): 1;
    console.log('refundssss', userMessage)
    let totalPages = 1
    if (userMessage.leadCount) {
      let getArray = userMessage.leadCount[2]
      if (getArray.cancellationCount) {
        totalPages = Math.ceil(getArray.cancellationCount / Number(this.state.limit));

      }
      //totalPages = Math.ceil(getArray.cancellationCount / Number(this.state.limit));
    }
    const cancellationtData = [];
    // for (let i = 0; i < 10; i++) {
    //   cancellationtData.push(<tr className="shown cursor-point trhover" data-toggle="modal" data-target="#myModaxl3" key={i}>
    //     <td ><p className="mt-2"><b>{i + 1}</b></p></td>
    //     <td>
    //       <div className=""><p className="appIDLeads mb-2">AI6125478</p><span className="ai_d appIDDates">May 25,2021</span></div>
    //     </td>
    //     <td>
    //       <div className=""><p className="applicantName mb-1">Sneha Sharma</p> <span className="ai_d applicantNam">9995 888 777</span></div>
    //     </td>
    //     <td colSpan={3}>
    //       <div className=""><p className="appIDCourseName appTextline">Executive PG Programme in Software Development-Specialization</p></div>
    //     </td>
    //     {activeWizrTab === 'WIZR Skills' && (<td><p className="settleAmount">₹ 1,20,000</p></td>)}
    //     {activeWizrTab === 'WIZR Skills' && (<td><p className="applicantName">Request Pending</p></td>)}
    //     {/* <td>
    //          <p className=""> UTR: HDFC4564755675</p>
    //        </td> */}
    //     <td><i className={`fa fa-angle-right float-right size20 cursor-point`} data-toggle="modal" data-target="#myModaxl3"></i></td>
    //   </tr>)
    // }
    return (
      <>

        <div className="table-responsive">
          <table
            className="table settlement_due_table tableStyle"
            id="dataTable1"
            cellSpacing={0}
          >
            <thead>
              {/* <tr>
                                    <th>
                                      <div className="d-flex all_check">
                                        <input type="checkbox" />
                                        <label>All</label>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="d-flex align-items-center">Application ID
                                    
                                      </div>

                                    </th>
                                    <th>Applicant Details</th>
                                    <th>Product Name</th>
                                    <th>
                                      <div className="d-flex align-items-center">Refund Amount
                                        <div className="d-none">
                                          <button className="up"></button>
                                          <button className="down"></button>
                                      </div>
                                      </div>
                                    </th>
                                    <th>
                                      Actionable
                                    </th>
                                  </tr> */}
              <tr>
                <th >
                  <div className="d-flex align-items-center appHead-headinghash">#</div>
                </th>
                <th >
                  <div className="d-flex align-items-center appHead-heading">Application ID</div>
                </th>
                <th>
                  <div className="d-flex align-items-center appHead-heading">Application Details
                    <div className="d-none">
                      <button className="up"></button>
                      <button className="down"></button>
                    </div>
                  </div>

                </th>
                <th colSpan={3} className='appHead-heading'>Course Name</th>
                {activeWizrTab === 'WIZR Skills' && (<th className='appHead-heading'>Course Fee</th>)}
                {activeWizrTab === 'WIZR Skills' && (<th>
                  <div className="d-flex align-items-center appHead-heading">Status
                    <div className="d-none">
                      <button className="up"></button>
                      <button className="down"></button>
                    </div>
                  </div>
                </th>)}
                {/* <th>
                                      UTR No.
                                    </th> */}
                <th className='appHead-heading'>
                  Edit info
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {userMessage.proData && userMessage.proData &&
                                    (
                                      userMessage.proData.map((item, index) => (

                                        <tr>
                                          <td>
                                            <div className="d-flex" key={item}>
                                              <div className="single_check">
                                                <input type="checkbox" className="" />
                                                <label></label>
                                              </div>
                                              <div>
                                                <div className="new_ribbon">New </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <p
                                              className="ai"
                                              data-toggle="modal"
                                              data-target="#myModal6"
                                              onClick={() => this.openSettlementDetails(item.opp_id)}

                                            >{item.transaction_application_id__c}</p>
                                            <span className="ai_d">{item.created_at}</span>
                                          </td>
                                          <td>
                                            <p>{item.name}</p>
                                            <span

                                >{item.mobile}</span>
                                          </td>
                                          <td>{item.product_name}</td>
                                          <td>₹ {item.amount}</td>
                                          <td>
                                            <div className="d-flex align-items-center">
                                              <div className="mr-2" >
                                                <button onClick={() => this.closeCancelModel(item.opp_id)} >
                                                  <i className="fa fa-check" style={{ "color": "#0FD17E" }} aria-hidden="true" /> Approve </button></div>
                                              <div>
                                                <i className="fa fa-times" style={{ "color": "#D51332" }} aria-hidden="true"></i> Reject</div>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                      ))
                                  } */}
                                                 {cancellationtData.length>0?cancellationtData:<td colSpan="10"><NoRecordsFound message='No Data to show'/></td>}

            </tbody>

          </table>
          <div className="d-flex align-items-center justify-content-lg-end justify-content-center mb-4">
          <div className="d-flex align-items-center pages">
           {cancellationtData.length>0 &&(<TablePagination
              component="div"
              count={200}
              page={this.state.page}
              onPageChange={this.handleChangePageNew}
              rowsPerPage={this.state.rowsPerPage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 20, 30]}

            />)}
          </div>

        </div>
        </div>

        


      </>
    );
  }
}

import React from 'react'
import { Component } from 'react';

class CustomizeCheckbox extends Component {
    constructor() {
        super()
        
    }

    // handleClose=()=>{
    //     console.log("here323")
    //     this.props.close();
    // }
render(){
 const {checked}=this.props
  return (
    <>
   <section title='.cust-checkbox'>
             
             <div className='cust-checkbox'>
               <input type="checkbox" value="None"  name="check" checked={checked}   />
               <label htmlFor="cust-checkbox" />
             </div>
            
           </section>
   
   </>
  )
}
  
}
export default CustomizeCheckbox
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { closeModel } from "../actions/model";
import { getMerchantProductList, getCategory, createProduct, getMerchantProducts, getFilterMaster } from "../actions/user";
import { openBulkNewProdModel } from "../actions/model";
import { Scrollbar } from "react-scrollbars-custom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import XlsxPopulate, { Promise } from "xlsx-populate";
import { saveAs } from "file-saver";
import readXlsxFile from 'read-excel-file'
import { finalDate } from '../common/commonFunctions';



const SECTION1 = 0;
const SECTION2 = 1;
const SECTION3 = 2;
const initialState = {
    product: '',
    product_price: '',
    product_name: '',
    category: '',
    brand: '',
    description: '',
    isValid: true,
    errorMsg: '',
    isSuccess: '',
    successMsg: '',
    onBoarding: SECTION1,
    showErr: false,
    loanerrmsg: '',
    brandData:[],
    courseStage :'Deactivate Course',
    finalTemplateData:[],
};

class CourseDetailsCred extends Component {

    constructor() {
        super()
        this.state = initialState;
        this.testRef = React.createRef();

    }

    componentDidMount() {

        let getProd = { merchant_id: this.props.user_id }
        // this.props.dispatch(getMerchantProductList(getProd));
        //dont need this api
        //this.props.dispatch(getCategory());

        //dont need this api
        // this.props.dispatch(getFilterMaster(localStorage.getItem('user_id'), 'brand')).then((response) => {
        //     console.log(response, 'brand response')
        //    const brand = [];
        //    const allbrand =  response?.data ? response.data : [];
        //    allbrand.forEach(element =>{ brand.push({ value:element.name, label:element.name})});
        //     this.setState({ brandData:brand })
        // })

        const finalD = finalDate();
        this.setState({finalTemplateData :finalD})
    }

    componentDidUpdate(prevProps) {
        if (prevProps.add_new_product_show !== this.props.add_new_product_show) {
            this.setState(initialState);
        }
    }

    onNextBording = (next) => {
        this.setState({ onBoarding: next });
    }

    closeModel = () => {
        this.props.dispatch(closeModel())
    }
    // openSuccessModel = () => {
    //     this.props.dispatch(openSuccessModel())
    // }


    openNewbulkUpload_modal = (e) => {
        e.preventDefault();
        this.props.dispatch(openBulkNewProdModel())
    }

    handleChange = (e) => {
        // this.setState({ [e.target.name]: e.target.value });
        if(e.target.name === 'brand'){

            this.setState({ [e.target.name]: e.target.value.replace(/[^a-z]/gi, '') });
        }else{
            this.setState({ [e.target.name]: e.target.value});
        }
    }

    handleClose = () => {
        // this.setState({ state: SECTION1 });
        this.setState(initialState);
    }


    onlyNumbers = (e) => {
        var pattern = new RegExp(/^(?!(0))\d+$/);
        if (e.target.value !== '') {
            if (!pattern.test(e.target.value)) {
                // this.setState({ [e.target.name]: "" });
            } else {
                this.setState({ [e.target.name]: e.target.value });
            }
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    // onlyNumbers = (e) => {
    //     var pattern = new RegExp(/^[0-9\b]+$/);
    //     if(e.target.value !=='')
    //     {
    //       if(!pattern.test(e.target.value))
    //       {
    //         this.setState({[e.target.name] : ""});
    //       }else{
    //         this.setState({[e.target.name] : e.target.value});
    //       }
    //     }else{
    //       this.setState({[e.target.name] : e.target.value});
    //     }
    //   }

    handleSelectProduct = (e) => {
        const { mproducts } = this.props;
        let id = e.value;
        if (id != '') {
            var selctedItem = mproducts.find(item => item.sfid == id);
            this.setState({ product: id, product_price: selctedItem.mrp__c, category: selctedItem.product_sub_category__c, product_name: selctedItem.name, brand: selctedItem.brand__c });
            this.setState({ onBoarding: SECTION2 });
        } else {
            this.setState({ product_price: '', product: '', product_name: '', brand: '' });
        }
    }
    scrollToBottom = () => {
        var objDiv = document.getElementById("create-lead");
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    handleSubmitProd = (e) => {
        e.preventDefault();
        let data = {
            product_id: this.state.product ? this.state.product : '',
            price: this.state.product_price,
            product_name: this.state.product_name,
            brand: this.state.brand,
            category: this.state.category,
            description: this.state.description,
            merchant_id: this.props.sfid
        };
        console.log('prod add data==', data)
        this.props.dispatch(createProduct(data)).then((response) => {
            if (response.status === "success") {
                console.log('add prod rponse', response)
                this.setState({ isSuccess: 1, onBoarding: SECTION3, successMsg: response.message });
                // setInterval(document.getElementById("close-create").click(), 5000);
                let getProd = { merchant_id: this.props.user_id }
                this.props.dispatch(getMerchantProductList(getProd));
                //window.location.reload();

            } else {
                if (response.status === "error") {
                    this.setState({ showErr: true, loanerrmsg: response.message })
                }
                else {
                    this.scrollToBottom();
                    this.setState({ isSuccess: 0, onBoarding: SECTION1, successMsg: response.message });
                }

            }
        }).catch((error) => {
            this.setState({ isSuccess: 0, onBoarding: SECTION1, successMsg: error });
        });

    }
    /*
    url=https://eduvanz-api-01.herokuapp.com/api/create_product
    brand: "bajaj"
category: "2-Wheelers"
description: ""
merchant_id: "00171000008GurGAAS"
price: "21000"
product_id: "01t7100DMTTT10313"
product_name: "Demo Test 314"
    
    */



    handleDownload = (url) => {

        let obj = { merchant_id: this.props.user_id, "section": '' }
        let getProd = { merchant_id: this.props.user_id }
        const {mproducts} = this.props;
        //  this.props.dispatch(merchantProductDownload(obj)).then((response) => {
        // this.props.dispatch(getMerchantProductList(getProd)).then((response) => {
            console.log('response here product=', mproducts)
            // if (response.proData && response.proData.length > 0) {

                let getData = []
                const m_id = localStorage.getItem("sfid") ? localStorage.getItem("sfid") : localStorage.getItem("sfid")
                for (let i = 0; i < mproducts.length; i++) {
                    let source = mproducts[i];

                    let obj = {
                        // name:source.name,
                        // // merchant_id : source.id,
                        // merchant_id : m_id,
                        // product_id : source.sfid,
                        // new_selling_price : source.offer_price__c,
                        // activation_status : source.activate_product__c,
                        // sku:source.sku,

                        merchant_id: m_id,
                        product_id: source.sfid,
                        new_selling_price: source.price__c,
                        activation_status: 'active',
                        brand: '',
                        name: source.name,
                        description: '',
                        category: source.product_sub_category__c
                        // merchant_id : source.id,
                    }
                    getData.push(obj)
                }

                this.saveAsExcel(getData);
            }
    //     });
    // }





    // saveAsExcel = async (getData) => {
    //     var data = [];
    //     await Promise.all(getData.map(async (elament) => {
    //         const productDet = elament
    //         data.push({
    //             merchant_id: productDet && productDet.merchant_id && productDet.merchant_id != null ? productDet.merchant_id : '',
    //             P_id: productDet && productDet.product_id ? productDet.product_id : '',
    //             // category: productDet && productDet.product_sub_category ? productDet.product_sub_category : '',
    //             // mrp: productDet && productDet.mrp && productDet.mrp != null ? productDet.mrp : '',
    //             // mop: productDet && productDet.mop && productDet.mop != null ? productDet.mop : '',
    //             selling_price: productDet && productDet.new_selling_price && productDet.new_selling_price != null ? productDet.new_selling_price : '',
    //             activation_status: productDet && productDet.activation_status == false ? 'inactive' : 'active',
    //             name: productDet && productDet.name ? productDet.name : '',
    //             sku: productDet && productDet.sku && productDet.sku != null ? productDet.sku : '',
    //         })
    //     }));
    //     let header = ["Merchant Id", "Product Id", "Selling Price", "Activation Status", "Name", "SKU"];
    //     //let header = ["Category","Sub Category","Brand","Product Name","Selling Price","Description"];
    //     XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    //         const sheet1 = workbook.sheet(0);
    //         const sheetData = await this.getSheetData(data, header);
    //         const totalColumns = sheetData[0].length;
    //         sheet1.cell("A1").value(sheetData);
    //         const range = sheet1.usedRange();
    //         const endColumn = String.fromCharCode(64 + totalColumns);
    //         sheet1.row(1).style("bold", true);
    //         sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
    //         range.style("border", true);
    //         return workbook.outputAsync().then((res) => {
    //             saveAs(res, "product_report.xlsx");
    //         });
    //     });
    // }

    // getSheetData = async (data, header) => {
    //     var fields = Object.keys(data[0]);
    //     var sheetData = data.map(function (row) {
    //         return fields.map(function (fieldName) {
    //             return row[fieldName] ? row[fieldName] : "";
    //         });
    //     });
    //     sheetData.unshift(header);
    //     return sheetData;
    // }

    saveAsExcel = async (getData) => {
        var data = [];
        await Promise.all(getData.map(async (elament) => {
            const productDet = elament
            data.push({
                merchant_id: productDet && productDet.merchant_id && productDet.merchant_id != null ? productDet.merchant_id : '',
                P_id: productDet && productDet.product_id ? productDet.product_id : '',
                // category: productDet && productDet.product_sub_category ? productDet.product_sub_category : '',
                // mrp: productDet && productDet.mrp && productDet.mrp != null ? productDet.mrp : '',
                // mop: productDet && productDet.mop && productDet.mop != null ? productDet.mop : '',
                selling_price: productDet && productDet.new_selling_price && productDet.new_selling_price != null ? productDet.new_selling_price : '',
                activation_status: 'Active',
                brand: productDet && productDet.brand && productDet.brand != null ? productDet.brand : '',
                name: productDet && productDet.name ? productDet.name : '',
                description: productDet && productDet.description && productDet.description ? productDet.description : '',
                category: productDet && productDet.category && productDet.category != null ? productDet.category : '',
            })
        }));
        let header = ["Merchant Id", "Product Id", "Selling Price", "Activation Status", "Brand", "Name", "Description", "Category"];
        //let header = ["Category","Sub Category","Brand","Product Name","Selling Price","Description"];
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = await this.getSheetData(data, header);
            const totalColumns = sheetData[0].length;
            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);
            sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                saveAs(res, "product_report.xlsx");
            });
        });
    }

    getSheetData = async (data, header) => {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
          return fields.map(function (fieldName) {
            return row[fieldName] ? row[fieldName] : "";
          });
        });
        sheetData.unshift(header);
        return sheetData;
      }

      selectBrand = (selectedOptions) => {
        this.setState({ brand:selectedOptions.value});
        
      }

    
        generateCsv = async() => {
          
        let header = ["", "Course Name", "Course Fee", "Course ID", "Medium", "Subject","Respective Skills","Batch Start Date","Last Enrolment Date","Course Level","Course Eligibility Required","Scholarship","Certificate Provided","Certificate Providing Authority","Course Duration","Delivery Mode","Course Learning Mode","Course/ Class Location","Type","Semester","Module","Course"];
         XlsxPopulate.fromBlankAsync().then(async (workbook) => {
          console.log("Top part");
        const sheet1 = workbook.sheet(0);
        const sheetData = await this.getSheetData(this.state.finalTemplateData, header);
        const totalColumns = sheetData[0].length;
        sheet1.cell("A1").value(sheetData);
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        sheet1.row(2).style("bold", true);
        sheet1.range("A1:" + endColumn + "1").style("fill","fcdeb8");
        sheet1.range("A2:A6").style({bold:true,fill:"D3D3D3"});
      //   sheet1.column("A1:" + endColumn + "1").width(25).hidden(false);
        sheet1.range("A1:A1").style("fill","FFFFFF");
        sheet1.range("B2:N2").style("fill","F1E5AC");
        sheet1.range("O2:S2").style("fill","ddfada");
        sheet1.range("T2:V2").style("fill","e4f2f7");
        range.style("border", true);
       
        console.log("bottom part");
  
        //merge cellss 
      const range1 = workbook.sheet(0).range("B1:N1");
      range1.value("Basic Details");
      range1.style({horizontalAlignment: "center", verticalAlignment: "center",fill:"f0bf60"})
      range1.merged(true);
  
      const range2 = workbook.sheet(0).range("O1:S1");
      range2.value("Duration & Delivery");
      range2.style({horizontalAlignment: "center", verticalAlignment: "center", fill:"BDF5BD"})
      range2.merged(true);
  
      const range3 = workbook.sheet(0).range("T1:V1");
      range3.value("Syllabus");
      range3.style({horizontalAlignment: "center", verticalAlignment: "center",fill:"ADD8E6" })
      range3.merged(true);
    
        return workbook.outputAsync().then((res) => {
          saveAs(res, "report.xlsx");
          this.setState({showDownloadCsvToast:true})
          setTimeout(() => {
              this.setState({showDownloadCsvToast:false})
          }, 3000);
        });
      });
          //   }
          // });
        }

        shouldComponentUpdate(nextProps, nextState) {
          if (this.props.singleProduct != nextProps.singleProduct) {
              return true
          } else {
              return true
          }
  
      }
  
      
      
    

    render() {
        const { isLoading, mproducts, category,singleProduct } = this.props;
        const item  = singleProduct && singleProduct.proData && singleProduct.proData.length > 0 ? singleProduct.proData[0]:{};

        let prodOptions = [];
        // if (mproducts) {
        //     for (var i = 0; i < mproducts.length; i++) {
        //         let pname = String(mproducts[i].name).slice(0, 15);
        //         prodOptions.push({ value: mproducts[i].sfid, label: pname });
        //     }
        // }
           
        return (
            <>
                {/* {isLoading?(
                <div className="loading">Loading&#8230;</div>
            ):''} */}
                {/* Modal */}                                    
                <div className="modal right fade myModal" id="myModal23" role="dialog">
                    <div className="modal-dialog">
                        {/* Modal content*/}
                     
                        <form onSubmit={this.handleSubmitProd} className="f_height">
                            <div className="modal-content ">
                            <div className='course-close-btn'>
                        {/* <button onClick={this.generateCsv} type="button" id="close-create" className="close" style={{marginRight:"16px"}} > <img src='./images/bank-icon/edits.svg' className='delete-icon-scheme'></img> </button> */}
                        <button onClick={this.handleClose} type="button" id="close-create" className="close" data-dismiss="modal"> <i className="fas fa-times" style={{fontSize:"25px"}}></i> </button>
                        </div>
                            <Scrollbar>
                                <div className="modal-header add-course-header padding0">
                                  
                                   
                                    <div className='d-flex justify-content-between align-items-center w-100'>
                                        <div className='d-flex align-items-center'>
                                        <h5 className="modal-title fz21 p-0 add-course-title fw600 color1D20">{item.sfid}</h5>
                                        {
                                          item.activate_product__c  ?  <button className='course-active-btn'>Active</button> : <button className='course-Inactive-btn'>Inactive</button>
                                        }
                                        
                                        </div>
                                        {/* {this.state.courseStage === "Deactivate Course" ? 
                                       <div className='deactivate-course-btn' onClick={()=>this.setState({courseStage :'Deactivation Pending'})}><img src='./images/deactive_dash.svg' style={{height:'19px'}}></img> <span>Deactivate Course</span></div>
                                        : this.state.courseStage === 'Deactivation Pending' ?
                                       <div className='deactivate-pending-course-btn' onClick={()=>this.setState({courseStage :'Activate Course'})}><img className='hoverImg2' src='./images/deactivationPending.svg' style={{height:'19px'}}></img><img className='hoverImg' src='./images/blackpending.svg' style={{height:'19px'}}></img><span>Deactivation Pending</span></div>
                                        : this.state.courseStage === 'Activate Course' ?
                                       <div className='activate-course-btn' onClick={()=>this.setState({courseStage :'Activation Pending'})}><img className='hoverImg2' src='./images/activate-course.svg' style={{height:'19px'}}></img><img className='hoverImg' src='./images/balckActive.svg' style={{height:'19px'}}></img><span>Activate Course</span></div>
                                        :
                                       <div className='deactivate-pending-course-btn' onClick={()=>this.setState({courseStage :'Deactivate Course'})}><img className='hoverImg2' src='./images/deactivationPending.svg' style={{height:'19px'}}></img><img className='hoverImg' src='./images/blackpending.svg' style={{height:'19px'}}></img><span>Activation Pending</span></div>

                                      } */}

                                    </div>

                                </div>

                                <div id="" className="modal-body pt-0 px-0">

                                    

                                        <div className='d-flex flex-column add-course-body ' style={{border:'1px solid #E6E6E6'}}>
                                           
                                          <div className='d-flex flex-column'>
                                            <h6 className='fw600 marginb24' >Product Details</h6>
                                            <p className='course-text-light product-name'>Product Name</p>
                                            {/* <p className='or'>Apple 2022 MacBook Air Laptop with M2 chip: 34.46 cm (13.6-inch) Liquid Retina Display, 8GB RAM, 256GB SSD Storage, Backlit Keyboard, 1080p FaceTime HD Camera</p> */}
                                            <p className='or'>{item?.name ? item.name : '-'}</p>
                                            <div className='course-details-info'>
                                                 <div className='width30'>
                                                    <p className='course-text-light'>Category</p>
                                                    <p className='course-all-labels'> {item?.product_category__c ? item.product_category__c : '-' }</p>
                                                 </div>
                                                 <div className='width30'>
                                                    <p className='course-text-light' >Brand</p>
                                                    {/* <p className='course-all-labels'>15 days</p> */}
                                                    <p className='course-all-labels'>{item.brand ? item.brand : '-'}</p>
                                                 </div>
                                                 <div className='invisible width30' >
                                                 <p className='course-text-light'> </p>
                                                    <p className='course-all-labels'></p>
                                                 </div>
        
                                             </div>
                                            <p className='course-text-light product-name mt-top'>Product Description</p>
                                            {/* <p className='or'>Liquid Retina Display, 8GB RAM, 256GB SSD Storage, Backlit Keyboard, 1080p FaceTime HD Camera</p> */}
                                            <p className='or'>{item?.description ? item.description : '-'}</p>
                   
                                            <div className='course-details-info'>
                                                <div className='width30'>
                                                   <p className='course-text-light'>Product MRP</p>
                                                   {/* <p className='course-all-labels'>₹ 1,40,000</p> */}
                                                   <p className='course-all-labels'>₹ {item?.mrp__c ? item.mrp__c.toLocaleString('en-IN') : '-'}</p>
                                                </div>
                                                <div className='width30'>
                                                   <p className='course-text-light'>Product MOP</p>
                                                   {/* <p className='course-all-labels'>English</p> */}
                                                   <p className='course-all-labels'>₹ {item?.mop ? item.mop.toLocaleString('en-IN') : '-'}</p>
                                                </div>
                                                <div className='width30'>
                                                   <p className='course-text-light'>Selling Price</p>
                                                   {/* <p className='course-all-labels'>Engineering</p> */}
                                                   <p className='course-all-labels'>₹ {item?.loan_amount__c ? item.loan_amount__c.toLocaleString('en-IN') : '-'}</p>
                                                </div>
                                            </div>
                                           
            
                                          </div>

                                                                                 <div className='course-details-info'>
                                                 <div className='width30'>
                                                    <p className='course-text-light'>Discounted Amount</p>
                                                    <p className='course-all-labels'>₹ {item?.offer_price__c ? item.offer_price__c.toLocaleString('en-IN') : '-'}</p>
                                                 </div>
                                                 <div className='width30'>
                                                    <p className='course-text-light'>Discount Percentage</p>
                                                    {/* <p className='course-all-labels'>10%</p> */}
                                                    <p className='course-all-labels'>{item?.discount_percent ? item.discount_percent+'%' : '-'}</p>
                                                    
                                                 </div>
                                                 <div className='width30 invisible'>
                                                    <p className='course-text-light'></p>
                                                    {/* <p className='course-all-labels'>1,200</p> */}
                                                    <p className='course-all-labels'>{item?.sku ? item.sku : "-" }</p>
                                                 </div>
                                             </div>
                                             {/* <div className='course-details-info'>
                                                 <div className='width30'>
                                                    <p className='course-text-light'>Delivery Charges</p>
                                                    <p className='course-all-labels'>₹ {item?.delivery_charge ? item.delivery_charge : "-" }</p>
                                                 </div>
                                                 <div className='width30'>
                                                    <p className='course-text-light' >Delivery TAT</p>
                                                  
                                                    <p className='course-all-labels'>{item?.delivery_tat ? item.delivery_tat : "-"}</p>
                                                 </div>
                                                 <div className='invisible width30' >
                                                 <p className='course-text-light'>Course </p>
                                                    <p className='course-all-labels'>Instructor</p>
                                                 </div>
        
                                             </div> */}


                                        </div>

                                    
                                    {/* duration and delivery */}
{/*                                     
                                    <div className='d-flex flex-column add-course-body' style={{border:'1px solid #E6E6E6'}}>
                                           
                                           <div className='d-flex flex-column'>
                                             <h6 className='mt-2' >Offer Details</h6>
                                             <div className='course-details-info pd-right'>
                                                
                                                <div>
                                                    <p className='course-text-light'>Offer Name</p>
                                                    <p className='course-all-labels'>Summer_sale</p>
                                                </div>
                                                <div className='offer-detail-active'>
                                                   <p><span className='green-dot'></span> Live</p>
                                                   <img className='delete-icon-scheme'  src='./images/bank-icon/deletesvg.svg'/>
                                                </div>
                                               
                                             </div>
                                             <div className='course-details-info pd-right'>
                                            
                                                <div>
                                                    <p className='course-text-light'>Offer Name</p>
                                                    <p className='course-all-labels'>Summer_sale</p>
                                                </div>
                                                <div className='offer-detail-active'>
                                                   <p><span className='green-dot'></span> Live</p>
                                                   <img  src='./images/bank-icon/deletesvg.svg'/>
                                                </div>
                                              
                                             </div>
                                           
                                           </div>

 
 
 
                                         </div> */}


                                         {/* Syllabus */}
                                   {/*       <div className='d-flex flex-column add-course-body' style={{border:'1px solid #E6E6E6'}}>
                                           
                                           <div className='d-flex flex-column'>
                                           <div className="container p-0">



<div className="row">
  <div className="col-12">
  <h6 className='font-weight-bold mt-2 course-syllabus-heading' >Syllabus</h6>
    <div id="accordionExample" style={{marginTop:"30px"}} >

     
      <div className="card syllabus-card">
        <div id="headingOne" className="card-header card-header-st">
          <h2 className="mb-0">
            <button type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
              aria-controls="collapseOne"
              className="btn-link font-weight-bold text-uppercase collapsible-link accordian-common-btn">Semester 1</button>
          </h2>
        </div>
        <div id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionExample" className="collapse">
          <div className="card-body">
             <div className='mb-3'>
                 
                <div className='mb-2 ml-1'>
                   <span className='course-number'>Module 1</span> Lorem Ipsum
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content ml-4 mb-2 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>
             <div className='mb-3'>
                 
                <div className='mb-2 ml-1'>
                   <span className='course-number'>Module 2</span> Lorem Ipsum
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content ml-4 mb-2 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>
             <div className='mb-3'>
                 
                <div className='mb-2 ml-1'>
                   <span className='course-number'>Module 3</span> Lorem Ipsum
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content ml-4 mb-2 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>
          </div>
        </div>
      </div>
      <div className="card syllabus-card">
        <div id="headingTwo" className="card-header card-header-st">
          <h2 className="mb-0">
            <button type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
              aria-controls="collapseTwo"
              className="btn-link  font-weight-bold text-uppercase collapsible-link accordian-common-btn">Semester 2</button>
          </h2>
        </div>
        <div id="collapseTwo" aria-labelledby="headingTwo" data-parent="#accordionExample" className="collapse">
          <div className="card-body">
             <div className='mb-3'>
                 
                <div className='mb-2 ml-1'>
                   <span className='course-number'>Module 1</span> Lorem Ipsum
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content ml-4 mb-2 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>
             <div className='mb-3'>
                 
                <div className='mb-2 ml-1'>
                   <span className='course-number'>Module 2</span> Lorem Ipsum
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content ml-4 mb-2 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>
             <div className='mb-3'>
                 
                <div className='mb-2 ml-1'>
                   <span className='course-number'>Module 3</span> Lorem Ipsum
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content ml-4 mb-2 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>
          </div>
        </div>
      </div>
      <div className="card syllabus-card">
        <div id="headingThree" className="card-header card-header-st">
          <h2 className="mb-0">
            <button type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
              aria-controls="collapseThree"
              className="btn-link  font-weight-bold text-uppercase collapsible-link accordian-common-btn">Semester 3</button>
          </h2>
        </div>
        <div id="collapseThree" aria-labelledby="headingThree" data-parent="#accordionExample" className="collapse">
          <div className="card-body">
             <div className='mb-3'>
                 
                <div className='mb-2 ml-1'>
                   <span className='course-number'>Module 1</span> Lorem Ipsum
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content ml-4 mb-2 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>
             <div className='mb-3'>
                 
                <div className='mb-2 ml-1'>
                   <span className='course-number'>Module 2</span> Lorem Ipsum
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content ml-4 mb-2 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>
             <div className='mb-3'>
                 
                <div className='mb-2 ml-1'>
                   <span className='course-number'>Module 3</span> Lorem Ipsum
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content ml-4 mb-2 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>
          </div>
        </div>
      </div>
      <div className="card syllabus-card">
        <div id="headingFour" className="card-header card-header-st">
          <h2 className="mb-0">
            <button type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false"
              aria-controls="collapseFour"
              className="btn-link  font-weight-bold text-uppercase collapsible-link accordian-common-btn">Semester 4</button>
          </h2>
        </div>
        <div id="collapseFour" aria-labelledby="headingFour" data-parent="#accordionExample" className="collapse">
          <div className="card-body">
             <div className='mb-3'>
                 
                <div className='mb-2 ml-1'>
                   <span className='course-number'>Module 1</span> Lorem Ipsum
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content ml-4 mb-2 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>
             <div className='mb-3'>
                 
                <div className='mb-2 ml-1'>
                   <span className='course-number'>Module 2</span> Lorem Ipsum
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content ml-4 mb-2 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>
             <div className='mb-3'>
                 
                <div className='mb-2 ml-1'>
                   <span className='course-number'>Module 3</span> Lorem Ipsum
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 1</span>
                 <span className='course-names'>Python for Data Science & Essentials of Programming
</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 2</span>
                 <span className='course-names'>Statistical Foundation for Machine Learning

</span>
                </div>
                <div className='course-module-content ml-4 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 3</span>
                 <span className='course-names'>Artificial and Computational Intelligence

</span>
                </div>
                <div className='course-module-content ml-4 mb-2 mb-2'>
                 <span className='green-circle'></span>
                 <span className='course-number'>Course 4</span>
                 <span className='course-names'>Pattern Recognition
</span>
                </div>
             </div>
          </div>
        </div>
      </div>

      
      

    </div>
  </div>
</div> 
</div>




                                           </div>
                                         </div>*/}
                                    
{/* 
                                         <div className='d-flex flex-column add-course-body pt-0 pb-0' style={{backgroundColor:"#F2F3F8",border:'1px solid #E6E6E6'}} > 
                                           <div className='d-flex flex-column'>
                                           <div className="container p-0">



<div className="row">
  <div className="col-12">
  
    <div id="accordionExample" >
    <div className="card course-edit-accordian" ba>
        <div id="headingEdit" className="card-header card-header-st">
          <h2 className="mb-1">
            <button type="button" data-toggle="collapse" data-target="#collapseEdit" aria-expanded="false" 
              aria-controls="collapseEdit"
              className="btn-link text-dark font-weight-bold collapsible-link accordian-btn">How to ‘Edit & Update’ course details</button>
          </h2>
        </div>
        <div id="collapseEdit" aria-labelledby="headingEdit" data-parent="#accordionExample" className="collapse">
          <div className="card-body">
                    
                
          <div className='course-edit-container mt-0 pr-0'>
                                                <div>
                                                   <p className='course-all-labels steps'>Step 1</p>
                                                   <p className='stepsPara'>Download the Excel file via download (  <img src='./images/download.svg' style={{height:'20px',width:"20px"}}/>  ) button on the top.</p>
                                                </div>
                                                <div>
                                                   <p className='course-all-labels steps'>Step 2</p>
                                                   <p className='stepsPara'>Update the details on the same file.</p>
                                                </div>
                                                <div>
                                                   <p className='course-all-labels steps'>Step 3</p>
                                                   <p className='stepsPara'>Upload the file via ‘Add Course’</p>
                                                </div>
                                                </div>
                
                
                
                
                
                </div>                        
                </div>                        
                </div>                        
                </div>                        
                </div>                        
                </div>                        
                </div>                        
                                           
                                           
                                           </div>
                                         </div> */}

                                </div>
                                </Scrollbar>
                            </div>
                        </form>
                    </div>
                </div>
                
                {/*Model Stop*/}
            </>
        )
    }

}

function mapStateToProps(state) {
    const { add_new_product_show } = state.model;
    const { isLoading, user_id, sfid } = state.auth;
    const { mproducts, category,singleProduct } = state.user;
    return {
        add_new_product_show,
        user_id,
        mproducts,
        isLoading,
        sfid,
        category,
        singleProduct
    };
}

export default connect(mapStateToProps)(CourseDetailsCred)
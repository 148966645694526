import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Form } from "react-bootstrap"
import { UserSuccessMsg, closeAddUser } from "../actions/model"
import { registerNewUserWizr, getUsers, getUsers_list, getUsersListWizr, logout } from "../actions/user"
import { Stack, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { history } from '../helpers/history';

const AntTab = styled(Tab)(
  ({ theme }) => ({
    marginLeft: "4px",
    color: '#000000',
    background: ' #FFFFFF;',
    borderRadius: "10px",
    marginTop: '2px',
    marginBottom: '2px',
    minHeight: '5px',
    textTransform: 'unset',
    padding: '15px 68px',
    fontSize: "16px",
    '&:hover': {
      color: "#50495A",
      opacity: 1,
    },
    '&.Mui-selected': {
      backgroundColor: '#A198AC',
      color: "#FFFFFF",
      fontSize: "16px",
      borderRadius: "10px",
      padding: '15px 68px',


    },


  }),
);
const AntTabs = styled(Tabs)(
  ({ theme }) => ({
    minHeight: '40px',
  }),
);

const initialState = {
  username: "",
  email: "",
  password: "",
  isMobileValid: false,
  mobileNumber: "",
  role: "",
  department: '',
  owner: "",
  active_status: false,
  successful: false,
  isDisabled: true,
  isValid: true,
  message: '',
  isEmail: false,
  isEmailvalid: false,
  isError: false,
  errormsg: '',
  isErrorName: false,
  errorname: '',
  value: 0,
  prefix: false,
  activeRoleTab: 'Admin',
  successMsg: '',
  isSuccess: false,
  onChangeInput: false,
  ischecked: false,
  emailError: ''
};

class AddNewUser extends Component {

  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.state = initialState
  }


  closeAddUser = () => {
    this.handleClear()
    this.props.dispatch(closeAddUser())
    this.setState({ prefix: false })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.adduser_show !== this.props.adduser_show) {
      this.setState(initialState);
    }
  }

  handleChange = (e) => {
    e.persist();
    if (e.target.name == 'username') {
      var hasNumber = /\d/;
      if (!hasNumber.test(e.target.value)) {
        this.setState(
          { [e.target.name]: e.target.value }
        );

        this.setState(
          { isErrorName: false, errorname: "" }
        );

        if (e.target.value.length <= 1) {
          this.setState(
            { isErrorName: true, errorname: "Please enter valid name with atleast 2 characters" }
          );
        }

      }
      else {
        this.setState(
          { isErrorName: true, errorname: "Numbers are not allowed" }
        );
      }
    } else {

      this.setState(
        { [e.target.name]: e.target.value }
      );
    }
  }



  handleChangeTabs = (event, newValue) => {
    this.setState({ value: newValue });
  };
  handleEmail = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    let str = event.target.value
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // if (mail.match(mailformat)) {


    if (str.length > 0) {
      if (str.match(mailformat)) {
        this.setState({ isEmail: true, emailError: "", isEmailvalid: true })
      }
      else {
        this.setState({ isEmail: true, emailError: "Invalid email format", isEmailvalid: false })
      }
    }
    else {
      this.setState({ isEmail: true, emailError: "Email cannot be empty", isEmailvalid: false })
    }
  }
  // this.state.isEmail && !this.state.isEmailvalid

  handleCheckBoxChange = (e) => {
    e.persist();
    if (e.target.checked) {
      this.setState(
        { [e.target.name]: true }
      );
    }
    else {
      this.setState(
        { [e.target.name]: false }
      );
    }

  }

  handleMobile = (e) => {
    var pattern = new RegExp(/^[0-9\b]+$/);
    const reg = /^[0]?[6789]\d{9}$/;
    this.setState({ prefix: true, onChangeInput: true })
    if (e.target.value !== '') {
      if (!pattern.test(e.target.value)) {
        document.getElementById('mobileNumber').value = '';
        // this.setState({ mobileNumber: '' });
        this.setState({ isValid: false });
        // this.setState({ message: "Please enter only number." });
      }
      //  else if (e.target.value.length != 10) {
      //   this.setState({ isValid: false });
      //   this.setState({ message: "Please enter valid phone number." });
      // } 
      else {
        if (reg.test(e.target.value)) {
          this.setState({ isValid: true, message: "", mobileNumber: e.target.value });
          console.log(this.state.mobileNumber);
        } else {
          this.setState({ isValid: false, message: "Please enter valid mobile number.", mobileNumber: e.target.value });
          console.log(this.state.mobileNumber);
        }
      }
    } else {
      this.setState({ isValid: true, message: "", mobileNumber: '' });
    }
  }



  ValidateEmail(mail) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mail.match(mailformat)) {
      this.setState({ isEmailvalid: true, emailError: "" })
      return true;
    }
    else {
      this.setState({ isEmailvalid: false, emailError: "Invalid email format" })
      return false;
    }
  }


  handleRegister(e) {
    e.preventDefault();

    this.setState({
      successful: false,
    });
    let givenData = {
      // mobileNumber: this.state.mobileNumber,
      // username: this.state.username,
      // email: this.state.email,
      // role: this.state.activeRoleTab,
      // department: this.state.department,
      // owner: localStorage.getItem('sfid'),
      // active_status: this.state.ischecked

      mobileNumber: this.state.mobileNumber,
      username: this.state.username,
      email: this.state.email,
      role: this.state.activeRoleTab,
      department: this.state.department,
      merchant_id: this.props.sfid,
      active_status: this.state.ischecked
    }

    this.props
      .dispatch(
        registerNewUserWizr(givenData)
      )
      .then((response) => {
        console.log(response, "res");
        if (response.data && response.data.responseCode == 200 && response.data.status == 'Success') {
          this.setState({ isError: false, errormsg: '' })
          // this.props.dispatch(UserSuccessMsg(true))
          this.closeAddUser();
          this.props.dispatch(UserSuccessMsg({ show: true, message: "User Added Successfully" }))
          this.setState({ isSuccess: true, successMsg: response.message })
          // const { user_id } = this.props;
          // let data = {
          //   id: user_id,
          // }
          // this.props.dispatch(getUsers(data));
          // this.props.dispatch(getUsers_list(localStorage.getItem('sfid')));
          let obj = {
            merchant_id: this.props.sfid,
            search: "",
            limit: 10,
            page: 0
          }
          this.props.dispatch(getUsersListWizr(obj))
          this.handleClear();
        } else if (response.data.responseCode == 400) {
          console.log("response.data.message", response.data.message)
          this.setState({ isError: true, errormsg: response.data.message })
        } else {
          if (response.status == 401) {
            this.props.dispatch(logout());
            history.push('/login');
          }
        }



        if (response.status == 'error') {
          console.log("error")
          this.setState({ isError: true, errormsg: response.message })

        }

      })
      .catch((err) => {
        console.log("Catch error")
        this.setState({ isError: true, errormsg: err.message })
      });

  }

  handleClear = () => {
    this.setState({
      username: "",
      email: "",
      password: "",
      mobileNumber: "",
      role: "",
      department: '',
      owner: "",
      active_status: false,
      successful: false,
      isDisabled: true,
      isValid: true,
      message: '',
      isEmail: false,
      isEmailvalid: false,
      isError: false,
      errormsg: '',
      isErrorName: false,
      errorname: ''
    })
  }
  handleOnFocus = () => {
    this.setState({ prefix: true })
  }


  handleChecked = (e) => {
    let checked = e.target.checked;
    if (checked) {
      this.setState({ ischecked: true });
    }
    else {
      this.setState({ ischecked: false });
    }
  }

  render() {
    const { adduser_show, user_id } = this.props;
    this.state.owner = user_id;
    return (
      <>
        <Modal show={adduser_show} className="adduser model myModal right">
          <Modal.Header className='modelheadernew'>
            <div className=''>
              <div className='adduser_header d-flex justify-content-between align-items-end'>

                <h4 >Add New User</h4>
                <div className='d-flex justify-content-between flex-column align-items-end closespace'>
                  <button type="button" className="adduser close" onClick={this.closeAddUser}><img src='../img/popclose.svg' alt='close' /> </button>
                  <div className="d-flex align-items-center switchbtnsec">
                    <div className="switch_btn d-flex">
                      <label className="switch mr-3">
                        <input type="checkbox" onChange={this.handleChecked} checked={this.state.ischecked} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    {
                      this.state.ischecked === true ? <span className='active'>Active</span> : <span>Inactive</span>
                    }
                  </div>
                </div>
              </div>
              {/* <div>
                <div className="switch_btn d-flex" style={{ color: `${this.state.active_status == true ? '#094588' : ''}` }}>
                  <label className="switch mr-3">
                    <input type="checkbox" onChange={this.handleCheckBoxChange} name="active_status" value="1" />
                    <span className="slider round" ></span>
                  </label> {this.state.active_status == true ? 'Active' : 'Inactive'}
                </div>
              </div> */}
            </div>
          </Modal.Header>
          <form onSubmit={this.handleRegister}>
            <Modal.Body>
              <div className="mb-4">
                <div className="form-group labelforadduser">
                  <label className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    value={this.state.username}
                    className="form-control formadduser"
                    placeholder="Enter full name here"
                    name="username"
                    onChange={this.handleChange}
                  />
                  {this.state.isErrorName && this.state.errorname != '' && (
                    <span style={{ color: "red" }} className="text-left">
                      {this.state.errorname}
                    </span>
                  )}
                </div>
              </div>

              <div className="mb-4">
                <div className="form-group labelforadduser">
                  <label className="form-label">
                    Mobile Number
                  </label>
                  <div className='d-flex align-item-center'>
                    {this.state.prefix && <p className={`${this.state.onChangeInput === true ? 'NewPrefixClass' : 'prefixForMobile'}`}>+91</p>}
                    <input
                      type="text"
                      value={this.state.mobileNumber}
                      // className="form-control formadduser"

                      className={`'form-control' ${this.state.prefix ? 'prefixAdded' : 'formadduserMobile'}`}
                      placeholder="Enter mobile number here"
                      name="mobileNumber"
                      id="mobileNumber"
                      maxLength={"10"}
                      onChange={this.handleMobile}
                      onFocus={this.handleOnFocus}
                    />
                  </div>
                  {this.state.isValid == false && this.state.message != '' && (
                    <span style={{ color: "red" }} className="text-left">
                      {this.state.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div className="form-group labelforadduser">
                  <label className="form-label">
                    Email ID
                  </label>
                  <input
                    type="text"
                    className="form-control formadduser"
                    placeholder="Enter email id here"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleEmail}
                  />
                  {/* {this.state.isEmail && !this.state.isEmailvalid ? 
                    <div className="alert alert-danger py-1" role="alert">
                      Invalid email ! Please enter a valid email address.
                    </div>
                  : ""} */}
                  {this.state.isEmail && !this.state.isEmailvalid && (
                    <span style={{ color: "red" }} className="text-left">
                      {/* Invalid email ! Please enter a valid email address. */}
                      {this.state.emailError}
                    </span>
                  )}
                </div>
              </div>

              {/* <div className="mb-2">
                <div className="form-group labelforadduser">
                  <label className="form-label ">
                    Role(s)
                  </label>
                  <select
                    name="role"
                    id=""
                    className="form-control formadduser"
                    onChange={this.handleChange}
                  >
                    <option value=''>Select atleast one role</option>
                    <option value="Admin">Admin</option>
                    <option value="Other users">Other User</option>
                  </select>
                </div>

              </div> */}

              {/* <div className="mb-2">
                <div className="form-group">
                  <label className="form-label">
                    Department(s)
                  </label>
                  <select
                    name="department"
                    id=""
                    className="form-control"
                    onChange={this.handleChange}
                  >
                    <option value=''>Select atleast one department</option>
                    <option value="Operations">Operations</option>
                    <option value="Sales">Sales</option>
                    <option value="Credit">Credit</option>
                    <option value="Accounts">Accounts</option>
                  </select>
                </div>

               
              </div> */}
              <div className="mb-4">
                <div className="form-group labelforadduser">
                  <label className="form-label ">
                    Role
                  </label>
                  <div className='d-flex roleTabs p-1'>
                    {/* <Stack direction="row"
                    className='stackModes'

                  > */}
                    {/* <AntTabs
                      value={this.state.value}
                      onChange={this.handleChangeTabs}
                      TabIndicatorProps={{
                        hidden: true
                      }}
                      className='stackModesTabs'
                    >
                      <AntTab label="Admin" className='stackModesTabs2' />
                      <AntTab label="User" className='stackModesTabs1' />
                    </AntTabs> */}
                    <div className={`${this.state.activeRoleTab === 'Admin' ? 'wizr-role-tab-active' : ''} cursor-point wizr-role-tab`}
                      onClick={() => this.setState({ activeRoleTab: "Admin" })}
                    >Admin</div>
                    <div className={`${this.state.activeRoleTab === 'User' ? 'wizr-role-tab-active' : ''} cursor-point wizr-role-tab`}
                      onClick={() => this.setState({ activeRoleTab: "User" })}
                    >User</div>
                    {/* </Stack> */}
                  </div>
                </div>
                {
                  this.state.isError &&
                  <div style={{ color: "red" }} className="text-left pt-4 mb-4">
                    {this.state.errormsg}
                  </div>
                }
              </div>



              <div className="pt-4 mb-4 savebtn">
                <Button
                  type="submit"
                  className="btn btn-default_ subBtn saveButton"
                  disabled={this.state.isErrorName == false && this.state.username != '' && this.state.isEmail && this.state.isEmailvalid && this.state.mobileNumber && this.state.isValid ? false : true}

                >
                  Save
                </Button>
              </div>
            </Modal.Body>
          </form>
        </Modal>
      </>
    )
  }

}

function mapStateToProps(state) {
  const { adduser_show } = state.model;
  const { user_id, sfid } = state.auth;
  return {
    adduser_show,
    user_id,
    sfid
  };
}


export default connect(mapStateToProps)(AddNewUser)
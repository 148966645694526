import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Form } from "react-bootstrap"
import { uploadDocument, uploadProfile,checkLiveliness,verifyDoc,fraudCheck,validatePan,getDocData} from "../actions/user"
import { closeDocumentDropModel, UploadAllModalCloseSuccess } from "../actions/model";
import { ToastContainer, toast } from 'react-toastify';
const FILE_LIMIT = parseInt(process.env.REACT_APP_FILE_SIZE_LIMIT);

const initial = {
    isFileSelected: false,
    isDragg: false,
    documents: [],
    selectedDoc: [],
    selectedOpt: [],
    livenessScore: 0,
    isLive: false,
    frontDocAttempt: 0,
    backDocAttempt: 0,
    panAttempt: 0,
    profile: null,
    profileBase: '',
    docUploadSuccess:false,
    uploadDocLenght : null,
    UploadCurrentDocIndex:null,
    LoadingAllDocument: false,
    
}

class DocumentDrop extends Component {

    constructor() {
        super()
        this.state = initial
        this.handleFile = this.handleFile.bind(this)
        this.handleDocumentSelect = this.handleDocumentSelect.bind(this)
    }

    componentDidUpdate(prevProps)
    {
        if(prevProps.document_drop_show !== this.props.document_drop_show)
        {
            this.setState(initial);
        }
    }

    closeModel = () => {
        this.props.dispatch(closeDocumentDropModel())
    }

    dragOver = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        this.setState({ isDragg: true});
    }
    
    dragEnter = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        this.setState({ isDragg: true});
    }
    
    dragLeave = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        this.setState({ isDragg: false});
    }
    
    fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        let selectedFile = [];
        this.setState({isDragg: false});
        for(var i = 0; i < files.length; i++)
        {
            selectedFile.push(files[i]);
        }
        this.setState({ documents: selectedFile, selectedDoc: []});
    }

    deleteRow = (row) => {
        let arr = this.state.documents;
        arr = arr.slice(0);
        arr.splice(row, 1);
        this.setState({documents: arr});
     }

    handleFile = e => {
        const files = e.target.files;
        let selectedFile = [];
        this.setState({isDragg: false});
        for(var i = 0; i < files.length; i++)
        {
            selectedFile.push(files[i]);
        }
        this.setState({ documents: selectedFile, selectedDoc: []});
    }

    handleDocumentSelect = (id, e) => {
        let document = this.state.documents;
        let selectDoc = this.state.selectedDoc;
        let selectOpt = this.state.selectedOpt;
        let selected = e.target.value;
        let obj = [];
       /*  if(selectOpt.indexOf(selected) != -1)
        {  
            alert("This document alredy selected try another one");
        } */

        selectDoc.forEach((item)=>{
            if(item.id != id)
            {
                obj.push(item);
            }
        });
        var selctedItem = document[id];
        let selectObj = {
            id: id,
            option: selected,
            file:document[id],
        }
        selectOpt.push(selected);
        obj.push(selectObj);
        this.setState({selectedDoc : obj, selectedOpt: selectOpt});
    }

    handlePanSelect = async (file) => {
        // e.preventDefault();
        // const files = e.target.files;
        let type = file.type;
        let fileSize = file.size / 1024 / 1024;
        if (fileSize >= FILE_LIMIT) {
            this.errorToast('File size exceeds');
            this.setState({LoadingAllDocument:false});
        } else {
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                this.uploadPanDocument(type, reader.result, file);
            }.bind(this);
        }
    }

    successToast = (message) => {
        toast.success(message);
    };

    uploadPanDocument = async (panType, panBase, file) => {
        const check = await this.panFraudCheck(file);
        if (check) {
            const { dispatch } = this.props
            let DataType = [];
            let mappedFiles = [];
            let BaseType = [];
            let eduFileType = ["PAN"];
            DataType.push("Pan-Front");
            BaseType.push(panType);
            let panBaseC = panBase;
            const panBase64 = panBaseC.replace(`data:${panType};base64,`, "");
            mappedFiles.push(panBase64);
            let data = {
                base64: mappedFiles,
                doctype: DataType,
                "catType": "PAN",
                basetype: BaseType,
                parent_id: this.props.lead_id,
                id: this.props.lead_id,
                fileType: eduFileType,
                token: this.props.salesForceToken
            }
            await dispatch(uploadDocument(data)).then(response => {
                if(response.status == "success"){
                    this.props.dispatch(verifyDoc({isPanUpload : true,user_sfid:this.props.lead_id}))

                   if((this.state.UploadCurrentDocIndex + 1) < this.state.uploadDocLenght){
                    this.uploaddoc(this.state.selectedDoc[this.state.UploadCurrentDocIndex+1]);
                    this.setState({UploadCurrentDocIndex:this.state.UploadCurrentDocIndex+1})
                 }else{
                    if(this.state.UploadCurrentDocIndex + 1  === this.state.uploadDocLenght){
                        this.setState({docUploadSuccess:true,LoadingAllDocument:false});
                    }
                 }
                    // this.setState({ panBase: panBase })
                    // this.getPanDoc()
                }
            });
        }
    }


    panFraudCheck = async (file) => {
        return new Promise(async (resolve, reject) => {
            try {
                const attempt = this.state.panAttempt;
                if (this.state.profile) {
                    const { dispatch } = this.props
                    var formdata = new FormData();
                    formdata.append("first_image", file);
                    formdata.append("sfid", this.props.lead_id);
                    formdata.append("second_image", this.state.profile);
                    await dispatch(validatePan(formdata)).then(async (response) => {
                        const panAttempt = attempt + 1;
                        if (response.status == "success") {
                            this.successToast("Verified Successfully.");
                            resolve(true);
                        } else {
                            if (panAttempt > 2) {
                                this.setState({ validPic: false, errorMsg: 'Your pan document not verified still we can moved to backend verification',LoadingAllDocument:false });
                                resolve(true);
                            } else {
                                this.errorToast(response.message);
                                this.setState({LoadingAllDocument:false});
                                resolve(false);
                            }
                        }
                        this.setState({ panAttempt: panAttempt});
                    });
                } else {
                    this.errorToast("Your profile picture is not ready, please try after some times!");
                    this.setState({LoadingAllDocument:false});
                    resolve(false);
                }
            } catch (err) {
                reject(err.message ? err.message : err)
            }
        });
    }

    errorToast = (message) => {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT,
        });
        
    };


    renderDocument = (documents) =>{
        let row = [];
        let selectDoc = this.state.selectedDoc;
        documents.map((item, index) => {   
        let selected = '';
        let getData = selectDoc.find(item => item.id == index);
        if(getData != undefined)
        {
            selected = getData.option;
        }
            row.push(
            <li key={`item-${index}`} className="border-bottom list-group-item">
                <span className="ltext"><span className="limitedtext">{item.name}</span> <span 
                style={{cursor:'pointer'}} 
                onClick={() => this.deleteRow(index)}
                ><i className="fas fa-times"></i></span></span>
                <span className="badge badge-pill">
                    <select
                        defaultValue={this.state[`document${index}`]}
                        onChange={e => this.handleDocumentSelect(index, e)}
                    >
                        <option value="" selected={selected ==''?true:false}>Select any document</option>
                        <option value={1} selected={selected =='1'?true:false}>Photo</option>
                        <option value={2} selected={selected =='2'?true:false}>PAN Card</option>
                        <option value={3} selected={selected =='3'?true:false}>Aadhaar Front</option>
                        <option value={4} selected={selected =='4'?true:false}>Aadhaar Back</option>
                        <option value={5} selected={selected =='5'?true:false}>Voter ID Front</option>
                        <option value={6} selected={selected =='6'?true:false}>Voter ID Back</option>
                        <option value={7} selected={selected =='7'?true:false}>Driving License Front</option>
                        <option value={8} selected={selected =='8'?true:false}>Driving License Back</option>
                        <option value={9} selected={selected =='9'?true:false}>Passport First page</option>
                        <option value={10} selected={selected =='10'?true:false}>Passport Last page</option>
                    </select>
                   
                </span>
            </li>
            );
        })
        return row;
    }

    dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }


    toBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
    };

    getPhotoDoc = () => {
        let proData = {
            sfid: this.props.lead_id,
            doc_type: "Photo",
        }
        this.props.dispatch(getDocData(proData)).then((response) => {
            if (response.status === "success") {
                let getData = response.imageData;
                if(getData.length > 0){
                    var img = getData[0]
                    this.setState({
                        profileBase: "data:image/jpg;base64," + img.base64.base64,
                        profileType: "image/jpg",
                        profile: this.dataURLtoFile("data:image/jpg;base64," + img.base64.base64, img.title)
                        // profileId: getData.id
                    });
                }
                // if (getData.profile !== undefined && getData.profile !== "") {
                // }
            }
        });
    }

    checkLiveness = (file) => {
        const { dispatch, lead_id, sfid } = this.props
        let isValid = false;
        var formdata = new FormData();
        formdata.append("files", file);
        formdata.append("user_sfid", lead_id);
        return dispatch(checkLiveliness(formdata)).then((response) => {
            return response;
        });
    }

    uploadProfileImage = async (profileType, profileBase) => {
        const { dispatch } = this.props;
        let proBase = profileBase;
        const proBase64 = proBase.replace(`data:${profileType};base64,`, "");
        const d = new Date()
        const time = d.getTime()
        localStorage.setItem("userBase", proBase64);
        let prodata = {
            base64: proBase64,
            doctype: "Photo",
            "catType": "Photo",
            fname: "eduvan-" + time + '.jpg',
            parent_id: this.props.lead_id,
            id: this.props.lead_id,
            token: this.props.salesForceToken,
            livenessScore: this.state.livenessScore,
            isLive: this.state.isLive,
        }
        await dispatch(uploadProfile(prodata)).then(response => { 

                // this.getPhotoDoc();
                // this.setState({ profileBase: profileBase })
                this.props.dispatch(verifyDoc({isPhotoUpload : true, user_sfid: this.props.lead_id}))

                if((this.state.UploadCurrentDocIndex + 1) < this.state.uploadDocLenght){
                    this.uploaddoc(this.state.selectedDoc[this.state.UploadCurrentDocIndex+1]);
                    this.setState({UploadCurrentDocIndex:this.state.UploadCurrentDocIndex+1})
                }else{
                    if(this.state.UploadCurrentDocIndex + 1  === this.state.uploadDocLenght){
                        this.setState({docUploadSuccess:true,LoadingAllDocument:false});
                    }
                }
        });
    }

    async handleProfileSelect(file) {
        // e.preventDefault();
        // const files = e.target.files;
        let type = file.type;
        let fileSize = file.size / 1024 / 1024;
        if (fileSize >= FILE_LIMIT) {
            this.errorToast("Profile picture size exceeds");
            this.setState({LoadingAllDocument:false});
        } else {
            this.setState({ profile: file });
            const isValidPic = await this.checkLiveness(file)
                .then((response) => {
                    if (response.message) {
                        const getData = response.message;
                        if (getData && getData.statusCode === 101) {
                            const result = getData && getData.result ? getData.result : null;
                            const livenessScore = result && result.livenessScore !== undefined ? result.livenessScore : 0;
                            const validPic = result && result.isLive !== undefined ? result.isLive : false
                            this.setState({ livenessScore: livenessScore, isLive: validPic });
                            var reader = new FileReader();
                            var url = reader.readAsDataURL(file);
                            reader.onloadend = function (e) {
                                this.uploadProfileImage(type, reader.result);
                            }.bind(this);
                        } else {
                            this.setState({ livenessScore: 0, isLive: false });
                            if (getData && getData.message && getData.message == "Insufficient Credits") {
                                this.errorToast("Insufficient Credits");
                                this.setState({LoadingAllDocument:false});
                            } else {
                                this.errorToast("Invalid Profile Picture");
                                this.setState({LoadingAllDocument:false});
                            }

                        }
                    }
                });
        }
    }

    checkFraud = async (file, type, tab) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { dispatch } = this.props
                const { frontDocAttempt, backDocAttempt } = this.state
                var formdata = new FormData();
                formdata.append("files", file);
                if (type == 1) {
                    if (tab == 1) {
                        formdata.append("type", "ADHAR-FRONT");
                    } else if (tab == 2) {
                        formdata.append("type", "DRIVING-LIICENSE-FRONT");
                    } else if (tab == 3) {
                        formdata.append("type", "VOTER-ID-FRONT");
                    } else {
                        formdata.append("type", "PASSPORT");
                    }
                } else {
                    if (tab == 1) {
                        formdata.append("type", "ADHAR-BACK");
                    } else if (tab == 2) {
                        formdata.append("type", "DRIVING-LIICENSE-BACK");
                    } else if (tab == 3) {
                        formdata.append("type", "VOTER-ID-BACK");
                    } else {
                        formdata.append("type", "PASSPORT");
                    }
                }
                formdata.append("sfid", this.props.lead_id);
                await dispatch(fraudCheck(formdata)).then(async (response) => {
                    if (response.status && response.status == "success") {
                        const res = response.result ? response.result : null;
                        const getData = res && res.length > 0 ? res[0] : null;
                        const doctype = getData && getData.type ? getData.type : null;
                        if (type == 1) {
                            this.successToast("Verification Successful!");
                            resolve(true);
                        } else {
                            this.successToast("Verification Successful!");
                            resolve(true);
                        }
                    } else {
                        if (type == 1) {
                            const doctAttempt = frontDocAttempt + 1;
                            this.setState({ frontDocAttempt: doctAttempt });
                            if (doctAttempt > 2) {
                                if (tab == 1) {
                                    this.errorToast("Your adhaar front document not verified still we can moved to backend verification");
                                    this.setState({LoadingAllDocument:false});
                                } else if (tab == 2) {
                                    this.errorToast("Your driving front document not verified still we can moved to backend verification");
                                    this.setState({LoadingAllDocument:false});
                                } else if (tab == 3) {
                                    this.errorToast("Your voter front document not verified still we can moved to backend verification");
                                    this.setState({LoadingAllDocument:false});
                                } else {
                                    this.errorToast("Your passport document not verified still we can moved to backend verification");
                                    this.setState({LoadingAllDocument:false});
                                }
                                resolve(true);
                            } else {
                                this.errorToast(response.message);
                                this.setState({LoadingAllDocument:false});
                                resolve(false);
                            }
                        } else {
                            const doctAttempt = backDocAttempt + 1;
                            this.setState({ backDocAttempt: doctAttempt });
                            if (doctAttempt > 2) {
                                if (tab == 1) {
                                    this.errorToast("Your adhaar back document not verified still we can moved to backend verification");
                                    this.setState({LoadingAllDocument:false});
                                } else if (tab == 2) {
                                    this.errorToast("Your driving back document not verified still we can moved to backend verification");
                                    this.setState({LoadingAllDocument:false});
                                } else if (tab == 3) {
                                    this.errorToast("Your voter back document not verified still we can moved to backend verification");
                                    this.setState({LoadingAllDocument:false});
                                } else {
                                    this.errorToast("Your passport document not verified still we can moved to backend verification");
                                    this.setState({LoadingAllDocument:false});
                                }
                                resolve(true);
                            } else {
                                this.errorToast(response.message);
                                this.setState({LoadingAllDocument:false});
                                resolve(false);
                            }
                        }
                    }
                });
            } catch (err) {
                reject(err.message ? err.message : err)
            }
        });
    }

    uploaddoc = async (item) => {
        let success = false;
        let option = item && item.option;
        let file = item.file
      switch (option) {
        case "1":
          await this.handleProfileSelect(file);
            break;
        case "2":
           await this.handlePanSelect(file);
            break;
        case "3":
          await  this.handleallDoc(file,"Aadhar-Front","Aadhar Front",1,1);
        // console.log("Adhar back response",res3);
        // if(res3 === 'Success'){
        //     success = true;
        // }
            break;

        case "4":
             await this.handleallDoc(file,"Aadhar-Back","Aadhar Back",2,1);
        // console.log(res4);
            // if(res4 === 'Success'){
            //     success = true;
            // }
            break;
        case "5":
          await  this.handleallDoc(file,"Voter-Front","Voter ID",1,3);
            // if(res5 === 'Success'){
            //     success = true;
            // }
            break;

        case "6":
         await   this.handleallDoc(file,"Voter-Back","Voter ID",2,3);
            // if(res6 === 'Success'){
            //     success = true;
            // }
            break;

        case "7":
              await  this.handleallDoc(file,"Driving-Front","Driving License",1,2);
            // if(res7 === 'Success'){
            //     success = true;
            // }
            break;

        case "8":
              await this.handleallDoc(file,"Driving-Back","Driving License",2,2);
            // if(res8 === 'Success'){
            //     success = true;
            // }
            break;
        case "9":
             await this.handleallDoc(file,"Passport-Front","Passport",1);
            // if(res9 === 'Success'){
            //     success = true;
            // }
            break;
        case "10":
            await this.handleallDoc(file,"Passport-Back","Passport",2);
            // if(res10 === 'Success'){
            //     success = true;
            // }
            break;
      
        default:
            break;
      } 
  

    //   console.log("Success",success);
    //   let ele = selectedAllDoc.shift();    
    //   if(ele && success){
    //     uploaddoc(ele);
    //   }
    };


    handleallDoc = async (file,type1,type2,ty,tab) => {
        // const files = e.target.files;
        let type = file.type;
        let fileSize = file.size / 1024 / 1024;
        if (fileSize >= FILE_LIMIT) {
            this.errorToast('File size exceeds');
            this.setState({LoadingAllDocument:false});
        } else {
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
          this.handleIdProof(type, reader.result, file,type1,type2,ty,tab)
        //   .then(res => {
        //     console.log("handleIdProof response:",res);
        //     if(res === "Success"){
        //       return "Success";
        //     }
        //   });
          
            }.bind(this);
        }
    }

    handleIdProof = async (frontProofType, frontProofBase, file,type1,type2,type,tab) => {
       
        const check = await this.checkFraud(file, type, tab);
        if (check) {
            const { dispatch } = this.props
            let DataType = [];
            let mappedFiles = [];
            let Basetype = [];
            let eduFileType = [];

            DataType.push(type1);
            eduFileType.push(type2);
            // if (this.state.selectedTab === 1) {
            //     DataType.push("Aadhar-Front");
            //     eduFileType.push("Aadhar Front");
            // } else if (this.state.selectedTab === 2) {
            //     DataType.push("Driving-Front");
            //     eduFileType.push("Driving License");
            // } else if (this.state.selectedTab === 3) {
            //     DataType.push("Voter-Front");
            //     eduFileType.push("Voter ID");
            // } else {
            //     DataType.push("Passport-Front");
            //     eduFileType.push("Passport");
            // }
            Basetype.push(frontProofType);
            let frontBase = frontProofBase;
            const frontBase64 = frontBase.replace(`data:${frontProofType};base64,`, "");
            mappedFiles.push(frontBase64);
            let data = {
                base64: mappedFiles,
                doctype: DataType,
                basetype: Basetype,
                "catType": "ID Proof",
                parent_id: this.props.lead_id,
                id: this.props.lead_id,
                fileType: eduFileType,
                token: this.props.salesForceToken
            }
            await dispatch(uploadDocument(data)).then(response => {
                if(response.status == "success"){
                    this.props.dispatch(verifyDoc({isAadharUpload : true,user_sfid:this.props.lead_id}))
                    if(this.state.backProofBase){
                      this.props.dispatch(verifyDoc({isKycDone : true,user_sfid:this.props.lead_id}))
                    }
                    
                    if((this.state.UploadCurrentDocIndex + 1) < this.state.uploadDocLenght){
                        this.uploaddoc(this.state.selectedDoc[this.state.UploadCurrentDocIndex+1]);
                        this.setState({UploadCurrentDocIndex:this.state.UploadCurrentDocIndex+1})
                    }else{
                        if(this.state.UploadCurrentDocIndex + 1  === this.state.uploadDocLenght){
                            this.setState({docUploadSuccess:true,LoadingAllDocument:false});
                        }
                    }
                    // this.getOtherDoc()
                  
                }
            });
            let selectedTab = this.state.selectedTab;
            this.setState({ frontProofBase: frontProofBase, defaultTab: selectedTab })
        }
    }


    // handleUploadFile = async () =>{
    //     const { selectedDoc, documents } = this.state
    //     const { dispatch } = this.props
    //     let profileBase = "";
    //     let baseData = [];
    //     let DataType = [];
    //     let fileType = [];
    //     let CatType = [];
    //     let Basetype = [];
    //     let uplodProfile;
    //     let uploadOthrs;
    //     const filePathsPromises = [];
    //      selectedDoc.forEach(file => {
    //         let fileData = documents[file.id];
    //         let type = fileData.type;
    //         fileType.push(type);
    //         filePathsPromises.push(this.toBase64(fileData));
    //       });
    //     const filePaths = await Promise.all(filePathsPromises);
       
    //     let mappedFiles = filePaths.map((base64File) => (base64File));
    //     console.log('mapped',mappedFiles);
    //     selectedDoc.forEach(async (item, index)=>{
    //         let option = item.option;
    //         let type = fileType[index];
    //         let base64File = mappedFiles[index];
    //         let proBase64 = base64File.replace(`data:${type};base64,`, "");

    //         Basetype.push(type);
    //         console.log("probase 64",proBase64);

    //         if(option =='1')
    //         {
    //             profileBase = proBase64;
                
    //         }else{
    //             if(option =='2')
    //             {
    //                 baseData.push(proBase64);
    //                 DataType.push("Pan-Front");
    //                 CatType.push("PAN")
    //             }else if(option =='3')
    //             {
    //                 baseData.push(proBase64);
    //                 DataType.push("Aadhar-Front");
    //                 CatType.push("Aadhar Front");
    //             }else if(option =='4')
    //             {
    //                 baseData.push(proBase64);
    //                 DataType.push("Aadhar-Back");
    //             }else if(option =='5')
    //             {
    //                 baseData.push(proBase64);
    //                 DataType.push("Voter-Front");
    //             }else if(option =='6')
    //             {
    //                 baseData.push(proBase64);
    //                 DataType.push("Voter-Back");
    //             }else if(option =='7')
    //             {
    //                 baseData.push(proBase64);
    //                 DataType.push("Driving-Front");
    //             }else if(option =='8')
    //             {
    //                 baseData.push(proBase64);
    //                 DataType.push("Driving-Back");
    //             }else if(option =='9')
    //             {
    //                 baseData.push(proBase64);
    //                 DataType.push("Passport-Front");
    //             }else if(option =='10')
    //             {
    //                 baseData.push(proBase64);
    //                 DataType.push("Passport-Back");
    //             }
    //         }
    //     });
        
    //      let data = {
    //         base64: baseData,
    //         doctype: DataType,
    //         basetype: Basetype,
    //         catType : CatType,
    //         parent_id: "001C30000039ZTBIA2", 
    //         id: this.props.lead_id,
    //         token: this.props.salesForceToken
    //     }
        
    //     if(profileBase !='')
    //     {
    //         const d = new Date()
    //         const time = d.getTime()
    //         let prodata = {
    //             base64: profileBase,
    //             doctype: "Photo",
    //             catType : "Photo",
    //             fname: "eduvan-"+time+'.jpg',
    //             parent_id: "001C30000039ZTBIA2",
    //             id: this.props.lead_id,
    //             token: this.props.salesForceToken,
    //         }
    //         uplodProfile =  await dispatch(uploadProfile(prodata)).then((response)=>{ return response.status});
    //     }

    //     if(baseData &&  baseData.length > 0)
    //     {
    //         uploadOthrs = await dispatch(uploadDocument(data)).then((response)=>{ return response.status});
    //     }

    //     if(uplodProfile=="success" || uploadOthrs=="success")
    //     {
    //         this.closeModel();
    //     }
 
    // }

    handleUploadFile = async () =>{
        const { selectedDoc, documents } = this.state;
        const { dispatch } = this.props;
        this.setState({uploadDocLenght:this.state.selectedDoc.length,UploadCurrentDocIndex:0})


        // let photoRecord ;
        // for(let i = 0;i< selectedDoc.length;i++){
        //     if(selectedDoc[i].option == 1){
        //       photoRecord = selectedDoc[i];
        //     }
        // }

    //     let arr = selectedDoc.filter(ele => ele.option != 1);
    //     if(photoRecord){
    //         arr = [photoRecord,...arr];
        
    //         const panRecord = selectedDoc.filter(ele => ele.option === 2)
            
    //         if(panRecord.length === 1){
    //             this.errorToast('Please Select the Profile photo');
    //             return ; 
    //         }
            
    // }

    
    const panRecord = selectedDoc.filter(ele => ele.option == 2)
    if(panRecord.length === 1){
        let photoRecord = selectedDoc.filter(ele => ele.option == 1);
        if(photoRecord.length !== 1){
            this.errorToast('Please Select the Profile photo');
            this.setState({LoadingAllDocument:false});
            return;
        }else{
            let arr = selectedDoc.filter(ele => ele.option != 1);
            let item = photoRecord[0];
            this.setState({selectedDoc:[...photoRecord,...arr],LoadingAllDocument:true})
            await this.uploaddoc(item);
            return
        }
    }else{
        let item = selectedDoc[0];
        // this.setState({selectedDoc:arr})
        this.setState({LoadingAllDocument:true})
        await this.uploaddoc(item);
    }

       


        // let selectedAllDoc = selectedDoc;
        // console.log('selectedDoc',selectedAllDoc);
        // let item = selectedAllDoc.shift();
        //  const uploaddoc = async (item) => {
        //             let success = false;
        //             let option = item && item.option;
        //             let file = item.file
        //           switch (option) {
        //             case "1":
        //                 console.log("upload profile");
        //               await this.handleProfileSelect(file);
        //                 break;
        //             case "2":
        //                 console.log("upload Pan");
        //                await this.handlePanSelect(file);
        //                 break;
        //             case "3":
        //             const  res3 =  await  this.handleallDoc(file,"Aadhar-Front","Aadhar Front",1,1);
        //             console.log("Adhar back response",res3);
        //             if(res3 === 'Success'){
        //                 success = true;
        //             }
        //                 break;

        //             case "4":
                        
        //                 const  res4 =  await this.handleallDoc(file,"Aadhar-Back","Aadhar Back",2,1);
        //             console.log(res4);
        //                 if(res4 === 'Success'){
        //                     success = true;
        //                 }
        //                 break;
        //             case "5":
        //                 const  res5 =  await  this.handleallDoc(file,"Voter-Front","Voter ID",1,3);
        //                 if(res5 === 'Success'){
        //                     success = true;
        //                 }
        //                 break;

        //             case "6":
        //                 const  res6 =   await   this.handleallDoc(file,"Voter-Back","Voter ID",2,3);
        //                 if(res6 === 'Success'){
        //                     success = true;
        //                 }
        //                 break;

        //             case "7":
        //                 const  res7 =   await  this.handleallDoc(file,"Driving-Front","Driving License",1,2);
        //                 if(res7 === 'Success'){
        //                     success = true;
        //                 }
        //                 break;

        //             case "8":
        //                 const  res8 =   await this.handleallDoc(file,"Driving-Back","Driving License",2,2);
        //                 if(res8 === 'Success'){
        //                     success = true;
        //                 }
        //                 break;
        //             case "9":
        //                 const  res9 =    await this.handleallDoc(file,"Passport-Front","Passport",1);
        //                 if(res9 === 'Success'){
        //                     success = true;
        //                 }
        //                 break;
        //             case "10":
        //                 const  res10 =  await this.handleallDoc(file,"Passport-Back","Passport",2);
        //                 if(res10 === 'Success'){
        //                     success = true;
        //                 }
        //                 break;
                  
        //             default:
        //                 break;
        //           } 
              

        //         //   console.log("Success",success);
        //         //   let ele = selectedAllDoc.shift();    
        //         //   if(ele && success){
        //         //     uploaddoc(ele);
        //         //   }
        //         };

            //  await this.uploaddoc(item);
    }


    render() {
        const { document_drop_show } = this.props
        const { documents, selectedDoc } = this.state
        const subBtn = {background: '#1F1F2D',borderRadius: '10px',color: '#ffffff'};
        const renderDocument = this.renderDocument(this.state.documents);
        let inputRef;
        // console.log('selectedDoc',this.state.selectedDoc,this.state.documents);
        if(this.state.docUploadSuccess){
            this.closeModel();
            this.props.dispatch(UploadAllModalCloseSuccess());
        }
        return (
            <>
                <Modal show={document_drop_show} className="bulkupload type_1 uploadall">
                    <Modal.Header>
                    <div className="row">
                    <div className="col-md-12">
                    <div className="popupclosebtn">
                        <button type="button" className="close float-left mr-2" onClick={this.state.LoadingAllDocument ? '': this.closeModel} style={{ marginTop:'-25px' }}> <i className="fas fa-times" style={{color:this.state.LoadingAllDocument ? "lightgray":"black"}} ></i> </button>
                        <h5>Upload All</h5>
                        <p>Upload all the KYC documents here</p>
                     </div>
                     </div>
                     </div>
                    </Modal.Header>
                    <form>
                    <Modal.Body>
                    {documents && documents.length === 0 &&(
                    <div className="row" style={{padding: "3px 17px"}}>
                    <div 
                     className={`col-md-12 text-center col-sm drop-container ${this.state.isDragg?"drag-area":""}`}
                     onDragOver={this.dragOver}
                     onDragEnter={this.dragEnter}
                     onDragLeave={this.dragLeave}
                     onDrop={this.fileDrop}
                    >
                        <div 
                           
                        >
                            <h4 className="draganddrop"><b>Drag &amp; Drop files here</b></h4>
                        </div>
                        <input 
                            type='file' 
                            style={{display: 'none'}}
                            ref={refParam => inputRef = refParam}
                            accept="image/x-png,image/gif,image/jpeg,image/jpg"
                            id='profile_pic' 
                            onChange={this.handleFile} 
                            multiple
                        />
                        <div className="or_type1">OR</div>
                        <button type="button" onClick={() => inputRef.click()} className={"btn btn-primary dark-colored"}>
                             Browse
                        </button>
                        <p className="light-weight pb-5 mb-2"><span className="d-block">Select all files to upload them</span>at once</p>
                    </div>
                    </div>
                    )}
                    { documents && documents.length > 0 &&(
                    <div className='col-md-12'>
                        <div className="uploadallfiles">
                            <span className="ltext">File Name</span>
                            <span className="rtext">Document Type</span>
                        </div>
                        <ul className="list-group uploadedfileslist">
                        { renderDocument }
                        </ul>
                        <div className="row justify-content-end align-items-center mt-3 mb-3 mr-3">
                            <button type="button" onClick={this.closeModel} className="btn btn-default" disabled = {this.state.LoadingAllDocument ? true : false}>Cancel</button>
                            <button type="button" onClick={this.handleUploadFile} disabled = {this.state.selectedDoc.length > 0 ? false : true} className="btn btn-secondary" style={selectedDoc.length > 0?subBtn:{}}>Upload</button>
                        </div>
                    </div>
                            )
                        }

                    </Modal.Body>
                    </form>
                </Modal>
            </>
        )
    }

}

function mapStateToProps(state) {
    const { document_drop_show } = state.model;
    const { lead_id } = state.user;
    const { salesForceToken } = state.auth;
    return {
        document_drop_show,
        lead_id,
        salesForceToken
    };
  }

export default connect(mapStateToProps)(DocumentDrop)
import React from 'react'
import { Modal, Button, Form } from "react-bootstrap"
import Box from '@mui/material/Box';
import { Component } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Card from '@mui/material/Card';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomizeCheckbox from '../common/CustomizeCheckbox';
import FilterCustomDivider from '../common/FilterCustomDivider';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputGroup from 'react-bootstrap/InputGroup';

const UserProfileDataArr=[
  {
    id:1,
    checked:false,
    label:'Mackbook 1',
  },
  {
    id:2,
    checked:false,
    label:'Asus',
  },
  {
    id:3,
    checked:false,
    label:'Asus',
  },
  {
    id:4,
    checked:false,
    label:'Asus',
  },
  {
    id:5,
    checked:false,
    label:'Asus',
  },
  {
    id:6,
    checked:false,
    label:'Asus',
  },
  {
    id:7,
    checked:false,
    label:'LG',
  },
  {
    id:8,
    checked:false,
    label:'LG',
  },

  {
    id:9,
    checked:false,
    label:'LG',
  },

  {
    id:10,
    checked:false,
    label:'LG',
  },

  {
    id:11,
    checked:false,
    label:'LG',
  },

  {
    id:12,
    checked:false,
    label:'LG',
  },



];
class ProductFilter extends Component {
    constructor(props) {
        super(props)
       this.state={
        // extensions:[{value: 1, label: 'Excel', extension: 'xls'},{value: 3, label: 'PDF', extension: 'pdf'}]
        UserProfileData:[...UserProfileDataArr],
    professionalcheck:false,
    studentcheck:false,
    checkValStud:false,
    checkValProf:false,
    search:'',
    // studentcheckboxchecked:true,
    // professionalcheckboxchecked:true
      }
    }
    componentDidUpdate(prevProps) {
      if (prevProps.clearfilterValue !== this.props.clearfilterValue) {     
        // console.log("dsjfhdff",this.props.clearfilterValue);
            this.setState({
             UserProfileData:this.state.UserProfileData.map(ele =>{ return {...ele,checked:false}})
           },() => {
             console.log("232",this.state.UserProfileData);
           })    
       }
       
   }
   handleFilterSearch=(e)=>{
    console.log(e.target.value,"we4")
 this.setState({
    search:e.target.value
 })
   }
    handleCheck = (e,index,Type) => {
        
        console.log(Type,index,"sds")
      let {UserProfileData} = this.state;
       if(Type==="UserProfileData"){
        UserProfileData.filter((val)=>val.id===index? val.checked = e.target.checked:'')
        // UserProfileData[index].checked = e.target.checked;
         this.setState({UserProfileData:UserProfileData})
       }
      }
  handleCloseFilterValue = (checkedValue,index) => {
    let {UserProfileData} = this.state;
    UserProfileData[index].checked = checkedValue;
     this.setState({UserProfileData:UserProfileData})
   };
render(){
  return (
 
    <>
    <Card variant="outlined" sx={{border: "none", boxShadow: "none"}} className='mobile-card-padding' >
    <Accordion elevation={0} disableGutters={true} sx={{paddingBottom: '0.573vw'}}    >
         <AccordionSummary className='accordian-summary' 
           expandIcon={<ExpandMoreIcon className='accordian-arrow' />}
           aria-controls="panel2a-content"
           id="panel2a-header"
         >
           <Typography className='text-title-filter'>
             Products
           </Typography>
         </AccordionSummary>
         <AccordionDetails className='accordian-detail'  >
         <>
   <Form>
    <InputGroup className='my-3'>
    <input placeholder='Enter Product name' className='product-search-bar' onChange={(e)=>this.handleFilterSearch(e)} />
    <img src='./img/icon_Search.svg' className='search-product-filter'/>
    </InputGroup>
   </Form>
         <Stack direction="row" gap="10px" marginBottom="15px" flexWrap="wrap">
                  {this.state.UserProfileData?.length &&
                    this.state.UserProfileData.map((ele,i) => {
                        if(ele.checked){
                            return (
                                <button className="btn filterOptchk">
                                  {ele.label}{" "}
                                  <span>
                                    <img className='filterclosebtn'
                                      src="./images/icons/icon-close3.svg"
                                      onClick={() =>
                                        this.handleCloseFilterValue(false,i)
                                      }
                                    />{" "}
                                  </span>{" "}
                                </button>
                              );
                        }                    
                    })}
                </Stack>
</>
{this.state.UserProfileData?.length &&
                this.state.UserProfileData.filter((ele)=>{
                    console.log(ele.label.toLowerCase(),"232")
                    return this.state.search.toLowerCase() === '' ? ele: ele.label.toLowerCase().includes(this.state.search)
                }).map((ele,i) => {
                  return (
                    <Stack key={ele.id}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={2}
                      className="accordion-option"
                    >
                      <FormControlLabel
                        label={
                          <Typography className="text-options-filter">
                            {ele.label}
                          </Typography>
                        }
                        control={
                          <Checkbox
                          sx={{ '& .MuiSvgIcon-root': { fontSize:'1.318vw' } }}
                            color="default"
                            checked={ele.checked}
                            onChange={(e) =>
                              this.handleCheck(e,ele.id,"UserProfileData")
                            }
                          />
                        }
                      />
                    </Stack>
                  );
                })}
        
 
         </AccordionDetails>
       </Accordion>
      
      </Card>
      <FilterCustomDivider/>
     </>
   );
   
      
    
 
 
  
}
}

export default ProductFilter;
const tableSort = (givenArray, sortKey, orderType) => {
    let sortedArray = givenArray.sort((a, b) => {
        if (orderType == 1) {
            if (a[sortKey] < b[sortKey]) {
                return -1;
            }
        }
        if (orderType == -1) {
            if (a[sortKey] > b[sortKey]) {
                return -1;
            }
        }
    })
    return sortedArray
}

export default tableSort;
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { closeModel, closeLeadApplicationModel, openLeadProfileModel, openDrop, openDropModel, openQueryModel, closeLoanCanelModal } from "../actions/model";
import { Modal, Button, Form } from "react-bootstrap"
import { Scrollbar } from "react-scrollbars-custom";
import { getInvoiceData } from '../actions/user';
import { data } from 'jquery';
import jsPDF from 'jspdf';
import $ from 'jquery'
import CenteredPopUp from '../common/CenteredPopUp';

const initialState = {
    mobile: '',
    first_name: '',
    last_name: '',
    email: '',
    product: '',
    product_price: '',
    loan_amount: '',
    isValid: true,
    errorMsg: '',
    isSuccess: '',
    successMsg: '',
    onBoarding: 0,
    active: true,
    invoiceBtn: true,
    html_content: ``,
    showCenteredPopUp: false,
    requestStage: "approveRequest"
};

class SettlementApplication extends Component {

    constructor() {
        super()
        this.state = initialState;
        this.testRef = React.createRef();
        // this.state = {
        //     products: {
        //         proData: []

        //       }
        // }
    }



    componentDidUpdate(prevProps) {
        if (prevProps.settlement_due_show !== this.props.settlement_due_show) {
            this.setState(initialState);
        }
        //     let sfid = localStorage.getItem('sfid');
        //  console.log(sfid,"ppppppppppp")
        //     let getProd = { merchant_id: this.props.sfid }
        //     this.props.dispatch(getSettlementSummary(getProd)).then((response) => {
        //    console.log('yyyyy',response)
        //       if (!response.responseCode) {
        //         this.setState({ DataSet: response });
        //       }
        //     });
        //     if (this.props.products) {
        //       this.setState({ products: this.props.products })
        //     }




    }


    closeModel = () => {
        this.props.dispatch(closeModel())
    }

    // raiseQuery = () =>{
    //     const { dispatch, lead_id } = this.props
    //     let obj = { 
    //         user_sfid: lead_id, 
    //         subject: this.state.query_type, 
    //         description: this.state.quer_description }
    //     dispatch(raiseLeadQuery(obj)).then((response)=>{
    //         if(response && response.status =="success")
    //         {
    //             window.location = '/leads';
    //             this.props.dispatch(closeDropModel());
    //         }
    //     });
    // }


    scrollToBottom = () => {
        var objDiv = document.getElementById("create-lead");
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    stToggle = () => {
        this.setState({ active: !this.state.active });
        this.setState({ invoiceBtn: !this.state.invoiceBtn });
    }
    // stInvoice = () => {
    //     this.setState({ active: true });
    //     this.setState({ invoiceBtn: false });
    // }

    stInvoice = () => {
        const { orderSummary } = this.props
        let data = {
            "opp_sfid": orderSummary.opp_sfid
        }


        this.props.dispatch(getInvoiceData(data)).then((res) => {

            this.setState({ html_content: res.toString() })


            window.$('#invoice_modal').modal('show')

        })

        this.setState({ active: true });
        this.setState({ invoiceBtn: false });
    }

    openLeads = (id) => {
        this.props.dispatch(closeLeadApplicationModel());
        this.props.dispatch(openLeadProfileModel(id));
    }

    openRaiseQuery = () => {
        this.props.dispatch(openQueryModel());
    }

    openDrop = () => {
        this.props.dispatch(openDropModel());
    }

    closeCancelModel = () => {

        this.props.dispatch(closeLoanCanelModal())
    }

    closecenterPopUp = () => {
        this.setState({ showCenteredPopUp: false })
    }

    render() {
        const { isLoading, userMessage, settlementData, orderSummary, settlementType } = this.props
        let item = settlementData
        let a = orderSummary.plan ? orderSummary.plan.application__c : ''
        //console.log('a',a.)
        return (
            <>
                {isLoading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : ''}
                {/* Modal */}

                <div className="modal right fade myModal" id="myModaxl3" role="dialog">
                    <div className="modal-dialog" key={item.id}>
                        {/* Modal content*/}
                        <form className="f_height">
                            <div className="modal-content">

                                <div className="modelbg_1 modal-header settlementModalHeader">
                                    {/* <button type="button" className="abs_close close" data-dismiss="modal"> <i className="fas fa-times"></i> </button> */}
                                    <div className='row justify-content-end'>
                                        <img src="./images/icons/icon-close2.png" style={{height:"1.9531vh"}} className="mr-1 cursor-point" data-dismiss="modal" />
                                    </div>
                                    <div className='row justify-content-between align-items-start mrb4 '>
                                        <div className='col-sm-7'>
                                            {/* <h5 className="modal-title fz24"><b>{orderSummary && orderSummary.customer_name ? orderSummary.customer_name : ''}</b></h5>
                                            <p><b>{orderSummary ? orderSummary.transaction_application_id__c : '-'}</b></p> */}
                                            <h5 className="modal-title settlementUserText">Sneha Sharma</h5>
                                            <p className='settlementUserId mrt2'>AI656236423</p>
                                        </div>
                                        {/* <div className='col-sm-5 d-flex justify-content-lg-end'>
                            <button className='' type='button'><i className="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                            </div>
                         */}
                                        <div>
                                            {/* <button onClick={() => this.openLeads(this.props.id)} className='p-2'><i className="fa fa-pencil" aria-hidden="true"></i></button> */}
                                            {/* <button type='button' className='p-2 ml-2' id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="fa fa-ellipsis-v"></i>
                                            </button> */}
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <i className="fas fa-times" style={{ float: 'right', paddingRight: '5px' }}></i>
                                                <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={this.openRaiseQuery} href={void (0)}>Raise Query</a>
                                                <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={this.closeCancelModel} href={void (0)}>Initiate Cancellation</a>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='row '>
                                        <div className='col-sm-3 settlementModalHeaderText'>
                                            Order Value
                                            <span className="icontext pt2">
                                                <i className="fas fa-rupee-sign size16"></i>
                                                {/* <span className="amtdetails settlementModalHeaderValue"> {orderSummary && orderSummary.order_value ? orderSummary.order_value : '0'}</span> */}
                                                <span className="amtdetails settlementModalHeaderValue">1,49,000</span>

                                            </span>
                                        </div>
                                        {settlementType == 'Settlement' || settlementType == 'Refund' ? (<div className='col-sm-3 settlementModalHeaderText'>
                                            Cancelled On
                                            <span className="icontext pt2">
                                                {/* <i className="fas fa-rupee-sign"></i>  */}
                                                {/* <span className="amtdetails settlementModalHeaderValue">{orderSummary && orderSummary.payment_date ? orderSummary.payment_date : '02-02-2023'}</span> */}
                                                <span className="amtdetails settlementModalHeaderValue">02-02-2023</span>

                                            </span>
                                        </div>) : ""}
                                        {settlementType == 'Cancellation' && (<div className='col-sm-3 settlementModalHeaderText'>
                                            Payment Date
                                            <span className="icontext pt2">
                                                {/* <i className="fas fa-rupee-sign"></i>  */}
                                                {/* <span className="amtdetails settlementModalHeaderValue">{orderSummary && orderSummary.payment_date ? orderSummary.payment_date : '02-02-2023'}</span> */}
                                                <span className="amtdetails settlementModalHeaderValue">02-02-2023</span>

                                            </span>
                                        </div>)}


                                        <div className='col-sm-6 settlementModalHeaderText'>
                                            UTR No.
                                            <span className="icontext pt2">
                                                {/* <i className="fas fa-rupee-sign"></i>  */}
                                                {/* <span className="amtdetails settlementModalHeaderValue">{orderSummary && orderSummary.utr_num ? orderSummary.utr_num : '-'}</span> */}
                                                <span className="amtdetails settlementModalHeaderValue">UTR : HDFC 45647 55675</span>

                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div id="" className="modal-body pt-0  px-0">
                                    <Scrollbar>
                                        {/* stepper code  */}
                                        {settlementType == 'Refund' && (<div>
                                            <div className="col-12 ml-n1 mt-4 pb-4">
                                                <ul className="timeliner Progress-Bar w180">
                                                    <li className="complete Progress-Bar-options">
                                                        <span className="leadTitle steppertitle">Refund Initiated</span>
                                                    </li>
                                                    <li className=' Progress-Bar-options'>
                                                        <span className="leadTitle ml110 stepperEndTitle">Application Closed</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>)}
                                        {/* stepper code end */}
                                        <div className='v-scroll_st px-3'>
                                            <div className='order_summery_wrapper pb3 pt5 px-3'>
                                                <div className='border padding24 courseDetailCardBorder'>
                                                    <div className='d-flex justify-content-between'>
                                                        <div className='d-flex'>
                                                            <div className='CourseSummaryStyle'>Course Summary</div>
                                                            <div className='enrolledDiv mrl2 mt-n1'>
                                                                <div className='enrolledText'>Enrolled on 31/01/22</div>
                                                            </div>
                                                        </div>
                                                        <div className='CourseSummaryCodeStyle pt-2'>AM99-9912-1122-WUCK</div>

                                                    </div>

                                                    <div className='d-flex justify-content-between mrt4 dashedBorder pb4'>
                                                        <div className='w60'>
                                                            <div className='CourseName'>
                                                                Executive PG Programme in Software Development - Specialisation in Full..
                                                            </div>
                                                            <div className='courseDescription mrt1'>
                                                                Skills you'll gain: Accounting, Data Management, Financial Accounting, General Accounting,
                                                            </div>
                                                            <div></div>
                                                        </div>
                                                        <div className='courseAmount'>
                                                            <i className="fas fa-rupee-sign size16"></i> 1,29,000
                                                        </div>

                                                    </div>

                                                    {settlementType == 'Settlement' && (<div className='d-flex justify-content-between mrt4'>
                                                        <div>
                                                            <div className='loanText'>Loan Applied</div>
                                                            <div className='loanTextValue'>Yes</div>
                                                        </div>
                                                        <div>
                                                            <div className='loanText'>Loan Status</div>
                                                            <div className='loanTextValue'>Loan Disbursed</div>
                                                        </div>
                                                        <div>
                                                            <div className='loanText'>Amount Disbursed</div>
                                                            <div className='loanTextValue'><i className="fas fa-rupee-sign size16"></i> 23,000</div>
                                                        </div>
                                                    </div>)}

                                                    {settlementType == 'Cancellation' && (<div><div className='d-flex justify-content-between mt-4'>
                                                        <div>
                                                            <div className='loanText'>Loan Applied</div>
                                                            <div className='loanTextValue'>Yes</div>
                                                        </div>
                                                        <div>
                                                            <div className='loanText'>Amount Disbursed</div>
                                                            <div className='loanTextValue'><i className="fas fa-rupee-sign size16"></i> 23,000</div>
                                                        </div>
                                                        <div>
                                                            <div className='loanText'>Refund Amount</div>
                                                            <div className='loanTextValue'><i className="fas fa-rupee-sign size16"></i> 10,000</div>
                                                        </div>
                                                    </div>
                                                        <div className='mt-4'>
                                                            <div className='loanText'>Status</div>
                                                            <div className='loanTextValue'>Request Pending</div>
                                                        </div>
                                                    </div>
                                                    )}
                                                    {settlementType == 'Refund' && (<div><div className='d-flex justify-content-between mt-4'>
                                                        <div>
                                                            <div className='loanText'>Loan Applied</div>
                                                            <div className='loanTextValue'>Yes</div>
                                                        </div>
                                                        <div>
                                                            <div className='loanText'>Amount Disbursed</div>
                                                            <div className='loanTextValue'><i className="fas fa-rupee-sign size16"></i> 23,000</div>
                                                        </div>
                                                        <div>
                                                            <div className='loanText'>Refund Amount</div>
                                                            <div className='loanTextValue'><i className="fas fa-rupee-sign size16"></i> 10,000</div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    )}
                                                </div>

                                                {settlementType == 'Cancellation' && (<div className='d-flex justify-content-end bottomRight pr-4 pb-4'>
                                                    <div className='rounded-pill d-flex justify-content-center align-items-center border border-dark cursor-point' style={{width:'6.22vw',height:"5.33vh"}} onClick={() => this.setState({ showCenteredPopUp: true, requestStage: "rejectRequest" })} data-dismiss="modal">
                                                        <span className='rejectText' >Reject</span>
                                                    </div>
                                                    <div className='rounded-pill ml-2 d-flex justify-content-center align-items-center bgBlack cursor-point' style={{width:'6.22vw',height:"5.33vh"}} onClick={() => this.setState({ showCenteredPopUp: true, requestStage: "approveRequest" })} data-dismiss="modal"><span className='approveText'>Approve</span> </div>
                                                </div>)}

                                                <CenteredPopUp show={this.state.showCenteredPopUp} closePopUp={this.closecenterPopUp} getWidthclass="w432">
                                                    {this.state.requestStage == "approveRequest" && (<div className='text-center pt4 pb3 mtn4'>
                                                        <div className='waitTextStyle'>Hey, Wait!</div>
                                                        <div className='waitSubTextStyle mrt1'>Are you sure you want</div>
                                                        <div className='waitSubTextStyle'>to continue?</div>
                                                        <div>
                                                            <div className='d-flex justify-content-center mrt5'>
                                                                <div className='rounded-pill d-flex justify-content-center align-items-center border border-dark  h43 w153 cursor-point' onClick={() => this.setState({ showCenteredPopUp: false })}>
                                                                    <span className='rejectButtonText ' >No, Cancel</span>
                                                                </div>
                                                                <div className='rounded-pill ml-2 d-flex justify-content-center align-items-center h43 w153 bgBlack cursor-point' onClick={() => this.setState({ requestStage: "successRequest" })}><span className='approveButtonText'>Yes, Approve</span> </div>
                                                            </div>

                                                        </div>
                                                    </div>)}

                                                    {this.state.requestStage == "rejectRequest" && (<div className='text-center pt4 pb3 mtn4'>
                                                        <div className='waitTextStyle'>Hey, Wait!</div>
                                                        <div className='waitSubTextStyle mt-1'>Are you sure you want</div>
                                                        <div className='waitSubTextStyle'>to continue?</div>
                                                        <div>
                                                            <div className='d-flex justify-content-center mrt5'>
                                                                <div className='rounded-pill d-flex justify-content-center align-items-center border border-dark  h43 w153 cursor-point' onClick={() => this.setState({ showCenteredPopUp: false })}>
                                                                    <span className='rejectButtonText' >No, Cancel</span>
                                                                </div>
                                                                <div className='rounded-pill ml-2 d-flex justify-content-center align-items-center h43 w153 bgBlack cursor-point' onClick={() => this.setState({ requestStage: "successReject" })}><span className='approveButtonText'>Yes, Reject</span> </div>
                                                            </div>

                                                        </div>
                                                    </div>)}

                                                    {this.state.requestStage == "successReject" && (<div className='text-center pt4 pb3 mtn4'>
                                                        <div className='rejectedTextStyle'>Request Rejected</div>
                                                        <div className='waitSubTextStyle mt-1'>Your cancellation request</div>
                                                        <div className='waitSubTextStyle'>rejected</div>
                                                        <div className="d-flex justify-content-center mrt5">
                                                            <div className='rounded-pill ml-2 d-flex justify-content-center align-items-center h43 w153 bgBlack cursor-point' onClick={() => this.setState({ showCenteredPopUp: false })}><span className='gotoleadsText'>Go to Leads</span> </div>
                                                        </div>
                                                    </div>)}

                                                    {this.state.requestStage == "successRequest" && (<div className='text-center pt4 pb3 mtn4'>
                                                        <div className='requestSuccessTextStyle'>Request Successful!</div>
                                                        <div className='waitSubTextStyle mt-1'>Your cancellation request</div>
                                                        <div className='waitSubTextStyle'>successful!</div>
                                                        <div className="d-flex justify-content-center mrt5">
                                                            <div className='rounded-pill ml-2 d-flex justify-content-center align-items-center h43 w153 bgBlack cursor-point' onClick={() => this.setState({ showCenteredPopUp: false })}><span className='gotoleadsText'>Go to Leads</span> </div>
                                                        </div>
                                                    </div>)}
                                                </CenteredPopUp>
                                            </div>
                                        </div>
                                    </Scrollbar >
                                </div >

                            </div >
                        </form >
                    </div >

                </div >
                {/*Model Stop*/}




                <div className="modal fade" id="invoice_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl w-100" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"></h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <iframe id="iframe" style={{ display: "block" }} srcdoc={this.state.html_content} width="100%" height="600"></iframe>

                            </div>
                        </div>
                    </div>
                </div>




            </>
        )
    }

}

function mapStateToProps(state) {
    const { settlement_due_show } = state.model;
    const { isLoading, user_id } = state.auth;
    const { userMessage, settlementData, orderSummary, settlementType } = state.user;
    return {
        settlement_due_show,
        user_id,
        userMessage,
        isLoading,
        settlementData,
        orderSummary,
        settlementType
    };
}

export default connect(mapStateToProps)(SettlementApplication)
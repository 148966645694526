import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { closeModel } from "../actions/model";
import { getMerchantProductList, getCategory, createProduct, getMerchantProducts, getFilterMaster, merchantSettlementDownload } from "../actions/user";
import { openBulkNewProdModel } from "../actions/model";
import { Scrollbar } from "react-scrollbars-custom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import XlsxPopulate, { Promise } from "xlsx-populate";
import { saveAs } from "file-saver";
import readXlsxFile from 'read-excel-file'
import { history } from '../helpers/history';
import { finalDate } from '../common/commonFunctions';
import axios from 'axios'
import fileDownload from 'js-file-download'
import { createBulkLeads, getLeads, bulkCreateLeadRequest } from "../actions/user"

const SECTION1 = 0;
const SECTION2 = 1;
const SECTION3 = 2;
const initialState = {
    product: '',
    product_price: '',
    product_name: '',
    category: '',
    brand: '',
    description: '',
    isValid: true,
    errorMsg: '',
    isSuccess: '',
    successMsg: '',
    onBoarding: SECTION1,
    showErr: false,
    loanerrmsg: '',
    brandData:[],
    selectedFile:"",
    showDownloadCsvToast:false,
    showUploadDetails:true,
    finalTemplateData :[],
    loadedFile: null,
    isFrontUploading: false,
    isBackUploading: false,
    leads: '',
    selected: '',
    uploadUpdatedFile: false,
    fileTaken: false,
    selectedFileName: '',
    uploadingData: null,
    uploadingDone: true,
    totalCount: 0,
    uploadedResponseStatus: false,
    uploadedCount: 0,
    rejectedCount: 0,
    uploadedResponse: {},
    error_upload:''
    
};


class LeadBulkUpload extends Component {

    constructor() {
        super()
        this.state = initialState;
        this.testRef = React.createRef();

    }

    componentDidMount() {

        // commented api
        // let getProd = { merchant_id: this.props.user_id }
        // this.props.dispatch(getMerchantProductList(getProd));
        // commented api because unneceessary call
        //this.props.dispatch(getCategory());

       //api dont need
        // this.props.dispatch(getFilterMaster(localStorage.getItem('user_id'), 'brand')).then((response) => {
        //     console.log(response, 'brand response')
        //    const brand = [];
        //    const allbrand =  response?.data ? response.data : [];
        //    allbrand.forEach(element =>{ brand.push({ value:element.name, label:element.name})});
        //     this.setState({ brandData:brand })
        // })

        const finalD = finalDate();
        this.setState({finalTemplateData :finalD})

    }

    componentDidUpdate(prevProps) {
        if (prevProps.add_new_product_show !== this.props.add_new_product_show) {
            this.setState(initialState);
        }
    }

    onNextBording = (next) => {
        this.setState({ onBoarding: next });
    }

    handleBulkDownload = (url) => {
        const d = new Date()
        const time = d.getTime()
        let filename = 'Bulk-leads-' + time + '.xlsx';
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
            })
    }
    closeModel = () => {
        this.props.dispatch(closeModel())
    }
    // openSuccessModel = () => {
    //     this.props.dispatch(openSuccessModel())
    // }


    openNewbulkUpload_modal = (e) => {
        e.preventDefault();
        this.props.dispatch(openBulkNewProdModel())
    }

    handleChange = (e) => {
        // this.setState({ [e.target.name]: e.target.value });
        if(e.target.name === 'brand'){

            this.setState({ [e.target.name]: e.target.value.replace(/[^a-z]/gi, '') });
        }else{
            this.setState({ [e.target.name]: e.target.value});
        }
    }

    handleClose = () => {
        // this.setState({ state: SECTION1 });
        this.setState(initialState);
    }


    onlyNumbers = (e) => {
        var pattern = new RegExp(/^(?!(0))\d+$/);
        if (e.target.value !== '') {
            if (!pattern.test(e.target.value)) {
                // this.setState({ [e.target.name]: "" });
            } else {
                this.setState({ [e.target.name]: e.target.value });
            }
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    // onlyNumbers = (e) => {
    //     var pattern = new RegExp(/^[0-9\b]+$/);
    //     if(e.target.value !=='')
    //     {
    //       if(!pattern.test(e.target.value))
    //       {
    //         this.setState({[e.target.name] : ""});
    //       }else{
    //         this.setState({[e.target.name] : e.target.value});
    //       }
    //     }else{
    //       this.setState({[e.target.name] : e.target.value});
    //     }
    //   }

    handleSelectProduct = (e) => {
        const { mproducts } = this.props;
        let id = e.value;
        if (id != '') {
            var selctedItem = mproducts.find(item => item.sfid == id);
            this.setState({ product: id, product_price: selctedItem.mrp__c, category: selctedItem.product_sub_category__c, product_name: selctedItem.name, brand: selctedItem.brand__c });
            this.setState({ onBoarding: SECTION2 });
        } else {
            this.setState({ product_price: '', product: '', product_name: '', brand: '' });
        }
    }
    scrollToBottom = () => {
        var objDiv = document.getElementById("create-lead");
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    handleSubmitProd = (e) => {
        e.preventDefault();
        let data = {
            product_id: this.state.product ? this.state.product : '',
            price: this.state.product_price,
            product_name: this.state.product_name,
            brand: this.state.brand,
            category: this.state.category,
            description: this.state.description,
            merchant_id: this.props.sfid
        };
        this.props.dispatch(createProduct(data)).then((response) => {
            if (response.status === "success") {
                this.setState({ isSuccess: 1, onBoarding: SECTION3, successMsg: response.message });
                // setInterval(document.getElementById("close-create").click(), 5000);
                let getProd = { merchant_id: this.props.user_id }
                this.props.dispatch(getMerchantProductList(getProd));
                //window.location.reload();

            } else {
                if (response.status === "error") {
                    this.setState({ showErr: true, loanerrmsg: response.message })
                }
                else {
                    this.scrollToBottom();
                    this.setState({ isSuccess: 0, onBoarding: SECTION1, successMsg: response.message });
                }

            }
        }).catch((error) => {
            this.setState({ isSuccess: 0, onBoarding: SECTION1, successMsg: error });
        });

    }
    /*
    url=https://eduvanz-api-01.herokuapp.com/api/create_product
    brand: "bajaj"
category: "2-Wheelers"
description: ""
merchant_id: "00171000008GurGAAS"
price: "21000"
product_id: "01t7100DMTTT10313"
product_name: "Demo Test 314"
    
    */



    handleDownload = (url) => {

        let obj = { merchant_id: this.props.user_id, "section": '' }
        let getProd = { merchant_id: this.props.user_id }
        const {mproducts} = this.props;
        //  this.props.dispatch(merchantProductDownload(obj)).then((response) => {
        // this.props.dispatch(getMerchantProductList(getProd)).then((response) => {
            // if (response.proData && response.proData.length > 0) {

                let getData = []
                const m_id = localStorage.getItem("sfid") ? localStorage.getItem("sfid") : localStorage.getItem("sfid")
                for (let i = 0; i < mproducts.length; i++) {
                    let source = mproducts[i];

                    let obj = {
                        // name:source.name,
                        // // merchant_id : source.id,
                        // merchant_id : m_id,
                        // product_id : source.sfid,
                        // new_selling_price : source.offer_price__c,
                        // activation_status : source.activate_product__c,
                        // sku:source.sku,

                        merchant_id: m_id,
                        product_id: source.sfid,
                        new_selling_price: source.price__c,
                        activation_status: 'active',
                        brand: '',
                        name: source.name,
                        description: '',
                        category: source.product_sub_category__c
                        // merchant_id : source.id,
                    }
                    getData.push(obj)
                }

                this.saveAsExcel(getData);
            }
    //     });
    // }





    // saveAsExcel = async (getData) => {
    //     var data = [];
    //     await Promise.all(getData.map(async (elament) => {
    //         const productDet = elament
    //         data.push({
    //             merchant_id: productDet && productDet.merchant_id && productDet.merchant_id != null ? productDet.merchant_id : '',
    //             P_id: productDet && productDet.product_id ? productDet.product_id : '',
    //             // category: productDet && productDet.product_sub_category ? productDet.product_sub_category : '',
    //             // mrp: productDet && productDet.mrp && productDet.mrp != null ? productDet.mrp : '',
    //             // mop: productDet && productDet.mop && productDet.mop != null ? productDet.mop : '',
    //             selling_price: productDet && productDet.new_selling_price && productDet.new_selling_price != null ? productDet.new_selling_price : '',
    //             activation_status: productDet && productDet.activation_status == false ? 'inactive' : 'active',
    //             name: productDet && productDet.name ? productDet.name : '',
    //             sku: productDet && productDet.sku && productDet.sku != null ? productDet.sku : '',
    //         })
    //     }));
    //     let header = ["Merchant Id", "Product Id", "Selling Price", "Activation Status", "Name", "SKU"];
    //     //let header = ["Category","Sub Category","Brand","Product Name","Selling Price","Description"];
    //     XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    //         const sheet1 = workbook.sheet(0);
    //         const sheetData = await this.getSheetData(data, header);
    //         const totalColumns = sheetData[0].length;
    //         sheet1.cell("A1").value(sheetData);
    //         const range = sheet1.usedRange();
    //         const endColumn = String.fromCharCode(64 + totalColumns);
    //         sheet1.row(1).style("bold", true);
    //         sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
    //         range.style("border", true);
    //         return workbook.outputAsync().then((res) => {
    //             saveAs(res, "product_report.xlsx");
    //         });
    //     });
    // }

    // getSheetData = async (data, header) => {
    //     var fields = Object.keys(data[0]);
    //     var sheetData = data.map(function (row) {
    //         return fields.map(function (fieldName) {
    //             return row[fieldName] ? row[fieldName] : "";
    //         });
    //     });
    //     sheetData.unshift(header);
    //     return sheetData;
    // }

    saveAsExcel = async (getData) => {
        var data = [];
        await Promise.all(getData.map(async (elament) => {
            const productDet = elament
            data.push({
                merchant_id: productDet && productDet.merchant_id && productDet.merchant_id != null ? productDet.merchant_id : '',
                P_id: productDet && productDet.product_id ? productDet.product_id : '',
                // category: productDet && productDet.product_sub_category ? productDet.product_sub_category : '',
                // mrp: productDet && productDet.mrp && productDet.mrp != null ? productDet.mrp : '',
                // mop: productDet && productDet.mop && productDet.mop != null ? productDet.mop : '',
                selling_price: productDet && productDet.new_selling_price && productDet.new_selling_price != null ? productDet.new_selling_price : '',
                activation_status: 'Active',
                brand: productDet && productDet.brand && productDet.brand != null ? productDet.brand : '',
                name: productDet && productDet.name ? productDet.name : '',
                description: productDet && productDet.description && productDet.description ? productDet.description : '',
                category: productDet && productDet.category && productDet.category != null ? productDet.category : '',
            })
        }));
        let header = ["Merchant Id", "Product Id", "Selling Price", "Activation Status", "Brand", "Name", "Description", "Category"];
        //let header = ["Category","Sub Category","Brand","Product Name","Selling Price","Description"];
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = await this.getSheetData(data, header);
            const totalColumns = sheetData[0].length;
            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);
            sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                saveAs(res, "product_report.xlsx");
            });
        });
    }

    getSheetData = async (data, header) => {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
          return fields.map(function (fieldName) {
            return row[fieldName] ? row[fieldName] : "";
          });
        });
        sheetData.unshift(header);
        return sheetData;
      }

      selectBrand = (selectedOptions) => {
        this.setState({ brand:selectedOptions.value});
        
      }

      generateCsv = async() => {
        // const { sfid } = this.props
        // let stage = this.state.stage;
        // let obj = `stage=${stage}`;
        // this.props.dispatch(merchantSettlementDownload(obj, sfid)).then(async(response) => {
        //   if (response.responseCode !== undefined && response.responseCode === 400) {
    
        //   } else {
        //     const getData = response;
        //     var data = [];
    // await Promise.all(getData.map.slice(0,3)(async (item, index) => {
    //   data.push({
    //     s_no: index + 1,
    //     opp_id: item.transaction_application_id__c ? item.transaction_application_id__c : '-',
    //     name: item.name ? item.name : '-',
    //     product_name: item.product_name ? item.product_name : '-',
    //     product_mrp: item.product_mrp ? item.product_mrp : '-',
    //     status: item.status ? item.status : '-',
    //   })
    
    // }));
    let header = ["", "Course Name", "Course Fee", "Course ID", "Medium", "Subject","Respective Skills","Batch Start Date","Last Enrolment Date","Course Level","Course Eligibility Required","Scholarship","Certificate Provided","Certificate Providing Authority","Course Duration","Delivery Mode","Course Learning Mode","Course/ Class Location","Type","Semester","Module","Course"];
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = await this.getSheetData(this.state.finalTemplateData, header);
      const totalColumns = sheetData[0].length;
      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.row(2).style("bold", true);
      sheet1.range("A1:" + endColumn + "1").style("fill","fcdeb8");
      sheet1.range("A2:A6").style({bold:true,fill:"D3D3D3"});
    //   sheet1.column("A1:" + endColumn + "1").width(25).hidden(false);
      sheet1.range("A1:A1").style("fill","FFFFFF");
      sheet1.range("B2:N2").style("fill","F1E5AC");
      sheet1.range("O2:S2").style("fill","ddfada");
      sheet1.range("T2:V2").style("fill","e4f2f7");
      range.style("border", true);
     

       // const range4 = workbook.sheet(0).range("B1:N1");
    // range4.style({fill:"F1E5AC"})

    // const range5 = workbook.sheet(0).range("O1:S1");
    // range5.style({fill:"ddfada"})
   
    // const range6 = workbook.sheet(0).range("T1:V1");
    // range6.style({fill:"e4f2f7" })
   

  

      //merge cellss 
    const range1 = workbook.sheet(0).range("B1:N1");
    range1.value("Basic Details");
    range1.style({horizontalAlignment: "center", verticalAlignment: "center",fill:"f0bf60"})
    range1.merged(true);

    const range2 = workbook.sheet(0).range("O1:S1");
    range2.value("Duration & Delivery");
    range2.style({horizontalAlignment: "center", verticalAlignment: "center", fill:"BDF5BD"})
    range2.merged(true);

    const range3 = workbook.sheet(0).range("T1:V1");
    range3.value("Syllabus");
    range3.style({horizontalAlignment: "center", verticalAlignment: "center",fill:"ADD8E6" })
    range3.merged(true);
    


   
      return workbook.outputAsync().then((res) => {
        saveAs(res, "report.xlsx");
        this.setState({showDownloadCsvToast:true})
        setTimeout(() => {
            this.setState({showDownloadCsvToast:false})
        }, 3000);
      });
    });
        //   }
        // });
      }
    
      handleFileSelect = (e) => {
      this.setState({
        loadedFile:e.target.files[0]
    })
      if(e.target.files.length > 0){
        this.setState({selectedFile:e.target.files[0]})
      }

      }
     
      uploadProcessing = () => {
     
        readXlsxFile(this.state.loadedFile).then((rows) => {
            //this.uploadingStage(rows)
            this.uploadProducts(rows);
            this.setState({ fileTaken: false, uploadUpdatedFile: false, uploadUpdatedFile: true })
        });
    }
    
    handleBackFileSelect = (event) => {
        const files = event.target.files;
        readXlsxFile(files[0]).then((rows) => {
            this.setState({ leads: rows, isSuccess: '', successMsg: '', selected: files[0].name, fileTaken: true, selectedFileName: files[0].name, loadedFile: files[0] });
            //this.uploadLeads()
        });
    }

    uploadProducts = async (getData) => {
        const { dispatch, sfid, user_id } = this.props
        let total = getData ? (getData.length - 1) : 0;
        this.setState({ totalCount: total })
        let now = 0;
        let obj = {
            total: total,
            now: now
        }
        let j = 0;
        let uploadingDatas = []
        for (let i = 1; i < getData.length; i++) {
            let data = {
                fname: getData[i][0],
                lname: getData[i][1],
                email: getData[i][3],
                mobile: getData[i][4],
                loan_amount: getData[i][7],
                
                product_id: getData[i][5],
                merchant_sfid: getData[i][0],
                product_price: getData[i][6],
                
            }
            uploadingDatas.push(data)
            getData[i][4] = String(getData[i][4])
        }
        this.setState({ uploadingData: uploadingDatas })
        let headers = ["merchant_sfid", "fname", "lname", "email", "mobile", "product_id", "product_price", "loan_amount"];
        getData[0] = headers;
        this.uploadingStage(getData)

        // await Promise.all(getData.map(async (elament) => {
        //     if (j !== 0) {
        //         let data = {

        //             merchant_sfid: elament[0],
        //             fname: elament[1],
        //             lname: elament[2],
        //             email: elament[3],
        //             mobile: elament[4],
        //             product_id: elament[5],
        //             product_price: elament[6],
        //             loan_amount: elament[7],
        //         }
        //         await dispatch(bulkCreateLeadRequest(data)).then((response) => {
        //             console.log('bulk resp', response)
        //             if (response.status !== undefined && response.status === "success") {
        //                 this.setState({ isSuccess: 1, successMsg: response.message, products: '', selected: '' });
        //                 // setInterval(dispatch(getMerchantProducts(getProd)), 5000);
        //             } else {
        //                 this.setState({ isSuccess: 0, successMsg: '', products: '', selected: '' });
        //             }
        //         });
        //         now = now + 1;
        //         // let obj = {
        //         //     total: total,
        //         //     now: now
        //         // }
        //         // let percent = ((now / total) * 100);
        //         // dispatch(bulkUploadData(obj));
        //         // dispatch(bulkUploadProgress(percent));
        //     }
        //     j = j + 1;
        // }));
        // dispatch(bulkUploadEnd());
    }
    uploadingStage = async (getData) => {
        await this.props.dispatch(bulkCreateLeadRequest(getData)).then((response) => {
            // if(response.leadData[0]["status"] == 'error'){
            //     this.setState({error_upload : response.leadData[0]["message"]})
            //     return
            // }

            if(response.status == 'error'){
                this.setState({error_upload : response.message})
                return
            }
            let uploadCount = response.leadData.length;
            if (uploadCount > 0) {
                let leads = response.leadData
                let totalFailed = 0
                let totalSuccess = 0
                for (let i = 0; i < leads.length; i++) {
                    if (leads[i].status == 'failed') {
                        totalFailed += 1
                    }
                    if (leads[i].status == 'success') {
                        totalSuccess += 1
                    }
                }
             

                this.setState({ totalCount: uploadCount, uploadedResponseStatus: true, uploadedCount: totalSuccess, rejectedCount: totalFailed, uploadedResponse: response.leadData, isSuccess: 1, successMsg: response.leadData[0]["message"], products: '', selected: '' });
            }
            else {
                this.setState({ isSuccess: 0, successMsg: response.message, products: '', selected: '' });

            }

            //    if (response.status !== undefined && response.status === "success") {
            //         let uploadCount=response.statusCount;
            //         let totalFailed=0
            //         let totalSuccess=0
            //         for(let i=0;i<uploadCount.length;i++){
            //             if(uploadCount[i].status=='failed'){
            //                 totalFailed=uploadCount[i].count
            //             }
            //             if(uploadCount[i].status=='success'){
            //                 totalSuccess=uploadCount[i].count
            //             }
            //         }
            //         this.setState({ totalCount:response.totalCount,uploadedResponseStatus:response.status,uploadedCount:totalSuccess,rejectedCount:totalFailed,uploadedResponse:response,isSuccess: 1, successMsg: response.message, products: '', selected: '' });
            //     } else {
            //         this.setState({ isSuccess: 0, successMsg: response.message, products: '', selected: '' });
            //     }
        }).catch(err=>{
            console.log(err,'error')
        })
    }
    render() {
        const { isLoading, mproducts, category } = this.props;
        let prodOptions = [];
        if (mproducts) {
            for (var i = 0; i < mproducts.length; i++) {
                let pname = String(mproducts[i].name).slice(0, 15);
                prodOptions.push({ value: mproducts[i].sfid, label: pname });
            }
        }
     
       
        return (
          <>
                {/* {isLoading?(
                <div className="loading">Loading&#8230;</div>
            ):''} */}
                {/* Modal */}
                <div className="modal right fade myModal" id="myModal80" role="dialog">
                    <div className="modal-dialog">
                        {/* Modal content*/}
                        <form onSubmit={this.handleSubmitProd} className="f_height">
                            <div className="modal-content ">

                                <div className="modal-header p-0 add-course-header">
                                  
                                    <button onClick={this.handleClose} type="button" id="close-create" className="close add-course-close" data-dismiss="modal"> <i className="fas fa-times" style={{fontSize:"21px"}}></i> </button>
                                    {this.state.showDownloadCsvToast && <div className='success-toast-con'>
                                        <img src='./images/client/courseSuccess.svg'/>
                                        <div className='fw-600 fz14 lineheight20 fontcolorfig'>Template Downloaded Successfully</div>
                                    </div>}
                                   
                                    <div className='d-flex justify-content-between align-items-center w-100 mt-5'>
                                        <h5 className="modal-title fz24 p-0 add-course-title">Lead Bulk Upload</h5>
                                       <div className='create-new-help cursor-point' data-dismiss="modal" onClick={()=>history.push('/help')}> <button type="button" className='qst mr-1'>?</button> Help</div>
                                    </div>
                               
                                </div>

                                <div id="" className="modal-body pt-0 px-0 mt-3">

                                    <Scrollbar>

                                        <div className='d-flex flex-column add-course-body-bulk'>
                                        
                                          <div className='d-flex flex-column add-course-download'>
                                            <h5>Bulk upload</h5>
                                            <p>Download template from below option, enter product details and then upload the file!</p>
                                            <span>supported file type: .XSL.XLSX upto 5MB</span>
                                          <div className='btn-both-bulk'>
                                          {!this.state.selectedFile?<div className='d-flex justify-content-end'> <button onClick={()=>this.handleBulkDownload("/bulk_leads.xlsx")}>Download template</button></div>:''}
                                           {this.state.selectedFile ? <div className='d-flex justify-content-between align-items-center btn-bulkfile-gap'>
                                           <div className='d-flex align-items-center'>
                                             <p className='fz16 pt0 fw-500 ' style={{position:"absolute",left:"0"}}>{this.state.selectedFile.name}</p>
                                             <i className="fas fa-times mr-4" onClick={() => this.setState({selectedFile:''})} style={{fontSize:"16px",color:'#000000',cursor:'pointer',marginLeft:"25px"}}></i>
                                           </div>
                                            <button className='upload-file-btn'  onClick={()=>this.uploadProcessing()}>Upload</button>
                                            
                                            </div> 
                                            :<div className='d-flex justify-content-end'><input type="file" id="upload" className='d-none' onChange={(e)=>this.handleFileSelect(e)}/> <label className='upload-file-btn-bulk' for="upload">Select File</label></div>}
                                        
                                         </div>
                                         <div className='add-course-download'>
                                            {this.state.error_upload && <p className='bulkUploaderror'>{this.state.error_upload}</p> }
                                            </div>
                                          </div>

                                          {/* <div className='add-course-download add-course-upload-bulk'>
                                              {/* <h5>Upload</h5>
                                            <p>Upload the filled file here</p>
                                            <span>supported file type: .XSL.XLSX upto 5MB</span> */}
                                           
                                          {/* </div> */}
                                              
                                       
                                         {/* {this.state.showUploadDetails && <> <div className='add-course-file d-flex justify-content-between align-items-center'>
                                           <div className='d-flex align-items-center'>
                                             <span>A190507054_Bank_Statement_-_6_Months_1557232217_5cd17a595eed9</span>
                                             <i className="fas fa-times mr-4" style={{fontSize:"16px",color:'lightgrey',cursor:'pointer'}}></i>
                                           </div>
                                           <button >Uploaded</button>
                                          </div>

                                          <div className='add-course-file-preview'>
                                            <p >File Preview</p>
                                           <div className='d-flex align-items-center'>
                                            <div className='add-course-file-count'>Total <span>83</span></div>
                                            <div className='add-course-file-count'>Added <span>83</span></div>
                                            <div className='add-course-file-count'>Failed <span>0</span></div>
                                           </div>
                                          </div>

                                          <div className='mt-4 mb-2'> 
                                          <table className="add-course-file-table" cellSpacing={0} >
                                          <thead>
                                 <tr>
                                <th className='add-course-thead-th'> # </th>
                                <th className='add-course-thead-th'>Course Name</th>
                                <th className='add-course-thead-th' style={{paddingLeft:'20px'}}>Status</th>
                                <th className='add-course-thead-th'>Errors/Warnings</th>
                            </tr>
                        </thead>
                         <tbody>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 1 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 2 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 3 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 4 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 5 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 6 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 7 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 8 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 9 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         
                         
                         </tbody>

                                           </table>
                                          </div></>} */}



                                        </div>


                                    </Scrollbar>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>                        
                {/*Model Stop*/}
            </>
        )
    }

}

function mapStateToProps(state) {
    const { add_new_product_show } = state.model;
    const { isLoading, user_id, sfid } = state.auth;
    const { mproducts, category } = state.user;
    return {
        add_new_product_show,
        user_id,
        mproducts,
        isLoading,
        sfid,
        category,
    };
}

export default connect(mapStateToProps)(LeadBulkUpload)
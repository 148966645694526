import { render } from '@testing-library/react';
import React from 'react';
import { history } from '../helpers/history';
import hi from 'date-fns/esm/locale/hi/index.js';


export default class PageNotFound extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="row pagenotfoundSec">
                <div className='col-sm-12'>
                    <div className='logo'>
                        <img src="./img/WiZRLogoBlack.png" alt='Page not found' className='img-responsive' />
                    </div>
                    <div className='imgnotfound'>
                        <img src="./img/pagenotfound.png" alt='Page not found' className='img-fluid' />
                    </div>
                    <div className='dashboardbtn'>
                        <button type='button' onClick={() => history.push('/')}>Go to Dashboard</button>
                    </div>
                </div>
            </div>
        );
    }

}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Button } from "react-bootstrap"
import { closeSuccessModel, openLeadProfileModel, updateProId, getHighLimit } from "../actions/model"
import { getLeadProfile, setSettlementclickItemId } from "../actions/user"
import { formLabelClasses } from '@mui/material'
// import { notNullish } from 'react-select/dist/declarations/src/utils'

class SuccessModel extends Component {

    constructor() {
        super()
        this.state = {
            isFrontUploading: false,
            isBackUploading: false,
        }
        this.closeModel = this.closeModel.bind(this);
    }

    closeModel = (highLimit) => {
        const { createLeadData } = this.props
        let opp_id = createLeadData ? createLeadData.opp_sfid : "";
        let sfid = createLeadData ? createLeadData.user_sfid : "";
        let id = createLeadData ? createLeadData.user_id : "";
        this.props.dispatch(closeSuccessModel())
        this.props.dispatch(setSettlementclickItemId(opp_id));
        this.props.dispatch(openLeadProfileModel(sfid, id))
        this.props.dispatch(updateProId(opp_id));
        // if (highLimit) {
        //     this.props.dispatch(getHighLimit(true));
        // }
    }

    render() {
        const { success_show, lead_limit, lead_name, lead_age } = this.props
        return (
            <>
                <Modal show={success_show} className="bulkupload type_1">

                    <form>
                        <Modal.Body>

                            <div className="row">

                                <div className="col-md-12 success-popup text-center">
                                    <img src="images/lead_succ.svg" alt="" className='img-fluid' />
                                    <h5>Congratulations!</h5>
                                    <p className="t1">{lead_name ? lead_name : ''} has successfully received a limit of</p>
                                    <div className="row align-items-center justify-content-center"><div className="col-md-12 prizeamt"><i className="fas fa-rupee-sign"></i> {lead_limit ? lead_limit.toLocaleString() : "-"}</div></div>
                                    <p className="t2" style={{ color: "#525252" }}><span className="d-block">Applicant can continue using the approved limit from Eduvanz and,</span>the balance amount can be paid as downpayment by the applicant.</p>
                                    {/* <p className="t2"><span className="d-block">User can also apply for a higher amount,</span>by providing few additional details.</p> */}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12">
                                <div className="d-block text-center mb-3">
                                    <Button onClick={() => this.closeModel(false)} variant="secondary" className="btn btn-dark">Continue with <i className="fas fa-rupee-sign"></i> {lead_limit ? lead_limit.toLocaleString() : "-"}</Button>
                                </div>
                                <div className="d-block text-center mb-3">

                                    {
                                        lead_age >= 21 && lead_limit ?
                                            <>
                                                <span style={{ cursor: 'pointer' }} onClick={() => this.closeModel(true)} className="downloadbulk">
                                                    Get a Higher Limit
                                                </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <span style={{ cursor: 'pointer' }} onClick={() => this.closeModel(false)} className="downloadbulk">
                                                    Add a Co-borrower
                                                </span></>
                                            :
                                            lead_age >= 21 || !lead_age ?
                                                <span style={{ cursor: 'pointer' }} onClick={() => this.closeModel(true)} className="downloadbulk">
                                                    Get a Higher Limit
                                                </span>
                                                : lead_age < 21 ?
                                                    <span style={{ cursor: 'pointer' }} onClick={() => this.closeModel(false)} className="downloadbulk">
                                                        Add a Co-borrower
                                                    </span> : ''

                                    }
                                    {/* <span style={{cursor:'pointer'}} onClick={this.closeModel} className="downloadbulk">
                             {lead_age >= 21 || !lead_age ? "Get a Higher Limit" : "Add a Co-borrower"}   
                        </span> */}
                                </div>
                            </div>
                        </Modal.Footer>
                    </form>
                </Modal>
            </>
        )
    }

}

function mapStateToProps(state) {
    const { success_show, createLeadData } = state.model;
    const { lead_limit, lead_name, lead_age } = state.user
    return {
        success_show,
        lead_name,
        lead_limit,
        createLeadData,
        lead_age
    };
}

export default connect(mapStateToProps)(SuccessModel)
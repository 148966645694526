import React from 'react'
import { Modal, Button, Form } from "react-bootstrap"
// import CourseLevelFilter from "./component/CourseLevelFilter/CourseLevelFilter";
// import DeliveryModeFilter from "./component/DeliveryModeFilter/DeliveryModeFilter";
// import DurationFilter from "./component/DurationFilter/DurationFilter";
// import EducationLevelFilter from "./component/EducationLevelFilter/EducationLevelFilter";
// import FeesStructureFilter from "./component/FeesStructureFilter/FeesStructureFilter";
// import InclusionsFilter from "./component/InclusionsFilter/InclusionsFilter";
// import InstituteFilter from "./component/InstituteFilter/InstituteFilter";
// import LanguageFilter from "./component/LanguageFilter/LanguageFilter";
// import LearningModeFilter from "./component/LearningModeFilter/LearningModeFilter";
// import RatingFilter from "./component/RatingFilter/RatingFilter";
// import SkillsFilter from "./component/SkillsFilter/SkillsFilter";
// import WorkExperienceFilter from "./component/WorkExperienceFilter/WorkExperienceFilter";
import Typography from '@mui/material/Typography';
// import Styles from './Filter.module.scss'
// import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { Component } from 'react';
import FilterCustomDivider from '../common/FilterCustomDivider';
// import CloseIcon from '@mui/icons-material/Close';
// import CloseImg from '../../public/images/icons/icon-close2.png'
// import { styled } from '@mui/material/styles';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import IconButton from '@mui/material/IconButton';


// import FilterButton from '../FilterButton/FilterButton';
// import TimeRequiredFilter from './component/TimeRequiredFilter/TimeRequiredFilter';

const initialState = {
closeModal:false,
}

class FilterMain extends Component {
    constructor() {
        super()
        this.state = initialState;  
    }

    handleClose=()=>{
        console.log("here323")
        this.props.close();
    }

    clearFilter = () => {
      this.props.handleClearfilter();
    }

    // handleApply = () => {
    //   this.props.onhandleApply()
    // }


render(){
    const {show,children}=this.props
//   const [open, setOpen] = React.useState(false);
//   const handleClose = () => setOpen(false);

//   const { filter_show, products } = this.props;
  return (
    
    <>
    {/* <Modal show={show} className='right myModal filter_modal' id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
    <Modal show={show} className='right myModal filter_modal filterbarMainModal' id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
    <button type="button"  onClick={this.handleClose}  className=" filterModalCloseButton" > <img style={{marginTop:"15px"}} src="./images/icons/icon-close2.png"/> </button>
      <Box className='filterDiv'>        
        <div className="filterboxdiv" >
         
          <h1  className='filter-main-title'   >
            Filters
          </h1>
          <div className="filterboxdivoptions">
          <button className='mr-2 filtermainbtn filterclearbtn' onClick = {this.clearFilter} >Clear All</button>
          <button className='filtermainbtn filterapplybtn'>Apply</button>
          </div>
        </div>
<FilterCustomDivider/>
       {this.props.children}
      </Box>
      </Modal>
      
    </>
  );
};
}

// function mapStateToProps(state) {
//     const { user_id, sfid } = state.auth;
//     const { filter_show, products } = state.model;
//     const { leadTab,allMerchantProductData } = state.user
//     return {
//         filter_show,
//         products,
//         user_id,
//         sfid,
//         leadTab,
//         allMerchantProductData
//     };
// }

export default FilterMain;
import React from 'react'
import {
  getSettlement, merchantSettlementDownload, sendSettlementDueDetail, merchantSettlementDetail
} from '../actions/user';
import { salesForceLogin } from "../actions/auth";
import { connect } from "react-redux";
import XlsxPopulate, { Promise } from "xlsx-populate";
import Topbar from "../common/topbar";
import jsPDF from 'jspdf';
import { saveAs } from "file-saver";
import { closeLoanCanelModal } from "../actions/model";
import {
  openBulkModel,
  openLeadProfileModel,

} from "../actions/model"
import Pagination from '@material-ui/lab/Pagination';
import TablePagination from '@mui/material/TablePagination';
import NoRecordsFound from "../common/NoRecordsFound.component";


const dummyData = [{ opp_id: "006C3000004zQL6IAM", transaction_application_id__c: "TA2306276", loan_id__c: "2959", limit_application_ids__c: "LA23061911", created_at: "27/06/2023", status: "Loan Disbursed", amount: 100000, utr: "976234", utr_date: "", name: "Rushabh Damkondwar", mobile: "9333317777", id: 880, sfid: "001C3000007eml9IAA", customer_id__c: "C23061911", product_name: "Tenure Restiction Test", product_price: null, product_mrp: null },
{ opp_id: "006C3000004zQL6IAM", transaction_application_id__c: "TA2306276", loan_id__c: "2959", limit_application_ids__c: "LA23061911", created_at: "27/06/2023", status: "Loan Disbursed", amount: 100000, utr: "976234", utr_date: "", name: "Rushabh Damkondwar", mobile: "9333317777", id: 880, sfid: "001C3000007eml9IAA", customer_id__c: "C23061911", product_name: "Tenure Restiction Test", product_price: null, product_mrp: null },
{ opp_id: "006C3000004zQL6IAM", transaction_application_id__c: "TA2306276", loan_id__c: "2959", limit_application_ids__c: "LA23061911", created_at: "27/06/2023", status: "Loan Disbursed", amount: 900000, utr: "976234", utr_date: "", name: "Rushabh Damkondwar", mobile: "9333317777", id: 880, sfid: "001C3000007eml9IAA", customer_id__c: "C23061911", product_name: "Tenure Restiction Test", product_price: null, product_mrp: null },
{ opp_id: "006C3000004zQL6IAM", transaction_application_id__c: "TA2306276", loan_id__c: "2959", limit_application_ids__c: "LA23061911", created_at: "27/06/2023", status: "Loan Disbursed", amount: 100000, utr: "976234", utr_date: "", name: "Rushabh Damkondwar", mobile: "9333317777", id: 880, sfid: "001C3000007eml9IAA", customer_id__c: "C23061911", product_name: "Tenure Restiction Test", product_price: null, product_mrp: null },
{ opp_id: "006C3000004zQL6IAM", transaction_application_id__c: "TA2306276", loan_id__c: "2959", limit_application_ids__c: "LA23061911", created_at: "27/06/2023", status: "Loan Disbursed", amount: 100000, utr: "976234", utr_date: "", name: "Rushabh Damkondwar", mobile: "9333317777", id: 880, sfid: "001C3000007eml9IAA", customer_id__c: "C23061911", product_name: "Tenure Restiction Test", product_price: null, product_mrp: null },
{ opp_id: "006C3000004zQL6IAM", transaction_application_id__c: "TA2306276", loan_id__c: "2959", limit_application_ids__c: "LA23061911", created_at: "27/06/2023", status: "Loan Disbursed", amount: 300000, utr: "976234", utr_date: "", name: "Rushabh Damkondwar", mobile: "9333317777", id: 880, sfid: "001C3000007eml9IAA", customer_id__c: "C23061911", product_name: "Tenure Restiction Test", product_price: null, product_mrp: null },
{ opp_id: "006C3000004zQL6IAM", transaction_application_id__c: "TA2306276", loan_id__c: "2959", limit_application_ids__c: "LA23061911", created_at: "27/06/2023", status: "Loan Disbursed", amount: 100000, utr: "976234", utr_date: "", name: "Rushabh Damkondwar", mobile: "9333317777", id: 880, sfid: "001C3000007eml9IAA", customer_id__c: "C23061911", product_name: "Tenure Restiction Test", product_price: null, product_mrp: null },
{ opp_id: "006C3000004zQL6IAM", transaction_application_id__c: "TA2306276", loan_id__c: "2959", limit_application_ids__c: "LA23061911", created_at: "27/06/2023", status: "Loan Disbursed", amount: 100000, utr: "976234", utr_date: "", name: "Rushabh Damkondwar", mobile: "9333317777", id: 880, sfid: "001C3000007eml9IAA", customer_id__c: "C23061911", product_name: "Tenure Restiction Test", product_price: null, product_mrp: null },
{ opp_id: "006C3000004zQL6IAM", transaction_application_id__c: "TA2306276", loan_id__c: "2959", limit_application_ids__c: "LA23061911", created_at: "27/06/2023", status: "Loan Disbursed", amount: 500000, utr: "976234", utr_date: "", name: "Rushabh Damkondwar", mobile: "9333317777", id: 880, sfid: "001C3000007eml9IAA", customer_id__c: "C23061911", product_name: "Tenure Restiction Test", product_price: null, product_mrp: null },
{ opp_id: "006C3000004zQL6IAM", transaction_application_id__c: "TA2306276", loan_id__c: "2959", limit_application_ids__c: "LA23061911", created_at: "27/06/2023", status: "Loan Disbursed", amount: 100000, utr: "976234", utr_date: "", name: "Rushabh Damkondwar", mobile: "9333317777", id: 880, sfid: "001C3000007eml9IAA", customer_id__c: "C23061911", product_name: "Tenure Restiction Test", product_price: null, product_mrp: null }]




class SettlementDueeCred extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: "",
      page: 1,
      limit: 10,
      stage: "Settlement Due",
      // page: 1,
      rowsPerPage: 10
    };
    this.formatData = this.formatData.bind(this);
  }

  componentDidMount() {
    const { merchant_sfid } = this.props
    //let objData = `stage=Ready to disburse`;
    let objData = `section=Settlement Due`;

    this.getSettlementsData(objData);
    let getProd = { merchant_id: this.props.user_id }
    let obj = { id: this.props.user_id, token: this.props.token }
    this.props.dispatch(salesForceLogin(obj));

    // $('#sidebarToggleTop').click(function () {
    //   $("#accordionSidebar").toggleClass("open-close")
    // })

    //   let table = new DataTable('#settlement_due_table_dataTable', {
    //     searching: false, paging: false, info: false,
    //     rowReorder: true,
    //     columnDefs: [
    //         { orderable: true, className: 'reorder', targets: 1 },
    //         { orderable: true, className: 'reorder', targets: 4 },
    //         { orderable: false, targets: '_all' }
    //     ]
    // });
  }

  handleChangePageNew = (event, newPage) => {
    this.setState({ page: newPage })
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 })

  };
  getSettlementsData = (getData) => {
    const { sfid } = this.props
    this.props.dispatch(getSettlement(getData, sfid));
  }

  formatData = (dateIs) => {
    let forDate = dateIs.split('/')
    forDate = forDate[1] + '/' + forDate[0] + '/' + forDate[2]
    let newDate = new Date(forDate);
    let months = ["Jan", "Feb", "Mar", "April", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    let year = newDate.getFullYear();
    let date = newDate.getDate();
    let month = newDate.getMonth();
    month = months[month];
    console.log(month, date, year);
    return month + ' ' + date + ', ' + year;
  }

  handleChangePage = (event, value) => {
    const { sfid } = this.props;
    let data = `page=${value}&section=${this.state.stage}&limit=${this.state.limit}`;
    this.setState({ page: value });
    this.props.dispatch(getSettlement(data, sfid));
  }

  handleChangelimitOfPage = (event) => {
    let perRowData = event.target.value;
    const { sfid } = this.props
    // let data =  `page=${perRowData}`;
    let data = `page=1&section=${this.state.stage}&limit=${perRowData}`;
    this.setState({ limit: perRowData });
    this.props.dispatch(getSettlement(data, sfid));
  }
  getSheetData = async (data, header) => {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  generatePDF = () => {

    const { sfid } = this.props
    let stage = this.state.stage;
    let obj = `stage=${stage}`;
    this.props.dispatch(merchantSettlementDownload(obj, sfid)).then((response) => {
      if (response.responseCode !== undefined && response.responseCode === 400) {
      } else {
        const getData = response;
        this.generatePDFData(getData);
      }
    });
  }

  generateCsv = () => {
    const { sfid } = this.props
    let stage = this.state.stage;
    let obj = `stage=${stage}`;
    this.props.dispatch(merchantSettlementDownload(obj, sfid)).then((response) => {
      if (response.responseCode !== undefined && response.responseCode === 400) {

      } else {
        const getData = response;
        this.saveAsExcel(getData);
      }
    });
  }

  generatePDFData = async getData => {
    const doc = new jsPDF();
    const tableColumn = ["#", "Application ID", "Application Details", "Product Name", "Order Value", "Status"];
    const tableRows = [];
    await Promise.all(getData.map((item, index) => {
      const leadData = [
        index + 1,
        item.opp_id ? item.opp_id : '-',
        item.name ? item.name : '-',
        item.product_name ? item.product_name : '-',
        item.product_mrp ? item.product_mrp : '-',
        item.status ? item.status : '-',
      ];
      tableRows.push(leadData);
    }));
    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    const date = Date().split(" ");
    const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
    doc.text("Closed tickets within the last one month.", 14, 15);
    doc.save(`report_${dateStr}.pdf`);
  }

  saveAsExcel = async (getData) => {
    var data = [];
    console.log("getData", getData);
    await Promise.all(getData.map(async (item, index) => {
      data.push({
        s_no: index + 1,
        opp_id: item.opp_id ? item.opp_id : '-',
        name: item.name ? item.name : '-',
        product_name: item.product_name ? item.product_name : '-',
        product_mrp: item.product_mrp ? item.product_mrp : '-',
        status: item.status ? item.status : '-',
      })
    }));
    let header = ["#", "Application ID", "Application Details", "Product Name", "Order Value", "Status"];
    console.log("data", data);
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = await this.getSheetData(data, header);
      const totalColumns = sheetData[0].length;
      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
      range.style("border", true);
      return workbook.outputAsync().then((res) => {
        saveAs(res, "report.xlsx");
      });
    });
  }

  componentWillUnmount() {
    //this.setState({isHovered:false,indexHovered:null})
  }

  render() {
    const { leads, user_id, userMessage, leadsCount, activeWizrTab,searchedKeyword,isLoading,show_searched } = this.props
    let totalPages = 1
    if (userMessage.leadCount) {
      let getArray = userMessage.leadCount[1]
      if (getArray.settlementCount) {
        totalPages = Math.ceil(getArray.settlementCount / Number(this.state.limit));

      }
    }
    console.log('settle due totalPages', totalPages)
    console.log('searchedKeyword11', searchedKeyword,isLoading,show_searched)


    //const totalPages = userMessage.disbursalPendingCount ? Math.ceil(userMessage.disbursalPendingCount / Number(this.state.limit)): 1;

    const refundData = [];
    for (let i = 0; i < 10; i++) {
      refundData.push(<tr className="shown cursor-point trhover" data-toggle="modal" data-target="#myModaxl3" key={i}>
        <td ><p className="mt-2"><b>{i + 1}</b></p></td>
        <td>
          <div className=""><p className="appIDLeads mb-1">AI6125478</p><span className="ai_d appIDDates">May 25,2021</span></div>
        </td>
        <td>
          <div className=""><p className="applicantName mb-1">Sneha Sharma</p> <span className="ai_d applicantNam">9995 888 777</span></div>
        </td>
        <td colSpan={3}>
          <div className=""><p className="appIDCourseName">Executive PG Programme in Software</p><span className="ai_d appIDCourseName">Development-Specialization</span></div>
        </td>
        {activeWizrTab === 'WIZR Skills' && (<td><p className="settleAmount">₹ 1,20,000</p></td>)}
        {activeWizrTab === 'WIZR Skills' && (<td><p className="applicantName">No</p></td>)}
        <td>
          <p className="utrstyle"> UTR: HDFC4564755675</p>
        </td>
        <td><i className={`fa fa-angle-right float-right cursor-point size20`} data-toggle="modal" data-target="#myModaxl3"></i></td>
      </tr>)
    }
     let userMessagee = userMessage
    // userMessagee['proData'] = ;
    // console.log('dummy', userMessagee)
    return (
      <>
        <div className="table-responsive">
          <table
            className="table settlement_due_table tableStyle"
            id="dataTable"
            cellSpacing={0}
          >
            <thead>
              {/* <tr>
                                    <th>
                                        <div className="d-flex all_check">
                                            <input type="checkbox" />
                                            <label>All</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex align-items-center">Application ID
                                            <div className="d-none">
                                                <button className="up"></button>
                                                <button className="down"></button>
                                            </div>
                                        </div>

                                    </th>
                                    <th>Applicant Details</th>
                                    <th>Product Name</th>
                                    <th>
                                        <div className="d-flex align-items-center">Order Value
                                            <div className="d-none">
                                                <button className="up"></button>
                                                <button className="down"></button>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        Payment Expected On
                                    </th>
                                </tr> */}
              <tr>
                <th >
                  <div className="d-flex align-items-center appHead-headinghash">#</div>
                </th>
                <th >
                  <div className="d-flex align-items-center appHead-heading ml-50">Application ID</div>
                </th>
                <th>
                  <div className="d-flex align-items-center appHead-heading">Application Details
                    <div className="d-none">
                      <button className="up"></button>
                      <button className="down"></button>
                    </div>
                  </div>

                </th>
                <th colSpan={0} className='appHead-heading'>Product Name</th>
                <th className='appHead-heading'>Order Value</th>
                <th>
                  <div className="d-flex align-items-center appHead-heading" colSpan={1}>Payment Expected On
                    <div className="d-none">
                      <button className="up"></button>
                      <button className="down"></button>
                    </div>
                  </div>
                </th>
                {/* <th className='appHead-heading'>
                  UTR No.
                </th> */}
                <th className='appHead-heading'>
                  Edit Info
                </th>
              </tr>
            </thead>
            {/* <tbody>
                                {userMessage && userMessage.data.length > 0 &&
                                    (
                                        userMessage.data.map((item, index) => (
                                            <tr>
                                                <td>
                                                    <div className="d-flex" key={index}>
                                                        <div className="single_check">
                                                            <input type="checkbox" className="" />
                                                            <label></label>
                                                        </div>
                                                        <div>
                                                            <div className="new_ribbon">New</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p
                                                        className="ai"
                                                        data-toggle="modal"
                                                        data-target="#myModal4"
                                                    > {item.opp_id ? item.opp_id: '-' }</p>
                                                    <span className="ai_d">{item.created_at ? item.created_at : '-'}</span>
                                                </td>
                                                <td>
                                                    <p>{item.name}</p>
                                                    <span>{item.mobile}</span> 
                                                </td>
                                                <td>{item.product_name}</td>
                                                <td>₹ {item.product_mrp}</td>
                                                <td>
                                                </td>
                                            </tr>
                                        ))
                                    )

                                    
                                }
                            </tbody>  */}
            <tbody>
              {userMessagee && userMessagee.proData && userMessagee.proData.length > 0 ?
                (
                  userMessagee.proData.map((item, index) => (

                    <tr className="shown" key={index} >
                      {/* <td>
                        <div className="d-flex">
                          <div className="single_check">
                            <input type="checkbox" className="" />
                            <label></label>
                          </div>
                          <div>
                            <div className="new_ribbon">New</div>
                          </div>
                        </div>
                      </td> */}

                      <td><p className="mt-2 appIDnum"><b>{index + 1}</b></p></td>

                      {/* <td>
                        <p
                          className="ai"
                          data-toggle="modal"
                          data-target="#myModal5"
                          onClick={() => this.props.dispatch(merchantSettlementDetail(item.opp_id))}

                        >{item.transaction_application_id__c ? item.transaction_application_id__c : '-'}</p> */}

                      {/* <span className="ai_d">{item.created_at ? this.formatData(item.created_at) : '-'}</span>
                      </td> */} 
                      <td>
                        <div className='coursenewlabel'>
                          <div className="new-course-button">NEW</div>
                          <div>
                            {/* <p
     className="ai"
     data-toggle="modal"
     data-target="#myModaxl3"
     onClick={() => this.props.dispatch(merchantSettlementDetail(item.opp_id))}
   >{item.transaction_application_id__c ? item.transaction_application_id__c : '-'}</p>
   <span className="ai_d">{item.created_at ? this.formatData(item.created_at) : '-'}</span> */}
                            <div className=""><p className="appIDLeads mb-1">{item.transaction_application_id__c ? item.transaction_application_id__c : '-'}</p><span className="ai_d appIDDates">{item.created_at ? this.formatData(item.created_at) : '-'}</span></div>

                          </div>
                        </div>
                      </td>

                    {/* <td>
                        <p
                        >{item.name ? item.name : '-'}</p>
                        <span>{item.mobile ? item.mobile : '-'}</span>
                      </td> */}
                       <td><p className="applicantName mb-1 ml-1p">{item.name ? item.name : '-'}</p> <span className="ai_d applicantNam">{item.mobile ? item.mobile : '-'}</span></td>

                      {/* <td>{item.product_name ? item.product_name : '-'}</td>
                      <td>₹ {item.amount ? item.amount : '-'}</td> */}

                              {/* <td><p className="mt-2"><b>{index + 1}</b></p></td> */}
                              <td><p className="appIDCourseName appTextline">{item.product_name ? item.product_name : '-'}</p></td>
                              <td><p className="settleAmount">₹ {item.amount ? item.amount.toLocaleString('en-IN') : '-'}</p></td>
                              <td><p className="appIDCourseName"> {item.created_at ? this.formatData(item.created_at) : '-'}</p></td>
                              <td><i className={`fa fa-angle-right float-right  cursor-point size20`} data-toggle="modal" data-target="#credMyModaxl3" onClick={() => this.props.dispatch(merchantSettlementDetail(item.opp_id))} key={item}></i></td>
                      {/* <td>
                        <span className="ai_d">{item.created_at ? this.formatData(item.created_at) : '-'}</span>
                      </td> */}
                    </tr>

                  ))) : <td colSpan="10">{!isLoading ? <NoRecordsFound message={ `${show_searched ?searchedKeyword:'No Data to show'}`}  show_searched={show_searched}/>: <div  className='norecordsTab'></div>}</td>
              }
              {/* // {refundData} */}
            </tbody>
          </table>
          <div className="d-flex align-items-center justify-content-lg-end justify-content-center mb-4">
            {/* <div className="d-flex align-items-center row_per_page mr-lg-5 mr-4">
            <p>Row per page</p>
            <select page={this.state.page} onChange={this.handleChangelimitOfPage}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div> */}
            <div className="d-flex align-items-center pages">
              {/* <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} /> */}
              {userMessage && userMessage.proData && userMessage.proData.length > 0 && (<TablePagination
                component="div"
                count={200}
                page={this.state.page}
                onPageChange={this.handleChangePageNew}
                rowsPerPage={this.state.rowsPerPage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 20, 50,100]}
              />)}

            </div>

          </div>
        </div>
        {/* <div className="pagination-section d-flex align-items-center justify-content-lg-end justify-content-center mb-4">
                        <div className="d-flex align-items-center row_per_page mr-lg-5 mr-4">
                            <p>Row per page</p>
                            <select>
                                <option>10</option>
                                <option>20</option>
                                <option>30</option>
                            </select>
                        </div>
                        <div className="d-flex align-items-center pages">
                            <p>Page</p>
                            <span className="first">1</span>
                            of
                            <span className="last">52</span>
                            <button className="prev_page"></button>
                            <button className="next_page"></button>
                        </div>

                    </div> */}




      </>
    );
  }
}

function mapStateToProps(state) {
  const { leadsCount,searchedKeyword,show_searched } = state.user;
  const { token, user, sfid, user_id,isLoading } = state.auth;

  const { message } = state.message;
  return {
    user,
    token,
    user_id,
    sfid,
    message,
    leadsCount,
    searchedKeyword,
    isLoading,
    show_searched
  };
}

export default connect(mapStateToProps)(SettlementDueeCred);

import React from 'react'
import { getMerchantProductsByStatus, merchentProductStatusUpdate, merchentProductSellingPriceUpdate, getSingleProductData, updateLoadingProductData, getMerchantProducts } from "../actions/user";
import Pagination from '@material-ui/lab/Pagination';
import { connect } from 'react-redux';
import TablePagination from '@mui/material/TablePagination';
import tableSort from "../common/tableSort"
import NoRecordsFound from './NoRecordsFound.component';

class ActiveProductsCred extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            sellingValue: "",
            products: {
                proData: []
            },
            page: 0,
            limit: 10,
            selectedInputTag: '',
            showInputTag: false,
            EditToggle: false,
            rowsPerPage:10,
            productData:[],
            serachPage:0,
            searchLimit:10,
        }
    }

    handleSatusChange = (item) => {
        let merchantId = localStorage.getItem('user_id')
        let getProd = {}
        let status = ''
        if (!item.activate_product__c) {
            status = 'true'
        } else {
            status = 'false'
        }
        getProd.merchant_id = merchantId
        getProd.product_id = item.sfid
        getProd.status = status


        this.props.dispatch(merchentProductStatusUpdate(getProd)).then((response) => {
            if (!response.responseCode) {
                let get_products = { merchant_id: this.props.user_id, page: this.state.page+1, limit: this.state.limit, status: "active",source:"B2B2C" }
                this.props.dispatch(getMerchantProductsByStatus(get_products)).then((response) => {
                    if (!response.responseCode) {
                        this.setState({ products: response });
                    }
                });
            }
        });
    }
    editprice = async (inputIs) => {
        this.setState({ EditToggle: inputIs })
        await this.setState({ showInputTag: true });
        document.getElementById(inputIs).focus();
        this.setState({ selectedInputTag: inputIs })

    }

    handleChangePage = (event, value) => {
        let getProd = { merchant_id: this.props.user_id, page: value, limit: this.state.limit, status: "active",source:"B2B2C" }
        this.setState({ page: value });
        this.props.dispatch(getMerchantProductsByStatus(getProd));
    }

    handleChangelimitOfPage = (event) => {
        let perRowData = event.target.value;
        let getProd = { merchant_id: this.props.user_id, page: this.state.page, limit: perRowData, status: "active",source:"B2B2C" }
        this.setState({ limit: perRowData });
        this.props.dispatch(getMerchantProductsByStatus(getProd));
    }

    updateSellingPrice = (item) => {
        let getProd = { merchant_id: this.props.user_id, product_id: item.sfid, new_selling_price: this.state.sellingValue, "section": "Product Details" }
        if (this.state.sellingValue != '') {
            this.props.dispatch(merchentProductSellingPriceUpdate(getProd)).then((response) => {
                if (!response.responseCode) {
                    let get_products = { merchant_id: this.props.user_id, status: "active",source:"B2B2C" }
                    this.props.dispatch(getMerchantProductsByStatus(get_products)).then((response) => {
                        if (!response.responseCode) {
                            //this.setState({ products: [] });
                            this.setState({ products: response });
                            this.setState({ EditToggle: '' })
                        }
                    });
                }
            })
        }
        this.setState({ sellingValue: "", showInputTag: false })
    }

    componentDidMount() {
        let getProd = { merchant_id: this.props.user_id, page: this.state.page+1, limit: this.state.limit, status: "active",source:"B2B2C" }
        this.props.dispatch(getMerchantProductsByStatus(getProd)).then((response) => {
            if (!response.responseCode) {
                this.setState({ DataSet: response });
            }
        });
        if (this.props.products) {
            this.setState({ products: this.props.products,productData:this.props.products.proData })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.products != this.props.products) {
            this.setState({ products: this.props.products,productData:this.props.products.proData })
        }
        if (prevProps.globalseraching != this.props.globalseraching) {
          this.setState({ serachPage:0, searchLimit:10 })
        }

    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.load_product_data != nextProps.load_product_data) {
            let get_products = { merchant_id: this.props.user_id, status: "active",source:"B2B2C" }
            this.props.dispatch(getMerchantProductsByStatus(get_products)).then((response) => {
                if (!response.responseCode) {
                    //this.setState({ products: [] });
                    this.setState({ products: response });
                    this.props.dispatch(updateLoadingProductData(false));


                }
            });
            return true
        }
        else {
            return true
        }
    }
    handleChangePageNew = (event, newPage) => { 
      if(this.props.globalseraching){
        this.setState({serachPage:newPage})
        return;
      }
      let getProd = { merchant_id: this.props.user_id, page:newPage+1, limit:this.state.limit,status: "active",source:"B2B2C"}
      this.props.dispatch(getMerchantProductsByStatus(getProd))
      this.setState({page:newPage})
    };
  
     handleChangeRowsPerPage = (event) => {
      if(this.props.globalseraching){
        this.setState({ searchLimit: parseInt(event.target.value, 10),serachPage:0,rowsPerPage:parseInt(event.target.value, 10)});
        return;
      }
      const { sfid } = this.props
      this.setState({ limit: parseInt(event.target.value, 10),page:0,rowsPerPage:parseInt(event.target.value, 10)});
      let getProd = { merchant_id: this.props.user_id, page:1, limit:parseInt(event.target.value, 10),status: "active",source:"B2B2C"}
      this.props.dispatch(getMerchantProductsByStatus(getProd))
    };
  
    sortBy = (givenArray, sortKey, order) => {
      const sortedData = tableSort(givenArray, sortKey, order);
      console.log('sorted data', sortedData)
      this.setState({ productData: sortedData })
    }
    render() {
        const { globalSearch, globalseraching,activeWizrTab,searchedKeyword } = this.props;
        const { products,productData,page,limit,searchLimit,serachPage} = this.state;

    // const products ={proData:["a","a","a","a","a","a","a","a","a","a"]}
        // const totalPages = products.active_product_count ? Math.ceil(products.active_product_count / Number(this.state.limit)) : 1;


        let showPageination = false;
        let totalPages = 1;
        if(globalseraching && globalSearch && globalSearch.length > 0){
          showPageination = true;
          totalPages =  globalSearch ? Math.ceil(globalSearch.length / Number(this.state.searchLimit)) : 1;
    
        }
        if(!globalseraching && productData && productData.length > 0){
          showPageination = true;
          totalPages = products.active_product_count ? Math.ceil(products.active_product_count / Number(this.state.limit)) : 1;
        }
        return (
            <>
                <div className="table-responsive">
                { globalseraching && <div className='search-result-text'>{`${globalSearch?.length ? globalSearch.length : 0} Results found for "${searchedKeyword}"`}</div>}
                <table
            className="table  tableStyle"
            // id="dataTable"
            cellSpacing={0}
          >
            <thead>
              <tr>
                <th className='appHead-headinghash'>
                  #
                </th>
                <th className='appHead-heading' style={{width:'350px',paddingLeft:'60px'}}>Product Details</th>
                {/* {activeWizrTab === 'WIZR Skills' &&(<th className='fz14'>Category</th>)}
                {activeWizrTab === 'WIZR Skills' &&(<th className='fz14'>MRP/MOP</th>)} */}
                 <th className='appHead-heading'>Categories</th>
                 <th className='appHead-heading'>MRP/MOP</th>
                <th className='fz14'>
                  <div className="d-flex align-items-center appHead-heading">Selling Price
                  <div className="" style={{display:'grid'}}>
                                              <button className="up" onClick={() => this.sortBy(products.proData, 'offer_price__c', 1)}></button>
                                              <button className="down" onClick={() => this.sortBy(products.proData, 'offer_price__c', -1)}></button>
                                            </div>
                  
                  </div>
                </th>
                {/* <th className='fz14 text-center'>Activation Status</th> */}
                <th className='appHead-heading'>Edit Info</th>     
                
              </tr>
            </thead>
            <tbody id="all_table_body">

              {globalseraching == true  ?
                <>
                  {globalSearch && globalSearch.length > 0 ?
                    (
                      globalSearch.slice(serachPage*searchLimit,serachPage*searchLimit+searchLimit).map((item, index) => (
                        <tr   className="shown cursor-point trhovercred" key={index}
                        onClick={() => this.props.dispatch(getSingleProductData({ merchant_id: this.props.user_id, product_id: item.sfid }))}
                        >
                          <td className='fz14' data-toggle="modal" data-target="#myModal23">
                            <div className="d-flex">
                              <div className="single_check">

                                <label className='appIDnum'>{index + 1}</label>
                              </div>
                            </div>
                          </td>
                          <td  data-toggle="modal" data-target="#myModal23"
                          >
                             <div className='coursenewlabel'>
                                {/* <div className="sale_ribbon">Sale</div>
                                <div className="new_ribbon">New</div> */}
                                <div className='d-flex flex-column'>
                             {/* {(index=== 0 || index=== 1 || index=== 2) && <div className="new-course-sale">SALE</div> }
                             {(index=== 0 || index===4) && <div className="new-course-button">NEW</div>} */}
                             <div className="new-course-sale">SALE</div> 
                             <div className="new-course-button">NEW</div>
                               
                              </div>
                                {/* <p>Executive PG Programme in Software Development - Specialisation in Full Stack Development</p> */}
                               <div>
                               <p className="appIDLeads appTextline">{item.name ? item.name : ''}</p>
                                <p className="appIDLeads  mt-2">{item.sfid ? item.sfid : ''}</p>
                               </div>
                              </div>
                          
                          </td>
                          <td className='ml-1' data-toggle="modal" data-target="#myModal23">
                          {/* Laptop */}
                          <p className='appIDCourseName'>{item?.product_category__c? item.product_category__c: '-'}</p>
                          </td>

                         
                          <td data-toggle="modal" data-target="#myModal23">
                          {/* <p> <span className='mop-mrp'>MRP </span> ₹ 1,49,000</p>
                            <p><span className='mop-mrp'>MOP </span> ₹ 1,39,500</p> */}
                            <p> <span className='applicantName'>MRP </span> ₹ {item?.mrp__c ? item.mrp__c.toLocaleString('en-IN'):"-"}</p>
                            <p><span className='applicantName'>MOP </span> ₹ {item?.price__c ? item.price__c.toLocaleString('en-IN'):"-"}</p>
                            </td>
                            {/* <td  data-toggle="modal" data-target="#myModal23">
                      
                            <p> <span className='mop-mrp'>MRP </span> ₹ {item?.mrp__c ? item.mrp__c:"-"}</p>
                            <p><span className='mop-mrp'>MOP </span> ₹ {item?.price__c ? item.price__c:"-"}</p>
                            </td> */}
                          <td onClick={e => e.stopPropagation()}>
                                {/* <p>₹ {item?.offer_price__c ? item.offer_price__c : '-'}</p> */}

                                <div className='appIDProfile' style={{display:'flex',gap:"10px"}}>
<div className="d-flex w85" >₹{this.state.showInputTag ? <span><input style={{width:"100px",backgroundColor:"transparent",paddingRight:"20px"}} type="number" defaultValue={item.loan_amount__c ? item.loan_amount__c : 0} onChange={(e) => {
  this.setState({ sellingValue: e.target.value })
}} id={`pirce_input_${index}`} autoComplete="off" readOnly={this.state.selectedInputTag == `pirce_input_${index}` ? false : true} /></span> : ''}
  {!this.state.showInputTag ? <span >{item.loan_amount__c ? item.loan_amount__c.toLocaleString('en-IN') : 0}</span> : ''}
</div>
<div >
<button id={`pirce_input_${index}`} className={`edit_btn ${this.state.EditToggle == `pirce_input_${index}` ? '' : 'd-none'} `} onClick={() => this.updateSellingPrice(item)} >
    {/* <img src="images/icons/close-red.png" alt="" className='img-fluid' /> */}
    {/* <i className="fa fa-save" style={{ fontSize: "15px" }}></i> */}
    <img src="images/correcticon.svg" alt="" className='img-fluid' style={{width:"20px",height:"20px"}}/>
  </button>
  
  <button className={`edit_btn ${this.state.EditToggle !== `pirce_input_${index}` ? '' : 'd-none'} `} onClick={() => { this.editprice(`pirce_input_${index}`) }}   >
    <img src="images/icons/edit_20.png" alt="" className='img-fluid' />
  </button>
  <button className={`edit_btn ${this.state.EditToggle == `pirce_input_${index}` ? '' : 'd-none'} `} onClick={() => this.setState({ sellingValue: "", showInputTag: false,EditToggle:false }) }  >
    <img src="images/crosssmall.svg" alt="" className='img-fluid' style={{width:"20px",height:"20px"}} />
  </button>
</div>
</div>


                          </td>
                          {/* <td data-toggle="modal" data-target="#myModal23">
                              <div className="d-flex align-items-center justify-content-center" >     
                            {item.activate_product__c ?  <>
                            <span className='green-circle '></span> 
                              <span className=''>Active</span>
                              </> :<><span className='red-circle '></span> 
                              <span className=''> Inactive</span></> }
                              </div>
                          </td> */}
                          <td data-toggle="modal" data-target="#myModal23"><i className="fa fa-angle-right float-right pt-2 cursor-point size20" 
                          
 >

</i>
</td>

                        </tr>

                      ))
                    )

                    :
                    <tr className="customodd">
                      {/* <td valign="top" colspan="7" className="dataTables_empty text-center">No data available in table</td> */}
                      <td colSpan="10"><NoRecordsFound message={searchedKeyword?searchedKeyword:''} show_searched={true} /></td>
                    </tr>
                  }
                </>

                :
                <>

                  {productData && productData.length > 0 ?
                    (
                      productData.map((item, index) => (
                        
                        <tr className="shown cursor-point trhovercred" key={index}   
                        onClick={() => this.props.dispatch(getSingleProductData({ merchant_id: this.props.user_id, product_id: item.sfid }))}
                        >
                          <td className='fz14' data-toggle="modal" data-target="#myModal23">
                            <div className="d-flex">
                              <div className="single_check">

                                <label className='appIDnum'>{index + 1}</label>
                              </div>

                            </div>
                          </td>
                          <td  data-toggle="modal" data-target="#myModal23"
                          >
                             <div className='coursenewlabel'>
                                {/* <div className="sale_ribbon">Sale</div>
                                <div className="new_ribbon">New</div> */}
                                <div className='d-flex flex-column'>
                            
                             <div className="new-course-sale">SALE</div> 
                             <div className="new-course-button">NEW</div>
                               
                              </div>
                                {/* <p>Executive PG Programme in Software Development - Specialisation in Full Stack Development</p> */}
                               <div>
                               <p className="appIDLeads appTextline">{item.name ? item.name : ''}</p>
                                <p className="appIDLeads mt-2">{item.sfid ? item.sfid : ''}</p>
                               </div>
                              </div>
                          
                          </td>
                          <td className='ml-1' data-toggle="modal" data-target="#myModal23">
                          {/* Laptop */}
                          <p className='appIDCourseName'>{item?.product_category__c? item.product_category__c: '-'}</p>
                          </td>

                         
                          <td  data-toggle="modal" data-target="#myModal23">
                          {/* <p> <span className='mop-mrp'>MRP </span> ₹ 1,49,000</p>
                            <p><span className='mop-mrp'>MOP </span> ₹ 1,39,500</p> */}
                            <p> <span className='applicantName'>MRP </span> ₹ {item?.mrp__c ? item.mrp__c.toLocaleString('en-IN'):"-"}</p>
                            <p><span className='applicantName'>MOP </span> ₹ {item?.price__c ? item.price__c.toLocaleString('en-IN'):"-"}</p>
                            </td>
                          <td onClick={e => e.stopPropagation()}>
                                {/* <p>₹ {item?.offer_price__c ? item.offer_price__c : '-'}</p> */}

                                {/* <div className="d-flex align-items-center justify-content-around px-2" >
<div className="" >₹{this.state.showInputTag ? <span><input style={{width:"100px",backgroundColor:"transparent",paddingRight:"20px"}} type="number" defaultValue={item.loan_amount__c ? item.loan_amount__c : 0} onChange={(e) => {
  this.setState({ sellingValue: e.target.value })
}} id={`pirce_input_${index}`} autoComplete="off" readOnly={this.state.selectedInputTag == `pirce_input_${index}` ? false : true} /></span> : ''}
  {!this.state.showInputTag ? <span >{item.loan_amount__c ? item.loan_amount__c.toLocaleString('en-IN') : 0}</span> : ''}
</div>
<div className="d-flex align-items-center">
  {this.state.EditToggle ? (
  <button id={`pirce_input_${index}`} className={`edit_btn ${this.state.EditToggle == `pirce_input_${index}` ? '' : 'd-none'} `} onClick={() => this.updateSellingPrice(item)}>
    <img src="images/icons/close-red.png" alt="" className='img-fluid' />
    <i className="fa fa-save" style={{ fontSize: "15px" }}></i>
  </button>

  <button className='edit_btn' onClick={() => { this.editprice(`pirce_input_${index}`) }}   >
    <img src="images/icons/edit_20.png" alt="" className='img-fluid' />
  </button>


</div>

</div> */}
<div className='appIDProfile' style={{display:'flex',gap:"10px"}}>
<div className="d-flex w85" >₹{this.state.showInputTag ? <span><input style={{width:"100px",backgroundColor:"transparent",paddingRight:"20px"}} type="number" defaultValue={item.loan_amount__c ? item.loan_amount__c : 0} onChange={(e) => {
  this.setState({ sellingValue: e.target.value })
}} id={`pirce_input_${index}`} autoComplete="off" readOnly={this.state.selectedInputTag == `pirce_input_${index}` ? false : true} /></span> : ''}
  {!this.state.showInputTag ? <span >{item.loan_amount__c ? item.loan_amount__c.toLocaleString('en-IN') : 0}</span> : ''}
</div>
<div >
<button id={`pirce_input_${index}`} className={`edit_btn ${this.state.EditToggle == `pirce_input_${index}` ? '' : 'd-none'} `} onClick={() => this.updateSellingPrice(item)} >
    {/* <img src="images/icons/close-red.png" alt="" className='img-fluid' /> */}
    {/* <i className="fa fa-save" style={{ fontSize: "15px" }}></i> */}
    <img src="images/correcticon.svg" alt="" className='img-fluid' style={{width:"20px",height:"20px"}}/>
  </button>
  
  <button className={`edit_btn ${this.state.EditToggle !== `pirce_input_${index}` ? '' : 'd-none'} `} onClick={() => { this.editprice(`pirce_input_${index}`) }}   >
    <img src="images/icons/edit_20.png" alt="" className='img-fluid' />
  </button>
  <button className={`edit_btn ${this.state.EditToggle == `pirce_input_${index}` ? '' : 'd-none'} `} onClick={() => this.setState({ sellingValue: "", showInputTag: false,EditToggle:false }) }  >
    <img src="images/crosssmall.svg" alt="" className='img-fluid' style={{width:"20px",height:"20px"}} />
  </button>
</div>
</div>

                          </td>
                          {/* <td data-toggle="modal" data-target="#myModal23">
                              <div className="d-flex align-items-center justify-content-center" >     
                            {item.activate_product__c ?  <>
                            <span className='green-circle '></span> 
                              <span className=''>Active</span>
                              </> :<><span className='red-circle '></span> 
                              <span className=''> Inactive</span></> }
                              </div>
                          </td> */}
                          <td data-toggle="modal" data-target="#myModal23"><i className="fa fa-angle-right float-right pt-2 cursor-point size20" 
                          onClick={() =>
                            console.log("click")
}>

</i>
</td>

                        </tr>                      ))
                    ) : <td colSpan="10"><NoRecordsFound message={'No Data to show '} show_searched={false} /></td>

                  }

                </>
              }
            </tbody>
          </table>              
                <div className="d-flex align-items-center justify-content-lg-end justify-content-center mb-4">
                    {/* <div className="d-flex align-items-center row_per_page mr-lg-5 mr-4">
                        <p>Row per page</p>
                        <select page={this.state.page} onChange={this.handleChangelimitOfPage}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                        </select>
                    </div>
                    <div className="d-flex align-items-center pages">
                        <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} />

                    </div> */}
                  { showPageination && <div className="d-flex align-items-center pages">
                                    
                    <TablePagination
                                      component="div"
                                      count={globalseraching ? globalSearch.length : products.active_product_count}
                                      page={globalseraching ? this.state.serachPage : this.state.page}
                                      onPageChange={this.handleChangePageNew}
                                      rowsPerPage={globalseraching ? this.state.searchLimit :this.state.limit}
                                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                                      rowsPerPageOptions={[10, 20, 50,100]}
                                      labelDisplayedRows={({ page }) => {

                                        return `Page: ${globalseraching ? this.state.serachPage+1:page+1} of ${totalPages} `;

                                      }}

                                      // labelDisplayedRows={<span>Rows:</span>}
                                      
                                    />
                                  </div>}
                </div>
                </div>

            </>
        );
    }
}

function mapStateToProps(state) {
    const { user_id } = state.auth;
    const { load_product_data, globalSearch, globalseraching,activeWizrTab,searchedKeyword } = state.user;



    return {
        user_id,
        load_product_data,
        globalSearch,
        globalseraching,
        activeWizrTab,
        searchedKeyword
    };
}

export default connect(mapStateToProps)(ActiveProductsCred)
import React, { Component } from "react";
import $ from 'jquery';
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Sidebar from "../common/sidebar";
import { getUsers, getRole, getRoleData, getUserData, getReportData, getUserProfile, getEmailsGroup, sendEmailReport, getLeadReportWizr, getCourseReport, logout, NewLeadReport } from "../actions/user"
import {
  openAddUser,
  openAddRole,
  openEditRole,
  openEditUser
} from "../actions/model"
import Typography from '@mui/material/Typography';
import { history } from '../helpers/history';
import { getRoles } from "@testing-library/react";
import TimePicker from 'react-bootstrap-time-picker';
import Topbar from "../common/topbar";
import DateFnsUtils from '@date-io/date-fns';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import XlsxPopulate, { Promise } from "xlsx-populate";
import { saveAs } from "file-saver";
// import { alpha } from '@material-ui/core/styles';
import Form from 'react-bootstrap/Form';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import NativeSelect from '@mui/material/NativeSelect';
import TopWizrbar from "./TopWizrbar";
import { ReactMultiEmail } from "react-multi-email";
import 'react-multi-email/dist/style.css';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TopBarWizrHelpNotification from "./TopBarWizrHelpNotification";
import { isToday } from "date-fns";
import CustomizeCheckbox from "../common/CustomizeCheckbox";
import CustomizeRadioBtn from "../common/CustomizeRadioBtn";
import TextField from '@mui/material/TextField';
import { logOut } from "../actions/auth";


const DAILY = "DAILY";
const WEEKLY = "WEEKLY";
const MONTHLY = "MONTHLY";
const TODAY = "Daily";
const THISWEEK = "Weekly";
const THISMONTH = "Monthly"
const CUSTOM = "Custom";
const LEADS = "Leads";
const PRODUCT_MASTER = "Product Master";
const DISBURSED_CASE = "Disbursed Case";
const DISBURSAL_PENDING = "Disbursal Pending";
const REFUND_CACELLATION = "Refunds/Cancellation";
const WHITELISTED = "Whitelisted Cases";
const STAGE1 = "Loan Disbursed";
const STAGE2 = "Ready to disburse";
const STAGE3 = "Loan Cancelled";
const Weekly = "Weekly";
const Monthly = "Monthly";
const LEADSCRED = "1";
const COURSES = "2";
const SETTLEMENTS = "3";
const IMMEDIATE_DOWNLOAD = "1";
const SCHEDULE_LATER = "2";




class Report extends Component {

  constructor() {
    super()
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.optionSelected = this.optionSelected.bind(this);
    this.selectReport = this.selectReport.bind(this);
    this.DownloadReport = this.DownloadReport.bind(this);
    this.stageReportIs = this.stageReportIs.bind(this);
    this.returnTime = this.returnTime.bind(this);
    this.returnDate = this.returnDate.bind(this);
    this.saveAsExcel = this.saveAsExcel.bind(this);
    this.getSheetData = this.getSheetData.bind(this);
    this.state = {
      // time: new Date(),
      time: "00:00",
      time2: "23:50",
      stime: "00:00",
      wtime: "00:00",
      mtime: "00:00",
      dwstart_date: new Date(),
      dwend_date: new Date(),
      cstart_date: new Date(),
      cend_date: new Date(),
      start_date: new Date(),
      end_date: new Date(),
      range: TODAY,
      report: '',
      days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      all_time: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      dates: Array.from({ length: 31 }, (v, k) => k + 1),
      selectedDay: 'Monday',
      selectedDate: 1,
      email: '',
      emailSuccessMsg: '',
      option1: [],
      option2: "1",
      emails: []
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $('#sidebarToggleTop').click(function () {
      $("#accordionSidebar").toggleClass("open-close")
    })

    //itinerary
    $('.date').click(function () {
      $(this).parent('.accordion_box').siblings('.accordion_box').children('.date').next().slideUp();
      $(this).parent('.accordion_box').siblings('.accordion_box').children('.date').removeClass('active');
      $(this).toggleClass('active');
      $(this).next('.day_plan').slideToggle();
      $(this).next().find('.nav-item').removeClass('active');
      $(this).next().find('.nav-item').first().addClass('active');
    });

    // const { user_id, sfid } = this.props;
    // let data = {
    //   id: user_id,
    // }
    // this.props.dispatch(getUsers(data));
    // let getData = {
    //   owner_id: user_id
    // }
    // this.props.dispatch(getRole(getData));
    // let userData = {
    //   user_sfid: localStorage.getItem('sfid')
    // }
    // this.props.dispatch(getUserProfile(userData))
    //   .then((response) => {
    //     if (response.responseCode === 200)
    //       console.log('email', response.profile.email__c)
    //     {
    //       this.setState({ email: response.profile.email__c });
    //     }

    //   })
    //
    // let usersData = {
    //   "lender_sfid": localStorage.getItem('sfid')
    // }
    // this.props.dispatch(getEmailsGroup(usersData))
    //   .then((response) => {
    //   })

    let lastday = new Date()
    let noOfDays = this.getCurrMonYear()
    let last7day = lastday.setDate(lastday.getDate() - 6)
    let last30day = new Date(new Date().setDate(new Date().getDate() - noOfDays));
    let currentTime = lastday.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    this.setState({ dwstart_date: last7day, start_date: last30day, time2: currentTime })

  }

  getCurrMonYear = () => {
    let date = new Date()
    let month = date.getMonth();
    let year = date.getFullYear();
    let days = new Date(year, month, 0).getDate();
    return days-1;
  }

  handleDateChange = (val, date) => {

    if (val == "start") {
      this.setState({
        start_date: date,
        range: CUSTOM
      })
    } else {
      this.setState({
        end_date: date,
        range: CUSTOM
      })
    }
    this.setState({ time: "00:00" });

  };
  optionSelected = (opt) => {

    switch (opt) {
      case DAILY:
        this.setState({
          // start_date : new Date(),
          // end_date : new Date(),
          range: TODAY
        })
        break;
      case WEEKLY:
        // let curr = new Date(); // get current date
        // var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
        // var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+6));
        this.setState({
          // start_date : firstday,
          // end_date : lastday
          range: THISWEEK
        })
        break;
      case MONTHLY:
        // let date = new Date();
        // let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        // let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.setState({
          // start_date : firstDay,
          // end_date : lastDay,
          range: THISMONTH
        })
        break;
      case CUSTOM:
        this.setState({
          range: CUSTOM
        })
      default:
        break;
    }
  }
  openAddUser = () => {
    this.props.dispatch(openAddUser());
  }

  openAddRole = () => {
    this.props.dispatch(openAddRole());
  }

  openEditRole = (roleid) => {
    const { user_id } = this.props;
    let data = {
      owner_id: user_id,
      role_id: roleid
    }
    this.props.dispatch(getRoleData(data)).then((response) => {
    });
    this.props.dispatch(openEditRole(roleid));
  }

  openEditUser = (usersid) => {
    const { user_id } = this.props;
    let data = {
      owner_id: user_id,
      id: usersid
    }
    this.props.dispatch(getUserData(data)).then((response) => {

    });
    this.props.dispatch(openEditUser(usersid));

  }

  handleTimeChange(time) {
    if (this.state.option2 == SCHEDULE_LATER) {
      this.setState({ stime: time });
    }
  }

  selectReport = (reportIs) => {
    this.setState({
      report: reportIs,
      range: TODAY
    })
  }

  stageReportIs = () => {
    const { report } = this.state
    switch (report) {
      case DISBURSED_CASE:
        return STAGE1
      case DISBURSAL_PENDING:
        return STAGE2
      case REFUND_CACELLATION:
        return STAGE3
      default:
        break;
    }
  }

  returnTime = (value) => {
    let date = new Date(value);
    let hour = date.getHours();
    let minute = date.getMinutes();
    // return hour + ':' + minute
    return value
  }
  returnDate = (value) => {
    let date = new Date(value);
    let day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
    let month = date.getMonth() < 12 ? date.getMonth() + 1 : 1;
    month = month >= 10 ? month : '0' + month;
    let year = date.getFullYear();
    return year + '-' + month + '-' + day
  }

  durationDate = (range) => {
    let selectedDate
    if (range == TODAY) {
      selectedDate = [this.returnDate(new Date())+" 00:00:00", this.returnDate(new Date().setDate(new Date().getDate() + 1))+" 23:59:59"]
    } else if (range == THISWEEK) {
      selectedDate = [this.returnDate(this.state.dwstart_date)+" 00:00:00", this.returnDate(this.state.dwend_date)+" 23:59:59"]
    } else if (range == THISMONTH) {
      selectedDate = [this.returnDate(this.state.start_date)+" 00:00:00", this.returnDate(this.state.end_date)+" 23:59:59"]
    } else if (range == CUSTOM) {
      selectedDate = [this.returnDate(this.state.cstart_date)+" 00:00:00", this.returnDate(this.state.cend_date)+" 23:59:59"]
    }
    return selectedDate;
  }

  getCourseDataReport = (reportdata) => {
    if(reportdata.length > 0){
      for (let i = 0; i < reportdata.length; i++) {
        const data = reportdata[i];
        data.skills__c = data.skills__c ? data.skills__c.join(",") : data.skills__c
      }
    }
    return reportdata
  }

  removeKey = (exlData) => {
    if(exlData.length > 0){
      for (let i = 0; i < exlData.length; i++) {
        const data = exlData[i];
        delete data.timeline_status;
      }
    }
    return exlData;
  }

  DownloadReport = () => {
    const { option2, option1, range, cend_date, cstart_date } = this.state;
    if (this.props.activeWizrTab == "WIZR Skills") {
      // let dataObj={
      //   merchant_id : this.props.sfid,
      //   report_type : option1.includes(LEADSCRED) ? "leads" : "",
      //   download_type : option2 == IMMEDIATE_DOWNLOAD ? "immediate" : "",
      //   duration : range == TODAY ? "today" : range == THISWEEK ? "week" : range == THISMONTH  ? "month" : "custom",
      // }
      let dataObj = {
        merchant_id: this.props.sfid,
        page: "",
        limit: "",
        filter: {
          duration: this.durationDate(range),
          status: "All",
          search: "",
          user_profile: "",
          gender: "",
          qualification: "",
          workExp: "",
          payment_request: "",
          course_fee: "",
          payment_status: ""
        }
      }
      if (range == CUSTOM) {
        dataObj.date_frame = [this.returnDate(cstart_date), this.returnDate(cend_date)]
      }
      if (option1.includes(LEADSCRED)) {
        this.props.dispatch(NewLeadReport(dataObj)).then(response => {
          if (response.status == "success") {
            if (response.merchantLeads.length > 0) {
              let exceldata = this.removeKey(response.merchantLeads)
              this.saveAsExcel(exceldata, "Lead_Report")
            } else {
              alert('No Data found for Lead')
            }
          } else {
            if (response.status == 401) {
              this.props.dispatch(logOut());
              history.push('/login');
            } else {
              alert('No Data found for Lead')
            }
          }
        })
          .catch(error => {
            alert('Error while fetching data')
          })
      }

      if (option1.includes(COURSES)) {
        const { range } = this.state
        let reportData = {
          // merchant_id: this.props.user_id,
          merchant_id: this.props.sfid,
          page: '',
          limit: '',
          filter: {
            search: "",
            // productStatus: "Active",  //Active, Inactive
            productStatus: "",
            course_type: "",
            course_delivery_mode: "", //Online, Offline
            medium_of_instruction__c: "",
            // listing_time: ["2023-07-01", "2023-08-01"]
            listing_time: this.durationDate(range),
          }
        }

        this.props.dispatch(getCourseReport(reportData)).then(response => {
          if (response.status == "success") {
            if (response.results.data.length > 0) {
              let data = this.getCourseDataReport(response.results.data)
              this.saveAsExcel(data, "Course_Report")
            } else {
              alert('No Data found for Course')
            }
          } else {
            if (response.status == 401) {
              this.props.dispatch(logOut());
              history.push('/login');
            } else {
              alert('No Data found for Course')
            }
          }
        })
          .catch(error => {
            alert('Error while fetching data')
          })
      }
    }
    if (this.props.activeWizrTab == "WIZR Cred") {
      const { start_date, end_date, time, time2, range, cend_date, cstart_date, option1 } = this.state
      const { sfid } = this.props;
      for (let i = 0; i < option1.length; i++) {
        const element = option1[i];
        if ((this.props.activeWizrTab == "WIZR Skills" && element != LEADSCRED) || this.props.activeWizrTab == "WIZR Cred") {
          let report;
          if (element == LEADSCRED) {
            report = "Leads"
          }
          if (element == COURSES) {
            report = "Product Master";
          }
          // let report = option1.includes(element) ? "Leads" : option1.includes(element) ? "Product Master" : "";
          // let stageValue = this.stageReportIs();
          let url
          let source = this.props.activeWizrTab == "WIZR Skills" ? "B2C" : "B2B2C"
          if (range == TODAY) {
            url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&time=${this.returnTime(time2)}&source=${source}`
            // url = 'merchant_sfid=00171000008GurGAAS&start_date=2022-01-01&end_date=2022-07-30&report_section=Product Master&date_range=Custom'
          } else if (range == CUSTOM) {
            url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&start_date=${this.returnDate(cstart_date)}&end_date=${this.returnDate(cend_date)}&source=${source}`
          } else {
            url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&source=${source}`
          }
          // if (report == LEADS || report == option1.includes(COURSES)) {
          //   if (range == TODAY) {
          //     url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&time=${this.returnTime(time)}`
          //     // url = 'merchant_sfid=00171000008GurGAAS&start_date=2022-01-01&end_date=2022-07-30&report_section=Product Master&date_range=Custom'
          //   } else if (range == CUSTOM) {
          //     url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&start_date=${this.returnDate(start_date)}&end_date=${this.returnDate(end_date)}`
          //   } else {
          //     url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}`
          //   }
          // } else {
          //   if (range == TODAY) {
          //     url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&time=${this.returnTime(time)}&stage_name=${stageValue}`
          //   } else if (range == CUSTOM) {
          //     url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&start_date=${this.returnDate(start_date)}&end_date=${this.returnDate(end_date)}&stage_name=${stageValue}`
          //   } else {
          //     url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&stage_name=${stageValue}`
          //   }
          // }
          // console.log(url);
          this.props.dispatch(getReportData(url)).then(response => {
            // console.log(response)
            if (response.status == "success") {
              if (response.proData.length > 0) {
                this.saveAsExcel(response.proData, element == LEADSCRED ? "Leads_Report" : this.props.activeWizrTab == "WIZR Cred" ? "Product_Report" : "Course_Report")
              } else {
                alert('No Data found')
              }
            } else {
              alert('Error while fetching data')
            }
          })
            .catch(error => {
              alert('Error while fetching data')
            })
        }
      }
    }
  }
  sendEmail = () => {
    const { report, start_date, end_date, time, range } = this.state
    const { sfid } = this.props;
    let stageValue = this.stageReportIs();
    let url


    if (report == LEADS || report == PRODUCT_MASTER || report == WHITELISTED) {
      if (range == TODAY) {
        url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&time=${this.returnTime(time)}&email=${this.state.email}`
        // url = 'merchant_sfid=00171000008GurGAAS&start_date=2022-01-01&end_date=2022-07-30&report_section=Product Master&date_range=Custom'
      } else if (range == CUSTOM) {
        url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&start_date=${this.returnDate(start_date)}&end_date=${this.returnDate(end_date)}&email=${this.state.email}`
      } else {
        if (range == Weekly) {

          url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&weekly_day=${this.state.selectedDay}&email=${this.state.email}`
        }
        if (range == Monthly) {
          url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&monthly_date=${this.state.selectedDate}&email=${this.state.email}`
        }

      }
    } else {
      if (range == TODAY) {

        url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&time=${this.returnTime(time)}&stage_name=${stageValue}&email=${this.state.email}`
      } else if (range == CUSTOM) {
        url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&start_date=${this.returnDate(start_date)}&end_date=${this.returnDate(end_date)}&stage_name=${stageValue}&email=${this.state.email}`

      } else {
        if (range == Weekly) {
          url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&stage_name=${stageValue}&weekly_day=${this.state.selectedDay}&email=${this.state.email}`
        }
        if (range == Monthly) {
          url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&stage_name=${stageValue}&monthly_date=${this.state.selectedDate}&email=${this.state.email}`
        }

        //url = `merchant_sfid=${sfid}&report_section=${report}&date_range=${range}&stage_name=${stageValue}`
      }
    }
    // console.log(url);
    this.props.dispatch(sendEmailReport(url)).then(response => {
      if (response.status == "success") {
        this.setState({ emailSuccessMsg: response.message })
        document.getElementById('responseModal').click()

      } else {
        alert('Error while fetching data')
      }
    })
      .catch(error => {
        alert('Error while fetching data')
      })



  }
  getSheetData = async (data, header) => {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  saveAsExcel = async (getData, fileName) => {
    var data = getData;
    var header = Object.keys(getData[0])
    const { report } = this.state    
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = await this.getSheetData(data, header);
      const totalColumns = sheetData[0].length;
      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn.toLocaleUpperCase() + "1").style("fill", "BFBFBF");
      range.style("border", true);
      return workbook.outputAsync().then((res) => {
        saveAs(res, fileName + ".xlsx");
      });
    });
  }
  // sendEmail = () => {
  //   console.log('user email', this.state.email)
  // }
  // const selectDay=(e)=>{
  //   console.log('selected day')

  // }

  selectReportOpt = (e) => {
    let hasValue = this.state.option1.indexOf(e.target.value) > -1
    if (hasValue) {
      let filteredArray = this.state.option1.filter(item => item !== e.target.value)
      this.setState({ option1: filteredArray });
    } else {
      this.setState({ option1: [...this.state.option1, e.target.value] })
    }
  }

  selectRadioOpt = (e) => {
    this.setState({ option2: e.target.value })
    document.getElementById('daily-tab').click()
    this.setState({ range: TODAY })
  }

  render() {
    const { user_id, users, roles } = this.props;
    const { time, start_date, end_date, dwstart_date, dwend_date, cstart_date, cend_date, emails, time2, stime, wtime, mtime, option2, option1 } = this.state
    if (!user_id) {
      return <Redirect to="/login" />
    }
    // const timeArray = []
    // timeArray.push(<></>);
    return (
      <>
        <Helmet>
          <title> Report </title>
        </Helmet>
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar
                dispatch={this.props.dispatch}
                title={"Report"}
              />
              <TopBarWizrHelpNotification />
              <TopWizrbar />
              <div className="container-fluid card-view">
                <h3 style={{ marginTop: "25px" }} className="reportsHeading">Reports</h3>
                <p className="boldp" style={{ marginTop: "30px" }}>Select the Report(s) to download</p>
                <div className="row mt-4">
                  <div className="col-md-2" style={{ display: "flex" }}>
                    <FormControlLabel
                      value="top"
                      control={
                        <Checkbox
                          sx={{ '& .MuiSvgIcon-root': { fontSize: '1.318vw' } }}
                          color="default"
                          onChange={this.selectReportOpt}
                          value={LEADSCRED}
                          className="leads"
                        />
                      }
                      label={<Typography className="reportLeads">
                        Leads
                      </Typography>}
                      labelPlacement="end"
                    />
                  </div>
                  <div className="col-md-2" style={{ display: "flex" }}>
                    <FormControlLabel
                      value="top"
                      control={
                        <Checkbox
                          sx={{ '& .MuiSvgIcon-root': { fontSize: '1.318vw' } }}
                          color="default"
                          onChange={this.selectReportOpt}
                          value={COURSES}
                        />
                      }
                      label={<Typography className="reportLeads">
                        {this.props.activeWizrTab == "WIZR Cred" ? "Product" : "Courses"}
                      </Typography>}
                      labelPlacement="end"
                    />
                  </div>
                  {/* <div className="col-md-2" style={{ display: "flex" }}>
                    <FormControlLabel
                      value="top"
                      control={
                        <Checkbox
                        sx={{ '& .MuiSvgIcon-root': { fontSize:'1.318vw' } }}
                          color="default"
                          onChange={this.selectReportOpt}
                          value={SETTLEMENTS}
                        />
                      }
                      label={<Typography className="reportLeads">
                      Settlements
                     </Typography>}
                      labelPlacement="end"
                    />
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <hr />
                  </div>
                </div>
                <div className="mt-2">
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                  // defaultValue="top"
                  >

                    <FormControlLabel
                      value="top"
                      control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1.318vw' } }} color="default" value={IMMEDIATE_DOWNLOAD} onChange={this.selectRadioOpt} checked />}
                      label={<Typography className="reportLeads">
                        Immediate Download
                      </Typography>}
                      labelPlacement="end"
                    />
                    {/* <FormControlLabel
                      value="top"
                      control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize:'1.318vw' } }} color="default" value={SCHEDULE_LATER} onChange={this.selectRadioOpt} />}
                      label={<Typography className="reportLeads">
                      Schedule for Later
                     </Typography>}
                      labelPlacement="end"
                    /> */}
                  </RadioGroup>
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <hr />
                  </div>
                </div>
                {
                  (this.state.option1.length > 0 && this.state.option2) &&
                  <div className="row">
                    <div className="col-md-8">
                      <div className="day_plan">
                        <label className="boldp mt-3 mb-3">Duration of the Report</label>

                        <div className="nav nav-tabs report_frequency_tabs" id="nav-tab" role="tablist">
                          <a
                            className={`nav-item report-nav nav-link active ${this.state.option2 == SCHEDULE_LATER ? "three-sec" : ""}`}
                            id="daily-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="daily"
                            aria-selected="true"
                            onClick={() => { this.optionSelected(DAILY) }}
                          >
                            {option2 == SCHEDULE_LATER ? "Daily" : "Today"}

                          </a>
                          <a
                            className={`nav-item report-nav nav-link ${this.state.option2 == SCHEDULE_LATER ? "three-sec" : ""}`}
                            id="weekly-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="weekly"
                            aria-selected="false"
                            onClick={() => { this.optionSelected(WEEKLY) }}
                          >
                            This Week

                          </a>
                          <a
                            className={`nav-item report-nav nav-link ${this.state.option2 == SCHEDULE_LATER ? "three-sec" : ""}`}
                            id="monthly-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="monthly"
                            aria-selected="false"
                            onClick={() => { this.optionSelected(MONTHLY) }}
                          >
                            This Month

                          </a>
                          {
                            this.state.option2 == IMMEDIATE_DOWNLOAD &&
                            <a
                              className={`nav-item report-nav nav-link ${this.state.option2 == SCHEDULE_LATER ? "three-sec" : ""}`}
                              id="custom-tab"
                              data-toggle="tab"
                              role="tab"
                              aria-controls="custom"
                              aria-selected="false"
                              onClick={() => { this.optionSelected(CUSTOM) }}
                            >
                              Custom

                            </a>
                          }
                        </div>
                        <div className="tab-content mt-3 mb-4" id="nav-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="daily"
                            role="tabpanel"
                            aria-labelledby="daily-tab"
                          >
                            <div className="row">
                              <div className={`col-4 mb-4 ${this.state.range == TODAY ? '' : 'd-none'}`}>
                                <p className="boldp" style={{ marginBottom: "30px" }}>Report Time</p>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label" className="label-color">From</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={option2 == IMMEDIATE_DOWNLOAD ? time : stime}
                                    label="From"
                                    onChange={(e) => this.handleTimeChange(e.target.value)}
                                    disabled={this.state.option2 == IMMEDIATE_DOWNLOAD ? true : false}
                                  >
                                    <MenuItem value="00:00">12.00 AM</MenuItem>
                                    <MenuItem value="00:30">12.30 AM</MenuItem>
                                    <MenuItem value="01:00">01.00 AM</MenuItem>
                                    <MenuItem value="01:30">01.30 AM</MenuItem>
                                    <MenuItem value="02:00">02.00 AM</MenuItem>
                                    <MenuItem value="02:30">02.30 AM</MenuItem>
                                    <MenuItem value="03:00">03.00 AM</MenuItem>
                                    <MenuItem value="03:30">03.30 AM</MenuItem>
                                    <MenuItem value="04:00">04.00 AM</MenuItem>
                                    <MenuItem value="04:30">04.30 AM</MenuItem>
                                    <MenuItem value="05:00">05.00 AM</MenuItem>
                                    <MenuItem value="05:30">05.30 AM</MenuItem>
                                    <MenuItem value="06:00">06.00 AM</MenuItem>
                                    <MenuItem value="06:30">06.30 AM</MenuItem>
                                    <MenuItem value="07:00">07.00 AM</MenuItem>
                                    <MenuItem value="07:30">07.30 AM</MenuItem>
                                    <MenuItem value="08:00">08.00 AM</MenuItem>
                                    <MenuItem value="08:30">08.30 AM</MenuItem>
                                    <MenuItem value="09:00">09.00 AM</MenuItem>
                                    <MenuItem value="09:30">09.30 AM</MenuItem>
                                    <MenuItem value="10:00">10.00 AM</MenuItem>
                                    <MenuItem value="10:30">10.30 AM</MenuItem>
                                    <MenuItem value="11:00">11.00 AM</MenuItem>
                                    <MenuItem value="11:30">11.30 AM</MenuItem>
                                    <MenuItem value="12:00">12.00 PM</MenuItem>
                                    <MenuItem value="12:30">12.30 PM</MenuItem>
                                    <MenuItem value="13:00">01.00 PM</MenuItem>
                                    <MenuItem value="13:30">01.30 PM</MenuItem>
                                    <MenuItem value="14:00">02.00 PM</MenuItem>
                                    <MenuItem value="14:30">02.30 PM</MenuItem>
                                    <MenuItem value="15:00">03.00 PM</MenuItem>
                                    <MenuItem value="15:30">03.30 PM</MenuItem>
                                    <MenuItem value="16:00">04.00 PM</MenuItem>
                                    <MenuItem value="16:30">04.30 PM</MenuItem>
                                    <MenuItem value="17:00">05.00 PM</MenuItem>
                                    <MenuItem value="17:30">05.30 PM</MenuItem>
                                    <MenuItem value="18:00">06.00 PM</MenuItem>
                                    <MenuItem value="18:30">06.30 PM</MenuItem>
                                    <MenuItem value="19:00">07.00 PM</MenuItem>
                                    <MenuItem value="19:30">07.30 PM</MenuItem>
                                    <MenuItem value="20:00">08.00 PM</MenuItem>
                                    <MenuItem value="20:30">08.30 PM</MenuItem>
                                    <MenuItem value="21:00">09.00 PM</MenuItem>
                                    <MenuItem value="21:30">09.30 PM</MenuItem>
                                    <MenuItem value="22:00">10.00 PM</MenuItem>
                                    <MenuItem value="22:30">10.30 PM</MenuItem>
                                    <MenuItem value="23:00">11.00 PM</MenuItem>
                                    <MenuItem value="23:30">11.30 PM</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                              {
                                this.state.option2 == IMMEDIATE_DOWNLOAD &&
                                <div className={`col-4 ${this.state.range == TODAY ? '' : 'd-none'}`}>
                                  <p className="boldp" style={{ marginBottom: "30px" }}>&nbsp;</p>
                                  <FormControl fullWidth>
                                    <TextField
                                      disabled
                                      label="To"
                                      defaultValue={time2}
                                      className='report-text'
                                    />
                                    {/* <InputLabel id="demo-simple-select-label">Time</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={time2}
                                      label="Time"
                                      // onChange={(e) => this.handleTimeChange(e.target.value)}
                                      disabled
                                    > */}
                                    {/* <MenuItem value="00:00">12.00 AM</MenuItem>
                                      <MenuItem value="00:30">12.30 AM</MenuItem>
                                      <MenuItem value="01:00">01.00 AM</MenuItem>
                                      <MenuItem value="01:30">01.30 AM</MenuItem>
                                      <MenuItem value="02:00">02.00 AM</MenuItem>
                                      <MenuItem value="02:30">02.30 AM</MenuItem>
                                      <MenuItem value="03:00">03.00 AM</MenuItem>
                                      <MenuItem value="03:30">03.30 AM</MenuItem>
                                      <MenuItem value="04:00">04.00 AM</MenuItem>
                                      <MenuItem value="04:30">04.30 AM</MenuItem>
                                      <MenuItem value="05:00">05.00 AM</MenuItem>
                                      <MenuItem value="05:30">05.30 AM</MenuItem>
                                      <MenuItem value="06:00">06.00 AM</MenuItem>
                                      <MenuItem value="06:30">06.30 AM</MenuItem>
                                      <MenuItem value="07:00">07.00 AM</MenuItem>
                                      <MenuItem value="07:30">07.30 AM</MenuItem>
                                      <MenuItem value="08:00">08.00 AM</MenuItem>
                                      <MenuItem value="08:30">08.30 AM</MenuItem>
                                      <MenuItem value="09:00">09.00 AM</MenuItem>
                                      <MenuItem value="09:30">09.30 AM</MenuItem>
                                      <MenuItem value="10:00">10.00 AM</MenuItem>
                                      <MenuItem value="10:30">10.30 AM</MenuItem>
                                      <MenuItem value="11:00">11.00 AM</MenuItem>
                                      <MenuItem value="11:30">11.30 AM</MenuItem>
                                      <MenuItem value="12:00">12.00 PM</MenuItem>
                                      <MenuItem value="12:30">12.30 PM</MenuItem>
                                      <MenuItem value="13:00">01.00 PM</MenuItem>
                                      <MenuItem value="13:30">01.30 PM</MenuItem>
                                      <MenuItem value="14:00">02.00 PM</MenuItem>
                                      <MenuItem value="14:30">02.30 PM</MenuItem>
                                      <MenuItem value="15:00">03.00 PM</MenuItem>
                                      <MenuItem value="15:30">03.30 PM</MenuItem>
                                      <MenuItem value="16:00">04.00 PM</MenuItem>
                                      <MenuItem value="16:30">04.30 PM</MenuItem>
                                      <MenuItem value="17:00">05.00 PM</MenuItem>
                                      <MenuItem value="17:30">05.30 PM</MenuItem>
                                      <MenuItem value="18:00">06.00 PM</MenuItem>
                                      <MenuItem value="18:30">06.30 PM</MenuItem>
                                      <MenuItem value="19:00">07.00 PM</MenuItem>
                                      <MenuItem value="19:30">07.30 PM</MenuItem>
                                      <MenuItem value="20:00">08.00 PM</MenuItem>
                                      <MenuItem value="20:30">08.30 PM</MenuItem>
                                      <MenuItem value="21:00">09.00 PM</MenuItem>
                                      <MenuItem value="21:30">09.30 PM</MenuItem>
                                      <MenuItem value="22:00">10.00 PM</MenuItem>
                                      <MenuItem value="22:30">10.30 PM</MenuItem>
                                      <MenuItem value="23:00">11.00 PM</MenuItem>
                                      <MenuItem value="23:30">11.30 PM</MenuItem> */}
                                    {/* </Select> */}
                                  </FormControl>
                                </div>
                              }
                              <div className={`col-8 ${this.state.range == THISWEEK ? '' : 'd-none'}`}>
                                {
                                  this.state.option2 == IMMEDIATE_DOWNLOAD ?
                                    <>
                                      <p className="boldp mb-4">Date</p>
                                      <div className="row mb-4">
                                        <div className="col-lg-6">
                                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                              <DatePicker
                                                label="From"
                                                className="datepickerInput"
                                                value={dwstart_date}
                                                onChange={(newValue) => this.setState({ dwstart_date: newValue })}
                                                disabled
                                                format="dd-MM-yyyy"
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>
                                        </div>
                                        <div className="col-lg-6">
                                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                              <DatePicker
                                                label="To"
                                                className="datepickerInput"
                                                value={dwend_date}
                                                onChange={(newValue) => this.setState({ dwend_date: newValue })}
                                                disabled
                                                format="dd-MM-yyyy"
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>
                                        </div>
                                      </div>
                                    </>
                                    :
                                    <>
                                      <div className="row mb-4">
                                        <div className="col-lg-6">
                                          <p className="boldp mb-4">Report Delivery Day</p>
                                          <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Day</InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={this.state.selectedDay}
                                              label="Day"
                                              onChange={(e) => this.setState({ selectedDay: e.target.value })}
                                            >
                                              <MenuItem value="Monday">Monday</MenuItem>
                                              <MenuItem value="Tuesday">Tuesday</MenuItem>
                                              <MenuItem value="Wednesday">Wednesday</MenuItem>
                                              <MenuItem value="Thusday">Thusday</MenuItem>
                                              <MenuItem value="Friday">Friday</MenuItem>
                                              <MenuItem value="Saturday">Saturday</MenuItem>
                                              <MenuItem value="Sunday">Sunday</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </div>
                                        <div className="col-lg-6">
                                          <p className="boldp mb-4">Report Delivery Time</p>
                                          <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Time</InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={wtime}
                                              label="Time"
                                              onChange={(e) => this.setState({ wtime: e.target.value })}
                                            >
                                              <MenuItem value="00:00">12.00 AM</MenuItem>
                                              <MenuItem value="00:30">12.30 AM</MenuItem>
                                              <MenuItem value="01:00">01.00 AM</MenuItem>
                                              <MenuItem value="01:30">01.30 AM</MenuItem>
                                              <MenuItem value="02:00">02.00 AM</MenuItem>
                                              <MenuItem value="02:30">02.30 AM</MenuItem>
                                              <MenuItem value="03:00">03.00 AM</MenuItem>
                                              <MenuItem value="03:30">03.30 AM</MenuItem>
                                              <MenuItem value="04:00">04.00 AM</MenuItem>
                                              <MenuItem value="04:30">04.30 AM</MenuItem>
                                              <MenuItem value="05:00">05.00 AM</MenuItem>
                                              <MenuItem value="05:30">05.30 AM</MenuItem>
                                              <MenuItem value="06:00">06.00 AM</MenuItem>
                                              <MenuItem value="06:30">06.30 AM</MenuItem>
                                              <MenuItem value="07:00">07.00 AM</MenuItem>
                                              <MenuItem value="07:30">07.30 AM</MenuItem>
                                              <MenuItem value="08:00">08.00 AM</MenuItem>
                                              <MenuItem value="08:30">08.30 AM</MenuItem>
                                              <MenuItem value="09:00">09.00 AM</MenuItem>
                                              <MenuItem value="09:30">09.30 AM</MenuItem>
                                              <MenuItem value="10:00">10.00 AM</MenuItem>
                                              <MenuItem value="10:30">10.30 AM</MenuItem>
                                              <MenuItem value="11:00">11.00 AM</MenuItem>
                                              <MenuItem value="11:30">11.30 AM</MenuItem>
                                              <MenuItem value="12:00">12.00 PM</MenuItem>
                                              <MenuItem value="12:30">12.30 PM</MenuItem>
                                              <MenuItem value="13:00">01.00 PM</MenuItem>
                                              <MenuItem value="13:30">01.30 PM</MenuItem>
                                              <MenuItem value="14:00">02.00 PM</MenuItem>
                                              <MenuItem value="14:30">02.30 PM</MenuItem>
                                              <MenuItem value="15:00">03.00 PM</MenuItem>
                                              <MenuItem value="15:30">03.30 PM</MenuItem>
                                              <MenuItem value="16:00">04.00 PM</MenuItem>
                                              <MenuItem value="16:30">04.30 PM</MenuItem>
                                              <MenuItem value="17:00">05.00 PM</MenuItem>
                                              <MenuItem value="17:30">05.30 PM</MenuItem>
                                              <MenuItem value="18:00">06.00 PM</MenuItem>
                                              <MenuItem value="18:30">06.30 PM</MenuItem>
                                              <MenuItem value="19:00">07.00 PM</MenuItem>
                                              <MenuItem value="19:30">07.30 PM</MenuItem>
                                              <MenuItem value="20:00">08.00 PM</MenuItem>
                                              <MenuItem value="20:30">08.30 PM</MenuItem>
                                              <MenuItem value="21:00">09.00 PM</MenuItem>
                                              <MenuItem value="21:30">09.30 PM</MenuItem>
                                              <MenuItem value="22:00">10.00 PM</MenuItem>
                                              <MenuItem value="22:30">10.30 PM</MenuItem>
                                              <MenuItem value="23:00">11.00 PM</MenuItem>
                                              <MenuItem value="23:30">11.30 PM</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </div>
                                      </div>
                                    </>
                                }
                              </div>
                              <div className={`col-8 ${this.state.range == THISMONTH ? '' : 'd-none'}`}>
                                {
                                  this.state.option2 == IMMEDIATE_DOWNLOAD ?
                                    <>
                                      <p className="boldp mb-4">Date</p>
                                      <div className="row mb-4">
                                        <div className="col-lg-6">
                                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                              <DatePicker
                                                label="From"
                                                value={start_date}
                                                className="datepickerInput"
                                                onChange={(newValue) => this.setState({ start_date: newValue })}
                                                disabled
                                                format="dd-MM-yyyy"
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>
                                        </div>
                                        <div className="col-lg-6">
                                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                              <DatePicker
                                                label="To"
                                                value={end_date}
                                                className="datepickerInput"
                                                onChange={(newValue) => this.setState({ end_date: newValue })}
                                                disabled
                                                format="dd-MM-yyyy"
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>
                                        </div>
                                      </div>
                                    </>
                                    :
                                    <>
                                      <div className="row mb-4">
                                        <div className="col-lg-6">
                                          <p className="boldp mb-4">Report Delivery Date</p>
                                          <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Date</InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={this.state.selectedDate}
                                              label="Date"
                                              onChange={(e) => this.setState({ selectedDate: e.target.value })}
                                            >
                                              <MenuItem value="1">1st</MenuItem>
                                              <MenuItem value="2">2nd</MenuItem>
                                              <MenuItem value="3">3rd</MenuItem>
                                              <MenuItem value="4">4th</MenuItem>
                                              <MenuItem value="5">5th</MenuItem>
                                              <MenuItem value="6">6th</MenuItem>
                                              <MenuItem value="7">7th</MenuItem>
                                              <MenuItem value="9">9th</MenuItem>
                                              <MenuItem value="10">10th</MenuItem>
                                              <MenuItem value="11">11th</MenuItem>
                                              <MenuItem value="12">12th</MenuItem>
                                              <MenuItem value="13">13th</MenuItem>
                                              <MenuItem value="14">14th</MenuItem>
                                              <MenuItem value="15">15th</MenuItem>
                                              <MenuItem value="16">16th</MenuItem>
                                              <MenuItem value="17">17th</MenuItem>
                                              <MenuItem value="18">18th</MenuItem>
                                              <MenuItem value="19">19th</MenuItem>
                                              <MenuItem value="20">20th</MenuItem>
                                              <MenuItem value="21">21st</MenuItem>
                                              <MenuItem value="22">22nd</MenuItem>
                                              <MenuItem value="23">23rd</MenuItem>
                                              <MenuItem value="24">24th</MenuItem>
                                              <MenuItem value="25">25th</MenuItem>
                                              <MenuItem value="26">26th</MenuItem>
                                              <MenuItem value="27">27th</MenuItem>
                                              <MenuItem value="28">28th</MenuItem>
                                              <MenuItem value="29">29th</MenuItem>
                                              <MenuItem value="30th">30th</MenuItem>
                                              <MenuItem value="31">31st</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </div>
                                        <div className="col-lg-6">
                                          <p className="boldp mb-4">Report Delivery Time</p>
                                          <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Time</InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={mtime}
                                              label="Time"
                                              onChange={(e) => this.setState({ mtime: e.target.value })}
                                            >
                                              <MenuItem value="00:00">12.00 AM</MenuItem>
                                              <MenuItem value="00:30">12.30 AM</MenuItem>
                                              <MenuItem value="01:00">01.00 AM</MenuItem>
                                              <MenuItem value="01:30">01.30 AM</MenuItem>
                                              <MenuItem value="02:00">02.00 AM</MenuItem>
                                              <MenuItem value="02:30">02.30 AM</MenuItem>
                                              <MenuItem value="03:00">03.00 AM</MenuItem>
                                              <MenuItem value="03:30">03.30 AM</MenuItem>
                                              <MenuItem value="04:00">04.00 AM</MenuItem>
                                              <MenuItem value="04:30">04.30 AM</MenuItem>
                                              <MenuItem value="05:00">05.00 AM</MenuItem>
                                              <MenuItem value="05:30">05.30 AM</MenuItem>
                                              <MenuItem value="06:00">06.00 AM</MenuItem>
                                              <MenuItem value="06:30">06.30 AM</MenuItem>
                                              <MenuItem value="07:00">07.00 AM</MenuItem>
                                              <MenuItem value="07:30">07.30 AM</MenuItem>
                                              <MenuItem value="08:00">08.00 AM</MenuItem>
                                              <MenuItem value="08:30">08.30 AM</MenuItem>
                                              <MenuItem value="09:00">09.00 AM</MenuItem>
                                              <MenuItem value="09:30">09.30 AM</MenuItem>
                                              <MenuItem value="10:00">10.00 AM</MenuItem>
                                              <MenuItem value="10:30">10.30 AM</MenuItem>
                                              <MenuItem value="11:00">11.00 AM</MenuItem>
                                              <MenuItem value="11:30">11.30 AM</MenuItem>
                                              <MenuItem value="12:00">12.00 PM</MenuItem>
                                              <MenuItem value="12:30">12.30 PM</MenuItem>
                                              <MenuItem value="13:00">01.00 PM</MenuItem>
                                              <MenuItem value="13:30">01.30 PM</MenuItem>
                                              <MenuItem value="14:00">02.00 PM</MenuItem>
                                              <MenuItem value="14:30">02.30 PM</MenuItem>
                                              <MenuItem value="15:00">03.00 PM</MenuItem>
                                              <MenuItem value="15:30">03.30 PM</MenuItem>
                                              <MenuItem value="16:00">04.00 PM</MenuItem>
                                              <MenuItem value="16:30">04.30 PM</MenuItem>
                                              <MenuItem value="17:00">05.00 PM</MenuItem>
                                              <MenuItem value="17:30">05.30 PM</MenuItem>
                                              <MenuItem value="18:00">06.00 PM</MenuItem>
                                              <MenuItem value="18:30">06.30 PM</MenuItem>
                                              <MenuItem value="19:00">07.00 PM</MenuItem>
                                              <MenuItem value="19:30">07.30 PM</MenuItem>
                                              <MenuItem value="20:00">08.00 PM</MenuItem>
                                              <MenuItem value="20:30">08.30 PM</MenuItem>
                                              <MenuItem value="21:00">09.00 PM</MenuItem>
                                              <MenuItem value="21:30">09.30 PM</MenuItem>
                                              <MenuItem value="22:00">10.00 PM</MenuItem>
                                              <MenuItem value="22:30">10.30 PM</MenuItem>
                                              <MenuItem value="23:00">11.00 PM</MenuItem>
                                              <MenuItem value="23:30">11.30 PM</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </div>
                                      </div>
                                    </>
                                }
                              </div>
                              <div className={`col-8 ${(this.state.range == CUSTOM && this.state.option2 == IMMEDIATE_DOWNLOAD) ? '' : 'd-none'}`}>
                                <p className="boldp mb-4">Date</p>
                                <div className="row mb-4">
                                  <div className="col-lg-6">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                          label="From"
                                          views={['year', 'month', 'day']}
                                          value={cstart_date}
                                          maxDate={new Date()}
                                          className="datepickerInput"
                                          onChange={(newValue) => this.setState({ cstart_date: newValue })}
                                          format="dd-MM-yyyy"
                                          slotProps={{ textField: { readOnly: true } }}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </div>
                                  <div className="col-lg-6">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                          label="To"
                                          views={['year', 'month', 'day']}
                                          maxDate={new Date()}
                                          value={cend_date}
                                          className="datepickerInput"
                                          onChange={(newValue) => this.setState({ cend_date: newValue })}
                                          format="dd-MM-yyyy"
                                          slotProps={{ textField: { readOnly: true } }}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {
                              this.state.option2 == SCHEDULE_LATER &&
                              <div style={{ marginTop: "25px" }}>
                                <p className="boldp mb-3">Add Recipients</p>
                                <ReactMultiEmail
                                  placeholder="Input your Email Address"
                                  emails={emails}
                                  onChange={(_emails) => {
                                    this.setState({ emails: _emails });
                                  }}
                                  getLabel={(
                                    email,
                                    index,
                                    removeEmail
                                  ) => {
                                    return (
                                      <div data-tag key={index}>
                                        {email}
                                        <span data-tag-handle onClick={() => removeEmail(index)}>
                                          ×
                                        </span>
                                      </div>
                                    );
                                  }}
                                />
                              </div>
                            }
                            <div className="row">
                              <div className="col-auto">
                                <div className="product_page mt-5">
                                  {
                                    this.state.option2 == IMMEDIATE_DOWNLOAD ?
                                      <>
                                        {
                                          this.props.activeTab == "WIZR Cred" &&
                                          <button className="nav_icons nav_download btn_style d-inline-block mr-3" onClick={this.sendEmail}><img src="images/icons/icon-email.png" /> Send Email</button>
                                        }
                                        <button className="nav_icons nav_download btn_style d-inline-block dark-blue-btn" onClick={this.DownloadReport}><img src="images/icons/download_white.png" /> Download</button>
                                      </>
                                      :
                                      <button className="nav_icons nav_download btn_style d-inline-block dark-blue-btn">Schedule</button>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { user, user_id, sfid } = state.auth;
  const { users, roles, reportData, activeWizrTab } = state.user;
  return {
    user,
    user_id,
    users,
    sfid,
    roles,
    reportData,
    activeWizrTab
  };
}

export default connect(mapStateToProps)(Report);
import React from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import Box from "@mui/material/Box";
import { Component } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Card from "@mui/material/Card";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomizeCheckbox from "../common/CustomizeCheckbox";
import FilterCustomDivider from "../common/FilterCustomDivider";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Scrollbar } from "react-scrollbars-custom";
import { getFilterMaster, getLeads, getMerchantProducts, getProductFilterData, getfilterLeadData } from "../actions/user";
import { connect } from "react-redux";




// let courseType = [
//     {
//         checked: false,
//         label: "Acer",
//     },
//     {
//         checked: false,
//         label: "Apple",
//     },
//     {
//         checked: false,
//         label: "Boat",
//     },
// ];

// let product = [
//     {
//         checked: false,
//         label: "Acer",
//     },
//     {
//         checked: false,
//         label: "Apple",
//     },
//     {
//         checked: false,
//         label: "Boat",
//     },
// ];

// let brand = [
//     {
//         checked: false,
//         label: "Acer",
//     },
//     {
//         checked: false,
//         label: "Apple",
//     },
//     {
//         checked: false,
//         label: "Boat",
//     },
// ];

// let category = [
//     {
//         checked: false,
//         label: "Acer",
//     },
//     {
//         checked: false,
//         label: "Apple",
//     },
//     {
//         checked: false,
//         label: "Boat",
//     },
// ];


// const initialCourseType = courseType.slice();


const listingTime = [
    {
        checked: false,
        name: "Today",
    },
    {
        checked: false,
        name: "This Week",
    },
    {
        checked: false,
        name: "This Month",
    },
    {
        checked: false,
        name: "This Quarter",
    },
];



class LeadCredFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // brands: brand,
            brands:[],
            // products: [...product],
            products:[],
            // categories: category,
            categories:[],
            listingTimes: listingTime,
            searchProduct: "",
        };
    }

    //   shouldComponentUpdate(nextProps, nextState){
    //      if(nextProps.clearfilterValue !== this.props.clearfilterValue){
    //     //    this.setState({ courseTypes:courseType,
    //     //     courseDeliveryModes:courseDeliveryMode,
    //     //     mediumOfInstructions:mediumOfInstruction,
    //     //     listingTimes:listingTime})
    //      }else{
    //         true;
    //      }

    componentDidMount(){
    
      const {sfid} = this.props;
      const stage = `stage=Pre Approval`;
        this.props.dispatch(getLeads(stage, sfid)).then(response => {
          console.log(response,'>>>>>>>>> with parameter');
          if (response.status == "success") {
            let actionData = [];
              response.proData.map(data => {
                  if (data.status) {
                      actionData.push(data.status)
                  }
              })
              let d = [...new Set(actionData)];
              console.log("ererer",d);
              this.setState({
                  brands: d.map(ele => {return {name:ele,checked:false}})
              })
          }
      })

        this.props.dispatch(getLeads("", sfid)).then(response => {
          if (response.status == "success") {
            let statusData = [];
              response.proData.map(data => {
                  if (data.status) {
                    statusData.push(data.status)
                  }
              })
              let d = [...new Set(statusData)];
              console.log("ererer",d);
              this.setState({
                  categories: d.map(ele => {return {name:ele,checked:false}})
              })
          }
      })


        // this.props.dispatch(getLeads(this.props.user_id, 'brand')).then((response) => {
      //       console.log(response, 'brand response Data')
      //       this.setState({ brands: response.data.map(ele => {return {...ele,checked:false}}) })
      //   })

        // this.props.dispatch(getFilterMaster(this.props.user_id, 'product')).then((response) => {
        //     console.log(response, 'product response')
        //     this.setState({ products: response.data.map(ele => {return {...ele,checked:false}}) })
        // })

        // this.props.dispatch(getFilterMaster(this.props.user_id, 'category')).then((response) => {
        //     console.log(response, 'category response')
        //     this.setState({ categories: response.data.map(ele => {return {...ele,checked:false}}) })
        // })

    }


    componentDidUpdate(prevProps) {
        if (prevProps.clearfilterValue !== this.props.clearfilterValue) {
            this.setState({
                brands: this.state.brands.map(ele => { return { ...ele, checked: false } }),
                products: this.state.products.map(ele => { return { ...ele, checked: false } }),
                categories: this.state.categories.map(ele => { return { ...ele, checked: false } }),
                listingTimes: this.state.listingTimes.map(ele => { return { ...ele, checked: false } })
            }, () => {
                console.log("updated", this.state.brands);
            })
        }

        if (prevProps.show !== this.props.show) {
            if(this.props.show){
                this.props.dispatch(getFilterMaster(this.props.user_id, 'product')).then((response) => {
                    console.log(response, 'product response')
                    this.setState({ products: response.data.map(ele => {return {...ele,checked:false}}) })
                })
            }
        }

    }

    handleFilterSearch = (e) => {

        this.setState({
            searchProduct: e.target.value
        })
        // let data = this.state.products.filter((item) => {
        //     return (this.state.searchProduct.toLowerCase() === "" ? item : item.label.toLowerCase().includes(this.state.searchProduct))
        // })
        // this.setState({ products: data })
    }

    handleCheck = (checkedValue, index, Type) => {
        let { brands, products, categories, listingTimes } = this.state;
        if (Type === "brands") {
            brands[index].checked = checkedValue;
            this.setState({ brands: brands })
        }
        if (Type === "products") {
        //    let i = products.findIndex(ele => ele.id === index)
            products[index].checked = checkedValue;
            this.setState({ products: products })
        }
        if (Type === "categories") {
            categories[index].checked = checkedValue;
            this.setState({ categories: categories })
        }
        if (Type === "listingTimes") {
            listingTimes[index].checked = checkedValue;
            this.setState({ listingTimes: listingTimes })
        }
    };

    applyFilter = () => {
        const { user_id } = this.props
        // const { brandOptSel, proOptSel, catOptSel, offOptSel, timeOptSel } = this.state
        let { brands, products, categories, listingTimes } = this.state;
      
        const brandsfilter = brands.filter(ele => ele.checked).map(element => element.name);
        const categoriesFilter = categories.filter(ele => ele.checked).map(element => element.name);
        const productsFilter = products.filter(ele => ele.checked).map(element => element.name);
        const listingTimesFilter = listingTime.filter(ele => ele.checked).map(element => element.name);
        const filterArray = [];
        brands.forEach(ele => {
           if(ele.checked){
           brandsfilter.push(ele.name)
           } 
        })
        categories.forEach(ele => {
           if(ele.checked){
            categoriesFilter.push(ele.name)
           } 
        })
        products.forEach(ele => {
           if(ele.checked){
            productsFilter.push(ele.name)
           } 
        })
        listingTime.forEach(ele => {
           if(ele.checked){
            listingTimesFilter.push(ele.name)
           } 
        })
        const searchKey = [...brandsfilter, ...productsFilter, ...categoriesFilter, ...listingTimesFilter]
        if (brandsfilter.length > 0) { filterArray.push("Status"); }
        if (productsFilter.length > 0) { filterArray.push("Product"); }
        if (categoriesFilter.length > 0) { filterArray.push("Action"); }
        if (listingTimesFilter.length > 0) { filterArray.push("Date"); }
        // if (timeOptSel.length > 0) { filterArray.push("Listing Time"); }
        console.log(filterArray, searchKey, '>>>>>>>>>> filter dwata is')
        console.log({filterArray,searchKey},"searchdata");
        let dataObj = { "merchant_id": user_id, "search_keyword": searchKey, "filter_type": filterArray }

        if (searchKey.length == 0 && filterArray.length == 0) {

            let getProd = { merchant_id: this.props.user_id }
            this.props.dispatch(getMerchantProducts(getProd)).then((response) => {
                if (response.status == 'error') {
                    return;
                }
                if (!response.responseCode) {
                    this.setState({ DataSet: response })
                }
            });
        } else {


            const dataObj = {
                "merchant_id": user_id,
                "filter_type": filterArray,
                "search_keyword": searchKey,
                "section": this.props.leadTab
            }
            this.props.dispatch(getfilterLeadData(dataObj)).then(response => {
                this.closeModel();
            })
        }

            // this.props.dispatch(getProductFilterData(dataObj)).then(response => {
            //     console.log(response, "product filter response");
            // })
                // .catch(error => {
                //     console.log('product filtere error', error)
                // })
        }
    

    handleClose=()=>{
        console.log("here323")
        this.props.close();
    }

    clearFilter = () => {
        this.setState({
            brands: this.state.brands.map(ele => { return { ...ele, checked: false } }),
            products: this.state.products.map(ele => { return { ...ele, checked: false } }),
            categories: this.state.categories.map(ele => { return { ...ele, checked: false } }),
            listingTimes: this.state.listingTimes.map(ele => { return { ...ele, checked: false } })
        })
    }

    render() {
        let { brands, courseTypes, products, categories, listingTimes } = this.state;
        let {show} = this.props;
        console.log({brands,products,categories,listingTimes},"reyrehrehr");
        return (
            <Modal show={show} className='right myModal filter_modal filterbarMainModal' id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
    <button type="button"  onClick={this.handleClose}  className=" filterModalCloseButton" > <img style={{marginTop:"15px"}} src="./images/icons/icon-close2.png"/> </button>
      <Box className='filterDiv'>        
        <div className="filterboxdiv" >
         
          <h1  className='filter-main-title'   >
            Filters
          </h1>
          <div className="filterboxdivoptions">
          <button className='mr-2 filtermainbtn filterclearbtn' onClick = {this.clearFilter} >Clear All</button>
          <button className='filtermainbtn filterapplybtn'onClick={this.applyFilter}>Apply</button>
          </div>
        </div>
     <FilterCustomDivider/>
     <>
                <Card
                    variant="outlined"
                    sx={{ border: "none", boxShadow: "none", overflowY: "auto" }}
                    className="mobile-card-padding"
                >
                    {/* <Scrollbar> */}

                    {/* single accordian */}
                      {/* <button className='filtermainbtn filterapplybtn' onClick={this.applyFilter}>Apply</button> */}
                    <Accordion
                        elevation={0}
                        disableGutters={true}
                        sx={{ paddingBottom: "0.573vw" }}
                    >
                        <AccordionSummary
                            className="accordian-summary"
                            expandIcon={<ExpandMoreIcon className="accordian-arrow" />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="text-title-filter">Status </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="accordian-detail max-height-acc">
                            <>
                                <Stack direction="row" gap="10px" marginBottom="15px" flexWrap="wrap">
                                    {brands?.length &&
                                        brands.map((ele, i) => {
                                            if (ele.checked) {
                                                return (
                                                    <button className="btn filterOptchk">
                                                        {ele.name}{" "}
                                                        <span>
                                                            <img className='filterclosebtn'
                                                                src="./images/icons/icon-close3.svg"
                                                                onClick={() =>
                                                                    this.handleCheck(false, i,"brands")
                                                                }
                                                            />{" "}
                                                        </span>{" "}
                                                    </button>
                                                );
                                            }
                                        })}
                                </Stack>
                            </>
                            {brands?.length &&
                                brands.map((ele, i) => {
                                    return (
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                            spacing={2}
                                            className="accordion-option"
                                        >
                                            <FormControlLabel
                                                label={
                                                    <Typography className="text-options-filter">
                                                        {ele.name}
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize:'1.318vw' } }}
                                                        color="default"
                                                        checked={ele.checked}
                                                        onChange={(e) =>
                                                            this.handleCheck(e.target.checked, i, "brands")
                                                        }
                                                    />
                                                }
                                            />
                                        </Stack>
                                    );
                                })}
                        </AccordionDetails>
                    </Accordion>

                    {/* second accordian */}

                    <Accordion
                        elevation={0}
                        disableGutters={true}
                        sx={{ paddingBottom: "0.573vw" }}
                    >
                        <AccordionSummary
                            className="accordian-summary"
                            expandIcon={<ExpandMoreIcon className="accordian-arrow" />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="text-title-filter">Product</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="accordian-detail max-height-acc" id="accordian">
                            <>
                                <Stack direction="row" gap="10px" marginBottom="15px" flexWrap="wrap">
                                    {products?.length &&
                                        products.map((ele, i) => {
                                            if (ele.checked) {
                                                return (
                                                    <button className="btn filterOptchk">
                                                        {ele.name}{" "}
                                                        <span>
                                                            <img className='filterclosebtn'
                                                                src="./images/icons/icon-close3.svg"
                                                                onClick={() =>
                                                                    this.handleCheck(false,i,"products")
                                                                }
                                                            />{" "}
                                                        </span>{" "}
                                                    </button>
                                                );
                                            }

                                        })}
                                </Stack>
                                {/* <Form>
                                    <InputGroup className='my-3'>
                                        <input placeholder='Enter Product name' className='product-search-bar' onChange={(e) => this.handleFilterSearch(e)} />
                                        <img src='./img/icon_Search.svg' className='search-product-filter' />
                                    </InputGroup>
                                </Form> */}
                            </>
                            {this.state.products?.length &&
                                this.state.products.filter((ele) => this.state.searchProduct === '' ? ele : ele.name && ele.name.toLowerCase().includes(this.state.searchProduct.toLowerCase())
                                ).map((ele, i) => {
                                    return (
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                            spacing={2}
                                            className="accordion-option"
                                        >
                                            <FormControlLabel
                                                label={
                                                    <Typography className="text-options-filter">
                                                        {ele.name}
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize:'1.318vw' } }}
                                                        color="default"
                                                        checked={ele.checked}
                                                        onChange={(e) =>
                                                            this.handleCheck(e.target.checked,i, "products")
                                                        }
                                                    />
                                                }
                                            />
                                        </Stack>
                                    );
                                })}
                        </AccordionDetails>
                    </Accordion>


                    <Accordion
                        elevation={0}
                        disableGutters={true}
                        sx={{ paddingBottom: "0.573vw" }}
                    >
                        <AccordionSummary
                            className="accordian-summary"
                            expandIcon={<ExpandMoreIcon className="accordian-arrow" />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="text-title-filter">Actionable</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="accordian-detail max-height-acc">
                            <>
                                <Stack direction="row" gap="10px" marginBottom="15px" flexWrap="wrap">
                                    {categories?.length &&
                                        categories.map((ele, i) => {
                                            if (ele.checked) {
                                                return (
                                                    <button className="btn filterOptchk">
                                                        {ele.name}{" "}
                                                        <span>
                                                            <img className='filterclosebtn'
                                                                src="./images/icons/icon-close3.svg"
                                                                onClick={() =>
                                                                    this.handleCheck(false, i,"categories")
                                                                }
                                                            />{" "}
                                                        </span>{" "}
                                                    </button>
                                                );
                                            }
                                        })}
                                </Stack>
                            </>
                            {categories?.length &&
                                categories.map((ele, i) => {
                                    return (
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                            spacing={2}
                                            className="accordion-option"
                                        >
                                            <FormControlLabel
                                                label={
                                                    <Typography className="text-options-filter">
                                                        {ele.name}
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize:'1.318vw' } }}
                                                        color="default"
                                                        checked={ele.checked}
                                                        onChange={(e) =>
                                                            this.handleCheck(e.target.checked, i, "categories")
                                                        }
                                                    />
                                                }
                                            />
                                        </Stack>
                                    );
                                })}
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        elevation={0}
                        disableGutters={true}
                        sx={{ paddingBottom: "0.573vw" }}
                    >
                        <AccordionSummary
                            className="accordian-summary"
                            expandIcon={<ExpandMoreIcon className="accordian-arrow" />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="text-title-filter">Date</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="accordian-detail max-height-acc">
                            <>
                                <Stack direction="row" gap="10px" marginBottom="15px" flexWrap="wrap">
                                    {listingTimes?.length &&
                                        listingTimes.map((ele, i) => {
                                            if (ele.checked) {
                                                return (
                                                    <button className="btn filterOptchk">
                                                        {ele.name}{" "}
                                                        <span>
                                                            <img className='filterclosebtn'
                                                                src="./images/icons/icon-close3.svg"
                                                                onClick={() =>
                                                                    this.handleCheck(false, i,"listingTimes")
                                                                }
                                                            />{" "}
                                                        </span>{" "}
                                                    </button>
                                                );
                                            }
                                        })}
                                </Stack>
                            </>
                            {listingTimes?.length &&
                                listingTimes.map((ele, i) => {
                                    return (
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                            spacing={2}
                                            className="accordion-option"
                                        >
                                            <FormControlLabel
                                                label={
                                                    <Typography className="text-options-filter">
                                                        {ele.name}
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize:'1.318vw' } }}
                                                        color="default"
                                                        checked={ele.checked}
                                                        onChange={(e) =>
                                                            this.handleCheck(e.target.checked, i, "listingTimes")
                                                        }
                                                    />
                                                }
                                            />
                                        </Stack>
                                    );
                                })}
                        </AccordionDetails>
                    </Accordion>
                    {/* </Scrollbar> */}

                </Card>
                <FilterCustomDivider />
            </>
      </Box>
      </Modal>
        );
    }
}





function mapStateToProps(state) {
    const { user, user_id, sfid } = state.auth;
    const { leadTab } = state.user;
    return {
    //   user,
    //   products,
    //   offer_details,
      user_id,
      sfid,
      leadTab
    };
  }

// export default ProductCredFilter;
export default connect(mapStateToProps)(LeadCredFilter);

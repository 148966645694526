import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { salesForceLogin } from "../actions/auth";
import { getLeadFunnelData, getdashboard, getSalesInsightGraphData, getCustInsighGraphData, getTopProductGraphData, getCategory } from '../actions/user'
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from 'chart.js';
import { Doughnut, Line, Bar } from 'react-chartjs-2';
import Topbar from "../common/topbar";
import Filter from "../common/filter";
import Sidebar from "../common/sidebar";
import FunnelGraph from "funnel-graph-js";
import TopBarWizrHelpNotification from "./TopBarWizrHelpNotification";
import TopWizrbar  from "./TopWizrbar";
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import Divider from '@mui/material/Divider';


ChartJS.register(
  ArcElement,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  // ChartDataLabels
);



export const data = {
  labels: ['KYC Failed', 'Verification Failed', 'Low CIBIL', 'Other'],
  datasets: [
    {
      data: [12, 19, 3, 5],
      backgroundColor: [
        '#1251F1',
        '#F44DAF',
        '#F8E593',
        '#7AF496',
      ],
      borderColor: [
        '#fff',
        '#fff',
        '#fff',
        '#fff',
      ],
      borderWidth: 5,
    },
  ],
};

export const data2 = {
  labels: ['18 - 30', '30 - 40', '40 - 50', '50 - 60', "60+"],
  datasets: [
    {
      //data: [19, 8, 5, 8, 6],
      data: [0, 0, 0, 0, 0],
      backgroundColor: [
        '#1251F1',
        '#F44DAF',
        '#F8E593',
        '#7AF496',
        '#1824AC'
      ],
      borderColor: [
        '#fff',
        '#fff',
        '#fff',
        '#fff',
        '#fff',
      ],
      borderWidth: 5,
    },
  ],
};


export const options = {

  layout: {
    padding: {
    }
  },
  responsive: true,
  plugins: {
    legend: {
      display: true,
      labels: {
        padding: 20,
        color: 'rgb(255, 99, 132)',
        color: '#222',
        usePointStyle: true,

        font: {
          size: 16
        },
      },
      position: 'right',

    },
    linearScale: {

    },
    title: {
      display: false,
      text: 'Reject/Drop reason',
      position: 'right',
    },
  }
};


export const options3 = {
  layout: {
    padding: {

    }
  },
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 20,
        boxHeight: 20,
        padding: 20,
        color: 'rgb(255, 99, 132)',
        color: '#222',

        font: {
          size: 16
        },
      },
    },
    title: {
      display: false,
      text: '',
    },
    tooltip: {
      backgroundColor: 'rgba(132, 177, 209, 1)',
    }
  },
};

const labels = [];

export const data3 = {
  labels,
  datasets: [
    {
      label: 'Refund/Cancelled',
      fill: false,
      lineTension: 0.5,
      backgroundColor: '#0600FF',
      borderColor: '#0600FF',
      borderWidth: 2,
      data: []
    },
    {
      label: 'Disbursed',
      fill: false,
      lineTension: 0.5,
      backgroundColor: '#FF0097',
      borderColor: '#FF0097',
      borderWidth: 2,
      data: []
    }
  ]
};

export const options4 = {
  scale: {
    x: {
      stacked: true,
      min: 0,
      ticks: {
        stepSize: 5
      }
    },
    y: {
      stacked: true,
      beginAtAero: true,
    }
  },
  layout: {
    padding: {

    }
  },
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 0,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
      labels: {
        boxWidth: 20,
        boxHeight: 20,
        padding: 20,

        font: {
          size: 16
        },
      }
    },
    title: {
      display: false,
      text: 'Chart.js Horizontal Bar Chart',
    },
    datalabels: {
      color: '#ffffff',
      font: {
        weight: 'bold',
        size: '18'
      }      
    }
  },
};


export const data4 = {
  // labels: ['Business', 'IT', 'Marketing', 'Arts'],
  labels: [''],

  datasets: [
    {
      label: 'Disbursed',
      // data: [40, 50, 33,10],
      data: [0],

      // borderColor: 'rgba(248, 229, 147, 1)',
      backgroundColor: '#102031',
    },
    {
      label: 'Cancelled',
      data: [0],
      // data: [19, 15, 33, 33],
      // borderColor: 'rgba(244, 77, 175, 1)',
      backgroundColor: '#5B8FCF',
    },
    {
      label: 'Leads',
      data: [0],
      // data: [10, 30, 10, 18],
      // borderColor: 'rgba(18, 81, 241, 1)',
      backgroundColor: '#BDD9F1',
    }


  ],
};

const TODAY = "Today";
const WEEK = "This Week";
const MONTH = "This Month";
const GRAPH1 = "1";
const GRAPH2 = "2";
const GRAPH3 = "3";

class HomeCred extends Component {
  constructor(props) {
    super(props);
    this.getSalesInsight = this.getSalesInsight.bind(this);
    this.getTopProduct = this.getTopProduct.bind(this);
    this.getCustInsight = this.getCustInsight.bind(this);
    this.state = {
      content: "",
      page: 1,
      // SummarySelectValue: TODAY,
      SummarySelectValue: MONTH,
      funnelSelectValue: MONTH,
      salesGraphData: data3,
      custInsigh: data2,
      topProData: data4,
      salesSelectValue: MONTH,
      topProSeleValue: MONTH,
      RejDropSeleValue: TODAY,
      custInsightValue: MONTH,
      topProCat: ""
    };
  }

  getfunneldata = (sifIdIs, isUpdate) => {
    // let sfid = localStorage.getItem('sfid');
    this.props.dispatch(getLeadFunnelData(this.state.funnelSelectValue, sifIdIs)).then(response => {
      let graphData = [['0'], ['0'], ['0'], ['0'], ['0'], ['0']]
      if (response.status == "success") {
        // let graphData = [[response.rowData.approved_leads ? response.rowData.approved_leads : "10"], [response.rowData.cancelled_leads ? response.rowData.cancelled_leads : "8"], [response.rowData.disbursal_pending_leads ? response.rowData.disbursal_pending_leads : "6"], 
        // [response.rowData.disbursed_leads ? response.rowData.disbursed_leads : "4"], [response.rowData.received_leads ? response.rowData.received_leads : "2"], [response.rowData.wip_leads ? response.rowData.wip_leads : "2"]]
        graphData = [[response.rowData.received_leads ? response.rowData.received_leads : '0'], [response.rowData.wip_leads ? response.rowData.wip_leads : '0'], [response.rowData.approved_leads ? response.rowData.approved_leads : '0'],
        [response.rowData.disbursal_pending_leads ? response.rowData.disbursal_pending_leads : '0'], [response.rowData.disbursed_leads ? response.rowData.disbursed_leads : '0'], [response.rowData.cancelled_leads ? response.rowData.cancelled_leads : '0']]
      }
      const funneldata = {
        labels: [
          "Leads Received",
          "WIP",
          "Approved",
          "Disbursal Pending",
          "Disbursed",
          "Cancelled"
        ],
        subLabels: ["Data"],
        colors: ["#D9E5F4", "#BDD9F1", "#5B8FCF", "#8AAFDD", "#5B8FCF", "#102031"],
        values: graphData
        // values: [10000,8000,6000,3000,3000,3000]
      };

      const graph = new FunnelGraph({
        container: ".App1",
        gradientDirection: "horizontal",
        data: funneldata,
        displayPercent: false,
        direction: "Horizontal",
        // width: 1000,
        // height: 350,
        subLabelValue: "values",
        color:'colors'
      });
      // document.getElementsByClassName("App1").innerHTML = "";
      graph.draw();
      if (isUpdate == "update") {
        graph.updateData(graphData)
        // graph.updateData({graphData}) 
      }
    })
      .catch(error => {
        console.log(error)
      })
  }
  getSummarydata = (data) => {
    let sfid = localStorage.getItem('sfid');
    let isUpdate="update";
    this.setState({ SummarySelectValue: data }, () => {
      this.props.dispatch(getdashboard(this.state.SummarySelectValue, sfid));
      this.props.dispatch(getLeadFunnelData(data, sfid)).then(response => {
        let graphData = [['0'], ['0'], ['0'], ['0'], ['0'], ['0']]
        if (response.status == "success") {
          // let graphData = [[response.rowData.approved_leads ? response.rowData.approved_leads : "10"], [response.rowData.cancelled_leads ? response.rowData.cancelled_leads : "8"], [response.rowData.disbursal_pending_leads ? response.rowData.disbursal_pending_leads : "6"], 
          // [response.rowData.disbursed_leads ? response.rowData.disbursed_leads : "4"], [response.rowData.received_leads ? response.rowData.received_leads : "2"], [response.rowData.wip_leads ? response.rowData.wip_leads : "2"]]
          graphData = [[response.rowData.received_leads ? response.rowData.received_leads : '0'], [response.rowData.wip_leads ? response.rowData.wip_leads : '0'], [response.rowData.approved_leads ? response.rowData.approved_leads : '0'],
          [response.rowData.disbursal_pending_leads ? response.rowData.disbursal_pending_leads : '0'], [response.rowData.disbursed_leads ? response.rowData.disbursed_leads : '0'], [response.rowData.cancelled_leads ? response.rowData.cancelled_leads : '0']]
        }
        const funneldata = {
          labels: [
            "Leads Received",
            "WIP",
            "Approved",
            "Disbursal Pending",
            "Disbursed",
            "Cancelled"
          ],
          subLabels: ["Data"],
          colors: ["#D9E5F4", "#BDD9F1", "#5B8FCF", "#8AAFDD", "#5B8FCF", "#102031"],
          values: graphData
          // values: [10000,8000,6000,3000,3000,3000]
        };
  
        const graph = new FunnelGraph({
          container: ".App1",
          gradientDirection: "horizontal",
          data: funneldata,
          displayPercent: false,
          direction: "Horizontal",
          // width: 1000,
          // height: 350,
          subLabelValue: "values",
          color:'colors'
        });
        // document.getElementsByClassName("App1").innerHTML = "";
        graph.draw();
        if (isUpdate == "update") {
          graph.updateData(graphData)
          // graph.updateData({graphData}) 
        }
      })
      this.props.dispatch(getTopProductGraphData(sfid, data, this.state.topProCat !== 'All Category' ? this.state.topProCat : "")).then(response => {
      // this.props.dispatch(getTopProductGraphData(sfid, data, 'All Category')).then(response => {

        if (response.status == "success") {
          let labelArray = []
          let disbusdata = []
          let cancelData = []
          let totalLead = []
  
          let graphData = response.top5Product ? response.top5Product : []
          if (graphData) {
            labelArray = graphData.labels ? graphData.labels : []
            disbusdata = graphData.Disbursed ? graphData.Disbursed : []
            cancelData = graphData.Cancelled ? graphData.Cancelled : []
            totalLead = graphData.Leads ? graphData.Leads : []
          }
  
          // for (let i = 0; i < response.top5Product.length; i++) {
          //   const productIs = response.top5Product[i];
          //   let disCount = 0;
          //   let cancelCount = 0;
          //   if (productIs.product_name) {
          //     labelArray.push(productIs.product_name)
          //     for (let j = 0; j < response.topProductByStatus.length; j++) {
          //       const singleProd = response.topProductByStatus[j];
          //       if (productIs.product_name == singleProd.product_name) {
          //         totalLead.push(productIs.total_lead)
          //         if (singleProd.status == "Loan Disbursed") {
          //           disCount += singleProd.total_lead
          //         } else if (singleProd.status == "Loan Cancelled") {
          //           cancelCount += singleProd.total_lead
          //         }
          //       }
          //     }
          //     disbusdata.push(disCount);
          //     cancelData.push(cancelCount);
          //     disCount = 0; cancelCount = 0;
          //   }
          // }
  
          // if(labelArray.length == 0){
          //   labelArray = ['Product 1', 'Product 2', 'Product 3', 'Product 4', 'Product 5']
          // }
          // if(disbusdata.length == 0){
          //   disbusdata = [5,8,6,9,2]
          // }
          // if(cancelData.length == 0){
          //   cancelData = [10,8,2,6,9]
          // }
          // if(totalLead.length == 0){
          //   totalLead = [15,16,8,15,11]
          // }
  
  
          let data = {
            labels: labelArray,
            //labels: ['Product 1', 'Product 2', 'Product 3', 'Product 4', 'Product 5'],
            datasets: [
              {
                label: 'Disbursed',
                data: disbusdata,
                //data: [5,8,6,9,20],
                // borderColor: 'rgba(248, 229, 147, 1)',
                backgroundColor: '#102031',
              },
              {
                label: 'Cancelled',
                data: cancelData,
                //data: [10,8,2,6,9],
                // borderColor: 'rgba(244, 77, 175, 1)',
                backgroundColor: '#5B8FCF',
              },
              {
                label: 'Leads',
                data: totalLead,
                //data: [15,16,8,15,11],
                // borderColor: 'rgba(18, 81, 241, 1)',
                backgroundColor: '#BDD9F1',
              }
  
  
            ],
          };
          this.setState({
            topProData: data,
          })
        }
      })
      this.props.dispatch(getCustInsighGraphData(sfid, data)).then(response => {
        let graphData = [response.customerInsight[0].ageGroupCount18to30 ? response.customerInsight[0].ageGroupCount18to30 : "0", response.customerInsight[0].ageGroupCount30to40 ? response.customerInsight[0].ageGroupCount30to40 : "0",
        response.customerInsight[0].ageGroupCount40to50 ? response.customerInsight[0].ageGroupCount40to50 : "0",
        response.customerInsight[0].ageGroupCount50to60 ? response.customerInsight[0].ageGroupCount50to60 : "0", response.customerInsight[0].ageGroupCount60plus ? response.customerInsight[0].ageGroupCount60plus : "0"];
        // for (let i = 0; i < response.customerInsight.length; i++) {
        //   const element = response.customerInsight[i];
  
        // }
        // console.log('cust insight', graphData)
        let data = {
          labels: ['18 - 30', '30 - 40', '40 - 50', '50 - 60', "60+"],
          datasets: [
            {
              data: graphData,
              backgroundColor: [
                '#102031',
                '#D9E5F4',
                '#BDD9F1',
                '#5B8FCF',
                '#8AAFDD'
              ],
              borderColor: [
                '#fff',
                '#fff',
                '#fff',
                '#fff',
                '#fff',
              ],
              borderWidth: 5,
            },
          ],
        };
        // console.log('graph data is', data)
        this.setState({
          custInsigh: data,
        })
      })
      this.props.dispatch(getSalesInsightGraphData(sfid, data)).then(res => {
        // let year = res.disburseData[0].year;
        // let labelD =[];
        // let labelR =[];
        let disbursedData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let refundData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        if (res.status == "success") {
          for (let i = 0; i < res.disburseData.length; i++) {
            // labelD.push(res.disburseData[i].month);
            const disDataIs = res.disburseData[i]
            switch (disDataIs.month) {
              case "January":
                disbursedData[0] = disDataIs.amount
                break;
              case "February":
                disbursedData[1] = disDataIs.amount
                break;
              case "March":
                disbursedData[2] = disDataIs.amount
                break;
              case "April":
                disbursedData[3] = disDataIs.amount
                break;
              case "May":
                disbursedData[4] = disDataIs.amount
                break;
              case "June":
                disbursedData[5] = disDataIs.amount
                break;
              case "July":
                disbursedData[6] = disDataIs.amount
                break;
              case "August":
                disbursedData[7] = disDataIs.amount
                break;
              case "September":
                disbursedData[8] = disDataIs.amount
                break;
              case "October":
                disbursedData[9] = disDataIs.amount
                break;
              case "November":
                disbursedData[10] = disDataIs.amount
                break;
              case "December":
                disbursedData[11] = disDataIs.amount
                break;
              default:
                break;
            }
            // disbursedData.push(res.disburseData[i].amount)
          }
          for (let i = 0; i < res.refundData.length; i++) {
            // labelR.push(res.refundData[i].month);
            const refDataIs = res.refundData[i]
            switch (refDataIs.month) {
              case "January":
                refundData[0] = refDataIs.amount
                break;
              case "February":
                refundData[1] = refDataIs.amount
                break;
              case "March":
                refundData[2] = refDataIs.amount
                break;
              case "April":
                refundData[3] = refDataIs.amount
                break;
              case "May":
                refundData[4] = refDataIs.amount
                break;
              case "June":
                refundData[5] = refDataIs.amount
                break;
              case "July":
                refundData[6] = refDataIs.amount
                break;
              case "August":
                refundData[7] = refDataIs.amount
                break;
              case "September":
                refundData[8] = refDataIs.amount
                break;
              case "October":
                refundData[9] = refDataIs.amount
                break;
              case "November":
                refundData[10] = refDataIs.amount
                break;
              case "December":
                refundData[11] = refDataIs.amount
                break;
              default:
                break;
            }
            // refundData.push(res.refundData[i].amount)
          }
        }
        //  let labels = [...new Set([...labelD,...labelR])];
        let datas = {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
          datasets: [
            {
              label: 'Refund/Cancelled',
              fill: false,
              lineTension: 0.5,
              backgroundColor: '#102031',
              borderColor: '#102031',
              borderWidth: 2,
              borderRadius:15,
              data: refundData,
            },
            {
              label: 'Disbursed',
              fill: false,
              lineTension: 0.5,
              backgroundColor: '#5B8FCF',
              borderColor: '#5B8FCF',
              borderWidth: 2,
              borderRadius:15,
              data: disbursedData,
            }
          ]
        };
        this.setState({ salesGraphData: datas });
  
  
  
      });
      
    });
    
  }
  componentDidMount() {
    let sfid = localStorage.getItem('sfid');
    const { funnelSelectValue } = this.state;
    this.props.dispatch(getCategory());
    this.getfunneldata(sfid);
    const { SummarySelectValue } = this.state;
    this.props.dispatch(getdashboard(SummarySelectValue, sfid));
    this.getSalesInsight(sfid)
    this.getTopProduct(sfid);
    this.getCustInsight(sfid, this.state.custInsightValue);
    let getProd = { merchant_id: this.props.user_id }
    //alert(sfid)


    // this.props.dispatch(getdashboard(getProd, sfid)).then((response) => {
    //   if (!response.responseCode) {
    //     this.setState({ rowData: response });
    //   }
    // });


    // if(this.props.products){
    //     this.setState({products : this.props.products})
    // }
  }

  getSalesInsight = (sfIdis) => {
    this.props.dispatch(getSalesInsightGraphData(sfIdis, this.state.salesSelectValue)).then(res => {
      // let year = res.disburseData[0].year;
      // let labelD =[];
      // let labelR =[];
      let disbursedData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let refundData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      if (res.status == "success") {
        for (let i = 0; i < res.disburseData.length; i++) {
          // labelD.push(res.disburseData[i].month);
          const disDataIs = res.disburseData[i]
          switch (disDataIs.month) {
            case "January":
              disbursedData[0] = disDataIs.amount
              break;
            case "February":
              disbursedData[1] = disDataIs.amount
              break;
            case "March":
              disbursedData[2] = disDataIs.amount
              break;
            case "April":
              disbursedData[3] = disDataIs.amount
              break;
            case "May":
              disbursedData[4] = disDataIs.amount
              break;
            case "June":
              disbursedData[5] = disDataIs.amount
              break;
            case "July":
              disbursedData[6] = disDataIs.amount
              break;
            case "August":
              disbursedData[7] = disDataIs.amount
              break;
            case "September":
              disbursedData[8] = disDataIs.amount
              break;
            case "October":
              disbursedData[9] = disDataIs.amount
              break;
            case "November":
              disbursedData[10] = disDataIs.amount
              break;
            case "December":
              disbursedData[11] = disDataIs.amount
              break;
            default:
              break;
          }
          // disbursedData.push(res.disburseData[i].amount)
        }
        for (let i = 0; i < res.refundData.length; i++) {
          // labelR.push(res.refundData[i].month);
          const refDataIs = res.refundData[i]
          switch (refDataIs.month) {
            case "January":
              refundData[0] = refDataIs.amount
              break;
            case "February":
              refundData[1] = refDataIs.amount
              break;
            case "March":
              refundData[2] = refDataIs.amount
              break;
            case "April":
              refundData[3] = refDataIs.amount
              break;
            case "May":
              refundData[4] = refDataIs.amount
              break;
            case "June":
              refundData[5] = refDataIs.amount
              break;
            case "July":
              refundData[6] = refDataIs.amount
              break;
            case "August":
              refundData[7] = refDataIs.amount
              break;
            case "September":
              refundData[8] = refDataIs.amount
              break;
            case "October":
              refundData[9] = refDataIs.amount
              break;
            case "November":
              refundData[10] = refDataIs.amount
              break;
            case "December":
              refundData[11] = refDataIs.amount
              break;
            default:
              break;
          }
          // refundData.push(res.refundData[i].amount)
        }
      }
      //  let labels = [...new Set([...labelD,...labelR])];
      let datas = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: 'Refund/Cancelled',
            fill: false,
            lineTension: 0.5,
            backgroundColor: '#102031',
            borderColor: '#102031',
            borderWidth: 2,
            borderRadius:15,
            data: refundData,
          },
          {
            label: 'Disbursed',
            fill: false,
            lineTension: 0.5,
            backgroundColor: '#5B8FCF',
            borderColor: '#5B8FCF',
            borderWidth: 2,
            borderRadius:15,
            data: disbursedData,
          }
        ]
      };
      this.setState({ salesGraphData: datas });



    });
  }

  getTopProduct = (sfIdIs) => {
    //console.log('top pro cat',this.state.topProCat)
    this.props.dispatch(getTopProductGraphData(sfIdIs, this.state.topProSeleValue, this.state.topProCat !== 'All Category' ? this.state.topProCat : "")).then(response => {
      if (response.status == "success") {
        let labelArray = []
        let disbusdata = []
        let cancelData = []
        let totalLead = []

        let graphData = response.top5Product ? response.top5Product : []
        if (graphData) {
          labelArray = graphData.labels ? graphData.labels : []
          disbusdata = graphData.Disbursed ? graphData.Disbursed : []
          cancelData = graphData.Cancelled ? graphData.Cancelled : []
          totalLead = graphData.Leads ? graphData.Leads : []
        }

        // for (let i = 0; i < response.top5Product.length; i++) {
        //   const productIs = response.top5Product[i];
        //   let disCount = 0;
        //   let cancelCount = 0;
        //   if (productIs.product_name) {
        //     labelArray.push(productIs.product_name)
        //     for (let j = 0; j < response.topProductByStatus.length; j++) {
        //       const singleProd = response.topProductByStatus[j];
        //       if (productIs.product_name == singleProd.product_name) {
        //         totalLead.push(productIs.total_lead)
        //         if (singleProd.status == "Loan Disbursed") {
        //           disCount += singleProd.total_lead
        //         } else if (singleProd.status == "Loan Cancelled") {
        //           cancelCount += singleProd.total_lead
        //         }
        //       }
        //     }
        //     disbusdata.push(disCount);
        //     cancelData.push(cancelCount);
        //     disCount = 0; cancelCount = 0;
        //   }
        // }

        // if(labelArray.length == 0){
        //   labelArray = ['Product 1', 'Product 2', 'Product 3', 'Product 4', 'Product 5']
        // }
        // if(disbusdata.length == 0){
        //   disbusdata = [5,8,6,9,2]
        // }
        // if(cancelData.length == 0){
        //   cancelData = [10,8,2,6,9]
        // }
        // if(totalLead.length == 0){
        //   totalLead = [15,16,8,15,11]
        // }


        let data = {
          labels: labelArray,
          //labels: ['Product 1', 'Product 2', 'Product 3', 'Product 4', 'Product 5'],
          datasets: [
            {
              label: 'Disbursed',
              data: disbusdata,
              //data: [5,8,6,9,20],
              // borderColor: 'rgba(248, 229, 147, 1)',
              backgroundColor: '#102031',
            },
            {
              label: 'Cancelled',
              data: cancelData,
              //data: [10,8,2,6,9],
              // borderColor: 'rgba(244, 77, 175, 1)',
              backgroundColor: '#5B8FCF',
            },
            {
              label: 'Leads',
              data: totalLead,
              //data: [15,16,8,15,11],
              // borderColor: 'rgba(18, 81, 241, 1)',
              backgroundColor: '#BDD9F1',
            }


          ],
        };
        this.setState({
          topProData: data,
        })
      }
    })
      .catch(error => {

      })
  }

  getCustInsight = (sfIdis) => {
    this.props.dispatch(getCustInsighGraphData(sfIdis, this.state.custInsightValue)).then(response => {
      let graphData = [response.customerInsight[0].ageGroupCount18to30 ? response.customerInsight[0].ageGroupCount18to30 : "0", response.customerInsight[0].ageGroupCount30to40 ? response.customerInsight[0].ageGroupCount30to40 : "0",
      response.customerInsight[0].ageGroupCount40to50 ? response.customerInsight[0].ageGroupCount40to50 : "0",
      response.customerInsight[0].ageGroupCount50to60 ? response.customerInsight[0].ageGroupCount50to60 : "0", response.customerInsight[0].ageGroupCount60plus ? response.customerInsight[0].ageGroupCount60plus : "0"];
      // for (let i = 0; i < response.customerInsight.length; i++) {
      //   const element = response.customerInsight[i];

      // }
      // console.log('cust insight', graphData)
      let data = {
        labels: ['18 - 30', '30 - 40', '40 - 50', '50 - 60', "60+"],
        datasets: [
          {
            data: graphData,
            backgroundColor: [
              '#102031',
              '#D9E5F4',
              '#BDD9F1',
              '#5B8FCF',
              '#8AAFDD'
            ],
            borderColor: [
              '#fff',
              '#fff',
              '#fff',
              '#fff',
              '#fff',
            ],
            borderWidth: 5,
          },
        ],
      };
      // console.log('graph data is', data)
      this.setState({
        custInsigh: data,
      })
    })
      .catch(error => {

      })
  }
   numDifferentiation=(value)=> {
    const val = Math.abs(value)
    if (val >= 10000000) return `${(value / 10000000).toFixed(2)} Cr`
    if (val >= 100000) return `${(value / 100000).toFixed(2)} Lac`
    return value;
  }
  handleOpenClick=(data)=>{
    let sfid = localStorage.getItem('sfid');
    this.setState({
      topProCat: data,
    }, () => { this.getTopProduct(sfid) });
  }
  render() {

    const { user_id, dashboardData, leadFunnelData, salesInsightGraphData, category } = this.props;
    let sfid = localStorage.getItem('sfid');


    if (!user_id) {
      return <Redirect to="/login" />
    }
    return (
      <>
        <Helmet>
          <title> Eduvanz - Dashboard </title>
        </Helmet>
        
        <div id="wrapper">
        
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar
                dispatch={this.props.dispatch}
                title={"Dashboard"}
              />
             
              <TopBarWizrHelpNotification/>
              <TopWizrbar showMontlydropdowncred={true} getSummary={this.getSummarydata}/>
              {/* <div className="container-fluid containerBackground"> */}
                {/* Content Row */}
                {/* {dashboardData && dashboardData.rowData ?
                    ( */}
                
                {/* <div className="row leadsdashboardcred" style={{alignItems:"start"}}>
                    <img src="./img/DashboardImg.png" width="100px" height="100px" alt='' /> */}
                  {/* Earnings (Monthly) Card Example */}
                  {/* <div className="col-md-12">
                    <div className="section-heading">
                      <h4>Summary</h4>
                      <div className="select-rounded">
                            <select>
                              <option>Today</option>
                              <option>Tommrrow</option>
                              <option>Sunday</option>
                            </select>
                          </div>
                      <div className="select-rounded mr-3">
                        <select style={{ float: "right" }} onChange={this.getSummarydata}>
                          <option value="Today" >Today</option>
                          <option value="This Week">This Week</option>
                          <option value="This Month" selected>This Month</option>
                        </select>
                      </div>
                    </div>
                  </div> */}

                  {/* Earnings (Monthly) Card Example */}
                  {/* <div className="col-xl-4 col-md-6 stundentEnrolledcred"> */}
                    {/* <img src="images/icons/croma-logo.png" width="100px" height="100px" alt='' /> */}
                    {/* <div className="card summary-info cred">
                      <p className="total">Total Loans</p>
                      <h3>
                        
                        ₹ {dashboardData && dashboardData && dashboardData.total_loans ? dashboardData.total_loans.toLocaleString() : '0'}
                        {this.numDifferentiation(541247745)} 
                      </h3>
                    </div> */}
                    {/* <span className="credApplicationCount">{dashboardData && dashboardData && dashboardData.application_count ? dashboardData.application_count : '0'} applications</span> */}
                    
                  {/* </div> */}
                  {/* <Divider orientation="vertical" flexItem></Divider> */}
                  {/* <div className="col-xl-2 col-md-6 stundentEnrolledcred">
                    <div className="card summary-info cred">
                      <p className="total">Total Leads</p>
                      <h3>
                        {dashboardData && dashboardData && dashboardData.total_lead ? dashboardData.total_lead : '0'}
                        
                      </h3>
                      <p className="application">{dashboardData && dashboardData && dashboardData.application_count ? dashboardData.application_count : '0'} applications</p>
                    </div>
                  </div> */}
                  {/* Earnings (Monthly) Card Example */}
                  {/* <div className="col-xl-3 col-md-6 stundentEnrolledcred">
                    <div className="card summary-info cred">
                      <p className="total">Avg. Order Value </p>
                      <h3>
                        ₹ {dashboardData && dashboardData && dashboardData.avg_order_value ? dashboardData.avg_order_value.toLocaleString() : '0'}
                        ₹ {dashboardData && dashboardData && dashboardData.total_loans ? dashboardData.total_loans.toLocaleString() : '0'}
                        ₹ 9,50,483 
                      </h3>
                      <p className="application">{dashboardData && dashboardData && dashboardData.application_count ? dashboardData.application_count : '0'} applications</p>
                    </div>
                  </div> */}
                  {/* <div className="col-xl-3 col-md-6 stundentEnrolledcred">
                    <div className="card summary-info cred summaryinforight">
                      <p className="total">Avg. Loan Tenure</p>
                      <h3>
                        ₹ {dashboardData && dashboardData && dashboardData.avg_order_value ? dashboardData.avg_order_value.toLocaleString() : '0'}
                        12 mo
                        {dashboardData && dashboardData && dashboardData.avg_tenure ? dashboardData.avg_tenure.toLocaleString() : '0'} mo
                      </h3>

                    </div>
                  </div> */}
                  
                  
                  {/* Pending Requests Card Example */}
                  {/* <div className="col-xl-2 col-md-6 ">
                    <div className="card summary-info summaryinforight">
                      <p className="total">Loan Amount Disbursed</p>
                      <h3> */}
                        {/* {dashboardData && dashboardData && dashboardData.avg_tenure ? dashboardData.avg_tenure.toLocaleString() : '0'} mo */}
                        {/* ₹ 9,50,483
                      </h3>
                    </div> */}

                  {/* </div> */}

                {/* </div>
              </div> */}
              <div className="container-fluid containerBackground">
              <div className="row leadsdashboardcred">
                  
                  {/* <div className="col-md-12">
                    <div className="section-heading">
                      <h4>Summary</h4>
                      <div className="select-rounded mr-3">
                        <select style={{ float: "right" }} onChange={this.getSummarydata}>
                          <option value="Today" >Today</option>
                          <option value="This Week">This Week</option>
                          <option value="This Month" selected>This Month</option>
                        </select>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-xl-4 col-md-4 mb-4 d-flex" style={{paddingLeft:"20px"}}>
                  <img src="./img/DashboardImg.png" width="100px" height="100px" alt='' />
                    <div className="card summary-info d-flex leftBorderNone">
                  {/* <img src="./img/DashboardImg.png" width="100px" height="100px" alt='' /> */}
                      <p className="total">Total Loans</p>
                      <h3>₹ {dashboardData && dashboardData && dashboardData.total_loans ? dashboardData.total_loans : '0'}</h3>
                      <span className="credApplicationCount">{dashboardData && dashboardData && dashboardData.application_count ? dashboardData.application_count.toLocaleString('en-IN') : '0'} applications</span>
                    </div>
                  </div>
                  
                  <div className="col-xl-2 col-md-2 mb-4">
                    <div className="card summary-info">
                      <p className="total">Total Leads</p>
                      <h3>{dashboardData && dashboardData && dashboardData.total_lead ? dashboardData.total_lead.toLocaleString('en-IN') : '0'}</h3>

                    </div>
                  </div>
                  
                  <div className="col-xl-3 col-md-3 mb-4">
                    <div className="card summary-info">
                      <p className="total">Avg. Order Value</p>
                      <h3>₹ {dashboardData && dashboardData && dashboardData.avg_order_value ? dashboardData.avg_order_value.toLocaleString('en-IN') : '0'}</h3>

                    </div>
                  </div>
                  
                  <div className="col-xl-3 col-md-3 mb-4">
                    <div className="card summary-info">
                      <p className="total">Avg. Loan Tenure</p>
                      <h3> {dashboardData && dashboardData && dashboardData.avg_tenure ? dashboardData.avg_tenure : '0'} mo</h3>
                    </div>

                  </div>
                </div>
              </div>
              {/* <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card summary-info">
                      <p className="total">Total Loans</p>
                      <h3>₹ {dashboardData && dashboardData.rowData && dashboardData.rowData.total_loans ? dashboardData.rowData.total_loans : '-'}</h3>
                      <p className="application">{dashboardData && dashboardData.rowData && dashboardData.rowData.application_count ? dashboardData.rowData.application_count : '-'} applications</p>
                    </div>
                  </div> */}
              {/* Earnings (Monthly) Card Example */}
              {/* <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card summary-info">
                      <p className="total">Total Leads</p>
                      <h3>{dashboardData && dashboardData.rowData && dashboardData.rowData.total_lead ? dashboardData.rowData.total_lead : '-'}</h3>

                    </div>
                  </div> */}
              {/* Earnings (Monthly) Card Example */}
              {/* <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card summary-info">
                      <p className="total">Avg. Order Value</p>
                      <h3>₹ {dashboardData && dashboardData.rowData && dashboardData.rowData.avg_order_value ? dashboardData.rowData.avg_order_value : '-'}</h3>

                    </div>
                  </div> */}
              {/* Pending Requests Card Example */}
              {/* <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card summary-info">
                      <p className="total">Avg. Loan Tenure</p>
                      <h3> {dashboardData && dashboardData.rowData && dashboardData.rowData.avg_tenure ? dashboardData.rowData.avg_tenure : '-'} mo</h3>
                    </div>
                  </div> */}
            </div>
            {/* ) : ""
                      } */}
            
            {/* <div> */}







              {/* <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card summary-info">
                      <p className="total">Total Loans</p>
                      <h3>₹ {dashboardData && dashboardData.rowData && dashboardData.rowData.total_loans ? dashboardData.rowData.total_loans : '-'}</h3>
                      <p className="application">{dashboardData && dashboardData.rowData && dashboardData.rowData.application_count ? dashboardData.rowData.application_count : '-'} applications</p>
                    </div>
                  </div> */}
              {/* Earnings (Monthly) Card Example */}
              {/* <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card summary-info">
                      <p className="total">Total Leads</p>
                      <h3>{dashboardData && dashboardData.rowData && dashboardData.rowData.total_lead ? dashboardData.rowData.total_lead : '-'}</h3>

                    </div>
                  </div> */}
              {/* Earnings (Monthly) Card Example */}
              {/* <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card summary-info">
                      <p className="total">Avg. Order Value</p>
                      <h3>₹ {dashboardData && dashboardData.rowData && dashboardData.rowData.avg_order_value ? dashboardData.rowData.avg_order_value : '-'}</h3>

                    </div>
                  </div> */}
              {/* Pending Requests Card Example */}
              {/* <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card summary-info">
                      <p className="total">Avg. Loan Tenure</p>
                      <h3> {dashboardData && dashboardData.rowData && dashboardData.rowData.avg_tenure ? dashboardData.rowData.avg_tenure : '-'} mo</h3>
                    </div>
                  </div> */}
            {/* </div> */}
            {/* ) : ""
                      } */}
            {/* <hr></hr> */}
            <div className="leadschartcontainercred">

              <div className="row">
                <div className="col-6">
                  <div className="section-heading">
                    <h4 style={{ marginBottom: "20px" }}>Lead Funnel</h4>
                  </div>
                </div>
                <div className="col-6">
                  {/* <div className="select-rounded mr-3">
                    <select style={{ float: "right" }} onChange={(e) => {
                      this.setState({
                        funnelSelectValue: e.target.value,
                      }, () => { this.getfunneldata(sfid, "update") })
                    }}>
                      <option value="Today" >Today</option>
                      <option value="This Week">This Week</option>
                      <option value="This Month" selected>This Month</option>
                    </select>
                  </div> */}
                </div>
              </div>

              {/* <div className="row">
                    <div className="col-md-2 col-sm-6 lead-funnel">
                      <h6>Leads Received</h6>
                      <h4>{leadFunnelData.received_leads && leadFunnelData.received_leads}</h4>
                    </div>
                    <div className="col-md-2 col-sm-6 lead-funnel">
                      <h6>WIP</h6>
                      <h4>{leadFunnelData.wip_leads && leadFunnelData.wip_leads}</h4>
                    </div>
                    <div className="col-md-2 col-sm-6 lead-funnel">
                      <h6>Approved</h6>
                      <h4>{leadFunnelData.approved_leads && leadFunnelData.approved_leads}</h4>
                    </div>
                    <div className="col-md-2 col-sm-6 lead-funnel">
                      <h6>Disbursal Pending</h6>
                      <h4>{leadFunnelData.disbursal_pending_leads && leadFunnelData.disbursal_pending_leads}</h4>
                    </div>
                    <div className="col-md-2 col-sm-6 lead-funnel">
                      <h6>Disbursed</h6>
                      <h4>{leadFunnelData.disbursed_leads && leadFunnelData.disbursed_leads}</h4>
                    </div>
                    <div className="col-md-2 col-sm-6 lead-funnel">
                      <h6>Cancelled</h6>
                      <h4>{leadFunnelData.cancelled_leads && leadFunnelData.cancelled_leads}</h4>
                    </div>
                  </div> */}
              <div className="App1 credCont col-12" style={{ height: "350px" }} />
            </div>
            {/* <hr></hr> */}
            <div className="topcoursesChartcontainercred">
              <div className="row">
                <div className="col-lg-12 px-lg-5 border-right">
                  <div className="row">
                    <div className="col-12">
                      <div className="section-heading">
                        <h4>Top Products</h4>
                        <div className="d-flex align-items-center mt-3 mt-lg-0">
                          {/* <div className="select-rounded mr-3">
                          <select onChange={(e) => {
                            e.target.value &&
                              this.setState({
                                topProCat: e.target.value,
                              }, () => { this.getTopProduct(sfid) });
                          }}>
                            <option value="All Category">All Categories</option>
                            {
                              category &&
                              category.map(data => {
                                return (
                                  <option value={data.category_name}>{data.category_name}</option>
                                )
                              })
                            }
                          </select>
                        </div> */}
                          {/* <div className="select-rounded">
                            <select onChange={(e) => {
                              this.setState({
                                topProSeleValue: e.target.value,
                              }, () => { this.getTopProduct(sfid) });
                            }}
                            >
                              <option value={TODAY}>Today</option>
                              <option value={WEEK}>This Week</option>
                              <option value={MONTH} selected>This Month</option>
                            </select>
                          </div> */}

                                                            <div class="dropdown openCard">
  {/* <button class="btn  dropdown-toggle btn-sm dropdownData" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                                                                  <button role="button" type="button" className=" d-flex btn dropdown dropdownData dropdownSubject" data-toggle="dropdown"> 
                                                                  All Categories
                                                                    {/* <span><i className="fa fa-angle-down pl-3"></i></span> */}
                                                                    </button>
                                                                    <div class="dropdown-menu dropdown-menu-subject" aria-labelledby="dropdownMenuButton">
                                                                      {category && <a class="dropdown-item"  onClick={()=>this.handleOpenClick("All Category")} >All Categories</a>}
                                                                    {
                              category &&
                              category.map(data => {
                                return (<a class="dropdown-item"  onClick={()=>this.handleOpenClick(data.category_name)} >{data.category_name}</a>)
                              })
                            }
                                                                        {/* <a class="dropdown-item" href="#">IT & Development</a>
                                                                        <a class="dropdown-item" href="#">Marketing</a>
                                                                        <a class="dropdown-item" href="#">Design</a>
                                                                        <a class="dropdown-item" href="#">Arts</a>
                                                                        <a class="dropdown-item" href="#">Teaching and Academics</a>
                                                                        <a class="dropdown-item" href="#">Science</a> */}
                                                                    </div>
                                                                    </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Bar options={options4} 
                  // plugins={ChartDataLabels} 
                  data={this.state.topProData} 
                  height="80"/>
                  <p >Count <br/>(in Thousand)</p>
                </div>
                
                {/* <div className="col-lg-5 px-lg-5">
                <div className="row">
                  <div className="col-12">
                    <div className="section-heading">
                      <h4>Reject/Drop reason</h4>
                      <div className="d-flex align-items-center">

                        <div className="select-rounded">
                          <select onChange={(e) => {
                            this.setState({
                              RejDropSeleValue: e.target.value,
                            })
                          }}>
                            <option value={TODAY}>Today</option>
                            <option value={WEEK}>This Week</option>
                            <option value={MONTH} selected>This Month</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Doughnut options={options} data={data} />
              </div> */}
              </div>
            </div>
            {/* <hr></hr> */}
            <div className="row mb-5">              
              <div className="col-lg-7 px-lg-5">
                <div className="row">
                  <div className="col-12">
                    <div className="section-heading">
                      <h4>Sales/Revenue Chart</h4>
                      {/* <div className="d-flex align-items-center">

                        <div className="select-rounded">
                          <select onChange={(e) => {
                            this.setState({
                              salesSelectValue: e.target.value,
                            }, () => { this.getSalesInsight(sfid) })
                          }}>
                            <option value={TODAY}>Today</option>
                            <option value={WEEK}>This Week</option>
                            <option value={MONTH} selected>This Month</option>
                          </select>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <Line options={options3} data={this.state.salesGraphData} height="200" />
              </div>
              <div className="col-lg-5 px-lg-5">
                <div className="row">
                  <div className="col-12">
                    <div className="section-heading align-items-start">
                      <h4>Customer Insights</h4>
                      {/* <div className="">
                        <div className="select-rounded mb-3">
                          <select onChange={(e) => {
                            this.setState({
                              custInsightValue: e.target.value,
                            }, () => { this.getCustInsight(sfid) })
                          }}>
                            <option value={TODAY}>Today</option>
                            <option value={WEEK}>This Week</option>
                            <option value={MONTH} selected>This Month</option>
                          </select>
                        </div>
                        <div className="select-rounded">
                              <select>
                                <option>By age</option>
                                <option>Tommrrow</option>
                                <option>Sunday</option>
                              </select>
                            </div>

                      </div> */}
                    </div>
                  </div>
                </div>
                <Doughnut options={options} data={this.state.custInsigh} />
              </div>
            </div>
          </div>
        </div >
        {/* </div>
        </div> */}
        < Filter />
      </>
    );
  }
}


function mapStateToProps(state) {
  const { leads, leadFunnelData, salesInsightGraphData, dashboardData, category } = state.user;
  const { user_id, token } = state.auth;
  const { message } = state.message;
  return {
    leads,
    user_id,
    token,
    message,
    leadFunnelData,
    dashboardData,
    salesInsightGraphData,
    category
  };
}
export default connect(mapStateToProps)(HomeCred);

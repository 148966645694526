import React from 'react'
import { Modal } from "react-bootstrap"
import { Component } from 'react';

const initialState = {
    closeModal: false
}

class CenteredPopUp extends Component {
    constructor(props) {
        super()
        this.state = initialState;
    }

    handleClose = () => {
        this.props.closePopUp();
    }
    // componentDidMount(){
    //     document.getElementById("x").style.opacity = "0.5";

    // }
    render() {
        const { show, children,getWidthclass } = this.props
        return (

            <>
                <Modal
                    show={show}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                     className='main-center-modal-new'
                    contentClassName={`main-center-popup ${getWidthclass?getWidthclass:''}`}
                >
                    <Modal.Body>
                        <div className='d-flex justify-content-end'>
                            <img src="./images/icons/icon-close2.png" style={{height:"1.9531vh"}} className="mr-4 mt-4 cursor-point" onClick={this.handleClose} />

                        </div>
                        <div className='text-center'>
                            {children}
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </>
        );
    };
}

export default CenteredPopUp;
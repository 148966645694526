import React, { Component } from "react";
import $ from 'jquery';
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Redirect } from "react-router-dom";
import Pagination from '@material-ui/lab/Pagination';
import { getLeads, getLeadsCount, getMerchantProducts, merchantLeadDownload, setLeadTab,setShowSearched ,setSettlementclickItemId } from "../actions/user";
import { salesForceLogin } from "../actions/auth";
import XlsxPopulate, { Promise } from "xlsx-populate"
import { saveAs } from "file-saver"
import jsPDF from 'jspdf'
import "jspdf-autotable"
import Filter from "../common/filter";
import FilterMain from "./filter-main.component";
import UserProfilefilter from "./userProfilefilter.component";
import LeadDateFilter from "./LeadDateFilter";

import Sidebar from "../common/sidebar";
import {
    openBulkModel,
    updateProId,
    openLeadProfileModel,
    openFilterModel,
    openEmailModel,
    openRequestModel,
    openPreviewModel,
    openSuccessModel,
    openCreateLeadModel,
    openLeadApplicationModel,
    openLeadApplicationDetailsModel
} from "../actions/model"
import Topbar from "../common/topbar";
import  TopWizrbar  from "./TopWizrbar";
import CenteredPopUp from "../common/CenteredPopUp";
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextareaAutosize } from '@mui/base';
import TopBarWizrHelpNotification from "./TopBarWizrHelpNotification";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TablePagination from '@mui/material/TablePagination';
// import {
// MuiPickersUtilsProvider,
// KeyboardDatePicker
// } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
import { styled } from '@mui/material/styles';
import NoRecordsFound from "../common/NoRecordsFound.component";
import { fontSize } from "@material-ui/system";
import tableSort from "../common/tableSort"
import dateSort from "../common/dateSort";
import LeadStatusFilter from "./LeadStatusFilter";
import LeadActionableFilter from "./LeadCredFilter";
import LeadProductFilter from "./LeadProductFilter";
import LeadCredFilter from "./LeadCredFilter";


const StyledDatePicker = styled(DesktopDatePicker)(
    ({ theme }) => ({
        border:'1px solid black',
        borderRadius: '7px',
        width: '59%',
        '&:hover': { 
         },
    }),
);


const StyledTextArea = styled(TextareaAutosize)(
    ({ theme }) => ({
        width: '60%',
        border: '1px solid black',
        borderRadius: '7px',
    }),
);

class Leads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: "",
            page: 0,
            limit: 10,
            stage: 'Pre Approval',
            showtabledata:false,
            showFilterModal:false,
            showApprovedCenteredPopUp:false,
            showonholdCenteredPopUp:false,
            showrejectedCenteredPopUp:false,
            whiteRequestbtn:false,
            clearfilterValue:false,
            rowsPerPage:10,
            leadCountList:0,
            preCount:0,
            postCount:0,
            leadDateFilterData:[],
            LeadActionableFilterData:[],
            LeadStatusFilterData:[],
            LeadProductFilterData:[]
            // closeMainModal:false
        };
        this.formatData = this.formatData.bind(this);
    }

    

    componentDidMount() {        
        const { sfid,salesForceToken } = this.props   
        let { leads } = this.props;
        this.setState({
            leadDataState:leads.proData
        })
        let objData = `stage=${this.state.stage}`;
        //let objData = ``;
        this.getLeadsData(objData);
        let getProd = { merchant_id: this.props.user_id }
        this.props.dispatch(getMerchantProducts(getProd));
        let obj = { id: this.props.user_id, token: this.props.token }
        if(!salesForceToken && this.props.merchant_journey=="B2B2C"){
            this.props.dispatch(salesForceLogin(obj));

        }

        $('#sidebarToggleTop').click(function () {
            $("#accordionSidebar").toggleClass("open-close")
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.products != this.props.products) {
            this.setState({ products: this.props.products })
        }
        if(prevProps.leads != this.props.leads){
            console.log("sdknskrfnrkefnr");
            this.setState({leadDataState:this.props.leads.proData})
        }

    }

    formatData = (dateIs) => {
        let forDate = dateIs.split('/')
        forDate = forDate[1]+'/'+forDate[0]+'/'+forDate[2]
        let newDate = new Date(forDate);
        let months = ["Jan","Feb","Mar","April","May","Jun","Jul","Aug","Sept","Oct","Nov","Dec"];
        let year = newDate.getFullYear();
        let date = newDate.getDate();
        let month = newDate.getMonth();
        month = months[month];
        console.log(month, date, year);
        return month+' '+date+', '+year;
    }

    handleTabChange = (stage) => {
        this.setState({ stage: stage,page:0,limit:10 });
        this.props.dispatch(setLeadTab(stage))
        this.props.dispatch(setShowSearched(false))
         this.setState({page:0,limit:10})
        // let objData = `stage=${stage}`;
        let objData = `page=1&stage=${stage}&limit=10`;
        this.getLeadsData(objData);
    }
  
    getLeadsData = (getData) => {
        const { sfid } = this.props
        console.log(getData,"mainup")
        this.props.dispatch(getLeads(getData, sfid));
        this.props.dispatch(getLeadsCount(getData, sfid));
        this.props.dispatch(getLeads(getData, sfid)).then(res=>{
            if(this.state.stage=='Pre Approval'){
            this.setState({
                preCount:res.leadCount
            })    
            }
            if(this.state.stage=='Post Approval'){
                this.setState({
                    postCount:res.leadCount
                }) 
            }
                    })
    }

    openCreateLeads = () => {
        this.props.dispatch(openCreateLeadModel());
    }

    openLeads = (sfid,opp_id , id) => {
        console.log('pre approve :' ,opp_id);
        this.props.dispatch(setSettlementclickItemId(opp_id));
        this.props.dispatch(openLeadProfileModel(sfid , id));
        this.props.dispatch(updateProId(opp_id));
    }
   
     toggle1=(e,id)=>{
            if(e.target.className=="fa fa-angle-up"){
                e.target.style.display="none";
                e.target.className="fa fa-angle-down"
                e.target.style.display="block";
                document.getElementById(id).style.display="none"

         }else{
            e.target.style.display="none";
            e.target.className="fa fa-angle-up"
            e.target.style.display="block";
            document.getElementById(id).style.display="table-row";
          
        }
    
    }

    openLeadApplication = (sfid,opp_id , id) => {
        // console.log("sfid,opp_id ", id,sfid);
        this.props.dispatch(openLeadApplicationModel(sfid , id));
        this.props.dispatch(updateProId(opp_id));
    }
    openLeadApplicationDetails = (sfid,opp_id , id) => {
        console.log("sfid,opp_id", id,sfid);
        this.props.dispatch(openLeadApplicationDetailsModel(sfid , id));
        this.props.dispatch(updateProId(opp_id));
    }

    openBulkModel = () => {
        this.props.dispatch(openBulkModel());
    }

    openFilter = () => {
        this.props.dispatch(openFilterModel());
    }

    openRequest = () => {
        this.props.dispatch(openRequestModel());
    }

    openEmail = () => {
        localStorage.setItem("lead_stage",this.state.stage)
        this.props.dispatch(openEmailModel());
    }

    openPreview = () => {
        this.props.dispatch(openPreviewModel());
    }

    openSuccess = () => {
        this.props.dispatch(openSuccessModel());
    }

    handleChangePage = (event, value) => {
        const sfid = localStorage.getItem('sfid');
        let data = `page=${value}&stage=${this.state.stage}&limit=${this.state.limit}`;
        this.setState({ page: value });
        this.props.dispatch(getLeads(data, sfid)).then(res=>{
            if(res.status=="success"){
                const accordian1=document.getElementsByClassName("cc1")
                const arrowup=document.getElementsByClassName("fa-angle-up")
                 for(let i=0;i<accordian1.length;i++){
                    accordian1[i].style.display="none"
                }
              for (let index = 0; index < arrowup.length; index++) {
                const element = arrowup[index];
               setTimeout(() => {
                element.classList.remove("fa-angle-up")
                element.classList.add("fa-angle-down")
               }, 1000);

              }

            }
        });
    }

    

    handleChangelimitOfPage = (event) => {
        let perRowData = event.target.value;
        const sfid = localStorage.getItem('sfid');
        // let data =  `page=${perRowData}`;
        let data = `page=${this.state.page}&stage=${this.state.stage}&limit=${perRowData}`;
        this.setState({ limit: perRowData });
        this.props.dispatch(getLeads(data, sfid));
    }

    getSheetData = async (data, header) => {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        sheetData.unshift(header);
        return sheetData;
    }

    generatePDF = () => {
        const { sfid } = this.props
        let stage = this.state.stage;
        let obj = `stage=${stage}`;
        this.props.dispatch(merchantLeadDownload(obj, sfid)).then((response) => {
            if (response.responseCode !== undefined && response.responseCode === 400) {
            } else {
                const getData = response;
                this.generatePDFData(getData);
            }
        });
    }

    generateCsv = () => {
        const { sfid } = this.props
        let stage = this.state.stage;
        let obj = `stage=${stage}`;
        this.props.dispatch(merchantLeadDownload(obj, sfid)).then((response) => {
            if (response.responseCode !== undefined && response.responseCode === 400) {
            } else {
                const getData = response;
                this.saveAsExcel(getData);
            }
        });
    }

    generatePDFData = async getData => {
        const doc = new jsPDF();
        const tableColumn = ["#", "Application ID", "Application Details", "Product Name", "Order Value", "Status"];
        const tableRows = [];
        await Promise.all(getData.map((item, index) => {
            const leadData = [
                index + 1,
                item.transaction_application_id__c ? item.transaction_application_id__c : '-',
                item.name ? item.name : '-',
                item.product_name ? item.product_name : '-',
                item.product_mrp ? item.product_mrp : '-',
                item.status ? item.status : '-',
            ];
            tableRows.push(leadData);
        }));
        doc.autoTable(tableColumn, tableRows, { startY: 20 });
        const date = Date().split(" ");
        const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
        doc.text("Closed tickets within the last one month.", 14, 15);
        doc.save(`report_${dateStr}.pdf`);
    }

    saveAsExcel = async (getData) => {
        var data = [];
        console.log("getData", getData);
        await Promise.all(getData.map(async (item, index) => {
            data.push({
                s_no: index + 1,
                sfid: item.transaction_application_id__c ? item.transaction_application_id__c : '-',
                name: item.name ? item.name : '-',
                product_name: item.product_name ? item.product_name : '-',
                product_mrp: item.product_mrp ? item.product_mrp : '-',
                status: item.status ? item.status : '-',
            })
        }));
        let header = ["#", "Application ID", "Application Details", "Product Name", "Order Value", "Status"];
        console.log("data", data);
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = await this.getSheetData(data, header);
            const totalColumns = sheetData[0].length;
            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);
            sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                saveAs(res, "report.xlsx");
            });
        });
    }
     
    closeFilterMainModal=()=>{
        console.log("model here")
        this.setState({
            showFilterModal:false
        })

        
    }

    closeApprovedcenterPopUp = () => {
        this.setState({ showApprovedCenteredPopUp: false })
    }

    closeonholdcenterPopUp = () => {
        this.setState({ showonholdCenteredPopUp: false })
    }

    closerejectedcenterPopUp = () => {
        this.setState({ showrejectedCenteredPopUp: false })
    }


    SelectedModal=(ModalType)=>{
    if(ModalType=='approve'){
        this.setState({
            showApprovedCenteredPopUp:true
        })
    }
    if(ModalType=='hold'){
        this.setState({
            showonholdCenteredPopUp:true
        })
    }
    if(ModalType=='reject'){
        this.setState({
            showrejectedCenteredPopUp:true
        })
    }
    }

    handleClearfilter = () => {
        this.setState({clearfilterValue:!this.state.clearfilterValue})
      }
      handleReqbtn =(e)=>{
        this.setState({whiteRequestbtn:!this.state.whiteRequestbtn}) 
        e.stopPropagation()
      }
      handleChangePageNew = (event, newPage) => {
        // this.setState({page:updatedPage,newPage})
        // let {page} = this.state;
        // let updatedPage = page+1;
        if(this.props.globalseraching){
            this.setState({page:newPage})
            return;
          }
        const { sfid } = this.props
        let {limit,stage} = this.state;
        let objData = `page=${newPage+1}&stage=${stage}&limit=${limit}`;
        this.props.dispatch(getLeads(objData, sfid));
        this.setState({page:newPage})
      };

      handleChangeRowsPerPage = (event) => {
        if(this.props.globalseraching){
            this.setState({ limit: parseInt(event.target.value, 10),page:0,rowsPerPage:parseInt(event.target.value, 10)});
            return;
          }
        const { sfid } = this.props
        let {limit,stage} = this.state;
        let objData = `page=${1}&stage=${stage}&limit=${parseInt(event.target.value, 10)}`;
        this.props.dispatch(getLeads(objData, sfid));
        this.setState({ limit: parseInt(event.target.value, 10),page:0,rowsPerPage:parseInt(event.target.value, 10)});


        // this.setState({rowsPerPage:parseInt(event.target.value, 10),page:0})
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
      };
      sortBy = (givenArray, sortKey, order) => {
        const sortedData = tableSort(givenArray, sortKey, order);
        console.log('sorted data', sortedData)
        console.log(this.state.leadDataState,"234345")
        this.setState({ leadDataState: sortedData })
      }
      sortByDate =(givenArray, sortKey, order)=>{
        console.log("hi")
        const sortedbyDate=dateSort(givenArray, sortKey, order)
        console.log("hi333")
        this.setState({ leadDataState: sortedbyDate })
      }

    handleDateApply=(data)=>{
        console.log(data,'date')
       this.setState({
        leadDateFilterData:data
       })
       this.applyFilter()
    }
    handleActionableApply=(data)=>{
        console.log(data,'actionable')
        this.setState({
            LeadActionableFilterData:data
        })
        this.applyFilter()
     }
     handleProductApply=(data)=>{
        console.log(data,'product')
        this.setState({
            LeadProductFilterData:data
        })
        this.applyFilter()
     }
     handleStatusApply=(data)=>{
        console.log(data,'status')
        this.setState({
            LeadStatusFilterData:data
        })
        this.applyFilter()
     }
     
     applyFilter = () => {
        console.log('in apply')
       // const { productApi, actionApi, statusApi, time } = this.state;
        const { leadDateFilterData, LeadStatusFilterData, LeadActionableFilterData, LeadProductFilterData } = this.state;
        const { leadTab, user_id } = this.props;
        const filterArray = []
        const searchOpt = [...LeadProductFilterData, ...LeadStatusFilterData, ...LeadActionableFilterData,...leadDateFilterData];
        if (LeadProductFilterData.length > 0) { filterArray.push("Product"); }
        if (LeadActionableFilterData.length > 0) { filterArray.push("Action"); }
        if (LeadStatusFilterData.length > 0) { filterArray.push("Status"); }
        if (leadDateFilterData.length>0) { 
            // if(leadDateFilterData.checked){
            //     filterArray.push("Date");
            // }
            
            filterArray.push("Date");
             searchOpt.push(leadDateFilterData); }
        console.log(filterArray, searchOpt, leadTab)
        const dataObj = {
            "merchant_id": user_id,
            "filter_type": filterArray,
            "search_keyword": searchOpt,
            "section": leadTab
        }
        console.log(dataObj,"343e")
        // this.props.dispatch(getfilterLeadData(dataObj)).then(response => {
        //     this.closeModel();
        // })
    }

    render() {
        const { user_id, leadsCount,show_searched,globalSearch,activeWizrTab,globalseraching,searchedKeyword } = this.props;
        let { leads } = this.props;
        let {leadDataState,page,limit}=this.state;
       console.log(leads,leadsCount,leadDataState,"222we")
       console.log(this.props.leadTab,"355")
let static_sfid= "001C30000073CLaIAM"
let static_id="8193"
let static_opp_id="006C3000004Lb8jIAC"
let static_stage_title="Application Pending"

        console.log('leadssss', leads)
        let { leadCount } = leads;
        if (!user_id) {
            return <Redirect to="/login" />
        }
        // const totalPages = Math.ceil(leadCount / Number(this.state.limit));

        let showPageination = false;
        let totalPages = 1;
        if(globalseraching && globalSearch && globalSearch.length > 0){
          showPageination = true;
          totalPages =  globalSearch ? Math.ceil(globalSearch.length / Number(this.state.limit)) : 1;
    
        }
        if(!globalseraching && leadDataState && leadDataState.length > 0){
          showPageination = true;
        // totalPages =  products.all_product_count ? Math.ceil(products.all_product_count / Number(this.state.limit)) : 1;
        totalPages = leadCount ? Math.ceil(leadCount / Number(this.state.limit)):1;
        }



        let searched={}
        leads=show_searched?globalSearch:leads
        console.log('show_searched',show_searched)
        console.log('global search', globalSearch,leads)
        if(show_searched){
            searched['proData']=leads;
            leads=searched            
        }
        
        return (
            <>
                <Helmet>
                    <title>Eduvanz - Leads</title>
                </Helmet>
                <LeadCredFilter show={this.state.showFilterModal} close={this.closeFilterMainModal} />
                {/* <FilterMain show={this.state.showFilterModal} close={this.closeFilterMainModal} handleClearfilter={this.handleClearfilter} > */}
                    {/* {<UserProfilefilter clearfilterValue={this.state.clearfilterValue}/>} */}
                    {/* {<LeadDateFilter   clearfilterValue={this.state.clearfilterValue} handleDateChange={this.handleDateApply} />}
                {<LeadStatusFilter clearfilterValue={this.state.clearfilterValue} handleStatusChange={this.handleStatusApply} /> }
                {<LeadActionableFilter clearfilterValue={this.state.clearfilterValue} handleActionableChange={this.handleActionableApply} />}
                {<LeadProductFilter clearfilterValue={this.state.clearfilterValue}  handleProductChange={this.handleProductApply}/>} */}
                
                {/* </FilterMain> */}
                <div id="wrapper">
                    <Sidebar />
                    <div id="content-wrapper" className="d-flex flex-column ">
                        <div id="content">
                            {/* <Topbar
                                dispatch={this.props.dispatch}
                                title={"Leads"}
                                createLead={true}
                                stage={this.state.stage}
                            /> */}
                            {/* End of Topbar */}
                            {/* Begin Page Content */}
                            <TopBarWizrHelpNotification/>

                            <TopWizrbar showCreateCourse={true} showbulkupload={true}/>
                            <div className="container-fluid">
                                <div className="row flex-lg-row flex-column-reverse align-items-center mainHeadersTab">
                                    <div className="col-md-8">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <a
                                                    className="nav-item nav-link active"
                                                    id="nav-application-tab"
                                                    data-toggle="tab"
                                                    href="#nav-pre-approval"
                                                    role="tab"
                                                    aria-controls="nav-pre-approval"
                                                    aria-selected="true"
                                                    onClick={() => this.handleTabChange('Pre Approval')}
                                                >
                                                   PreApproval ({this.state.preCount})
                                                </a>
                                                <a
                                                    className="nav-item nav-link nav-post"
                                                    id="nav-documentation-tab"
                                                    data-toggle="tab"
                                                    href="#nav-post-approval"
                                                    role="tab"
                                                    aria-controls="nav-post-approval"
                                                    aria-selected="false"
                                                    onClick={() => this.handleTabChange('Post Approval')} 
                                                >
                                                     Post Approval ({this.state.postCount}) 
                                                </a>
                                               
                                            </div>
                                        </nav>
                                    </div>
                                    <div className="col-md-4">
                                        <ul className="list-group list-group-horizontal align-items-center justify-content-end">
                                            <li className="list-group-item">
                                                {/* <span className="nav_icons nav_sorting" onClick={this.openBulkModel} >
                                                    <img src="images/sorting.svg" alt="Sort" />
                                                </span> */}
                                            
                                            </li>
                                            <diV className="filterSearch">
                                                 <Topbar
                                dispatch={this.props.dispatch}
                                title={"Leads"}
                                createLead={true}
                                stage={this.state.stage}
                            />
                                            <li className="list-group-item"
                                                onClick={()=>this.setState(
                                                    {
                                                        showFilterModal:!this.state.showFilterModal
                                                    }
                                                ) }
                                            >
                                             
                                                <button className="nav_icons nav_filter filtericon-new-btn" >
                                                    <img src="img/filterIcon.svg" alt="Filter" />Filters
                                                </button>
                                                
                                            </li>
                                            </diV>
                                            {/* <li className="list-group-item" id="enableDownloads">
                                                <div className="dropdown">
                                                    <button className="" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="nav_icons nav_download">
                                                            <img src="img/icon_Download.svg" alt="Download" />
                                                        </span>
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-leads shadow" aria-labelledby="dropdownMenuButton">
                                                        <div className="d-flex">
                                                            <button type="button" onClick={this.generateCsv} className="doc-dowlload-btn">
                                                                <img src="images/icons/xls.png" alt="Download" className="img-fluid" />
                                                            </button>
                                                            <button type="button" onClick={this.generatePDF} className="doc-dowlload-btn">
                                                                <img src="images/icons/pdf.png" alt="Download" className="img-fluid" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </li> */}


                                            {/* <li className="list-group-item" onClick={this.openEmail}> */}
                                                {/* <span className="nav_icons nav_download" style={{ "fontSize": "20px" }}> */}
                                                    {/* <img src="images/icons/icon-email.png" alt="Email" /> */}
                                                    {/* <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                                </span> */}
                                            {/* </li> */}
                                        </ul>
                                    </div>
                                </div>

                                <div className="sorting_values">
                                    <ul>
                                        <li>
                                            <input
                                                type="checkbox"
                                                defaultValue="recentlyUpdated"
                                                name="radio"
                                                id="radio1"
                                            />
                                            <label htmlFor="radio1">Recently Updated</label>
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                defaultValue="loanAmountHightoLow"
                                                name="radio"
                                                id="radio2"
                                            />
                                            <label htmlFor="radio2">Loan Amount: High to Low</label>
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                defaultValue="loanAmountLowtoHigh"
                                                name="radio"
                                                id="radio3"
                                            />
                                            <label htmlFor="radio3">Loan Amount: Low to High</label>
                                        </li>
                                    </ul>
                                </div>


                                <div className="row">
                                    <div className="col-md-12 pt-2">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="tab-content" id="nav-tabContent">
                                                    <div
                                                        className="tab-pane fade show active"
                                                        id="nav-pre-approval"
                                                        role="tabpanel"
                                                        aria-labelledby="nav-application-tab"
                                                    >
                                                        <div className="table-responsive">
                                                            <table
                                                                className="table tableStyle"
                                                                // id="dataTable"
                                                                width="100%"
                                                                cellSpacing={0}
                                                            >
                                                                <thead>    
                                                                    <tr>
                                                                        <th className="appHead-headinghash">#</th>
                                                                        <th>
                                                                            {/* Application ID  */}
                                                                            <div className="d-flex align-items-center appHead-heading ">Application ID
                                                                                <div className="d-flex flex-column">
                                                                                    <button className="up" onClick={() => this.sortBy(leadDataState, 'transaction_application_id__c', 1)} ></button>
                                                                                    <button className="down" onClick={() => this.sortBy(leadDataState, 'transaction_application_id__c',-1)}></button>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th className="appHead-heading">
                                                                        Applicant Details
                                                                        </th>
                                                                        <th>
                                                                        <div className="d-flex align-items-center appHead-heading">Product Name
                                                                                {/* <div className="d-flex flex-column">
                                                                                    <button className="up"></button>
                                                                                    <button className="down"></button>
                                                                              </div> */}
                                                                              </div>
                                                                              </th>
                                                                              <th className="d-flex align-items-center appHead-heading">
                                                                              Order Value
                                                                              <div className="d-flex flex-column">
                                                                                    <button className="up" onClick={() => this.sortBy(leadDataState, 'order_value', 1)}></button>
                                                                                    <button className="down" onClick={() => this.sortBy(leadDataState, 'order_value',-1)} ></button>
                                                                                </div>
                                                                        </th>
                                                                    <th className="appHead-heading">Status</th>
                                                                     {/* <th className="appHead-heading">Qualification</th> */}
                                                                       
                                                                            {/* Order Value */}
                                                                           
                                                                                {/* <div className="d-flex flex-column">
                                                                                    <button className="up"></button>
                                                                                    <button className="down"></button>
                                                                                </div> */}
                                                                          
                                                                       
                                                                        {/* <th className="appHead-heading">Actionable</th> */}
                                                                        <th className="appHead-heading">Edit Info</th>
                                                                    </tr>
                                                                </thead>
                                                                {/* { !this.state.showtabledata ? */}
                                                                <tbody>
                                                              {
                                                               globalseraching == true  ? 
                                                               <>
                                                               {  globalSearch && globalSearch.length ?
                                                                  
                                                                    // globalSearch.slice(page*limit,page*limit+limit).map((item, index) =>  true
                                                                    // <span>fdfd</span>
                                                                    globalSearch.slice(page*limit,page*limit+limit).map((item, index) => (
                                                                        <tr className="shown trhovercred" key={index} >
                                                                               
                                                                        <td className="appIDnum">{index + 1} </td>
                                                                        <td onClick={() => item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)} 
                                                                            data-toggle="modal"
                                                                            data-target="#myModa20"
                                                                        > 
                                                                            {/* while clicking open profile for approval */}
                                                                            {/* <b href={void (0)} onClick={() => this.openLeads(item.sfid)}>{item.sfid}</b> */}
                                                                            <div className="d-flex" >
                                                                                <b className="underline d-block" 
                                                                                 style={item.stage_title=== "Lead Dropped" ?  { cursor: 'not-allowed' }:{ cursor: 'pointer' }}
                                                                                >
                                                                                    <a href={void (0)} > <b className="appIDLeads">{item.transaction_application_id__c ? item.transaction_application_id__c : item.limit_application_ids__c ? item.limit_application_ids__c : item.customer_id__c }</b></a>
                                                                                </b>
                                                                                {/* <button className="ml-3"><i className="fa fa-angle-down" style={{ "fontSize": "20px" }} aria-hidden="true" onClick={(e)=>this.toggle1(e,item.opp_id)}></i></button> */}

                                                                            </div>

                                                                            {/* <b className="d-block">{item.sfid?item.sfid:'-'}</b> */}
                                                                            <span className="mt-1 date appIDDates">{item.created_at ? this.formatData(item.created_at) : '-'}</span>
                                                                        </td>
                                                                        <td
                                                                            data-toggle="modal"
                                                                            data-target="#myModal8"
                                                                            onClick={() => item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}
                                                                        >
                                                                            <div><p className="applicantName mb-1">{item.name ? item.name : "-"}</p> <span className="ai_d applicantNam">{item.mobile ? item.mobile : '-'}</span></div>
                                                                            
                                                                        </td>
                                                                        <td className="appIDCourseName" onClick={() => item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}>{item.product_name ? item.product_name : '-'}</td>
                                                                        {
                                                                            !show_searched ? 
                                                                            <td className="appIDProfile" onClick={() => item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}>₹ {item.order_value ? item.order_value.toLocaleString('en-IN') : '0'}</td>
                                                                            :
                                                                            <td className="appIDProfile" onClick={() => item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}>₹ {item.amount ? item.amount.toLocaleString('en-IN') : '0'}</td>
                                                                        }
                                                                        {/* <td>₹ {item.product_mrp ? item.product_mrp : '0'}</td> */}

                                                                        <td className="has_btn" onClick={() => item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}>
                                                                            <b className="appIDCourseName">{item.stage_title ? item.stage_title : '-'}</b>
                                                                            {/* <span className="view_status active">&nbsp;</span> */}
                                                                        </td>
                                                                        <td className="has_btn">
                                                                             {" "} 
                                                                            {/* Send Agreement Link{" "} */}
                                                                            {/* <span
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => this.openLeadApplication(item.sfid, item.opp_id)}
                                                                                className="actionable"
                                                                            >&nbsp;</span> */}
                                                                             {/* <span /> */}
                                                                            {/* <img
                                                                                style={item.stage_title=== "Lead Dropped" ?  { cursor: 'not-allowed' }:{ cursor: 'pointer' }}
                                                                                onClick={() => item.stage_title !== "Lead Dropped" &&  this.openLeadApplication(item.sfid, item.opp_id, item.id)}
                                                                                src="images/arrow-left.svg" alt="help" /> */}
                                                                       <i className="fa fa-angle-right float-right pt-2  cursor-point size20" style={item.stage_title=== "Lead Dropped" ?  { cursor: 'not-allowed',fontSize:'20px' }:{ cursor: 'pointer',fontSize:'20px' } } data-toggle="modal" data-target="#myModaxl8"  onClick={() => item.stage_title !== "Lead Dropped" && this.openLeadApplicationDetails(item.sfid, item.opp_id, item.id)}  ></i>
                                                                        </td>
                                                                    </tr>

                                                                    )
                                                                    )
                                                                    :
                                                                    <tr className="customodd">
                      {/* <td valign="top" colspan="7" className="dataTables_empty text-center">No data available in table</td> */}
                         <td colSpan="10"><NoRecordsFound message={searchedKeyword?searchedKeyword:''} show_searched={true} /></td>
                    </tr>
                                                                    }
                                                               </>
                                                              
                                                             
                                                              : 
                                                              <>
                                                             { leadDataState?.length > 0 ?
                                                               
                                                               leadDataState.map((item,index) => (
                                                                <tr className="shown trhovercred" key={index}  >
                                                                               
                                                                                    <td className="appIDnum">{index + 1} </td>
                                                                                    <td  
                                                                                    onClick={() => item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}
                                                                                        data-toggle="modal"
                                                                                        data-target="#myModa20"
                                                                                        className="cursor-point"
                                                                                    > 
                                                                                        {/* while clicking open profile for approval */}
                                                                                        {/* <b href={void (0)} onClick={() => this.openLeads(item.sfid)}>{item.sfid}</b> */}
                                                                                        <div className="d-flex cursor-point" >
                                                                                            <b className="underline d-block" 
                                                                                             style={item.stage_title=== "Lead Dropped" ?  { cursor: 'not-allowed' }:{ cursor: 'pointer' }}
                                                                                            >
                                                                                                <a href={void (0)} > <b className="appIDLeads">{item.transaction_application_id__c ? item.transaction_application_id__c : item.limit_application_ids__c ? item.limit_application_ids__c : item.customer_id__c }</b></a>
                                                                                            </b>
                                                                                            {/* <button className="ml-3"><i className="fa fa-angle-down" style={{ "fontSize": "20px" }} aria-hidden="true" onClick={(e)=>this.toggle1(e,item.opp_id)}></i></button> */}

                                                                                        </div>

                                                                                        {/* <b className="d-block">{item.sfid?item.sfid:'-'}</b> */}
                                                                                        <span className="mt-1 date appIDDates">{item.created_at ? this.formatData(item.created_at) : '-'}</span>
                                                                                    </td>
                                                                                    <td
                                                                                        data-toggle="modal"
                                                                                        data-target="#myModal8"
                                                                                        className="cursor-point"
                                                                                        onClick={() => item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}
                                                                                    >
                                                                                        <div><p className="applicantName mb-1">{item.name ? item.name : "-"}</p> <span className="ai_d applicantNam">{item.mobile ? item.mobile : '-'}</span></div>
                                                                                        {/* <div><p className="applicantName mb-1">Sneha Sharma</p> <span className="ai_d applicantNam">9995 888 777</span></div> */}
                                                                                    </td>
                                                                                    <td className="appIDCourseName cursor-point" onClick={() => item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}>{item.product_name ? item.product_name : '-'}</td>
                                                                                    {
                                                                                        !show_searched ? 
                                                                                        <td className="appIDProfile cursor-point" onClick={() => item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}>₹ {item.order_value ? item.order_value.toLocaleString('en-IN') : '0'}</td>
                                                                                        :
                                                                                        <td className="appIDProfile cursor-point" onClick={() => item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}>₹ {item.amount ? item.amount.toLocaleString('en-IN') : '0'}</td>
                                                                                    }
                                                                                    {/* <td>₹ {item.product_mrp ? item.product_mrp : '0'}</td> */}

                                                                                    <td className="has_btn cursor-point" onClick={() => item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}>
                                                                                        <b className="appIDCourseName cursor-point">{item.stage_title ? item.stage_title : '-'}</b>
                                                                                        {/* <span className="view_status active">&nbsp;</span> */}
                                                                                    </td>
                                                                                    <td className="has_btn">
                                                                                         {" "} 
                                                                                        {/* Send Agreement Link{" "} */}
                                                                                        {/* <span
                                                                                            style={{ cursor: 'pointer' }}
                                                                                            onClick={() => this.openLeadApplication(item.sfid, item.opp_id)}
                                                                                            className="actionable"
                                                                                        >&nbsp;</span> */}
                                                                                         {/* <span /> */}
                                                                                        {/* <img
                                                                                            style={item.stage_title=== "Lead Dropped" ?  { cursor: 'not-allowed' }:{ cursor: 'pointer' }}
                                                                                            onClick={() => item.stage_title !== "Lead Dropped" &&  this.openLeadApplication(item.sfid, item.opp_id, item.id)}
                                                                                            src="images/arrow-left.svg" alt="help" /> */}
                                                                                   <i className="fa fa-angle-right float-right pt-2  cursor-point size20" style={item.stage_title=== "Lead Dropped" ?  { cursor: 'not-allowed',fontSize:'20px' }:{ cursor: 'pointer',fontSize:'20px' } } data-toggle="modal" data-target="#myModaxl8"  onClick={() => item.stage_title !== "Lead Dropped" && this.openLeadApplicationDetails(item.sfid, item.opp_id, item.id)}  ></i>
                                                                                    </td>
                                                                                </tr>

                                                               ))
                                                                  
                                                                           
                                                                            :
                                                                            <td colSpan="10"><NoRecordsFound message={'No Data to show '} show_searched={false} /></td>
                                                                            }
                                                                
                                                                            </>
                                                                            }  

  
                                                                   
                                                                   
                                                                   
                                                                </tbody>
                                                                 {/* :<tbody> */}
                                                                    {/* <td colSpan="10"><NoRecordsFound message={'No Data to show '} /></td></tbody> */}
                                                            </table>
                                                         
                                                            <div className="d-flex align-items-center justify-content-lg-end justify-content-center mb-4">
                                  {/* <div className="d-flex align-items-center row_per_page mr-lg-5 mr-4">
                                    <p>Row per page</p>
                                    <select page={this.state.page} onChange={this.handleChangelimitOfPage}>
                                      <option value={10}>10</option>
                                      <option value={20}>20</option>
                                      <option value={30}>30</option>
                                    </select>
                                  </div> */}
                                  <div className="d-flex align-items-center pages">
                                    {/* <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} /> */}
                                  { showPageination &&  <TablePagination
                                      component="div"
                                      count={globalseraching ? globalSearch.length : this.state.preCount}
                                      page={this.state.page}
                                      onPageChange={this.handleChangePageNew}
                                      rowsPerPage={this.state.limit}
                                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                                      rowsPerPageOptions={[10, 20, 50,100]}
                                    //   className="leadtablepagination"
                                      // labelDisplayedRows={({ page }) => {
                                      //   return `Page: ${this.state.page} of 200 `;
                                      // }}
                                      // labelDisplayedRows={<span>Rows:</span>}
                                      labelDisplayedRows={({ page }) => {

                                        return `Page: ${this.state.page+1} of ${totalPages} `;

                                      }}
                                    />}
                                  </div>

                                </div>
                                                        </div>
                                                        {/* <div style={{ display: 'block', padding: 30, marginLeft: "76%" }}>
                                                            <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} />
                                                        </div> */}
                                                    </div>

                                                    {/* post-approval */}
                                                    <div
                                                        className="tab-pane fade"
                                                        id="nav-post-approval"
                                                        role="tabpanel"
                                                        aria-labelledby="nav-documentation-tab"
                                                    >
                                                        <div className="table-responsive">
                                                            <table
                                                                className="table tableStyle"
                                                                // id="dataTable"
                                                                width="100%"
                                                                cellSpacing={0}
                                                            >
                                                                <thead>
                                                                <tr>
                                                                        <th className="appHead-headinghash">#</th>
                                                                        <th>
                                                                            {/* Application ID  */}
                                                                            <div className="d-flex align-items-center appHead-heading ">Application ID
                                                                                <div className="d-flex flex-column">
                                                                                    <button className="up" onClick={() => this.sortBy(leadDataState, 'transaction_application_id__c', 1)}></button>
                                                                                    <button className="down" onClick={() => this.sortBy(leadDataState, 'transaction_application_id__c',-1)} ></button>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th className="appHead-heading">
                                                                        Applicant Details
                                                                        </th>
                                                                        <th>
                                                                        <div className="d-flex align-items-center appHead-heading">Product Name
                                                                                {/* <div className="d-flex flex-column">
                                                                                    <button className="up"></button>
                                                                                    <button className="down"></button>
                                                                              </div> */}
                                                                              </div>
                                                                              </th>
                                                                              <th className="d-flex align-items-center appHead-heading">
                                                                              Order Value
                                                                              <div className="d-flex flex-column">
                                                                                    <button className="up" onClick={() => this.sortBy(leadDataState, 'order_value', 1)}></button>
                                                                                    <button className="down" onClick={() => this.sortBy(leadDataState, 'order_value',-1)} ></button>
                                                                                </div>
                                                                        </th>
                                                                    <th className="appHead-heading">Status</th>
                                                                     {/* <th className="appHead-heading">Qualification</th> */}
                                                                       
                                                                            {/* Order Value */}
                                                                           
                                                                                {/* <div className="d-flex flex-column">
                                                                                    <button className="up"></button>
                                                                                    <button className="down"></button>
                                                                                </div> */}
                                                                          
                                                                       
                                                                        {/* <th className="appHead-heading">Actionable</th> */}
                                                                        <th className="appHead-heading">Edit Info</th>
                                                                    </tr>
                                                                </thead>

                                                                {/**Approved */}
                                                                {leadDataState?.length > 0?
                                                                <tbody>



                                                               
{
                                                               globalseraching == true  ? 
                                                               <>
                                                               {  globalSearch && globalSearch.length ?
                                                                  
                                                                    // globalSearch.slice(page*limit,page*limit+limit).map((item, index) =>  true
                                                                    // <span>fdfd</span>
                                                                    globalSearch.slice(page*limit,page*limit+limit).map((item, index) => (
                                                                        <tr className="shown trhovercred" key={index} 
                                                                        // onClick={() =>item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}
                                                                        >
                                                                        <td className="appIDnum">{index + 1}</td>
                                                                        <td  onClick={() =>item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}>
                                                                            <div className="d-flex">

                                                                                <b data-toggle="modal" 
                                                                                style={item.stage_title === "Lead Dropped" ?{ cursor: 'not-allowed' }:{ cursor: 'pointer' } }
                                                                                    data-target="#myModa20" className="d-block">
                                                                                    <a href={void (0)} ><b className="appIDLeads">{item.transaction_application_id__c ? item.transaction_application_id__c : item.limit_application_ids__c ? item.limit_application_ids__c : item.customer_id__c }</b></a>

                                                                                </b>
                                                                                {/* <button className="ml-3"><i className="fa fa-angle-down" style={{ "fontSize": "20px" }} aria-hidden="true"></i></button> */}
                                                                            </div>
                                                                            <span className="date appIDDates">{item.created_at ? this.formatData(item.created_at) : '-'}</span>

                                                                        </td>
                                                                        <td
                                                                            data-toggle="modal"
                                                                            data-target="#myModal8"
                                                                            className="cursor-point"
                                                                            onClick={() =>item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}
                                                                        >
                                                                            {/* <p><span className="d-block appIDCourseName">{item.name ? item.name : "-"}<br/>{item.mobile ? item.mobile : '-'}</span></p> */}
                                                                            <div><p className="applicantName mb-1">{item.name ? item.name : "-"}</p> <span className="ai_d applicantNam">{item.mobile ? item.mobile : '-'}</span></div>
                                                                        </td >
                                                                        <td className="appIDCourseName cursor-point">{item.product_name ? item.product_name : '-'}</td>
                                                                        {
                                                                            !show_searched ? 
                                                                            <td className="appIDProfile cursor-point">₹ {item.order_value ? item.order_value.toLocaleString('en-IN') : '0'}</td>
                                                                            :
                                                                            <td className="appIDProfile cursor-point">₹ {item.amount ? item.amount.toLocaleString('en-IN') : '0'}</td>
                                                                        }
                                                                        {/* <td className="appIDProfile">{item.created_at}</td> */}
                                                                        <td className="has_btn cursor-point">
                                                                            <b className="appIDCourseName cursor-point">{item.stage_title ? item.stage_title : '-'}</b>
                                                                            {/* <span className="view_status active">&nbsp;</span> */}
                                                                        </td>

                                                                        <td className="has_btn">
                                                                            {" "}
                                                                            {/* Upload documents{" "} */}
                                                                            {/* <span
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => this.openLeadApplication(item.sfid, item.opp_id)}
                                                                                className="actionable"
                                                                            >&nbsp;</span>
                                                                            <span /> */}
                                                                            {/* <img
                                                                                style={item.stage_title=== "Lead Dropped" ?{ cursor: 'not-allowed' }:{ cursor: 'pointer' } }
                                                                                onClick={() => item.stage_title !== "Lead Dropped" && this.openLeadApplication(item.sfid, item.opp_id, item.id)}
                                                                                src="images/arrow-left.svg" alt="help" /> */}
                                                                             <i className="fa fa-angle-right float-right pt-2  cursor-point size20" style={item.stage_title=== "Lead Dropped" ?  { cursor: 'not-allowed',fontSize:'20px' }:{ cursor: 'pointer',fontSize:'20px' } } onClick={() => static_stage_title === "Lead Dropped" && this.openLeadApplicationDetails(item.sfid, item.opp_id, item.id)}  data-toggle="modal" data-target="#myModaxl8"   ></i>    
                                                                        </td>
                                                                    </tr>

                                                                    )
                                                                    )
                                                                    :
                                                                    <tr className="customodd">
                      {/* <td valign="top" colspan="7" className="dataTables_empty text-center">No data available in table</td> */}
                         <td colSpan="10"><NoRecordsFound message={searchedKeyword?searchedKeyword:''} show_searched={true} /></td>
                    </tr>
                                                                    }
                                                               </>
                                                              
                                                             
                                                              : 
                                                              <>
                                                             { leadDataState?.length > 0 ?
                                                               
                                                               leadDataState.map((item,index) => (
                                                                <tr className="shown trhovercred" key={index} 
                                                                // onClick={() =>item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}
                                                                >
                                                                                    <td className="appIDnum">{index + 1}</td>
                                                                                    <td onClick={() =>item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}>
                                                                                        <div className="d-flex">

                                                                                            <b data-toggle="modal" 
                                                                                            style={item.stage_title === "Lead Dropped" ?{ cursor: 'not-allowed' }:{ cursor: 'pointer' } }
                                                                                                data-target="#myModa20" className="d-block">
                                                                                                <a href={void (0)} ><b className="appIDLeads">{item.transaction_application_id__c ? item.transaction_application_id__c : item.limit_application_ids__c ? item.limit_application_ids__c : item.customer_id__c }</b></a>

                                                                                            </b>
                                                                                            {/* <button className="ml-3"><i className="fa fa-angle-down" style={{ "fontSize": "20px" }} aria-hidden="true"></i></button> */}
                                                                                        </div>
                                                                                        <span className="date appIDDates">{item.created_at ? this.formatData(item.created_at) : '-'}</span>

                                                                                    </td>
                                                                                    <td
                                                                                        data-toggle="modal"
                                                                                        data-target="#myModal8"
                                                                                        className="cursor-point"
                                                                                        onClick={() =>item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}
                                                                                    >
                                                                                       <div><p className="applicantName mb-1">{item.name ? item.name : "-"}</p> <span className="ai_d applicantNam">{item.mobile ? item.mobile : '-'}</span></div>
                                                                                        
                                                                                    </td >
                                                                                    <td className="appIDCourseName cursor-point" onClick={() =>item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}>{item.product_name ? item.product_name : '-'}</td>
                                                                                    {
                                                                                        !show_searched ? 
                                                                                        <td className="appIDProfile cursor-point">₹ {item.order_value ? item.order_value.toLocaleString('en-IN') : '0'}</td>
                                                                                        :
                                                                                        <td className="appIDProfile cursor-point">₹ {item.amount ? item.amount.toLocaleString('en-IN') : '0'}</td>
                                                                                    }
                                                                                    {/* <td className="appIDProfile">{item.created_at}</td> */}
                                                                                    <td className="has_btn cursor-point" onClick={() =>item.stage_title !== "Lead Dropped" && this.openLeads(item.sfid,item.opp_id,item.id)}>
                                                                                        <b className="appIDCourseName cursor-point">{item.stage_title ? item.stage_title : '-'}</b>
                                                                                        {/* <span className="view_status active">&nbsp;</span> */}
                                                                                    </td>

                                                                                    <td className="has_btn">
                                                                                        {" "}
                                                                                        {/* Upload documents{" "} */}
                                                                                        {/* <span
                                                                                            style={{ cursor: 'pointer' }}
                                                                                            onClick={() => this.openLeadApplication(item.sfid, item.opp_id)}
                                                                                            className="actionable"
                                                                                        >&nbsp;</span>
                                                                                        <span /> */}
                                                                                        {/* <img
                                                                                            style={item.stage_title=== "Lead Dropped" ?{ cursor: 'not-allowed' }:{ cursor: 'pointer' } }
                                                                                            onClick={() => item.stage_title !== "Lead Dropped" && this.openLeadApplication(item.sfid, item.opp_id, item.id)}
                                                                                            src="images/arrow-left.svg" alt="help" /> */}
                                                                                         <i className="fa fa-angle-right float-right pt-2  cursor-point size20" style={item.stage_title=== "Lead Dropped" ?  { cursor: 'not-allowed',fontSize:'20px' }:{ cursor: 'pointer',fontSize:'20px' } }  data-toggle="modal" data-target="#myModaxl8" onClick={() => item.stage_title !== "Lead Dropped" && this.openLeadApplicationDetails(item.sfid, item.opp_id, item.id)}  ></i>    
                                                                                    </td>
                                                                                </tr>

                                                               ))
                                                                  
                                                                           
                                                                            :
                                                                            <td colSpan="10"><NoRecordsFound message={'No Data to show '} show_searched={false} /></td>
                                                                            }
                                                                
                                                                            </>
                                                                            }  





                                                            

                                                          </tbody>        
                                                        :<tbody>        
                                                                    <td colSpan="10"><NoRecordsFound message={'No Data to show '} /></td></tbody>} 
                                                            </table>
                                                            <div className="d-flex align-items-center justify-content-lg-end justify-content-center mb-4">
                                  {/* <div className="d-flex align-items-center row_per_page mr-lg-5 mr-4">
                                    <p>Row per page</p>
                                    <select page={this.state.page} onChange={this.handleChangelimitOfPage}>
                                      <option value={10}>10</option>
                                      <option value={20}>20</option>
                                      <option value={30}>30</option>
                                    </select>
                                  </div> */}
                                  <div className="d-flex align-items-center pages">
                                    {/* <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} /> */}
                                  { showPageination && <TablePagination
                                      component="div"
                                      count={this.state.postCount}
                                      page={this.state.page}
                                      onPageChange={this.handleChangePageNew}
                                      rowsPerPage={this.state.limit}
                                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                                      rowsPerPageOptions={[10, 20, 50,100]}
                                    //   className="leadtablepagination"
                                      // labelDisplayedRows={({ page }) => {
                                      //   return `Page: ${this.state.page} of 200 `;
                                      // }}
                                      // labelDisplayedRows={<span>Rows:</span>}
                                      labelDisplayedRows={({ page }) => {

                                        return `Page: ${this.state.page+1} of ${totalPages} `;

                                      }}
                                    />}
                                  </div>

                                </div>
                                                        </div>
                                                        {/* <div style={{ display: 'block', padding: 30, marginLeft: "76%" }}>
                                                            <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} />
                                                        </div> */}

                                                    </div>
                                                   
                                                   

                                                    
                                                   
                                                    
                                                    {/* end */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Filter />

                <CenteredPopUp show={this.state.showApprovedCenteredPopUp} closePopUp={this.closeApprovedcenterPopUp} getWidthclass="w432" >
                  <div className='LeadApprovedModal'>
                  <h1 className='LeadApprovedModalTitle'>Approve Lead*</h1>
                  <p className="LeadApprovedModalSubTitle">Please select the details before approving<br/> the lead</p>
                  {/* <div class="dropdown">
                  {
                        <button type="button" class="btn d-flex justify-content-between w-100 align-items-center btn-primary dropdown-toggle" data-toggle="dropdown">
                            {this.state.selectedqueryType}
                        </button>  */}


                         <label className="ApproveLeadlabel" for="QueryType">Batch Start Date</label>
                         <LocalizationProvider fullWidth  dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DesktopDatePicker']}>
                             <StyledDatePicker
                             inputVariant="outlined"
                               label="" /> 
                        </DemoContainer> 
                        </LocalizationProvider>
            
                        
                        <label className="ApproveLeadlabel" for="QueryType">Last enrollment date</label>
                        <LocalizationProvider fullWidth  dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DesktopDatePicker']}>
                             <StyledDatePicker
                             inputVariant="outlined"
                               label="" /> 
                        </DemoContainer> 
                        </LocalizationProvider>
                            <button className="ApproveSubmit" type="button">Submit</button>     
                            </div>
                                                  
                        </CenteredPopUp>



                <CenteredPopUp show={this.state.showonholdCenteredPopUp} closePopUp={this.closeonholdcenterPopUp} getWidthclass="w432" >
                  <div className='LeadApprovedModal'>
                  <h1 className='LeadApprovedModalTitle'>On hold Leads*</h1>
                  <p className="LeadApprovedModalSubTitle">Please select the details before approving<br/> the lead</p>
                  {/* <div class="dropdown">
                  {
                        <button type="button" class="btn d-flex justify-content-between w-100 align-items-center btn-primary dropdown-toggle" data-toggle="dropdown">
                            {this.state.selectedqueryType}
                        </button>  */}
                         <label className="ApproveLeadlabel" style={{marginTop:'44px'}} for="QueryType">Batch Start Date</label>
                         <StyledTextArea
                         aria-label="minimum height"
                         minRows={5}
                           />
                            <button className="ApproveSubmit" type="button">Submit</button>     
                            </div>
                                                  
                                                </CenteredPopUp>





                                                 
                                                 
        <CenteredPopUp show={this.state.showrejectedCenteredPopUp} closePopUp={this.closerejectedcenterPopUp} getWidthclass="w432" >
                    <div className='LeadApprovedModal'>
                    <h1 className='LeadApprovedModalTitle'>Rejected Leads*</h1>
                    <p className="LeadApprovedModalSubTitle">Please enter the reason for rejecting the lead</p>
                    {/* <div class="dropdown">
                    {
                            <button type="button" class="btn d-flex justify-content-between w-100 align-items-center btn-primary dropdown-toggle" data-toggle="dropdown">
                                {this.state.selectedqueryType}
                            </button>  */}
                            <label className="ApproveLeadlabel" style={{marginTop:'44px'}} for="QueryType">Reason</label>
                            <StyledTextArea
                            aria-label="minimum height"
                            minRows={5}
                        />
                                <button className="ApproveSubmit" type="button">Submit</button>     
                                </div>
                                                    
                                                    </CenteredPopUp>
            </>
        )
    }
}

function mapStateToProps(state) {
    const { leads, leadsCount,show_searched,globalSearch,activeWizrTab,globalseraching,searchedKeyword,leadTab,merchant_journey } = state.user;
    const { user_id, token, sfid,salesForceToken } = state.auth;
    const { message } = state.message;
    return {
        sfid,
        leads,
        leadsCount,
        user_id,
        token,
        message,
        show_searched,
        globalSearch,
        activeWizrTab,
        globalseraching,
        searchedKeyword,
        leadTab,
        salesForceToken,
        merchant_journey
    };
}
export default connect(mapStateToProps)(Leads);
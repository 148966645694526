
export const finalDate = () => {
    const csvtemplateData = [
        ["Label", "CourseName", "CourseFee", "CourseID", "Medium", "Subject","RespectiveSkills","BatchStartDate","LastEnrolmentDate","CourseLevel","CourseEligibilityRequired","Scholarship","CertificateProvided","CertificateProvidingAuthority","CourseDuration","DeliveryMode","CourseLearningMode","CourseClassLocation","Type","Semester","Module","Course"],
        ["Format","Varchar", "Numbers", "Varchar - (AA000,AA001,.....,ZZ999) 2 Alphabets, 3 Numbers (676,000 possible combinations)","","","Varchar","Date format; DD-MM-YYYY","Date format; DD-MM-YYYY","","","","","Varchar","Varchar","","","Varchar","","Varchar","Varchar","Varchar"],
        ["Required","Yes","Yes", "Yes","Yes","Yes","Yes","Yes","Yes","Yes","Yes","Yes","Yes","Yes","Yes","Yes","Yes","Yes","Yes","Optional","Optional","Yes"],
        ["InputType","Enter", "Enter", "Auto - generated","Drop Down","Drop Down","Enter","Enter","Enter","Drop Down","Drop Down","Drop Down","Drop Down","Enter","Enter","Drop Down","Drop Down","Enter","Drop Down","Enter","Enter","Enter"],
        ["Drop Down Contents","", "", "","English","Arts","","","","All","Yes","Yes","Yes","","","In-person (Offline)","Self-paced (Recorded)","","Part Time","","",""],
        ["","", "", "","Hindi","Beauty and Wellness","","","","Beginner","No","No","No","","","Online","Instructor-led (Live)","","Full Time","","",""],
        ["","", "", "","Urdu","BFSI","","","","Intermediate","","","","","","Hybrid","Hybrid","","","","",""],
        ["","", "", "","Chinise","Business Management","","","","Advance","","","","","","","","","","","",""],
        ["","", "", "","Spanish","Computer Science","","","","","","","","","","","","","","","",""],
        ["","", "", "","French","Data Science","","","","","","","","","","","","","","","",""],
        ["","", "", "","Arabic","Design","","","","","","","","","","","","","","","",""],
        ["","", "", "","Russian","Engineering","","","","","","","","","","","","","","","",""],
        ["","", "", "","Portuguese","Healthcare","","","","","","","","","","","","","","","",""],
        ["","", "", "","Indonesian","Human Resourses","","","","","","","","","","","","","","","",""],
        ["","", "", "","Japanese","IT & Development","","","","","","","","","","","","","","","",""],
        ["","", "", "","German","Law","","","","","","","","","","","","","","","",""],
        ["","", "", "","Turkish","Linguistics","","","","","","","","","","","","","","","",""],
        ["","", "", "","Korean","Marketing","","","","","","","","","","","","","","","",""],
        ["","", "", "","Others","Personal Development","","","","","","","","","","","","","","","",""],
        ["","", "", "","","Science","","","","","","","","","","","","","","","",""],
        ["","", "", "","","Teaching and Academics","","","","","","","","","","","","","","","",""],
        ["","", "", "","","Test & Preparation","","","","","","","","","","","","","","","",""],
        ["","", "", "","","Travel & Hospitality","","","","","","","","","","","","","","","",""],
    ]
    
    const data = [];
    for (let i = 0; i < csvtemplateData.length; i++) {
       data.push(Object.assign({}, csvtemplateData[i])) 
    }
    return data;
}
export const finalCourseData = (dataobj) => {
    const {product_name,offer_price,product_sfid,medium_of_instruction,subject_name,skill_name,course_level,course_eligibility,scholarship,certificate_availability,certificate_providing_authority,course_duration,course_delivery_mode,course_learning_mode,course_class_location,fulltime_parttime,course_syllabus} = dataobj;
    const csvtemplateData = [
        ["Label", "CourseName", "CourseFee", "CourseID", "Medium", "Subject","RespectiveSkills","BatchStartDate","LastEnrolmentDate","CourseLevel","CourseEligibilityRequired","Scholarship","CertificateProvided","CertificateProvidingAuthority","CourseDuration","DeliveryMode","CourseLearningMode","CourseClassLocation","Type","Syllabus"],
        ["Format",product_name, offer_price, product_sfid,medium_of_instruction,subject_name,skill_name,"NA","NA",course_level,course_eligibility,scholarship,certificate_availability,certificate_providing_authority,course_duration,course_delivery_mode,course_learning_mode,course_class_location,fulltime_parttime,course_syllabus ? course_syllabus.split("<br>").join("\n"):""],
    ]
    
    const data = [];
    for (let i = 0; i < csvtemplateData.length; i++) {
       data.push(Object.assign({}, csvtemplateData[i])) 
    }
    return data;
}


import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Form } from "react-bootstrap"
import { UserSuccessMsg, closeEditUser } from "../actions/model"
import { registerNewUser, getUsers, registerNewUserWizr, getUsers_list, getUsersListWizr,logout } from "../actions/user"
import { Stack, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { history } from '../helpers/history';
import { Scrollbar } from 'react-scrollbars-custom'

const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
}
const AntTab = styled(Tab)(
  ({ theme }) => ({
    marginLeft: "4px",
    color: '#000000',
    background: ' #FFFFFF;',
    borderRadius: "10px",
    marginTop: '2px',
    marginBottom: '2px',
    minHeight: '5px',
    textTransform: 'unset',
    padding: '15px 68px',
    fontSize: "16px",
    '&:hover': {
      color: "#50495A",
      opacity: 1,
    },
    '&.Mui-selected': {
      backgroundColor: '#A198AC',
      color: "#FFFFFF",
      fontSize: "16px",
      borderRadius: "10px",
      padding: '15px 68px',


    },


  }),
);
const AntTabs = styled(Tabs)(
  ({ theme }) => ({
    minHeight: '40px',
  }),
);
class EditUserModel extends Component {

  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.state = {
      username: "",
      email: "",
      password: "",
      mobileNumber: "",
      role: "",
      department: '',
      owner: "",
      active_status: false,
      successful: false,
      isDisabled: true,
      isValid: true,
      message: '',
      isEmail: false,
      isEmailvalid: true,
      isError: false,
      errormsg: '',
      isErrorName: false,
      errorname: '',
      selectedUser: {},
      disableForm: true,
      activeRoleTab: 'Admin',
      onChangeInput: false
    };
  }


  closeEditUser = () => {
    this.handleClear();
    this.props.dispatch(closeEditUser())
    this.setState({ disableForm: true })
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.edituser_show !== this.props.edituser_show) {
      this.setState(this.state);
    }
    const { singleUser } = this.props
    if (Object.keys(this.state.selectedUser).length == 0 && Object.keys(singleUser).length > 0 || (JSON.stringify(this.state.selectedUser) != JSON.stringify(singleUser))) {
      // if(Object.keys(singleUser).length >0 && prevState.selectedUser !== this.state.selectedUser){
      // if (Object.keys(this.state.selectedUser).length == 0  && Object.keys(singleUser).length >0) {
      this.setState({

        selectedUser: singleUser,
        mobileNumber: singleUser && singleUser.mobile__c ? singleUser.mobile__c : '',
        username: singleUser && singleUser.name__c ? singleUser.name__c : '',
        activeRoleTab: singleUser && singleUser.select_user__c ? singleUser.select_user__c : 'Admin',
        email: singleUser && singleUser.email__c ? singleUser.email__c : '',
        owner: singleUser && singleUser.sfid ? singleUser.sfid : '',
        active_status: singleUser.activate__c ? singleUser.activate__c : false,
        department: singleUser && singleUser.department__c ? singleUser.department__c : '',

      });
    }
  }

  handleChange = (e) => {
    e.persist();
    if (e.target.name == 'username') {
      var hasNumber = /\d/;
      if (!hasNumber.test(e.target.value)) {

        this.setState(
          { [e.target.name]: e.target.value }
        );

        this.setState(
          { isErrorName: false, errorname: "" }
        );
        if (e.target.value.length <= 1) {
          this.setState(
            { isErrorName: true, errorname: "Please enter valid name with atleast 2 characters" }
          );
        }

      }
      else {
        this.setState(
          { isErrorName: true, errorname: "Numbers are not allowed" }
        );
      }
    } else {

      this.setState(
        { [e.target.name]: e.target.value }
      );
    }
  }


  handleEmail = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    let str = event.target.value
    if (str && str.length > 2) {
      this.setState({ isEmail: true })
      this.ValidateEmail(str)
    } else {
      this.setState({ isEmail: false })
    }
  }

  ValidateEmail(mail) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mail.match(mailformat)) {
      this.setState({ isEmailvalid: true })
      return true;
    }
    else {
      this.setState({ isEmailvalid: false })
      return false;
    }
  }

  handleChangeTabs = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleMobile = (e) => {
    this.setState({ onChangeInput: true })
    var pattern = new RegExp(/^[0-9\b]+$/);
    const reg = /^[0]?[6789]\d{9}$/;
    if (e.target.value !== '') {
      if (!pattern.test(e.target.value)) {
        document.getElementById('mobileNumber').value = '';
        // this.setState({ mobileNumber: '' });
        this.setState({ isValid: false });
        // this.setState({ message: "Please enter only number." });
      }
      //  else if (e.target.value.length != 10) {
      //   this.setState({ isValid: false });
      //   this.setState({ message: "Please enter valid phone number." });
      // } 
      else {
        if (reg.test(e.target.value)) {
          this.setState({ isValid: true, message: "", mobileNumber: e.target.value });
        } else {
          this.setState({ isValid: false, message: "Please enter valid mobile number.", mobileNumber: e.target.value });
        }
      }
    } else {
      this.setState({ isValid: true, message: "", mobileNumber: '' });
    }
  }


  handleCheckBoxChange = (e) => {
    e.persist();
    if (e.target.checked) {
      this.setState(
        { [e.target.name]: true }
      );
    }
    else {
      this.setState(
        { [e.target.name]: false }
      );
    }

  }



  handleRegister(e) {
    e.preventDefault();


    this.setState({
      successful: false,
    });

    let givenData = {};

    if (this.props.singleUser.sfid === null) {
      givenData = {
        // mobileNumber: this.state.mobileNumber,
        // username: this.state.username,
        // role: this.state.activeRoleTab,
        // department: this.state.department,
        // email: this.state.email,
        // owner: localStorage.getItem('sfid'),
        // active_status: this.state.active_status,
        // id: this.props.singleUser.id
        mobileNumber: this.state.mobileNumber,
        username: this.state.username,
        email: this.state.email,
        role: this.state.activeRoleTab,
        department: this.state.department,
        merchant_id: this.props.sfid,
        active_status: this.state.active_status,
        id: this.props.singleUser.id
      }
    }
    else {

      givenData = {
        // mobileNumber: this.state.mobileNumber,
        // username: this.state.username,
        // role: this.state.role,
        // department: this.state.department,
        // email: this.state.email,
        // owner: localStorage.getItem('sfid'),
        // active_status: this.state.active_status,
        // sfid: this.props.singleUser.sfid

        mobileNumber: this.state.mobileNumber,
        username: this.state.username,
        email: this.state.email,
        role: this.state.activeRoleTab,
        department: this.state.department,
        merchant_id: this.props.sfid,
        active_status: this.state.active_status,
        id: this.props.singleUser.id
      }
    }

    this.props
      .dispatch(
        registerNewUserWizr(givenData)
      )
      .then((response) => {
        if (response.data && response.data.responseCode == 200 && response.data.status == 'Success') {


          this.closeEditUser();
          this.props.dispatch(UserSuccessMsg({ show: true, message: "User Details Edited Successfully" }))
          const { user_id, sfid } = this.props;
          // let data = {
          //   id: user_id,
          // }
          // this.props.dispatch(getUsers_list(localStorage.getItem('sfid')));
          let obj = {
            merchant_id: sfid,
            search: "",
            limit: 10,
            page: 0
          }
          this.props.dispatch(getUsersListWizr(obj))

        }else{
          if(response.status == 401){
            this.props.dispatch(logout());
            history.push('/login');
          }
        }

        if (response.status == 'error') {

          this.setState({ isError: true, errormsg: response.message })

        }

      })
      .catch((err) => {
        this.setState({ isError: true, errormsg: err.message })
      });

  }

  handleChecked = (e) => {
    let checked = e.target.checked;
    if (checked) {
      this.setState({ active_status: true });
    }
    else {
      this.setState({ active_status: false });
    }
  }


  handleClear = () => {
    this.setState({
      username: "",
      email: "",
      password: "",
      mobileNumber: "",
      role: "",
      department: '',
      owner: "",
      active_status: false,
      successful: false,
      isDisabled: true,
      isValid: true,
      message: '',
      isEmail: false,
      isEmailvalid: true,
      isError: false,
      errormsg: '',
      isErrorName: false,
      errorname: '',
      selectedUser: {}
    })
  }

  render() {
    const { edituser_show, user_id, singleUser } = this.props;
    this.state.owner = user_id;

    return (
      <>
        <Modal show={edituser_show} className="adduser edituser model myModal right" >
          <Scrollbar scrollTop={0}>
            <Modal.Header>
              <div className=' align-items-center w-100 pt-2'>
                <div className='adduser_header d-flex justify-content-between align-items-end UserHeader'>
                  <h4>{this.state.disableForm === true ? 'User Details' : 'Edit User'}</h4>
                  <div className='d-flex'>
                    {this.state.disableForm === true ? <button type="button" className="mr-2" onClick={() => this.setState({ disableForm: false })}> <img src="./img/pencil.svg" /> </button> : ""}

                    <button type="button" className="adduser close" onClick={this.closeEditUser}> <img src='../img/popclose.svg' alt='close' /> </button>
                  </div>
                </div>
                {/* <div>
                  <div className="switch_btn d-flex" style={{ color: `${this.state.active_status == true ? '#094588' : ''}` }}>
                    <label className="switch mr-3">
                      <input type="checkbox"
                        onChange={this.handleCheckBoxChange}
                        name="active_status" value="1"
                        checked={this.state.active_status}
                      />
                      <span className="slider round"></span>
                    </label> {this.state.active_status == true ? "Active" : "Inactive"}
                  </div>
                </div> */}
              </div>
            </Modal.Header>
            <form onSubmit={this.handleRegister}>
              <Modal.Body>
                <div className="mb-4">
                  <div className="form-group labelforadduser">
                    <label className="form-label ">
                      Full Name
                    </label>
                    <input
                      type="text"
                      value={this.state.username}
                      className={`'form-control' ${this.state.disableForm ? 'formadduserEdit' : 'FormTextBold'}`}
                      placeholder="Enter User's Full Name"
                      name="username"
                      onChange={this.handleChange}
                      disabled={this.state.disableForm}
                    />
                    {this.state.isErrorName && this.state.errorname != '' && (
                      <span style={{ color: "red" }} className="text-left">
                        {this.state.errorname}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <div className="form-group labelforadduser">
                    <label className="form-label">
                      Mobile Number
                    </label>
                    <div className='d-flex align-item-center'>
                      <p className={`${this.state.onChangeInput === true ? 'NewPrefixClass' : 'prefixForMobile'}`}>+91</p>
                      <input
                        type="text"
                        value={this.state.mobileNumber}
                        // className="form-control "
                        className={`'form-control' ${this.state.disableForm ? 'EditUserMobile' : 'FormTextBoldMobile'}`}
                        placeholder="0000 000 000"
                        name="mobileNumber"
                        id="mobileNumber"
                        maxLength={"10"}
                        onChange={this.handleMobile}
                        disabled={this.state.disableForm}
                      />
                    </div>
                    {this.state.isValid == false && this.state.message != '' && (
                      <span style={{ color: "red" }} className="text-left">
                        {this.state.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="form-group labelforadduser">
                    <label className="form-label">
                      Email ID
                    </label>
                    <input
                      type="text"
                      className={`'form-control' ${this.state.disableForm ? 'formadduserEdit' : 'FormTextBold'}`}
                      placeholder="Start typing..."
                      name="email"
                      value={this.state.email}
                      onChange={this.handleEmail}
                      disabled={this.state.disableForm}
                    />
                    {this.state.isEmail && !this.state.isEmailvalid && (
                      <span style={{ color: "red" }} className="text-left">
                        Invalid email ! Please enter a valid email address.
                      </span>
                    )}
                  </div>

                </div>

                {/* <div className="mb-4">
                  <div className="form-group">
                    <label className="form-label">
                      Role(s)
                    </label>
                    <select
                      name="role"
                      id=""
                      className="form-control"
                      onChange={this.handleChange}
                    >
                      <option value=''>Select atleast one role</option>
                      <option selected={this.state.selectedUser.select_user__c == 'Admin'} value="Admin">Admin</option>
                      <option selected={this.state.selectedUser.select_user__c == 'Other users'} value="Other users">Other User</option>
                    </select>
                  </div>

                </div> */}

                {/* <div className="mb-4">
                  <div className="form-group">
                    <label className="form-label">
                      Department(s)
                    </label>
                    <select
                      name="department"
                      id=""
                      className="form-control"
                      onChange={this.handleChange}
                    >
                      <option value=''>Select atleast one department</option>
                      <option selected={this.state.selectedUser.department__c == 'Operations'} value="Operations">Operations</option>
                      <option selected={this.state.selectedUser.department__c == 'Sales'} value="Sales">Sales</option>
                      <option selected={this.state.selectedUser.department__c == 'Credit'} value="Credit">Credit</option>
                      <option selected={this.state.selectedUser.department__c == 'Accounts'} value="Accounts">Accounts</option>
                    </select>
                  </div>

                  {
                    this.state.isError &&
                    <span style={{ color: "red" }} className="text-left">
                      {this.state.errormsg}
                    </span>
                  }
                </div> */}
                <div className="mb-4">
                  <div className="form-group labelforadduser">
                    <label className="form-label ">
                      Role(s)
                    </label>
                    {/* <Stack direction="row"
                      className='stackModes'

                    >
                      <AntTabs
                        value={this.state.value}
                        onChange={this.handleChangeTabs}
                        TabIndicatorProps={{
                          hidden: true
                        }}
                        className='stackModesTabs'
                        
                      >
                        <AntTab label="Admin" className='stackModesTabs2' disabled={this.state.disableForm} />
                        <AntTab label="User" className='stackModesTabs1' disabled={this.state.disableForm}/>
                      </AntTabs>
                    </Stack> */}
                    <div className='d-flex roleTabs p-1' style={this.state.disableForm ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}>
                      <div className={`${this.state.activeRoleTab === 'Admin' ? 'wizr-role-tab-active' : ''} cursor-point wizr-role-tab`}
                        onClick={() => this.setState({ activeRoleTab: "Admin" })}
                      >Admin</div>
                      <div className={`${this.state.activeRoleTab === 'User' ? 'wizr-role-tab-active' : ''} cursor-point wizr-role-tab`}
                        onClick={() => this.setState({ activeRoleTab: "User" })}
                      >User</div>
                    </div>
                  </div>

                </div>
                <div className="mb-4">
                  <div className="form-group labelforadduser">
                    {
                      this.state.disableForm ? 
                      <>
                        {this.state.active_status ?
                        <div class="">
                          <span class="green-circle "></span>
                          <span class="userstatus">Active</span>
                        </div> :
                        <div class="">
                          <span class="red-circle  "></span>
                          <span class="userstatus">Inactive</span>
                        </div>}
                      </>
                      :
                      <div className="d-flex align-items-left" style={{justifyContent: "left"}}>
                        <div className="switch_btn d-flex">
                          <label className="switch mr-3">
                            <input type="checkbox" onChange={this.handleChecked} checked={this.state.active_status} />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        {this.state.active_status ? "Active" : "Inactive"}
                      </div>
                    }
                    {/* <div className=" d-flex align-items-center">
                      <div className="switch_btn d-flex">
                        <label className="switch mr-3">
                          <input type="checkbox" disabled={this.state.disableForm} checked={this.state.active_status} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      {this.state.active_status ? "Active" : "Inactive"}
                    </div> */}
                    {/* {this.state.active_status ?
                      <div class="d-flex align-items-center">
                        <span class="green-circle "></span>
                        <span class="userstatus">Active</span>
                      </div> :
                      <div class="">
                        <span class="red-circle  "></span>
                        <span class="userstatus">Inactive</span>
                      </div>
                    } */}
                  </div>
                </div>
                <div className="pt-3 mb-5 savebtn d-flex justify-content-end">
                  <Button
                    type="submit"
                    className="btn btn-default_ subBtn saveButton"
                    disabled={this.state.isErrorName == false && this.state.username != '' && this.state.email != '' && this.state.isEmailvalid && this.state.mobileNumber != '' && this.state.isValid && !this.state.disableForm ? false : true}

                  >
                    Save
                  </Button>
                </div>
              </Modal.Body>
            </form>
          </Scrollbar>

        </Modal >
      </>
    )
  }

}

function mapStateToProps(state) {
  const { edituser_show } = state.model;
  const { user_id, sfid } = state.auth;
  const { singleUser } = state.user;
  return {
    edituser_show,
    user_id,
    sfid,
    singleUser,
  };
}


export default connect(mapStateToProps)(EditUserModel)
import React, { Component } from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

export default class forgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            newPassword: "",
            confirmPassword: "",
            passwordError: "",
            newPasswordError: "",
            confirmPasswordError: "",
            validPassword: false,
            validConfirmPassword: false,
            validNewPassword: false,
            createNewPasswordStage: true,
            showPasswordText: false,
            captcha: "",
            captchaError: "",
            validCaptcha: false
        }
    }
    componentDidMount() {
        loadCaptchaEnginge(6);
    }

    validatePassword = (e) => {
        let password = e.target.value;
        let specialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        this.setState({ newPassword: password })
        if (password.length == 0) {
            this.setState({ newPasswordError: "Password cannot be empty", validNewPassword: false })
        }
        // else if (password.length < 8 || password.length > 15) {
        //     this.setState({ newPasswordError: "The Email ID and Password are incorrect", validNewPassword: false })
        // }
        else if (!(/[A-Z]/.test(password))) {
            this.setState({ newPasswordError: "Atleast 1 upper case", validNewPassword: false })
        }
        else if (!(/[a-z]/.test(password))) {
            this.setState({ newPasswordError: "Atleast 1 lower case", validNewPassword: false })
        }
        else if (!(/\d/.test(password))) {
            this.setState({ newPasswordError: "Atleast 1 digit", validNewPassword: false })
        }
        else if (!(specialChar.test(password))) {
            this.setState({ newPasswordError: "Atleast 1 specal", validNewPassword: false })
        }
        else {
            this.setState({ newPasswordError: "", validNewPassword: true, newPassword: password })
        }
    }


    validateconfirmPassword = (e) => {
        let password = e.target.value;
        this.setState({ confirmPassword: password })
        if (password.length == 0) {
            this.setState({ confirmPasswordError: "Password cannot be empty", validConfirmPassword: false })
        }
        // else if (password.length < 8 || password.length > 15) {
        //     this.setState({ confirmPasswordError: "The Email ID and Password are incorrect", validConfirmPassword: false })
        // }
        else if (password !== this.state.newPassword) {
            this.setState({ confirmPasswordError: "Password does not match with new passwword", validConfirmPassword: false })
        }
        else {
            this.setState({ confirmPasswordError: "", validPassword: true, validConfirmPassword: password })
        }
    }

    changedCaptcha = (e) => {
        let userTypeCaptcha = e.target.value;
        this.setState({ captcha: userTypeCaptcha });
        if (userTypeCaptcha.length == 0) {
            this.setState({ captchaError: 'Captcha cannot be empty' })
        }
        if (userTypeCaptcha.length > 6) {
            return
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { captcha } = this.state;
        if (validateCaptcha(captcha) === true) {
            this.setState({ validCaptcha: true, captchaError: "" })
            loadCaptchaEnginge(6);
            this.setState({ captcha: "" });
        }
        else {
            this.setState({ validCaptcha: false, captchaError: "Captcha is incorrect,Please try again!", captcha: "" })
        }
    }

    // checkValidPassword = (reqPassoword) => {
    //     let specialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    //     let valid = false;
    //     if (/[A-Z]/.test(reqPassoword)) {
    //         console.log('upper case')
    //         if (/[a-z]/.test(reqPassoword)) {
    //             console.log('lower case')
    //             if (/\d/.test(reqPassoword)) {
    //                 console.log('digit case')
    //                 if (specialChar.test(reqPassoword)) {
    //                     console.log('special char')
    //                     valid = true
    //                 }
    //                 else{
    //                     console.log('special char')

    //                     valid = false
    //                 }
    //             }
    //             else {
    //                 console.log('digit case')

    //                 valid = false
    //             }
    //         }
    //         else {
    //             console.log('lowerCase fail')

    //             valid = false
    //         }
    //     }
    //     else {
    //         console.log('uppercase fail')
    //         valid = false
    //     }

    // }



    render() {
        const { captchaError, createNewPasswordStage, newPassword, confirmPassword, newPasswordError, validNewPassword, confirmPasswordError, validConfirmPassword, showPasswordText, captcha } = this.state;
        return (
            <div className='login_mainSection' id="loginSection">
                <div className='container newContainer'>
                    <div className="row">
                        <div className='loginsec'>
                            <div className='col-lg-7 colForLogin'>
                                <div className='header'>
                                    <div className='logoWizr'><img src="./img/WiZRLogoBlack.png" /></div>
                                    {createNewPasswordStage && (
                                        <div>
                                            <h4>Create New Password</h4>
                                            <p>Please follow the instructions to create the password</p>
                                            <form className='formLogin' onSubmit={this.handleSubmit} >
                                                <div className="form-group labelforadduser">
                                                    <label className="form-label">New Password</label>
                                                    <div className='passSec'>
                                                        <input
                                                            className="form-control formadduser2"
                                                            type={showPasswordText ? "text" : "password"}
                                                            name="newPassword"
                                                            id="new_password"
                                                            value={newPassword}
                                                            onChange={(e) => this.validatePassword(e)}
                                                            style={{ height: '35px' }}
                                                        />
                                                        <div class="input-group-addon">
                                                            <i class="fa fa-eye-slash cursor-point" aria-hidden="true" onClick={() => this.setState({ showPasswordText: !showPasswordText })}></i>
                                                        </div>
                                                        <div className='text-danger'>{newPasswordError}</div>
                                                    </div>
                                                </div>

                                                <div className="form-group labelforadduser mt-2">
                                                    <label className="form-label">Confirm Password</label>
                                                    <input
                                                        className="form-control formadduser2"
                                                        type="password"
                                                        name="confirmPassword"
                                                        value={confirmPassword}
                                                        onChange={(e) => this.validateconfirmPassword(e)}
                                                        id="password"
                                                        style={{ height: '35px' }}
                                                    />
                                                    <div className='text-danger'>{confirmPasswordError}</div>
                                                </div>

                                                {/* <p className='rememberMe'><input type="checkbox" />Remember Me</p> */}

                                                <div className="form-group labelforadduser mt-2">
                                                    <div className='d-flex'>
                                                        <LoadCanvasTemplate />
                                                        <div className='ml-1'><img src="./img/reloadImage.svg" height="18" className='cursor-point' onClick={(e) => { document.getElementById('reload_href').click() }} /></div>
                                                    </div>

                                                </div>

                                                <div className="form-group labelforadduser mt-2">
                                                    <label className="form-label">Enter Captcha Shown Above</label>
                                                    <input
                                                        className="form-control formadduser2"
                                                        type="text"
                                                        name="captcha"
                                                        id="captcha"
                                                        value={captcha}
                                                        onChange={(e) => this.changedCaptcha(e)}
                                                        style={{ height: '35px' }}
                                                    />
                                                    <div className='text-danger'>{captchaError}</div>
                                                </div>

                                                <div className='Buttonwithcontent mt-n5'>
                                                    <button type="submit" className={`ContinueButton ${validNewPassword && validConfirmPassword && captcha.length != 0 ? '' : 'disabledButtonStyle'}`} disabled={!(validNewPassword && validConfirmPassword && captcha.length != 0)}>Continue</button>
                                                </div>
                                            </form>
                                        </div>)}
                                    {/* New Password End */}
                                </div>
                            </div>
                            <div className='col-lg-5 imgBackground  pd0'>
                                <div className='imgContainer'>
                                    {<img src='./img/loginRightImage.svg' className='img-fuild' />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


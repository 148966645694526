import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { getUserProfile, setShowSearched, emptyserachList } from "../actions/user";
import { openProfileModel, openPasswordModel } from "../actions/model"
//import sidelogo from "/images/pin.png"

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      path: '',
      f_name: "",
      l_name: "",

    }
  }

  componentDidMount() {
    const pathArray = window.location.pathname.split("/");
    const segment_1 = pathArray[1];
    this.setState({ path: segment_1 });
    this.props.dispatch(setShowSearched(false))
    this.props.dispatch(emptyserachList())


    const { user_id, userProfileData, merchant_journey } = this.props;
    let userData = {
      user_sfid: localStorage.getItem('sfid')
    }
    if (!userProfileData && merchant_journey == "B2B2C") {
      this.props.dispatch(getUserProfile(userData))
        .then((response) => {
          if (response.responseCode === 200) {
            let profileData = response.profile;
            if (profileData) {
              this.setState({ f_name: profileData.first_name__c, l_name: profileData.last_name__c })
            }

            this.setState({ first_name: response.first_name });
            this.setState({ last_name: response.last_name });
            this.setState({ email: response.email });
            this.setState({ mobile_number: response.mobile });
          }
        })

    }


    $('#sidebarClose').click(function () {
      $("#accordionSidebar").removeClass("open-close")
    })
  }


  openProfile = () => {
    this.props.dispatch(openProfileModel());
  }

  openPassword = () => {
    this.props.dispatch(openPasswordModel());
  }
  render() {
    const { activeWizrTab } = this.props;
    return (
      <>
        <div className='sidebar' id="accordionSidebar">
          <div className='d-flex justify-content-end w-100'>
            <button id="sidebarClose" className="btn d-lg-none mb-0">
              <i className="fa fa-times"></i>
            </button>
          </div>

          <ul
            className="navbar-nav bg-gradient-primary sidebar-dark accordion"
            id=""
          >
            <li className="nav-item text-center" style={{ height: "120px" }}>
              <Link
                className="sidebar-brand admin-name-w-75"
                to="/home"
              >
                <div className="sidebar-brand-text">

                  {/* <div className='brand-logo-sidebar'><img src="images/icons/croma-logo.png"  alt=''/></div>
                         Croma India ok
                      </div> */}
                  <div className='brand-logo-sidebar'>
                    <img src="./img/wizrLogo.png" alt='' />
                    {/* <h1 style={{color:"wheat"}}>Wizr</h1> */}

                  </div>
                  {/* {`${this.state.f_name} ${this.state.l_name}`} */}
                </div>
              </Link>
              <div className="sidebar-brand-subtext">{this.state.first_name ? (this.state.first_name + " " + this.state.last_name) : ''}</div>
            </li>

            <li className={`nav-item icon_dashboard ${this.state.path === 'home' ? 'active' : ''}`}>
              <Link className="nav-link" to="/home">
                <span className='sidebar-tabs-both'>Dashboard</span>
              </Link>
            </li>
            <li className={`nav-item icon_leads ${this.state.path === 'leads' ? 'active' : ''}`}>
              <Link className="nav-link" to="/leads">
                <span className='sidebar-tabs-both'>Leads</span>
              </Link>
            </li>
            {/* settlementy tab commented because used next plan*/}
            {activeWizrTab !== "WIZR Skills" ? <li
              // onClick={()=> {localStorage.setItem("section",settlement)}}
              className={`nav-item icon_settlements ${this.state.path === 'settlement' ? 'active' : ''}`}
            >
              <Link className="nav-link" to="/settlement">
                <span className='sidebar-tabs-both'>Settlements</span>
              </Link>
            </li> : ""}

            <li className={`nav-item icon_products ${this.state.path === 'products' ? 'active' : ''}`}>

              <Link className="nav-link" to="/products">
                {activeWizrTab === "WIZR Skills" ?
                  <span className='sidebar-tabs-both'>Courses</span>
                  : <span className='sidebar-tabs-both'>Products</span>
                }
              </Link>
            </li>
            <li className={`nav-item icon_api_key ${this.state.path === 'report' ? 'active' : ''}`}>
              <Link className="nav-link" to="/report">
                <span className='sidebar-tabs-both'>Reports</span>
              </Link>
            </li>
            <li className={`nav-item icon_settings ${this.state.path === 'settings' ? 'active' : ''}`}>
              <Link

                className="nav-link" to="/settings">
                <span className='sidebar-tabs-both'>Settings</span>
              </Link>
            </li>
            {/* Sidebar Toggler (Sidebar) */}
            {/* <div className="nav_profile" style={{background:"#000000"}}> */}
            {/* <div className="nav_profile">
                          <div className="brand-text">Eduvanz</div>  
                          <img src="images/logo-stride.png" alt="icon"></img>
                          <img src="images/stride_logo.png" alt="icon"></img>

                    </div> */}
          </ul>
        </div>

        {/* End of Sidebar */}

      </>
    )
  }
}

function mapStateToProps(state) {
  const { user_id } = state.auth;
  const { activeWizrTab, userProfileData, merchant_journey } = state.user;
  return {
    user_id,
    activeWizrTab,
    userProfileData,
    merchant_journey
  };
}

export default connect(mapStateToProps)(Sidebar);
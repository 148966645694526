import axios from "axios";
import authHeader from "./auth-header";

const API_URL_WIZR = process.env.REACT_APP_API_URI_WIZR;
let config = {
  headers: {
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }
}

class UserServiceWizr {
  get(Url) {
    return axios
      .get(API_URL_WIZR + Url, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
      .then((response) => {
        //  console.log('response', response);
        return response.data;
      });
  }

  post(getdata, Url) {
    return axios
      .post(API_URL_WIZR + Url, getdata, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
      // .post(API_URL_WIZR + Url, getdata, { headers: { 'Authorization': 'Bearer ' + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NTYzOCwiaWF0IjoxNjkxMTM0MzIxLCJleHAiOjE2OTExNzc1MjF9.Mh1js3vQUGwKZOLA3CEQdd_fullA3gPI99mEgtaU46k" } })
      .then((response) => {
        return response.data;
      });
  }
}

export default new UserServiceWizr();
import React from 'react'
import { Component } from 'react';

class CustomizeRadioBtn extends Component {
    constructor() {
        super()
        
    }

render(){
 const {checked}=this.props
  return (
    <>
   <div class="radiomainbtn">
    <input id="radio-1" name="radio" type="radio" checked={checked} />
    <label for="" class="radiomainbtnlabel"></label>
  </div>

   
   </>
  )
}
  
}
export default CustomizeRadioBtn
import React from 'react'
import { Component } from 'react';

class SuccessErrorMsg extends Component {
    constructor(props) {
        super()
        this.state = {};
    }

    render() {
        const { left,top,message } = this.props
        return (

            <>
                <div className='success-Adduser-con d-flex' style={{position:"absolute",left:left,top:top}}>
                    <div><img src='./images/client/courseSuccess.svg' /></div>
                    <div className='fw-600 fz14 lineheight20 fontcolorfig msgstyle'>{message}</div>
                </div>
            </>
        );
    };
}

export default SuccessErrorMsg;
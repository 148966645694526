import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Modal, Button, Form } from "react-bootstrap"
import { logoutWizr } from '../actions/auth';
import { history } from '../helpers/history';
import { connect } from "react-redux";

class TopBarWizrHelpNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNotificationModal: false,
            notificationStage: 'wizrskills',
            selected: 1
        }
    }
    closeNotification = () => {
        this.setState({ showNotificationModal: false })
    }
    openNotification = () => {
        this.setState({ showNotificationModal: true })
    }
    handleLogout = () => {
        this.props.dispatch(logoutWizr()).then((response) => {
            if (response.data.status == "success") {
                history.push('/login')
            }
        });
    }
    render() {
        return (
            <>
                <div className='topBarContainer'>
                    <div className='topBarmenuList'>
                        <li className='topbarHelp'> <Link to='help'><img className='mr-2' src='./img/help.svg' />Help Center</Link></li>
                        <li className='topbarHelp' onClick={this.handleLogout}><img className='mr-2' src='./img/logout.svg' alt='Logout icon' />Logout</li>
                        {/* <li className='topbarHelp' onClick={this.openNotification}> <p ><img className='mr-2' src='./img/notification.png' /> Notification</p></li> */}
                    </div>
                </div>
                <Modal show={this.state.showNotificationModal} className="adduser model myModal right">
                    <Modal.Header className='modelheaderNotification'>
                        <div className=''>
                            <div className='adduser_header d-flex justify-content-between align-items-end UserHeader'>
                                <h4 >Notifications</h4>
                                <div className='d-flex justify-content-between flex-column align-items-end'>
                                    <button type="button" className="adduser close" onClick={this.closeNotification}> <i className="fas fa-times"></i> </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row WizrCred">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a onClick={() => this.setState({ notificationStage: 'wizrskills' })} className={this.state.notificationStage === 'wizrskills' ? 'WizrSkills notificationActive' : 'WizrSkills'} >Wizr Skills</a>
                                </li>
                                <li className="nav-item ml-3">
                                    <a onClick={() => this.setState({ notificationStage: 'wizrCred' })} className={this.state.notificationStage === 'wizrCred' ? 'WizrSkills notificationActive' : 'WizrSkills'} >Wizr Cred</a>
                                </li>
                            </ul>

                        </div>
                        <div className="tab-content" id="myTabContent">
                            {this.state.notificationStage === 'wizrskills' && <div className="" >
                                <div className='notificationContainer'>
                                    <div className={`noticontent ${this.state.selected === 1 ? 'active' : ''}`} onClick={() => this.setState({ selected: 1 })}>
                                        <img src='./img/colorBell.png' />
                                        <div>
                                            <h6>12 Cancellation Requests Received.</h6>
                                            <p>Just Now</p>
                                        </div>
                                    </div>
                                    <div className={`noticontent ${this.state.selected === 2 ? 'active' : ''}`} onClick={() => this.setState({ selected: 2 })}>
                                        <img src='./img/bell.png' />
                                        <div>
                                            <h6>Rs.46,999 Disbursed for app Id: AD18272S</h6>
                                            <p>1m ago</p>
                                        </div>
                                    </div>
                                    <div className={`noticontent ${this.state.selected === 3 ? 'active' : ''}`} onClick={() => this.setState({ selected: 3 })}>
                                        <img src='./img/bell.png' />
                                        <div>
                                            <h6>4 Whitelisting Requests were Received.</h6>
                                            <p>Feb 15, 2022, | 03:43 PM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {this.state.notificationStage === 'wizrCred' && <div >
                                <div className='notificationContainer'>
                                    <div className='noticontent'>
                                        <img src='./img/colorBell.png' />
                                        <div>
                                            <h6>23 New leads were received via the Wizr app.</h6>
                                            <p>Just Now</p>
                                        </div>
                                    </div>
                                    <div className='noticontent'>
                                        <img src='./img/bell.png' />
                                        <div>
                                            <h6>5 Cancellation requests Received</h6>
                                            <p>1m ago</p>
                                        </div>
                                    </div>
                                    <div className='noticontent'>
                                        <img src='./img/bell.png' />
                                        <div>
                                            <h6>12 Cancellation Requests Received.</h6>
                                            <p>Feb 15, 2022, | 03:43 PM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    const { merchant_journey } = state.user;

    return {
        merchant_journey,
    };
}

export default connect(mapStateToProps)(TopBarWizrHelpNotification);



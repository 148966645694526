import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import Select from "react-select";
import { Modal, Button } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from '@mui/material/Tooltip';
import { asset } from "../common/assets";
import {
  openPreviewPdfModel,
  closeLeadProfileModel,
  openImageEditModel,
  openDocumentDropModel,
  openDropModel,
  openQueryModel,
  openSuccessModel,
  openEnach,
  closeEnach,
  closeLeadApplicationModel,
  openLeadProfileModel,
  UploadAllModalCloseFailure,
  getHighLimit,
  UserClickedOnce
} from "../actions/model";
import {
  updateProfession,
  updateEmpType,
  updateAddress,
  updateRent,
  updatePan,
  updatePanStatus,
  getLeadProfile,
  updateAccount,
  getAddress,
  addAddress,
  uploadDocument,
  uploadProfile,
  checkLiveliness,
  getLeadProfileDocuemnt,
  getLeadOtherDocuemnt,
  getLeadPanDocuemnt,
  checkEnachDownload,
  checkEnachUpload,
  checkEnachStatus,
  clearSearchEntity,
  getBankDocuemnt,
  removeDocument,
  leadPdfStore,
  searchEntity,
  removeProfile,
  getBankDetails,
  statementUpload,
  verifyUserOtp,
  checkAccount,
  sendUserOtp,
  sendLink,
  faceMatch,
  checkIfsc,
  fraudCheck,
  getBanks,
  updateBanks,
  createTransApp,
  updateIpabureau,
  bankProofUpload,
  validatePan,
  merchantSettlementDetail,
  sendAgreementSms,
  downloadNach,
  getStage,
  getDocData,
  verifyDoc,
  enachPay,
  getPaymentPlan,
  updateOpp,
  addCoApplicant,
  uploadDocAdharBack,
  newBankstatementupload,
} from "../actions/user";
import {
  selectPlan,
  getPlanById,
  getPlans,
  getUserProduct,
} from "../actions/payment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Scrollbar } from "react-scrollbars-custom";
import { Typography } from "@material-ui/core";

const MAP_API_KEY = process.env.REACT_APP_MAP_API_KEY;
const FILE_LIMIT = parseInt(process.env.REACT_APP_FILE_SIZE_LIMIT);
// const FILE_LIMIT = 1;
let searchTimer,startTimeOut;
const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
};
const initial = {
  isPanVerified: false,
  isProfileVerified: false,
  isAddressDocVerified: false,
  addressType: 1,
  downpayment: null,
  timer: "00:18",
  viewResend: false,
  logId: null,
  isOtpSent: false,
  lead_sfid: null,
  livenessScore: 0,
  isLive: false,
  bankSrc: "",
  maxRequestLength: "5242880",
  company_name: "",
  monthly_income: "",
  family_income: "",
  gmapsLoaded: false,
  isValidPan: true,
  isValid: true,
  isValidAccount: true,
  errorMsg: "",
  otpError: false,
  onBoarding: 21,
  rent_amount: "",
  house: 0,
  isBackUploading: false,
  isSuccess: "",
  successMsg: "",
  isProfileSuccess: "",
  profileSuccessMsg: "",
  statementUploaded: false,
  userProfile: null,
  panAttempt: 0,
  pan: null,
  username: "",
  userstatus: "PENDING",
  sfid: "",
  product: "",
  email: "",
  mobile: "",
  card: "",
  loan_amount: "",
  profileId: null,
  panId: null,
  frontId: null,
  backId: null,
  limit: 0,
  dob: null, //new Date
  gender: "",
  pincode: "",
  address_pin: "",
  address: "",
  state: "",
  city: "",
  errpincode: "",
  isValidpincode: false,
  addressList: [],
  selectedAddress: "",
  current_address: 0,
  selectedLeadAddress: "",
  profession: "",
  selectedTab: 1,
  selectedBankTab: 1,
  defaultTab: 0,
  defaultBankTab: 0,
  profileType: "",
  profileBase: "",
  profile: null,
  panType: "",
  panBase: "",
  Errmessage: "",
  isErrorName: true,
  errorname: "",
  frontProofType: "",
  frontProofBase: "",
  backProofType: "",
  backProofBase: "",
  bankDocument: [],
  selectedPlan: "",
  frontFileType: 0,
  backFileType: 0,
  panFileType: 0,
  frontDocAttempt: 0,
  backDocAttempt: 0,
  bankProofSrc: null,
  ifsc: "",
  acc_name: "",
  acc_no: "",
  bank: "",
  otp1: "",
  otp2: "",
  otp3: "",
  otp4: "",
  professionValue: "Select Any",
  showSelectOptionDropdown: false,
  showCompanySearch: false,
  planData: null,
  productData: null,
  download_status: null,
  upload_status: null,
  responsive: {
    0: {
      items: 1,
    },
    450: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  },
  successmsg_: false,
  help_modal: false,
  showAddAddress: false,
  isAutoAdd: false,
  smscheck: 0,
  emailcheck: 0,
  emailcheck: 0,
  sendagainshow: true.valueOf,
  bothChecked: 0,
  isLoader: false,
  plansSfid: null,
  payment_plan_is: null,
  mandate_signed_is: null,
  indicator: 1,
  co_pan: "",
  co_number: "",
  co_email: "",
  co_last_name: "",
  co_first_name: "",
  co_relation: "",
  co_isEmail: true,
  co_isNumber: true,
  co_isPan: true,
  merchant_id: null,
  checkSal: true,
  showConsentModal: false,
  showInputUpdatePopupCount: 0,
  monthlyIncome: '',
  customer_idIs:'-',
  bank_name__c: '',
  get_stage_hit:0,
  showErrorMsg: false,
  adharFrontBase64:'',
  adharBackBase64:'',
  panUploadAttempt:true,
  showBankDropdown:false,
  profileAttempt:0,
  photoVerify: false,
  panVerify: false,
  frontVerify: false,
  backVerify: false
};


class LeadsProfile extends Component {
  constructor(props) {
    super(props);
    this.state = initial;
    this.onlyNumbers = this.onlyNumbers.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePanChange = this.handlePanChange.bind(this);
    this.handleProfileSelect = this.handleProfileSelect.bind(this);
    this.handlePanSelect = this.handlePanSelect.bind(this);
    this.handleBackProofSelect = this.handleBackProofSelect.bind(this);
    this.handleFrontProofSelect = this.handleFrontProofSelect.bind(this);
    this.handleEnachUploadSelect = this.handleEnachUploadSelect.bind(this);
    this.handleBankProofSelect = this.handleBankProofSelect.bind(this);
    this.handleBankSlipSelect = this.handleBankSlipSelect.bind(this);
    this.handleEntitySearch = this.handleEntitySearch.bind(this);
    this.handleIfscChange = this.handleIfscChange.bind(this);
    this.handleOtpChange = this.handleOtpChange.bind(this);
    this.bankChange = this.bankChange.bind(this);
    this.startTimer = this.startTimer.bind(this);
    // this.aadharAddFront = this.aadharAddFront.bind(this)
    // this.aadharAddBack = this.aadharAddBack.bind(this)
    this.screenRedirect = this.screenRedirect.bind(this);
    // this.localScreenRedirect = this.localScreenRedirect(this);
    this.getDocumentImage = this.getDocumentImage.bind(this);
    this.getPhotoDoc = this.getPhotoDoc.bind(this);
    this.getPanDoc = this.getPanDoc.bind(this);
    this.getOtherDoc = this.getOtherDoc.bind(this);
    this.dataURLtoFile = this.dataURLtoFile.bind(this);
    this.updatePaymentPlan = this.updatePaymentPlan.bind(this);
    this.submitCoDetails = this.submitCoDetails.bind(this);
    this.handleChangeCo = this.handleChangeCo.bind(this);
    this.textInput1 = React.createRef();
    this.textInput2 = React.createRef();
    this.textInput3 = React.createRef();
    this.textInput4 = React.createRef();
    this.textInput5 = React.createRef();
    this.redirectScreen = this.redirectScreen.bind(this);


  }
  redirectScreen=()=>{
    if(this.state.get_stage_hit>2){
      clearTimeout(startTimeOut);
      this.closeModel();
    }
    else{
      startTimeOut = setTimeout(this.screenRedirect, 2000);
    }

  }

  screenRedirect = () => {
    const { lead_id, dispatch } = this.props;
    const {
      co_email,
      co_first_name,
      co_last_name,
      co_number,
      co_relation,
      co_pan,
    } = this.state;
    let id = parseInt(localStorage.getItem("L_id"));
    dispatch(getStage({ user_sfid: lead_id })).then((response) => {
      this.setState({ get_stage_hit: this.state.get_stage_hit+1})
      if (response.status == "success") {
        if(startTimeOut){
          clearTimeout(startTimeOut);
        }
        let stageData = response.accountDet;
     

        if(stageData.account_status__c === "Full User"){
        
            if(stageData.application_stage == "KYC Pending"){
              this.setState({
                onBoarding: 5,
                indicator: 2,
              });
              this.getDocumentImage();
            }else if(stageData.application_stage == "Bank Account Details/ Penny Drop Pending"){  
              this.setState({
                onBoarding: 7,
                indicator: 2,
              });
            }else{
              if(!this.state.payment_plan_is){
                let payload={
                  merchant_productid: this.state.merchant_id,
                  user_sfid:lead_id,
                  // opp_id: this.props.opp_id,
                }                
                this.props.dispatch(
                  getPlans(payload)
                );
      
                this.setState({
                  onBoarding: 6,
                  indicator: 3,
                });
              }else{
                this.setState(
                  {
                    onBoarding: 11,
                    indicator: 4,
                  },
                  () => {
                    this.props.dispatch(
                      merchantSettlementDetail(this.props.seettlemt_opp_id)
                    );              
                  }
                );
              }
            }
        }else{
          if (
            stageData.age__c &&
            stageData.age__c < 21 &&
            !co_email &&
            !co_first_name &&
            !co_last_name &&
            !co_number &&
            !co_relation &&
            !co_pan
          ) {
            this.setState({
              onBoarding: 22,
              indicator: 1,
            });
          } else if (!stageData.pan_verified__c) {
            this.setState({
              onBoarding: 0,
              indicator: 1,
            });
          } else if (
            !stageData.date_of_birth_applicant__c ||
            !stageData.approved_pin_code__c ||
            !stageData.gender__c
          ) {
            this.setState({
              onBoarding: 1,
              indicator: 1,
            });
          } else if (
            !stageData.monthly_income__c ||
            !stageData.occupation__c ||
            !stageData.employer_name__c
          ) {
            this.setState({
              onBoarding: 4,
              indicator: 1,
            });
          } else if (
            !stageData.resident_type__c ||
            !stageData.current_address_id__c
          ) {
            this.setState({
              onBoarding: 3,
              indicator: 1,
            });
          }else{
            if(stageData.application_stage == "KYC Pending"){
              this.setState({
                onBoarding: 5,
                indicator: 2,
              });
              this.getDocumentImage();
            }else if(stageData.application_stage == "Bank Account Details/ Penny Drop Pending"){  
              this.setState({
                onBoarding: 7,
                indicator: 2,
              });
            }else{
              if(!this.state.payment_plan_is){
                let payload={
                  merchant_productid: this.state.merchant_id,
                  user_sfid:lead_id,
                  // opp_id: this.props.opp_id,
                }                
                this.props.dispatch(
                  getPlans(payload)
                );
      
                this.setState({
                  onBoarding: 6,
                  indicator: 3,
                });
              }else{
                this.setState(
                  {
                    onBoarding: 11,
                    indicator: 4,
                  },
                  () => {
                    this.props.dispatch(
                      merchantSettlementDetail(this.props.seettlemt_opp_id)
                    );              
                  }
                );
              }
            }
          } 
        }
      }else{
        this.redirectScreen();
      }
    })
    .catch(error => {
     this.redirectScreen();
    })
  };

  localScreenRedirect = (screen) => {
    let indication;
    if(screen == 22 || screen == 0 || screen == 1 || screen == 4 || screen == 3){
      indication = 1;
    }else if(screen == 5 || screen == 7){
      indication = 2;
      if(screen == 5){
        this.getDocumentImage();
      }
    }else if(screen == 6){
      indication = 3;
      let payload={
        // merchant_productid: this.state.merchant_id,
        merchant_productid: this.state.merchant_id,
        user_sfid:this.state.sfid,
        opp_id: this.props.opp_id,
      }
      // if(localStorage.getItem("user_id")){
      //   payload['user_sfid']=localStorage.getItem("sfid")
      // }
      // this.props.dispatch(
      //   getPlans({
      //     merchant_productid: this.state.merchant_id,
      //     opp_id: this.props.opp_id,
      //   })
      // );
      this.props.dispatch(
        getPlans(payload)
      );
    }else if(screen == 11){
      indication = 4;
      this.props.dispatch(
        merchantSettlementDetail(this.props.seettlemt_opp_id)
      );              
    }
    this.setState({
      onBoarding: screen,
      indicator: indication
    })
  }

  submitCoDetails = () => {
    const {
      co_email,
      co_first_name,
      co_last_name,
      co_number,
      co_relation,
      co_pan,
    } = this.state;

    let dataObj = {
      mobileNumber: co_number,
      first_name: co_first_name,
      last_name: co_last_name,
      email: co_email,
      pan: co_pan.toUpperCase(),
      relation: co_relation,
      sfid: this.props.lead_id,
    };
    this.props
      .dispatch(addCoApplicant(dataObj))
      .then((response) => {
        this.getLeadProfile();
        if (response.status == "success") {
          this.props.dispatch(getHighLimit(false));
        }
      })
      .catch((error) => {
        // console.log(error, "add co applicant error");
      });
  };

  bankChange = (e) => {
    let bank_details = e.label.split('---')
    this.setState({ bank: bank_details[0], ifsc: bank_details[1] });
  };

  shouldComponentUpdate(nextProps, nextState) {
    //   console.log("active state",this.props.activeSettlement );
    if (
      this.props.uploadAllModalCloseSuccess !=
      nextProps.uploadAllModalCloseSuccess
    ) {
      if (nextProps.uploadAllModalCloseSuccess) {
        this.getDocumentImage();
      }
      return true;
    } else {
      return true;
    }
  }

  handlebank = (name) => {
    this.setState({ bank: name });
  };

  handleSelectPlan = (id) => {
    // this.getPlanData(id);
    this.checkEnachStatus();
    this.setState({ selectedPlan: id });
  };

  updatePaymentPlan = () => {
    let allPlansId = [];
    let { plans } = this.props;
    if (plans.length > 0) {
      for (let i = 0; i < plans.length; i++) {
        allPlansId.push(plans[i].id);
      }
    }
    let obj = {
      // stage: 'Application Pending',
      stage: "Agreement generation/Signoff",
      plan: this.state.selectedPlan,
      sfid: this.props.opp_id,
      payment_plans: allPlansId,
    };
    this.createTransactionApp(obj);
  };

  handlePanChange = (e) => {
    e.persist();
    var regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/i;
    if (e.target.value !== "") {
      if (regex.test(e.target.value)) {
        this.setState({ isValidPan: true });
        this.setState({ [e.target.name]: e.target.value.toUpperCase() });
        this.setState({ errorMsg: "", successMsg: "", isSuccess: 0 });
      } else {
        this.setState({ isValidPan: false });
        this.setState({
          successMsg: "",
          isSuccess: 0,
          errorMsg: "Enter valid pan number!",
          [e.target.name]: e.target.value.toUpperCase(),
        });
      }
    } else {
      this.setState({ isValidPan: true });
      this.setState({ [e.target.name]: e.target.value.toUpperCase() });
      this.setState({ errorMsg: "" });
    }
  };

  initMap = () => {
    this.setState({
      gmapsLoaded: true,
    });
  };

  componentDidMount() {
    this.initMap();
    window.initMap = this.initMap;
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
    $("input[name='checkAll']").change(function () {
      alert();
    });

    if(startTimeOut) {
      clearTimeout(startTimeOut);
    }
  
  }

  // download = (url, filename) => {
  //     fetch(url).then(function(t) {
  //         return t.blob().then((b)=>{
  //             var a = document.createElement("a");
  //             a.href = URL.createObjectURL(b);
  //             a.setAttribute("download", filename);
  //             a.click();
  //         }
  //         );
  //     });
  // }

  saveFile = async () => {
    // saveAs(
    //     "assets/A220325570_nach.pdf",
    //     "example.pdf"
    // );
    let obj = {
      user_sfid: this.props.lead_id,
      opp_sfid: this.props.opp_id,
    };
    let data = { user_sfid: this.props.lead_id };
    this.props.dispatch(downloadNach(data)).then((response) => {
      if (response && response.status && response.status === "success") {
        if (response.getdata) {
          window.open(`${response.getdata.Url}`, "_blank");
          // this.download(`https://${response.getdata.Url}`, "Axis_Bank_PDF_Form.pdf")
        }
      } else {
      }
    });
    await this.handleEnachDownload();
  };

  handleEnachDownload = async () => {
    const { user_id, dispatch } = this.props;
    let data = { user_sfid: this.props.lead_id };
    await dispatch(checkEnachDownload(data)).then((response) => {
      if (response && response.status && response.status === "success") {
        this.setState({ download_status: 1 });
      } else {
        this.setState({ download_status: 1 });
      }
    });
  };

  handleEnachUpload = async () => {
    const { user_id, dispatch } = this.props;
    let data = { user_sfid: this.props.lead_id };
    await dispatch(checkEnachUpload(data)).then((response) => {
      if (response && response.status && response.status === "success") {
        // this.setState({ upload_status: 1, onBoarding: 11 });
        this.props.dispatch(
          updateOpp({
            id: parseInt(localStorage.getItem("L_id")),
            // stagename: "NACH Verification",
            stagename: "Pre-disbursal",
          })
        );
        this.getLeadProfile();
      } else {
        // this.setState({ upload_status: 1, onBoarding: 11 });
      }
    });
  };

  checkEnachStatus = async () => {
    const { user_id, dispatch } = this.props;
    let data = { user_sfid: this.props.lead_id };
    await dispatch(checkEnachStatus(data)).then((response) => {
      if (response && response.status && response.status === "success") {
        const getData = response && response.data ? response.data : null;
        if (getData) {
          const download =
            getData && getData.download_status ? getData.download_status : null;
          const upload =
            getData && getData.upload_status ? getData.upload_status : null;
          this.setState({ download_status: download, upload_status: upload });
        } else {
          this.setState({ download_status: null, upload_status: null });
        }
      }
    });
  };

  // sendAgreement = async () => {
  //     const { opp_sfid, dispatch } = this.props
  //     let data = { user_sfid: this.props.lead_id }
  //     await dispatch(sendAgreementSms(data)).then((response) => {
  //         if (response && response.status && response.status === "success") {

  //         } else {

  //         }
  //     });
  // }

  handleBankAccount = (e) => {
    e.persist();
    this.setState({ acc_no: e.target.value });
    var pattern = new RegExp(/^[a-zA-Z0-9]+$/);

    if (e.target.value !== "") {
      if (!pattern.test(e.target.value)) {
        document.getElementById("acc_no").value = "";
        this.setState({ acc_no: "" });
        this.setState({ isValidAccount: false });
        this.setState({ Errmessage: "Please enter valid account number." });
      } else if (e.target.value.length < 9) {
        this.setState({ isValidAccount: false });
        this.setState({ Errmessage: "Please enter valid account number." });
      } else if (e.target.value.length >= 9) {
        this.setState({ isValidAccount: true, Errmessage: "" });
      }
    } else {
      this.setState({ isValidAccount: true, Errmessage: "" });
    }
  };
  handleAccountName = (e) => {
    if (e.target.name == "acc_name") {
      var hasNumber = /\d/;
      if (!hasNumber.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value });

        this.setState({ isErrorName: false, errorname: "" });
      } else {
        this.setState({
          isErrorName: true,
          errorname: "Please enter valid name",
        });
      }
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  handleStatementNext = async () => {
    const { dispatch } = this.props;
    let data = { user_sfid: this.props.sfid };
    await dispatch(updateIpabureau(data))
      .then(
        async (response) => {
          if (response && response.status && response.status === "success") {
            let obj = {
              user_sfid: this.props.lead_id,
              opp_sfid: this.props.opp_id,
            };
            await this.props.dispatch(getLeadProfile(obj)).then((response) => {
              if (response.status === "success") {
                const getData = response.data;
                if (getData && getData.ipa_basic_bureau__c) {
                  this.openSuccess();
                  this.handleClearMessage();
                  this.getUserAddress();
                  this.setState({ onBoarding: 2 });
                } else {
                  this.setState({ onBoarding: 14 });
                }
              } else {
                this.setState({ onBoarding: 14 });
              }
            });
          } else {
            this.setState({ onBoarding: 14 });
          }
        },
        (error) => {
          if (error) {
            // console.log("error Faild Called");
            //  this.setState({onBoarding : 14});
          }
        }
      )
      .catch((error) => {
        if (error) {
          // console.log("Catch Faild Called");
          // this.setState({onBoarding : 14});
        }
      });
  };

  handleKycNext = async () => {
    const { dispatch } = this.props;
    let obj = {
      user_sfid: this.props.lead_id,
      opp_sfid: this.props.opp_id,
    };
    // this.props.dispatch(
    //   updateOpp({
    //     id: parseInt(localStorage.getItem("L_id")),
    //     stagename: "KYC Verification",
    //   })
    // );
    this.getLeadProfile(7);
    // await dispatch(getLeadProfile(obj)).then((response) => {
    //     if (response.status === "success") {
    //         // const getData = response.data;
    //         // if (getData && getData.is_bank_detail_verified__c) {
    //         //     this.setState({ onBoarding: 6 });
    //         // } else {
    //         //     this.setState({ onBoarding: 7 });
    //         // }
    //     }
    //     // else {
    //     //     this.setState({ onBoarding: 7 });
    //     // }
    // }, (error) => {
    //     console.log(error);
    //     // this.setState({ onBoarding: 7 });
    // }).catch((error) => {
    //     console.log(error);
    //     // this.setState({ onBoarding: 7 });
    // });
  };

  handlePlacesChange = (address) => {
    this.setState({ address });
  };

  closeModel = () => {
    this.props.dispatch(closeLeadProfileModel());
    this.props.dispatch(UploadAllModalCloseFailure());
    this.props.dispatch(UserClickedOnce(false))
  };

  openImageEdit = (value, tab) => {
    let data = {
      value: value,
      tab: tab,
    };
    this.props.dispatch(openImageEditModel(data));
  };

  openEnachModel = () => {
    this.props.dispatch(openEnach());
  };

  closeEnachModel = () => {
    this.props.dispatch(closeEnach());
  };

  openDropModel = () => {
    this.props.dispatch(openDocumentDropModel());
  };

  openSuccess = () => {
    this.props.dispatch(openSuccessModel());
  };

  showToast = () => {
    toast("I am Tostify!");
  };

  successToast = (message) => {
    toast.success(message);
  };

  errorToast = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  checkLiveness = (file) => {
    const { dispatch, lead_id, sfid } = this.props;
    let isValid = false;
    var formdata = new FormData();
    formdata.append("files", file);
    formdata.append("user_sfid", lead_id);
    return dispatch(checkLiveliness(formdata)).then((response) => {
      return response;
    });
  };

  // getPlanData = async (id) => {
  //     const { dispatch } = this.props
  //     let data = {
  //         plan_id: id
  //     }

  //     // await dispatch(getPlanById(data)).then((response) => {
  //     //     if (response.status === "success") {
  //     //         const getData = response.data ? response.data : null;
  //     //         this.setState({ planData: getData, downpayment: getData.down_payment__c ? getData.down_payment__c : 0 });
  //     //     }
  //     // })
  //     await dispatch(getPaymentPlan({merchant_productid: "a1sC30000001emgIAA"})).then((response) => {
  //         if (response.message === "success") {
  //             console.log(response.paymentPlan[0], response.paymentPlan[0].id,'>>>>>> get data by merchant id is')
  //             this.setState({
  //                 plansSfid: response.paymentPlan[0].id
  //             })
  //             // const getData = response.data ? response.data : null;
  //             // this.setState({ planData: getData, downpayment: getData.down_payment__c ? getData.down_payment__c : 0 });
  //         }
  //     })
  // }

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => results[0])
      .then((getData) => {
        const address = getData.formatted_address;
        let addressArray = getData.address_components;
        let city = this.getCity(addressArray);
        let state = this.getState(addressArray);
        let pincode = this.getPincode(addressArray);
        this.setState({
          address: address ? address : "",
          city: city ? city : "",
          state: state ? state : "",
          address_pin: pincode ? pincode : "",
          pincode: pincode ? pincode : "",
        });
      })
      .catch((error) => console.error("Error", error));
  };

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_3" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  getPincode = (addressArray) => {
    let pincode = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "postal_code" === addressArray[i].types[0]
        ) {
          pincode = addressArray[i].long_name;
          return pincode;
        }
      }
    }
  };

  onNextBording = (next) => {
    if (next == "10") {
      this.props.dispatch(
        merchantSettlementDetail(this.props.seettlemt_opp_id)
      );
    }
    this.setState({
      onBoarding: next,
      successMsg: "",
      isSuccess: 0,
      errorMsg: "",
      isValidPan: false,
    });
    if (next === 5) {
      //     this.getLeadsKycData();
      this.getDocumentImage();
    }
  };

  getLeadProfile = (screenStage) => {
    let data = {
      user_sfid: this.props.lead_id,
      opp_sfid: this.props.opp_id,
    };
    this.props.dispatch(getLeadProfile(data)).then((response) => {
      if (response.status === "success") {
        let getData = response.data;
        let account_profile = getData.account_profile
          ? getData.account_profile
          : "";
        let gender = getData.gender__c ? getData.gender__c.toLowerCase() : "";
        this.setState(
          {
            limit: getData.ipa_basic_bureau__c
              ? getData.ipa_basic_bureau__c
              : 0,
            lead_sfid: getData.sfid ? getData.sfid : "",
            username: getData.first_name__c + " " + getData.last_name__c,
            userstatus: response.account_status__c
              ? response.account_status__c
              : "PENDING",
            sfid: getData.sfid ? getData.sfid : "",
            product: response.rowData.product_name
              ? response.rowData.product_name
              : "-",
            email: getData.email__c,
            mobile: getData.phone,
            pan: getData.pan_number__c,
            card: getData.pan_number__c ? getData.pan_number__c : "",
            dob: this.convertDOB(getData.date_of_birth_applicant__c),
            loan_amount: response.rowData.loan_amount
              ? response.rowData.loan_amount
              : "-",
            payment_plan_is: response.rowData.payment_plan__c
              ? response.rowData.payment_plan__c
              : null,
            pincode: getData.approved_pin_code__c
              ? getData.approved_pin_code__c.toString()
              : "",
            gender: gender,
            selectedAddress: account_profile
              ? account_profile.current_address
              : 0,
            rent_amount: getData.rent_amount__c,
            house: getData.rent_amount__c ? 2 : 1,
            profession: getData.employer_type__c,
            monthly_income: getData.monthly_income__c
              ? getData.monthly_income__c
              : "",
            monthlyIncome: getData.monthly_income__c
              ? getData.monthly_income__c
              : "",
            family_income: getData.family_income__c
              ? getData.family_income__c
              : "",
            company_name: getData.employer_name__c
              ? getData.employer_name__c
              : "",
            isPanVerified: getData.is_pan_document_verified__c,
            isProfileVerified: getData.is_photo_verified__c,
            isAddressDocVerified: getData.is_address_document_verified__c,
            stage: getData.limit_application_stage__c
              ? getData.limit_application_stage__c
              : null,
            mandate_signed_is: response.rowData.mandate_signed
              ? response.rowData.mandate_signed
              : null,
            co_first_name: response.rowData.coBorrower_first_name__c
              ? response.rowData.coBorrower_first_name__c
              : "",
            co_last_name: response.rowData.coBorrower_last_name__c
              ? response.rowData.coBorrower_last_name__c
              : "",
            co_pan: response.rowData.coBorrower_pan_number__c
              ? response.rowData.coBorrower_pan_number__c
              : "",
            co_email: response.rowData.coBorrower_email__c
              ? response.rowData.coBorrower_email__c
              : "",
            co_number: response.rowData.coBorrower_phone
              ? response.rowData.coBorrower_phone
              : "",
            co_relation: response.rowData.coBorrower_relation
              ? response.rowData.coBorrower_relation
              : "",
            merchant_id: response.rowData.merchant_product__c,
            customer_idIs: getData.customer_id__c ? getData.customer_id__c : "-",
            acc_no: getData.bank_account_number__c,
            ifsc: getData.ifsc__c,
            bankSelected: getData.bank_name__c,
            bank: getData.bank_name__c,
          },
          () => {
            if(this.props.clickedOnce){
              this.localScreenRedirect(screenStage)
            }else{
              this.screenRedirect();
              this.props.dispatch(UserClickedOnce(true))
            }
          }
        );
      }
    });
  };

  dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  convertDOB = (date) => {
    if(!date){
      return null;
    }
    const newDate = new Date(date);
    let year = newDate.getFullYear();
    let month = parseInt(newDate.getUTCMonth())+1
    month = month < 10 ? '0'+month : month; 
    let day = newDate.getDate() < 10 ? '0'+newDate.getDate() : newDate.getDate();
    return year+'-'+month+'-'+day
  }

  getPhotoDoc = () => {
    let proData = {
      sfid: this.props.lead_id,
      doc_type: "Photo",
    };
    this.props.dispatch(getDocData(proData)).then((response) => {
      if (response.status === "success") {
        let getData = response.imageData;
        if (getData.length > 0) {
          var img = getData[0];
          this.setState({
            profileBase: "data:image/jpg;base64," + img.base64.base64,
            profileType: "image/jpg",
            profile: this.dataURLtoFile(
              "data:image/jpg;base64," + img.base64.base64,
              img.title
            ),
            // profileId: getData.id
          });
        }
        // if (getData.profile !== undefined && getData.profile !== "") {
        // }
      }
    });
  };

  getBankDoc = () => {
    let proData = {
      sfid: this.props.lead_id,
      doc_type: "Financial",
    };
    this.props.dispatch(getDocData(proData)).then((response) => {
      if (response.status === "success") {
        let getData = response.imageData;
        if (getData.length > 0) {
          var img = getData[0];
          this.setState({
            bankSrc: [img.base64.base64],
            // profileType: "image/jpg",
            // profile: this.dataURLtoFile("data:image/jpg;base64," + img.base64.base64, img.title)
            // profileId: getData.id
          });
        }
        // if (getData.profile !== undefined && getData.profile !== "") {
        // }
      }
    });
  };

  getPanDoc = () => {
    let proData = {
      sfid: this.props.lead_id,
      doc_type: "PAN",
    };
    this.props.dispatch(getDocData(proData)).then((response) => {
      if (response.status === "success") {
        let getData = response.imageData;
        let type = 0;
        let DocBase = "";
        if (getData.length > 0) {
          var img = getData[0];
          if (img.filetype == "PDF") {
            type = 2;
            DocBase = "data:application/pdf;base64," + img.base64.base64;
          } else {
            type = 1;
            DocBase = "data:image/jpg;base64," + img.base64.base64;
          }
          this.setState({
            panFileType: type,
            panBase: DocBase,
            panType: img.filetype,
            // panId: resData.id
            // profileId: getData.id
          });
        }
        // if (getData.pan_front !== undefined && getData.pan_front !== "") {
        //     let resData = getData.pan_front;

        //     if (resData.formate !== null) {
        //         if (resData.formate === "application/pdf") {
        //             type = 2;
        //             DocBase = "data:application/pdf;base64," + resData.base;
        //         } else {
        //             type = 1;
        //             DocBase = "data:image/jpg;base64," + resData.base;
        //         }

        //     }
        //     this.setState({
        //         panFileType: type,
        //         panBase: DocBase,
        //         panType: resData.formate,
        //         panId: resData.id
        //     });
        // }
      }
    });
  };

  getOtherDoc = () => {
    const { selectedTab } = this.state;
    // selectedTab == 1 ? 'Aadhaar Card' : this.state.selectedTab == 2 ? 'Driving License' : this.state.selectedTab == 3 ? 'Voter ID' : 'Passport'
    let proData = {
      sfid: this.props.lead_id,
      doc_type:
        selectedTab == 1
          ? "Aadhar"
          : this.state.selectedTab == 2
            ? "DL"
            : this.state.selectedTab == 3
              ? "VoterId"
              : "Passport",
    };
    this.props.dispatch(getDocData(proData)).then((response) => {
      if (response.status === "success") {
        let getData = response.imageData;
        if (getData.length == 0) {
          return;
        }
        for (let i = 0; i < getData.length; i++) {
          const element = getData[i];
          let FB = element.document_type.split(" ")[1];
          let type = 0;
          let DocBase = "";
          if (element.type == "PDF") {
            type = 2;
            DocBase = "data:application/pdf;base64," + element.base64.base64;
          } else {
            type = 1;
            DocBase = "data:image/jpg;base64," + element.base64.base64;
          }
          if (FB == "Front") {
            this.setState({
              frontFileType: type,
              frontProofBase: DocBase,
              selectedTab: 1,
              defaultTab: 1,
              frontProofType: element.type,
              adharFrontBase64:element.base64.base64,
              // frontId: resData.id
            });
          } else {
            this.setState({
              backFileType: type,
              backProofBase: DocBase,
              selectedTab: 1,
              defaultTab: 1,
              backProofType: element.type,
              adharBackBase64:element.base64.base64
              // backId: resData.id
            });
          }
        }
        // if (getData.aadhar_front !== undefined && getData.aadhar_front !== "") {
        //     let resData = getData.aadhar_front;
        //     let type = 0;
        //     let DocBase = ""
        //     if (resData.formate !== null) {
        //         if (resData.formate === "application/pdf") {
        //             type = 2;
        //             DocBase = "data:application/pdf;base64," + resData.base;
        //         } else {
        //             type = 1;
        //             DocBase = "data:image/jpg;base64," + resData.base;
        //         }

        //     }
        //     this.setState({
        //         frontFileType: type,
        //         frontProofBase: DocBase,
        //         selectedTab: 1,
        //         defaultTab: 1,
        //         frontProofType: resData.formate ? resData.formate : '',
        //         frontId: resData.id
        //     });
        // } else if (getData.driving_front !== undefined && getData.driving_front !== "") {
        //     let resData = getData.driving_front;
        //     let type = 0;
        //     let DocBase = ""
        //     if (resData.formate !== null) {
        //         if (resData.formate === "application/pdf") {
        //             type = 2;
        //             DocBase = "data:application/pdf;base64," + resData.base;
        //         } else {
        //             type = 1;
        //             DocBase = "data:image/jpg;base64," + resData.base;
        //         }

        //     }
        //     this.setState({
        //         frontFileType: type,
        //         frontProofBase: DocBase,
        //         selectedTab: 2,
        //         defaultTab: 2,
        //         frontProofType: resData.formate ? resData.formate : '',
        //         frontId: resData.id
        //     });
        // } else if (getData.voter_front !== undefined && getData.voter_front !== "") {
        //     let resData = getData.voter_front;
        //     let type = 0;
        //     let DocBase = ""
        //     if (resData.formate !== null) {
        //         if (resData.formate === "application/pdf") {
        //             type = 2;
        //             DocBase = "data:application/pdf;base64," + resData.base;
        //         } else {
        //             type = 1;
        //             DocBase = "data:image/jpg;base64," + resData.base;
        //         }

        //     }
        //     this.setState({
        //         frontFileType: type,
        //         frontProofBase: DocBase,
        //         selectedTab: 3,
        //         defaultTab: 3,
        //         frontProofType: resData.formate ? resData.formate : '',
        //         frontId: resData.id
        //     });
        // } else if (getData.passport_front !== undefined && getData.passport_front !== "") {
        //     let resData = getData.passport_front;
        //     let type = 0;
        //     let DocBase = ""
        //     if (resData.formate !== null) {
        //         if (resData.formate === "application/pdf") {
        //             type = 2;
        //             DocBase = "data:application/pdf;base64," + resData.base;
        //         } else {
        //             type = 1;
        //             DocBase = "data:image/jpg;base64," + resData.base;
        //         }

        //     }
        //     this.setState({
        //         frontFileType: type,
        //         frontProofBase: DocBase,
        //         selectedTab: 4,
        //         defaultTab: 4,
        //         frontProofType: resData.formate ? resData.formate : '',
        //         frontId: resData.id
        //     });
        // }

        // if (getData.aadhar_back !== undefined && getData.aadhar_back !== "") {
        //     let resData = getData.aadhar_back;
        //     let type = 0;
        //     let DocBase = ""
        //     if (resData.formate !== null) {
        //         if (resData.formate === "application/pdf") {
        //             type = 2;
        //             DocBase = "data:application/pdf;base64," + resData.base;
        //         } else {
        //             type = 1;
        //             DocBase = "data:image/jpg;base64," + resData.base;
        //         }

        //     }
        //     this.setState({
        //         backFileType: type,
        //         backProofBase: DocBase,
        //         selectedTab: 1,
        //         defaultTab: 1,
        //         backProofType: resData.formate ? resData.formate : '',
        //         backId: resData.id
        //     });
        // } else if (getData.driving_back !== undefined && getData.driving_back !== "") {
        //     let resData = getData.driving_back;
        //     let type = 0;
        //     let DocBase = ""
        //     if (resData.formate !== null) {
        //         if (resData.formate === "application/pdf") {
        //             type = 2;
        //             DocBase = "data:application/pdf;base64," + resData.base;
        //         } else {
        //             type = 1;
        //             DocBase = "data:image/jpg;base64," + resData.base;
        //         }

        //     }
        //     this.setState({
        //         backProofBase: DocBase,
        //         backFileType: type,
        //         selectedTab: 2,
        //         defaultTab: 2,
        //         backProofType: resData.formate ? resData.formate : '',
        //         backId: resData.id
        //     });
        // } else if (getData.voter_back !== undefined && getData.voter_back !== "") {
        //     let resData = getData.voter_back;
        //     let type = 0;
        //     let DocBase = ""
        //     if (resData.formate !== null) {
        //         if (resData.formate === "application/pdf") {
        //             type = 2;
        //             DocBase = "data:application/pdf;base64," + resData.base;
        //         } else {
        //             type = 1;
        //             DocBase = "data:image/jpg;base64," + resData.base;
        //         }

        //     }
        //     this.setState({
        //         backFileType: type,
        //         backProofBase: DocBase,
        //         selectedTab: 3,
        //         defaultTab: 3,
        //         backProofType: resData.formate ? resData.formate : '',
        //         backId: resData.id
        //     });
        // } else if (getData.passport_back !== undefined && getData.passport_back !== "") {
        //     let resData = getData.passport_back;
        //     let type = 0;
        //     let DocBase = ""
        //     if (resData.formate !== null) {
        //         if (resData.formate === "application/pdf") {
        //             type = 2;
        //             DocBase = "data:application/pdf;base64," + resData.base;
        //         } else {
        //             type = 1;
        //             DocBase = "data:image/jpg;base64," + resData.base;
        //         }

        //     }
        //     this.setState({
        //         backFileType: type,
        //         backProofBase: DocBase,
        //         selectedTab: 4,
        //         defaultTab: 4,
        //         backProofType: resData.formate ? resData.formate : '',
        //         backId: resData.id
        //     });
        // }
      }
    });
  };

  getDocumentImage = () => {
    this.getPhotoDoc();
    this.getPanDoc();
    this.getOtherDoc();
    this.getBankDoc();
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.lead_profile_show !== this.props.lead_profile_show &&
      this.props.lead_profile_show === true
    ) {
      let data = {
        user_sfid: this.props.lead_id,
        opp_sfid: this.props.opp_id,
      };

      // this.props.dispatch(getPlans(data));
      // this.props.dispatch(getPlans({merchant_productid: this.state.merchant_id}));
      this.props.dispatch(getBanks());
      this.setState(initial);
      this.initMap();
      window.initMap = this.initMap;
      this.setState({ isLoader: true });
      this.props.dispatch(getLeadProfile(data)).then((response) => {
        this.setState({ isLoader: false });

        if (response.status === "success") {
          let getData = response.data;
          //  let onBoard = !getData.pan_number__c?13:!getData.pan_verified__c?0:getData.account_profile.onbording;
          let account_profile = getData.account_profile
            ? getData.account_profile
            : "";
          let gender = getData.gender__c ? getData.gender__c.toLowerCase() : "";
          const notiDet = response.notification ? response.notification : null;
          const notiStatus =
            notiDet && notiDet.status__c ? notiDet.status__c : null;
          // let userOnBord = 0;
          // this.setState({ lead_sfid: getData.sfid ? getData.sfid : '' });
          // if (notiStatus && notiStatus == "pending") {
          //     userOnBord = 20;
          // } else if (getData.account_status__c === "Full User") {
          //     userOnBord = 6;
          // } else if (!getData.pan_number__c) {
          //     userOnBord = 13;
          // } else if (getData.pan_number__c && !getData.pan_verified__c) {
          //     userOnBord = 0;
          // } else if (!getData.is_qde_1_form_done__c) {
          //     userOnBord = 1;
          // } else if (!getData.ipa_basic_bureau__c && getData.pan_verified__c) {
          //     userOnBord = 19;
          // } else if (getData.ipa_basic_bureau__c && !getData.pan_verified__c) {
          //     userOnBord = 14;
          // } else if (!getData.ipa_basic_bureau__c && !getData.pan_verified__c) {
          //     userOnBord = 15;
          // } else if (!getData.is_qde_2_form_done__c) {
          //     this.getUserAddress();
          //     userOnBord = 2;
          // } else if (!getData.is_kyc_document_verified__c) {
          //     this.getLeadsKycData();
          //     userOnBord = 5;
          // } else if (!getData.is_bank_detail_verified__c) {
          //     userOnBord = 7;
          // } else {
          //     userOnBord = 6;
          // }
          this.setState(
            {
              limit: getData.ipa_basic_bureau__c
                ? getData.ipa_basic_bureau__c
                : 0,
              lead_sfid: getData.sfid ? getData.sfid : "",
              username: getData.first_name__c + " " + getData.last_name__c,
              userstatus: getData.account_status__c
                ? getData.account_status__c
                : "PENDING",
              sfid: getData.sfid ? getData.sfid : "02398123",
              product: response.rowData.product_name
                ? response.rowData.product_name
                : "-",
              email: getData.email__c,
              mobile: getData.phone,
              pan: getData.pan_number__c,
              card: getData.pan_number__c ? getData.pan_number__c : "",
              dob: this.convertDOB(getData.date_of_birth_applicant__c),
              loan_amount: response.rowData.loan_amount
                ? response.rowData.loan_amount
                : "-",
              payment_plan_is: response.rowData.payment_plan__c
                ? response.rowData.payment_plan__c
                : null,
              mandate_signed_is: response.rowData.mandate_signed
                ? response.rowData.mandate_signed
                : null,
              pincode: getData.approved_pin_code__c
                ? getData.approved_pin_code__c.toString()
                : "",
              gender: gender,
              selectedAddress: account_profile
                ? account_profile.current_address
                : 0,
              // onBoarding: userOnBord,
              //onBoarding: 6,
              rent_amount: getData.rent_amount__c,
              house: getData.rent_amount__c ? 2 : 1,
              profession: getData.employer_type__c,
              monthly_income: getData.monthly_income__c
                ? getData.monthly_income__c
                : "",
              monthlyIncome: getData.monthly_income__c
                ? getData.monthly_income__c
                : "",
              family_income: getData.family_income__c
                ? getData.family_income__c
                : "",
              company_name: getData.employer_name__c
                ? getData.employer_name__c
                : "",
              isPanVerified: getData.is_pan_document_verified__c,
              isProfileVerified: getData.is_photo_verified__c,
              isAddressDocVerified: getData.is_address_document_verified__c,
              stage: getData.limit_application_stage__c
                ? getData.limit_application_stage__c
                : null,
              co_first_name: response.rowData.coBorrower_first_name__c
                ? response.rowData.coBorrower_first_name__c
                : "",
              co_last_name: response.rowData.coBorrower_last_name__c
                ? response.rowData.coBorrower_last_name__c
                : "",
              co_pan: response.rowData.coBorrower_pan_number__c
                ? response.rowData.coBorrower_pan_number__c
                : "",
              co_email: response.rowData.coBorrower_email__c
                ? response.rowData.coBorrower_email__c
                : "",
              co_number: response.rowData.coBorrower_phone
                ? response.rowData.coBorrower_phone
                : "",
              co_relation: response.rowData.coBorrower_relation
                ? response.rowData.coBorrower_relation
                : "",
              merchant_id: response.rowData.merchant_product__c,
              customer_idIs: getData.customer_id__c ? getData.customer_id__c : "-",
              acc_no: getData.bank_account_number__c,
              ifsc: getData.ifsc__c,
              bankSelected: getData.bank_name__c,
              bank: getData.bank_name__c,
            },
            () => {
              if(this.props.clickedOnce){
                this.localScreenRedirect()
              }else{
                this.screenRedirect();
                this.props.dispatch(UserClickedOnce(true))
              }
            }
          );
        }
      });

      this.props.dispatch(getBankDetails(data)).then((response) => {
        if (response.status === "success") {
          const getData = response.data ? response.data : null;
          this.setState({
            ifsc: getData && getData.ifsc__c ? getData.ifsc__c : "",
            acc_name:
              getData && getData.bank_account_holder_name__c
                ? getData.bank_account_holder_name__c
                : "",
            acc_no:
              getData && getData.account_number__c
                ? parseInt(getData.account_number__c)
                : "",
            bank: getData && getData.bank_name__c ? getData.bank_name__c : "",
          });
        }
      });
      this.getProductDetails();
    }

    // if (prevProps.document_drop_show !== this.props.document_drop_show) {
    //     this.getDocumentImage();
    // }
  }

  getProductDetails = async () => {
    let objData = { user_sfid: this.props.lead_id };
    await this.props.dispatch(getUserProduct(objData)).then((response) => {
      if (response.status === "success") {
        const getData = response.data;
        this.setState({ productData: getData });
      }
    });
  };

  async profileDataURLtoFile(dataurl, filename) {
    return new Promise((resolve, reject) => {
      try {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const profile = new File([u8arr], filename, { type: mime });
        this.setState({ profile: profile });
        resolve(true);
      } catch (err) {
        reject(err.message ? err.message : err);
      }
    });
  }

  handleClearMessage = () => {
    this.setState({
      isSuccess: 0,
      successMsg: "",
      isValidPan: true,
      errorMsg: "",
      isProfileSuccess: "",
      profileSuccessMsg: "",
    });
  };

  updatePanStatus = () => {
    const { user_id, dispatch } = this.props;
    let data = {
      user_sfid: this.props.lead_id,
      pan: this.state.pan,
    };
    // this.getLeadProfile();
    // this.setState({ onBoarding: 1 })
    dispatch(updatePanStatus(data)).then((response) => {
      if (response.status === "success") {
        //  this.setState({ isSuccess: 1, successMsg: response.message });
        //    this.setState({ onBoarding: 1});
        this.getLeadProfile(1);
        if (response.profiledata && response.profiledata.sfObj) {
          this.setState({
            dob: this.convertDOB(response.profiledata.sfObj.Date_Of_Birth_Applicant__c),
            pincode: response.profiledata.sfObj.Approved_Pin_Code__c,
            gender: response.profiledata.sfObj.Gender__c.toLowerCase(),
          });
        }
        //   gender,pincode,dob
        //   Date_Of_Birth_Applicant__c
        // Gender__c
        //Approved_Pin_Code__c
      } else {
         this.setState({ isSuccess: 0, errorMsg: response.message,successMsg:"",isValidPan:false });
        // this.setState({ onBoarding: 1});
      }
    });
  };

  updatePan = () => {
    const { user_id, dispatch } = this.props;
    let data = {
      user_sfid: this.props.lead_id,
      pan: this.state.pan,
    };
    dispatch(updatePan(data)).then((response) => {
      if (response.status === "success") {
        this.getLeadProfile(1);
      }
    });
  };

  updateProfile = async () => {
    const { dispatch } = this.props;
    const { dob, pincode, gender } = this.state;
    if(!dob && !pincode && !gender){
      this.errorToast("Enter Valid Details");
      return;
    }
    let data = {
      dob: dob,
      gender: gender,
      pin: pincode,
      // id: parseInt(this.props.lead_id)
      //id:localStorage.getItem("L_id")
      user_sfid: this.props.lead_id,
    };    
    await dispatch(updateAccount(data)).then(async (response) => {
      if (response.status === "success") {
        let obj = {
          user_sfid: this.props.lead_id,
          opp_sfid: this.props.opp_id,
        };
        await this.props.dispatch(getLeadProfile(obj)).then((response) => {
          if (response.status === "success") {
            const getData = response.data;
            if (getData && getData.ipa_basic_bureau__c) {
              this.getUserAddress();
              // this.openSuccess();
              this.handleClearMessage();
            } else {
              // this.setState({ onBoarding: 19 });
            }
          } else {
            // this.setState({ onBoarding: 19 });
          }
        });
      } else {
        // this.setState({ onBoarding: 19 });
      }
      this.getLeadProfile(4);
    });
  };

  getUserAddress = () => {
    let addData = {
      user_sfid: this.props.lead_id,
    };
    this.props.dispatch(getAddress(addData)).then((response) => {
      if (response.status === "success") {
        let getData = response.data;
        this.setState({
          addressList: getData,
        });
      }
    });
  };

  updateBank = async () => {
    const { dispatch } = this.props;
    const { acc_name, ifsc, acc_no, bank,bankSelected } = this.state;
    let obj = { account_number: acc_no, ifsc_code: ifsc, user_sfid: this.props.lead_id,account_name: acc_name};
    let data = {
      name: acc_name,
      bank: bank ? bank : bankSelected,
      ifsc: ifsc,
      account_number: acc_no,
      user_sfid: this.props.lead_id,
    };
    await dispatch(checkAccount(obj)).then(async (response) => {
      if (response.status === "success") {
        await dispatch(updateBanks(data)).then((response) => {
          this.getLeadProfile(6);
          dispatch(
            enachPay({ user_sfid: this.props.lead_id, type: "DebitCard" })
          ).then((response) => {
            if (response.status == "success") {
              localStorage.setItem("customer_id", response.data.customer_id);
              localStorage.setItem("order_id", response.data.order_id);
            }
          });
          if (response) {
            this.successToast(response.message);
            // setInterval(this.setState({ onBoarding: 6 }), 15000);
          } else {
            this.errorToast(response.message);
            // setInterval(this.setState({ onBoarding: 18 }), 15000);
          }
        });
      } else {
        this.errorToast(response.message);
        // setInterval(this.setState({ onBoarding: 18 }), 15000);
      }
    });
  };

  submitProfession = () => {
    const { dispatch } = this.props;
    const { company_name, monthly_income, family_income, profession } =
      this.state;
    let obj = {
      user_sfid: this.props.lead_id,
    };
    let employerType = "Salaried";
    if (profession === "1") {
      obj.source = "Salaried";
      obj.compant_name = company_name;
      obj.amount = monthly_income;
      employerType = "Salaried";
    }
    if (profession === "2") {
      obj.source = "Self-Employed-Professional";
      obj.compant_name = company_name;
      obj.isProfessional = 1;
      obj.amount = monthly_income;
      employerType = "Self-Employed-Professional";
    }
    if (profession === "3") {
      obj.source = "Self-Employed-Non Professional";
      obj.compant_name = company_name;
      obj.isProfessional = 2;
      obj.amount = monthly_income;
      employerType = "Self-Employed-Non Professional";
    }
    if (profession === "4") {
      obj.source = "Retired";
      obj.amount = monthly_income;
      obj.compant_name = company_name;
      employerType = "Retired";
    }
    if (profession === "5") {
      obj.source = "Students";
      employerType = "Students";
    }
    let objData = { user_sfid: this.props.lead_id, type: employerType };
    dispatch(updateEmpType(objData));
    dispatch(updateProfession(obj)).then((response) => {
      if (response.status === "success") {
        this.setState({ isSuccess: 1, successMsg: response.message });
        setInterval(this.setState({ isSuccess: "", successMsg: "" }), 15000);
        this.getLeadsKycData();
      } else {
        this.setState({ isSuccess: 0, successMsg: response.message });
      }
    });
    this.getLeadProfile(3);
  };

  startTimer() {
    var presentTime = this.state.timer;
    var timeArray = presentTime.split(/[:]+/);
    var m = timeArray[0];
    var s = this.checkSecond(timeArray[1] - 1);
    if (s == 59) {
      m = m - 1;
    }
    if (m === "00" && s === "00") {
      this.setState({ viewResend: true });
    }
    if (m < 0) {
      return;
    }
    this.setState({ timer: m + ":" + s });
    setTimeout(this.startTimer, 1000);
  }

  handleOtpSend = () => {
    const { user_id, dispatch } = this.props;
    let data = {
      id: parseInt(this.props.lead_id),
    };
    this.startTimer();
    dispatch(sendUserOtp(data)).then((response) => {
      if (response.status === "success") {
        this.setState({ logId: response.logId, isOtpSent: true });
      }
    });
  };

  handleResendSendOtp = () => {
    const { user_id, dispatch } = this.props;
    let data = {
      id: parseInt(this.props.lead_id),
    };
    dispatch(sendUserOtp(data)).then((response) => {
      if (response.status === "success") {
        this.setState({
          logId: response.logId,
          isOtpSent: true,
          viewResend: false,
          timer: "00:18",
        });
        this.startTimer();
      }
    });
  };

  handleOtpChange(value1, event) {
    this.setState({ [value1]: event.target.value, errorMsg: "" });
    if (value1 === "otp4" && event.target.value) {
      this.handleSubmitotp(event.target.value);
    }
  }

  inputfocus = (elmnt, getvalue) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        this.reverseFocueInputS(next);
      }
    } else {
      const pattern = /^[0-9]$/;
      if (pattern.test(elmnt.target.value)) {
        const next = elmnt.target.tabIndex;
        if (next < 4) {
          this.focueInputS(next);
        }
      } else {
        this.setState({ [getvalue]: "" });
        document.getElementById(getvalue).value = "";
      }
    }
  };

  reverseFocueInputS = (next) => {
    if (next === 2) {
      this.textInput3.current.focus();
    } else if (next === 1) {
      this.textInput2.current.focus();
    } else if (next === 0) {
      this.textInput1.current.focus();
    }
  };

  focueInputS = (next) => {
    if (next === 1) {
      this.textInput2.current.focus();
    } else if (next === 2) {
      this.textInput3.current.focus();
    } else if (next === 3) {
      this.textInput4.current.focus();
    }
  };

  checkSecond(sec) {
    if (sec < 10 && sec >= 0) {
      sec = "0" + sec;
    }
    if (sec < 0) {
      sec = "59";
    }
    return sec;
  }

  handleSubmitotp = (otp4) => {
    const { otp1, otp2, otp3 } = this.state;
    const { dispatch } = this.props;
    if (otp1 && otp2 && otp3) {
      const givenOtp = parseInt(
        this.state.otp1 + this.state.otp2 + this.state.otp3 + otp4
      );
      let data = {
        otp: givenOtp,
        logId: this.state.logId,
      };
      dispatch(verifyUserOtp(data)).then((response) => {
        if (response.status === "success") {
          let obj = {
            stage: "Ready to disburse",
            sfid: this.props.opp_id,
          };
          this.createTransactionApp(obj);
          this.setState({
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
          });
          if (this.state.downpayment) {
            this.getUserAddress();
            this.setState({ onBoarding: 12 });
          } else {
            this.getUserAddress();
            this.setState({ onBoarding: 12 });
          }
        } else {
          this.setState({ otpError: true });
        }
      });
    }
  };

  getLeadsKycData = () => {
    let proData = {
      sfid: this.props.lead_id,
    };
    this.props.dispatch(getLeadProfileDocuemnt(proData)).then((response) => {
      if (response.status === "success") {
        const getData = response.data;
        if (getData && getData.base64 !== undefined && getData.base64 !== "") {
          const base = getData && getData.base64 ? getData.base64 : null;
          const base64 = base && base.base64 ? base.base64 : null;
          this.setState({
            profileBase: base64
              ? `data:image/${getData.filetype};base64,` + base64
              : "",
            profileType: `image/${getData.filetype}`,
            profileId: null,
          });
          this.profileDataURLtoFile(
            base64 ? `data:image/${getData.filetype};base64,${base64}` : "",
            "profile.jpg"
          );
        } else {
          this.setState({
            profileBase: "",
            profileType: "",
          });
          const proBase = localStorage.getItem("userBase");
          if (proBase) {
            this.profileDataURLtoFile(
              `data:image/jpg;base64,${proBase}`,
              "profile.jpg"
            );
          }
        }
      }
    });

    this.props.dispatch(getLeadPanDocuemnt(proData)).then((response) => {
      if (response.status === "success") {
        let getData = response.data;
        if (getData.base64 !== undefined && getData.base64 !== "") {
          let resData = getData.base64;
          let type = 0;
          let DocBase = "";
          if (resData.filetype !== null) {
            if (resData.filetype === "PDF") {
              type = 2;
              DocBase = "data:application/pdf;base64," + resData.base64;
            } else {
              type = 1;
              DocBase = "data:image/jpg;base64," + resData.base64;
            }
          }
          this.setState({
            panFileType: type,
            panBase: DocBase,
            panType: resData.filetype ? resData.filetype : "",
            panId: resData.id,
          });
        } else {
          this.setState({ panBase: "", panType: "" });
        }
      }
    });

    this.props.dispatch(getLeadOtherDocuemnt(proData)).then((response) => {
      if (response.status === "success") {
        let getData = response;

        if (
          getData &&
          getData.aadharfrontdata &&
          getData.aadharfrontdata.length !== 0
        ) {
          let resData =
            getData && getData.aadharfrontdata && getData.aadharfrontdata.base64
              ? getData.aadharfrontdata.base64
              : "";
          let type = 0;
          let DocBase = "";
          if (getData.aadharfrontdata.filetype !== null) {
            if (getData.aadharfrontdata.filetype === "PDF") {
              type = 2;
              DocBase = "data:application/pdf;base64," + resData.base64;
            } else {
              type = 1;
              DocBase = "data:image/jpg;base64," + resData.base64;
            }
          }
          this.setState({
            frontFileType: type,
            frontProofBase: DocBase,
            selectedTab: 1,
            defaultTab: 1,
            frontProofType: getData.aadharfrontdata.filetype
              ? getData.aadharfrontdata.filetype
              : "",
            frontId: resData.id,
          });
        } else if (
          getData &&
          getData.driving_front &&
          getData.driving_front !== undefined &&
          getData.driving_front !== ""
        ) {
          let resData = getData.driving_front;
          let type = 0;
          let DocBase = "";
          if (resData.formate !== null) {
            if (resData.formate === "application/pdf") {
              type = 2;
              DocBase = "data:application/pdf;base64," + resData.base;
            } else {
              type = 1;
              DocBase = "data:image/jpg;base64," + resData.base;
            }
          }
          this.setState({
            frontFileType: type,
            frontProofBase: DocBase,
            selectedTab: 2,
            defaultTab: 2,
            frontProofType: resData.formate ? resData.formate : "",
            frontId: resData.id,
          });
        } else if (
          getData &&
          getData.voterfrontdata &&
          getData.voterfrontdata.length !== 0
        ) {
          let resData =
            getData && getData.voterfrontdata && getData.voterfrontdata.base64
              ? getData.voterfrontdata.base64
              : "";
          let type = 0;
          let DocBase = "";
          if (getData.voterfrontdata.filetype !== null) {
            if (getData.voterfrontdata.filetype === "PDF") {
              type = 2;
              DocBase = "data:application/pdf;base64," + resData.base64;
            } else {
              type = 1;
              DocBase = "data:image/jpg;base64," + resData.base64;
            }
          }
          this.setState({
            frontFileType: type,
            frontProofBase: DocBase,
            selectedTab: 3,
            defaultTab: 3,
            frontProofType: getData.voterfrontdata.filetype
              ? getData.voterfrontdata.filetype
              : "",
            frontId: resData.id,
          });
        } else if (
          getData &&
          getData.passport &&
          getData.passport.length !== 0
        ) {
          let resData =
            getData && getData.passport && getData.passport.base64
              ? getData.passport.base64
              : "";
          let type = 0;
          let DocBase = "";
          if (getData.passport.filetype !== null) {
            if (getData.passport.filetype === "PDF") {
              type = 2;
              DocBase = "data:application/pdf;base64," + resData.base64;
            } else {
              type = 1;
              DocBase = "data:image/jpg;base64," + resData.base64;
            }
          }
          this.setState({
            frontFileType: type,
            frontProofBase: DocBase,
            selectedTab: 4,
            defaultTab: 4,
            frontProofType: getData.passport.filetype
              ? getData.passport.filetype
              : "",
            frontId: resData.id,
          });
        } else {
          this.setState({
            frontProofBase: "",
            selectedTab: 1,
            defaultTab: 0,
            frontProofType: "",
          });
        }

        if (
          getData &&
          getData.aadharbackdata &&
          getData.aadharbackdata.length !== 0
        ) {
          let resData =
            getData && getData.aadharbackdata && getData.aadharbackdata.base64
              ? getData.aadharbackdata.base64
              : "";
          let type = 0;
          let DocBase = "";
          if (getData.aadharbackdata.filetype !== null) {
            if (getData.aadharbackdata.filetype === "PDF") {
              type = 2;
              DocBase = "data:application/pdf;base64," + resData.base64;
            } else {
              type = 1;
              DocBase = "data:image/jpg;base64," + resData.base64;
            }
          }
          this.setState({
            backFileType: type,
            backProofBase: DocBase,
            selectedTab: 1,
            defaultTab: 1,
            backProofType: getData.aadharbackdata.filetype
              ? getData.aadharbackdata.filetype
              : "",
            backId: resData.id,
          });
        } else if (
          getData &&
          getData.driving_back &&
          getData.driving_back !== undefined &&
          getData.driving_back !== ""
        ) {
          let resData = getData.driving_back;
          let type = 0;
          let DocBase = "";
          if (resData.formate !== null) {
            if (resData.formate === "application/pdf") {
              type = 2;
              DocBase = "data:application/pdf;base64," + resData.base;
            } else {
              type = 1;
              DocBase = "data:image/jpg;base64," + resData.base;
            }
          }
          this.setState({
            backFileType: type,
            backProofBase: DocBase,
            selectedTab: 2,
            defaultTab: 2,
            backProofType: resData.formate ? resData.formate : "",
            backId: resData.id,
          });
        } else if (
          getData &&
          getData.voterbackdata &&
          getData.voterbackdata.length !== 0
        ) {
          let resData =
            getData && getData.voterbackdata && getData.voterbackdata.base64
              ? getData.voterbackdata.base64
              : "";
          let type = 0;
          let DocBase = "";
          if (getData.voterbackdata.filetype !== null) {
            if (getData.voterbackdata.filetype === "PDF") {
              type = 2;
              DocBase = "data:application/pdf;base64," + resData.base64;
            } else {
              type = 1;
              DocBase = "data:image/jpg;base64," + resData.base64;
            }
          }
          this.setState({
            backFileType: type,
            backProofBase: DocBase,
            selectedTab: 3,
            defaultTab: 3,
            backProofType: getData.voterbackdata.filetype
              ? getData.voterbackdata.filetype
              : "",
            backId: resData.id,
          });
        } else if (
          getData &&
          getData.passport_back &&
          getData.passport_back !== undefined &&
          getData.passport_back !== ""
        ) {
          let resData = getData.passport_back;
          let type = 0;
          let DocBase = "";
          if (resData.formate !== null) {
            if (resData.formate === "application/pdf") {
              type = 2;
              DocBase = "data:application/pdf;base64," + resData.base;
            } else {
              type = 1;
              DocBase = "data:image/jpg;base64," + resData.base;
            }
          }
          this.setState({
            backFileType: type,
            backProofBase: DocBase,
            selectedTab: 4,
            defaultTab: 4,
            backProofType: resData.formate ? resData.formate : "",
            backId: resData.id,
          });
        }
      }
    });

    this.props.dispatch(getBankDocuemnt(proData)).then((response) => {
      let getData = response.data;
      if (getData && getData.length > 0) {
        this.setState({ bankSrc: getData });
      }
    });
  };

  updateResident = () => {
    const { dispatch, lead_id } = this.props;
    const { address, state, city, pincode, rent_amount, house } =
      this.state;
    let data = {
      address: address,
      type: "Manual",
      state: state,
      city: city,
      pincode: pincode,
      user_sfid: this.props.lead_id,
    };
    let obj = {
      rent: parseInt(rent_amount),
      user_sfid: this.props.lead_id,
      resident_type: house == 1 ? "Owned" : "Rented",
    };
    dispatch(updateRent(obj));
    dispatch(addAddress(data)).then((response) => {
      if (response.status === "success") {
        this.setState({
          isSuccess: 1,
          successMsg: response.message,
          selectedAddress: response.data,
        });
        setInterval(this.setState({ isSuccess: "", successMsg: "" }), 15000);
        this.getUserAddress();
        this.getLeadProfile(5);
      } else {
        this.setState({ isSuccess: 0, successMsg: response.message });
      }
    });
  };

  addDeliveryAddress = async () => {
    const { dispatch } = this.props;
    const { address, state, city, address_pin } = this.state;
    let data = {
      address: address,
      type: "Manual",
      state: state,
      city: city,
      pincode: address_pin,
      user_sfid: this.props.lead_id,
    };
    await dispatch(addAddress(data)).then(async (response) => {
      if (response.status === "success") {
        if (this.state.addressType === 2) {
          this.setState({
            onBoarding: 12,
            isSuccess: 1,
            successMsg: response.message,
            selectedAddress: response.data,
          });
        } else {
          this.setState({
            onBoarding: 2,
            isSuccess: 1,
            successMsg: response.message,
            selectedAddress: response.data,
          });
        }
        this.getUserAddress();
      } else {
        this.setState({ isSuccess: 0, successMsg: response.message });
      }
    });
  };

  updateLenderAddress = () => {
    const { dispatch } = this.props;
    const { selectedAddress } = this.state;
    let addObj = {
      user_sfid: this.props.lead_id,
      address_id: selectedAddress,
    };
    dispatch(updateAddress(addObj)).then((response) => {
      if (response.status === "success") {
        this.setState({ isSuccess: 1, successMsg: response.message });
        setInterval(this.setState({ isSuccess: "", successMsg: "" }), 15000);
      } else {
        this.setState({ isSuccess: 0, successMsg: response.message });
      }
      this.getLeadProfile();
    });
  };

  updateDeliveryAddress = () => {
    const { dispatch } = this.props;
    const { selectedAddress } = this.state;
    let addObj = {
      user_sfid: this.props.lead_id,
      address_id: selectedAddress,
    };
    dispatch(updateAddress(addObj)).then((response) => {
      if (response.status === "success") {
        this.setState({ isSuccess: 1, successMsg: response.message });
        if (this.state.downpayment) {
          this.props.dispatch(
            merchantSettlementDetail(this.props.seettlemt_opp_id)
          );
          this.setState({ onBoarding: 10, isSuccess: "", successMsg: "" });
        } else {
          this.setState({ onBoarding: 16, isSuccess: "", successMsg: "" });
        }
      } else {
        this.setState({
          onBoarding: 16,
          isSuccess: 0,
          successMsg: response.message,
        });
      }
    });
  };

  getDocumentBase = async () => {
    const { bankDocument } = this.state;
    let bindData = [];
    for (let i = 0; i < bankDocument.length; i++) {
      var reader = new FileReader();
      var url = reader.readAsDataURL(bankDocument[i]);
      reader.onloadend = function (e) {
        if (reader.result) {
          let proBase = reader.result;
          const proBase64 = proBase.replace(`data:application/pdf;base64,`, "");
          bindData.push(proBase64);
        }
      }.bind(this);
    }
    return bindData;
  };

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  submitDocument = async () => {
    const { dispatch } = this.props;
    const {
      backProofBase,
      bankDocument,
      profileType,
      profileBase,
      panType,
      panBase,
      frontProofType,
      frontProofBase,
      backProofType,
    } = this.state;
    let DataType = [];
    const filePathsPromises = [];
    bankDocument.forEach((file) => {
      filePathsPromises.push(this.toBase64(file));
      DataType.push("Bank-Statement");
    });
    const filePaths = await Promise.all(filePathsPromises);
    let mappedFiles = filePaths.map((base64File) =>
      base64File.replace(`data:application/pdf;base64,`, "")
    );
    if (this.state.selectedTab === 1) {
      DataType.push("Aadhar-Front");
      DataType.push("Aadhar-Back");
    } else if (this.state.selectedTab === 2) {
      DataType.push("Driving-Front");
      DataType.push("Driving-Back");
    } else if (this.state.selectedTab === 3) {
      DataType.push("Voter-Front");
      DataType.push("Voter-Back");
    } else {
      DataType.push("Passport-Front");
      DataType.push("Passport-Back");
    }
    let frontBase = frontProofBase;
    const frontBase64 = frontBase.replace(`data:${frontProofType};base64,`, "");
    let backBase = backProofBase;
    const backBase64 = backBase.replace(`data:${backProofType};base64,`, "");
    mappedFiles.push(frontBase64);
    mappedFiles.push(backBase64);
    DataType.push("Pan-Front");
    let panBaseC = panBase;
    const panBase64 = panBaseC.replace(`data:${panType};base64,`, "");
    mappedFiles.push(panBase64);
    let data = {
      base64: mappedFiles,
      doctype: DataType,
      parent_id: this.state.lead_id,
      id: this.props.lead_id,
      is_doc_verified: true,
      token: this.props.salesForceToken,
    };

    dispatch(uploadDocument(data)).then((response) => {
      if (response.status === "success") {
      }
    });
  };

  createTransactionApp = (getData) => {
    const { dispatch } = this.props;
    let id = parseInt(localStorage.getItem("L_id"));
    dispatch(createTransApp(getData)).then((response) => {
      if (response.status == "success") {
        // this.props.dispatch(
        //   updateOpp({ id: id, stagename: "Agreement generation/Signoff" })
        // );
        this.getLeadProfile(11);
      }
    });
  };

  uploadBankDocument = async (bankDocument) => {
    const { dispatch } = this.props;
    const filePathsPromises = [];
    let limit = "";
    await Promise.all(
      bankDocument.map(async (file) => {
        let baseData = await this.toBase64(file);
        let base = baseData.replace(`data:application/pdf;base64,`, "");
        let type = file.type;
        const d = new Date();
        const time = d.getTime();
        let ext = "pdf";
        if (type) {
          const getExt = type.split("/");
          ext = getExt[1];
        }
        let data = {
          statement: file,
          base64: base,
          doctype: "Bank-Statement",
          catType: "Income Proof",
          basetype: file.type,
          fname: "eduvan-web-" + time + "." + ext,
          fileType: "Bank Statement",
          parent_id: this.props.lead_id,
          id: this.props.lead_id,
          token: this.props.salesForceToken,
          sfid: this.props.sfid,
        };
        await dispatch(newBankstatementupload(data)).then((response) => {
          const getData = response;
          if (
            // getData &&
            // getData.file_status !== undefined &&
            // getData.file_status
            getData.status == "Submitted"
          ) {
            filePathsPromises.push(base);
            this.successToast("file uploaded successfully");
            this.setState({ statementUploaded: true });
            // this.props.dispatch(
            //   verifyDoc({
            //     isStatementUpload: true,
            //     is_force_verification: false,
            //     user_sfid: this.props.lead_id,
            //   })
            // );
          } else {
            let msg = "Something Went Wrong";
            this.errorToast(msg);
          }
        });
      })
    );
    if (limit) {
      this.getLeadProfile(6);
      this.openSuccess();
    }
    const filePaths = await Promise.all(filePathsPromises);
    let mappedFiles = filePaths.map((base64File) =>
      base64File.replace(`data:application/pdf;base64,`, "")
    );
    if (mappedFiles && mappedFiles.length > 0) {
      this.setState({ bankSrc: mappedFiles });
    } else {
      this.setState({ bankSrc: "" });
    }
  };

  uploadBackDocument = async (backProofType, backProofBase, file) => {
    const { dispatch } = this.props;
    const tab = this.state.selectedTab;
    const check = await this.checkFraud(file, 2, tab);
    if (check[0]) {
      if(this.state.onBoarding == 3){
        var addKey = check[1].data.result && check[1].data.result[0].details
      }
      let DataType = [];
      let mappedFiles = [];
      let BaseType = [];
      let eduFileType = [];
      if (this.state.selectedTab === 1) {
        DataType.push("Aadhar-Back");
        eduFileType.push("Aadhar Back");
      } else if (this.state.selectedTab === 2) {
        DataType.push("Driving-Back");
        eduFileType.push("Driving License");
      } else if (this.state.selectedTab === 3) {
        DataType.push("Voter-Back");
        eduFileType.push("Voter ID");
      } else {
        DataType.push("Passport-Back");
        eduFileType.push("Passport Back");
      }
      BaseType.push(backProofType);
      let backBase = this.state.adharBackBase64 ? this.state.adharBackBase64 : backProofBase;
      const backBase64 = backBase.replace(`data:${backProofType};base64,`, "");
      mappedFiles.push(backBase64);
      let data = {
        base64: mappedFiles,
        doctype: DataType,
        catType: "ID Proof",
        basetype: BaseType,
        parent_id: this.props.lead_id,
        id: this.props.lead_id,
        fileType: eduFileType,
        is_doc_verified: this.state.backDocAttempt > 2 ? false : true,
        token: this.props.salesForceToken,
      };
      let selectedTab = this.state.selectedTab;
      await dispatch(uploadDocument(data)).then((response) => {
        if (response.status == "success") {
          this.props.dispatch(
            verifyDoc({
              isAadharUpload: true,
              is_force_verification: false,
              user_sfid: this.props.lead_id,
            })
          );
          this.props.dispatch(
            uploadDocAdharBack({
              document_type: "aa",
              doc__type: "bb",
              user_sfid: this.props.lead_id,
            })
          );
          if (this.state.onBoarding == 3 && addKey.address) {
            this.setState({
              isAutoAdd: false,
              address: addKey.address.value ? addKey.address.value : "",
              city: addKey.address.district ? addKey.address.district : "",
              state: addKey.address.state ? addKey.address.state : "",
              address_pin: addKey.address.pin ? addKey.address.pin : "",
              pincode: addKey.address.pin ? addKey.address.pin : "",
              // selectedTab:1,
              // frontProofBase: base64File,
            });
          }
          if (this.state.frontProofBase) {
            this.props.dispatch(
              verifyDoc({
                isKycDone: true,
                is_force_verification: false,
                user_sfid: this.props.lead_id,
              })
            );
          }
          this.getOtherDoc();
        }
      });
      this.setState({ backProofBase: backProofBase, defaultTab: selectedTab });
    }
  };

  panFraudCheck = async (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        const attempt = this.state.panAttempt;
        if (this.state.profile) {
          const { dispatch } = this.props;
          var formdata = new FormData();
          formdata.append("first_image", file);
          formdata.append("sfid", this.props.lead_id);
          formdata.append("second_image", this.state.profile);
          await dispatch(validatePan(formdata)).then(async (response) => {
            const panAttempt = attempt + 1;
            if (response.status == "success") {
              this.successToast("Verified Successfully.");
              this.setState({panVerify:true})
              resolve(true);
            } else {
              if (panAttempt > 2) {
                this.setState({
                  panUploadAttempt: false,
                  errorMsg: "Your pan document not verified still we can moved to backend verification",
                });
                this.errorToast("Your pan document not verified still we can moved to backend verification");
                resolve(true);
              } else {
                this.errorToast(response.message);
                resolve(false);
              }
            }
            this.setState({ panAttempt: panAttempt });
          });
        } else {
          this.errorToast(
            "Your profile picture is not ready, please try after some times!"
          );
          resolve(false);
        }
      } catch (err) {
        reject(err.message ? err.message : err);
      }
    });
  };

  checkFraud = async (file, type, tab) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { dispatch } = this.props;
        const { frontDocAttempt, backDocAttempt } = this.state;
        var formdata = new FormData();
        formdata.append("files", file);
        if (type == 1) {
          if (tab == 1) {
            formdata.append("type", "ADHAR-FRONT");
          } else if (tab == 2) {
            formdata.append("type", "DRIVING-LIICENSE-FRONT");
          } else if (tab == 3) {
            formdata.append("type", "VOTER-ID-FRONT");
          } else {
            formdata.append("type", "PASSPORT");
          }
        } else {
          if (tab == 1) {
            formdata.append("type", "ADHAR-BACK");
          } else if (tab == 2) {
            formdata.append("type", "DRIVING-LIICENSE-BACK");
          } else if (tab == 3) {
            formdata.append("type", "VOTER-ID-BACK");
          } else {
            formdata.append("type", "PASSPORT");
          }
        }
        formdata.append("sfid", this.props.lead_id);
        await dispatch(fraudCheck(formdata)).then(async (response) => {        
          if (response.status && response.status == "success") {
            const res = response.result ? response.result : null;
            const getData = res && res.length > 0 ? res[0] : null;
            const doctype = getData && getData.type ? getData.type : null;
            if (type == 1) {
                this.setState({adharFrontBase64:response.base64Image,frontVerify:true})
              this.successToast("Verification Successful!");
              resolve([true, response]);
            } else {
              this.setState({adharBackBase64:response.base64Image,backVerify:true})
              this.successToast("Verification Successful!");
              resolve([true, response]);
            }
          } else {
            if (type == 1) {
              const doctAttempt = frontDocAttempt + 1;
              this.setState({ frontDocAttempt: doctAttempt });
              if (doctAttempt > 2) {
                this.setState({adharFrontBase64:response.base64Image})
                if (tab == 1) {
                  this.errorToast(
                    "Your adhaar front document not verified still we can moved to backend verification"
                  );
                } else if (tab == 2) {
                  this.errorToast(
                    "Your driving front document not verified still we can moved to backend verification"
                  );
                } else if (tab == 3) {
                  this.errorToast(
                    "Your voter front document not verified still we can moved to backend verification"
                  );
                } else {
                  this.errorToast(
                    "Your passport document not verified still we can moved to backend verification"
                  );
                }
                resolve([true, response]);
              } else {
                this.errorToast(response.message);
                resolve([false, response]);
              }
            } else {
              const doctAttempt = backDocAttempt + 1;
              this.setState({ backDocAttempt: doctAttempt });
              if (doctAttempt > 2) {
                this.setState({adharBackBase64:response.base64Image})
                if (tab == 1) {
                  this.errorToast(
                    "Your adhaar back document not verified still we can moved to backend verification"
                  );
                } else if (tab == 2) {
                  this.errorToast(
                    "Your driving back document not verified still we can moved to backend verification"
                  );
                } else if (tab == 3) {
                  this.errorToast(
                    "Your voter back document not verified still we can moved to backend verification"
                  );
                } else {
                  this.errorToast(
                    "Your passport document not verified still we can moved to backend verification"
                  );
                }
                resolve([true, response]);
              } else {
                this.errorToast(response.message);
                resolve([false, response]);
              }
            }
          }
        });
      } catch (err) {
        reject(err.message ? err.message : err);
      }
    });
  };

  checkFrontFile = async (type) => {
    return new Promise(async (resolve) => {
      const doctype = this.state.selectedTab;
      if (
        doctype == 1 &&
        (type == "aadhaar_front_bottom" || type == "aadhaar_front_top")
      ) {
        resolve(true);
      } else if (doctype == 2) {
        resolve(true);
      } else if (
        doctype == 3 &&
        (type == "voterid_front_new" || type == "voterid_front")
      ) {
        resolve(true);
      } else if (doctype == 4 && type == "passport_front") {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  checkBackFile = async (type) => {
    return new Promise(async (resolve) => {
      const doctype = parseInt(this.state.selectedTab);
      if (doctype == 1 && type == "aadhaar_back") {
        resolve(true);
      } else if (doctype == 2) {
        resolve(true);
      } else if (doctype == 3 && type == "voterid_back") {
        resolve(true);
      } else if (doctype == 4 && type == "passport_front") {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  uploadEnachDocument = async (type, base) => {
    const { dispatch } = this.props;
    const base64 = base.replace(`data:${type};base64,`, "");
    let data = {
        base64: [base64],
        doctype: "Enach Form",
        basetype: [type],
        catType: "Physical NACH",
        parent_id: this.props.sfid,
        id: this.props.sfid,
        fileType: ["Physical_NACH"],
        is_doc_verified: true,
    };
    const getData = await dispatch(uploadDocument(data));
    await this.getProductDetails();
    await this.handleEnachUpload();
  };

  uploadFrontDocument = async (frontProofType, frontProofBase, file) => {
    const tab = this.state.selectedTab;
    const check = await this.checkFraud(file, 1, tab);
    if (check[0]) {
      if(this.state.onBoarding == 3){
        var addKey = check[1].data.result && check[1].data.result[0].details
      }
      const { dispatch } = this.props;
      let DataType = [];
      let mappedFiles = [];
      let Basetype = [];
      let eduFileType = [];
      if (this.state.selectedTab === 1) {
        DataType.push("Aadhar-Front");
        eduFileType.push("Aadhar Front");
      } else if (this.state.selectedTab === 2) {
        DataType.push("Driving-Front");
        eduFileType.push("Driving License");
      } else if (this.state.selectedTab === 3) {
        DataType.push("Voter-Front");
        eduFileType.push("Voter ID");
      } else {
        DataType.push("Passport-Front");
        eduFileType.push("Passport Front");
      }
      Basetype.push(frontProofType);
      let frontBase = this.state.adharFrontBase64 ? this.state.adharFrontBase64 : frontProofBase;
      const frontBase64 = frontBase.replace(
        `data:${frontProofType};base64,`,
        ""
        );
        
      mappedFiles.push(frontBase64);
      let data = {
        base64: mappedFiles,
        doctype: DataType,
        basetype: Basetype,
        catType: "ID Proof",
        parent_id: this.props.lead_id,
        id: this.props.lead_id,
        fileType: eduFileType,
        token: this.props.salesForceToken,
        is_doc_verified: this.state.frontDocAttempt > 2 ? false : true,
      };
      await dispatch(uploadDocument(data)).then((response) => {
        if (response.status == "success") {
          this.props.dispatch(
            verifyDoc({
              isAadharUpload: true,
              is_force_verification: false,
              user_sfid: this.props.lead_id,
            })
          );
          if (this.state.onBoarding == 3 && addKey.address) {
            this.setState(
              {
                isAutoAdd: false,
                address: addKey.address.value ? addKey.address.value : "",
                city: addKey.address.city ? addKey.address.city : "",
                state: addKey.address.state ? addKey.address.state : "",
                address_pin: addKey.address.pin ? addKey.address.pin : "",
                pincode: addKey.address.pin ? addKey.address.pin : "",
                // selectedTab:1,
                // frontProofBase: base64File,
              },
              () => {
                // console.log("address set in state successful");
              }
            );
          }
          if (this.state.backProofBase) {
            this.props.dispatch(
              verifyDoc({
                isKycDone: true,
                is_force_verification: false,
                user_sfid: this.props.lead_id,
              })
            );
          }
          this.getOtherDoc();
          let selectedTab = this.state.selectedTab;
          this.setState({
            frontProofBase: frontProofBase,
            defaultTab: selectedTab,
          });
        }else{
          this.errorToast(response.message);
        }
      });    
    }
  };

  uploadPanDocument = async (panType, panBase, file) => {
    const check = await this.panFraudCheck(file);
    if (check) {
      const { dispatch } = this.props;
      let DataType = [];
      let mappedFiles = [];
      let BaseType = [];
      let eduFileType = ["PAN"];
      DataType.push("Pan-Front");
      BaseType.push(panType);
      let panBaseC = panBase;
      const panBase64 = panBaseC.replace(`data:${panType};base64,`, "");
      mappedFiles.push(panBase64);
      let data = {
        base64: mappedFiles,
        doctype: DataType,
        catType: "PAN",
        basetype: BaseType,
        parent_id: this.props.lead_id,
        id: this.props.lead_id,
        fileType: eduFileType,
        is_doc_verified: this.state.panUploadAttempt,
        token: this.props.salesForceToken,
      };
      await dispatch(uploadDocument(data)).then((response) => {
        if (response.status == "success") {
          this.props.dispatch(
            verifyDoc({
              isPanUpload: true,
              is_force_verification: false,
              user_sfid: this.props.lead_id,
            })
          );
          this.setState({ panBase: panBase });
          if(panType === 'application/pdf'){
            this.setState({panFileType:2})
          }
          this.getPanDoc();
        }
      });
    }
  };

  uploadProfileImage = async (profileType, profileBase) => {
    const { dispatch } = this.props;
    let proBase = profileBase;
    const proBase64 = proBase.replace(`data:${profileType};base64,`, "");
    const d = new Date();
    const time = d.getTime();
    localStorage.setItem("userBase", proBase64);
    let prodata = {
      base64: proBase64,
      doctype: "Photo",
      catType: "Photo",
      fname: "eduvan-" + time + ".jpg",
      parent_id: this.props.lead_id,
      id: this.props.lead_id,
      token: this.props.salesForceToken,
      livenessScore: this.state.livenessScore,
      isLive: this.state.isLive,
      is_doc_verified:this.state.profileAttempt < 2 ? true : false
    };
    await dispatch(uploadProfile(prodata)).then((response) => {
      if(this.state.profileAttempt < 2){
        this.successToast("Verification Successful!");
      }
      this.getPhotoDoc();
      this.setState({ profileBase: profileBase });
      this.props.dispatch(
        verifyDoc({
          isPhotoUpload: true,
          is_force_verification: false,
          user_sfid: this.props.lead_id,
        })
      );
    });
  };

  setStartDate = (date) => {
    this.setState({ dob: this.convertDOB(date) });
  };

  selectAddress = (value) => {
    this.setState({
      selectedAddress: value,
    });
  };

  handleGender = (value) => {
    this.setState({ gender: value });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChangeCo = (event) => {
    if (event.target.name == "co_email") {
      if (isValidEmail(event.target.value)) {
        this.setState({
          co_isEmail: true,
        });
      } else {
        this.setState({
          co_isEmail: false,
        });
      }
    }
    if (event.target.name == "co_number") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (pattern.test(event.target.value)) {
        if (event.target.value.length == 10) {
          this.setState({
            co_isNumber: true,
          });
        } else {
          this.setState({
            co_isNumber: false,
          });
        }
      } else {
        this.setState({
          co_isNumber: false,
        });
      }
    }
    if (event.target.name == "co_pan") {
      var regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/i;
      if (regex.test(event.target.value)) {
        this.setState({
          co_isPan: true,
        });
      } else {
        this.setState({
          co_isPan: false,
        });
      }
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  handleEntitySearch = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      isValidCompany: false,
    });
    let string = event.target.value;
    if (string.length <= 2) {
      this.setState({ showCompanySearch: false });
      this.props.dispatch(clearSearchEntity());
    } else {
      this.setState({ showCompanySearch: true });
      let data = { company_name: string };
      if (searchTimer) {
        clearTimeout(searchTimer)
      }
      searchTimer = setTimeout(() => {
        this.props.dispatch(searchEntity(data));
      }, 1500)

      //this.props.dispatch(searchEntity(data));
    }
  };

  handleHouse = (value) => {
    this.setState({ house: value, rent_amount: "" });
  };

  handleTab = (eventKey) => {
    this.setState({ selectedTab: parseInt(eventKey) });
  };

  handleBankTab = (eventKey) => {
    this.setState({ selectedBankTab: parseInt(eventKey) });
  };

  async handleProfileSelect(e) {
    e.preventDefault();
    const files = e.target.files;
    let type = files[0].type;
    let fileSize = files[0].size / 1024 / 1024;
    if (fileSize >= FILE_LIMIT) {
      this.errorToast("Profile picture size exceeds");
    }else if(type === 'application/pdf'){
      this.errorToast("Please upload in Jpeg/Png format");
    } else {
      this.setState({ profile: files[0] });
      const isValidPic = await this.checkLiveness(files[0]).then((response) => {
        const { profileAttempt } = this.state;
        const doctAttempt = profileAttempt + 1;
        if (response.message) {
          const getData = response.message;
          const result = getData.result
          if(((getData && getData.statusCode === 101) && (result && result.isLive))){
            this.setState({photoVerify: true})
          }
          if (((getData && getData.statusCode === 101) && (result && result.isLive)) || doctAttempt>2) {
            if(doctAttempt>2){
              this.errorToast(
                   "Your profile document not verified still we can moved to backend verification");
            }
            const result = getData && getData.result ? getData.result : null;
            const livenessScore =
              result && result.livenessScore !== undefined
                ? result.livenessScore
                : 0;
            const validPic =
              result && result.isLive !== undefined ? result.isLive : false;
            this.setState({ livenessScore: livenessScore, isLive: validPic });
            var reader = new FileReader();
            var url = reader.readAsDataURL(files[0]);
            reader.onloadend = function (e) {
              this.uploadProfileImage(type, reader.result);
            }.bind(this);
          } else {
            this.setState({ livenessScore: 0, isLive: false });

             const { profileAttempt } = this.state;
             const doctAttempt = profileAttempt + 1;
             this.setState({ profileAttempt: doctAttempt });
             document.getElementById("profileInput").value = "";

           if (
              getData &&
              getData.message &&
              getData.message == "Insufficient Credits"
            ) {
              this.errorToast("Insufficient Credits");
            } else {
              this.errorToast("Invalid Profile Picture");
            }
          }
        }
      });
    }
  }

  handlePanSelect = (e) => {
    e.preventDefault();
    const files = e.target.files;
    let type = files[0].type;
    let fileSize = files[0].size / 1024 / 1024;
    if (fileSize >= FILE_LIMIT) {
      this.errorToast("File size exceeds");
    } else {
      var reader = new FileReader();
      var url = reader.readAsDataURL(files[0]);
      reader.onloadend = function (e) {
        this.uploadPanDocument(type, reader.result, files[0]);
      }.bind(this);
    }
  };

  handleEnachUploadSelect = async (e) => {
    e.preventDefault();
    const files = e.target.files;
    let type = files[0].type;
    let fileSize = files[0].size / 1024 / 1024;
    if (fileSize >= FILE_LIMIT) {
      this.errorToast("File size exceeds");
    } else {
      var reader = new FileReader();
      var url = reader.readAsDataURL(files[0]);
      reader.onloadend = function (e) {
        this.uploadEnachDocument(type, reader.result);
      }.bind(this);
    }
  };

  handleFrontProofSelect = async (e) => {
    e.preventDefault();
    const files = e.target.files;
    let type = files[0].type;
    let fileSize = files[0].size / 1024 / 1024;
    if (fileSize >= FILE_LIMIT) {
      this.errorToast("File size exceeds");
    } else {
      var reader = new FileReader();
      var url = reader.readAsDataURL(files[0]);
      reader.onloadend = function (e) {
        this.uploadFrontDocument(type, reader.result, files[0]);
      }.bind(this);
    }
  };

  handleBackProofSelect = (e) => {
    e.preventDefault();
    const files = e.target.files;
    let type = files[0].type;
    let fileSize = files[0].size / 1024 / 1024;
    if (fileSize >= FILE_LIMIT) {
      this.errorToast("File size exceeds");
    } else {
      var reader = new FileReader();
      var url = reader.readAsDataURL(files[0]);
      reader.onloadend = function (e) {
        this.uploadBackDocument(type, reader.result, files[0]);
      }.bind(this);
    }
  };

  handleBankSlipSelect = (e) => {
    e.preventDefault();
    const files = e.target.files;
    let selectedFile = [];
    let sizeError = 0;
    let fileError = 0;
    for (var i = 0; i < files.length; i++) {
      let fname = files[i].name;
      let fileSize = files[i].size / 1024 / 1024;
      var re = /(\.pdf)$/i;
      if (fileSize >= FILE_LIMIT) {
        sizeError = 1;
      } else {
        if (re.exec(fname)) {
          selectedFile.push(files[i]);
        } else {
          fileError = 1;
        }
      }
    }
    if (selectedFile.length > 0) {
      this.uploadBankDocument(selectedFile);
      //  this.setState({ bankDocument: selectedFile});
    }

    if (fileError === 1) {
      this.errorToast("Some file not supported!");
    }
    if (sizeError === 1) {
      this.errorToast("Some file size exceeds");
    }
    //var re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
  };

  handleBankProofSelect = async (e) => {
    e.preventDefault();
    const files = e.target.files[0];
    let sizeError = 0;
    let fileError = 0;
    let fname = files.name;
    let fileSize = files.size / 1024 / 1024;
    var re = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
    if (fileSize >= FILE_LIMIT) {
      sizeError = 1;
    } else {
      if (!re.exec(fname)) {
        fileError = 1;
      }
    }
    if (fileError === 1 || sizeError === 1) {
      if (fileError === 1) {
        this.errorToast("File not supported!");
      }
      if (sizeError === 1) {
        this.errorToast("File size exceeds 5 MB");
      }
    } else {
      let type = files.type;
      let baseData = await this.toBase64(files);
      let base = baseData.replace(`data:${type};base64,`, "");
      const d = new Date();
      const time = d.getTime();
      let ext = "pdf";
      let fileType = "Cancelled Cheque";
      if (this.state.selectedBankTab === 1) {
        fileType = "Cancelled Cheque";
      } else if (this.state.selectedBankTab === 2) {
        fileType = "Bank Passbook";
      } else {
        fileType = "Bank Statement";
      }
      if (type) {
        const getExt = type.split("/");
        ext = getExt[1];
      }
      let data = {
        base64: base,
        doctype: "Bank Account Proof",
        basetype: files.type,
        fname: "eduvan-web-" + time + "." + ext,
        fileType: fileType,
        parent_id: this.props.lead_id,
        id: this.props.lead_id,
      };
      this.props.dispatch(bankProofUpload(data)).then((response) => {
        if (response && response.status === "success") {
          this.successToast("Uploaded Successfully");
          this.setState({ onBoarding: 6 });
        } else {
          this.setState({ onBoarding: 6 });
        }
      });
    }
  };

  handleSendPhysicalMandate = async () => {
    const { dispatch, user_id } = this.props;
    let data = {
      type: "Physical Mandate",
      user_sfid: this.props.lead_id,
      send_via: "email",
    };
    let datasms = {
      email_notification_flag: this.state.emailcheck,
      sms_notification_flag: this.state.smscheck,
      whats_app_notification_flag: 0,
      opp_sfid: this.props.opp_id,
    };
    await dispatch(sendAgreementSms(datasms)).then((response) => { });
    await dispatch(sendLink(data)).then((response) => {
      if (response && response.status === "success") {
        this.successToast("Sent Successfully");
        this.getLeadProfile();
        // this.setState({ onBoarding: 11 });
      } else {
        // this.setState({ onBoarding: 11 });
      }
    });
  };

  handleSendKyc = async () => {
    const { dispatch, user_id } = this.props;
    let data = {
      type: "Kyc Complete",
      user_sfid: this.props.lead_id,
      send_via: "email",
    };

    await dispatch(sendLink(data)).then((response) => {
      if (response && response.status === "success") {
        this.successToast("Sent Successfully");
        this.setState({ onBoarding: 9 });
      } else {
        this.setState({ onBoarding: 9 });
      }
    });
  };

  deleteRow = (row) => {
    let arr = this.state.bankDocument;
    arr = arr.slice(0);
    arr.splice(row, 1);
    this.setState({ bankDocument: arr });
  };

  onlyNumbers = (e) => {
    var pattern = new RegExp(/^(?!(0))\d+$/);
    if (e.target.value !== "") {
      if (!pattern.test(e.target.value)) {
        // this.setState({ [e.target.name]: "" });
      } else {
        this.setState({ [e.target.name]: e.target.value });
        if(e.target.value < 15000){
          this.setState({
            showErrorMsg : true,
          })
        }else{
          this.setState({
            showErrorMsg : false,
          })
        }
      }
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }

    if (this.state.showInputUpdatePopupCount === 0 && this.props.monthlyIncome && e.target.name === 'monthly_income') {
      this.setState({ showConsentModal: true })
    }
  };

  onlyPincode = (e) => {
    var pattern = new RegExp(/^[0-9\b]+$/);
    if (e.target.value !== "") {
      if (!pattern.test(e.target.value)) {
        this.setState({
          errpincode: "Please Enter the valid Pincode",
          isValidpincode: true,
        });
      } else if (e.target.value.length < 6) {
        this.setState({
          [e.target.name]: e.target.value,
          errpincode: "Please Enter the valid Pincode ",
          isValidpincode: true,
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          errpincode: " ",
          isValidpincode: false,
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        errpincode: " ",
        isValidpincode: false,
      });
    }
  };

  openPdf = (base64,documentName) => {
    const { dispatch } = this.props;
    dispatch(leadPdfStore(base64,documentName));
    dispatch(openPreviewPdfModel());
  };

  base64toBlob = (base64Data) => {
    const { dispatch } = this.props;
    dispatch(openPreviewPdfModel());
  };

  handleselectCompany = (item) => {
    this.setState({ company_name: item.company_name__c, isValidCompany: true });
    this.props.dispatch(clearSearchEntity());
  };

  renderAutoSearch = (entity) => {
    let row = [];
    entity.map((item, index) => {
      row.push(
        <div
          onClick={() => this.handleselectCompany(item)}
          style={{ backgroundColor: "#f5f5f5", cursor: "pointer" }}
          key={`search-${index}`}
        >
          {item.company_name__c}
        </div>
      );
    });
    return row;
  };

  handleProfileRemove = async (id) => {
    const { salesForceToken, dispatch } = this.props;
    let data = {
      id: id,
      token: salesForceToken,
    };
    document.getElementById("profileInput").value = "";
    if (id !== null && id !== "") {
      await dispatch(removeProfile(data));
    }
    this.setState({ profileBase: "", profileType: "" });
  };

  handleFileRemove = async (id, type) => {
    const { salesForceToken, dispatch } = this.props;
    let data = {
      id: id,
      token: salesForceToken,
    };
    if (id !== null) {
      await dispatch(removeDocument(data));
    }
    if (type === 1) {
      this.setState({ panBase: "" });
    } else if (type === 2) {
      let tab = 0;
      if (this.state.backProofBase !== "") {
        tab = this.state.defaultTab;
      }
      this.setState({ frontProofBase: "", defaultTab: tab });
    } else if (type === 3) {
      let tab = 0;
      if (this.state.frontProofBase !== "") {
        tab = this.state.defaultTab;
      }
      this.setState({ backProofBase: "", defaultTab: tab });
    }
  };

  handleIfscChange = async (event) => {
    event.persist();
    let ifsc = event.target.value;
    var reg = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;
    if(ifsc.length < 4){
      return;
    }
    if (ifsc.length == 11) {
      if (ifsc.match(reg)) {
        this.setState({
          isValid: true,
          errorMsg: "",
          ifsc: event.target.value,
        });
        let data = {
          ifsc: event.target.value,
        };
        await this.props.dispatch(checkIfsc(data)).then((response) => {
          if (response.status == "success") {
            this.setState({
              isValid: true,
              errorMsg: "",
              ifsc: event.target.value,
            });
          } else {
            this.setState({
              isValid: false,
              errorMsg: "Enter valid ifsc code",
              ifsc: event.target.value.slice(0,4),
            });
          }
        });
      } else {
        this.setState({
          isValid: false,
          errorMsg: "Enter valid ifsc code",
          ifsc: event.target.value.slice(0,4),
        });
      }
    } else {
      this.setState({ isValid: false, errorMsg: "", ifsc: event.target.value });
    }
  };

  succesmsg_ = () => {
    this.setState({ successmsg_: !this.state.successmsg_ });
  };

  replaceMiddle(string, n) {
    let str;
    if (n > 0) {
      str = string.replace(/^(\+?[\d]{2})\d+(\d{4})$/g, "$1****$2");
    } else {
      str = string.replace(/^(\+?[\d]{0})\d+(\d{4})$/g, "$1XXXXXX$2");
    }
    return str;
  }

  addNewAddress = (type) => {
    this.setState({ onBoarding: 17, addressType: type });
  };

  loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  async handlePay(amount) {
    const res = await this.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    var payAmount = parseInt(amount);
    var options = {
      key: "rzp_test_KHBSglpuXC3xGa",
      amount: payAmount * 100, //100000, //  = INR 1
      name: "Eduvanz",
      description: "Test Transaction",
      image: "https://eduvanz-web.herokuapp.com/images/icons/favicon.png",
      handler: (response) => {
        alert(response.razorpay_payment_id);
        this.setState({ onBoarding: 16 });
        /* alert(response.razorpay_order_id);
                alert(response.razorpay_signature); */
      },
      prefill: {
        name: this.state.username ? this.state.username : "",
        email: this.state.email ? this.state.email : "",
        contact: this.state.mobile ? this.state.mobile : "",
      },
      theme: {
        color: "#F37254",
      },
    };
    var rzp1 = new window.Razorpay(options);
    /*  document.getElementById('rzp-button1').onclick = function (e) {
          rzp1.open();
          e.preventDefault();
        } */
    /*   const rzp1  = new window.Razorpay(options); */
    rzp1.open();
  }

  handleSendAgreementSigning = async (plan) => {
    const { user_id, dispatch } = this.props;
          this.props.dispatch(closeLeadProfileModel());
          this.props.dispatch(UserClickedOnce(false))
    // let data = {
    //     type: 'Agreement Signing',
    //     user_sfid: this.props.lead_id,
    //     send_via: 'email',
    //     plan: plan
    // }
    // let data = {
    //   id: parseInt(localStorage.getItem("L_id")),
    //   stagename: "Payment Collection",
    // };
    // await dispatch(sendLink(data)).then((response) => {
    //     if (response.status === "success") {
    //         this.successToast("Sent Successfully");
    //     }
    // });
    // dispatch(updateOpp(data)).then((response) => {
    //   if (response.status === "success") {
    //     this.successToast("Sent Successfully");
    //     setTimeout(() => {
    //       this.props.dispatch(closeLeadProfileModel());
    //     }, 1000);
    //   }
    // });
  };
  openLeads = (id) => {
    this.props.dispatch(closeLeadApplicationModel());
    this.props.dispatch(openLeadProfileModel(id));
  };
  openRaiseQuery = () => {
    this.props.dispatch(openQueryModel());
    this.props.dispatch(closeLeadProfileModel());
  };

  openDrop = () => {
    this.props.dispatch(openDropModel());
    this.props.dispatch(closeLeadProfileModel());
  };

  handleSelectOption = (no, value) => {
    this.setState({
      profession: no,
      professionValue: value,
      showSelectOptionDropdown: false,
    });
  };

  getPanDetails = (e) => {
    e.preventDefault();
    const files = e.target.files;
    let type = files[0].type;
    let fileSize = files[0].size / 1024 / 1024;
    if (fileSize >= FILE_LIMIT) {
      this.errorToast("File size exceeds");
    } else {
      var reader = new FileReader();
      var url = reader.readAsDataURL(files[0]);
      reader.onloadend = function (e) {
        // this.uploadFrontDocument(type, reader.result, files[0]);
        var formdata = new FormData();
        formdata.append("files", files[0]);
        formdata.append("type", "PAN");
        formdata.append("sfid", this.props.lead_id);
        this.props
          .dispatch(fraudCheck(formdata))
          .then((response) => {
            if(response.status == "success"){
              if (response.data.status == "success") {
                this.setState({
                  pan: response.data.result[0].details.pan_no.value,
                  isValidPan: true
                });
                this.successToast(response.message)
              }
            }else{              
              document.getElementById("file_input_id").value = "";
              this.errorToast(response.message)
              this.setState({
                  pan: '',
                  isValidPan: false
              })
            }
          })
          .catch((error) => {
            // console.log("error while getting pan details");
          });
      }.bind(this);
    }
  };

  handleEmailChange = (e) => {
    if (e.target.checked) {
      this.setState({ emailcheck: 1 });
    } else {
      this.setState({ emailcheck: 0 });
    }
  };

  handleSmsChange = (e) => {

    if (e.target.checked) {
      this.setState({ smscheck: 1 });
    } else {
      this.setState({ smscheck: 0 });
    }
  };

  handleSelectSmsEmail = (e) => {
    if (e.target.checked) {
      this.setState({ smscheck: 1, emailcheck: 1 });
      this.setState({});
    } else {
      this.setState({ smscheck: 0, emailcheck: 0 });
    }
  };
  handleSendAgain = async () => {
    const { dispatch, user_id } = this.props;
    this.setState({ smscheck: 1, emailcheck: 1 });
    let datasms = {
      email_notification_flag: 1,
      sms_notification_flag: 1,
      whats_app_notification_flag: 0,
      opp_sfid: this.props.opp_id,
    };
    await dispatch(sendAgreementSms(datasms)).then((response) => {
      if (response.status === "success") {
        toast.success(response.message);
        this.setState({ sendagainshow: false });
      }
    });
  };

  componentWillUnmount (){
    clearTimeout(searchTimer);
    clearTimeout(startTimeOut);

  }
  render() {
    // console.log("Akhsya",this.state.smscheck,this.state.emailcheck,this.state.bothChecked,this.state.emailcheck == 1 && this.state.smscheck == 1)
    const {
      lead_profile_show,
      lead_id,
      entitySearch,
      entity,
      banks,
      plans,
      orderSummary,
    } = this.props;
    const {
      bankProofSrc,
      defaultBankTab,
      limit,
      isOtpSent,
      productData,
      planData,
      bankSrc,
      profileSuccessMsg,
      isProfileSuccess,
      addressList,
      dob,
      gender,
      username,
      defaultTab,
      userstatus,
      sfid,
      product,
      email,
      mobile,
      card,
      loan_amount,
      pincode,
      onBoarding,
      isSuccess,
      successMsg,
      state,
      city,
      address,
      upload_status,
      download_status,
      co_email,
      co_number,
      co_pan,
      co_isEmail,
      co_first_name,
      co_last_name,
      co_isNumber,
      co_relation,
      co_isPan,
      checkSal,
      photoVerify,
      panVerify,
      frontVerify,
      backVerify,
    } = this.state;
    const subBtn = {
      background: "#1F1F2D",
      borderRadius: "100px",
      color: "#ffffff",
    };
    // console.log('photo icon disable', photoVerify, this.state.isProfileVerified)
    const searchResult = this.renderAutoSearch(entity);
    // console.log(this.state.showSelectOptionDropdown, this.state.professionValue);
    let plans_content;
    if (plans && plans.length) {
      plans_content = plans.map((item, index) => (
        <div
          className={`item ${this.state.selectedPlan === item.id ? "selected" : ""
            } `}
          key={`item-${index}`}
          onClick={() => this.handleSelectPlan(item.id)}
        >
          <h4>
            <span>₹</span>{" "}
            {item.emi_amount__c ? item.emi_amount__c.toLocaleString() : "--"}{" "}
            <span>/Mo</span>
          </h4>
          <div className="month mb-3">{item.net_tenure__c} Months </div>
          <ul className="list-unstyled m-0">
            <li>
              <p>Processing Fee</p>
              <h5>
                <span>₹</span>{" "}
                {item.processing_fee__c
                  ? item.processing_fee__c.toLocaleString()
                  : "--"}
              </h5>
            </li>
            <li>
              <p>Due today</p>
              <h5>
                <span>₹</span>{" "}
                {item.emi_amount__c
                  ? item.emi_amount__c.toLocaleString()
                  : "--"}
              </h5>
            </li>
            <li>
              <p>Interest ({item.fixed_rate__c}% p.a)</p>
              <h5>
                <span>₹</span>{" "}
                {((item.fixed_rate__c / 100) * item.emi_amount__c)
                  .toFixed(2)
                  .toLocaleString()}
              </h5>
            </li>
            <li>
              <p>Total Repayble Amount</p>
              <h5>
                <span>₹</span>{" "}
                {(
                  (item.fixed_rate__c / 100) * item.emi_amount__c +
                  item.emi_amount__c
                )
                  .toFixed(2)
                  .toLocaleString()}
              </h5>
            </li>
            <li>
              <p>Down Payment</p>
              <h5>
                <span>₹</span>{" "}
                {item.down_payment__c
                  ? item.down_payment__c.toFixed(2).toLocaleString()
                  : "--"}
              </h5>
            </li>
          </ul>
          <div className="text-center">
            <button className="d-inline-flex im_btn">
              Includes Moratorium{" "}
              <span className="d-inline-block ml-2">
                <img
                  src="images/icons/icon-ind.png"
                  alt="icon-ind"
                  className="img-fluid"
                />
              </span>
            </button>
          </div>
        </div>
      ));
    }
    let hMobile = "";
    if (this.state.mobile) {
      hMobile = this.replaceMiddle(this.state.mobile, 0);
    }
    const proImages =
      productData && productData.images ? productData.images[0] : [];
    let profileInputRef;
    let panInputRef;
    let frontProofInputRef;
    let backProofInputRef;
    let bankProofInputRef;
    let bankSlipInputRef;
    let addFront;
    let addBack;
    let bankOptions = [];
    let bankData;
    let enachUploadInputRef;
    if (this.props.banks) {
      bankData = this.props.banks.slice(0, 6);
      for (var i = 0; i < this.props.banks.length; i++) {
        bankOptions.push({
          value: this.props.banks[i].bank_name+'---'+this.props.banks[i].bank_code,
          label: this.props.banks[i].bank_name+'---'+this.props.banks[i].bank_code,
        });
      }
    }

    return (
      <>
        {this.state.isLoader ? (
          <div className="loading" style={{ zIndex: 1 }}>
            Loading&#8230;
          </div>
        ) : (
          ""
        )}
        <Modal
          show={lead_profile_show}
          className="right myModal w-700 enable_scroll"
        >
          <Modal.Header className="lp-modal-head">
            {/* <button
              type="button"
              className="abs_close close"
              onClick={this.closeModel}
            >
              {" "}
              <i className="fas fa-times"></i>{" "}
            </button> */}
            <div className="row">
              <div className="col-sm-12">
                <div className="fullrow float-left mb-3">
                  <div className="leftpart float-left">
                    <div className="namearea float-left pr-4">
                      {username !== "" && <Modal.Title>{username}</Modal.Title>}
                      {this.state.customer_idIs !== "" && (
                        <span className="d-block appnum">{this.state.customer_idIs}</span>
                      )}
                    </div>
                    <div className="statusbtn float-left">
                      <span className="btn btn-sm btn-blue radius-20">
                        {userstatus}
                      </span>
                    </div>
                  </div>
                  <div className="rightpart float-right">
                    {/* <i className="fas fa-pen"></i>
                          <i style={{cursor:'pointer'}} className="fas fa-ellipsis-v" aria-haspopup="true" aria-expanded="false" ></i> */}

                    {/* <button onClick={() => this.openLeads(this.props.lead_id)} style={{ cursor: 'pointer' }} className='p-2'><i className="fa fa-pencil" aria-hidden="true"></i></button> */}
                    <button
                      type="button"
                      className="p-2 ml-2"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img src="./images/menuIcon.svg" class="mr-1 cursor-point mt-n2 closeSettlementIcon menuHeightWidth"/>
                    </button>
                    <img src="./images/icons/icon-close2.png" class="mr-1 cursor-point" onClick={this.closeModel} data-dismiss="modal" />
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={this.openRaiseQuery}
                        href={void 0}
                      >
                        Raise Query
                      </a>
                      <a
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={this.openDrop}
                        href={void 0}
                      >
                        Drop Lead
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    {mobile !== "" && (
                      <span className="icontext">
                        <i className="fas fa-phone-alt"></i> {mobile}
                      </span>
                    )}
                    {email !== "" && (
                      <span className="icontext">
                        <i className="fas fa-envelope"></i> {email}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-4">
                    {card !== "" && (
                      <span className="icontext">
                        <i className="fas fa-credit-card"></i> {card}
                      </span>
                    )}
                    {product !== "" && (
                      <span className="icontext">
                        <i className="fas fa-cube"></i> {product}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-4">
                    <span className="icontext">
                      <i className="fas fa-rupee-sign"></i>{" "}
                      <span className="amtdetails">
                        Loan Amount
                        {loan_amount !== "" && (
                          <b>
                            <i className="fas fa-rupee-sign"></i> {loan_amount}
                          </b>
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="px-0">
            <Scrollbar>
              <div className="px-5 ApplicationStart">
                <div className="row">
                  <div className="col-sm-12 form-group">
                    <ul className="timeliner">
                      <li
                        className={
                          this.state.indicator > 1
                            ? "complete"
                            : this.state.indicator == 1
                              ? "inProgress"
                              : ""
                        }
                      >
                        <span className="leadTitle">Application</span>
                      </li>
                      <li
                        className={
                          this.state.indicator > 2
                            ? "complete"
                            : this.state.indicator == 2
                              ? "inProgress"
                              : ""
                        }
                      >
                        <span className="leadTitle">Document</span>
                      </li>
                      <li
                        className={
                          this.state.indicator > 3
                            ? "complete"
                            : this.state.indicator == 3
                              ? "inProgress"
                              : ""
                        }
                      >
                        <span className="leadTitle">Payment Plan</span>
                      </li>
                      <li
                        className={
                          this.state.indicator > 4
                            ? "complete"
                            : this.state.indicator == 4
                              ? "inProgress"
                              : ""
                        }
                      >
                        <span className="leadTitle">Repayment</span>
                      </li>
                    </ul>
                  </div>
                </div>
                {this.state.onBoarding == 0 ? (
                  <>
                    <div className="v-scroll_lead lp-modal-border">
                      <h5 className="mb-3 verifyPantext"> Verify PAN</h5>
                      <div className="pansection text-center">
                        <div className="paninput">
                          <input
                            type={"text"}
                            className="PANtextbox"
                            placeholder="XXXXXXXXXX"
                            value={this.state.pan ? this.state.pan : ""}
                            name="pan"
                            maxLength="10"
                            onChange={this.handlePanChange}
                          //  readOnly={true}
                          />
                        </div>
                        <div className="form_notes">
                          {/* <p className="color_1">
                            This will not affect the applicant's Credit Score
                          </p> */}
                        </div>
                        <div className="d-block text-center">
                          <div className="row error-msg">
                            {this.state.isValidPan === false &&
                              this.state.errorMsg !== "" ? (
                              <span>{this.state.errorMsg}</span>
                            ) : (
                              ""
                            )}
                            {this.state.successMsg != "" &&
                              this.state.isSuccess === 0 ? (
                              <span>{this.state.successMsg}</span>
                            ) : (
                              ""
                            )}
                          </div>
                          <p className="parapan"><img src="images/shield.svg"/>This will not affect the applicant's Credit Score</p>
                          <button
                            onClick={this.updatePanStatus}
                            type="button"
                            className="flex-c-m cl0 bor1 p-lr-15 trans-04 pointer mr-btn-sty correntpanbtn"
                          style={this.state.errorMsg || !this.state.pan ?{}:subBtn}
                             disabled={this.state.errorMsg || !this.state.pan ?true:false}
                          >
                            Yes, this is correct PAN
                          </button>
                        </div>
                        <span
                          onClick={() => this.onNextBording(13)}
                          style={{ cursor: "pointer", fontWeight: "600"}}
                          className="correntpanbtn"
                        >
                          No, Incorrect PAN
                        </span>
                      </div>
                    </div>
                    {/* <button
                      type="button"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.onNextBording(1)}
                      className="btn btn-default"
                    >
                      <i className="fas fa-arrow-right"></i>
                    </button> */}
                  </>
                ) : this.state.onBoarding == 1 ? (
                  <>
                    <div className="v-scroll_lead">
                      <div className="mn_height_57">
                        <div className="boxed mt-4 lp-modal-border">
                          <h5 className="mb-3 basicDetailsText">Basic Details</h5>
                          <div className="row">
                            <div className="col-sm-6 form-group">
                              <label htmlFor="" className="form-label">
                                Date of Birth
                              </label>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  format="dd-MM-yyyy"
                                  value={dob}
                                  name="dob"
                                  disableFuture={true}
                                  onChange={this.setStartDate}
                                  className="datePic_input"
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                            <div
                              className="col-sm-6 form-group"
                            >
                              <label htmlFor="" className="form-label">
                                Pincode
                              </label>
                              <input
                                type="text"
                                className="form-control wc_input"
                                placeholder="000 000"
                                maxLength={"6"}
                                name="pincode"
                                value={pincode ? pincode : ""}
                                onChange={this.onlyPincode}
                              />
                              {this.state.isValidpincode == true ? (
                                <div className="form-group">
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {this.state.errpincode}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-sm-12 form-group r_shadow">
                              <label htmlFor="" className="form-label mb-2">
                                Gender
                              </label>
                              {/* <button
                                type="button"
                                onClick={() => this.handleGender("male")}
                                className={`btn ${gender == "male"
                                    ? "selected-gender"
                                    : "btn-primary"
                                  }`}
                              >
                                <i className="fas fa-mars"></i> Male
                              </button>
                              <button
                                type="button"
                                onClick={() => this.handleGender("female")}
                                className={`btn ${gender == "female"
                                    ? "selected-gender"
                                    : "btn-primary"
                                  }`}
                              >
                                <i className="fas fa-venus"></i> Female
                              </button>
                              <button
                                type="button"
                                onClick={() => this.handleGender("others")}
                                className={`btn ${gender == "others"
                                    ? "selected-gender"
                                    : "btn-primary"
                                  }`}
                              >
                                <i className="fas fa-venus-mars"></i> Others
                              </button> */}
                                <div className="nav nav-tabs report_frequency_tabs" id="nav-tab" role="tablist">
                              <a
                                className={`nav-item report-nav nav-link ${gender == "male" && "active"} three-sec`}
                                id="daily-tab"
                                data-toggle="tab"
                                role="tab"
                                aria-controls="daily"
                                aria-selected="true"
                                onClick={() => this.handleGender("male")}
                              >
                                {/* {option2 == SCHEDULE_LATER ? "Daily" : "Today"} */}
                                <i className="fas fa-mars"></i> Male

                              </a>
                              <a
                                className={`nav-item report-nav nav-link ${gender == "female" && "active"} three-sec`}
                                id="weekly-tab"
                                data-toggle="tab"
                                role="tab"
                                aria-controls="weekly"
                                aria-selected="false"
                                onClick={() => this.handleGender("female")}
                              >
                               <i className="fas fa-venus"></i> Female

                              </a>
                              <a
                                className={`nav-item report-nav nav-link ${gender == "others" && "active"} three-sec`}
                                id="monthly-tab"
                                data-toggle="tab"
                                role="tab"
                                aria-controls="monthly"
                                aria-selected="false"
                                onClick={() => this.handleGender("others")}
                              >
                                <i className="fas fa-venus-mars"></i> Others

                              </a>                          
                            </div>
                            </div>                            
                          </div>
                        </div>
                      </div>
                      <div className="row error-msg">
                        {profileSuccessMsg != "" && isProfileSuccess === 0 ? (
                          <span>{profileSuccessMsg}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row pr-4">
                      <div className="col-sm-12 d-flex  justify-content-end align-items-center">
                        {/* prev next arrow*/}
                        {/* <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(0)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                              <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(2)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button> */}

                        {/* <Button
                          style={{ cursor: "pointer" }}
                          onClick={() => this.onNextBording(0)}
                          className="border-0 mr-4 btn cancel"
                        >
                          Previous
                        </Button> */}
                        <Button
                          onClick={this.updateProfile}
                          className="btn btn-default_"
                          disabled={
                            dob &&
                              gender &&
                              pincode &&
                              !this.state.isValidpincode
                              ? false
                              : true
                          }
                          style={
                            dob &&
                              gender &&
                              pincode &&
                              !this.state.isValidpincode
                              ? subBtn
                              : {}
                          }
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding == 2 ? (
                  <>
                    <div className="v-scroll_lead">
                      <div className="mn_height_57">
                        <div className="boxed mt-4">
                          <h5 className="mb-3">
                            Select Communication Address{" "}
                            <button
                              type="button"
                              onClick={() => this.addNewAddress(1)}
                              className="btn btn-sm border-0 btn-primary color_1 shadow-none float-right"
                            >
                              <i className="fas fa-plus"></i> Add New
                            </button>
                          </h5>
                          <div className="row">
                            <ul className="customInputsradio">
                              {addressList &&
                                addressList.length > 0 &&
                                addressList.map((item, index) => (
                                  <li key={"item" + index}>
                                    <input
                                      type="radio"
                                      value={item.id}
                                      name="selectedAddress"
                                      id={`selectedAddress-${item.id}`}
                                      defaultChecked={`${this.state.selectedAddress === item.id
                                          ? "checked"
                                          : ""
                                        }`}
                                      onClick={() =>
                                        this.selectAddress(item.id)
                                      }
                                    />
                                    <label
                                      htmlFor={`selectedAddress-${item.id}`}
                                    >
                                      {`${item.address__c ? item.address__c : ""
                                        } ,${item.city__c ? item.city__c : ""} ,${item.state__c ? item.state__c : ""
                                        } ,${item.pincode__c ? item.pincode__c : ""
                                        }`}
                                      <span className="d-block color_grey">
                                        Source: <b>{item.name}</b>
                                      </span>
                                    </label>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pr-4">
                      <div className="col-sm-12 d-flex  justify-content-end align-items-center">
                        {/* prev next arrow*/}
                        {/* <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(1)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                              <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(3)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button> */}

                        <Button
                          style={{ cursor: "pointer" }}
                          onClick={this.closeModel}
                          className="border-0 mr-4 btn cancel"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={this.updateLenderAddress}
                          className="btn btn-default_"
                          disabled={
                            this.state.selectedAddress != "" ? false : true
                          }
                          style={this.state.selectedAddress != "" ? subBtn : {}}
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding == 3 ? (
                  <>
                    <div className="v-scroll_lead communicationDetails">
                      <div className="">
                        <div className="boxed mt-4 communicationBox">
                          <h5 className="mb-4 DetailsTitle">
                          Select Communication Address{" "}
                            <button
                              type="button"
                              className="btn btn-sm border-0 btn-primary shadow-none float-right"
                            >
                              {/* <i className="fas fa-info-circle f-16"></i> */}
                            </button>
                          </h5>
                          <div className="switch_btn d-flex">
                            <label className="switch mr-3">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  this.setState({
                                    showAddAddress: e.target.checked,
                                  })
                                }
                              />
                              <span className="slider sliderAddress round"></span>
                            </label>{" "}
                            <p>Same as Aadhaar address</p>
                          </div>
                          {this.state.showAddAddress && (
                            <div
                              style={{
                                position: "relative",
                                float: "right",
                                top: "-24px",
                              }}
                            >
                              {!this.state.isAutoAdd && (
                                <p
                                  onClick={() =>
                                    this.setState({ isAutoAdd: true })
                                  }
                                  style={{
                                    fontSize: "1.171vw",
                                    fontWeight: "500",
                                    color: "#1D1B21",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img className="mr-2" src="./images/upload.svg"/>Upload aadhaar to autofill
                                </p>
                              )}
                              {this.state.isAutoAdd && (
                                <p
                                  onClick={() =>
                                    this.setState({ isAutoAdd: false })
                                  }
                                  style={{
                                    fontSize: "1.171vw",
                                    fontWeight: "500",
                                    color: "#1D1B21",
                                    cursor: "pointer",
                                  }}
                                >
                                  Enter manually instead
                                </p>
                              )}
                            </div>
                          )}
                          {!this.state.isAutoAdd ? (
                            <div className="row w-100">
                              <div className="col-sm-12 form-group mt-3">
                                <label htmlFor="" className="form-label mb-1">
                                  Address Line 1
                                </label>
                                {this.state.gmapsLoaded && (
                                  <PlacesAutocomplete
                                    value={this.state.address}
                                    style={{ width: "100%" }}
                                    onChange={this.handlePlacesChange}
                                    onSelect={this.handleSelect}
                                  >
                                    {({
                                      getInputProps,
                                      suggestions,
                                      getSuggestionItemProps,
                                      loading,
                                    }) => (
                                      <div className="form-group position-relative">
                                        <input
                                          {...getInputProps({
                                            placeholder: "Flat No. / Building Name / Society / Locality / Street Name",
                                            className:
                                              "form-control location-search-input locationinput",
                                          })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                          {loading && <div>Loading...</div>}
                                          {suggestions.map(
                                            (suggestion, index) => {
                                              const className =
                                                suggestion.active
                                                  ? "suggestion-item--active"
                                                  : "suggestion-item";
                                              // inline style for demonstration purpose
                                              const style = suggestion.active
                                                ? {
                                                  backgroundColor: "#eaeaea",
                                                  cursor: "pointer",
                                                }
                                                : {
                                                  backgroundColor: "#f5f5f5",
                                                  cursor: "pointer",
                                                };
                                              return (
                                                <div
                                                  key={"item" + index}
                                                  {...getSuggestionItemProps(
                                                    suggestion,
                                                    {
                                                      className,
                                                      style,
                                                    }
                                                  )}
                                                >
                                                  <span key={index}>
                                                    {suggestion.description}
                                                  </span>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </PlacesAutocomplete>
                                )}
                              </div>
                              <div className="col-sm-6 form-group">
                                <label htmlFor="" className="form-label mb-1">
                                Address Line 2
                                </label>
                                <input
                                  type="text"
                                  className="form-control locationinput"
                                  placeholder="City / State"
                                  onChange={this.handleChange}
                                  value={city}
                                  name="city"
                                />
                              </div>
                              <div className="col-md-6 form-group clearfix">
                                <label htmlFor="" className="form-label mb-1">
                                  Pincode
                                </label>
                                <input
                                  type="text"
                                  className="form-control locationinput"
                                  placeholder="000 000"
                                  maxLength={"6"}
                                  name="pincode"
                                  id="address_pin"
                                  value={this.state.pincode}
                                  onChange={this.onlyNumbers}
                                />
                              </div>
                              
                              {/* <div className="col-sm-6 form-group">
                                <label htmlFor="" className="form-label">
                                  State
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="State"
                                  name="state"
                                  value={state}
                                  onChange={this.handleChange}
                                />
                              </div> */}
                              
                            </div>
                          ) : (
                            <>
                             <p className="aadharUpload mt-4">Upload Aadhar </p>
                              <div
                                className="row"
                                style={{ marginTop: "15px" }}
                              >
                               
                                <div className="col-md-6 uploadimgcontainer">
                                  {/* <label htmlFor="" className="form-label mb-2">
                                    Front
                                  </label> */}
                                  {!this.state.frontProofBase ? (
                                    <button
                                      type="button"
                                      onClick={() => addFront.click()}
                                      className="btn docupload-btn"
                                    >
                                       <img src="./images/uploadpic.svg"/> <br/> <p className="upload-para">Upload Aadhar Front</p> 
                                    </button>
                                  ) : (
                                    <img
                                      src={this.state.frontProofBase}
                                      style={{ height: "115px" }}
                                    />
                                  )}
                                  <input
                                    type="file"
                                    style={{ display: "none" }}
                                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                    ref={(refParam) => (addFront = refParam)}
                                    onChange={(e) =>
                                      this.handleFrontProofSelect(e)
                                    }
                                    onClick={(event) => {
                                      event.target.value = null;
                                    }}
                                  />
                                </div>
                                <div className="col-md-6 uploadimgcontainer">
                                  {/* <label htmlFor="" className="form-label mb-2">
                                    Back
                                  </label> */}
                                  {!this.state.backProofBase ? (
                                    <button
                                      type="button"
                                      onClick={() => addBack.click()}
                                      className="btn docupload-btn"
                                    >
                                      <img src="./images/uploadpic.svg"/> <br/> <p className="upload-para">Upload Aadhar Back</p> 
                                    </button>
                                  ) : (
                                    <img
                                      src={this.state.backProofBase}
                                      style={{ height: "115px" }}
                                    />
                                  )}
                                  <input
                                    type="file"
                                    style={{ display: "none" }}
                                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                    ref={(refParam) => (addBack = refParam)}
                                    onChange={(e) =>
                                      this.handleBackProofSelect(e)
                                    }
                                    onClick={(event) => {
                                      event.target.value = null;
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          )}

                          <div className="row w-100 mt-2">
                            <div className="col-md-7 form-group r_shadow">
                              <label htmlFor="" className="form-label mb-2">
                                Address Type
                              </label>
                              
                                {/* <button
                                  type="button"
                                  onClick={() => this.handleHouse(1)}
                                  className={`btn ${this.state.house === 1
                                      ? "selected-gender selectedHouse"
                                      : "btn-primary"
                                    }`}
                                >
                                  <i className="fas fa-home"></i>Owned
                                </button>
                                <button
                                  type="button"
                                  onClick={() => this.handleHouse(2)}
                                  className={` btn ${this.state.house === 2
                                      ? "selected-gender"
                                      : "btn-primary"
                                    }`}
                                >
                                  <i className="fas fa-home"></i>Rented
                                </button> */}
                                 <div className="nav nav-tabs report_frequency_tabs" id="nav-tab" role="tablist">
                              <a
                                className={`nav-item report-nav nav-link houseNav activeNav ${this.state.house === 1 && "active activeNav"} two-sec`}
                                id="daily-tab"
                                data-toggle="tab"
                                role="tab"
                                aria-controls="daily"
                                aria-selected="true"
                                onClick={() => this.handleHouse(1)}
                              >
                                {/* {option2 == SCHEDULE_LATER ? "Daily" : "Today"} */}
                                <img src="./images/owned.svg"/> Owned

                              </a>
                              <a
                                className={`nav-item report-nav nav-link houseNav rentedNav ${this.state.house === 2 && "active activeNav"} two-sec`}
                                id="weekly-tab"
                                data-toggle="tab"
                                role="tab"
                                aria-controls="weekly"
                                aria-selected="false"
                                onClick={() => this.handleHouse(2)}
                              >
                               <img src="./images/rented.svg"/> Rented

                              </a>
                              </div>
                              
                            </div>
                            {this.state.house === 2 && (
                              <div className="col-md-6 form-group">
                                <label
                                  htmlFor="rent_amount"
                                  className="form-label mb-2"
                                >
                                  Rent Amount
                                </label>
                                <input
                                  type="text"
                                  className="form-control locationinput"
                                  name="rent_amount"
                                  id="rent_amount"
                                  placeholder="₹ 000"
                                  value={this.state.rent_amount}
                                  onChange={this.onlyNumbers}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-between align-items-center pr-4 mt-4">
                      <div className="col-lg-6 form_notes mt-0 form_notesforclose d-flex align-items-start">
                        
                          <span className="d-inline-block mr-2">
                            <img
                              src="images/shield.svg"
                              alt="tick-mark"
                            />
                          </span>
                          <p className="link2">Applicant's data will not be saved in case you cancel
                          or close the page</p>
                        
                      </div>

                      <div className="col-lg-6 d-flex justify-content-end">
                        {/* prev next arrow*/}

                        {/* <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(2)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                              <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(4)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button> */}

                        {/* <Button
                          style={{ cursor: "pointer" }}
                          type="button"
                          onClick={this.closeModel}
                          className="border-0 mr-4 btn cancel"
                        >
                          Cancel
                        </Button> */}
                        <Button
                          type="button"
                          onClick={this.updateResident}
                          className="btn btn-default_ saveleadbtn"
                          disabled={
                            address != "" &&
                              state != "" &&
                              city &&
                              pincode != "" &&
                              pincode.length == 6 &&
                              (this.state.house === 1 ||
                                (this.state.house === 2 &&
                                  this.state.rent_amount !== ""))
                              ? false
                              : true
                          }
                          style={
                            address != "" &&
                              state != "" &&
                              city &&
                              pincode != "" &&
                              pincode.length == 6 &&
                              (this.state.house === 1 ||
                                (this.state.house === 2 &&
                                  this.state.rent_amount !== ""))
                              ? subBtn
                              : {}
                          }
                        >
                          Save &amp; Next
                        </Button>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding == 4 ? (
                  <>
                    <div className="v-scroll_lead">
                      <div className="mn_height_57">
                        <div className="boxed mt-4 leadProfessionalDetails">
                          <h5 className="mb-4 DetailsTitle">
                            Professional Details{" "}
                            {/* <button
                            type="button"
                            className="btn btn-sm border-0 btn-primary shadow-none float-right"
                          >
                            <i className="fas fa-info-circle f-16"></i>
                          </button> */}
                          </h5>
                          <div className="row w-100">
                            <div className="col-sm-6 form-group">
                              <label
                                htmlFor="professional"
                                className="form-label"
                              >
                                {username !== "" ? `${username} is a` : ""}
                              </label>
                              {/* <select 
                                          name="profession"
                                          id="professional"
                                          className="form-control"
                                          value={this.state.profession?this.state.profession:'0'}
                                          onChange={this.handleChange}
                                      >
                                          <option value={''}>Select Any</option>
                                          <option value={'1'} >Salaried Person</option>
                                          <option value={'2'} >Self Employed (Professional)</option>
                                          <option value={'3'} >Self employed (Non-Professional)</option>
                                          <option value={'4'} >Retired</option>
                                          <option value={'5'} >Student</option>
                                      </select> */}

                              <div
                                className="d-flex justify-content-between align-items-center border-darks position-relative"
                                style={{
                                  paddingBottom: "4px",
                                  paddingTop: "3px",
                                  marginTop:"8px"
                                }}
                                onClick={() =>
                                  this.setState({
                                    showSelectOptionDropdown: !this.state.showSelectOptionDropdown,
                                  })
                                }
                              >
                                <p
                                  className="form-control font-weight-bold"
                                 
                                >
                                  {/* type="text"
                                          className="form-control"
                                          placeholder="Start typing..."
                                          onChange={() => this.setState({professionValue:this.state.professionValue})} */}

                                  {this.state.professionValue}
                                </p>
                                <i
                                  style={{ cursor: "pointer" }}
                                  class="fa fa-chevron-down"
                                  aria-hidden="true"
                                ></i>
                              </div>

                              {this.state.showSelectOptionDropdown && (
                                <div className="optionsdropdown">
                                  <p
                                    onClick={() =>
                                      this.handleSelectOption(
                                        "1",
                                        "Salaried Person"
                                      )
                                    }
                                  >
                                    Salaried Person
                                  </p>
                                  <p
                                    onClick={() =>
                                      this.handleSelectOption(
                                        "2",
                                        "Self Employed (P)"
                                      )
                                    }
                                  >
                                    Self Employed (Professional)
                                  </p>
                                  <p
                                    onClick={() =>
                                      this.handleSelectOption(
                                        "3",
                                        "Self Employed (NP)"
                                      )
                                    }
                                  >
                                    Self employed (Non-Professional)
                                  </p>
                                  <p
                                    onClick={() =>
                                      this.handleSelectOption("4", "Retired")
                                    }
                                  >
                                    Retired
                                  </p>
                                  {/* <p onClick={() => this.handleSelectOption('5', 'Student')}>Student</p> */}
                                </div>
                              )}
                            </div>
                            {/* <div className="col-sm-6 form-group">
                            <label
                              htmlFor="monthly_income"
                              className="form-label"
                            >
                              Family Income
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="₹ 00000"
                              name="family_income"
                              value={this.state.family_income}
                              onChange={this.onlyNumbers}
                            />
                          </div> */}
                          </div>
                          <div className="row w-100 companyDetails">
                            {this.state.profession === "1" ? (
                              <>
                                <div className="col-sm-6 form-group">
                                  <label
                                    htmlFor="company_name"
                                    className="form-label mb-2"
                                  >
                                    Company Name
                                  </label>
                                  <div className="d-flex justify-content-between align-items-center border-darks">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Start typing..."
                                      onChange={this.handleEntitySearch}
                                      value={this.state.company_name}
                                      name="company_name"
                                    />
                                    <i
                                      style={{ cursor: "pointer" }}
                                      class="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  <div className="autocomplete">
                                    {this.state.showCompanySearch ? (
                                      entitySearch ? (
                                        <div style={{ width: "100%" }}>
                                          Searching....
                                        </div>
                                      ) : (
                                        searchResult
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-sm-6 form-group">
                                  <label
                                    htmlFor="monthly_income"
                                    className="form-label mb-2"
                                  >
                                    Monthly Income
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control inputForIncome"
                                    placeholder="00000"
                                    name="monthly_income"
                                    value={this.state.monthly_income}
                                    onChange={this.onlyNumbers}
                                  />
                                  <p className="rupeesArrow">₹</p>
                                </div>
                                {
                                  this.state.showErrorMsg &&
                                  <p className="text-danger">Your current income profile does not qualify for the loan</p>
                                }
                              </>
                            ) : (
                              ""
                            )}
                            {this.state.profession === "2" ||
                              this.state.profession === "3" ? (
                              <>
                                <div className="col-sm-6 form-group">
                                  <label
                                    htmlFor="company_name"
                                    className="form-label mb-2"
                                  >
                                    Company Name
                                  </label>
                                  <div className="d-flex justify-content-between align-items-center border-darks">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Start typing..."
                                      onChange={this.handleEntitySearch}
                                      value={this.state.company_name}
                                      name="company_name"
                                    />
                                    <i
                                      style={{ cursor: "pointer" }}
                                      class="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  <div className="autocomplete">
                                    {this.state.showCompanySearch ? (
                                      entitySearch ? (
                                        <div style={{ width: "100%" }}>
                                          Searching....
                                        </div>
                                      ) : (
                                        searchResult
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                <div className="col-sm-6 form-group">
                                  <label
                                    htmlFor="monthly_income"
                                    className="form-label mb-2"
                                  >
                                    Monthly Income
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control inputForIncome"
                                    placeholder="00000"
                                    name="monthly_income"
                                    value={this.state.monthly_income}
                                    onChange={this.onlyNumbers}
                                  />
                                  <p className="rupeesArrow">₹</p>
                                </div>
                                {
                                  this.state.showErrorMsg &&
                                  <p className="text-danger">Your current income profile does not qualify for the loan</p>
                                }
                              </>
                            ) : (
                              ""
                            )}
                            {this.state.profession === "4" ? (
                              <>


                                <div className="col-sm-6 form-group">
                                  <label
                                    htmlFor="company_name"
                                    className="form-label mb-2"
                                  >
                                    Company Name
                                  </label>
                                  <div className="d-flex justify-content-between align-items-center border-darks">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Start typing..."
                                      onChange={this.handleEntitySearch}
                                      value={this.state.company_name}
                                      name="company_name"
                                    />
                                    <i
                                      style={{ cursor: "pointer" }}
                                      class="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  <div className="autocomplete">
                                    {this.state.showCompanySearch ? (
                                      entitySearch ? (
                                        <div style={{ width: "100%" }}>
                                          Searching....
                                        </div>
                                      ) : (
                                        searchResult
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                <div className="col-sm-6 form-group">
                                  <label
                                    htmlFor="monthly_income"
                                    className="form-label mb-2"
                                  >
                                    Monthly Pension
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control inputForIncome"
                                    placeholder="00000"
                                    name="monthly_income"
                                    value={this.state.monthly_income}
                                    onChange={this.onlyNumbers}
                                  />
                                  <p className="rupeesArrow">₹</p>
                                </div>
                                {
                                  this.state.showErrorMsg &&
                                  <p className="text-danger">Your current income profile does not qualify for the loan</p>
                                }

                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row error-msg">
                        {this.state.successMsg != "" &&
                          this.state.isSuccess === 0 ? (
                          <span>{this.state.successMsg}</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="row">
                        <span className="d-flex" style={{ gap: '12px', padding: "0.75rem" }}>
                          <input
                            onChange={(e) =>
                              this.setState({ checkSal: e.target.checked })
                            }
                            className=""
                            id="mincome"
                            name="mincome"
                            style={{
                              width: "60px",
                              marginTop: "-70px",
                            }}
                            checked={this.state.checkSal}
                            value={checkSal}
                            type="checkbox"
                          />
                          <label htmlFor="mincome" style={{fontSize:"0.878vw",marginTop:"-30px"}}>
                            By ticking here, I (on my own behalf or on behalf of the applicant after verifying necessary details) confirm that my/applicant’s monthly household income (i.e. combined income of individual family unit) is more than INR 25,000 per month.
                          </label>
                        </span>
                      </div>
                      <div className="row">
                        {this.state.successMsg != "" &&
                          this.state.isSuccess === 1 ? (
                          <span>{this.state.successMsg}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-between align-items-center mb-4">
                      {!checkSal && <div className="text-danger col-lg-12 pb-2" style={{ fontSize: '13px', marginBottom: '15px', paddingLeft: '15px' }}>
                        To proceed kindly check the above declaration.<i> Please check the same only if your household income is greater than INR 25,000 per month. In case of any query, you may reach out to the respective RM or you may contact us at <a href="mailto:support@eduvanz.com" onclick="window.location=another.html">support@eduvanz.com</a> </i>
                      </div>}    
                    <div className="col-lg-6 form_notes mt-0 form_notesforclose d-flex align-items-start">
                        <span className="d-inline-block mr-2">
                          <img
                            src="images/shield.svg"
                            alt="tick-mark"
                          />
                        </span>
                        <p className="link2">  Applicants's data will not be saved in case you cancel
                          or close the page</p>
                    </div>
                        
                    

                      <div className="col-lg-6 d-flex justify-content-end">
                        {/* prev next arrow*/}
                        {/* <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(3)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                              <button type="button" style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(5)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button> */}

                        <Button
                          style={{ cursor: "pointer" }}
                          type="button"
                          onClick={this.closeModel}
                          className="border-0 cancel btn mr-4 cancalleaddbtn"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="button"
                          onClick={this.submitProfession}
                          className="btn btn-default_ saveleadbtn"
                          disabled={
                            this.state.profession && this.state.checkSal &&
                              ((this.state.profession === "1" &&
                                this.state.monthly_income >= 15000 &&
                                this.state.company_name.length > 2) ||
                                (this.state.profession === "2" &&
                                  this.state.monthly_income >= 15000 &&
                                  this.state.company_name.length > 2) ||
                                (this.state.profession === "3" &&
                                  this.state.monthly_income >= 15000 &&
                                  this.state.company_name.length > 2) ||
                                (this.state.profession === "4" &&
                                  this.state.monthly_income >= 15000 &&
                                  ( !this.state.company_name || this.state.company_name && this.state.company_name.trim().length > 2)) ||
                                this.state.profession === "5")
                              ? false
                              : true
                          }
                          style={
                            this.state.profession && this.state.checkSal &&
                              ((this.state.profession === "1" &&
                                this.state.monthly_income >= 15000 &&
                                this.state.company_name.length > 2) ||
                                (this.state.profession === "2" &&
                                  this.state.monthly_income >= 15000 &&
                                  this.state.company_name.length > 2) ||
                                (this.state.profession === "3" &&
                                  this.state.monthly_income >= 15000 &&
                                  this.state.company_name.length > 2) ||
                                (this.state.profession === "4" &&
                                  this.state.monthly_income >= 15000 &&
                                 ( !this.state.company_name || this.state.company_name && this.state.company_name.length > 2)) ||
                                this.state.profession === "5")
                              ? subBtn
                              : {}
                          }
                        >
                          Save &amp; Next
                        </Button>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding == 5 ? (
                  <>
                    <div className="v-scroll_lead lp-modal-border">
                      <div className="boxed max_images mt-4">
                        <div className="row">
                          <div className="col-sm-12 form-group">
                            <h5 className="mb-2 kycdocuments">Kyc Documents</h5>
                            {/* <div className="rightpart float-right position-relative">
                              <i
                                style={{ cursor: "pointer" }}
                                className="fas fa-ellipsis-v"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              ></i>
                              <div
                                className="dropdown-menu nav_upload_all"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <i
                                  style={{ cursor: "pointer" }}
                                  className="fas fa-times float-right"
                                ></i>
                                <a
                                  className="dropdown-item"
                                  style={{ cursor: "pointer" }}
                                  onClick={this.openDropModel}
                                  href={void 0}
                                >
                                  Upload All
                                </a> */}
                                {/* <a className="dropdown-item" style={{ cursor: 'pointer' }} href={void (0)}>Request Documents</a> */}
                              {/* </div>
                            </div> */}
                            <p className="listinputs">
                              Required PDF or JPEG. Max file size 5 MB
                            </p>
                          </div>
                          <div className="col-sm-6 form-group uploaded_img">
                            <label htmlFor="" className="form-label mb-2 kyc-sub-head">
                              Photo
                            </label>
                            <div className="row">
                              <div className="col-md-12">
                                <input
                                  type="file"
                                  style={{ display: "none" }}
                                  id="profileInput"
                                  ref={(refParam) =>
                                    (profileInputRef = refParam)
                                  }
                                  onChange={this.handleProfileSelect}
                                  accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                />
                                {this.state.profileBase ? (
                                  <>
                                    {" "}
                                    <div className="radius10">
                                      <img src={this.state.profileBase} />
                                      {/* {!this.state.isProfileVerified && ( */}
                                      <div className="img_abs">
                                        <button
                                          type="button"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.openImageEdit(0, 1)
                                          }
                                        >
                                          <i className="fas fa-search-plus"></i>
                                        </button>
                                        <button
                                          type="button"
                                          disabled={
                                            // this.state.isProfileVerified || (this.state.profileBase && photoVerify)
                                            false
                                          }
                                          style={{
                                            cursor: this.state.isProfileVerified || (this.state.profileBase && photoVerify)
                                              ? "not-allowed"
                                              : "pointer",
                                          }}
                                          onClick={() =>
                                            this.openImageEdit(1, 1)
                                          }
                                        >
                                          <i className="fas fa-pen"></i>
                                        </button>
                                        <button
                                          type="button"
                                          disabled={
                                            this.state.isProfileVerified || (this.state.profileBase && photoVerify)
                                          }
                                          style={{
                                            cursor: this.state.isProfileVerified || (this.state.profileBase && photoVerify)
                                              ? "not-allowed"
                                              : "pointer",
                                          }}
                                          onClick={() =>
                                            this.handleProfileRemove(
                                              this.state.profileId
                                            )
                                          }
                                        >
                                          <i className="fa fa-trash-o"></i>
                                        </button>
                                      </div>
                                      {/* )} */}
                                    </div>
                                  </>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={() => profileInputRef.click()}
                                    className="btn docupload-btn"
                                  >
                                    <img src="./images/uploadpic.svg" style={{width:"30px"}}/> <br/> <p className="upload-para">Upload Photo</p> 
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 form-group uploaded_img">
                            <label htmlFor="" className="form-label mb-2 kyc-sub-head">
                              PAN
                            </label>
                            <div className="row">
                              <div className="col-md-12">
                                <input
                                  type="file"
                                  style={{ display: "none" }}
                                  ref={(refParam) => (panInputRef = refParam)}
                                  onChange={this.handlePanSelect}
                                  onClick={(event) => {
                                    event.target.value = null;
                                  }}
                                  accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                />
                                {this.state.panBase ? (
                                  <div className="radius10">
                                    {this.state.panFileType === 2 ? (
                                      <img
                                        style={{
                                          cursor: "pointer",
                                          padding: "10px",
                                        }}
                                        onClick={() =>
                                          this.openPdf(this.state.panBase.replace("data:application/pdf;base64,",""),"Pan Card")
                                        }
                                        src="./images/pdf.png"
                                      />
                                    ) : (
                                      <>
                                        <img src={this.state.panBase} />
                                        {/* {!this.state.isPanVerified && ( */}
                                        <div className="img_abs">
                                          <button
                                            type="button"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.openImageEdit(0, 2)
                                            }
                                          >
                                            <i className="fas fa-search-plus"></i>
                                          </button>
                                          <button
                                            type="button"
                                            disabled={this.state.isPanVerified || (this.state.panBase && panVerify)}
                                            style={{
                                              cursor: this.state.isPanVerified || (this.state.panBase && panVerify)
                                                ? "not-allowed"
                                                : "pointer",
                                            }}
                                            onClick={() =>
                                              this.openImageEdit(1, 2)
                                            }
                                          >
                                            <i className="fas fa-pen"></i>
                                          </button>
                                          <button
                                            type="button"
                                            disabled={this.state.isPanVerified || (this.state.panBase && panVerify)}
                                            style={{
                                              cursor: this.state.isPanVerified || (this.state.panBase && panVerify)
                                                ? "not-allowed"
                                                : "pointer",
                                            }}
                                            onClick={() =>
                                              this.handleFileRemove(
                                                this.state.panId,
                                                1
                                              )
                                            }
                                          >
                                            <i className="fa fa-trash-o"></i>
                                          </button>
                                        </div>
                                        {/* )} */}
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={() => panInputRef.click()}
                                    className="btn docupload-btn"
                                  >
                                    <img src="./images/uploadpic.svg" style={{width:"30px"}}/> <br/> <p className="upload-para">Upload PAN</p> 
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 form-group">
                            <h6 className="h6_1 kyc-sub-head">
                              {"Identity Proof (Upload atleast one)"}
                            </h6>
                            {/* <Nav
                              variant="pills"
                              activeKey={this.state.selectedTab}
                              onSelect={this.handleTab}
                              defaultActiveKey={this.state.selectedTab}
                            >
                              <Nav.Item>
                                <Nav.Link
                                  eventKey={1}
                                  disabled={
                                    defaultTab != 0 && defaultTab != 1
                                      ? true
                                      : false
                                  }
                                >
                                  Aadhar
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey={2}
                                  disabled={
                                    defaultTab != 0 && defaultTab != 2
                                      ? true
                                      : false
                                  }
                                >
                                  Driving License
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey={3}
                                  disabled={
                                    defaultTab != 0 && defaultTab != 3
                                      ? true
                                      : false
                                  }
                                >
                                  Voter ID
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey={4}
                                  disabled={
                                    defaultTab != 0 && defaultTab != 4
                                      ? true
                                      : false
                                  }
                                >
                                  Passport
                                </Nav.Link>
                              </Nav.Item>
                            </Nav> */}
                            <div className="nav nav-tabs report_frequency_tabs" id="nav-tab" role="tablist">
                              <a
                                className={`nav-item report-nav nav-link ${this.state.selectedTab == 1 && "active"}`}
                                id="daily-tab"
                                data-toggle="tab"
                                role="tab"
                                // aria-controls="daily"
                                aria-selected="true"
                                onClick={() => this.handleTab(1)}
                              >
                                {/* {option2 == SCHEDULE_LATER ? "Daily" : "Today"} */}
                                  Aadhar
                              </a>
                              <a
                                className={`nav-item report-nav nav-link ${this.state.selectedTab == 2 && "active"}`}
                                id="weekly-tab"
                                data-toggle="tab"
                                role="tab"
                                // aria-controls="weekly"
                                aria-selected="false"
                                onClick={() => this.handleTab(2)}
                              >
                               Driving License

                              </a>
                              <a
                                className={`nav-item report-nav nav-link ${this.state.selectedTab == 3 && "active"}`}
                                id="monthly-tab"
                                data-toggle="tab"
                                role="tab"
                                // aria-controls="monthly"
                                aria-selected="false"
                                onClick={() => this.handleTab(3)}
                              >
                                Voter ID

                              </a>   
                              <a
                                className={`nav-item report-nav nav-link ${this.state.selectedTab == 4 && "active"}`}
                                id="monthly-tab"
                                data-toggle="tab"
                                role="tab"
                                // aria-controls="monthly"
                                aria-selected="false"
                                onClick={() => this.handleTab(4)}
                              >
                                Passport

                              </a>                         
                            </div>
                          </div>
                          <div className="col-md-12">
                            <h6 className="h6_2 kyc-sub-head">
                              {this.state.selectedTab == 1
                                ? "Aadhaar Card"
                                : this.state.selectedTab == 2
                                  ? "Driving License"
                                  : this.state.selectedTab == 3
                                    ? "Voter ID"
                                    : "Passport"}
                            </h6>
                            <div className="row">
                              <div className="col-md-6 form-group">
                                <label htmlFor="" className="form-label mb-2 kyc-sub-head">
                                  Front
                                </label>
                                <input
                                  type="file"
                                  style={{ display: "none" }}
                                  ref={(refParam) =>
                                    (frontProofInputRef = refParam)
                                  }
                                  onChange={this.handleFrontProofSelect}
                                  onClick={(event) => {
                                    event.target.value = null;
                                  }}
                                  accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                />
                                {this.state.frontProofBase ? (
                                  <div className="radius10">
                                    {this.state.frontFileType === 2 ? (
                                      <img
                                        style={{
                                          cursor: "pointer",
                                          padding: "10px",
                                        }}
                                        onClick={() =>
                                          this.openPdf(
                                            this.state.frontProofBase
                                          )
                                        }
                                        src="./images/pdf.png"
                                      />
                                    ) : (
                                      <>
                                        <img src={this.state.frontProofBase} />
                                        {/* {!this.state.isAddressDocVerified && ( */}
                                        <div className="img_abs">
                                          <button
                                            type="button"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.openImageEdit(0, 3)
                                            }
                                          >
                                            <i className="fas fa-search-plus"></i>
                                          </button>
                                          <button
                                            type="button"
                                            disabled={
                                              this.state.isAddressDocVerified || (this.state.frontProofBase && frontVerify)
                                            }
                                            style={{
                                              cursor: this.state.isAddressDocVerified || (this.state.frontProofBase && frontVerify)
                                                ? "not-allowed"
                                                : "pointer",
                                            }}
                                            onClick={() =>
                                              this.openImageEdit(1, 3)
                                            }
                                          >
                                            <i className="fas fa-pen"></i>
                                          </button>
                                          <button
                                            type="button"
                                            disabled={
                                              this.state.isAddressDocVerified || (this.state.frontProofBase && frontVerify)
                                            }
                                            style={{
                                              cursor: this.state
                                                .isAddressDocVerified || (this.state.frontProofBase && frontVerify)
                                                ? "not-allowed"
                                                : "pointer",
                                            }}
                                            onClick={() =>
                                              this.handleFileRemove(
                                                this.state.frontId,
                                                2
                                              )
                                            }
                                          >
                                            <i className="fa fa-trash-o"></i>
                                          </button>
                                        </div>
                                        {/* )} */}
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={() => frontProofInputRef.click()}
                                    className="btn docupload-btn"
                                  >
                                    <img src="./images/uploadpic.svg" style={{width:"30px"}}/> <br/> <p className="upload-para">Upload Front</p> 
                                  </button>
                                )}
                              </div>
                              <div className="col-md-6 form-group">
                                <label htmlFor="" className="form-label mb-2 kyc-sub-head">
                                  Back
                                </label>
                                <input
                                  type="file"
                                  style={{ display: "none" }}
                                  ref={(refParam) =>
                                    (backProofInputRef = refParam)
                                  }
                                  onChange={this.handleBackProofSelect}
                                  onClick={(event) => {
                                    event.target.value = null;
                                  }}
                                  accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                />
                                {this.state.backProofBase ? (
                                  <div className="radius10">
                                    {this.state.backFileType === 2 ? (
                                      <img
                                        style={{
                                          cursor: "pointer",
                                          padding: "10px",
                                        }}
                                        onClick={() =>
                                          this.openPdf(this.state.backProofBase)
                                        }
                                        src="./images/pdf.png"
                                      />
                                    ) : (
                                      <>
                                        <img src={this.state.backProofBase} />
                                        {/* {!this.state.isAddressDocVerified && ( */}
                                          <div className="img_abs">
                                            <button
                                              type="button"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.openImageEdit(0, 4)
                                              }
                                            >
                                              <i className="fas fa-search-plus"></i>
                                            </button>
                                            <button
                                              type="button"
                                              disabled={
                                                this.state.isAddressDocVerified || (this.state.backProofBase && backVerify)
                                              }
                                              style={{
                                                cursor: this.state
                                                  .isAddressDocVerified || (this.state.backProofBase && backVerify)
                                                  ? "not-allowed"
                                                  : "pointer",
                                              }}
                                              onClick={() =>
                                                this.openImageEdit(1, 4)
                                              }
                                            >
                                              <i className="fas fa-pen"></i>
                                            </button>
                                            <button
                                              type="button"
                                              disabled={
                                                this.state.isAddressDocVerified || (this.state.backProofBase && backVerify)
                                              }
                                              style={{
                                                cursor: this.state
                                                  .isAddressDocVerified || (this.state.backProofBase && backVerify)
                                                  ? "not-allowed"
                                                  : "pointer",
                                              }}
                                              onClick={() =>
                                                this.handleFileRemove(
                                                  this.state.backId,
                                                  3
                                                )
                                              }
                                            >
                                              <i className="fa fa-trash-o"></i>
                                            </button>
                                          </div>
                                        {/* )} */}
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={() => backProofInputRef.click()}
                                    className="btn docupload-btn"
                                  >
                                    <img src="./images/uploadpic.svg" style={{width:"30px"}}/> <br/> <p className="upload-para">Upload Back</p> 
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <>
                        <div className="v-scroll_lead">
                         {  this.state.limit <= 0 ? <div className="boxed max_images">
                            <div className="row">
                              <div className="col-sm-12 form-group">
                                <h5 className="mb-2 kyc-sub-head">Financial Documents</h5>
                                <p className="listinputs">
                                  Required PDF or JPEG. Max file size 5 MB
                                </p>
                              </div>
                              <div className="row">
                                {bankSrc &&
                                  bankSrc.length > 0 &&
                                  bankSrc.map((item, index) => (
                                    <div
                                      key={`pdf-${index}`}
                                      className="col-md-3"
                                    >
                                      <img
                                        onClick={() => this.openPdf(item,"Bank Statement")}
                                        style={{ cursor: "pointer" }}
                                        src="images/icons/pdf.png"
                                        alt=""
                                      />
                                    </div>
                                  ))}
                              </div>
                              {!bankSrc && (
                                <div className="col-md-6 form-group">
                                  <h6 className="h6_2 kyc-sub-head">Bank Statement</h6>
                                  <ul>
                                    {this.state.bankDocument &&
                                      this.state.bankDocument.length > 0 &&
                                      this.state.bankDocument.map(
                                        (item, index) => (
                                          <li key={`statement-${index}`}>
                                            {item.name}
                                            <span
                                              style={{
                                                float: "right",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                this.deleteRow(index)
                                              }
                                            >
                                              X
                                            </span>
                                          </li>
                                        )
                                      )}
                                  </ul>

                                  <input
                                    type="file"
                                    style={{ display: "none" }}
                                    ref={(refParam) =>
                                      (bankSlipInputRef = refParam)
                                    }
                                    onChange={this.handleBankSlipSelect}
                                    accept="application/pdf"
                                    multiple
                                  />
                                  <button
                                    type="button"
                                    onClick={() => bankSlipInputRef.click()}
                                    className="btn docupload-btn"
                                  >
                                    <img src="./images/uploadpic.svg" style={{width:"30px"}}/> <br/> <p className="upload-para">Upload Statement</p> 
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>:""}
                        </div>
                        {/* <div className="row pr-4">

                                                          <div className='col-sm-12 d-flex  justify-content-end align-items-center'>
                                                              <button style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(4)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                                              <button style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(6)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button>

                                                              <Button style={{ cursor: 'pointer' }} type='button' onClick={() => this.onNextBording(4)} className="border-0 btn cancel mr-4">Cancel</Button>
                                                              <Button
                                                                  type="button"
                                                                  //  onClick={() => this.onNextBording(6)} 
                                                                  variant="secondary"
                                                                  className="btn btn-default_"
                                                                  onClick={this.handleStatementNext}
                                                                  disabled={bankSrc.length > 0 ? false : true}
                                                                  style={bankSrc.length > 0 ? subBtn : {}}
                                                              >Continue</Button>
                                                          </div>

                                                      </div> */}
                      </>
                    </div>
                    <div className="row pr-4">
                      <div className="col-sm-12 d-flex  justify-content-end align-items-center">
                        {/* prev next arrow*/}
                        {/* <button style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(4)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                              <button style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(6)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button> */}

                        {/* <Button
                          style={{ cursor: "pointer" }}
                          type="button"
                          onClick={this.closeModel}
                          className="border-0 btn cancel mr-4"
                        >
                          Cancel
                        </Button> */}
                        <Button
                          type="button"
                          //  onClick={() => this.onNextBording(6)}
                          variant="secondary"
                          className="btn btn-default_ mt-3 Financial Documents saveleadbtn"
                          onClick={this.handleKycNext}
                          disabled={
                            this.state.profileBase !== "" && 
                              this.state.panBase !== "" &&
                              this.state.frontProofBase !== "" &&
                              this.state.backProofBase !== "" &&
                              (limit <= 0 ? bankSrc.length > 0 ? true : false : true  )
                              // (bankSrc.length > 0 || limit > 0 )
                              
                              ? false
                              : true
                          }
                          style={
                            this.state.profileBase !== "" &&
                              this.state.panBase !== "" &&
                              this.state.frontProofBase !== "" &&
                              this.state.backProofBase !== "" &&
                              (limit <= 0 ? bankSrc.length > 0 ? true : false : true  )
                              // (bankSrc.length > 0 || limit > 0)
                              ? subBtn
                              : {}
                          }
                        >
                          Save &amp; Next
                        </Button>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding == 6 ? (
                  <>
                    <div className="v-scroll_lead lp-modal-border">
                      <h4 className="cpp_title mt-3 mb-4">
                        Choose Payment Plan
                      </h4>
                      {/* <div className="pricing_cariusel mb-5 row newpricingcorousel">
                        {plans_content && (
                          <OwlCarousel
                            className="owl-theme"
                            loop={false}
                            margin={20}
                            responsive={this.state.responsive}
                            nav
                            dots={false}
                          >
                            {plans_content}
                         </OwlCarousel>
                        )}
                        </div> */}
                         <div className=" mb-5 row newpricingcorousel" style={{columnGap:"10px"}}>
                        {
                          plans?.length > 0  && 
                          plans.map((item, index) => (
                            <div
                              className={`item mb-3 cursor-point col-md-5.9 col-lg-5.9 ${this.state.selectedPlan === item.id ? "selected" : ""
                                } `}
                              key={`item-${index}`}
                              onClick={() => this.handleSelectPlan(item.id)}
                              style={{width:"48%"}}
                            >
                              <div className="d-flex justify-content-between">
                              <h4 className="plansmonth">{item.net_tenure__c} Months
                                
                              </h4>
                              <div className="month mb-3 planmonthsemi"><span>₹</span>{" "}
                                {item.emi_amount__c ? item.emi_amount__c.toLocaleString() : "--"}{" "}
                                <span>/Mo</span> </div>
                                </div>

                              <div className="d-flex justify-content-between mt-3">
                                  <div>
                                  <p className="payemntsTitle">Processing Fee</p>
                                  <h5 className="paymentsContent mt-1">
                                    <span>₹</span>{" "}
                                    {item.processing_fee__c
                                      ? item.processing_fee__c.toLocaleString()
                                      : "--"}
                                  </h5>
                                  </div>
                                  <div>
                                  <p className="payemntsTitle">Interest ({item.fixed_rate__c}% p.a)</p>
                                  <h5 className="paymentsContent text-right mt-1">
                                    <span>₹</span>{" "}
                                    {((item.fixed_rate__c / 100) * item.emi_amount__c)
                                      .toFixed(2)
                                      .toLocaleString()}
                                  </h5>
                                  </div>
                              </div>
                              <div className="d-flex justify-content-between mt-4">
                                  <div>
                                  <p className="payemntsTitle">Down Payment</p>
                                  <h5 className="paymentsContent mt-1">
                                    <span>₹</span>{" "}
                                    {item.down_payment__c
                                      ? item.down_payment__c.toFixed(2).toLocaleString()
                                      : "--"}
                                  </h5>
                                  </div>
                                  <div>
                                  <p className="payemntsTitle">Total Repayment</p>
                                  <h5 className="paymentsContent text-right mt-1">
                                    <span>₹</span>{" "}
                                    {(
                                      (item.fixed_rate__c / 100) * item.emi_amount__c +
                                      item.emi_amount__c
                                    )
                                      .toFixed(2)
                                      .toLocaleString()}
                                  </h5>
                                  </div>
                              </div>
                              <div className="d-flex justify-content-between mt-4">
                                  <div>
                                  <p className="payemntsTitle">Due today</p>
                                  <h5 className="paymentsContent text-right mt-1">
                                    <span>₹</span>{" "}
                                    {item.emi_amount__c
                                      ? item.emi_amount__c.toLocaleString()
                                      : "--"}
                                  </h5>
                                  </div>
                                  
                              </div>
                              {/* <ul className="list-unstyled m-0">
                                <li>
                                  <p>Processing Fee</p>
                                  <h5>
                                    <span>₹</span>{" "}
                                    {item.processing_fee__c
                                      ? item.processing_fee__c.toLocaleString()
                                      : "--"}
                                  </h5>
                                </li>
                                <li>
                                  <p>Due today</p>
                                  <h5>
                                    <span>₹</span>{" "}
                                    {item.emi_amount__c
                                      ? item.emi_amount__c.toLocaleString()
                                      : "--"}
                                  </h5>
                                </li>
                                <li>
                                  <p>Interest ({item.fixed_rate__c}% p.a)</p>
                                  <h5>
                                    <span>₹</span>{" "}
                                    {((item.fixed_rate__c / 100) * item.emi_amount__c)
                                      .toFixed(2)
                                      .toLocaleString()}
                                  </h5>
                                </li>
                                <li>
                                  <p>Total Repayble Amount</p>
                                  <h5>
                                    <span>₹</span>{" "}
                                    {(
                                      (item.fixed_rate__c / 100) * item.emi_amount__c +
                                      item.emi_amount__c
                                    )
                                      .toFixed(2)
                                      .toLocaleString()}
                                  </h5>
                                </li>
                                <li>
                                  <p>Down Payment</p>
                                  <h5>
                                    <span>₹</span>{" "}
                                    {item.down_payment__c
                                      ? item.down_payment__c.toFixed(2).toLocaleString()
                                      : "--"}
                                  </h5>
                                </li>
                              </ul> */}
                              <div className="text-right mt-4">
                                <button className="d-inline-flex im_btn includeMoratorium">
                                  Includes Moratorium{" "}
                                  <span className="d-inline-block ml-2">
                                    <img
                                      src="images/icons/icon-ind.png"
                                      alt="icon-ind"
                                      className="img-fluid"
                                      width="12px"
                                      height="12px"
                                    />
                                  </span>
                                </button>
                              </div>
                              <div className="text-center mt-1">
                                {this.state.selectedPlan === item.id ?<button className="d-inline-flex selectedBtn">
                                  Selected Plan 
                                  <img src="./images/selectedPlan.svg"/>
                                </button>: <button className="d-inline-flex im_btn selectBtn justify-content-center">
                                  Select Now
                                </button>}
                               
                                
                              </div>
                            </div>))
                        }
                      </div>
                    </div>
                    <div className="row pr-4 mt-4">
                      <div className="col-sm-12 d-flex justify-content-end align-items-center">
                        {/* prev next arrow*/}
                        {/* <button type="button" style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(5)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                              <button type="button" style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(7)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button> */}
                        <Button
                          onClick={() => this.onNextBording(7)}
                          variant="secondary"
                          className="btn border-0 cancel"
                        >
                          Previous
                        </Button>
                        <Button
                          onClick={this.updatePaymentPlan}
                          disabled={
                            this.state.selectedPlan.length === 0 ? true : false
                          }
                          variant="secondary"
                          className="btn btn-default_"
                          style={subBtn}
                        >
                          Save & Next
                        </Button>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding == 7 ? (
                  <>
                    <div className="v-scroll_lead lp-modal-border">
                      <h4 className="cpp_title mt-3 mb-4">
                        Bank Account Details
                      </h4>
                      <p className="mb-2">Bank Name</p>
                      {/* <div className='p-1'>
                                              <ul className='d-flex align-items-center bank_name'>
                                                  {bankData && bankData.length > 0 && (
                                                      bankData.map((item, index) => (
                                                          <li onClick={() => this.handlebank(item.bank_name)} key={"bank-" + index} >
                                                              <span className={`d-block ${this.state.bank === item.bank_name ? 'bank-active' : ''}`}><img src={item.bank_icon} alt="bank-1" className='img-fluid' /></span>
                                                          </li>
                                                      ))
                                                  )}
                                              </ul>
                                          </div> */}
                      <div className="row w-100">
                       
                        {this.state.showBankDropdown ? 
                         <div className="col-sm-12 form-group d-flex">
                        <Select
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? '#102031' : '#102031',
                            borderRadius:"7px",
                            padding:state.selected ? '5px' : ' 5px'
                          }),
                        }}
                            options={bankOptions}
                            placeholder="Search your bank"
                            name="bank"
                            onChange={this.bankChange}
                            defaultValue={this.state.bankSelected ? this.state.bankSelected : ""}
                          />
                         <button type="button" class="search_btnaccount"><i class="fa fa-search searchInputIcon" aria-hidden="true"></i></button>
                         <button onClick={() => this.setState({showBankDropdown:false})} type="button" class="search_btnaccoutclose"><img src="./images/closeblackicon.svg"/></button>

                         {/* <button onClick={() => this.setState({showBankDropdown:false})} className="bank-name-cancel-btn">cancel</button> */}
                          </div>
                          :
                          <div className="col-sm-12 form-group" style={{position:"relative"}}>
                           <input
                            type="text"
                            className="form-control inputForAccount"
                            placeholder="Enter Bank Account Number"
                            onChange={this.handleBankAccount}
                            value={this.state.bankSelected ? this.state.bankSelected : ""}
                            name="acc_no"
                            id="acc_no"
                            minLength="9"
                            maxLength="18"
                            disabled
                          />
                         <button onClick={() => this.setState({showBankDropdown:true})} className="bank-name-edit-btn"><img src="./images/icons/edit_20.png"/></button>
                          </div>
                          }
                       
                        <div className="col-sm-6 form-group">
                          <label htmlFor="" className="form-label mb-2">
                            Account Number
                          </label>
                          <input
                            type="text"
                            className="form-control inputForAccount"
                            placeholder="Enter Bank Account Number"
                            onChange={this.handleBankAccount}
                            value={this.state.acc_no ? this.state.acc_no : ""}
                            name="acc_no"
                            id="acc_no"
                            minLength="9"
                            maxLength="18"
                          />
                          {this.state.isValidAccount === false &&
                            this.state.acc_no.length !== 0 ? (
                            <div className="form-group">
                              <div className="alert alert-danger" role="alert">
                                {this.state.Errmessage}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-sm-6 form-group">
                          <label htmlFor="" className="form-label mb-2">
                            Account Holder Name
                          </label>
                          <input
                            type="text"
                            className="form-control inputForAccount"
                            placeholder="Enter Full Name"
                            name="acc_name"
                            value={
                              this.state.acc_name ? this.state.acc_name : ""
                            }
                            onChange={this.handleAccountName}
                          />
                          {this.state.isErrorName &&
                            this.state.errorname != "" && (
                              <span style={{ color: "red" }}>
                                {this.state.errorname}
                              </span>
                            )}
                        </div>
                        <div className="col-md-6 form-group clearfix">
                          <label htmlFor="" className="form-label mb-2">
                            IFSC Code
                          </label>
                          <input
                            type="text"
                            className="form-control inputForAccount"
                            placeholder="Enter Bank IFSC Code"
                            maxLength={"11"}
                            name="ifsc"
                            id="ifsc"
                            value={this.state.ifsc ? this.state.ifsc : ""}
                            onChange={this.handleIfscChange}
                          />
                          {this.state.isValid == false &&
                            this.state.errorMsg != "" && (
                              <span style={{ color: "red" }}>
                                {this.state.errorMsg}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-between align-items-center pr-4 mt-3">
                      {/* <div className="col-lg-5">
                        <button className="link">
                          <span
                            className="d-inline-block mr-2"
                            style={{ width: "12px" }}
                          >
                            <img
                              src="images/icons/tick-mark.png"
                              alt="tick-mark"
                            />
                          </span>
                          We will deposit Rs. 1 to above bank account.
                        </button>
                      </div> */}
                  <div className="col-lg-6 form_notes mt-0 form_notesforclose d-flex align-items-start ">
                        
                        <span className="d-inline-block mr-2">
                          <img
                            src="images/shield.svg"
                            alt="tick-mark"
                          />
                        </span>
                        <p className="link2">We will deposit Rs. 1 to above bank account.</p>
                      
                    </div>
                      <div className="col-lg-6 d-flex justify-content-lg-end">
                        {/* prev next arrow*/}
                        {/* <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(6)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                              <button type="button" style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(8)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button> */}

                        <Button
                          onClick={() => this.onNextBording(5)}
                          variant="secondary"
                          className="btn cancel border-0"
                        >
                          Previous
                        </Button>
                        <Button
                          disabled={
                            this.state.ifsc &&
                              this.state.acc_name &&
                              this.state.acc_no &&
                              this.state.bank
                              ? false
                              : true
                          }
                          onClick={() => this.updateBank()}
                          style={
                            this.state.ifsc &&
                              this.state.acc_name &&
                              this.state.acc_no &&
                              this.state.bank
                              ? subBtn
                              : {}
                          }
                          variant="secondary"
                          className="btn btn-default"
                        >
                          Verify
                        </Button>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding == 8 ? (
                  <>
                    <div className="v-scroll_lead">
                      <div className="d-flex justify-content-between align-items-center mt-4  mb-4">
                        <h4 className="cpp_title">
                          Send eMandate completion request
                        </h4>
                        <div className="ml-3 d-flex justify-content-end align-items-center">
                          <label className="switch m-0">
                            <input type="checkbox" name="checkAll" />
                            <span className="slider round"></span>
                          </label>
                          <p className="m-0 w-a-txt ml-3">Select all</p>
                        </div>
                      </div>

                      <div className="d-flex flex-wrap three_checkbox mb-4">
                        <div className="mr-4">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="emailCheck"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="emailCheck"
                            >
                              Email
                            </label>
                          </div>
                        </div>
                        <div className="mr-4">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="whatsappCheck"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="whatsappCheck"
                            >
                              WhatsApp
                            </label>
                          </div>
                        </div>
                        <div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="smsCheck"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="smsCheck"
                            >
                              Push Notification/SMS
                            </label>
                          </div>
                        </div>
                      </div>

                      <p className="cpp_title fz14 mb-4">
                        How eMandate registration works?
                      </p>

                      <div className="row mb-5">
                        <div className="col-lg-4">
                          <div className="d-flex align-items-center justify-content-between">
                            <h6 className="step_txt">Step 1</h6>
                            <div className="arrow"></div>
                          </div>
                          <div>
                            <p className="fz12">
                              Open Link and Generate Auto-pay Mandate
                              Instruction
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="d-flex align-items-center justify-content-between">
                            <h6 className="step_txt">Step 2</h6>
                            <div className="arrow"></div>
                          </div>
                          <div>
                            <p className="fz12">Sign Mandate using OTP</p>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="d-flex align-items-center justify-content-between">
                            <h6 className="step_txt">Step 3</h6>
                            <div className="arrow"></div>
                          </div>
                          <div>
                            <p className="fz12">
                              Amount due (if any) is auto-debited from the
                              customer's account monthly
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pr-4">
                      <div className="col-sm-12 d-flex  justify-content-end align-items-center">
                        {/* prev next arrow*/}
                        {/* <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(7)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                              <button type="button" style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(9)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button> */}

                        <Button
                          onClick={() => this.onNextBording(7)}
                          variant="secondary"
                          className="btn cancel border-0"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={this.handleSendKyc}
                          variant="secondary"
                          className="btn btn-default_"
                          style={subBtn}
                        >
                          Send
                        </Button>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding == 9 ? (
                  <>
                    <div className="v-scroll_lead">
                      <div className="d-flex justify-content-between align-items-center mt-4  mb-4">
                        <h4 className="cpp_title mt-4 mb-4">
                          Physical Mandate Registration
                        </h4>
                        <span
                          className="qst cursor-point"
                          onClick={() =>
                            this.setState({
                              help_modal: !this.state.help_modal,
                            })
                          }
                        >
                          ?
                        </span>
                      </div>

                      <div className="row align-items-center justify-content-between">
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={(refParam) => (enachUploadInputRef = refParam)}
                          onChange={this.handleEnachUploadSelect}
                          accept="application/pdf"
                        />
                        {!download_status && (
                          <div className="col-lg-6">
                            <button
                              type="button"
                              onClick={this.saveFile}
                              className="download_btn"
                            >
                              <span>
                                <img
                                  src="images/icons/download.png"
                                  alt="download"
                                />
                              </span>
                              Download Mandate Form
                            </button>
                          </div>
                        )}

                        <div className="col-lg-6">
                          <button
                            type="button"
                            className="upload"
                            onClick={(e) => {
                              e.stopPropagation();
                              enachUploadInputRef.click();
                            }}
                          >
                            <span>
                              <img src="images/icons/upload.png" alt="upload" />
                            </span>
                            <span>Upload Signed Copy</span>
                            {/* <input type="file" /> */}
                          </button>
                        </div>
                      </div>

                      <div className="my-lg-5 my-4">
                        <h4 className="or text-center">-OR-</h4>
                      </div>

                      {this.state.help_modal && (
                        <div class="row help_modal cursor-point">
                          <div className="fs-16 font-weight-bold container d-flex justify-content-between mb-2">
                            <span>
                              How to share Physical Mandate Registration request
                              with customer?
                            </span>
                            <span>
                              <i
                                className="fa fa-times "
                                onClick={() =>
                                  this.setState({
                                    help_modal: !this.state.help_modal,
                                  })
                                }
                              ></i>
                            </span>
                          </div>
                          <div class="col-3">
                            <div class="d-flex align-items-center justify-content-between">
                              <h6 class="step_txt">Step 1</h6>
                              <div class="arrow"></div>
                            </div>
                            <div>
                              <p class="fz12">
                                Select medium to share the download link with
                                customer.
                              </p>
                            </div>
                          </div>

                          <div class="col-3">
                            <div class="d-flex align-items-center justify-content-between">
                              <h6 class="step_txt">Step 2</h6>
                              <div class="arrow"></div>
                            </div>
                            <div>
                              <p class="fz12">
                                Click on "Send Request" button.
                              </p>
                            </div>
                          </div>

                          <div class="col-3">
                            <div class="d-flex align-items-center justify-content-between">
                              <h6 class="step_txt">Step 3</h6>
                              <div class="arrow"></div>
                            </div>
                            <div>
                              <p class="fz12">
                                Ask the customer to fill in the blank details
                                and EMI details on the mandate.
                              </p>
                            </div>
                          </div>

                          <div class="col-3">
                            <div class="d-flex align-items-center justify-content-between">
                              <h6 class="step_txt">Step 4</h6>
                              <div class="arrow"></div>
                            </div>
                            <div>
                              <p class="fz12">
                                Get the scanned copy of signed mandate from the
                                customer and upload it via "Upload Signed Copy"
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="d-flex justify-content-between align-items-center mt-4  mb-4">
                        <h4 className="cpp_title">
                          Send Physical Mandate completion request to user via
                        </h4>

                        <div className="ml-3 d-flex justify-content-end align-items-center">
                          <label className="switch m-0">
                            <input
                              type="checkbox"
                              name="checkAll"
                              onChange={(e) => this.handleSelectSmsEmail(e)}
                              checked={
                                this.state.smscheck == 1 &&
                                  this.state.emailcheck == 1
                                  ? true
                                  : false
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                          <p className="m-0 w-a-txt ml-3">Select all</p>
                        </div>
                      </div>

                      <div className="d-flex flex-wrap three_checkbox mb-4">
                        <div className="mr-4">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="emailCheck"
                              onChange={(e) => this.handleEmailChange(e)}
                              checked={
                                this.state.emailcheck == 1 ? true : false
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="emailCheck"
                            >
                              Email
                            </label>
                          </div>
                        </div>
                        {/* <div className='mr-4'>
                                                  <div className="custom-control custom-checkbox">
                                                      <input type="checkbox" className="custom-control-input" id="whatsappCheck" />
                                                      <label className="custom-control-label" htmlFor="whatsappCheck">WhatsApp</label>
                                                  </div>
                                              </div> */}
                        <div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="smsCheck"
                              onChange={(e) => this.handleSmsChange(e)}
                              checked={this.state.smscheck == 1 ? true : false}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="smsCheck"
                            >
                              Push Notification/SMS
                            </label>
                          </div>
                        </div>
                      </div>

                      <p className="cpp_title fz14 mb-4">
                        How eMandate registration works?
                      </p>

                      <div className="row mb-5">
                        <div className="col-lg-4">
                          <div className="d-flex align-items-center justify-content-between">
                            <h6 className="step_txt">Step 1</h6>
                            <div className="arrow"></div>
                          </div>
                          <div>
                            <p className="fz12">
                              Open Link and Generate Auto-pay Mandate
                              Instruction
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="d-flex align-items-center justify-content-between">
                            <h6 className="step_txt">Step 2</h6>
                            <div className="arrow"></div>
                          </div>
                          <div>
                            <p className="fz12">Sign Mandate using OTP</p>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="d-flex align-items-center justify-content-between">
                            <h6 className="step_txt">Step 3</h6>
                            <div className="arrow"></div>
                          </div>
                          <div>
                            <p className="fz12">
                              Amount due (if any) is auto-debited from the
                              customer's account monthly
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pr-4">
                      <div className="col-sm-12 d-flex  justify-content-end align-items-center">
                        {/* prev next arrow*/}
                        {/* <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(8)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                              <button type="button" style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(10)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button> */}

                        <Button
                          onClick={this.closeModel}
                          variant="secondary"
                          className="btn cancel border-0"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={this.handleSendPhysicalMandate}
                          variant="secondary"
                          className="btn btn-default_"
                          style={subBtn}
                        >
                          Send
                        </Button>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding == 10 ? (
                  <>
                    <div className="v-scroll_lead">
                      {this.state.successmsg_ ? (
                        <div className="boxed text-center mt-4 position-relative">
                          <h5 className="d-flex align-items-center justify-content-center">
                            <span className="success">
                              <img
                                src="images/tick-white.png"
                                alt="tick-white"
                                className="img-fluid"
                              />
                            </span>
                            Physical Mandate Signed Copy Uploaded!{" "}
                          </h5>
                          <button
                            type="button"
                            className="position-absolute border-0 close"
                          >
                            <span className="">
                              <img
                                src="images/icons/icon-close2.png"
                                alt="icon-close2"
                                className="img-fluid"
                              />
                            </span>
                          </button>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="order_summery_wrapper">
                        <h4 className="cpp_title mt-4 mb-4 px-4">
                          {" "}
                          Order Summary
                        </h4>

                        <div className="row px-4">
                          <div className="col-md-9">
                            <div className="row">
                              <div className="col-md-3">
                                {orderSummary && orderSummary.product_image && (
                                  <img
                                    src={orderSummary.product_image}
                                    alt="loptop"
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <div className="col-md-9 pro_description">
                                <h5>
                                  {orderSummary && orderSummary.product_name
                                    ? orderSummary.product_name
                                    : "- "}
                                </h5>
                                <p>
                                  {orderSummary &&
                                    orderSummary.product_description
                                    ? orderSummary.product_description
                                    : "-"}
                                </p>
                                <span>
                                  {orderSummary && orderSummary.product_sku
                                    ? `SKU: ${orderSummary.product_sku}`
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <p className="price__ color_DarkCerulean font-weight-bold">
                              {orderSummary && orderSummary.product_price
                                ? `₹ ${orderSummary.product_price}`
                                : "-"}
                            </p>
                          </div>
                        </div>

                        <div className="dashed_line my-4"></div>

                        <div className="table__ px-4">
                          <div className="row_table_th">
                            <div className="th">Tenure</div>
                            <div className="th">APR (p.a.)</div>
                            <div className="th">EMI Amount</div>
                            <div className="th">EMI Start Date</div>
                            <div className="th">
                              <button
                                type="button"
                                className="position-absolute dd___"
                                onClick={this.succesmsg_}
                              >
                                <img
                                  src="images/wc-next.png"
                                  alt="drop-down"
                                  className="rotate90"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="row_table_td">
                            <div className="td">
                              {orderSummary.plan &&
                                orderSummary.plan.net_tenure__c
                                ? `${orderSummary.plan.net_tenure__c} months`
                                : "-"}
                            </div>
                            <div className="td">
                              {orderSummary.plan &&
                                orderSummary.plan.interest_rate_apr__c
                                ? `${orderSummary.plan.interest_rate_apr__c}%`
                                : "-"}
                            </div>
                            <div className="td">
                              {orderSummary.plan &&
                                orderSummary.plan.emi_amount__c
                                ? `₹ ${orderSummary.plan.emi_amount__c}`
                                : "0"}
                            </div>
                            <div className="td">
                              {orderSummary.plan &&
                                orderSummary.first_emi_date__c
                                ? orderSummary.first_emi_date__c
                                : "-"}
                            </div>
                            <div className="td"></div>
                          </div>
                        </div>

                        {/* <div className='d-flex tablebottom_row justify-content-between' id="accordion">
                                                  <div className='t_r_txt'>Total Repayble Amount</div>
                                                  <div>
                                                      <div className='t_r_amount'><span>₹</span> {planData && planData.down_payment__c ? planData.down_payment__c.toLocaleString('en-IN') : 0}</div>
                                                      <button className='position-absolute dd_'>
                                                          <img src="images/wc-next-white.png" alt="drop-down" className='rotate90' data-toggle="collapse" data-target="#collapseOne" aria-controls="collapseOne"/>
                                                      </button>
      
                                                  </div> */}
                      </div>

                      <div className="row my-3" id="accordion">
                        <div
                          className="card"
                          style={{ width: "100%", backgroundColor: "#1824AC" }}
                        >
                          <div className="card-header" id="heading-2">
                            <h5 className="mb-0">
                              <a
                                className="collapsed d-flex align-items-center d-flex justify-content-between text-white"
                                role="button"
                                data-toggle="collapse"
                                href="#collapse-2"
                                aria-expanded="false"
                                aria-controls="collapse-2"
                              >
                                <div>Total Repayble Amount</div>
                                <div style={{ marginRight: "22px" }}>
                                  {orderSummary &&
                                    orderSummary.total_repayable_amount
                                    ? `₹ ${orderSummary.total_repayable_amount}`
                                    : "-"}
                                </div>
                              </a>
                            </h5>
                          </div>
                          <div
                            id="collapse-2"
                            className="collapse"
                            data-parent="#accordion"
                            aria-labelledby="heading-2"
                          >
                            <div className="card-body my-3 text-white">
                              <div className="d-flex align-items-center d-flex justify-content-between px-3">
                                <div>EMIs</div>
                                <div>
                                  ₹{" "}
                                  {orderSummary.plan &&
                                    orderSummary.plan.emi_amount__c
                                    ? `${orderSummary.plan.emi_amount__c}`
                                    : "-"}{" "}
                                  x{" "}
                                  {orderSummary.plan &&
                                    orderSummary.plan.net_tenure__c
                                    ? `${orderSummary.plan.net_tenure__c}`
                                    : "-"}
                                </div>
                              </div>
                              <div className="d-flex align-items-center d-flex justify-content-between px-3">
                                <div>Interest</div>
                                <div>
                                  ₹{" "}
                                  {orderSummary && orderSummary.interest
                                    ? ` ${orderSummary.interest}`
                                    : "-"}
                                </div>
                              </div>
                              <div className="d-flex align-items-center d-flex justify-content-between px-3">
                                <div>Pre-Emi Amount</div>
                                <div>
                                  ₹{" "}
                                  {orderSummary &&
                                    orderSummary.plan &&
                                    orderSummary.plan.pre_emi__c
                                    ? `${orderSummary.plan.pre_emi__c}`
                                    : "-"}
                                </div>
                              </div>
                              <div className="d-flex align-items-center d-flex justify-content-between px-3">
                                <div>Other Changes</div>
                                <div>
                                  ₹{" "}
                                  {orderSummary && orderSummary.other_charges
                                    ? `${orderSummary.other_charges}`
                                    : "-"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className='d-flex justify-content-between align-items-center mt-lg-5 mt-4  mb-4'>
                                              <h4 className='cpp_title'>Send Agreement Signing Request to Customer via</h4>

                                              <div className='ml-3 d-flex justify-content-end align-items-center'>
                                                  <label className="switch m-0">
                                                      <input type="checkbox" name='checkAll' />
                                                      <span className="slider round"></span>
                                                  </label>
                                                  <p className='m-0 w-a-txt ml-3'>Select all</p>
                                              </div>

                                          </div> */}

                      {/* 
                                          <div className='d-flex flex-wrap three_checkbox mb-4'>
                                              <div className='mr-4'>
                                                  <div className="custom-control custom-checkbox">
                                                      <input type="checkbox" className="custom-control-input" id="emailCheck" />
                                                      <label className="custom-control-label" htmlFor="emailCheck">Email</label>
                                                  </div>
                                              </div>
                                              <div className='mr-4'>
                                                  <div className="custom-control custom-checkbox">
                                                      <input type="checkbox" className="custom-control-input" id="whatsappCheck" />
                                                      <label className="custom-control-label" htmlFor="whatsappCheck">WhatsApp</label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div className="custom-control custom-checkbox">
                                                      <input type="checkbox" className="custom-control-input" id="smsCheck" />
                                                      <label className="custom-control-label" htmlFor="smsCheck">Push Notification/SMS</label>
                                                  </div>
                                              </div>
                                          </div> */}
                    </div>
                    <div className="row pr-4">
                      <div className="col-sm-12 d-flex  justify-content-end align-items-center">
                        {/* prev next arrow*/}
                        {/* <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(9)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                              <button type="button" style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(11)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button> */}

                        <Button
                          onClick={() => this.onNextBording(9)}
                          variant="secondary"
                          className="cancel border-0"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() =>
                            this.handlePay(
                              planData && planData.down_payment__c
                                ? planData.down_payment__c
                                : 0
                            )
                          }
                          variant="secondary"
                          className="btn btn-default_"
                          style={subBtn}
                        >
                          Pay
                        </Button>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding == 11 ? (
                  <>
                    <div className="v-scroll_lead lp-modal-border">
                      {/* <div className="boxed text-center mt-4 position-relative">
                        <h5 className="d-flex align-items-center justify-content-center">
                          <span className="success">
                            <img
                              src="images/tick-white.png"
                              alt="tick-white"
                              className="img-fluid"
                            />
                          </span>
                          Physical Mandate Signed Copy Uploaded!
                        </h5>
                        <button
                          type="button"
                          className="position-absolute border-0 close"
                        >
                          <span className="">
                            <img
                              src="images/icons/icon-close2.png"
                              alt="icon-close2"
                              className="img-fluid"
                            />
                          </span>
                        </button>
                      </div> */}

                      <div className="order_summery_wrapper">
                                      <div className='d-flex cpp_title mt-4 mb-5'>
                                                            <div className='CourseSummaryStyle'>Order Summary</div>
                                                            <div className='enrolledDiv ml-2 mt-n1'>
                                                                <div className='enrolledText'>Delivered on 31/01/22</div>
                                                            </div>
                                                        </div>
                        {/* <h4 className="cpp_title mt-4 mb-4">
                          {" "}
                          Order Summary
                        </h4> */}

                        {/* <div className='row px-4'>
                                                  <div className='col-md-9'>
                                                      <div className='row'>
                                                          <div className='col-md-3'>
                                                              <img src={proImages && proImages.base64 ? `data:image/jpg;base64,${proImages.base64}` : "images/custom/loptop.png"} alt="loptop" className='img-fluid' />
                                                          </div>
                                                          <div className='col-md-9 pro_description'>
                                                              <h5>{productData && productData.name ? productData.name : ''}</h5>
                                                              <p>Silvergray, Apple M1 Pro with 8-core CPU, 14-core GPU, 16-core Neural Engine</p>
                                                              <span>{productData && productData.sfid ? productData.sfid : ''}</span>
                                                          </div>
                                                      </div>

                                                  </div>
                                                  <div className='col-md-3'>
                                                      <p className='price__'>₹ {productData && productData.mrp__c ? productData.mrp__c.toLocaleString('en-IN') : '50000'}</p>
                                                  </div>
                                              </div> */}

                        <div className="row ">
                          <div className="col-md-9">
                            <div className="row">
                              <div className="col-md-3">
                                {orderSummary && orderSummary.product_image && (
                                  <img
                                    src={orderSummary.product_image}
                                    alt="loptop"
                                    className="img-fluid h-100 br-10"
                                  />
                                )}
                              </div>
                              <div className="col-md-9 pro_description pro_desc_lead">
                                <h5>
                                  {orderSummary && orderSummary.product_name
                                    ? orderSummary.product_name
                                    : "- "}
                                </h5>
                                <p>
                                  {orderSummary &&
                                    orderSummary.product_description
                                    ? orderSummary.product_description
                                    : "-"}
                                </p>
                                <span>
                                  {!orderSummary && orderSummary.product_sku
                                    ? `SKU: ${orderSummary.product_sku}`
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <p className="price__ color_DarkCerulean font-weight-bold newColorForprice">
                              {orderSummary && orderSummary.product_price
                                ? `₹ ${orderSummary.product_price}`
                                : "-"}
                            </p>
                          </div>
                        </div>

                        <div className="dashed_line my-4"></div>

                        <div className="table__ ">
                          <div className="row_table_th">
                            <div className="th">Tenure</div>
                            <div className="th">APR (p.a.)</div>
                            <div className="th">EMI Amount</div>
                            <div className="th">EMI Start Date</div>
                            {/* <div className="th">
                              <button
                                type="button"
                                className="position-absolute dd___"
                              >
                                <img
                                  src="images/wc-next.png"
                                  alt="drop-down"
                                  className="rotate90"
                                />
                              </button>
                            </div> */}
                          </div>
                          <div className="row_table_td mb-4">
                            <div className="td">
                              {orderSummary.plan &&
                                orderSummary.plan.net_tenure__c
                                ? `${orderSummary.plan.net_tenure__c} months`
                                : "-"}
                            </div>
                            <div className="td">
                              {orderSummary.plan &&
                                orderSummary.plan.interest_rate_apr__c
                                ? `${orderSummary.plan.interest_rate_apr__c}%`
                                : "-"}
                            </div>
                            <div className="td">
                              {orderSummary.plan &&
                                orderSummary.plan.emi_amount__c
                                ? `₹ ${orderSummary.plan.emi_amount__c}`
                                : "-"}
                            </div>
                            <div className="td">
                              {orderSummary.plan &&
                                orderSummary.first_emi_date__c
                                ? orderSummary.first_emi_date__c
                                : "-"}
                            </div>
                            {/* <div className="td"></div> */}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-md-6 ">
                            <p className="moratoriumtenu">Moratorium Tenure</p>
                            <h5 className="fz18">
                              {orderSummary &&
                                orderSummary.plan &&
                                orderSummary.moratorium_duration__c
                                ? orderSummary.moratorium_duration__c
                                : "-"}
                            </h5>
                          </div>
                          <div className="col-md-6">
                            <p className="moratoriumtenu">EMI Account</p>
                            {/* <div><span className='d-inline-block' style={{ "width": "20px" }}><img src="images/bank-icon/bank-1.png" alt="" className='img-fluid' /></span>xxxxxxx12001</div> */}
                            <div className="fz18">
                              {orderSummary && orderSummary.bank_account_num
                                ? `xxxxxxx${orderSummary.bank_account_num.substr(
                                  orderSummary.bank_account_num.length - 5,
                                  5
                                )}`
                                : "-"}
                            </div>
                          </div>
                        </div>

                        {/* <div className='d-flex tablebottom_row justify-content-between'>
                                                  <div className='t_r_txt'>Total Repayble Amount</div>
                                                  <div>
                                                      <div className='t_r_amount'><span>₹</span> {planData && planData.down_payment__c ? planData.down_payment__c.toLocaleString('en-IN') : 0} </div>
                                                      <button className='position-absolute dd_'>
                                                          <img src="images/wc-next-white.png" alt="drop-down" className='rotate90' />
                                                      </button>
                                                  </div>

                                              </div> */}
                        </div>
                      </div>
                        <div className="row my-3 px-2" id="accordion">
                          <div
                            className="card"
                            style={{
                              width: "100%",
                              // backgroundColor: "#1824AC",
                              border: "1px solid #E6E6E6",
                              borderRadius: "10px"
                            }}
                          >
                            <div className="card-header" id="heading-2">
                              <h5 className="mb-0 arrowdrop">
                                <a
                                  className="collapsed d-flex align-items-center d-flex justify-content-between text-black"
                                  role="button"
                                  data-toggle="collapse"
                                  href="#collapse-2"
                                  aria-expanded="false"
                                  aria-controls="collapse-2"
                                >
                                  <div className="cardcontentpayment">Total Repayble Amount</div>
                                  <div className="cardcontentpayment" style={{ marginRight: "50px" }}>
                                    {orderSummary &&
                                      orderSummary.total_repayable_amount
                                      ? `₹ ${orderSummary.total_repayable_amount}`
                                      : "-"}
                                  </div>
                                </a>
                              </h5>
                            </div>
                            <div
                              id="collapse-2"
                              className="collapse"
                              data-parent="#accordion"
                              aria-labelledby="heading-2"
                            >
                              <div className="card-body my-3 text-black">
                                <div className="d-flex align-items-center d-flex justify-content-between px-3">
                                  <div className="titlename">EMIs</div>
                                  <div className="titlename">
                                    ₹{" "}
                                    {orderSummary.plan &&
                                      orderSummary.plan.emi_amount__c
                                      ? `${orderSummary.plan.emi_amount__c}`
                                      : "-"}{" "}
                                    x{" "}
                                    {orderSummary.plan &&
                                      orderSummary.plan.net_tenure__c
                                      ? `${orderSummary.plan.net_tenure__c}`
                                      : "-"}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center d-flex justify-content-between px-3 mt-2">
                                  <div className="titlename">Interest</div>
                                  <div className="titlename">
                                    ₹{" "}
                                    {orderSummary && orderSummary.interest
                                      ? ` ${orderSummary.interest}`
                                      : "-"}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center d-flex justify-content-between px-3 mt-2">
                                  <div className="titlename">Pre-Emi Amount</div>
                                  <div className="titlename">
                                    ₹{" "}
                                    {orderSummary &&
                                      orderSummary.plan &&
                                      orderSummary.plan.pre_emi__c
                                      ? `${orderSummary.plan.pre_emi__c}`
                                      : "-"}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center d-flex justify-content-between px-3 mt-2">
                                  <div className="titlename">Other Changes</div>
                                  <div className="titlename">
                                    ₹{" "}
                                    {orderSummary && orderSummary.other_charges
                                      ? `${orderSummary.other_charges}`
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      
                      {isOtpSent && (
                        <div className="col-md-12">
                          <h4
                            className="e_otp_txt mt-5"
                            style={{ textAlign: "center" }}
                          >
                            Enter OTP sent to {hMobile}
                          </h4>
                          <div className="otp-fill-boxes d-flex flex-row justify-content-center">
                            <input
                              ref={this.textInput1}
                              name="otp1"
                              id="otp1"
                              type="text"
                              autoComplete="off"
                              value={this.state.otp1}
                              maxLength={1}
                              onChange={(e) => this.handleOtpChange("otp1", e)}
                              tabIndex="1"
                              placeholder={0}
                              onKeyUp={(e) => this.inputfocus(e, "otp1")}
                              className={`${this.state.otpError && this.state.otp1
                                  ? "error"
                                  : ""
                                }`}
                            />
                            <input
                              ref={this.textInput2}
                              type="text"
                              maxLength={1}
                              name="otp2"
                              id="otp2"
                              autoComplete="off"
                              value={this.state.otp2}
                              onChange={(e) => this.handleOtpChange("otp2", e)}
                              tabIndex="2"
                              placeholder={0}
                              onKeyUp={(e) => this.inputfocus(e, "otp2")}
                              className={`${this.state.otpError && this.state.otp2
                                  ? "error"
                                  : ""
                                }`}
                            />
                            <input
                              ref={this.textInput3}
                              type="text"
                              maxLength={1}
                              name="otp3"
                              id="otp3"
                              autoComplete="off"
                              value={this.state.otp3}
                              onChange={(e) => this.handleOtpChange("otp3", e)}
                              tabIndex="3"
                              placeholder={0}
                              onKeyUp={(e) => this.inputfocus(e, "otp3")}
                              className={`${this.state.otpError && this.state.otp3
                                  ? "error"
                                  : ""
                                }`}
                            />
                            <input
                              ref={this.textInput4}
                              type="text"
                              maxLength={1}
                              name="otp4"
                              id="otp4"
                              autoComplete="off"
                              value={this.state.otp4}
                              onChange={(e) => this.handleOtpChange("otp4", e)}
                              tabIndex="4"
                              placeholder={0}
                              onKeyUp={(e) => this.inputfocus(e, "otp4")}
                              className={`${this.state.otpError && this.state.otp4
                                  ? "error"
                                  : ""
                                }`}
                            />
                          </div>
                          {(this.state.otp1 ||
                            this.state.otp2 ||
                            this.state.otp3 ||
                            this.state.otp4) &&
                            this.state.otpError ? (
                            <div
                              className={"row"}
                              style={{
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span className="d-inline-block invalid_otp">
                                Please enter valid OTP
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          {!this.state.viewResend && (
                            <div
                              className={"row"}
                              style={{
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                            >
                              <p className="mt-2">
                                <img
                                  src={"images/icons/icon-ind.png"}
                                  alt="icon-ind2"
                                  className="img-fluid"
                                />{" "}
                                Verification code valid for next{" "}
                                {" " + this.state.timer} min
                              </p>
                            </div>
                          )}
                          {this.state.viewResend && (
                            <div
                              className={"row"}
                              style={{
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                type="button"
                                onClick={this.handleResendSendOtp}
                                className="d-inline-block resend-btn mt-2"
                              >
                                Resend OTP
                              </button>
                            </div>
                          )}
                        </div>
                      )}

                      {/* <div className='d-flex justify-content-between align-items-center mt-lg-5 mt-4  mb-4'>
                                              <h4 className='cpp_title'>Send Agreement Signing Request to Customer via</h4>

                                              <div className='ml-3 d-flex justify-content-end align-items-center'>
                                                  <label className="switch m-0">
                                                      <input type="checkbox" name='checkAll' />
                                                      <span className="slider round"></span>
                                                  </label>
                                                  <p className='m-0 w-a-txt ml-3'>Select all</p>
                                              </div>

                                          </div> */}

                      {/* 
                                          <div className='d-flex flex-wrap three_checkbox mb-4'>
                                              <div className='mr-4'>
                                                  <div className="custom-control custom-checkbox">
                                                      <input type="checkbox" className="custom-control-input" id="emailCheck" checked={true} />
                                                      <label className="custom-control-label" htmlFor="emailCheck">Email</label>
                                                  </div>
                                              </div>
                                              <div className='mr-4'>
                                                  <div className="custom-control custom-checkbox">
                                                      <input type="checkbox" className="custom-control-input" id="whatsappCheck" />
                                                      <label className="custom-control-label" htmlFor="whatsappCheck">WhatsApp</label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div className="custom-control custom-checkbox">
                                                      <input type="checkbox" className="custom-control-input" id="smsCheck" />
                                                      <label className="custom-control-label" htmlFor="smsCheck">Push Notification/SMS</label>
                                                  </div>
                                              </div>
                                          </div> */}
                   

                    <div className="row justify-content-between align-items-center mb-4">
                      <div className="col-md-5">
                        {/* <input
                          type="text"
                          style={{ width: "90%" }}
                          placeholder="Input serial number"
                          className="input_style_"
                        /> */}
                      </div>
                      <div className="col-md-7 d-flex justify-content-end text-right">
                        {/* prev next arrow*/}
                        {/* <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(10)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                              <button type="button" style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(12)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button> */}

                        <Button
                          onClick={this.closeModel}
                          variant="secondary"
                          className="btn border-0 cancel cancelSummary mr-4"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() =>
                            this.handleSendAgreementSigning(
                              planData && planData.id ? planData.id : 0
                            )
                          }
                          variant="secondary"
                          className="btn btn-default_ approvebtn"
                          style={subBtn}
                        >
                          Approve
                        </Button>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding == 12 ? (
                  <>
                    <div className="v-scroll_lead">
                      <div className="boxed mt-4">
                        <div className="d-flex justify-content-between mb-lg-5 mb-4">
                          <h5 className="m-0">Delivery Address</h5>
                          <button
                            type="button"
                            onClick={() => this.addNewAddress(2)}
                            className="btn btn-sm border-0 btn-primary color_1 shadow-none"
                          >
                            <i className="fas fa-plus"></i> Add New
                          </button>
                        </div>
                        {addressList &&
                          addressList.length > 0 &&
                          addressList.map((item, index) => (
                            <div className="address_box mb-4">
                              <div className="address_checkbox">
                                <input
                                  type="radio"
                                  value={item.id}
                                  name="selectedAddress"
                                  id={`selectedAddress-${item.id}`}
                                  defaultChecked={`${this.state.selectedAddress === item.id
                                      ? "checked"
                                      : ""
                                    }`}
                                  onClick={() => this.selectAddress(item.id)}
                                />
                                <label></label>
                              </div>
                              <div className="address address_checked">
                                <h5>{item.address__c}</h5>
                                <p>
                                  Source: <span>{item.name}</span>
                                </p>
                              </div>
                              {/* <button className='position-absolute edit_address_btn'>
                                                              <img src="images/icons/edit_20.png" alt="" className='rotate180' />
                                                          </button> */}
                            </div>
                          ))}
                      </div>

                      <div className="order_summery_wrapper mt-4">
                        <h4 className="cpp_title mt-4 mb-4 px-4">
                          {" "}
                          Order Summary
                        </h4>
                        <div className="d-flex tablebottom_row justify-content-between">
                          <div className="t_r_txt">Total Repayble Amount</div>
                          <div>
                            <div className="t_r_amount">
                              <span>₹</span>{" "}
                              {planData && planData.down_payment__c
                                ? planData.down_payment__c.toLocaleString(
                                  "en-IN"
                                )
                                : 0}
                            </div>
                            <button className="position-absolute dd_">
                              <img
                                src="images/wc-next-white.png"
                                alt="drop-down"
                                className="rotate90"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex align-items-center mt-4">
                            <div className="single_check mr-2">
                              <input type="checkbox" />
                              <label></label>
                            </div>
                            <label className="m-0">
                              I have received the upfront payment from the
                              customer.
                            </label>
                          </div>
                        </div>
                        <div className="col-12 mt-3">
                          <button className="link">
                            <span
                              className="d-inline-block mr-2"
                              style={{ width: "12px" }}
                            >
                              <img
                                src="images/icons/tick-mark.png"
                                alt="tick-mark"
                              />
                            </span>
                            The amount collected from the customer will be
                            deducted from the disbursal amount.
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row pr-4">
                      <div className="col-sm-12 d-flex justify-content-end align-items-center">
                        {/* prev next arrow*/}
                        {/* <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(11)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                              <button type="button" style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(13)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button> */}

                        <Button
                          onClick={() => this.onNextBording(11)}
                          variant="secondary"
                          className="btn border-0 cancel"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={this.updateDeliveryAddress}
                          disabled={
                            this.state.selectedAddress != "" ? false : true
                          }
                          style={this.state.selectedAddress != "" ? subBtn : {}}
                          variant="secondary"
                          className="btn btn-default_"
                        >
                          Save &amp; Next
                        </Button>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding == 13 ? (
                  <>
                    <div className="v-scroll_lead">
                      <h5 className="mt-4 mb-3"> Enter PAN</h5>
                      <div className="pansection text-center">
                        <div className="paninput">
                          <input
                            type={"text"}
                            name="pan"
                            className="PANtextbox"
                            placeholder="XXXXXXXXXX"
                            value={this.state.pan ? this.state.pan : ""}
                            maxLength="10"
                            onChange={this.handlePanChange}
                          />
                        </div>
                        <div>
                          <p>OR</p>
                          <label for="file_input_id" className="upload_pan_btn">
                            Upload PAN Card
                          </label>
                          <input
                            type="file"
                            id="file_input_id"
                            onChange={(e) => {
                              this.getPanDetails(e);
                            }}
                          ></input>
                        </div>
                        <div className="d-block text-center">
                          <div className="row error-msg">
                            {this.state.isValidPan === false &&
                              this.state.errorMsg !== "" ? (
                              <span>{this.state.errorMsg}</span>
                            ) : (
                              ""
                            )}
                            {this.state.successMsg != "" &&
                              this.state.isSuccess === 0 ? (
                              <span>{this.state.successMsg}</span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-between align-items-center pr-4">
                      <div className="col-lg-5 form_notes mt-0">
                        {/* <button className="link">
                          <span className="d-inline-block mr-2">
                            <img
                              src="images/icons/tick-mark.png"
                              alt="tick-mark"
                            />
                          </span>
                          This will not affect the applicant's Credit Score
                        </button> */}
                        {/* <p className="color_1 mb-0"><img src="images/icons/tick-mark.png" alt="tick-mark" className='img-fluid'/> </p> */}
                      </div>

                      <div className="col-lg-7 d-flex justify-content-end">
                        {/* prev next arrow*/}
                        {/* <button type="button" style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(1)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button> */}

                        {/* <button
                          disabled={
                            this.state.pan &&
                              this.state.pan !== "" &&
                              this.state.isValidPan === true
                              ? false
                              : true
                          }
                          type="button"
                          style={{ cursor: "pointer" }}
                          className="btn btn-default"
                        >
                          <i className="fas fa-arrow-right"></i>
                        </button> */}

                        <Button
                          type="button"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.onNextBording(0)}
                          className="border-0 btn cancel mr-4"
                        >
                          Cancel
                        </Button>

                        <Button
                          type="button"
                          onClick={this.updatePan}
                          className="btn btn-secondary"
                          disabled={
                            this.state.pan &&
                              this.state.pan !== "" &&
                              this.state.isValidPan === true
                              ? false
                              : true
                          }
                          style={
                            this.state.pan &&
                              this.state.pan !== "" &&
                              this.state.isValidPan === true
                              ? subBtn
                              : {}
                          }
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding === 14 ? (
                  <>
                    <div className="v-scroll_lead">
                      <h5 className="mt-4 mb-3"> Approval Pending</h5>
                    </div>
                  </>
                ) : this.state.onBoarding === 15 ? (
                  <>
                    <div className="v-scroll_lead">
                      <h5 className="mt-4 mb-3"> Approval Rejected</h5>
                    </div>
                  </>
                ) : this.state.onBoarding === 16 ? (
                  <div className="row">
                    <div className="col-md-12 success-popup text-center">
                      <h5>Congratulations!</h5>
                      <p className="t1">Your order has been completed</p>
                      <div className="row align-items-center justify-content-center">
                        <div className="col-md-12 prizeamt">
                          <i className="fas fa-check-circle"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : this.state.onBoarding === 17 ? (
                  <>
                    <div className="v-scroll_lead">
                      <div className="mn_height_57">
                        <div className="boxed mt-4">
                          <h5 className="mb-3">
                            Add Delivery Address{" "}
                            <button
                              type="button"
                              className="btn btn-sm border-0 btn-primary shadow-none float-right"
                            >
                              <i className="fas fa-info-circle f-16"></i>
                            </button>
                          </h5>

                          <div className="row w-100">
                            <div className="col-sm-12 form-group">
                              <label htmlFor="" className="form-label">
                                Address Line
                              </label>
                              {this.state.gmapsLoaded && (
                                <PlacesAutocomplete
                                  value={this.state.address}
                                  style={{ width: "100%" }}
                                  onChange={this.handlePlacesChange}
                                  onSelect={this.handleSelect}
                                >
                                  {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                  }) => (
                                    <div className="form-group position-relative">
                                      <input
                                        {...getInputProps({
                                          placeholder: "Search Area/Road ...",
                                          className:
                                            "form-control location-search-input",
                                        })}
                                      />
                                      <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(
                                          (suggestion, index) => {
                                            const className = suggestion.active
                                              ? "suggestion-item--active"
                                              : "suggestion-item";
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                              ? {
                                                backgroundColor: "#eaeaea",
                                                cursor: "pointer",
                                              }
                                              : {
                                                backgroundColor: "#f5f5f5",
                                                cursor: "pointer",
                                              };
                                            return (
                                              <div
                                                key={"item" + index}
                                                {...getSuggestionItemProps(
                                                  suggestion,
                                                  {
                                                    className,
                                                    style,
                                                  }
                                                )}
                                              >
                                                <span key={index}>
                                                  {suggestion.description}
                                                </span>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete>
                              )}
                            </div>
                            <div className="col-sm-6 form-group">
                              <label htmlFor="" className="form-label">
                                City
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                onChange={this.handleChange}
                                value={city}
                                name="city"
                              />
                            </div>
                            <div className="col-sm-6 form-group">
                              <label htmlFor="" className="form-label">
                                State
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="State"
                                name="state"
                                value={state}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="col-md-6 form-group clearfix">
                              <label htmlFor="" className="form-label">
                                Pincode
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="000 000"
                                maxLength={"6"}
                                name="address_pin"
                                id="address_pin"
                                value={this.state.address_pin}
                                onChange={this.onlyNumbers}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-between align-items-center pr-4">
                      <div className="col-lg-5 form_notes mt-0">
                        <button className="link">
                          <span className="d-inline-block mr-2">
                            <img
                              src="images/icons/tick-mark.png"
                              alt="tick-mark"
                            />
                          </span>
                          User's data will not be saved in case you cancel or
                          close the page.4
                        </button>
                      </div>
                      {/* prev next arrow*/}
                      <div className="col-lg-7 d-flex justify-content-end">
                        {/* <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(2)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                              <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(4)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button> */}

                        <Button
                          style={{ cursor: "pointer" }}
                          type="button"
                          onClick={this.closeModel}
                          className="border-0 mr-4 btn cancel"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="button"
                          onClick={this.addDeliveryAddress}
                          className="btn btn-default_"
                          disabled={
                            address != "" &&
                              state != "" &&
                              city &&
                              pincode != ""
                              ? false
                              : true
                          }
                          style={
                            address != "" &&
                              state != "" &&
                              city &&
                              pincode != ""
                              ? subBtn
                              : {}
                          }
                        >
                          Save &amp; Next
                        </Button>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding == 18 ? (
                  <>
                    <div className="v-scroll_lead">
                      <div className="boxed max_images mt-4">
                        <div className="row">
                          <div className="col-sm-12 form-group">
                            <h6 className="h6_1">
                              {"Identity Proof > Upload Atleast One"}
                            </h6>
                            <Nav
                              variant="pills"
                              activeKey={this.state.selectedBankTab}
                              onSelect={this.handleBankTab}
                              defaultActiveKey={this.state.selectedBankTab}
                            >
                              <Nav.Item>
                                <Nav.Link
                                  eventKey={1}
                                  disabled={
                                    defaultBankTab != 0 && defaultBankTab != 1
                                      ? true
                                      : false
                                  }
                                >
                                  Cancelled Cheque
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey={2}
                                  disabled={
                                    defaultBankTab != 0 && defaultBankTab != 2
                                      ? true
                                      : false
                                  }
                                >
                                  Bank Passbook
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey={3}
                                  disabled={
                                    defaultBankTab != 0 && defaultBankTab != 3
                                      ? true
                                      : false
                                  }
                                >
                                  Bank Statement
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </div>
                          <div className="col-md-12">
                            <input
                              type="file"
                              style={{ display: "none" }}
                              ref={(refParam) => (bankProofInputRef = refParam)}
                              onChange={this.handleBankProofSelect}
                              accept="image/x-png,image/jpeg,image/jpg, application/pdf"
                            />
                            <div className="row">
                              {bankProofSrc &&
                                bankProofSrc.length > 0 &&
                                bankProofSrc.map((item, index) => (
                                  <div
                                    key={`pdf-${index}`}
                                    className="col-md-3"
                                  >
                                    <img
                                      onClick={() => this.openPdf(item)}
                                      style={{ cursor: "pointer" }}
                                      src="images/icons/pdf.png"
                                      alt=""
                                    />
                                  </div>
                                ))}
                            </div>
                            {!bankProofSrc && (
                              <button
                                type="button"
                                onClick={() => bankProofInputRef.click()}
                                className="btn btn-upload"
                              >
                                <i className="fas fa-upload"></i> Upload
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding === 19 ? (
                  <>
                    <div className="v-scroll_lead">
                      <div className="boxed max_images">
                        <div className="row">
                          <div className="col-sm-12 form-group">
                            <h5 className="mb-2">Financial Documents</h5>
                            <ul className="listinputs">
                              <li>Max file size 5MB</li>
                              <li>Pdf only</li>
                            </ul>
                          </div>
                          <div className="row">
                            {bankSrc &&
                              bankSrc.length > 0 &&
                              bankSrc.map((item, index) => (
                                <div key={`pdf-${index}`} className="col-md-3">
                                  <img
                                    onClick={() => this.openPdf(item)}
                                    style={{ cursor: "pointer" }}
                                    src="images/icons/pdf.png"
                                    alt=""
                                  />
                                  <p
                                    style={{
                                      color: "#1824AC",
                                      cursor: "pointer",
                                      marginTop: "12px",
                                      textAlign: "center",
                                    }}
                                    onClick={() =>
                                      this.setState({ bankSrc: "" })
                                    }
                                  >
                                    remove
                                  </p>
                                </div>
                              ))}
                          </div>
                          {!bankSrc && (
                            <div className="col-md-3 form-group">
                              <h6 className="h6_2">Bank Statement</h6>
                              <ul>
                                {this.state.bankDocument &&
                                  this.state.bankDocument.length > 0 &&
                                  this.state.bankDocument.map((item, index) => (
                                    <li key={`statement-${index}`}>
                                      {item.name}
                                      <span
                                        style={{
                                          float: "right",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => this.deleteRow(index)}
                                      >
                                        X
                                      </span>
                                    </li>
                                  ))}
                              </ul>

                              <input
                                type="file"
                                style={{ display: "none" }}
                                ref={(refParam) =>
                                  (bankSlipInputRef = refParam)
                                }
                                onChange={this.handleBankSlipSelect}
                                accept="application/pdf"
                                multiple
                              />
                              <button
                                type="button"
                                onClick={() => bankSlipInputRef.click()}
                                className="btn btn-upload"
                              >
                                <i className="fas fa-upload"></i> Upload
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row pr-4">
                      <div className="col-sm-12 d-flex  justify-content-end align-items-center">
                        {/* prev next arrow*/}
                        {/* <button style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(4)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                              <button style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(6)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button> */}

                        <Button
                          style={{ cursor: "pointer" }}
                          type="button"
                          onClick={() => this.onNextBording(4)}
                          className="border-0 btn cancel mr-4"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="button"
                          //  onClick={() => this.onNextBording(6)}
                          variant="secondary"
                          className="btn btn-default_"
                          onClick={this.handleStatementNext}
                          disabled={bankSrc.length > 0 ? false : true}
                          style={bankSrc.length > 0 ? subBtn : {}}
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding === 20 ? (
                  <>
                    <div className="v-scroll_st px-4">
                      <div className="mb-5 pb-5">
                        <div className="p-lg-5 p-4 shadow rounded mb-4">
                          <div className="row align-items-center">
                            <div className="col-3">
                              <img src="images/icons/thumbsup.png"></img>
                            </div>
                            <div className="col-9">
                              <p>
                                Sit back &amp; relax till customer completes
                                bank details verification!
                              </p>
                            </div>
                          </div>
                        </div>
                        {this.state.sendagainshow ? (
                          <p className="text-right pt-4">
                            Customer didn't receive the link?
                            <button
                              onClick={this.handleSendAgain}
                              className="links"
                            >
                              Send Again
                            </button>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="text-right">
                        <button className="proceed-btn">
                          <img src="images/loadinfo2.gif"></img> Waiting for
                          customer to proceed...
                        </button>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding === 22 ? (
                  <>
                    <div className="v-scroll_lead">
                      <div className="boxed mt-4">
                        <h5 className="mb-3">Co-Borrower’s Details</h5>
                        <div className="row">
                          <div className="col-sm-6 mt-2 form-group">
                            <label htmlFor="" className="form-label">
                              First Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              onChange={this.handleChangeCo}
                              value={this.state.co_first_name}
                              name="co_first_name"
                            />
                          </div>
                          <div className="col-sm-6 mt-2 form-group">
                            <label htmlFor="" className="form-label">
                              Last Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              onChange={this.handleChangeCo}
                              value={this.state.co_last_name}
                              name="co_last_name"
                            />
                          </div>
                          <div className="col-sm-6 mt-2 form-group">
                            <label htmlFor="" className="form-label">
                              Email ID
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Email ID"
                              onChange={this.handleChangeCo}
                              value={this.state.co_email}
                              name="co_email"
                            />
                            {!this.state.co_isEmail && (
                              <p style={{ color: "red" }}>Enter valid email</p>
                            )}
                          </div>
                          <div className="col-sm-6 mt-2 form-group">
                            <label htmlFor="" className="form-label">
                              Mobile Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={"10"}
                              placeholder="Mobile Number"
                              onChange={this.handleChangeCo}
                              value={this.state.co_number}
                              name="co_number"
                            />
                            {!this.state.co_isNumber && (
                              <p style={{ color: "red" }}>Enter valid Number</p>
                            )}
                          </div>
                          <div className="col-sm-6 mt-2 form-group">
                            <label htmlFor="" className="form-label">
                              Relationship with Borrower
                            </label>
                            <select
                              name="co_relation"
                              onChange={this.handleChangeCo}
                              className="form-control"
                              value={this.state.co_relation}
                            >
                              <option value="">Select Relation</option>
                              <option value="Father">Father</option>
                              <option value="Mother">Mother</option>
                              <option value="Friend">Friend</option>
                              <option value="Brother">Brother</option>
                              <option value="Sister">Sister</option>
                              <option value="Husband">Husband</option>
                              <option value="Wife">Wife</option>
                            </select>
                            {/* <input
                                                          type="text"
                                                          
                                                          placeholder="First Name"
                                                          
                                                          // value={city}
                                                          name="co_first_name"
                                                      /> */}
                          </div>
                          <div className="col-sm-6 mt-2 form-group">
                            <label htmlFor="" className="form-label">
                              Pan
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Pan"
                              maxLength={"10"}
                              onChange={this.handleChangeCo}
                              value={this.state.co_pan}
                              name="co_pan"
                              style={{ textTransform: "uppercase" }}
                            />
                            {!this.state.co_isPan && (
                              <p style={{ color: "red" }}>
                                Enter valid PAN number
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="d-flex justify-content-end mt-4">
                          {/* <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(2)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                                  <button type='button' style={{ cursor: 'pointer' }} onClick={() => this.onNextBording(4)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button> */}

                          {/* <Button style={{ cursor: 'pointer' }} type='button' onClick={this.closeModel} className="border-0 mr-4 btn cancel">Previous</Button> */}
                          <Button
                            type="button"
                            onClick={this.submitCoDetails}
                            className="btn btn-default_"
                            disabled={
                              co_isEmail &&
                                co_email != "" &&
                                co_isPan &&
                                co_pan != "" &&
                                co_isNumber &&
                                co_number != "" &&
                                co_first_name != "" &&
                                co_last_name != "" &&
                                co_relation != ""
                                ? false
                                : true
                            }
                            style={
                              co_isEmail &&
                                co_email != "" &&
                                co_isPan &&
                                co_pan != "" &&
                                co_isNumber &&
                                co_number != "" &&
                                co_first_name != "" &&
                                co_last_name != "" &&
                                co_relation != ""
                                ? subBtn
                                : {}
                            }
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : this.state.onBoarding === 21 ? (
                  <>
                    <div className="loader"></div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Scrollbar>
          </Modal.Body>
        </Modal>
        <ToastContainer />
        <Modal show={this.state.showConsentModal} onHide={() => this.setState({ showConsentModal: false, showInputUpdatePopupCount: 1 })}>

          <Modal.Body style={{ padding: '15px' }}>A change in income will result in recapturing the consent.</Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button style={{ backgroundColor: '#007bff', padding: '4px 20px' }} variant="primary" onClick={() => this.setState({ showConsentModal: false, showInputUpdatePopupCount: 1 })}>
              OK
            </Button>
            {/* <Button  style={{backgroundColor:'#007bff'}} variant="primary" onClick={() => this.setState({showConsentModal:false,showInputUpdatePopupCount:1})}>
         Cancel
        </Button> */}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    lead_profile_show,
    document_drop_show,
    uploadAllModalCloseSuccess,
    higherLimit,
    clickedOnce
  } = state.model;
  const {
    banks,
    opp_id,
    lead_id,
    lead_profile,
    entity,
    entitySearch,
    seettlemt_opp_id,
    orderSummary,
  } = state.user;
  const { user_id, salesForceToken, sfid } = state.auth;
  const { plans, product } = state.payment;

  return {
    document_drop_show,
    lead_profile_show,
    lead_profile,
    entitySearch,
    banks,
    entity,
    opp_id,
    lead_id,
    user_id,
    sfid,
    plans,
    product,
    salesForceToken,
    seettlemt_opp_id,
    orderSummary,
    uploadAllModalCloseSuccess,
    higherLimit,
    clickedOnce,
  };
}

export default connect(mapStateToProps)(LeadsProfile);

import React from 'react';
import $ from 'jquery';
import { openAddAccount } from "../actions/model";
import AddNewAccount from '../model/add-account.component';
import { getUserBankListWizr, deleteBank, getUpdateUserData, getBankDetailsOfUser, updateBankUserStatus,logout } from "../actions/user";
import EditNewAccount from '../model/edit-account.components';
import TablePagination from '@mui/material/TablePagination';
import tableSort from "../common/tableSort"
import NoRecordsFound from "../common/NoRecordsFound.component";
import { history } from '../helpers/history';


export default class BankDetails extends React.Component {
    constructor(props) {
        super(props);
        this.deleteBankDetails = this.deleteBankDetails.bind(this);
        this.state = {
            userData: null,
            bank_account: null,
            editData: {},
            bankEditSfid: '',
            bankData: [],
            rowsPerPage: 10,
            page: 0,
            limit: 10,
            tData: '',
            final_bank_account: [],
        }
    }

    componentDidMount() {
        // this.getBankList();
        // let data = {
        //     user_sfid: this.props.sfid
        // }

        // this.props.dispatch(getUserBankList(data)).then(res => {
        //     if (res.status === "success") {
        //         this.setState({ bankData: res.data });
        //     }
        // })
        /* this.props.dispatch(getProfileData(data)).then((response)=>{
            if(response.status ==="success")
            {
                this.setState({userData: response.accountDet});
            }
        }); */
    }

    getBankList = () => {
        let data = {
            // user_sfid: this.props.sfid
            merchant_id: this.props.sfid
        }
        this.props.dispatch(getUserBankListWizr(data)).then((response) => {
            let { page, limit } = this.state;
            if (response && response.status == 'success') {
                this.setState({ bank_account: response.data, final_bank_account: response.data.slice(page * limit, page * limit + limit) });
            } else {
                this.setState({ bank_account: null, final_bank_account: [] });
                if(response.status == 401){
                    this.props.dispatch(logout());
                    history.push('/login');
                }
            }
        });
    }

    handleSatusChange = (status, id) => {
        let data = {
            user_sfid: this.props.sfid
        }
        let obj = {
            "account_id": id,
            "status": !status
        }
        this.props.dispatch(updateBankUserStatus(obj)).then(res => {
            if (res.status === "success") {
                this.props.dispatch(getUserBankListWizr(data)).then(res => {
                    if (res.status === "success") {
                        this.setState({ bankData: res.data });
                    }
                });
            }
        })
    }

    openAddModel = () => {
        this.props.dispatch(openAddAccount());
    }

    deleteBankDetails = (bank_sfid) => {
        this.props.dispatch(deleteBank(bank_sfid)).then(response => {
            if (response.status == "success") {
                this.getBankList();
            } else {
                alert(response.message)
            }
        })
            .catch(error => {
                // console.log(error, '>>>>>>>>')
            })
    }

    updateBankDetails = (item) => {
        this.props.dispatch(getBankDetailsOfUser(item.id)).then(res => {
            if (res.status === 'success') {
                this.props.dispatch(getUpdateUserData(res.bankAccDet[0]));
            }
        })

    }


    handlebankModal = () => {
        $('#banklist_btn').trigger('click');
    }

    handleChangePageNew = (event, newPage) => {
        // this.setState({page:updatedPage,newPage})
        // let {page} = this.state;
        // let updatedPage = page+1;
        // this.setState({page:newPage})
        const { bank_account, limit } = this.state;

        const user = bank_account.slice(newPage * limit, newPage * limit + limit);
        this.setState({ page: newPage, final_bank_account: user })
    };

    handleChangeRowsPerPage = (event) => {
        const { bank_account, page } = this.state;
        const user = bank_account.slice(0, parseInt(event.target.value, 10));
        this.setState({ limit: parseInt(event.target.value, 10), page: 0, final_bank_account: user })
    };
    sortBy = (givenArray, sortKey, order) => {
        const sortedData = tableSort(givenArray, sortKey, order);
        this.setState({ final_bank_account: sortedData })
    }

    componentDidUpdate(prevProps){
        if(prevProps.showBanksData!==this.props.showBanksData){
           if(this.props.showBanksData){
            this.getBankList();
        }
    }
    }
    render() {
        const { merchant_banks } = this.props;
        const { bank_account, final_bank_account,page,limit } = this.state;
        const totalPages = bank_account?.length ? Math.ceil(bank_account.length / Number(this.state.limit)) : 1;
        return (
            <>

                <div className="card">
                    {/* <div className="card-header">
                        <h2 className="mb-0 position-relative">
                            <button
                                className="btn btn-link accordion_btn collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                            >Bank Details
                            </button>
                            <button type='button' onClick={this.openAddModel} className="d-sm-inline-block btn btn-sm btn-primary btn-dark add_btn_pos" >
                    <i className="fas fa-plus"></i>Add New Account</button>
                            <button type='button' data-toggle="modal" data-target="#addNewAccountModal" className="d-sm-inline-block btn btn-sm btn-primary btn-dark add_btn_pos" >
                                <i className="fas fa-plus"></i>Add New Account</button>
                                <button type='button' data-toggle="modal" id="banklist_btn" data-target="#bankListModal" className="d-none" >
                                <i className="fas fa-plus"></i>x</button>
                        </h2>
                    </div> */}

                    <div className="card-body">
                        <div className="table-responsive">
                            <table
                                className="table dataTable no-footer tableStyle"
                                id="dataTable22"
                                cellSpacing={0}
                                accountDetaills={this.accountDetaills}
                            >
                                <thead>
                                    <tr>
                                        <th width="3%"><div className="hash">#</div></th>
                                        <th width="17%">
                                            <div className="d-flex align-items-center gap10">Account Name
                                                <div className="" style={{ display: 'grid' }}>
                                                    <button onClick={() => this.sortBy(this.state.final_bank_account, 'account_name__c', 1)} className="up"></button>
                                                    <button onClick={() => this.sortBy(this.state.final_bank_account, 'account_name__c', -1)} className="down"></button>
                                                </div>
                                            </div>
                                        </th>
                                        <th width="20%">Account Number</th>
                                        <th width="25%">
                                            Bank Name
                                        </th>
                                        <th width="20%">
                                            <div className="d-flex align-items-center gap10">Branch Name
                                                <div className="" style={{ display: 'grid' }}>
                                                    <button onClick={() => this.sortBy(this.state.final_bank_account, 'branch_name__c', 1)} className="up"></button>
                                                    <button onClick={() => this.sortBy(this.state.final_bank_account, 'branch_name__c', -1)} className="down"></button>
                                                </div>
                                            </div>
                                        </th>
                                        <th width="15%">IFSC Code
                                        </th>
                                        {/* <th className='text-center'>Action</th> */}

                                    </tr>
                                </thead>
                                {final_bank_account.length > 0 ?
                                    <tbody>
                                        {final_bank_account && final_bank_account.length > 0 && (
                                            final_bank_account.map((item, index) => (
                                                <tr key={index} className="bank-tr">
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="t_r_number IndexNumber">{(page*limit)+(index + 1)}</div>
                                                            {/* <div>
                                    <div className="sale_ribbon">Sale</div>
                                    <div className="new_ribbon">New</div>
                                </div> */}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="link_">{item.account_name__c}</p>
                                                    </td>
                                                    <td>
                                                        <p className="email_ID">{item.account_number__c}</p>
                                                    </td>

                                                    <td>
                                                        <span>{item.bank_name__c}</span>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <span>{item.branch_name__c}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <span>{item.ifsc__c}</span>
                                                        </div>
                                                    </td>
                                                    {/* <td>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <button className='edit_btn'>
                                                            <img src="images/icons/edit_20.png" alt="" className='img-fluid' />
                                                        </button>
                                                        <div className="switch_btn d-flex justify-content-center align-items-center" style={{ color: `${item.bank_txn_status__c == true ? '#094588' : ''}` }}>
                                                            <label className="switch mr-3">
                                                                <input type="checkbox"
                                                                    onChange={(e) => this.handleSatusChange(item.bank_txn_status__c, item.id)}

                                                                    // defaultChecked={item.activate_product__c? item.activate_product__c : true}

                                                                    // defaultChecked={item.activate_product__c? true:false}

                                                                    // current update for teting pursure

                                                                    //defaultChecked={()=>{item.activate_product__c == true ? true :false}}

                                                                    //defaultChecked={item.activate_product__c && item.activate_product__c == true ? true : false}

                                                                    checked={item.bank_txn_status__c == true ? true : false}

                                                                />
                                                                <span className="slider round"></span>
                                                            </label>

                                                            {item.bank_txn_status__c == true ? "Active" : "Inactive"}
                                                        </div>
                                                        &nbsp;&nbsp; &nbsp;&nbsp; 


                                                        <button type='button' data-toggle="modal" data-target="#editNewAccountModal" onClick={() => { this.updateBankDetails(item) }} >
                                                            <i class="fa fa-pencil fa-lg" aria-hidden="true"></i>

                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <button type='button' data-toggle="modal" onClick={() => {this.deleteBankDetails(item.id)}}  >
                                                        <i class="fa fa-trash fa-lg" aria-hidden="true"></i>
                                                       </button>
                                                    </div>
                                                </td> */}
                                                </tr>
                                            ))
                                        )
                                        }
                                    </tbody> :
                                    <tbody>
                                        <td colSpan="10"><NoRecordsFound message={'No Data to show '} /></td></tbody>
                                }
                            </table>
                            {final_bank_account.length > 0 ?
                                <div className="d-flex align-items-center justify-content-lg-end justify-content-center mb-4">
                                    {/* <div className="d-flex align-items-center row_per_page mr-lg-5 mr-4">
                                    <p>Row per page</p>
                                    <select page={this.state.page} onChange={this.handleChangelimitOfPage}>
                                      <option value={10}>10</option>
                                      <option value={20}>20</option>
                                      <option value={30}>30</option>
                                    </select>
                                  </div> */}
                                    <div className="d-flex align-items-center pages">
                                        {/* <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} /> */}
                                        <TablePagination
                                            component="div"
                                            count={this.state.bank_account?.length ? this.state.bank_account.length : 0}
                                            page={this.state.page}
                                            onPageChange={this.handleChangePageNew}
                                            rowsPerPage={this.state.limit}
                                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                                            rowsPerPageOptions={[10, 20, 50, 100]}
                                            labelDisplayedRows={({ page }) => {

                                                return `Page: ${this.state.page + 1} of ${totalPages} `;

                                            }}

                                        />
                                    </div>

                                </div> : ''}
                        </div>

                    </div>
                </div>
                {/* <AddNewAccount
                    handlebankModal={this.handlebankModal}
                    add_account={this.props.add_account}
                    dispatch={this.props.dispatch}
                    banks={this.props.banks}
                    user_sfid={this.props.sfid}
                    getBankList={this.getBankList}
                />
                <EditNewAccount
                    edit_account={this.props.edit_account}
                    dispatch={this.props.dispatch}
                    banks={this.props.banks}
                    user_sfid={this.props.sfid}
                    getBankList={this.getBankList}
                    bankEditSfid={this.state.bankEditSfid}
                /> */}

                {/* <BankListModal
                    handlebank={this.handlebank}
                    banks={this.props.banks}
                /> */}


            </>
        );
    }

}
import React, { Component } from 'react';
import $ from "jquery";
import { openProfileModel } from "../actions/model"
import { Link } from "react-router-dom";
import TopBarRight from './tobar-rigth';
import { getGlobalSearch, setShowSearched, emptyserachList, setSearchedField, getSkillsGlobalSearch, getMerchantProductsWizr, getMerchantProductsWizrSearch } from "../actions/user"
//import { useParams } from 'react-router-dom';

import {
    openBulkModel,
    updateProId,
    openLeadProfileModel,
    openFilterModel,
    openEmailModel,
    openRequestModel,
    openPreviewModel,
    openSuccessModel,
    openCreateLeadModel,
    openLeadApplicationModel
} from "../actions/model"
import { logOut } from '../actions/auth';
import { history } from '../helpers/history';
import { connect } from 'react-redux';
let searchTimer;
class Topbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Gsearch: null,
            serach: ""

        }
    }

    componentDidMount() {
        // const url = useParams();
        // const { merchant_sfid } = this.props.match.params
        // const { search_keyword } = this.props.match.params
        // const { section } = this.props.match.params
        // const { sub_section } = this.props.match.params


        //  fetch('https://eduvanz-api-01.herokuapp.com/api/merchant_search?merchant_sfid=${merchant_sfid}&search_keyword=${search_keyword}&section=${section}&sub_section=${sub_section}').then((resp)=>{

        //     resp.json().then((result)=>{
        //         this.setState({Gsearch:result.data})
        //     })
        //  })


        // let sfid = localStorage.getItem('sfid');
        // let sideData =  localStorage.getItem('section');
        // const {keyword,sectionb,sub_sectionc}= this.state;

        // this.props.dispatch(getGlobalSearch(sfid,sideData,sectionb,sub_sectionc));


        $('#sidebarToggleTop').on('click', function () {
            $('#accordionSidebar').toggleClass('toggled');
        })
        $('#sidebarClose').on('click', function () {
            $('#accordionSidebar').removeClass('toggled');
        })
        
    }
    componentDidUpdate(prevProps, prevState) {
        // if (prevState.search !== this.state.search) {
           if(this.props.stage != prevProps.stage){
            this.setState({serach:""})
            this.props.dispatch(setShowSearched(false))
               if (this.state.serach.length > 3) {
                    // this.props.dispatch(setShowSearched(true))
                    // this.getsearchdata()
               }
           }
           if(prevProps.searchedKeyword !== this.props.searchedKeyword ){
            if(!this.props.searchedKeyword){
                this.setState({serach:""})
            }
           }
        // }
    }
    getserachInput = (e) => {
        this.setState({ serach: e.target.value });
        if (e.target.value.length > 2) {
            //this.props.dispatch(setShowSearched(true))
            this.getsearchdata(e.target.value);
            // this.props.dispatch(setShowSearched(true))
        }
        else if (e.target.value.length == 0) {
            // this.props.dispatch(emptyserachList())
          
            this.getsearchdata(e.target.value);
            // this.props.dispatch(setShowSearched(false))
            // this.props.dispatch(setSearchedField(""))
        }
        // else{
        // } 

    }


    getsearchdata = (query_search) => {
        let sfid = localStorage.getItem('sfid');
        // let sideData =  localStorage.getItem('section');
        const { title, stage,productFilter } = this.props;
        // this.props.dispatch(getGlobalSearch(sfid, query_search?query_search:this.state.serach , title , stage.charAt(0).toUpperCase()+stage.slice(1) ));
        //clearTimeout(myTimeout);
        //add debouncing
        let credSource = this.props.Type === "CourseSkills"? "B2C": "B2B2C";
        let skillSource = "B2C";
        if (searchTimer) {
            clearTimeout(searchTimer)
        }
        searchTimer = setTimeout(() => {
            // this.props.dispatch(setSearchedField(query_search))
            // if (this.state.serach.length > 2) {                
                if(this.props.Type === "Skills"){
                    const searchfilter = {  ...this.props.productFilter,
                        search:query_search ? query_search : this.state.serach,
                        status:stage.charAt(0).toUpperCase() + stage.slice(1)
                    }
                    let obj = {
                        merchant_id :sfid,
                        // status: stage ? stage : this.state.stage,
                        page: 1,
                        limit: 10,
                        filter: searchfilter
                    }
                    this.props.handleFilterValue(searchfilter)
                    this.props.dispatch(getSkillsGlobalSearch(obj)).then(response => {
                        if(response.status === "success"){
                             this.props.dispatch(setSearchedField(query_search))
                            if(query_search === ""){
                                this.props.dispatch(setShowSearched(false))
                                this.props.dispatch(setSearchedField(""))
                            }else{
                                 this.props.dispatch(setShowSearched(true))
                            }
                        }
                    });
                }else if(this.props.Type === "CourseSkills"){
                  const productStage = stage === "active" ? "Active": stage === "inactive" ? "Inactive":"";

                  const searchfilter = {  ...this.props.productFilter,
                    search:query_search ? query_search : this.state.serach}

                    let getProd = { 
                        // merchant_id: this.props.user_id,
                        merchant_id: sfid,
                         page:1,
                          limit:10,
                          filter: searchfilter
                        //   filter: {
                          
                        //     // productStatus: "Active",  //Active, Inactive
                        //     // productStatus: productStage,
                        //     // course_type: "",
                        //     // course_delivery_mode:"", //Online, Offline
                        //     // medium_of_instruction__c:"",
                        //     // listing_time:"",
                        // }
                        }
                        this.props.handleFilterValue(searchfilter)
                    this.props.dispatch(getMerchantProductsWizrSearch(getProd)).then(response => {
                        if(response.status === "success"){
                             this.props.dispatch(setSearchedField(query_search))
                            if(query_search === ""){
                                this.props.dispatch(setShowSearched(false))
                                this.props.dispatch(setSearchedField(""))
                            }else{
                                this.props.dispatch(setShowSearched(true))
                           }
                        }
                    }).catch(error => {
                        if(error.status === 401){
                          this.props.dispatch(logOut());
                          history.push('/login');
                        }
                      })
                }
                else{
                    this.props.dispatch(getGlobalSearch(sfid, query_search ? query_search : this.state.serach, title, stage.charAt(0).toUpperCase() + stage.slice(1),credSource));
                }
            // }
        }, 1000)

    }

    openProfile = () => {
        this.props.dispatch(openProfileModel());
    }


    openCreateLeads = () => {
        this.props.dispatch(openCreateLeadModel());
    }

    openLeads = (id) => {
        this.props.dispatch(openLeadProfileModel(id));
    }

    openLeadApplication = (id, sfid) => {
        this.props.dispatch(openLeadApplicationModel(id));
        this.props.dispatch(updateProId(sfid));
    }

    openBulkModel = () => {
        this.props.dispatch(openBulkModel());
    }

    openFilter = () => {
        this.props.dispatch(openFilterModel());
    }

    openRequest = () => {
        this.props.dispatch(openRequestModel());
    }

    openEmail = () => {
        this.props.dispatch(openEmailModel());
    }

    openPreview = () => {
        this.props.dispatch(openPreviewModel());
    }

    openSuccess = () => {
        this.props.dispatch(openSuccessModel());
    }
    componentWillUnmount (){
        if(searchTimer){
            clearTimeout(searchTimer);

        }
    }



    render() {
        const { title, createLead, createProduct,searchedKeyword } = this.props
        return (
            <>
                {/* Topbar */}
                {/* <nav className="navbar navbar-expand navbar-light bg-white topbar mb-5 static-top shadow"> */}

                {/* Sidebar Toggle (Topbar) */}
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="">

                            {/* <h1 className="mr-3 min-width150">
                                <button id="sidebarToggleTop" className="btn btn-link d-lg-none rounded-circle mr-3">
                                    <i className="fa fa-bars"></i>
                                </button>{title ? title : "Dashboard"}</h1> */}


                           

                            <div className="header_search_wrapper">
                                <div className="search-form nav_search" onSubmit="return false;">
                                    <div className={`form-group has-feedback ${title == "Dashboard" || title == "Report" ? 'd-none' : ''}`}>
                                       <label htmlFor="search" className="sr-only">
                                            Search
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control searchRounded"
                                            name="search"
                                            id="search"
                                            placeholder="Search"
                                            value={this.state.serach}
                                            // onChange={(e)=>{this.setState({search : e.target.value})}}
                                            onChange={this.getserachInput}
                                        //onInput={this.getserachInput}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* <div className="col-md-5">
                            <ul className="btn_lists float-right">
                                {createLead && (
                                    <li>
                                        <a
                                            href="#"
                                            onClick={this.openCreateLeads}
                                            className="d-sm-inline-block btn btn-sm btn-primary btn-dark"
                                            data-toggle="modal"
                                            data-target="#myModal"
                                        >
                                            <i className="fas fa-plus" /> Create Lead
                                        </a>
                                    </li>
                                )}
                                {createProduct && (
                                    <li>
                                        <a
                                            href="#"
                                            className="d-sm-inline-block btn btn-sm btn-primary btn-dark"
                                            data-toggle="modal"
                                            data-target="#myModal2"
                                        >
                                            <i className="fas fa-plus" /> Add New Product
                                        </a>
                                    </li>
                                )}
                              
                                <li  >
                                    <Link to="/help" className="header_icons">   <img src="img/Help.svg" alt="help" /></Link>
                                  
                                </li>
                             
                                <TopBarRight
                                    dispatch={this.props.dispatch}
                                />
                            </ul>
                        </div> */}


                    </div>
                </div>
               
            </>
        );
    }
}


function mapStateToProps(state) {
    const { user_id,sfid } = state.auth;
    const {searchedKeyword } = state.user;
  
    return {
      user_id,
    //   load_product_data,
    //   globalSearch,
    //   globalseraching,
    //   activeWizrTab,
      searchedKeyword,
      sfid
    };
  }
  
  export default connect(mapStateToProps)(Topbar)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { closeModel } from "../actions/model";
import { getMerchantProductList, getCategory, createProduct, getMerchantProducts, getFilterMaster, bulkUploadRequest } from "../actions/user";
import { openBulkNewProdModel,openAddNewProduct } from "../actions/model";
import { Scrollbar } from "react-scrollbars-custom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import XlsxPopulate, { Promise } from "xlsx-populate";
import { saveAs } from "file-saver";
import readXlsxFile from 'read-excel-file'
import { Link } from 'react-router-dom'
import { history } from '../helpers/history';




const SECTION1 = 0;
const SECTION2 = 1;
const SECTION3 = 2;
const initialState = {
    product: '',
    product_price: '',
    product_name: '',
    category: '',
    brand: '',
    description: '',
    isValid: true,
    errorMsg: '',
    isSuccess: '',
    successMsg: '',
    onBoarding: SECTION1,
    showErr: false,
    loanerrmsg: '',
    brandData: [],
    selectedFile: '',
    bulkUploadErr: '',
    showDownloadCsvToast: false,
};

class AddNewProdctCred extends Component {

    constructor() {
        super()
        this.state = initialState;
        this.testRef = React.createRef();

    }

    componentDidMount() {
        //comment api
        // let getProd = { merchant_id: this.props.user_id }
        // this.props.dispatch(getMerchantProductList(getProd));
        //api commented dont need api
        // this.props.dispatch(getCategory());
        //api commented
        // this.props.dispatch(getFilterMaster(localStorage.getItem('user_id'), 'brand')).then((response) => {
        //    const brand = [];
        //    const allbrand =  response?.data ? response.data : [];
        //    allbrand.forEach(element =>{ brand.push({ value:element.name, label:element.name})});
        //     this.setState({ brandData:brand })
        // })

    }

    componentDidUpdate(prevProps) {
        if (prevProps.add_new_product_show !== this.props.add_new_product_show) {
            this.setState(initialState);
            if (this.props.add_new_product_show) {
                this.props.dispatch(getFilterMaster(localStorage.getItem('user_id'), 'brand')).then((response) => {
                    const brand = [];
                    const allbrand = response?.data ? response.data : [];
                    allbrand.forEach(element => { brand.push({ value: element.name, label: element.name }) });
                    this.setState({ brandData: brand })
                })


            }
        }
    }

    onNextBording = (next) => {
        this.setState({ onBoarding: next });
    }

    closeModel = () => {
        this.props.dispatch(closeModel())
    }
    // openSuccessModel = () => {
    //     this.props.dispatch(openSuccessModel())
    // }


    openNewbulkUpload_modal = (e) => {
        e.preventDefault();
        this.props.dispatch(openBulkNewProdModel())
    }

    handleChange = (e) => {
        // this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === 'brand') {

            this.setState({ [e.target.name]: e.target.value.replace(/[^a-z]/gi, '') });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    handleClose = () => {
        // this.setState({ state: SECTION1 });
        this.setState(initialState);
        this.props.dispatch(openAddNewProduct(false));

    }


    onlyNumbers = (e) => {
        var pattern = new RegExp(/^(?!(0))\d+$/);
        if (e.target.value !== '') {
            if (!pattern.test(e.target.value)) {
                // this.setState({ [e.target.name]: "" });
            } else {
                this.setState({ [e.target.name]: e.target.value });
            }
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    // onlyNumbers = (e) => {
    //     var pattern = new RegExp(/^[0-9\b]+$/);
    //     if(e.target.value !=='')
    //     {
    //       if(!pattern.test(e.target.value))
    //       {
    //         this.setState({[e.target.name] : ""});
    //       }else{
    //         this.setState({[e.target.name] : e.target.value});
    //       }
    //     }else{
    //       this.setState({[e.target.name] : e.target.value});
    //     }
    //   }

    handleSelectProduct = (e) => {
        const { mproducts } = this.props;
        let id = e.value;
        if (id != '') {
            var selctedItem = mproducts.find(item => item.sfid == id);
            this.setState({ product: id, product_price: selctedItem.mrp__c, category: selctedItem.product_sub_category__c, product_name: selctedItem.name, brand: selctedItem.brand__c });
            this.setState({ onBoarding: SECTION2 });
        } else {
            this.setState({ product_price: '', product: '', product_name: '', brand: '' });
        }
    }
    scrollToBottom = () => {
        var objDiv = document.getElementById("create-lead");
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    handleSubmitProd = (e) => {
        e.preventDefault();
        let data = {
            product_id: this.state.product ? this.state.product : '',
            price: this.state.product_price,
            product_name: this.state.product_name,
            brand: this.state.brand,
            category: this.state.category,
            description: this.state.description,
            merchant_id: this.props.sfid
        };
        this.props.dispatch(createProduct(data)).then((response) => {
            if (response.status === "success") {
                this.setState({ isSuccess: 1, onBoarding: SECTION3, successMsg: response.message });
                // setInterval(document.getElementById("close-create").click(), 5000);
                let getProd = { merchant_id: this.props.user_id }
                this.props.dispatch(getMerchantProductList(getProd));
                //window.location.reload();

            } else {
                if (response.status === "error") {
                    this.setState({ showErr: true, loanerrmsg: response.message })
                }
                else {
                    this.scrollToBottom();
                    this.setState({ isSuccess: 0, onBoarding: SECTION1, successMsg: response.message });
                }

            }
        }).catch((error) => {
            this.setState({ isSuccess: 0, onBoarding: SECTION1, successMsg: error });
        });

    }
    /*
    url=https://eduvanz-api-01.herokuapp.com/api/create_product
    brand: "bajaj"
category: "2-Wheelers"
description: ""
merchant_id: "00171000008GurGAAS"
price: "21000"
product_id: "01t7100DMTTT10313"
product_name: "Demo Test 314"
    
    */



    handleDownload = (url) => {

        let obj = { merchant_id: this.props.user_id, "section": '' }
        let getProd = { merchant_id: this.props.user_id }
        const { mproducts } = this.props;
        //  this.props.dispatch(merchantProductDownload(obj)).then((response) => {
        // this.props.dispatch(getMerchantProductList(getProd)).then((response) => {
        // if (response.proData && response.proData.length > 0) {

        let getData = []
        const m_id = localStorage.getItem("sfid") ? localStorage.getItem("sfid") : localStorage.getItem("sfid")
        for (let i = 0; i < mproducts.length; i++) {
            let source = mproducts[i];

            let obj = {
                // name:source.name,
                // // merchant_id : source.id,
                // merchant_id : m_id,
                // product_id : source.sfid,
                // new_selling_price : source.offer_price__c,
                // activation_status : source.activate_product__c,
                // sku:source.sku,

                merchant_id: m_id,
                product_id: source.sfid,
                new_selling_price: source.price__c,
                activation_status: 'active',
                brand: '',
                name: source.name,
                description: '',
                category: source.product_sub_category__c
                // merchant_id : source.id,
            }
            getData.push(obj)
        }

        this.saveAsExcel(getData);
    }
    //     });
    // }





    // saveAsExcel = async (getData) => {
    //     var data = [];
    //     await Promise.all(getData.map(async (elament) => {
    //         const productDet = elament
    //         data.push({
    //             merchant_id: productDet && productDet.merchant_id && productDet.merchant_id != null ? productDet.merchant_id : '',
    //             P_id: productDet && productDet.product_id ? productDet.product_id : '',
    //             // category: productDet && productDet.product_sub_category ? productDet.product_sub_category : '',
    //             // mrp: productDet && productDet.mrp && productDet.mrp != null ? productDet.mrp : '',
    //             // mop: productDet && productDet.mop && productDet.mop != null ? productDet.mop : '',
    //             selling_price: productDet && productDet.new_selling_price && productDet.new_selling_price != null ? productDet.new_selling_price : '',
    //             activation_status: productDet && productDet.activation_status == false ? 'inactive' : 'active',
    //             name: productDet && productDet.name ? productDet.name : '',
    //             sku: productDet && productDet.sku && productDet.sku != null ? productDet.sku : '',
    //         })
    //     }));
    //     let header = ["Merchant Id", "Product Id", "Selling Price", "Activation Status", "Name", "SKU"];
    //     //let header = ["Category","Sub Category","Brand","Product Name","Selling Price","Description"];
    //     XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    //         const sheet1 = workbook.sheet(0);
    //         const sheetData = await this.getSheetData(data, header);
    //         const totalColumns = sheetData[0].length;
    //         sheet1.cell("A1").value(sheetData);
    //         const range = sheet1.usedRange();
    //         const endColumn = String.fromCharCode(64 + totalColumns);
    //         sheet1.row(1).style("bold", true);
    //         sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
    //         range.style("border", true);
    //         return workbook.outputAsync().then((res) => {
    //             saveAs(res, "product_report.xlsx");
    //         });
    //     });
    // }

    // getSheetData = async (data, header) => {
    //     var fields = Object.keys(data[0]);
    //     var sheetData = data.map(function (row) {
    //         return fields.map(function (fieldName) {
    //             return row[fieldName] ? row[fieldName] : "";
    //         });
    //     });
    //     sheetData.unshift(header);
    //     return sheetData;
    // }

    saveAsExcel = async (getData) => {
        var data = [];
        await Promise.all(getData.map(async (elament) => {
            const productDet = elament
            data.push({
                merchant_id: productDet && productDet.merchant_id && productDet.merchant_id != null ? productDet.merchant_id : '',
                P_id: productDet && productDet.product_id ? productDet.product_id : '',
                // category: productDet && productDet.product_sub_category ? productDet.product_sub_category : '',
                // mrp: productDet && productDet.mrp && productDet.mrp != null ? productDet.mrp : '',
                // mop: productDet && productDet.mop && productDet.mop != null ? productDet.mop : '',
                selling_price: productDet && productDet.new_selling_price && productDet.new_selling_price != null ? productDet.new_selling_price : '',
                activation_status: 'Active',
                brand: productDet && productDet.brand && productDet.brand != null ? productDet.brand : '',
                name: productDet && productDet.name ? productDet.name : '',
                description: productDet && productDet.description && productDet.description ? productDet.description : '',
                category: productDet && productDet.category && productDet.category != null ? productDet.category : '',
            })
        }));
        let header = ["Merchant Id", "Product Id", "Selling Price", "Activation Status", "Brand", "Name", "Description", "Category"];
        //let header = ["Category","Sub Category","Brand","Product Name","Selling Price","Description"];
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = await this.getSheetData(data, header);
            const totalColumns = sheetData[0].length;
            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);
            sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                saveAs(res, "product_report.xlsx");
                this.setState({ showDownloadCsvToast: true })
                setTimeout(() => {
                    this.setState({ showDownloadCsvToast: false })
                }, 3000);
            });
        });
    }

    getSheetData = async (data, header) => {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        sheetData.unshift(header);
        return sheetData;
    }

    selectBrand = (selectedOptions) => {
        this.setState({ brand: selectedOptions.value });

    }
    handleFileSelect = (e) => {
        this.setState({ bulkUploadErr: "" })
        if (e.target.files.length > 0) {

            this.setState({ selectedFile: e.target.files[0] })
        }

    }

    uploadProcessing = () => {
        readXlsxFile(this.state.selectedFile).then((rows) => {
            //this.uploadingStage(rows)
            this.uploadProducts(rows);
            this.setState({ fileTaken: false, uploadUpdatedFile: false, uploadUpdatedFile: true })
        }).catch(error => {
            this.setState({ bulkUploadErr: error.message })
        });
    }

    uploadProducts = async (getData) => {
        const { dispatch, sfid, user_id } = this.props
        //dispatch(closeBulkProdModel());
        //dispatch(bulkUploadStart());
        let total = getData ? (getData.length - 1) : 0;
        this.setState({ totalCount: total })
        let now = 0;
        let obj = {
            total: total,
            now: now
        }
        let j = 0;
        let uploadingDatas = []
        for (let i = 1; i < getData.length; i++) {
            let data = {
                merchant_id: getData[i][0],
                product_id: getData[i][1],
                new_selling_price: getData[i][2],
                new_status: getData[i][3],
            }
            uploadingDatas.push(data)
        }
        this.setState({ uploadingData: uploadingDatas })
        this.uploadingStage(getData)

        // await Promise.all(getData.map(async (elament) => {
        //     if (j !== 0) {
        //         let data = {
        //             merchant_id: elament[0],
        //             product_id: elament[1],
        //             new_selling_price: elament[2],
        //             new_status: elament[3],
        //         }
        //         await dispatch(createBulkProducts(data)).then((response) => {
        //             if (response.status !== undefined && response.status === "success") {
        //                 this.setState({ isSuccess: 1, successMsg: response.message, products: '', selected: '' });
        //                 // setInterval(dispatch(getMerchantProducts(getProd)), 5000);
        //             } else {
        //                 this.setState({ isSuccess: 0, successMsg: '', products: '', selected: '' });
        //             }
        //         });
        //         now = now + 1;
        //         let obj = {
        //             total: total,
        //             now: now
        //         }
        //         let percent = ((now / total) * 100);
        //         dispatch(bulkUploadData(obj));
        //         dispatch(bulkUploadProgress(percent));
        //     }
        //     j = j + 1;
        // }));
        // dispatch(bulkUploadEnd());
    }

    uploadingStage = async (getData) => {
        await this.props.dispatch(bulkUploadRequest(getData)).then((response) => {
            if (response.status !== undefined && response.status === "success") {
                let uploadCount = response.statusCount;
                let totalFailed = 0
                let totalSuccess = 0
                for (let i = 0; i < uploadCount.length; i++) {
                    if (uploadCount[i].status == 'failed') {
                        totalFailed = uploadCount[i].count
                    }
                    if (uploadCount[i].status == 'success') {
                        totalSuccess = uploadCount[i].count
                    }
                }
                this.setState({ totalCount: response.totalCount, uploadedResponseStatus: true, uploadedCount: totalSuccess, rejectedCount: totalFailed, uploadedResponse: response, isSuccess: 1, successMsg: response.message, products: '', selected: '' });
            } else {
                this.setState({ isSuccess: 0, successMsg: response.message, products: '', selected: '', uploadedResponseStatus: true, uploadedResponse: response });
                this.setState({ bulkUploadErr: response.message })
            }
        });
    }


    render() {
        const { isLoading, mproducts, category } = this.props;
        let prodOptions = [];
        if (mproducts) {
            for (var i = 0; i < mproducts.length; i++) {
                let pname = String(mproducts[i].name).slice(0, 15);
                prodOptions.push({ value: mproducts[i].sfid, label: pname });
            }
        }

        return (
            <>
                {/* {isLoading?(
                <div className="loading">Loading&#8230;</div>
            ):''} */}
                {/* Modal */}
                <div className="modal right fade myModal addProductModal" id="myModal245" role="dialog">
                    <div className="modal-dialog">
                        {/* Modal content*/}
                        <form onSubmit={this.handleSubmitProd} className="f_height">
                            <div className="modal-content productModal">

                                <div className="modal-header">
                                    {this.state.showDownloadCsvToast &&
                                        <div className='success-toast-pro'>
                                            <img src='./images/client/courseSuccess.svg' />
                                            <div className='fw-600 fz14 lineheight20 fontcolorfig'>Template Downloaded Successfully</div>
                                        </div>
                                    }

                                    <div className='d-flex justify-content-end w-100'>
                                        <button onClick={this.handleClose} type="button" id="close-create" data-dismiss="modal"> <i className="fas fa-times" style={{ fontSize: "1.537vw" }}></i> </button>
                                    </div>
                                    {/* <button onClick={this.handleClose} type="button" id="close-create" className="abs_close close" data-dismiss="modal"> <i className="fas fa-times"></i> </button> */}
                                    <div className='d-flex justify-content-between w-100'>
                                        <h5 className="modal-title fz24">Add New Product</h5>
                                        <button onClick={() => history.push("/help")} data-dismiss="modal" className='help-product'><img style={{ marginRight: "8px", width: "1.46vw", height: "2.60vh" }} src='./images/help.svg' />Help</button>
                                    </div>

                                </div>

                                <div id="" className="modal-body pt-0 px-0">

                                    <Scrollbar>

                                        <div className='px-3'>

                                            {this.state.onBoarding == SECTION1 ? (
                                                <>

                                                    <div className="row justify-content-center mb-2">
                                                        <div className="col-sm-11 form-group formgroupDiv">
                                                            <label className="form-label productSku">
                                                                Product Name/SKU
                                                            </label>

                                                            <Select
                                                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                options={prodOptions}
                                                                placeholder="Enter product name/SKU here"
                                                                name="sel_product"
                                                                onChange={this.handleSelectProduct}
                                                                styles={{
                                                                    option: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        fontSize: "1.171vw"
                                                                    }),
                                                                    placeholder: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        fontSize: '1.2vw'
                                                                    }),
                                                                    input: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        fontSize: '1.2vw'
                                                                    }),
                                                                    singleValue: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        fontSize: '1.2vw'
                                                                    }),

                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        fontSize: '1.2vw'
                                                                    }),

                                                                }}
                                                            />
                                                            <button type="button" className='search_btn'><i className="fa fa-search searchInputIcon" aria-hidden="true"></i></button>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center mb-2 ">
                                                        <div className="col-sm-11">
                                                            {/* <div className='text-right'>
                                                                <button onClick={() => this.onNextBording(1)} variant="secondary" className="link_">Can't find my product</button>
                                                            </div> */}


                                                            <div className='text-center or my-5'>-OR-</div>
                                                            <div className='BulkUploadProduct'>
                                                                <div className='d-flex justify-content-between w-100 mb-2'>
                                                                    <h5 className='font-weight-bold bulkUploadTitle'>Bulk upload</h5>
                                                                    {/* <span>

                                                                    <OverlayTrigger
                                                                        key={'left'}
                                                                        placement={'left'}
                                                                        overlay={
                                                                            <Tooltip >
                                                                                Supported file type: XLS
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <img src="images/icons/info.png" />
                                                                    </OverlayTrigger>

                                                                </span> */}
                                                                </div>
                                                                {/* <p>Why upload products one-by-one when you can add hundreds at once.</p> */}
                                                                {/* <p>Why upload products one-by-one when you can add them all at once</p> */}
                                                                <p className='bulkUploadpara'>Download template from below option, enter <br /> product details and then upload the file!</p>
                                                                <p className='bulkuploadSupport'>Supported file type: <span>.XLS,.XLSX</span> upto <span>5MB</span> </p>
                                                                <div className='d-flex justify-content-end mt-4 bulkuploadtabs' style={{ position: 'relative' }}>
                                                                    {!this.state.selectedFile ? <div className=''>
                                                                        <button style={{ cursor: 'pointer' }} onClick={() => this.handleDownload()} className=" text-center upload-file-btn mr-3">
                                                                            <img className='mr-1' src="./images/downloadbulk.svg" style={{ width: "1.75vw", height: '3.12vh' }} />Download Template
                                                                        </button>

                                                                        {/* <a className='link_ w-100 text-center' onClick={() => this.handleDownload("/bulk_product_upload.xlsx")} >Download Template</a> */}
                                                                    </div> : ''}
                                                                    <div className=''>
                                                                        {this.state.selectedFile ? <div className='d-flex justify-content-between align-items-center'>
                                                                            <div className='d-flex align-items-center' style={{ position: 'absolute', left: '0px' }}>
                                                                                <p className='fz16 pt0 fw-500'>{this.state.selectedFile.name}</p>
                                                                                <i className="fas fa-times mr-4" onClick={() => this.setState({ selectedFile: '', bulkUploadErr: '' })} style={{ fontSize: "1.171vw", color: '#000000', cursor: 'pointer', marginLeft: "1.830vw" }}></i>
                                                                            </div>
                                                                            <button className='upload-file-btn' onClick={this.uploadProcessing}>Upload</button>
                                                                        </div>
                                                                            : <div className='d-flex justify-content-end'><input type="file" id="upload" className='d-none' onChange={this.handleFileSelect} /> <label className='upload-file-btn' for="upload">Select File</label></div>}

                                                                    </div>
                                                                </div>
                                                                {this.state.bulkUploadErr && <div className='bulk-upload-error'>{this.state.bulkUploadErr}</div>}


                                                                {/* <div className='add-course-file d-flex justify-content-between align-items-center'>
                                           <div className='d-flex align-items-center'>
                                             <span>A190507054_Bank_Statement_-_6_Months_1557232217_5cd17a595eed9</span>
                                             <i className="fas fa-times mr-4" style={{fontSize:"16px",color:'lightgrey',cursor:'pointer'}}></i>
                                           </div>
                                           <button>Uploaded</button>
                                          </div>

                                          <div className='add-course-file-preview'>
                                            <p >File Preview</p>
                                           <div className='d-flex align-items-center'>
                                            <div className='add-course-file-count'>Total <span>83</span></div>
                                            <div className='add-course-file-count'>Added <span>83</span></div>
                                            <div className='add-course-file-count'>Failed <span>0</span></div>
                                           </div>
                                          </div>

                                          <div className='mt-4 mb-2'> 
                                          <table className="add-course-file-table" cellSpacing={0} >
                                          <thead>
                                 <tr>
                                <th className='add-course-thead-th'> # </th>
                                <th className='add-course-thead-th'>Course Name</th>
                                <th className='add-course-thead-th' style={{paddingLeft:'20px'}}>Status</th>
                                <th className='add-course-thead-th'>Errors/Warnings</th>
                            </tr>
                        </thead>
                         <tbody>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 1 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 2 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 3 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 4 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 5 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 6 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 7 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 8 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         <tr>
                                <td className='add-course-tbody-th' style={{width:"10%"}}> 9 </td>
                                <td className='add-course-tbody-th' style={{width:"50%",fontSize:'14px'}}>wjdehfn erhej erjekj weret wdwdw wdweret wewrwerer weret </td>
                                <td className='add-course-tbody-th' style={{width:"20%",paddingLeft:'20px'}}><div className='d-flex align-items-center'><img src='./images/success-icon.svg' className='mr-1'/><span>Added</span></div></td>
                                <td className='add-course-tbody-th' style={{width:"20%"}}>No Error</td>
                            </tr>
                         
                         
                         </tbody>

                                           </table>
                                          </div> */}

                                                            </div>
                                                        </div>


                                                    </div>
                                                </>) : this.state.onBoarding == SECTION2 ? (
                                                    <>


                                                        <div className="row justify-content-center mb-2">
                                                            <div className="col-sm-11">
                                                                <div className='vscrollProducts'>
                                                                    <div className="row justify-content-center mb-2">
                                                                        <div className="col-sm-6 form-group">
                                                                            {/* <label className="form-label">
                                                                                Category*
                                                                            </label> */}

                                                                            {/* <select
                                                                                name="category"
                                                                                id="category"
                                                                                className="form-control xx"
                                                                                value={this.state.category ? this.state.category : ''}
                                                                                onChange={this.handleChange}

                                                                            >

                                                                                <option style={{ color: "#6e707e" }}>Select Product Category</option>
                                                                                {category && category.length > 0 &&
                                                                                    (
                                                                                        category.map((item, index) => (
                                                                                            item.sub_cat.map((subcat, ii) => (
                                                                                                <option style={{ color: "#6e707e" }} key={subcat.category_id} value={subcat.category_name} >{subcat.category_name}</option>
                                                                                            ))
                                                                                        ))
                                                                                    )
                                                                                }
                                                                            </select> */}

                                                                            <label className="form-label mb-1">
                                                                                Category*
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control productInput"
                                                                                placeholder="Select Product Category"
                                                                                name="category"
                                                                                value={this.state.category ? this.state.category : ''}
                                                                                // onChange={this.handleChange}
                                                                                readOnly
                                                                                disabled
                                                                            />




                                                                        </div>
                                                                        <div className="col-sm-6 form-group select-brand">
                                                                            <label className="form-label mb-1">
                                                                                Brand*
                                                                            </label>
                                                                            {/* <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Select Brand"
                                                                                name="brand"
                                                                                value={this.state.brand ? this.state.brand : ''}
                                                                                onChange={this.handleChange}
                                                                            /> */}
                                                                            <Select
                                                                                // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}

                                                                                styles={{
                                                                                    option: (baseStyles, state) => ({
                                                                                        ...baseStyles,
                                                                                        fontSize: "1.171vw"
                                                                                    }),
                                                                                    placeholder: (baseStyles, state) => ({
                                                                                        ...baseStyles,
                                                                                        fontSize: '1.2vw'
                                                                                    }),
                                                                                    singleValue: (baseStyles, state) => ({
                                                                                        ...baseStyles,
                                                                                        fontSize: '1.2vw'
                                                                                    }),

                                                                                    control: (baseStyles, state) => ({
                                                                                        ...baseStyles,
                                                                                        boxShadow: "none",
                                                                                        borderColor: "none",
                                                                                        "&:hover": {
                                                                                            borderColor: "#102031",
                                                                                        }
                                                                                    }),
                                                                                }}
                                                                                options={this.state.brandData}
                                                                                placeholder="Select brand"
                                                                                name="brand"
                                                                                onChange={this.selectBrand}
                                                                            />
                                                                            {/* <button type="button" className='search_btnbrand'><i className="fa fa-search searchInputIcon" aria-hidden="true"></i></button> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row justify-content-center mb-2">
                                                                        <div className="col-sm-6 form-group">
                                                                            <label className="form-label mb-1">
                                                                                Product*
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control productInput"
                                                                                placeholder="Enter Product Name"
                                                                                name="product_name"
                                                                                value={this.state.product_name ? this.state.product_name : ''}
                                                                                onChange={this.handleChange}
                                                                                readOnly
                                                                            />

                                                                        </div>
                                                                        {/* <div className="col-sm-6 form-group">
                                                                            <label className="form-label">
                                                                                SKU
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter SKU"
                                                                                name="SKU"
                                                                                maxLength={10}
                                                                            />

                                                                        </div> */}
                                                                        <div className="col-sm-6 form-group">
                                                                            <label className="form-label mb-1">
                                                                                Selling Price
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control productInput"
                                                                                placeholder="Enter Selling Price"
                                                                                name="product_price"
                                                                                value={this.state.product_price ? this.state.product_price : ''}
                                                                                onChange={this.onlyNumbers}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row justify-content-center mb-2">
                                                                        <div className="col-sm-12 form-group">
                                                                            <label className="form-label mb-1">
                                                                                Product URL
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control productInput"
                                                                                placeholder="Enter Product URL"
                                                                                name="ProductURL"
                                                                            />

                                                                            {/* <OverlayTrigger
                                                                                key={'left'}
                                                                                placement={'left'}
                                                                                overlay={
                                                                                    <Tooltip >
                                                                                        Enter the URL of the product from other ecommerce platform
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <p className='info__'>
                                                                                    <img src="images/icons/info.png" />
                                                                                </p>

                                                                            </OverlayTrigger> */}


                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-2">
                                                                        {/* <div className="col-sm-6 form-group">
                                                                            <label className="form-label">
                                                                                Selling Price
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter Selling Price"
                                                                                name="product_price"
                                                                                value={this.state.product_price ? this.state.product_price : ''}
                                                                                onChange={this.onlyNumbers}
                                                                            />
                                                                        </div> */}
                                                                    </div>
                                                                    <div className="row justify-content-center mb-2">
                                                                        <div className="col-sm-12 form-group">
                                                                            <label className="form-label mb-1">
                                                                                Product Description
                                                                            </label>
                                                                            <textarea className="form-control productInput" maxLength={4000} onChange={this.handleChange} name="description" id="exampleFormControlTextarea1" rows="3" placeholder='Type here..'></textarea>
                                                                            <p style={{ float: "right" }} class="float-end">0/4000 Characters</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {this.state.showErr &&
                                                                    <div className="form-group"><div className='alert alert-danger' role='alert'>{this.state.loanerrmsg}</div></div>
                                                                }
                                                                <div className="d-flex justify-content-between mb-2 mt-4" title={this.state.name}>
                                                                    <div className='d-flex invisible' >
                                                                        <img src='images/shield.svg' width="25px" height="25px" />
                                                                        <p>The payment schemes & cancellation/return policy for <br /> all  products are pre-agreed.</p>
                                                                    </div>
                                                                    <div>
                                                                        <button
                                                                            type="submit"
                                                                            className='btn btn-default_ btn btn-secondary'
                                                                            disabled={this.state.product_price && this.state.product_name && this.state.brand && this.state.category ? false : true}

                                                                        >Request Approval</button>
                                                                    </div>
                                                                </div>
                                                                <div className="modal fade" id="loanBooked">
                                                                    <div className="modal-dialog modal-dialog-centered dr-modal">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header border-0 pb-0">
                                                                                <button type="button" className="close ml-auto" data-dismiss="modal" aria-label="Close">
                                                                                    <span aria-hidden="true">&times;</span>
                                                                                </button>
                                                                            </div>
                                                                            <div className="modal-body text-center">
                                                                                <img src="images/wallet.png" className="img-fluid"></img>
                                                                                <h4 className="success-msg mt-5">Loan Booked Succesfully !</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </>
                                                ) : this.state.onBoarding == SECTION3 ? (
                                                    <div className='row'>
                                                        <div className="col-11 shadowleadsucc mx-auto mb-3">
                                                            <div className="mb-2 d-flex align-items-center justify-content-center">

                                                                <div className='d-flex align-items-center'>
                                                                    <img src="images/succ-thumb.png" className="img-fluid mr-3"></img>
                                                                </div>
                                                                <div className='d-flex flex-column justify-content-center '>
                                                                    <h5 className="success-msg">Request sent successfully!</h5>
                                                                    <p>Sit back & relax while our team checks product & then proceed accordingly</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-11 mx-auto mt-3">
                                                            <button type="button" className='btn-sm btn-primary btn-dark bor-red' onClick={() => this.setState(initialState)}>
                                                                <i className="fas fa-plus" /> Add another Product
                                                            </button>
                                                        </div>


                                                    </div>
                                                ) : ''

                                            }

                                        </div>


                                    </Scrollbar>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
                {/*Model Stop*/}
            </>
        )
    }

}

function mapStateToProps(state) {
    const { add_new_product_show } = state.model;
    const { isLoading, user_id, sfid } = state.auth;
    const { mproducts, category } = state.user;
    return {
        add_new_product_show,
        user_id,
        mproducts,
        isLoading,
        sfid,
        category,
    };
}

export default connect(mapStateToProps)(AddNewProdctCred)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"
import { ToastContainer, toast } from "react-toastify";
// import ReactCrop from 'react-image-crop'
// import 'react-image-crop/dist/ReactCrop.css'
import { Modal, Button } from "react-bootstrap"
import { closeImageEditModel } from "../actions/model"
import { uploadProfile, uploadDocument, getLeadProfileDocuemnt, getLeadOtherDocuemnt, getLeadPanDocuemnt,validatePan,verifyDoc,getDocData,fraudCheck,uploadDocAdharBack,checkLiveliness } from "../actions/user"

class ImageEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
          croppedImgSrc: "",
          rawImageScr: "",
          type: "image/png",
          isCropEnable: false,
          selectedNav: 1,
          otherSelected: 1, 
          cropSelected: 0,
          isValidPan: true,
          panType: 0,
          isValidFronImg: true,
          isvalidBackImg: true,
          profile:null,
          panBase:"",
          selectedTab:1,
          defaultTab: 0,
          address: "",
          isAutoAdd: false,
          city:'',
          state:'',
          address_pin:'',
          pincode:'',
         // aspectRatio: 16 / 9,
          imageSrc: '',
          livenessScore: 0,
          isLive: false,
          profileBase: "",
          modalPan: "",
          modalPhoto: "",
          modalFrontImg: "",
          modalBackImg: "",
          modalClose: true
        };
        this.fileInput = React.createRef();
        this.handleFileRead = this.handleFileRead.bind(this);
        this.fileReader = new FileReader();
        this.cropper = React.createRef();
    }

    componentDidUpdate(prevProps)
    {
      const { selectedImageTab} = this.props
        if(prevProps.image_edit_show !== this.props.image_edit_show)
        {
          //  this.handleChange();
          this.setState({otherSelected: selectedImageTab==4?2:1});
          if(this.state.modalClose){
            if(selectedImageTab == 1){
              this.getPhotoDoc();
            }
            if(selectedImageTab == 2){
              this.getPanDoc()
            }
            if(selectedImageTab == 3){
              this.getOtherDoc()
            }            
          }
          this.setState({
            modalClose: true
          })
        }
    }
    

    handleFileRead(e) {
        const binaryData = this.fileReader.result;
        const base64Data = window.btoa(binaryData);
        this.setState({ base64: base64Data });
      }
    
      handleChange = () => {
        const imageSrc = this.props.leadProfileImg ? this.props.leadProfileImg : this.state.modalPhoto;
        this.setState({imageSrc: imageSrc, croppedImgSrc: imageSrc, rawImageScr: imageSrc});
        this.fileReader.onloadend = this.handleFileRead;
      }
    
      handleCropChange() {
        const croppedImgData = this.cropper.current.cropper
          .getCroppedCanvas()
          .toDataURL();
          
        this.setState({ croppedImgSrc: croppedImgData });
      }
    
      handleRotate() {
        this.setState({isCropEnable: true});
        this.cropper.current.cropper.rotate(90);
        this.handleCropChange();
      }

      showToast = () => {
        toast("I am Tostify!");
      };

      successToast = (message) => {
        toast.success(message);
      };
     

      errorToast = (message) => {
        toast.error(message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      };
      handleLeftRotate() {
        this.setState({isCropEnable: true});
        this.cropper.current.cropper.rotate(-90);
        this.handleCropChange();
      }

      handleCrop = (value) => {
        const { leadProfileImg, leadPanImg, otherFrontImg, otherBackImg } = this.props
        let imageSrc='';
        if(value ===1)
        {
          imageSrc = leadProfileImg ? leadProfileImg : this.state.modalPhoto;
        }else if(value ===2)
        {
          imageSrc = leadPanImg ? leadPanImg : this.state.modalPan;
        }else if(value ===3)
        {
          imageSrc = otherFrontImg ? otherFrontImg : this.state.modalFrontImg;
        }else if(value ===4)
        {
          imageSrc = otherBackImg ? otherBackImg : this.state.modalBackImg;
        }
        this.setState({isCropEnable: true, cropSelected: value, imageSrc: imageSrc});
      }

  async profileDataURLtoFile(dataurl, filename) {
    return new Promise((resolve, reject)=>{
     try {
           var arr = dataurl.split(','),
           mime = arr[0].match(/:(.*?);/)[1],
           bstr = atob(arr[1]), 
           n = bstr.length, 
           u8arr = new Uint8Array(n);
           
           while(n--){
               u8arr[n] = bstr.charCodeAt(n);
           }
           const profile = new File([u8arr], filename, {type:mime});
           this.setState({ profile: profile });
           resolve(true);
       } catch (err) {
         reject(err.message ? err.message : err)
     }
   });
}


uploadFrontDocument = async (frontProofType, frontProofBase, file) => {
  const tab = this.state.selectedTab;
  const check = await this.checkFraud(file, 1, tab);
  if (check[0]) {
    var addKey = check[1].data.result[0].details;
    const { dispatch } = this.props;
    let DataType = [];
    let mappedFiles = [];
    let Basetype = [];
    let eduFileType = [];
    if (this.state.selectedTab === 1) {
      DataType.push("Aadhar-Front");
      eduFileType.push("Aadhar Front");
    } else if (this.state.selectedTab === 2) {
      DataType.push("Driving-Front");
      eduFileType.push("Driving License");
    } else if (this.state.selectedTab === 3) {
      DataType.push("Voter-Front");
      eduFileType.push("Voter ID");
    } else {
      DataType.push("Passport-Front");
      eduFileType.push("Passport Front");
    }
    Basetype.push(frontProofType);
    // let frontBase = frontProofBase;
    let imagetypesplit=frontProofBase.split(";")
    let cropbase64url = imagetypesplit[1].split(",")
    // const frontBase64 = frontBase.replace(
    //   `data:${frontProofType};base64,`,
    //   ""
    // );
    mappedFiles.push(cropbase64url[1]);
    let data = {
      base64: mappedFiles,
      doctype: DataType,
      basetype: Basetype,
      catType: "ID Proof",
      parent_id: this.props.lead_id,
      id: this.props.lead_id,
      fileType: eduFileType,
      token: this.props.salesForceToken,
      is_doc_verified: this.state.frontDocAttempt > 2 ? false : true,   
    };
    await dispatch(uploadDocument(data)).then((response) => {
      if (response.status == "success") {
        this.props.dispatch(
          verifyDoc({
            isAadharUpload: true,
            is_force_verification: false,
            user_sfid: this.props.lead_id,
          })
        );
        if (addKey.address) {
          this.setState(
            {
              isAutoAdd: false,
              address: addKey.address.value ? addKey.address.value : "",
              city: addKey.address.city ? addKey.address.city : "",
              state: addKey.address.state ? addKey.address.state : "",
              address_pin: addKey.address.pin ? addKey.address.pin : "",
              pincode: addKey.address.pin ? addKey.address.pin : "",
              // selectedTab:1,
              // frontProofBase: base64File,
            },
            () => {
              // console.log("address set in state successful");
            }
          );
        }
        if (this.state.backProofBase) {
          this.props.dispatch(
            verifyDoc({
              isKycDone: true,
              is_force_verification: false,
              user_sfid: this.props.lead_id,
            })
          );
        }
        this.getOtherDoc();
      }
    });
    let selectedTab = this.state.selectedTab;
    this.setState({
      modalFrontImg: frontProofBase,
      defaultTab: selectedTab,
    });
  }
};
   


checkFraud = async (file, type, tab) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { dispatch } = this.props;
      const { frontDocAttempt, backDocAttempt } = this.state;
      var formdata = new FormData();
      formdata.append("files", file);
      if (type == 1) {
        if (tab == 1) {
          formdata.append("type", "ADHAR-FRONT");
        } else if (tab == 2) {
          formdata.append("type", "DRIVING-LIICENSE-FRONT");
        } else if (tab == 3) {
          formdata.append("type", "VOTER-ID-FRONT");
        } else {
          formdata.append("type", "PASSPORT");
        }
      } else {
        if (tab == 1) {
          formdata.append("type", "ADHAR-BACK");
        } else if (tab == 2) {
          formdata.append("type", "DRIVING-LIICENSE-BACK");
        } else if (tab == 3) {
          formdata.append("type", "VOTER-ID-BACK");
        } else {
          formdata.append("type", "PASSPORT");
        }
      }
      formdata.append("sfid", this.props.lead_id);
      await dispatch(fraudCheck(formdata)).then(async (response) => {
        if (response.status && response.status == "success") {
          const res = response.result ? response.result : null;
          const getData = res && res.length > 0 ? res[0] : null;
          const doctype = getData && getData.type ? getData.type : null;
          if (type == 1) {
            this.successToast("Verification Successful!");
            resolve([true, response]);
          } else {
            this.successToast("Verification Successful!");
            resolve([true, response]);
          }
        } else {
          if (type == 1) {
            const doctAttempt = frontDocAttempt + 1;
            this.setState({ frontDocAttempt: doctAttempt });
            if (doctAttempt > 2) {
              if (tab == 1) {
                this.errorToast(
                  "Your adhaar front document not verified still we can moved to backend verification"
                );
              } else if (tab == 2) {
                this.errorToast(
                  "Your driving front document not verified still we can moved to backend verification"
                );
              } else if (tab == 3) {
                this.errorToast(
                  "Your voter front document not verified still we can moved to backend verification"
                );
              } else {
                this.errorToast(
                  "Your passport document not verified still we can moved to backend verification"
                );
              }
              resolve([true, response]);
            } else {
              this.errorToast(response.message);
              resolve([false, response]);
            }
          } else {
            const doctAttempt = backDocAttempt + 1;
            this.setState({ backDocAttempt: doctAttempt });
            if (doctAttempt > 2) {
              if (tab == 1) {
                this.errorToast(
                  "Your adhaar back document not verified still we can moved to backend verification"
                );
              } else if (tab == 2) {
                this.errorToast(
                  "Your driving back document not verified still we can moved to backend verification"
                );
              } else if (tab == 3) {
                this.errorToast(
                  "Your voter back document not verified still we can moved to backend verification"
                );
              } else {
                this.errorToast(
                  "Your passport document not verified still we can moved to backend verification"
                );
              }
              resolve([true, response]);
            } else {
              this.errorToast(response.message);
              resolve([false, response]);
            }
          }
        }
      });
    } catch (err) {
      reject(err.message ? err.message : err);
    }
  });
};

getOtherDoc = () => {
  const { selectedTab } = this.state;
  // selectedTab == 1 ? 'Aadhaar Card' : this.state.selectedTab == 2 ? 'Driving License' : this.state.selectedTab == 3 ? 'Voter ID' : 'Passport'
  let proData = {
    sfid: this.props.lead_id,
    doc_type:
      selectedTab == 1
        ? "Aadhar"
        : this.state.selectedTab == 2
          ? "DL"
          : this.state.selectedTab == 3
            ? "VoterId"
            : "Passport",
  };
  this.props.dispatch(getDocData(proData)).then((response) => {
    if (response.status === "success") {
      let getData = response.imageData;
      if (getData.length == 0) {
        return;
      }
      for (let i = 0; i < getData.length; i++) {
        const element = getData[i];
        let FB = element.document_type.split(" ")[1];
        let type = 0;
        let DocBase = "";
        if (element.type == "PDF") {
          type = 2;
          DocBase = "data:application/pdf;base64," + element.base64.base64;
        } else {
          type = 1;
          DocBase = "data:image/jpg;base64," + element.base64.base64;
        }
        if (FB == "Front") {
          this.setState({
            frontFileType: type,
            modalFrontImg: DocBase,
            selectedTab: 1,
            defaultTab: 1,
            frontProofType: element.type,
            // frontId: resData.id
          });
        } else {
          this.setState({
            backFileType: type,
            modalBackImg: DocBase,
            selectedTab: 1,
            defaultTab: 1,
            backProofType: element.type,
            // backId: resData.id
          });
        }
      }
    
    }
  });
};


uploadProfileImage = async (profileType, profileBase) => {
  const { dispatch } = this.props;

  let proBase = profileBase;
   let imagetypesplit=proBase.split(";")
  let cropbase64url = imagetypesplit[1].split(",")
  // const proBase64 = proBase.replace(`data:${profileType};base64,`, "");
  const d = new Date();
  const time = d.getTime();
  localStorage.setItem("userBase", cropbase64url[1]);
  let prodata = {
    base64: cropbase64url[1],
    doctype: "Photo",
    catType: "Photo",
    fname: "eduvan-" + time + ".jpg",
    parent_id: this.props.lead_id,
    id: this.props.lead_id,
    token: this.props.salesForceToken,
    livenessScore: this.state.livenessScore,
    isLive: this.state.isLive,
  };
  await dispatch(uploadProfile(prodata)).then((response) => {
    this.getPhotoDoc();
    this.setState({ modalPhoto: profileBase });
    this.props.dispatch(
      verifyDoc({
        isPhotoUpload: true,
        is_force_verification: false,
        user_sfid: this.props.lead_id,
      })
    );
  });
};


getPhotoDoc = () => {
  let proData = {
    sfid: this.props.lead_id,
    doc_type: "Photo",
  };
  this.props.dispatch(getDocData(proData)).then((response) => {
    if (response.status === "success") {
      let getData = response.imageData;
      if (getData.length > 0) {
        var img = getData[0];

        this.setState({
          modalPhoto: "data:image/jpg;base64," + img.base64.base64,
          //profileType: "image/jpg",
         // profile: this.dataURLtoFile(
          //  "data:image/jpg;base64," + img.base64.base64,
          //  img.title
        //  ),
          // profileId: getData.id
        });
        // console.log(this.state.leadProfileImg,"2222")
      }
      // if (getData.profile !== undefined && getData.profile !== "") {
      // }
    }
  });
};


checkLiveness = (file) => {
  const { dispatch, lead_id, sfid } = this.props;
  let isValid = false;
  var formdata = new FormData();
  formdata.append("files", file);
  formdata.append("user_sfid", lead_id);
  return dispatch(checkLiveliness(formdata)).then((response) => {
    return response;
  });
};

      handleCropSave = async(e) => {
        const { dispatch, selectedTab } = this.props
        if(this.state.cropSelected === 1)
        {
          const d = new Date()
          const time = d.getTime()
          let proBase = this.state.croppedImgSrc; 
          let imagetypesplit=proBase.split(";")     
          let imagetype = imagetypesplit[0].split(":")  
          let cropbase64url = imagetypesplit[1].split(",")
          let type=imagetype[1]
          // const proBase64 = proBase.replace(`data:image/png;base64,`, "");
          // fileType.push("Photo");
          // mediaFile.push(panBase64);
          const files= await this.profileDataURLtoFile(cropbase64url[1]?`data:image/jpg;base64,${cropbase64url[1]}`:'', "profile.jpg")    
          const isValidPic = await this.checkLiveness(this.state.profile).then((response) => {
            if (response.message) {
              const getData = response.message;
              if (getData && getData.statusCode === 101  && (getData.result && getData.result.isLive)) {
                const result = getData && getData.result ? getData.result : null;
                const livenessScore =
                  result && result.livenessScore !== undefined
                    ? result.livenessScore
                    : 0;
                const validPic =
                  result && result.isLive !== undefined ? result.isLive : false;
                this.setState({ livenessScore: livenessScore, isLive: validPic });
                var reader = new FileReader();
                var url = reader.readAsDataURL(this.state.profile);
                reader.onloadend = function (e) {
                  this.uploadProfileImage(type, reader.result);
                }.bind(this);
                this.setState({isCropEnable: false});
              } else {
                this.setState({ livenessScore: 0, isLive: false });
                this.setState({isCropEnable: false});
                if (
                  getData &&
                  getData.message &&
                  getData.message == "Insufficient Credits"
                ) {
                  this.errorToast("Insufficient Credits");
                  this.setState({isCropEnable: false});
                } else {
                  this.errorToast("Invalid Profile Picture");
                  this.setState({isCropEnable: false});
                }
              }
            }
          });
        }else{
            let fileType = [];
            let mediaFile = [];
            let fileName = selectedTab?selectedTab:"Aadhar";
            if(this.state.cropSelected === 2)
            {
              let proBase = this.state.croppedImgSrc;
              let imagetypesplit=proBase.split(";")   
              let imagetype = imagetypesplit[0].split(":")   
              let cropbase64url = imagetypesplit[1].split(",")
              const panBase64 = proBase.replace(`data:image/png;base64,`, "");
              fileType.push("Pan-Front");
              mediaFile.push(panBase64);
              const d = new Date()
              const time = d.getTime()
              const files= await this.profileDataURLtoFile(cropbase64url[1]?`data:image/jpg;base64,${cropbase64url[1]}`:'', "profile.jpg")        
              let type=imagetype[1]
              var reader = new FileReader();
              var url = reader.readAsDataURL(this.state.profile);
              reader.onloadend = function (e) {
                this.uploadPanDocument(type, reader.result, this.state.profile );
              }.bind(this);
              this.setState({isCropEnable: false});          
            }else if(this.state.cropSelected === 3)
            {
              let proBase = this.state.croppedImgSrc;
              let imagetypesplit=proBase.split(";")     
              let imagetype = imagetypesplit[0].split(":")  
              let cropbase64url = imagetypesplit[1].split(",")
              const panBase64 = proBase.replace(`data:image/png;base64,`, "");
              fileType.push(`${fileName}-Front`);
              mediaFile.push(panBase64);
              const d = new Date()
              const time = d.getTime()
              const files= await this.profileDataURLtoFile(cropbase64url[1]?`data:image/jpg;base64,${cropbase64url[1]}`:'', "profile.jpg")     
              let type=imagetype[1] 
              var reader = new FileReader();
              var url = reader.readAsDataURL(this.state.profile);
              reader.onloadend = function (e) {
                this.uploadFrontDocument(type, reader.result, this.state.profile );
              }.bind(this);
              this.setState({isCropEnable: false});
            }else if(this.state.cropSelected === 4)
            {
              let proBase = this.state.croppedImgSrc;
              let imagetypesplit=proBase.split(";")     
              let imagetype = imagetypesplit[0].split(":")  
              let cropbase64url = imagetypesplit[1].split(",")
              const panBase64 = proBase.replace(`data:image/png;base64,`, "");
              fileType.push(`${fileName}-Back`);
              mediaFile.push(panBase64);
              const d = new Date()
              const time = d.getTime()
              const files= await this.profileDataURLtoFile(cropbase64url[1]?`data:image/jpg;base64,${cropbase64url[1]}`:'', "profile.jpg")     
              let type=imagetype[1] 
              var reader = new FileReader();
              var url = reader.readAsDataURL(this.state.profile);
              reader.onloadend = function (e) {
                this.uploadBackDocument(type, reader.result, this.state.profile );
              }.bind(this);
              this.setState({isCropEnable: false});
            }
         
            let data = {
              base64: mediaFile,
              doctype: fileType,
             // parent_id: "00171000005MEj0AAG", 
             parent_id: this.props.lead_id,
              id: this.props.lead_id,
              token: this.props.salesForceToken
            }        
        }
      }
      panFraudCheck = async (file) => {
        return new Promise(async (resolve, reject) => {
          try {
            const attempt = this.state.panAttempt;
            if (this.state.profile) {
              const { dispatch } = this.props;
              var formdata = new FormData();
              formdata.append("first_image", file);
              formdata.append("sfid", this.props.lead_id);
              formdata.append("second_image", this.state.profile);
              await dispatch(validatePan(formdata)).then(async (response) => {
                const panAttempt = attempt + 1;
                if (response.status == "success") {
                  this.successToast("Verified Successfully.");
                  resolve(true);
                } else {
                  if (panAttempt > 2) {
                    this.setState({
                      validPic: false,
                      errorMsg:
                        "Your pan document not verified still we can moved to backend verification",
                    });
                    resolve(true);
                  } else {
                    this.errorToast(response.message);
                    resolve(false);
                  }
                }
                this.setState({ panAttempt: panAttempt });
              });
            } else {
              this.errorToast(
                "Your profile picture is not ready, please try after some times!"
              );
              resolve(false);
            }
          } catch (err) {
            reject(err.message ? err.message : err);
          }
        });
      };
     

      uploadPanDocument = async (panType, panBase, file) => {
        const check = await this.panFraudCheck(file);
        if (check) {
          const { dispatch } = this.props;
          let DataType = [];
          let mappedFiles = [];
          let BaseType = [];
          let eduFileType = ["PAN"];
          DataType.push("Pan-Front");
          BaseType.push(panType);
          let imagetypesplit=panBase.split(";")
          let cropbase64url = imagetypesplit[1].split(",")
          mappedFiles.push(cropbase64url[1])
          let data = {
            base64: mappedFiles,
            doctype: DataType,
            catType: "PAN",
            basetype: BaseType,
            parent_id: this.props.lead_id,
            id: this.props.lead_id,
            fileType: eduFileType,
            is_doc_verified: true,
            token: this.props.salesForceToken,
          };
         
          await dispatch(uploadDocument(data)).then((response) => {
            if (response.status == "success") {
              this.props.dispatch(
                verifyDoc({
                  isPanUpload: true,
                  is_force_verification: false,
                  user_sfid: this.props.lead_id,
                })
              );
              this.setState({ modalPan: panBase });
              this.getPanDoc();
            }
          });
        }
      };


      getPanDoc = () => {
        let proData = {
          sfid: this.props.lead_id,
          doc_type: "PAN",
        };
        this.props.dispatch(getDocData(proData)).then((response) => {
          if (response.status === "success") {
            let getData = response.imageData;
            let type = 0;
            let DocBase = "";
            if (getData.length > 0) {
              var img = getData[0];
              if (img.filetype == "PDF") {
                type = 2;
                DocBase = "data:application/pdf;base64," + img.base64.base64;
              } else {
                type = 1;
                DocBase = "data:image/jpg;base64," + img.base64.base64;
              }
              this.setState({
                panFileType: type,
               // panBase: DocBase,
                modalPan:DocBase,
                // panType: img.filetype,
                // panId: resData.id
                // profileId: getData.id
              });
            }
           
          }
        });
      };
    

      uploadBackDocument = async (backProofType, backProofBase, file) => {
        const { dispatch } = this.props;
        const tab = this.state.selectedTab;
        const check = await this.checkFraud(file, 2, tab);
        if (check[0]) {
          var addKey = check[1].data.result[0].details;
          let DataType = [];
          let mappedFiles = [];
          let BaseType = [];
          let eduFileType = [];
          if (this.state.selectedTab === 1) {
            DataType.push("Aadhar-Back");
            eduFileType.push("Aadhar Back");
          } else if (this.state.selectedTab === 2) {
            DataType.push("Driving-Back");
            eduFileType.push("Driving License");
          } else if (this.state.selectedTab === 3) {
            DataType.push("Voter-Back");
            eduFileType.push("Voter ID");
          } else {
            DataType.push("Passport-Back");
            eduFileType.push("Passport Back");
          }
          BaseType.push(backProofType);
         
          let imagetypesplit=backProofBase.split(";")
         
    let cropbase64url = imagetypesplit[1].split(",")
   
          mappedFiles.push(cropbase64url[1]);
          let data = {
            base64: mappedFiles,
            doctype: DataType,
            catType: "ID Proof",
            basetype: BaseType,
            parent_id: this.props.lead_id,
            id: this.props.lead_id,
            fileType: eduFileType,
            is_doc_verified: this.state.backDocAttempt > 2 ? false : true,
            token: this.props.salesForceToken,
          };
          let selectedTab = this.state.selectedTab;
          await dispatch(uploadDocument(data)).then((response) => {
            if (response.status == "success") {
              this.props.dispatch(
                verifyDoc({
                  isAadharUpload: true,
                  is_force_verification: false,
                  user_sfid: this.props.lead_id,
                })
              );
              this.props.dispatch(
                uploadDocAdharBack({
                  document_type: "aa",
                  doc__type: "bb",
                  user_sfid: this.props.lead_id,
                })
              );
              if (addKey.address) {
                this.setState({
                  isAutoAdd: false,
                  address: addKey.address.value ? addKey.address.value : "",
                  city: addKey.address.city ? addKey.address.city : "",
                  state: addKey.address.state ? addKey.address.state : "",
                  address_pin: addKey.address.pin ? addKey.address.pin : "",
                  pincode: addKey.address.pin ? addKey.address.pin : "",
                  // selectedTab:1,
                  // frontProofBase: base64File,
                });
              }
              if (this.state.frontProofBase) {
                this.props.dispatch(
                  verifyDoc({
                    isKycDone: true,
                    is_force_verification: false,
                    user_sfid: this.props.lead_id,
                  })
                );
              }
              this.getOtherDoc();
            }
          });
          this.setState({ otherBackImg: backProofBase, defaultTab: selectedTab });
        }
      };
    

      closeModel = () => {
        this.setState({
          isCropEnable: false,
          modalPan: "",
          modalPhoto: "",
          modalFrontImg: "",
          modalBackImg: "",
          modalClose: false
        });
        this.props.dispatch(closeImageEditModel())
      }

      getOthers = (otherFrontImg, enableCrop) =>{
          let data = [];
          const [,type] = otherFrontImg.split(';')[0].split('/');
          if(otherFrontImg)
          {
            if(type ==="pdf" || type ==="PDF")
            {
              data.push(<img style={{cursor:'pointer', padding: '10px'}} src='./images/pdf.png' />);
            }else{
            data.push(<>
            <img key={"other-1"} src={otherFrontImg} />
            <div key={"roe-1"} className="row mt-2 align-items-center justify-content-center">
                {!this.state.isCropEnable &&  enableCrop ==1 &&(  
                  <button key={"btn-2"} type="button" onClick={() => this.handleCrop(3)} className="btn btn-default btncrop"><i className="fas fa-crop-alt mr-2"></i>Crop</button>
                )}
              </div>
            </>);
            }
          }else{
              data.push(<img key={"other-0"} src="images/dummy.png" />);
          }
          return data;
      }

      getOthersBackDoc = (otherBackImg, enableCrop) =>{
        let data = [];
        const [,type] = otherBackImg.split(';')[0].split('/');
        if(otherBackImg)
        {
          if(type ==="pdf" || type ==="PDF")
          {
            data.push(<img style={{cursor:'pointer', padding: '10px'}} src='./images/pdf.png' />);
          }else{
            data.push(<>
              <img key={"other-2"} src={otherBackImg} />
              <div key={"roe-2"} className="row mt-2 align-items-center justify-content-center">
                  {!this.state.isCropEnable &&  enableCrop ==1 &&(  
                    <button key={"btn-2"} type="button" onClick={() => this.handleCrop(4)} className="btn btn-default btncrop"><i className="fas fa-crop-alt mr-2"></i>Crop</button>
                  )}
                </div>
              </>
              );
          }
           
        }else{
            data.push(<img key={"other-0"} src="images/dummy.png" />);
        }
        return data;
    }

      handleOther = (value) =>{
        this.setState({otherSelected: value});
      }

      handleTabChange = (value) =>{
        this.setState({selectedNav: value});
      }

      renderPandetails = (baseStr) =>{
        const { selectedImageTab, enableCrop } = this.props
        let row = [];
        const [,type] = baseStr.split(';')[0].split('/');
        if(baseStr)
        {
          if(type ==="pdf" || type ==="PDF")
          {
            row.push(
            <div className={`carousel-item ${selectedImageTab==2?"active":""}`}>
              <img style={{cursor:'pointer', padding: '10px'}} src='./images/pdf.png' />
            </div>
              );
          }else{
            row.push(<div className={`carousel-item ${selectedImageTab==2?"active":""}`}>
              <img src={baseStr} />
                  <div className="row mt-2 align-items-center justify-content-center">
                  {!this.state.isCropEnable &&  enableCrop ==1 &&(  
                    <button type="button" onClick={() => this.handleCrop(2)} className="btn btn-default btncrop"><i className="fas fa-crop-alt mr-2"></i>Crop</button>
                  )}
                </div>
            </div>);
          }
        }
        return row;
      }

      renderPhoto = (baseStr) => {
        const { selectedImageTab, enableCrop } = this.props
        let row = [];
        const [,type] = baseStr.split(';')[0].split('/');
        if(baseStr)
        {
          if(type ==="pdf" || type ==="PDF")
          {
            row.push(
            <div className={`carousel-item ${selectedImageTab==2?"active":""}`}>
              <img style={{cursor:'pointer', padding: '10px'}} src='./images/pdf.png' />
            </div>
              );
          }else{
            row.push(<div className={`carousel-item ${selectedImageTab==1?"active":""}`}>
              <img src={baseStr} />
                  <div className="row mt-2 align-items-center justify-content-center">
                  {!this.state.isCropEnable &&  enableCrop ==1 &&(  
                    <button type="button" onClick={() => this.handleCrop(1)} className="btn btn-default"><i className="fas fa-crop-alt"></i></button>
                  )}
                </div>
            </div>);
          }
        }
        return row;
      }

    render() {
        const { selectedImageTab, image_edit_show, leadProfileImg, enableCrop, selectedTab, leadPanImg, otherFrontImg,otherBackImg } = this.props
        const { imageSrc, base64, croppedImgSrc, otherSelected, selectedNav } = this.state;
        const  renderOthers = this.getOthers(otherFrontImg ? otherFrontImg : this.state.modalFrontImg , enableCrop);
        const renderOtherBack = this.getOthersBackDoc(otherBackImg ? otherBackImg : this.state.modalBackImg, enableCrop);
        const renderPan = this.renderPandetails(leadPanImg ? leadPanImg : this.state.modalPan);
        const renderPhoto = this.renderPhoto(leadProfileImg ? leadProfileImg : this.state.modalPhoto);
        return (
            <>
              <Modal show={image_edit_show} className="bulkupload type_2 modelminheight">
                <form>
                  <Modal.Body>
                    <>
                    <div>
                      <div id="icropcaro" className="carousel slide" data-ride="carousel" data-interval="false">
                        <button type="button" className="close float-right" onClick={this.closeModel} style={{paddingTop:"15px",paddingRight:"35px"}}> <i className="fas fa-times"></i> </button>
                        {/* <ol className="carousel-indicators">
                          {leadProfileImg || this.state.modalPhoto && (
                            <li data-target="#icropcaro" onClick={() =>this.handleTabChange(1)} data-slide-to="0" className={`${selectedImageTab==1?"active":""}`}>Photo</li>
                          )}
                          {leadPanImg && (
                            <li data-target="#icropcaro" onClick={() =>this.handleTabChange(2)} data-slide-to="1" className={`${selectedImageTab==2?"active":""}`}>PAN Card</li>
                          )}
                          {
                           (otherFrontImg || this.state.modalFrontImg) &&  (otherBackImg || this.state.modalBackImg) &&(
                             <li data-target="#icropcaro" onClick={() =>this.handleTabChange(3)} data-slide-to="2" className={`${(selectedImageTab==3 || selectedImageTab==4)?"active":""}`}>{selectedTab? selectedTab:"Aadhar"} Card</li>
                           )
                          }
                        </ol> */}
                      {this.state.isCropEnable === false?(
                      <div className="carousel-inner text-center">
                        {/* {leadProfileImg || this.state.modalPhoto && (
                        <div className={`carousel-item ${selectedImageTab==1?"active":""}`}>
                              {
                                leadProfileImg ? 
                                <img src={leadProfileImg} />
                                :
                                <img src={this.state.modalPhoto} />
                              }
                              <div className="row mt-2 align-items-center justify-content-center">
                                {!this.state.isCropEnable &&  enableCrop ==1 &&(  
                                  <button type="button " onClick={() => this.handleCrop(1)} className="btn btn-default btncrop"><i className="fas fa-crop-alt mr-2"></i>Crop</button>
                                )}
                              </div>
                        </div>
                        )} */}
                        {selectedImageTab == 1 && renderPhoto}
                        {selectedImageTab == 2 && renderPan}
                      <div className={`carousel-item tabs_type2 ${(selectedImageTab===3 || selectedImageTab===4)?"active":""}`}>
                        
                         <ul className="nav nav-pills justify-content-center mb-3 nav-pillsfront_back" role="tablist">
                        <li className="nav-item">
                          <a className={`nav-link  ${selectedImageTab===3?'active':selectedImageTab !==4?'active':''}`} data-toggle="pill" href="#panfront" onClick={() => this.handleOther(1)}>Front</a>
                        </li>
                        <li className="nav-item">
                          <a className={`nav-link ${selectedImageTab===4?'active':''}`} data-toggle="pill" href="#panback" onClick={() => this.handleOther(2)} >Back</a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div id="panfront" className={`container tab-pane ${(selectedImageTab===3)?'active':selectedImageTab !==4?'active':''}`}>
                        {renderOthers}
                        </div>
                        <div id="panback" className={`container tab-pane ${selectedImageTab===4?'active':'fade'}`}>
                        {renderOtherBack}
                        </div>
                      </div>
                      </div>
    
                    </div>
                      ):(
                      <>
                      <div className="carousel-inner text-center">                     
                        <div className="carousel-item active">
                            <Cropper
                                style={{ maxWidth: "600px", height: "300px" }}
                                ref={this.cropper}
                                src={this.state.imageSrc}
                             //   aspectRatio={this.state.aspectRatio}                         
                                cropend={() => this.handleCropChange()}
                            />
                            <div className="row mt-2 align-items-center justify-content-center">
                              <button type="button" onClick={() => this.handleLeftRotate()} className="btn btn-default"><i className="fas fa-undo"></i> </button>
                              <button type="button" onClick={() => this.handleRotate()} className="btn btn-default"><i className="fas fa-redo"></i></button>
                              <button type="button" onClick={(e) => this.handleCropSave(e)} className="btn btn-default"><i className="fas fa-save"></i></button>            
                            </div>
                        </div>
                      </div>
                      </>
                    )}
                    {/* <a className="carousel-control-prev" href="#icropcaro" role="button" data-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#icropcaro" role="button" data-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="sr-only">Next</span>
                    </a> */}
                  </div>
                </div>
                </>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeModel} className="border-0 btn btn-default">Cancel</Button>
                    {
                      (enableCrop == 1) &&
                      <Button onClick={this.closeModel} className="btn btn-secondary">Save</Button>
                    }
                </Modal.Footer>
            </form>
          </Modal>
        </>
        )
    }

}

function mapStateToProps(state) {
    const { image_edit_show, enableCrop, selectedImageTab } = state.model;
    const { salesForceToken } = state.auth;
    const { leadProfileImg, leadPanImg, otherFrontImg,otherBackImg, selectedTab, lead_id } = state.user
    return {
        image_edit_show,
        selectedImageTab,
        leadProfileImg,
        enableCrop,
        leadPanImg,
        otherFrontImg,
        otherBackImg,
        selectedTab,
        salesForceToken,
        lead_id
    };
  }

export default connect(mapStateToProps)(ImageEdit)
import React, { Component } from "react";
import $ from 'jquery';
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BankDetails from "../common/bank-details";
import Sidebar from "../common/sidebar";
import { getUsers, getUser_data, setUserDataByID, getUsersListWizr, getMerchantDetails, update_user_details, getRole, getRoleData, getUserData, updateMerchantProfile, getMerchantProfile, getCategory, getSubCategory, getAllSubCategory, logout } from "../actions/user"
import moment from 'moment'
import {
  openAddUser,
  openAddRole,
  openEditRole,
  openEditUser,
  closeEditUser,
  closeAddUser
} from "../actions/model"
import { getRoles } from "@testing-library/react";
import Topbar from "../common/topbar";
import { Button } from "react-bootstrap";
import TopWizrbar from "./TopWizrbar";
import Pagination from '@material-ui/lab/Pagination';
import TopBarWizrHelpNotification from "./TopBarWizrHelpNotification";
import TablePagination from '@mui/material/TablePagination';
import SuccessErrorMsg from "../common/succesErrormsg";
import tableSort from "../common/tableSort"
import NoRecordsFound from "../common/NoRecordsFound.component";
import { history } from '../helpers/history';
import Avatar from '@mui/material/Avatar';
// import moment from 'moment';

let showTimer;
class Settings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      profileEdit: false,
      created_date: "",
      merchant_name: "",
      brand_name: "",
      entity_name: "",
      website: "",
      pan_no: "",
      gst: "",
      cin: "",
      region_service: "",
      address: "",
      SubCatArr: [],
      isGst: true,
      isCin: true,
      failMessage: '',
      failMessageGst: '',
      failMessageCin: '',
      isValidPan: true,
      errorMsg: '',
      successMsg: '',
      isSuccess: '',
      failMessagePan: '',
      isPanValid: true,
      profilePhoto: null,
      showSearch: false,
      page: 0,
      limit: 10,
      showAddNewUser: false,
      rowsPerPage: 10,
      showUserSuccessMsg: false,
      showAddedMsg: "",
      searchKey: "",
      userData: [],
      finalUserData: [],
      isSearching: false,
      searchData: [],
      showLogout: false,
      showBankdata: false,
      pageCount: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    $('#sidebarToggleTop').click(function () {
      $("#accordionSidebar").toggleClass("open-close")
    })
    const { user_id, sfid, users } = this.props;
    let data = {
      owner: sfid,
    }
    this.setState({ userData: users, finalUserData: users.slice(0, 10), showLogout: true, searchKey: "" })
    // this.props.dispatch(getUsers(data));

    // this.props.dispatch(getUsersListWizr(sfid))

    let getData = {
      owner_id: user_id
    }
    // api code  commented because unneccesary api call
    //this.props.dispatch(getRole(getData));

    // this.props.dispatch(getCategory());
    // let mdata = {
    //   user_sfid: sfid
    // }
    // this.props.dispatch(getMerchantProfile(mdata)).then((response) => {
    //   if (response.status === "success") {
    //     let obj = response.accountDet;
    //     let bdata = obj && obj.brand;
    //     const addressDet = obj && obj.address ? obj.address : null
    //     const brandDet = obj && obj.brand ? obj.brand : null
    //     console.log("res", obj)
    //     console.log("bdata", bdata)
    //     this.setState({
    //       created_date: obj && obj.createddate ? obj.createddate : '',
    //       merchant_name: obj && obj.first_name__c ? obj.first_name__c : '',
    //       brand_name: obj && obj.brand_name__c ? obj.brand_name__c : '',
    //       entity_name: obj && obj.entity_name__c ? obj.entity_name__c : '',
    //       website: obj && obj.website ? obj.website : '',
    //       pan_no: obj && obj.pan_number__c ? obj.pan_number__c : '',
    //       gst: obj && obj.gstin__c ? obj.gstin__c : '',
    //       cin: obj && obj.cin_no__c ? obj.cin_no__c : '',
    //       //region_service:obj && obj.region_of_service__c?obj.region_of_service__c:'',
    //       //region_service:"Mumbai, Pune",
    //       region_service: obj && obj.billingcity ? obj.billingcity : '',
    //       address: addressDet && addressDet.address__c ? addressDet.address__c : '',
    //       communication_address__c: obj && obj.communication_address__c ? obj.communication_address__c : '',
    //       profilePhoto: obj && obj.merchant_logo_url__c
    //     });
    //   }
    // });
    let dataObj = {
      merchant_id: sfid
    }
    this.props.dispatch(getMerchantDetails(dataObj)).then(response => {
      let obj = response.data;
      if (response.status === "success") {
        this.setState({
          created_date: obj && obj.createddate ? obj.createddate : '',
          // merchant_name: obj && obj.first_name__c ? obj.first_name__c : '',
          // brand_name: obj && obj.brand_name__c ? obj.brand_name__c : '',
          entity_name: obj && obj.entity_name__c ? obj.entity_name__c : '-',
          website: obj && obj.website ? obj.website : '-',
          pan_no: obj && obj.pan_number__c ? obj.pan_number__c : '-',
          gst: obj && obj.gstin__c ? obj.gstin__c : '-',
          cin: obj && obj.cin_no__c ? obj.cin_no__c : '-',
          //region_service:obj && obj.region_of_service__c?obj.region_of_service__c:'',
          //region_service:"Mumbai, Pune",
          // region_service: obj && obj.billingcity ? obj.billingcity : '',
          // address: addressDet && addressDet.address__c ? addressDet.address__c : '',
          communication_address__c: obj && obj.kyc_address__c ? obj.kyc_address__c : '-',
          profilePhoto: obj && obj.merchant_logo_url__c
        });
      } else {
        if (response.status == 401) {
          this.props.dispatch(logout());
          history.push('/login');
        }
      }
    }).catch(error => {
      // console.log(error, 'error while fetching merchant details')
    })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isUserAddedSuccess !== this.props.isUserAddedSuccess) {
      if (this.props.isUserAddedSuccess) {
        this.setState({ showUserSuccessMsg: true, showAddedMsg: this.props.isEditAddedMsg })
        showTimer = setTimeout(() => {
          this.setState({ showUserSuccessMsg: false })
        }, 3000);
        // clearTimeout(showTimer)
      }
      else {
        clearTimeout(showTimer)
        this.setState({ showUserSuccessMsg: false })
      }

    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.users != nextProps.users) {
      this.setState({ userData: nextProps.users, finalUserData: nextProps.users.slice(0, 10), })
      return true;
    } else {
      return true
    }
  }

  stringAvatar = (name) => {
    if(name){
      return {
        sx: {
          color: "#50495A",
          bgcolor: "##F5F4F6",
          fontSize: "32px",
          fontFamily: "Graphik SemiBold",
          fontWeigth: "600",
          textAlign: "center",
          width: 68,
          height: 68
        },
        children: name.split(' ')[1] ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : `${name.split(' ')[0][0]}`,
      };
    }else{
      return {
        sx: {
          bgcolor: "#50495A",
        },
        children: `NA`,
      };
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  
  handlePanChange = (e) => {
    e.persist();
    var regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/i;
    if (e.target.name == 'pan_no') {
      if (regex.test(e.target.value)) {
        this.setState({
          isPanValid: true,
          failMessagePan: '',
        })
      } else {
        this.setState({
          isPanValid: false,
          failMessagePan: 'Invalid Pan Number',
        })
      }
    }
    this.setState(
      { [e.target.name]: e.target.value }
    );
  }

  handleGSTAndCIN = (e) => {
    let regexCin = new RegExp(/^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/)
    let regexGst = new RegExp(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/);
    if (e.target.name == 'cin') {
      if (regexCin.test(e.target.value)) {
        this.setState({
          isCin: true,
          failMessageCin: '',
        })
      } else {
        this.setState({
          isCin: false,
          failMessageCin: 'Invalid CIN Number',
        })
      }
    } else if (e.target.name == 'gst') {
      if (regexGst.test(e.target.value)) {
        this.setState({
          isGst: true,
          failMessageGst: '',
        })
      } else {
        this.setState({
          isGst: false,
          failMessageGst: 'Invalid GST Number',
        })
      }
    }
    this.setState(
      { [e.target.name]: e.target.value }
    );
  }

  // cateChange = (e) => {
  //   let data = {
  //     parent_id: e.target.value,
  //   }
  //   this.props.dispatch(getAllSubCategory(data)).then(res => {
  //     this.setState({ SubCatArr: res });
  //   });
  // }

  chgProfile = () => {
    this.setState({ profileEdit: true });
    $('html, body').animate({
      scrollTop: $("#profileDiv").offset().top
    }, 1000);


  }

  openAddUser = () => {
    this.props.dispatch(openAddUser());
  }

  openAddRole = () => {
    this.props.dispatch(openAddRole());
  }

  openEditRole = (roleid) => {
    const { user_id } = this.props;
    let data = {
      owner_id: user_id,
      role_id: roleid
    }
    this.props.dispatch(getRoleData(data)).then((response) => {
    });
    this.props.dispatch(openEditRole(roleid));
  }

  openEditUser = (usersid, data) => {
    // const { user_id } = this.props;
    // let data = {
    //   owner_id: user_id,
    //   id: usersid
    // }

    // this.props.dispatch(getUser_data(usersid)).then((response) => {
    //   // this.props.dispatch(getUserData(data)).then((response) => {

    // });
    this.props.dispatch(openEditUser(usersid));
    this.props.dispatch(setUserDataByID(data))
  }

  handleRegister = (e) => {
    e.preventDefault();
    const { sfid } = this.props
    let obj = {
      user_sfid: sfid,
      first_name: this.state.merchant_name,
      brand_name: this.state.brand_name,
      entity_name: this.state.entity_name,
      website: this.state.website,
      pan_no: this.state.pan_no,
      gst: this.state.gst,
      cin: this.state.cin,
      address: this.state.address,
      communication_address__c: this.state.communication_address__c,
      region: this.state.region_service
    }



    this.props.dispatch(updateMerchantProfile(obj)).then((response) => {
      if (response && response.status && response.status == "success") {
        toast.success(response.message);
      } else {
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  }


  handleUpdateProfile = (e) => {
    e.preventDefault();
    return;

    this.props.update_merchant_profile((data) => {

    })

  }


  handleActive_status = (e, id) => {
    const { users } = this.props;
    e.persist();

    const oneuser = users.find((item) => {
      return item.id === id
    });

    let active_status = false;
    if (e.target.checked) {
      active_status = true;
    }
    let givenData = {}
    if (oneuser.sfid === null) {
      givenData = {
        mobileNumber: oneuser.mobile__c,
        username: oneuser.name__c,
        role: oneuser.select_user__c,
        department: oneuser.department__c,
        email: oneuser.email__c,
        owner: localStorage.getItem('sfid'),
        active_status: active_status,
        id: oneuser.id
      }
    }
    else {

      givenData = {
        mobileNumber: oneuser.mobile__c,
        username: oneuser.name__c,
        role: oneuser.select_user__c,
        department: oneuser.department__c,
        email: oneuser.email__c,
        owner: localStorage.getItem('sfid'),
        active_status: active_status,
        sfid: oneuser.sfid
      }
    }

    this.props
      .dispatch(
        update_user_details(givenData)
      )
      .then(() => {
        const { sfid } = this.props;
        const { page, limit, searchKey } = this.state
        let obj = {
          merchant_id: sfid,
          search: searchKey,
          limit,
          page
        }
        this.props.dispatch(getUsersListWizr(obj))
      })
      .catch(() => {

      });

  }
  handleChangePage = (event, value) => {
    let getProd = { merchant_id: this.props.user_id, page: value, limit: this.state.limit }
    this.setState({ page: value });
  }
  handleChangelimitOfPage = (event) => {
    let perRowData = event.target.value;
    let getProd = { merchant_id: this.props.user_id, page: this.state.page, limit: perRowData }
    this.setState({ limit: perRowData });
    // this.props.dispatch(getMerchantProducts(getProd));
  }
  handleChangePageNew = (event, newPage) => {
    // this.setState({page:updatedPage,newPage})
    // let {page} = this.state;: page: page
    // let updatedPage = page+1;
    const { userData, limit } = this.state;
    // const user = userData.slice(newPage * limit, newPage * limit + limit);
    // console.log(user, "user", user.length, newPage * limit, newPage * limit + limit);
    this.setState({ page: newPage }, () => { this.getUserRole() })
  };
  handleChangeRowsPerPage = (event) => {
    const { userData, page } = this.state;
    // const user = userData.slice(0, parseInt(event.target.value, 10));
    this.setState({ limit: parseInt(event.target.value, 10), page: 0 }, () => { this.getUserRole() })
  };
  handleSearchChange = (e) => {
    if (e.target.value == "") {
      this.setState({ isSearching: false, searchKey: "" }, () => { this.getUserRole() })
      return
    }
    if (e.target.value.length >= 2) {
      // let data = this.state.userData.filter((item) => {
      //   return (item.name__c.toLowerCase().includes(e.target.value))
      // })
      // this.setState({ searchKey: e.target.value, isSearching: true, searchData: data })
      this.setState({ searchKey: e.target.value, isSearching: true }, () => { this.getUserRole("search") })
    }
  }
  sortBy = (givenArray, sortKey, order) => {
    for(let i=0;i<givenArray.length;i++){
      if(givenArray[i][sortKey]==null){
        givenArray[i][sortKey]=""
      }
    }
    const sortedData = tableSort(givenArray, sortKey, order);
    // this.setState({ finalUserData: sortedData })
    this.setState({ userData: sortedData })
  }

  getUserRole = (ifSearch) => {
    this.setState({ showSearch: true, showAddNewUser: true, showLogout: false, showBankdata: false })
    const { user_id, sfid, users } = this.props;
    const { page, limit, searchKey } = this.state
    let obj = {
      merchant_id: sfid,
      search: searchKey,
      limit,
      page
    }
    this.props.dispatch(getUsersListWizr(obj)).then(response => {
      this.setState({ pageCount: response.totalCount })
      if (ifSearch == "search") {
        this.setState({ searchData: response.data, page: 0 })
      }
    })
  }

  componentWillUnmount(){
      this.props.dispatch(closeEditUser())
      this.props.dispatch(closeAddUser())
      clearTimeout(showTimer);
  }

  render() {
    const { user_id, users, roles, add_account, category, sub_cat, isLoading, merchant_banks, activeWizrTab, isUserAddedSuccess } = this.props;
    const { SubCatArr, isSearching, page, limit } = this.state;
    const subBtn = { background: '#1F1F2D', borderRadius: '10px', color: '#ffffff' };
    if (!user_id) {
      return <Redirect to="/login" />
    }
    const totalPages = users.length ? Math.ceil(this.state.pageCount / Number(this.state.limit)) : 1;
    return (
      <>
        <Helmet>
          <title> Settings </title>
        </Helmet>
        {isLoading ? (
          <div className="loading">Loading&#8230;</div>
        ) : ''}
        {/* {this.state.merchant_name == '' ? (
          <div className="loading">Loading&#8230;</div>
        ) : ''}  */}
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              {/* <Topbar
                dispatch={this.props.dispatch}
                title={"Settings"}
              /> */}
              <TopBarWizrHelpNotification />
              <TopWizrbar showAddNewUser={this.state.showAddNewUser} openAddUser={this.openAddUser} showLogout={this.state.showLogout} />
              {/* <div className="container-fluid leads_header">
                <div className="row align-items-center">
                  <div className="col-md-7 d-flex flex-wrap">

                    <h1 className="mr-3 min-width150">
                      <button id="sidebarToggleTop" className="btn btn-link d-lg-none rounded-circle mr-3">
                        <i className="fa fa-bars"></i>
                      </button>{"Settings"}</h1>
                  </div>
                 
                </div>
              </div> */}

              <div className="container-fluid">
                <div className="row flex-lg-row flex-column-reverse mt-4">
                  <div className="col-md-9">
                    <div className="product_page_tabs_wrapper">
                      <nav className="product_page_tabs">
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                          <a
                            className="nav-item nav-link active"
                            id="nav-profile-tab"
                            data-toggle="tab"
                            href="#nav-profile"
                            role="tab"
                            aria-controls="nav-profile"
                            aria-selected="true"
                            onClick={() => this.setState({ showSearch: false, showAddNewUser: false, showLogout: true, showBankdata: false })}
                          >Profile</a>
                          <a
                            className="nav-item nav-link"
                            id="nav-user-role-management-tab"
                            data-toggle="tab"
                            href="#nav-user-role-management"
                            role="tab"
                            aria-controls="nav-user-role-management"
                            aria-selected="false"
                            //onClick={() => this.setState({ showSearch: true, showAddNewUser: true, showLogout: false })}
                            onClick={() => this.getUserRole()}

                          >User Role Management</a>
                          <a
                            className="nav-item nav-link"
                            id="nav-bank-details-tab"
                            data-toggle="tab"
                            href="#nav-bank-details"
                            role="tab"
                            aria-controls="nav-bank-details"
                            aria-selected="false"
                            onClick={() => this.setState({ showSearch: false, showAddNewUser: false, showLogout: false, showBankdata: true })}
                          > Bank Details</a>

                          {/* <a
                            className="nav-item nav-link"
                            id="nav-API-keys-tab"
                            data-toggle="tab"
                            href="#nav-API-keys"
                            role="tab"
                            aria-controls="nav-API-keys"
                            aria-selected="false"
                          >API Keys</a> */}
                          {/* <a
                                className="nav-item nav-link"
                                id="nav-cat-tab"
                                data-toggle="tab"
                                href="#nav-cat-details"
                                role="tab"
                                aria-controls="nav-cat-details"
                                aria-selected="false"
                              > Manage Category</a> */}
                          {/* <a
                                className="nav-item nav-link"
                                id="nav-manage-session-tab"
                                data-toggle="tab"
                                href="#nav-manage-session"
                                role="tab"
                                aria-controls="nav-manage-session"
                                aria-selected="false"
                              >Manage Session</a>
                              <a
                                className="nav-item nav-link"
                                id="nav-API-keys-tab"
                                data-toggle="tab"
                                href="#nav-API-keys"
                                role="tab"
                                aria-controls="nav-API-keys"
                                aria-selected="false"
                              >API Keys</a> */}
                          {/* <div className='success-toast-con'>
                                        <img src='./images/client/courseSuccess.svg'/>
                                        <div className='fw-600 fz14 lineheight20 fontcolorfig'>User Created Successfully</div>
                                    </div> */}
                          {this.state.showUserSuccessMsg ? <SuccessErrorMsg top="0%" left="50%" message={this.state.showAddedMsg} /> : ""}
                        </div>
                      </nav>

                    </div>
                  </div>
                  <div className="col-lg-3 searchbarForUserMgmt">
                    {/* {this.state.showSearch && <Topbar
                      // dispatch={this.props.dispatch}
                      title={""}
                      stage={""}
                    />} */}
                    {this.state.showSearch &&
                      // <input type="text" placeholder="Search" />
                      <div class="header_search_wrapper"><div class="search-form nav_search"><div class="form-group has-feedback "><label for="search" class="sr-only">Search</label><input type="text" class="form-control searchRounded" name="search" id="search" placeholder="Search" onChange={this.handleSearchChange} /></div></div></div>}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="">
                      <div className="card-body">
                        <div className="tab-content pt-4" id="nav-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="nav-profile"
                            role="tabpanel"
                            aria-labelledby="nav-profile-tab"
                          >

                            {/* <div className="col-lg-10 col-12 shadow p-4" id="profileDiv">

                              {!this.state.profileEdit ? (
                                <>
                                  <div className="row">
                                    <div className="col-12">
                                      <button onClick={() => this.chgProfile()} className="edit-setting-profile-btn">
                                        <img src="images/icons/edit_20.png" />
                                      </button>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <h5>Profile</h5>

                                      <div className="pt-lg-5 mt-lg-5 text-center">
                                        {
                                          this.state.profilePhoto 
                                          ? <img src={this.state.profilePhoto} width="100%"/>                                           
                                          : <img src="https://eduvanz.s3.ap-south-1.amazonaws.com/images/eduvanz_logo.png" width="100%"/>
                                        }
                                        
                                      </div>
                                    </div>
                                    <div className="col-lg-9">
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <div className="pro-data mb-3">
                                            <label>Onboarded On</label>
                                            <input value={this.state.created_date} disabled />
                                          </div>
                                          <div className="pro-data mb-3">
                                            <label>Brand Name</label>
                                            <input value={this.state.brand_name} disabled />
                                          </div>
                                          <div className="pro-data mb-3">
                                            <label>Website URL</label>
                                            <input value={this.state.website} disabled />
                                          </div>
                                          <div className="pro-data mb-3">
                                            <label>GST</label>
                                            <input value={this.state.gst} disabled />
                                          </div>
                                          <div className="pro-data mb-3">
                                            <label>Region of Service</label>
                                            <input value={this.state.region_service ? this.state.region_service : ''} disabled />
                                          </div>
                                          <div className="pro-data mb-3">
                                            <label>
                                              Communication Address
                                            </label>
                                            <textarea value={this.state.communication_address__c ? this.state.communication_address__c : ''} disabled />
                                          </div>
                                        </div>
                                        <div className="col-lg-6">

                                          <div className="pro-data mb-3">
                                            <label>PM Name</label>
                                            <input value={this.state.merchant_name} disabled />
                                          </div>

                                          <div className="pro-data mb-3">
                                            <label>Entity Name</label>
                                            <input value={this.state.entity_name} disabled />
                                          </div>
                                          <div className="pro-data mb-3">
                                            <label>PAN</label>
                                            <input value={this.state.pan_no} disabled />
                                          </div>
                                          <div className="pro-data mb-3">
                                            <label>CIN</label>
                                            <input value={this.state.cin} disabled />
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) :
                                <>
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <h5>Profile</h5>

                                      <div className="pt-lg-5 mt-lg-5 text-center">
                                        <img src="images/icons/logo-croma.png" />
                                      </div>
                                    </div>
                                    <form className="col-lg-9" onSubmit={this.handleRegister} >
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <div className="pro-data m-b-20">
                                            <label>Onboarded On</label>
                                            <input value={this.state.created_date} disabled />
                                          </div>
                                          <div className="bor8 m-b-20 how-pos4-parent">
                                            <label htmlFor="brand_name">Brand Name</label>
                                            <input onChange={this.handleChange} name="brand_name" id="brand_name" value={this.state.brand_name} type="text" />
                                          </div>
                                          <div className="bor8 m-b-20 how-pos4-parent">
                                            <label htmlFor="website">Website URL</label>
                                            <input name="website" id="website" onChange={this.handleChange} value={this.state.website ? this.state.website : ''} />
                                          </div>
                                          <div className="bor8 m-b-20 how-pos4-parent">
                                            <label htmlFor="gst">GST</label>
                                            <input onChange={this.handleGSTAndCIN} name="gst" id="gst" value={this.state.gst} />
                                          </div>
                                          {
                                            !this.state.isGst ?
                                              <span style={{
                                                color: '#EA4335', position: 'absolute', top: '265px'
                                              }}>{this.state.failMessageGst}</span>
                                              : ''
                                          }
                                          <div className="bor8 m-b-20 how-pos4-parent">
                                            <label htmlFor="region_service">Region of Service</label>
                                            <input onChange={this.handleChange} name="region_service" id="region_service" value={this.state.region_service ? this.state.region_service : ''} />
                                          </div>
                                          <div className="bor8 m-b-20 how-pos4-parent">
                                            <label htmlFor="address">
                                              Communication Address
                                            </label>
                                            <textarea onChange={this.handleChange} name="address" id="address" value={this.state.address ? this.state.address : ''} />
                                          </div>

                                        </div>
                                        <div className="col-lg-6">

                                          <div className="bor8 m-b-20 how-pos4-parent">
                                            <label htmlFor="merchant_name">PM Name</label>
                                            <input onChange={this.handleChange} name="merchant_name" id="merchant_name" value={this.state.merchant_name} />
                                          </div>

                                          <div className="bor8 m-b-20 how-pos4-parent">
                                            <label htmlFor="entity_name">Entity Name</label>
                                            <input onChange={this.handleChange} name="entity_name" id="entity_name" value={this.state.entity_name} />
                                          </div>
                                          <div className="bor8 m-b-20 how-pos4-parent">
                                            <label htmlFor="pan_no">PAN</label>
                                            <input onChange={this.handlePanChange} name="pan_no" id="pan_no" value={this.state.pan_no} />
                                          </div>
                                          {
                                            !this.state.isPanValid ?
                                              <span style={{ color: '#EA4335', position: 'absolute', top: '195px' }}>{this.state.failMessagePan}</span>
                                              : ''
                                          }
                                          <div className="bor8 m-b-20 how-pos4-parent">
                                            <label htmlFor="cin">CIN</label>
                                            <input onChange={this.handleGSTAndCIN} name="cin" id="cin" value={this.state.cin} />
                                          </div>
                                          {
                                            !this.state.isCin ?
                                              <span style={{ color: '#EA4335', position: 'absolute', top: '265px' }}>{this.state.failMessageCin}</span>
                                              : ''
                                          }
                                        </div>
                                      </div>
                                      <button
                                        type="submit"
                                        disabled={this.state.merchant_name != '' && this.state.address != '' && this.state.region_service != '' && this.state.website != '' && this.state.entity_name != '' && this.state.brand_name != '' && (this.state.cin != '' && this.state.isCin) && (this.state.gst != '' && this.state.isGst) && (this.state.pan_no != '' && this.state.isPanValid) ? true : false}
                                        style={this.state.merchant_name != '' && this.state.address != '' && this.state.region_service != '' && this.state.website != '' && this.state.entity_name != '' && this.state.brand_name != '' && (this.state.cin != '' && this.state.isCin) && (this.state.gst != '' && this.state.isGst) && (this.state.pan_no != '' && this.state.isPanValid) ? subBtn : {}}
                                        className={`flex-c-m stext-101 cl0 size-121 bor1 p-lr-15 trans-04 pointer mr-btn-sty`} onClick={this.handleUpdateProfile}>Save </button>
                                    </form>
                                  </div>
                                </>
                              }
                            </div> */}
                            <div className="col-lg-12" id="profileDiv">
                              <div className="profileDetails">
                                <div className="setting-item">
                                  {
                                    this.state.profilePhoto ? 
                                    <img src={`${this.state.profilePhoto}`}/>
                                    : <Avatar {...this.stringAvatar(this.state.entity_name)} />
                                  }
                                </div>
                                <div className="setting-item">
                                  <div className="contentDiv">
                                    <h5>{this.state.entity_name}</h5>
                                    <p>{this.state.website}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 profileDetailsOnbording" >
                              <h5>Onboarding Details</h5>
                              <div className="row detailscontent">
                                <div className="col-lg-4 onbordeddiv">
                                  <img src="../img/calender.svg" height="20px" width="20px" />
                                  <div className="ml-2">
                                    <h6>Onboarded On</h6>
                                    <p className="">{this.state.created_date ? this.state.created_date : "-"}</p>
                                  </div>
                                </div>
                                {/* <div className="col-lg-4 onbordeddiv">
                                  <img src="../img/contact.svg" height="20px" width="20px" />
                                  <div className="ml-2">
                                    <h6>PM Name</h6>
                                    <p className="">{this.state.merchant_name}</p>
                                  </div>
                                </div> */}
                                <div className="col-lg-4">
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12 profileDetailsCompany" >
                              <h5>Company Details</h5>
                              <div className="row profileDetailsCompanyDetails">
                                <div className="col-lg-4 companydiv">
                                  <img src="../img/gst.svg" height="20px" width="20px" />
                                  <div className="ml-2">
                                    <h6 >GST</h6>
                                    <p className="">{this.state.gst}</p>
                                  </div>
                                </div>
                                <div className="col-lg-4 companydiv">
                                  <img src="../img/pan.svg" height="20px" width="20px" />
                                  <div className="ml-2">
                                    <h6>PAN</h6>
                                    <p className="">{this.state.pan_no}</p>
                                  </div>
                                </div>
                                <div className="col-lg-4 companydiv">
                                  <img src="../img/cin.svg" height="20px" width="20px" />
                                  <div className="ml-2">
                                    <h6>CIN</h6>
                                    <p className="">{this.state.cin}</p>
                                  </div>
                                </div>
                                {/* <div className="col-lg-4 companydiv mt-3">
                                  <img src="../img/location.svg" height="20px" width="20px" />
                                  <div className="ml-2">
                                    <h6>Region of Service</h6>
                                    <p>{this.state.region_service ? this.state.region_service : ''}</p>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <div className="col-lg-12 profileDetailsCommunication" >
                              <h5>Communication Details</h5>
                              <div className="row ">
                                <div className="col-lg-12 addressDiv">
                                  <img src="../img/address.svg" height="20px" width="20px" />
                                  <div className="ml-2">
                                    <h6 >Communication Address</h6>
                                    <p style={{ width: "50%" }}>{this.state.communication_address__c ? this.state.communication_address__c : ''}</p>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-user-role-management"
                            role="tabpanel"
                            aria-labelledby="nav-user-role-management-tab"
                          >
                            <div className="accordion__" id="accordionSettings">
                              <div className="">
                                {/* <div className="card-header">
                                  <h2 className="mb-0 position-relative"> */}
                                {/* <button
                                      className="btn btn-link accordion_btn collapsed"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#collapseOne"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                    </button> */}
                                {/* <button className="d-sm-inline-block btn btn-sm btn-dark add_btn_pos" onClick={this.openAddUser}>
                                      <i className="fas fa-plus mr-2"></i>Add New User</button> */}
                                {/* </h2>
                                </div> */}
                                {/* <div
                                  id="collapseOne"
                                  className="collapse show"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordionSettings"
                                > */}
                                <div className="card-body">
                                  <div className="table-responsive">
                                    <table
                                      className="table  no-footer tableStyle"
                                      id=""
                                      cellSpacing={0}
                                    >
                                      <thead>
                                        <tr>
                                          <th width="3%"><div className="hash">#</div></th>
                                          <th width="17%">
                                            <div className="d-flex align-items-center gap10">User Name
                                              <div className="" style={{ display: 'grid' }}>
                                                <button className="up" onClick={() => this.sortBy(this.state.userData, 'name__c', 1)}></button>
                                                <button className="down" onClick={() => this.sortBy(this.state.userData, 'name__c', -1)}></button>
                                              </div>
                                            </div>
                                          </th>
                                          <th width="25%">User Details</th>

                                          <th width="15%">
                                            Last Login
                                          </th>
                                          <th width="20%">
                                            <div className="d-flex align-items-center gap10">Role(s)
                                              <div className="" style={{ display: 'grid' }}>
                                                <button className="up" onClick={() => this.sortBy(this.state.userData, 'select_user__c', 1)}></button>
                                                <button className="down" onClick={() => this.sortBy(this.state.userData, 'select_user__c', -1)}></button>
                                              </div>
                                              {/* <div className="">
                                              <button className="up"></button>
                                              <button className="down"></button>
                                            </div> */}
                                            </div></th>
                                          <th width="10%">Action</th>
                                          <th width="10%" className="text-right">Edit Info</th>
                                        </tr>
                                      </thead>
                                      {isSearching ?

                                        <tbody>
                                          {this.state.searchData && this.state.searchData.length > 0 ? (
                                            this.state.searchData.map((item, index) => (
                                              <tr className="cursor-point trhover" onClick={() => this.openEditUser(item.id, item)} key={index} >
                                                <td>
                                                  <div className="d-flex">
                                                    <div className="t_r_number IndexNumber">{(page * limit) + (index + 1)}</div>

                                                  </div>
                                                </td>
                                                <td className="titleTd">
                                                  <p className="link_">{item.name__c}</p>
                                                </td>
                                                <td className="titleTd">
                                                  <p className="email_ID mb-1">{item.email__c}</p>
                                                  <span>{item.mobile__c}</span>
                                                </td>

                                                <td className="titleTd">
                                                  <div className="mb-1">{item.last_login ? moment(item.last_login).format('DD MMM YYYY'):"-"}</div>
                                                  <div>{item.last_login && moment(item.last_login).format('LT')}</div>
                                                </td>
                                                <td className="titleTd">
                                                  <div className="">
                                                    {item.select_user__c ? item.select_user__c : "-"}

                                                  </div>


                                                </td>

                                                <td className="titleTd" onClick={(e) => e.stopPropagation()}>
                                                  {/* <div className="d-flex align-items-center">
                                                    <div className="switch_btn d-flex">
                                                      <label className="switch mr-3">
                                                        <input type="checkbox" />
                                                        <span className="slider round"></span>
                                                      </label>
                                                    </div>
                                                    Active
                                                  </div> */}
                                                  {item.activate__c ?
                                                    <div class="d-flex align-items-center">
                                                      <span class="green-circle "></span>
                                                      <span class="">Active</span>
                                                    </div> :
                                                    <div class="d-flex align-items-center">
                                                      <span class="red-circle  "></span>
                                                      <span class="">Inactive</span>
                                                    </div>
                                                  }
                                                </td>
                                                <td>
                                                  <div>
                                                    {/* <div className="switch_btn d-flex" style={{ color: `${item.activate__c == true ? '#094588' : ''}`  , width:"8rem"}}>

                                                  <label className="switch mr-3">
                                                    <input type="checkbox"
                                                      onChange={(e) => this.handleActive_status(e, item.id)}
                                                      name="active_status" value="1"
                                                      checked={item.activate__c}
                                                    />
                                                    <span className="slider round"></span>
                                                  </label> {item.activate__c == true ? "Active" : "Inactive"}

                                                </div> */}
                                                    {/* <button
                                                  className='edit_btn'
                                                  > */}
                                                    <i style={{ fontSize: "20px", float: "right" }} className='fa fa-angle-right float-right cursor-point size20'></i>
                                                    {/* </button> */}

                                                  </div>
                                                </td>
                                              </tr>
                                            ))
                                          ) : <td colSpan="10"><NoRecordsFound message={`${this.state.isSearching ? this.state.searchKey : 'No Data to show'}`} show_searched={this.state.isSearching} /></td>
                                          }
                                        </tbody> :
                                        this.state.userData.length > 0 ?
                                          <tbody>
                                            {this.state.userData && this.state.userData.length > 0 && (
                                              this.state.userData.map((item, index) => (
                                                <tr className="cursor-point trhover" onClick={() => this.openEditUser(item.id, item)} key={index} >
                                                  <td>
                                                    <div className="d-flex">

                                                      <div className="t_r_number IndexNumber">{(page * limit) + (index + 1)}</div>

                                                    </div>
                                                  </td>
                                                  <td className="titleTd">
                                                    <p className="link_">{item.name__c}</p>
                                                  </td>
                                                  <td className="titleTd">
                                                    <p className="email_ID mb-1">{item.email__c}</p>
                                                    <span>{item.mobile__c}</span>
                                                  </td>

                                                  <td className="titleTd">
                                                    <div className="mb-1">{item.last_login ? moment(item.last_login).format('DD MMM YYYY'):"-"}</div>
                                                    <div>{item.last_login && moment(item.last_login).format('LT')}
                                                    </div>
                                                  </td>
                                                  <td className="titleTd">
                                                    <div className="">
                                                      {item.select_user__c ? item.select_user__c : "-"}

                                                    </div>


                                                  </td>

                                                  <td className="titleTd" onClick={(e) => e.stopPropagation()}>
                                                    {/* <div className="d-flex align-items-center">
                                                {item.activate__c ? <div>Active</div>:<div>Inactive</div>}
                                              </div> */}
                                                    {/* {item.activate__c ?
                                              <div class="d-flex align-items-center">
                                                <span class="green-circle "></span>
                                                <span class="">Active</span>
                                              </div>: 
                                              <div class="d-flex align-items-center">
                                                <span class="red-circle  "></span>
                                                <span class="">Inactive</span>
                                              </div>} */}
                                                    {/* <div className="d-flex align-items-center">
                                                      <div className="switch_btn d-flex">
                                                        <label className="switch mr-3">
                                                          <input type="checkbox" disabled={true} checked={item.activate__c} />
                                                          <span className="slider round"></span>
                                                        </label>
                                                      </div>
                                                      {
                                                        item.activate__c ? "Active" : "Inactive"
                                                      }
                                                    </div> */}
                                                    {item.activate__c ?
                                                      <div class="d-flex align-items-center">
                                                        <span class="green-circle "></span>
                                                        <span class="">Active</span>
                                                      </div> :
                                                      <div class="d-flex align-items-center">
                                                        <span class="red-circle  "></span>
                                                        <span class="">Inactive</span>
                                                      </div>
                                                    }
                                                  </td>
                                                  <td>
                                                    <div>
                                                      {/* <div className="switch_btn d-flex" style={{ color: `${item.activate__c == true ? '#094588' : ''}`  , width:"8rem"}}>

                                                  <label className="switch mr-3">
                                                    <input type="checkbox"
                                                      onChange={(e) => this.handleActive_status(e, item.id)}
                                                      name="active_status" value="1"
                                                      checked={item.activate__c}
                                                    />
                                                    <span className="slider round"></span>
                                                  </label> {item.activate__c == true ? "Active" : "Inactive"}

                                                </div> */}
                                                      {/* <button
                                                  className='edit_btn'
                                                  > */}
                                                      <i style={{ fontSize: "20px", float: "right" }} className='fa fa-angle-right float-right cursor-point size20'></i>
                                                      {/* </button> */}

                                                    </div>
                                                  </td>
                                                </tr>
                                              ))
                                            )
                                            }
                                          </tbody> :
                                          <tbody>
                                            <td colSpan="10"><NoRecordsFound message={'No Data to show '} /></td></tbody>



                                      }



                                    </table>
                                  </div>
                                </div>
                                {/* </div> */}
                                {this.state.userData.length > 0 ?
                                  <div className="d-flex align-items-center justify-content-lg-end justify-content-center mb-4">
                                    {/* <div className="d-flex align-items-center row_per_page mr-lg-5 mr-4">
                                    <p>Row per page</p>
                                    <select page={this.state.page} onChange={this.handleChangelimitOfPage}>
                                      <option value={10}>10</option>
                                      <option value={20}>20</option>
                                      <option value={30}>30</option>
                                    </select>
                                  </div> */}
                                    <div className="d-flex align-items-center pages">
                                      {/* <Pagination count={totalPages} page={this.state.page} onChange={this.handleChangePage} /> */}
                                      <TablePagination
                                        component="div"
                                        count={this.state.pageCount}
                                        page={this.state.page}
                                        onPageChange={this.handleChangePageNew}
                                        rowsPerPage={this.state.limit}
                                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                        labelDisplayedRows={({ page }) => {

                                          return `Page: ${this.state.page + 1} of ${totalPages} `;

                                        }}

                                      />
                                    </div>

                                  </div> : ''}
                              </div>


                              {/* <div className="card">
                                <div className="card-header">
                                  <h2 className="mb-0 position-relative">
                                    <button
                                      className="btn btn-link accordion_btn"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#collapseTwo"
                                      aria-expanded="false"
                                      aria-controls="collapseTwo"
                                    >
                                      Role Management
                                    </button>
                                    <button className="d-sm-inline-block btn btn-sm btn-primary btn-dark add_btn_pos" onClick={this.openAddRole}>
                                      <i className="fas fa-plus"></i>Add New Role</button>
                                  </h2>
                                </div>
                                <div
                                  id="collapseTwo"
                                  className="collapse"
                                  aria-labelledby="headingTwo"
                                  data-parent="#accordionSettings"
                                >
                                  <div className="card-body">
                                    <div className="row justify-content-between pr-5 mr-3">
                                      {roles && roles.length > 0 && (
                                        roles.map((item, index) => (
                                          <div key={index} className="col-sm-5">

                                            <div className="d-flex justify-content-between role_management_box">
                                              <div>{item.role_name__c}</div>
                                              <div className="d-flex align-items-center">
                                                <div className="switch_btn d-flex">
                                                  <label className="switch mr-3">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                  </label> Inactive
                                                </div>
                                                <button className='edit_btn' onClick={() => this.openEditRole(item.id)}>
                                                  <img src="images/icons/edit_20.png" alt="" className='img-fluid' />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        ))
                                      )}

                                    </div>
                                  </div>
                                </div>
                              </div> */}

                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-bank-details"
                            role="tabpanel"
                            aria-labelledby="nav-bank-details-tab"
                          >
                            <BankDetails
                              user_id={this.props.user_id}
                              dispatch={this.props.dispatch}
                              add_account={this.props.add_account}
                              banks={this.props.banks}
                              sfid={this.props.sfid}
                              merchant_banks={merchant_banks}
                              showBanksData={this.state.showBankdata}
                            />
                          </div>


                          {/* <div
                                className="tab-pane fade"
                                id="nav-cat-details"
                                role="tabpanel"
                                aria-labelledby="nav-cat-tab"
                            >
                               <h5>Category</h5>
                               <select name='category' onChange={this.cateChange}>
                                  <option value="">Select Category</option>
                                  {category && category.length > 0 && category.map((items, index) => (
                                      <option value={items.category_id} key={`category-${index}`}>{items.category_name}</option>
                                  ))}
                        </select>
                        <h5>Sub Category</h5>
                               <select name='category' >
                                  <option value="">Select Category</option>
                                  {SubCatArr && SubCatArr.length > 0 && SubCatArr.map((items, index) => (
                                      <option value={items.category_id} key={items.category_id}>{items.category_name}</option>
                                  ))}
                        </select>
                            </div> */}


                          <div
                            className="tab-pane fade"
                            id="nav-manage-session"
                            role="tabpanel"
                            aria-labelledby="nav-manage-session-tab"
                          >manage-session</div>
                          <div
                            className="tab-pane fade"
                            id="nav-API-keys"
                            role="tabpanel"
                            aria-labelledby="nav-API-keys-tab"
                          >
                            <div className="row shadow mr-4" id="apiDiv"
                              style={{ padding: "2rem 2rem" }}
                            >

                              <h4 className="col-12 mb-4 pb-2 fs-20">API Keys</h4>
                              <div className="col-8">

                                <h5>
                                  API keys let you see Stride merchant dashboard from within other tools on your own software.
                                </h5>
                              </div>
                              <div className="col-4 text-center">
                                <a href="./assets/Eduvanz - Unified Merchant API.pdf" download>
                                  <button
                                    style={{ padding: "0.75rem 3rem" }}
                                    className="btn btn-default_ subBtn btn btn-primary">
                                    Download PDF
                                  </button>
                                </a>

                              </div>

                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </>
    )
  }
}

function mapStateToProps(state) {
  const { user, user_id, sfid, isLoading } = state.auth;
  const { users, roles, banks, category, sub_cat, merchant_banks, activeWizrTab } = state.user;
  const { add_account, isUserAddedSuccess, isEditAddedMsg } = state.model;
  return {
    user,
    user_id,
    users,
    sfid,
    roles,
    banks,
    isLoading,
    add_account,
    category,
    sub_cat,
    merchant_banks,
    activeWizrTab,
    isUserAddedSuccess,
    isEditAddedMsg
  };
}

export default connect(mapStateToProps)(Settings);

import React from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import Box from "@mui/material/Box";
import { Component } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Card from "@mui/material/Card";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomizeCheckbox from "../common/CustomizeCheckbox";
import FilterCustomDivider from "../common/FilterCustomDivider";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Scrollbar } from "react-scrollbars-custom";
import { getFilterMaster, getLeads, getMerchantProducts, getProductFilterData, getfilterLeadData, getSkillsLeadsData, getQualificationFilterData, getWorkExperienceData, setShowSearched, setSearchedField } from "../actions/user";
import { connect } from "react-redux";
import Alert from '@mui/material/Alert';
import { logOut } from "../actions/auth"
import { history } from '../helpers/history';






// let courseType = [
//     {
//         checked: false,
//         label: "Acer",
//     },
//     {
//         checked: false,
//         label: "Apple",
//     },
//     {
//         checked: false,
//         label: "Boat",
//     },
// ];

// let product = [
//     {
//         checked: false,
//         label: "Acer",
//     },
//     {
//         checked: false,
//         label: "Apple",
//     },
//     {
//         checked: false,
//         label: "Boat",
//     },
// ];

// let brand = [
//     {
//         checked: false,
//         label: "Acer",
//     },
//     {
//         checked: false,
//         label: "Apple",
//     },
//     {
//         checked: false,
//         label: "Boat",
//     },
// ];

// let category = [
//     {
//         checked: false,
//         label: "Acer",
//     },
//     {
//         checked: false,
//         label: "Apple",
//     },
//     {
//         checked: false,
//         label: "Boat",
//     },
// ];



// const initialCourseType = courseType.slice();
const listingTime = [
    {
        checked: false,
        name: "Today",
    },
    {
        checked: false,
        name: "This Week",
    },
    {
        checked: false,
        name: "This Month",
    },
    {
        checked: false,
        name: "This Quarter",
    },
];


const UserProfile = [
    {
        checked: false,
        name: "Professional",
    },
    {
        checked: false,
        name: "Student",
    },
];

const Gender = [
    {
        checked: false,
        name: "Male",
    },
    {
        checked: false,
        name: "Female",
    }
];

const Qualification = [
    {
        checked: false,
        name: "B.E./ B.Tech",
    },
    {
        checked: false,
        name: "B.Sc",
    },
    {
        checked: false,
        name: "B.S.L. LL.B.",
    },
    {
        checked: false,
        name: "B.E./ B.Tech",
    },
    {
        checked: false,
        name: "L.L.B.-Bachelor of Law or Laws",
    },
    {
        checked: false,
        name: "B. Com. LL.B.",
    },
    {
        checked: false,
        name: "B.V.Sc &amp; AH- Bachelor of Veterinary Sciences and Animal Husbandry",
    },
];

const Work_Exp = [
    {
        checked: false,
        name: "0 - 5 Yrs",
    },
    {
        checked: false,
        name: "5 - 10 Yrs",
    },
    {
        checked: false,
        name: "10 - 15 Yrs",
    },
    {
        checked: false,
        name: "15 - 20 Yrs",
    },
];


const Course_Fees = [
    {
        checked: false,
        name: "Less than ₹ 5,000",
    },
    {
        checked: false,
        name: "₹ 5,001 - ₹ 10,000 ",
    },
    {
        checked: false,
        name: "₹ 10,001 - ₹ 30,000 ",
    },
    {
        checked: false,
        name: "₹ 30,001 - ₹ 60,000 ",
    },
    {
        checked: false,
        name: "₹ 60,001 - ₹ 1,00,000 ",
    },
    {
        checked: false,
        name: "More than ₹ 1,00,000",
    },
];

const Payment_Status = [    
    {
        checked: false,
        name: "Application sent for approval",        
        id: 3,
    },
    {
        checked: false,
        name: "Offer letter received & awaiting WIZR application block fee",
        id: 4,
    },
    {
        checked: false,
        name: "Awaiting first payment",
        id: 5
    },
    {
        checked: false,
        name: "Awaiting final payment",
        id: 6
    },
    {
        checked: false,
        name: "Fee Payment Requested",
        id: 7
    },
    {
        checked: false,
        name: "Awaiting course commencment",
        id: 8
    },
    {
        checked: false,
        name: "Application on-hold",
        id: 9
    },
    {
        checked: false,
        name: "Application rejected",
        id: 10
    },    
];


const Payment_Request = [
    {
        checked: false,
        name: "Request Pending",
    },
    {
        checked: false,
        name: "Requested",
    }
];


// const Course_Fees = [
//     {
//         checked: false,
//         name: "20,000 - 40,000",
//     },
//     {
//         checked: false,
//         name: "40,000 - 60,000 ",
//     },
//     {
//         checked: false,
//         name: "60,000 - 80,000",
//     },
//     {
//         checked: false,
//         name: "80,000 - 1,00,000 ",
//     },
// ];



class LeadSkillFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // brands: brand,
            brands: UserProfile,
            // products: [...product],
            products: Gender,
            // categories: category,
            // categories:Qualification,
            categories: [],
            listingTimes: Work_Exp,
            course_Fees: Course_Fees,
            payment_Status: Payment_Status,
            payment_Request: Payment_Request,
            searchProduct: "",
            FilterError: ""
        };
    }

    //   shouldComponentUpdate(nextProps, nextState){
    //      if(nextProps.clearfilterValue !== this.props.clearfilterValue){
    //     //    this.setState({ courseTypes:courseType,
    //     //     courseDeliveryModes:courseDeliveryMode,
    //     //     mediumOfInstructions:mediumOfInstruction,
    //     //     listingTimes:listingTime})
    //      }else{
    //         true;
    //      }

    componentDidMount() {

        const { sfid, stage } = this.props;
        //   const stage = `stage=Pre Approval`;

        //   const data = {
        //     merchant_id:"1"
        //   }
        //   console.log(stage,"ewehjdh");
        //   if(stage === "Pending" || stage === "Onhold" || stage === "Rejected"){
        //     this.props.dispatch(getQualificationFilterData(data)).then(response => {

        //        console.log("dendenbfnbr",response);
        //        if(response.status === "success"){
        //         this.setState({categories: response.data.map(ele =>{return {checked: false,name:ele.qualification}})})
        //        }else{
        //            this.setState({categories:[]})
        //        }
        //     })
        // }
        //     this.props.dispatch(getLeads(stage, sfid)).then(response => {
        //      
        //       if (response.status == "success") {
        //         let actionData = [];
        //           response.proData.map(data => {
        //               if (data.status) {
        //                   actionData.push(data.status)
        //               }
        //           })
        //           let d = [...new Set(actionData)];
        //           console.log("ererer",d);
        //           this.setState({
        //               brands: d.map(ele => {return {name:ele,checked:false}})
        //           })
        //       }
        //   })

        //     this.props.dispatch(getLeads("", sfid)).then(response => {
        //       if (response.status == "success") {
        //         let statusData = [];
        //           response.proData.map(data => {
        //               if (data.status) {
        //                 statusData.push(data.status)
        //               }
        //           })
        //           let d = [...new Set(statusData)];
        //           console.log("ererer",d);
        //           this.setState({
        //               categories: d.map(ele => {return {name:ele,checked:false}})
        //           })
        //       }
        //   })


        // this.props.dispatch(getLeads(this.props.user_id, 'brand')).then((response) => {
        //       console.log(response, 'brand response Data')
        //       this.setState({ brands: response.data.map(ele => {return {...ele,checked:false}}) })
        //   })

        // this.props.dispatch(getFilterMaster(this.props.user_id, 'product')).then((response) => {
        //     console.log(response, 'product response')
        //     this.setState({ products: response.data.map(ele => {return {...ele,checked:false}}) })
        // })

        // this.props.dispatch(getFilterMaster(this.props.user_id, 'category')).then((response) => {
        //     console.log(response, 'category response')
        //     this.setState({ categories: response.data.map(ele => {return {...ele,checked:false}}) })
        // })

    }


    componentDidUpdate(prevProps) {
        if (prevProps.clearfilterValue !== this.props.clearfilterValue) {
            this.setState({
                brands: this.state.brands.map(ele => { return { ...ele, checked: false } }),
                products: this.state.products.map(ele => { return { ...ele, checked: false } }),
                categories: this.state.categories.map(ele => { return { ...ele, checked: false } }),
                listingTimes: this.state.listingTimes.map(ele => { return { ...ele, checked: false } }),
                course_Fees: this.state.course_Fees.map(ele => { return { ...ele, checked: false } }),
                payment_Status: this.state.payment_Status.map(ele => { return { ...ele, checked: false } }),
                payment_Request: this.state.payment_Request.map(ele => { return { ...ele, checked: false } })
            })
        }

        if (prevProps.show !== this.props.show) {
            if (this.props.show) {
                const { stage, sfid } = this.props;
                const data = {
                    merchant_id: sfid,
                    status: stage,
                }
                if (stage === "Pending" || stage === "Onhold" || stage === "Rejected") {
                    if (this.state.categories.length == 0) {
                        this.props.dispatch(getQualificationFilterData(data)).then(response => {
                            if (response.status === "success") {
                                if (response.data.length !== 0) {
                                    this.setState({ categories: response.data.map(ele => { return { checked: false, name: ele.qualification } }) })
                                }
                                else {
                                    this.setState({ categories: [] })
                                }
                            } else {
                                this.setState({ categories: [] })
                                if (response.status == 401) {
                                    this.props.dispatch(logOut());
                                    history.push('/login');
                                }

                            }
                        })
                    }

                    this.props.dispatch(getWorkExperienceData(data)).then(response => {
                        if (response.status === "success") {
                            let booleanValue = this.state.listingTimes.filter((item) => item.checked === true).length > 0
                            if (!booleanValue) {
                                if (response.data.length !== 0) {
                                    this.setState({ listingTimes: response.data.map(ele => { return { checked: false, name: ele.experience } }) })
                                }
                                else {
                                    this.setState({ listingTimes: [] })
                                }
                            }
                        } else {
                            this.setState({ listingTimes: [] })
                            if (response.status == 401) {
                                this.props.dispatch(logOut());
                                history.push('/login');
                            }

                        }
                    })

                }
            }
        }

    }

    handleFilterSearch = (e) => {

        this.setState({
            searchProduct: e.target.value
        })
        // let data = this.state.products.filter((item) => {
        //     return (this.state.searchProduct.toLowerCase() === "" ? item : item.label.toLowerCase().includes(this.state.searchProduct))
        // })
        // this.setState({ products: data })
    }

    handleCheck = (checkedValue, index, Type) => {
        let { brands, products, categories, listingTimes, payment_Request, course_Fees, payment_Status } = this.state;
        if (Type === "brands") {
            brands[index].checked = checkedValue;
            this.setState({ brands: brands })
        }
        if (Type === "products") {
            //    let i = products.findIndex(ele => ele.id === index)
            products[index].checked = checkedValue;
            this.setState({ products: products })
        }
        if (Type === "categories") {
            categories[index].checked = checkedValue;
            this.setState({ categories: categories })
        }
        if (Type === "listingTimes") {
            listingTimes[index].checked = checkedValue;
            this.setState({ listingTimes: listingTimes })
        }

        if (Type === "payment_Request") {
            payment_Request[index].checked = checkedValue;
            this.setState({ payment_Request: payment_Request })
        }
        if (Type === "course_Fees") {
            course_Fees[index].checked = checkedValue;
            this.setState({ course_Fees: course_Fees })
        }
        if (Type === "payment_Status") {
            payment_Status[index].checked = checkedValue;
            this.setState({ payment_Status: payment_Status })
        }
    };

    applyFilter = () => {
        const { user_id, stage, sfid } = this.props
        // const { brandOptSel, proOptSel, catOptSel, offOptSel, timeOptSel } = this.state
        let { brands, products, categories, listingTimes, course_Fees, payment_Status, payment_Request } = this.state;
        const brandsfilter = brands.filter(ele => ele.checked).map(element => element.name);
        const categoriesFilter = categories.filter(ele => ele.checked).map(element => element.name);
        const productsFilter = products.filter(ele => ele.checked).map(element => element.name);
        const listingTimesFilter = listingTimes.filter(ele => ele.checked).map(element => element.name);


        const payment_RequestFilter = payment_Request.filter(ele => ele.checked).map(element => element.name);
        let course_FeesFilter = course_Fees.filter(ele => ele.checked).map(element => element.name.replace(/,/g, ""));
        course_FeesFilter = course_FeesFilter.map(element => element.replace(/₹ /g, "").trim());
        course_FeesFilter = course_FeesFilter.map(ele => {
            if(ele === "Less than 5000"){
                return "0 - 5000"
              }else if( ele === "More than 100000"){
                  return "100000 - 10000000"
              }else{
                return ele
              }
        })
        const payment_StatusFilter = payment_Status.filter(ele => ele.checked).map(element => element.id);
        // if (stage === "Pending" || stage === "Onhold" || stage === "Rejected") {
          let dataObj = {
                "merchant_id": sfid,
                // "status": stage,
                "page": 1,
                "limit": 10,
                filter: 
                    {
                        "duration": "",
                        "status": stage,
                        "search": "",
                        "user_profile": brandsfilter.length > 0 ? brandsfilter : "",
                        "gender": productsFilter.length > 0 ? productsFilter : "",
                        "qualification": categoriesFilter.length > 0 ? categoriesFilter : "",
                        "workExp": listingTimesFilter.length > 0 ? listingTimesFilter : "",
                        "payment_request": payment_RequestFilter.length > 0 ? payment_RequestFilter : "",
                        "course_fee": course_FeesFilter.length > 0 ? course_FeesFilter : "",
                        "payment_status": payment_StatusFilter.length > 0 ? payment_StatusFilter : "",
                    }
            }
        // }
        // if (stage === "Approved") {
        //     dataObj = {
        //         "merchant_id": sfid,
        //         "status": stage,
        //         "page": 1,
        //         "limit": 10,
        //         filter: [
        //             {
        //                 "payment_request": payment_RequestFilter.length > 0 ? payment_RequestFilter : "",
        //                 "course_fee": course_FeesFilter.length > 0 ? course_FeesFilter : "",
        //                 "payment_status": payment_StatusFilter.length > 0 ? payment_StatusFilter : "",
        //             }
        //         ]
        //     }
        // }
      
        this.props.dispatch(setShowSearched(false))
        this.props.dispatch(setSearchedField(""))
        this.props.handleFilterValue(dataObj.filter)
        this.props.dispatch(getSkillsLeadsData(dataObj)).then(response => {
            if (response.status === "success") {
                this.handleClose();
            } else {
                this.setState({ FilterError: response.message })
                setTimeout(() => {
                    this.setState({ FilterError: "" })
                }, 1000);
            }
        }).catch(error => {
            this.setState({ FilterError: error?.response?.data?.message ? error.response.data.message : "something went wrong" })
            setTimeout(() => {
                this.setState({ FilterError: "" })
            }, 1000);
        })
    }

    // this.props.dispatch(getProductFilterData(dataObj)).then(response => {
    //     console.log(response, "product filter response");
    // })
    // .catch(error => {
    //     console.log('product filtere error', error)
    // })
    // }


    handleClose = () => {
        this.props.close();
    }

    clearFilter = () => {
        const { user_id, stage, sfid } = this.props
        let { brands, products, categories, listingTimes, course_Fees, payment_Status, payment_Request } = this.state;

        const brandsfilter = brands.filter(ele => ele.checked).map(element => element.name);
        const categoriesFilter = categories.filter(ele => ele.checked).map(element => element.name);
        const productsFilter = products.filter(ele => ele.checked).map(element => element.name);
        const listingTimesFilter = listingTimes.filter(ele => ele.checked).map(element => element.name);

        const payment_RequestFilter = payment_Request.filter(ele => ele.checked).map(element => element.name);
        const course_FeesFilter = course_Fees.filter(ele => ele.checked).map(element => element.name.replace(/,/g, ""));
        const payment_StatusFilter = payment_Status.filter(ele => ele.checked).map(element => element.name);

        if (payment_StatusFilter.length > 0 || course_FeesFilter.length > 0 || payment_RequestFilter.length > 0 || listingTimesFilter.length > 0 || categoriesFilter.length > 0 || productsFilter.length > 0 || brandsfilter.length > 0) {
            let dataObj = {
                "merchant_id": sfid,
                // "status": stage,
                "page": 1,
                "limit": 10,
                filter: 
                    {
                        "duration": "",
                        "status": stage,
                        "search": "",
                        "user_profile":"",
                        "gender":"",
                        "qualification": "",
                        "workExp": "",
                        "payment_request":"",
                        "course_fee":"",
                        "payment_status": "",
                    }
            }
            
            this.props.dispatch(setShowSearched(false))
            this.props.dispatch(setSearchedField(""))
            this.props.handleFilterValue(dataObj.filter)
            this.props.dispatch(getSkillsLeadsData(dataObj)).then(response => {
                if (response.status === "success") {
                    // this.handleClose();
                } else {
                    this.setState({ FilterError: response.message })
                    setTimeout(() => {
                        this.setState({ FilterError: "" })
                    }, 1000);
                }
            }).catch(error => {
                this.setState({ FilterError: error?.response?.data?.message ? error.response.data.message : "something went wrong" })
                setTimeout(() => {
                    this.setState({ FilterError: "" })
                }, 1000);
            })
        }

        this.setState({
            brands: this.state.brands.map(ele => { return { ...ele, checked: false } }),
            products: this.state.products.map(ele => { return { ...ele, checked: false } }),
            categories: this.state.categories.map(ele => { return { ...ele, checked: false } }),
            listingTimes: this.state.listingTimes.map(ele => { return { ...ele, checked: false } }),
            course_Fees: this.state.course_Fees.map(ele => { return { ...ele, checked: false } }),
            payment_Status: this.state.payment_Status.map(ele => { return { ...ele, checked: false } }),
            payment_Request: this.state.payment_Request.map(ele => { return { ...ele, checked: false } }),
        })
    }

    render() {
        let { brands, courseTypes, products, categories, listingTimes, course_Fees, payment_Request, payment_Status } = this.state;
        let { show, stage } = this.props;
        return (
            <Modal show={show} className='right myModal filter_modal filterbarMainModal' id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <button type="button" onClick={this.handleClose} className=" filterModalCloseButton" > <img style={{ marginTop: "15px" }} src="./images/icons/icon-close2.png" /> </button>
                <Box className='filterDiv'>
                    <div className="filterboxdiv" >

                        <h1 className='filter-main-title'   >
                            Filters
                        </h1>
                        <div className="filterboxdivoptions">
                            <button className='mr-2 filtermainbtn filterclearbtn' onClick={this.clearFilter} >Clear All</button>
                            <button className='filtermainbtn filterapplybtn' onClick={this.applyFilter}>Apply</button>
                        </div>
                    </div>
                    <FilterCustomDivider />
                    <div style={{ position: 'relative' }}>
                        {this.state.FilterError && <div className="filter-err-container">
                            <Alert severity="error">{this.state.FilterError}</Alert>
                        </div>}
                        <Card
                            variant="outlined"
                            sx={{ border: "none", boxShadow: "none", overflowY: "auto" }}
                            className="mobile-card-padding"
                        >
                            {/* <Scrollbar> */}

                            {/* single accordian */}
                            {/* <button className='filtermainbtn filterapplybtn' onClick={this.applyFilter}>Apply</button> */}
                            {(stage === "Pending" || stage === "Onhold" || stage === "Rejected") &&
                                <>
                                    <Accordion
                                        elevation={0}
                                        disableGutters={true}
                                        className="accordionsec"
                                        sx={{ paddingBottom: "0.573vw" }}
                                    >
                                        <AccordionSummary
                                            className="accordian-summary"
                                            expandIcon={<ExpandMoreIcon className="accordian-arrow" />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className="text-title-filter">User Profile </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordian-detail max-height-acc paddingAccordianFilter">
                                            <>
                                                <Stack direction="row" gap="10px" flexWrap="wrap">
                                                    {brands?.length &&
                                                        brands.map((ele, i) => {
                                                            if (ele.checked) {
                                                                return (
                                                                    <button className="btn filterOptchk">
                                                                        {ele.name}{" "}
                                                                        <span>
                                                                            <img className='filterclosebtn'
                                                                                src="./images/icons/icon-close3.svg"
                                                                                onClick={() =>
                                                                                    this.handleCheck(false, i, "brands")
                                                                                }
                                                                            />{" "}
                                                                        </span>{" "}
                                                                    </button>
                                                                );
                                                            }
                                                        })}
                                                </Stack>
                                            </>
                                            {brands?.length &&
                                                brands.map((ele, i) => {
                                                    return (
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="flex-start"
                                                            spacing={2}
                                                            className="accordion-option"
                                                        >
                                                            <FormControlLabel
                                                                label={
                                                                    <Typography className="text-options-filter">
                                                                        {ele.name}
                                                                    </Typography>
                                                                }
                                                                control={
                                                                    <Checkbox
                                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: '1.318vw' } }}
                                                                        color="default"
                                                                        checked={ele.checked}
                                                                        onChange={(e) =>
                                                                            this.handleCheck(e.target.checked, i, "brands")
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </Stack>
                                                    );
                                                })}
                                        </AccordionDetails>
                                    </Accordion>

                                    {/* second accordian */}

                                    <Accordion
                                        elevation={0}
                                        disableGutters={true}
                                        className="accordionsec"
                                        sx={{ paddingBottom: "0.573vw" }}
                                    >
                                        <AccordionSummary
                                            className="accordian-summary"
                                            expandIcon={<ExpandMoreIcon className="accordian-arrow" />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className="text-title-filter">Gender</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordian-detail max-height-acc paddingAccordianFilter" id="accordian">
                                            <>
                                                <Stack direction="row" gap="10px" flexWrap="wrap">
                                                    {products?.length &&
                                                        products.map((ele, i) => {
                                                            if (ele.checked) {
                                                                return (
                                                                    <button className="btn filterOptchk">
                                                                        {ele.name}{" "}
                                                                        <span>
                                                                            <img className='filterclosebtn'
                                                                                src="./images/icons/icon-close3.svg"
                                                                                onClick={() =>
                                                                                    this.handleCheck(false, i, "products")
                                                                                }
                                                                            />{" "}
                                                                        </span>{" "}
                                                                    </button>
                                                                );
                                                            }

                                                        })}
                                                </Stack>
                                                {/* <Form>
                                    <InputGroup className='my-3'>
                                        <input placeholder='Enter Product name' className='product-search-bar' onChange={(e) => this.handleFilterSearch(e)} />
                                        <img src='./img/icon_Search.svg' className='search-product-filter' />
                                    </InputGroup>
                                </Form> */}
                                            </>
                                            {this.state.products?.length &&
                                                this.state.products.filter((ele) => this.state.searchProduct === '' ? ele : ele.name && ele.name.toLowerCase().includes(this.state.searchProduct.toLowerCase())
                                                ).map((ele, i) => {
                                                    return (
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="flex-start"
                                                            spacing={2}
                                                            className="accordion-option"
                                                        >
                                                            <FormControlLabel
                                                                label={
                                                                    <Typography className="text-options-filter">
                                                                        {ele.name}
                                                                    </Typography>
                                                                }
                                                                control={
                                                                    <Checkbox
                                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: '1.318vw' } }}
                                                                        color="default"
                                                                        checked={ele.checked}
                                                                        onChange={(e) =>
                                                                            this.handleCheck(e.target.checked, i, "products")
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </Stack>
                                                    );
                                                })}
                                        </AccordionDetails>
                                    </Accordion>


                                    <Accordion
                                        elevation={0}
                                        disableGutters={true}
                                        className="accordionsec"
                                        sx={{ paddingBottom: "0.573vw" }}
                                    >
                                        <AccordionSummary
                                            className="accordian-summary"
                                            expandIcon={<ExpandMoreIcon className="accordian-arrow" />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className="text-title-filter">Qualification</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordian-detail max-height-acc paddingAccordianFilter">
                                            <>
                                                <Stack direction="row" gap="10px" flexWrap="wrap">
                                                    {categories.length > 0 ?
                                                        categories.map((ele, i) => {
                                                            if (ele.checked) {
                                                                return (
                                                                    <button className="btn filterOptchk">
                                                                        {ele.name}{" "}
                                                                        <span>
                                                                            <img className='filterclosebtn'
                                                                                src="./images/icons/icon-close3.svg"
                                                                                onClick={() =>
                                                                                    this.handleCheck(false, i, "categories")
                                                                                }
                                                                            />{" "}
                                                                        </span>{" "}
                                                                    </button>
                                                                );
                                                            }
                                                        }) : ""}
                                                </Stack>
                                            </>
                                            {categories?.length ?
                                                categories.map((ele, i) => {
                                                    if (!ele.name) {
                                                        return;
                                                    }
                                                    return (
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="flex-start"
                                                            spacing={2}
                                                            className="accordion-option"
                                                        >
                                                            <FormControlLabel
                                                                label={
                                                                    <Typography className="text-options-filter">
                                                                        {ele.name}
                                                                    </Typography>
                                                                }
                                                                control={
                                                                    <Checkbox
                                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: '1.318vw' } }}
                                                                        color="default"
                                                                        checked={ele.checked}
                                                                        onChange={(e) =>
                                                                            this.handleCheck(e.target.checked, i, "categories")
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </Stack>
                                                    );
                                                }) : ""}
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion
                                        elevation={0}
                                        disableGutters={true}
                                        className="accordionsec"
                                        sx={{ paddingBottom: "0.573vw" }}
                                    >
                                        <AccordionSummary
                                            className="accordian-summary"
                                            expandIcon={<ExpandMoreIcon className="accordian-arrow" />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className="text-title-filter">Work Exp.</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordian-detail max-height-acc paddingAccordianFilter">
                                            <>
                                                <Stack direction="row" gap="10px" flexWrap="wrap">
                                                    {listingTimes?.length ?
                                                        listingTimes.map((ele, i) => {
                                                            if (ele.checked) {
                                                                return (
                                                                    <button className="btn filterOptchk">
                                                                        {ele.name}{" "}
                                                                        <span>
                                                                            <img className='filterclosebtn'
                                                                                src="./images/icons/icon-close3.svg"
                                                                                onClick={() =>
                                                                                    this.handleCheck(false, i, "listingTimes")
                                                                                }
                                                                            />{" "}
                                                                        </span>{" "}
                                                                    </button>
                                                                );
                                                            }
                                                        }) : ""}
                                                </Stack>
                                            </>
                                            {listingTimes?.length ?
                                                listingTimes.map((ele, i) => {
                                                    if (!ele.name) {
                                                        return;
                                                    }
                                                    return (
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="flex-start"
                                                            spacing={2}
                                                            className="accordion-option"
                                                        >
                                                            <FormControlLabel
                                                                label={
                                                                    <Typography className="text-options-filter">
                                                                        {ele.name}
                                                                    </Typography>
                                                                }
                                                                control={
                                                                    <Checkbox
                                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: '1.318vw' } }}
                                                                        color="default"
                                                                        checked={ele.checked}
                                                                        onChange={(e) =>
                                                                            this.handleCheck(e.target.checked, i, "listingTimes")
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </Stack>
                                                    );
                                                }) : ""}
                                        </AccordionDetails>
                                    </Accordion></>}

                            {/* </Scrollbar> */}


                            {stage === "Approved" &&
                                <>
                                    <Accordion
                                        elevation={0}
                                        disableGutters={true}
                                        className="accordionsec"
                                        sx={{ paddingBottom: "0.573vw" }}
                                    >
                                        <AccordionSummary
                                            className="accordian-summary"
                                            expandIcon={<ExpandMoreIcon className="accordian-arrow" />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className="text-title-filter">Course Fee</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordian-detail max-height-acc paddingAccordianFilter">
                                            <>
                                                <Stack direction="row" gap="10px" flexWrap="wrap">
                                                    {course_Fees?.length &&
                                                        course_Fees.map((ele, i) => {
                                                            if (ele.checked) {
                                                                return (
                                                                    <button className="btn filterOptchk">
                                                                        {ele.name}{" "}
                                                                        <span>
                                                                            <img className='filterclosebtn'
                                                                                src="./images/icons/icon-close3.svg"
                                                                                onClick={() =>
                                                                                    this.handleCheck(false, i, "course_Fees")
                                                                                }
                                                                            />{" "}
                                                                        </span>{" "}
                                                                    </button>
                                                                );
                                                            }
                                                        })}
                                                </Stack>
                                            </>
                                            {course_Fees?.length &&
                                                course_Fees.map((ele, i) => {
                                                    return (
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="flex-start"
                                                            spacing={2}
                                                            className="accordion-option"
                                                        >
                                                            <FormControlLabel
                                                                label={
                                                                    <Typography className="text-options-filter">
                                                                        {ele.name}
                                                                    </Typography>
                                                                }
                                                                control={
                                                                    <Checkbox
                                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: '1.318vw' } }}
                                                                        color="default"
                                                                        checked={ele.checked}
                                                                        onChange={(e) =>
                                                                            this.handleCheck(e.target.checked, i, "course_Fees")
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </Stack>
                                                    );
                                                })}
                                        </AccordionDetails>
                                    </Accordion>



                                    <Accordion
                                        elevation={0}
                                        disableGutters={true}
                                        className="accordionsec"
                                        sx={{ paddingBottom: "0.573vw" }}
                                    >
                                        <AccordionSummary
                                            className="accordian-summary"
                                            expandIcon={<ExpandMoreIcon className="accordian-arrow" />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className="text-title-filter">Payment Status</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordian-detail max-height-acc paddingAccordianFilter">
                                            <>
                                                <Stack direction="row" gap="10px" flexWrap="wrap">
                                                    {payment_Status?.length &&
                                                        payment_Status.map((ele, i) => {
                                                            if (ele.checked) {
                                                                return (
                                                                    <button className="btn filterOptchk">
                                                                        {ele.name}{" "}
                                                                        <span>
                                                                            <img className='filterclosebtn'
                                                                                src="./images/icons/icon-close3.svg"
                                                                                onClick={() =>
                                                                                    this.handleCheck(false, i, "payment_Status")
                                                                                }
                                                                            />{" "}
                                                                        </span>{" "}
                                                                    </button>
                                                                );
                                                            }
                                                        })}
                                                </Stack>
                                            </>
                                            {payment_Status?.length &&
                                                payment_Status.map((ele, i) => {
                                                    return (
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="flex-start"
                                                            spacing={2}
                                                            className="accordion-option"
                                                        >
                                                            <FormControlLabel
                                                                label={
                                                                    <Typography className="text-options-filter">
                                                                        {ele.name}
                                                                    </Typography>
                                                                }
                                                                control={
                                                                    <Checkbox
                                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: '1.318vw' } }}
                                                                        color="default"
                                                                        checked={ele.checked}
                                                                        onChange={(e) =>
                                                                            this.handleCheck(e.target.checked, i, "payment_Status")
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </Stack>
                                                    );
                                                })}
                                        </AccordionDetails>
                                    </Accordion>


                                    {/* <Accordion
                                        elevation={0}
                                        disableGutters={true}
                                        sx={{ paddingBottom: "0.573vw" }}
                                    >
                                        <AccordionSummary
                                            className="accordian-summary"
                                            expandIcon={<ExpandMoreIcon className="accordian-arrow" />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className="text-title-filter">Payment Request</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordian-detail max-height-acc paddingAccordianFilter">
                                            <>
                                                <Stack direction="row" gap="10px" marginBottom="15px" flexWrap="wrap">
                                                    {payment_Request?.length &&
                                                        payment_Request.map((ele, i) => {
                                                            if (ele.checked) {
                                                                return (
                                                                    <button className="btn filterOptchk">
                                                                        {ele.name}{" "}
                                                                        <span>
                                                                            <img className='filterclosebtn'
                                                                                src="./images/icons/icon-close3.svg"
                                                                                onClick={() =>
                                                                                    this.handleCheck(false, i, "payment_Request")
                                                                                }
                                                                            />{" "}
                                                                        </span>{" "}
                                                                    </button>
                                                                );
                                                            }
                                                        })}
                                                </Stack>
                                            </>
                                            {payment_Request?.length &&
                                                payment_Request.map((ele, i) => {
                                                    return (
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="flex-start"
                                                            spacing={2}
                                                            className="accordion-option"
                                                        >
                                                            <FormControlLabel
                                                                label={
                                                                    <Typography className="text-options-filter">
                                                                        {ele.name}
                                                                    </Typography>
                                                                }
                                                                control={
                                                                    <Checkbox
                                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: '1.318vw' } }}
                                                                        color="default"
                                                                        checked={ele.checked}
                                                                        onChange={(e) =>
                                                                            this.handleCheck(e.target.checked, i, "payment_Request")
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </Stack>
                                                    );
                                                })}
                                        </AccordionDetails>
                                    </Accordion> */}
                                     </>
                            }


                        </Card>
                        <FilterCustomDivider />
                    </div>
                </Box>
            </Modal>
        );
    }
}





function mapStateToProps(state) {
    const { user, user_id, sfid } = state.auth;
    const { leadTab,searchedKeyword } = state.user;
    return {
        //   user,
        //   products,
        //   offer_details,
        user_id,
        sfid,
        leadTab,
        searchedKeyword,
    };
}

// export default ProductCredFilter;
export default connect(mapStateToProps)(LeadSkillFilter);

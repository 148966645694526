import React from 'react'
// import {
//     getSettlement,
//   } from '../actions/user';
import { closeLoanCanelModal } from "../actions/model"
import { selectedCancelationId, getSettlement, merchantSettlementDetail, setSettlementclickItemId } from "../actions/user"
import Pagination from '@material-ui/lab/Pagination';
import TablePagination from '@mui/material/TablePagination';
import NoRecordsFound from "../common/NoRecordsFound.component";
// const a=[{opp_id: "006C3000004zQL6IAM",transaction_application_id__c: "TA2306276",loan_id__c: "2959",limit_application_ids__c: "LA23061911",created_at: "27/06/2023",status: "Loan Disbursed",amount: 10,utr: "976234",utr_date: "",name: "Rushabh Damkondwar",mobile: "9333317777",id: 880,sfid: "001C3000007eml9IAA",customer_id__c: "C23061911",product_name: "Tenure Restiction Test",product_price: null,product_mrp: null},
// {opp_id: "006C3000004zQL6IAM",transaction_application_id__c: "TA2306276",loan_id__c: "2959",limit_application_ids__c: "LA23061911",created_at: "27/06/2023",status: "Loan Disbursed",amount: 100000,utr: "976234",utr_date: "",name: "Rushabh Damkondwar",mobile: "9333317777",id: 880,sfid: "001C3000007eml9IAA",customer_id__c: "C23061911",product_name: "Tenure Restiction Test",product_price: null,product_mrp: null},
// {opp_id: "006C3000004zQL6IAM",transaction_application_id__c: "TA2306276",loan_id__c: "2959",limit_application_ids__c: "LA23061911",created_at: "27/06/2023",status: "Loan Disbursed",amount: 900000,utr: "976234",utr_date: "",name: "Rushabh Damkondwar",mobile: "9333317777",id: 880,sfid: "001C3000007eml9IAA",customer_id__c: "C23061911",product_name: "Tenure Restiction Test",product_price: null,product_mrp: null},
// {opp_id: "006C3000004zQL6IAM",transaction_application_id__c: "TA2306276",loan_id__c: "2959",limit_application_ids__c: "LA23061911",created_at: "27/06/2023",status: "Loan Disbursed",amount: 100000,utr: "976234",utr_date: "",name: "Rushabh Damkondwar",mobile: "9333317777",id: 880,sfid: "001C3000007eml9IAA",customer_id__c: "C23061911",product_name: "Tenure Restiction Test",product_price: null,product_mrp: null},
// {opp_id: "006C3000004zQL6IAM",transaction_application_id__c: "TA2306276",loan_id__c: "2959",limit_application_ids__c: "LA23061911",created_at: "27/06/2023",status: "Loan Disbursed",amount: 100000,utr: "976234",utr_date: "",name: "Rushabh Damkondwar",mobile: "9333317777",id: 880,sfid: "001C3000007eml9IAA",customer_id__c: "C23061911",product_name: "Tenure Restiction Test",product_price: null,product_mrp: null},
// {opp_id: "006C3000004zQL6IAM",transaction_application_id__c: "TA2306276",loan_id__c: "2959",limit_application_ids__c: "LA23061911",created_at: "27/06/2023",status: "Loan Disbursed",amount: 300000,utr: "976234",utr_date: "",name: "Rushabh Damkondwar",mobile: "9333317777",id: 880,sfid: "001C3000007eml9IAA",customer_id__c: "C23061911",product_name: "Tenure Restiction Test",product_price: null,product_mrp: null},
// {opp_id: "006C3000004zQL6IAM",transaction_application_id__c: "TA2306276",loan_id__c: "2959",limit_application_ids__c: "LA23061911",created_at: "27/06/2023",status: "Loan Disbursed",amount: 100000,utr: "976234",utr_date: "",name: "Rushabh Damkondwar",mobile: "9333317777",id: 880,sfid: "001C3000007eml9IAA",customer_id__c: "C23061911",product_name: "Tenure Restiction Test",product_price: null,product_mrp: null},
// {opp_id: "006C3000004zQL6IAM",transaction_application_id__c: "TA2306276",loan_id__c: "2959",limit_application_ids__c: "LA23061911",created_at: "27/06/2023",status: "Loan Disbursed",amount: 100000,utr: "976234",utr_date: "",name: "Rushabh Damkondwar",mobile: "9333317777",id: 880,sfid: "001C3000007eml9IAA",customer_id__c: "C23061911",product_name: "Tenure Restiction Test",product_price: null,product_mrp: null},
// {opp_id: "006C3000004zQL6IAM",transaction_application_id__c: "TA2306276",loan_id__c: "2959",limit_application_ids__c: "LA23061911",created_at: "27/06/2023",status: "Loan Disbursed",amount: 500000,utr: "976234",utr_date: "",name: "Rushabh Damkondwar",mobile: "9333317777",id: 880,sfid: "001C3000007eml9IAA",customer_id__c: "C23061911",product_name: "Tenure Restiction Test",product_price: null,product_mrp: null},
// {opp_id: "006C3000004zQL6IAM",transaction_application_id__c: "TA2306276",loan_id__c: "2959",limit_application_ids__c: "LA23061911",created_at: "27/06/2023",status: "Loan Disbursed",amount: 100000,utr: "976234",utr_date: "",name: "Rushabh Damkondwar",mobile: "9333317777",id: 880,sfid: "001C3000007eml9IAA",customer_id__c: "C23061911",product_name: "Tenure Restiction Test",product_price: null,product_mrp: null}]

export default class CancellationsettlementCred extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sellingValue: "",
      page: 1,
      limit: 10,
      stage: "Cancellation Request",
      // page: 1,
      rowsPerPage: 10
    }
  }

  closeCancelModel = (ele) => {
    this.props.dispatch(selectedCancelationId(ele))
    this.props.dispatch(closeLoanCanelModal())
  }

  handleChangePage = (event, value) => {
    const sfid = localStorage.getItem('sfid');
    let data = `page=${value}&section=${this.state.stage}&limit=${this.state.limit}`;
    this.setState({ page: value });
    this.props.dispatch(getSettlement(data, sfid));
  }

  handleChangelimitOfPage = (event) => {
    let perRowData = event.target.value;
    const sfid = localStorage.getItem('sfid');
    // let data =  `page=${perRowData}`;
    let data = `page=1&section=${this.state.stage}&limit=${perRowData}`;
    this.setState({ limit: perRowData });
    this.props.dispatch(getSettlement(data, sfid));
  }

  handleChangePageNew = (event, newPage) => {
    this.setState({ page: newPage })
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 })

  };


  openSettlementDetails = (id) => {
    this.props.dispatch(setSettlementclickItemId(id));
    this.props.dispatch(merchantSettlementDetail(id));
  }

  componentWillUnmount() {
    // this.setState({isHovered:false,indexHovered:null})
  }

  formatData = (dateIs) => {
    let forDate = dateIs.split('/')
    forDate = forDate[1] + '/' + forDate[0] + '/' + forDate[2]
    let newDate = new Date(forDate);
    let months = ["Jan", "Feb", "Mar", "April", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    let year = newDate.getFullYear();
    let date = newDate.getDate();
    let month = newDate.getMonth();
    month = months[month];
    console.log(month, date, year);
    return month + ' ' + date + ', ' + year;
  }
  render() {
    const { userMessage, activeWizrTab } = this.props
    //const totalPages = userMessage.cancellation_request_count ? Math.ceil(userMessage.cancellation_request_count / Number(this.state.limit)): 1;
    console.log('refundssss', userMessage)
    let totalPages = 1
    if (userMessage.leadCount) {
      let getArray = userMessage.leadCount[2]
      if (getArray.cancellationCount) {
        totalPages = Math.ceil(getArray.cancellationCount / Number(this.state.limit));

      }
      //totalPages = Math.ceil(getArray.cancellationCount / Number(this.state.limit));
    }
    const cancellationtData = [];
    for (let i = 0; i < 10; i++) {
      cancellationtData.push(<tr className="shown cursor-point trhover" data-toggle="modal" data-target="#credMyModaxl3" key={i}>
        <td ><p className="mt-2"><b>{i + 1}</b></p></td>
        <td>
          <div className=""><p className="appIDLeads mb-2">AI6125478</p><span className="ai_d appIDDates">May 25,2021</span></div>
        </td>
        <td>
          <div className=""><p className="applicantName mb-1">Sneha Sharma</p> <span className="ai_d applicantNam">9995 888 777</span></div>
        </td>
        <td colSpan={3}>
          <div className=""><p className="appIDCourseName appTextline">Executive PG Programme in Software Development-Specialization</p></div>
        </td>
        {activeWizrTab === 'WIZR Skills' && (<td><p className="settleAmount">₹ 1,20,000</p></td>)}
        {activeWizrTab === 'WIZR Skills' && (<td><p className="applicantName">Request Pending</p></td>)}
        {/* <td>
             <p className=""> UTR: HDFC4564755675</p>
           </td> */}
        <td><i className={`fa fa-angle-right float-right size20 cursor-point`} data-toggle="modal" data-target="#credMyModaxl3"></i></td>
      </tr>)
    }

  //   let rowDet = {
  //     opp_id:"006C3000004zQL6IAM",
  //     transaction_application_id__c :  "TA2306276",
  //     loan_id__c : '2959',
  //     limit_application_ids__c : "LA23061911",
  //     created_at: "27/06/2023",
  //     status: "Loan Disbursed",
  //     amount: 100000,
  //     utr: "976234",
  //     utr_date: "",
  //     mobile: "9333317777",

  // };
  console.log("abb")

//  const a=[{
//     opp_id: "006C3000004zQL6IAM",
//     transaction_application_id__c: "TA2306276",
//     loan_id__c: "2959",
//     limit_application_ids__c: "LA23061911",
//     created_at: "27/06/2023",
//     status: "Loan Disbursed",
//     amount: 100000,
//     utr: "976234",
//     utr_date: "",
//     name: "Rushabh Damkondwar",
//     mobile: "9333317777",
//     id: 880,
//     "sfid": "001C3000007eml9IAA",
//     customer_id__c: "C23061911",
//     product_name: "Tenure Restiction Test",
//     product_price: null,
//     product_mrp: null
// }]
// console.log("abb",a[0].sfid)
// console.log('get',)
    return (
      <>

        <div className="table-responsive">
          <table
            className="table settlement_due_table tableStyle"
            id="dataTable1"
            cellSpacing={0}
          >
            <thead>
              {/* <tr>
                                    <th>
                                      <div className="d-flex all_check">
                                        <input type="checkbox" />
                                        <label>All</label>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="d-flex align-items-center">Application ID
                                    
                                      </div>

                                    </th>
                                    <th>Applicant Details</th>
                                    <th>Product Name</th>
                                    <th>
                                      <div className="d-flex align-items-center">Refund Amount
                                        <div className="d-none">
                                          <button className="up"></button>
                                          <button className="down"></button>
                                      </div>
                                      </div>
                                    </th>
                                    <th>
                                      Actionable
                                    </th>
                                  </tr> */}
              <tr>
                <th >
                  <div className="d-flex align-items-center appHead-headinghash">#</div>
                </th>
                <th >
                  <div className="d-flex align-items-center appHead-heading ml-50">Application ID</div>
                </th>
                <th>
                  <div className="d-flex align-items-center appHead-heading">Application Details
                    <div className="d-none">
                      <button className="up"></button>
                      <button className="down"></button>
                    </div>
                  </div>

                </th>
                <th colSpan={0} className='appHead-heading'>Product Name</th>
               <th className='appHead-heading'>Order Value</th>
                <th>
                  <div className="d-flex align-items-center appHead-heading">Payment Expected On
                    <div className="d-none">
                      <button className="up"></button>
                      <button className="down"></button>
                    </div>
                  </div>
                </th>
                {/* <th>
                                      UTR No.
                                    </th> */}
                <th className='appHead-heading'>
                  Edit info
                </th>
              </tr>
            </thead>
            <tbody>
              {userMessage.proData && userMessage.proData && userMessage.proData.length>0 ?
              // { a && a.length>0 ?

                                    (
                                      userMessage.proData.map((item, index) => (

                                        <tr>
                                          {/* <td>
                                            <div className="d-flex" key={item}>
                                              <div className="single_check">
                                                <input type="checkbox" className="" />
                                                <label></label>
                                              </div>
                                              <div>
                                                <div className="new_ribbon">New </div>
                                              </div>
                                            </div>
                                          </td> */}
                                          <td>
                                            {/* <div className="d-flex">
                                              <div className="single_check">
                                                <input type="checkbox" className="" />
                                                <label></label>
                                              </div>
                                              <div>
                                                <div className="new_ribbon">New </div>
                                              </div>
                                            </div> */}
                                            <p className="mt-2 appIDnum"><b>{index + 1}</b></p>

                                          </td>

                                          {/* <td>
                                            <p
                                              className="ai"
                                              data-toggle="modal"
                                              data-target="#myModal6"
                                              onClick={() => this.openSettlementDetails(item.opp_id)}

                                            >{item.transaction_application_id__c}</p>
                                            <span className="ai_d">{item.created_at}</span>
                                          </td> */}

                                          <td>
                                            <div className='coursenewlabel'>
                                              <div className="new-course-button">NEW</div>
                                              <div>
                                                {/* <p
                                                className="ai"
                                                data-toggle="modal"
                                                data-target="#myModaxl3"
                                                onClick={() => this.props.dispatch(merchantSettlementDetail(item.opp_id))}
                                              >{item.transaction_application_id__c ? item.transaction_application_id__c : '-'}</p>
                                              <span className="ai_d">{item.created_at ? this.formatData(item.created_at) : '-'}</span> */}
                                                <div className=""><p className="appIDLeads mb-1">{item.transaction_application_id__c ? item.transaction_application_id__c : '-'}</p><span className="ai_d appIDDates">{item.created_at ? this.formatData(item.created_at) : '-'}</span></div>

                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            {/* <p>{item.name ? item.name : '-'}</p>
                                            <span>{item.mobile ? item.mobile : '-'}</span> */}
                                            <p className="applicantName mb-1 ml-1p">{item.name ? item.name : '-'}</p> <span className="ai_d applicantNam">{item.mobile ? item.mobile : '-'}</span>
                                          </td>


                                          {/* <td>
                                            <p>{item.name}</p>
                                            <span

                                >{item.mobile}</span>
                                          </td> */}
                                          <td>
                                            <p className="appIDCourseName appTextline">{item.product_name ? item.product_name : '-'}</p>
                                          </td>
                                          <td><p className="settleAmount">₹ {item.amount ? item.amount.toLocaleString('en-IN') : '-'}</p></td>
                                          <td><p className="appIDCourseName"> {item.created_at ? this.formatData(item.created_at) : '-'}</p></td>
                                          <td><i className={`fa fa-angle-right float-right  cursor-point size20`} data-toggle="modal" data-target="#credMyModaxl3" onClick={() => this.props.dispatch(merchantSettlementDetail(item.opp_id))} key={item}></i></td>


                                          {/* <td>{item.product_name}</td> */}
                                          {/* <td>₹ {item.amount}</td> */}
                                          {/* <td>
                                            <div className="d-flex align-items-center">
                                              <div className="mr-2" >
                                                <button onClick={() => this.closeCancelModel(item.opp_id)} >
                                                  <i className="fa fa-check" style={{ "color": "#0FD17E" }} aria-hidden="true" /> Approve </button></div>
                                              <div>
                                                <i className="fa fa-times" style={{ "color": "#D51332" }} aria-hidden="true"></i> Reject</div>
                                            </div>
                                          </td> */}
                                        </tr>
                                      )
                                      )): <td colSpan="10"><NoRecordsFound message={'No Data to show '} /></td>
                                  }


              {/* {cancellationtData} */}
            </tbody>

          </table>
          <div className="d-flex align-items-center justify-content-lg-end justify-content-center mb-4">
          <div className="d-flex align-items-center pages">
          <TablePagination
              component="div"
              count={200}
              page={this.state.page}
              onPageChange={this.handleChangePageNew}
              rowsPerPage={this.state.rowsPerPage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 20, 30]}

            />
          </div>

        </div>
        </div>

        


      </>
    );
  }
}
